const list = [
  {
    code: '110000',
    name: '北京市',
    city_level: 1,
    lng: '116.407526',
    lat: '39.904030'
  },
  {
    code: '110100',
    name: '北京市',
    city_level: 2,
    lng: '116.407526',
    lat: '39.904030'
  },
  {
    code: '110101',
    name: '东城区',
    city_level: 3,
    lng: '116.416357',
    lat: '39.928353'
  },
  {
    code: '110102',
    name: '西城区',
    city_level: 3,
    lng: '116.365868',
    lat: '39.912289'
  },
  {
    code: '110105',
    name: '朝阳区',
    city_level: 3,
    lng: '116.601144',
    lat: '39.948574'
  },
  {
    code: '110106',
    name: '丰台区',
    city_level: 3,
    lng: '116.287149',
    lat: '39.858427'
  },
  {
    code: '110107',
    name: '石景山区',
    city_level: 3,
    lng: '116.222982',
    lat: '39.906611'
  },
  {
    code: '110108',
    name: '海淀区',
    city_level: 3,
    lng: '116.329519',
    lat: '39.972134'
  },
  {
    code: '110109',
    name: '门头沟区',
    city_level: 3,
    lng: '116.102009',
    lat: '39.940646'
  },
  {
    code: '110111',
    name: '房山区',
    city_level: 3,
    lng: '116.143267',
    lat: '39.749144'
  },
  {
    code: '110112',
    name: '通州区',
    city_level: 3,
    lng: '121.075087',
    lat: '32.064161'
  },
  {
    code: '110113',
    name: '顺义区',
    city_level: 3,
    lng: '116.654561',
    lat: '40.130347'
  },
  {
    code: '110114',
    name: '昌平区',
    city_level: 3,
    lng: '116.231204',
    lat: '40.220660'
  },
  {
    code: '110115',
    name: '大兴区',
    city_level: 3,
    lng: '116.341014',
    lat: '39.784747'
  },
  {
    code: '110116',
    name: '怀柔区',
    city_level: 3,
    lng: '116.642349',
    lat: '40.315704'
  },
  {
    code: '110117',
    name: '平谷区',
    city_level: 3,
    lng: '117.121383',
    lat: '40.140701'
  },
  {
    code: '110118',
    name: '密云区',
    city_level: 3,
    lng: '116.843177',
    lat: '40.376834'
  },
  {
    code: '110119',
    name: '延庆区',
    city_level: 3,
    lng: '115.974848',
    lat: '40.456951'
  },
  {
    code: '120000',
    name: '天津市',
    city_level: 1,
    lng: '117.200983',
    lat: '39.084158'
  },
  {
    code: '120100',
    name: '天津市',
    city_level: 2,
    lng: '117.200983',
    lat: '39.084158'
  },
  {
    code: '120101',
    name: '和平区',
    city_level: 3,
    lng: '123.395680',
    lat: '41.789450'
  },
  {
    code: '120102',
    name: '河东区',
    city_level: 3,
    lng: '118.402893',
    lat: '35.089917'
  },
  {
    code: '120103',
    name: '河西区',
    city_level: 3,
    lng: '117.223372',
    lat: '39.109563'
  },
  {
    code: '120104',
    name: '南开区',
    city_level: 3,
    lng: '117.150738',
    lat: '39.138203'
  },
  {
    code: '120105',
    name: '河北区',
    city_level: 3,
    lng: '117.196648',
    lat: '39.147869'
  },
  {
    code: '120106',
    name: '红桥区',
    city_level: 3,
    lng: '117.151533',
    lat: '39.167345'
  },
  {
    code: '120110',
    name: '东丽区',
    city_level: 3,
    lng: '117.314324',
    lat: '39.086569'
  },
  {
    code: '120111',
    name: '西青区',
    city_level: 3,
    lng: '117.013949',
    lat: '39.141591'
  },
  {
    code: '120112',
    name: '津南区',
    city_level: 3,
    lng: '117.391976',
    lat: '38.984526'
  },
  {
    code: '120113',
    name: '北辰区',
    city_level: 3,
    lng: '117.139343',
    lat: '39.217817'
  },
  {
    code: '120114',
    name: '武清区',
    city_level: 3,
    lng: '117.044388',
    lat: '39.384119'
  },
  {
    code: '120115',
    name: '宝坻区',
    city_level: 3,
    lng: '117.309863',
    lat: '39.717379'
  },
  {
    code: '120116',
    name: '滨海新区',
    city_level: 3,
    lng: '117.780670',
    lat: '39.246083'
  },
  {
    code: '120117',
    name: '宁河区',
    city_level: 3,
    lng: '117.826625',
    lat: '39.329858'
  },
  {
    code: '120118',
    name: '静海区',
    city_level: 3,
    lng: '116.974130',
    lat: '38.947512'
  },
  {
    code: '120119',
    name: '蓟州区',
    city_level: 3,
    lng: '117.408307',
    lat: '40.046060'
  },
  {
    code: '130000',
    name: '河北省',
    city_level: 1,
    lng: '114.468664',
    lat: '38.037057'
  },
  {
    code: '130100',
    name: '石家庄市',
    city_level: 2,
    lng: '114.514859',
    lat: '38.042306'
  },
  {
    code: '130102',
    name: '长安区',
    city_level: 3,
    lng: '108.906917',
    lat: '34.159016'
  },
  {
    code: '130104',
    name: '桥西区',
    city_level: 3,
    lng: '114.869407',
    lat: '40.819564'
  },
  {
    code: '130105',
    name: '新华区',
    city_level: 3,
    lng: '113.293999',
    lat: '33.737365'
  },
  {
    code: '130107',
    name: '井陉矿区',
    city_level: 2,
    lng: '114.514859',
    lat: '38.042306'
  },
  {
    code: '130108',
    name: '裕华区',
    city_level: 3,
    lng: '114.531362',
    lat: '38.006453'
  },
  {
    code: '130109',
    name: '藁城区',
    city_level: 3,
    lng: '114.847075',
    lat: '38.021567'
  },
  {
    code: '130110',
    name: '鹿泉区',
    city_level: 3,
    lng: '114.313724',
    lat: '38.085958'
  },
  {
    code: '130111',
    name: '栾城区',
    city_level: 3,
    lng: '114.648318',
    lat: '37.900200'
  },
  {
    code: '130121',
    name: '井陉县',
    city_level: 3,
    lng: '114.145240',
    lat: '38.032148'
  },
  {
    code: '130123',
    name: '正定县',
    city_level: 3,
    lng: '114.570941',
    lat: '38.146445'
  },
  {
    code: '130125',
    name: '行唐县',
    city_level: 3,
    lng: '114.552737',
    lat: '38.438411'
  },
  {
    code: '130126',
    name: '灵寿县',
    city_level: 3,
    lng: '114.376140',
    lat: '38.306110'
  },
  {
    code: '130127',
    name: '高邑县',
    city_level: 3,
    lng: '114.611433',
    lat: '37.615159'
  },
  {
    code: '130128',
    name: '深泽县',
    city_level: 3,
    lng: '115.200910',
    lat: '38.184072'
  },
  {
    code: '130129',
    name: '赞皇县',
    city_level: 3,
    lng: '114.386155',
    lat: '37.665576'
  },
  {
    code: '130130',
    name: '无极县',
    city_level: 3,
    lng: '114.976337',
    lat: '38.179141'
  },
  {
    code: '130131',
    name: '平山县',
    city_level: 3,
    lng: '114.199134',
    lat: '38.247144'
  },
  {
    code: '130132',
    name: '元氏县',
    city_level: 3,
    lng: '114.525580',
    lat: '37.766651'
  },
  {
    code: '130133',
    name: '赵县',
    city_level: 3,
    lng: '114.776187',
    lat: '37.756498'
  },
  {
    code: '130181',
    name: '辛集市',
    city_level: 3,
    lng: '115.218057',
    lat: '37.943315'
  },
  {
    code: '130183',
    name: '晋州市',
    city_level: 3,
    lng: '115.044185',
    lat: '38.033629'
  },
  {
    code: '130184',
    name: '新乐市',
    city_level: 3,
    lng: '114.684014',
    lat: '38.343296'
  },
  {
    code: '130200',
    name: '唐山市',
    city_level: 2,
    lng: '118.180193',
    lat: '39.630867'
  },
  {
    code: '130202',
    name: '路南区',
    city_level: 3,
    lng: '118.154354',
    lat: '39.625059'
  },
  {
    code: '130203',
    name: '路北区',
    city_level: 3,
    lng: '118.200692',
    lat: '39.624437'
  },
  {
    code: '130204',
    name: '古冶区',
    city_level: 3,
    lng: '118.447635',
    lat: '39.733578'
  },
  {
    code: '130205',
    name: '开平区',
    city_level: 3,
    lng: '118.261842',
    lat: '39.671001'
  },
  {
    code: '130207',
    name: '丰南区',
    city_level: 3,
    lng: '118.085169',
    lat: '39.576031'
  },
  {
    code: '130208',
    name: '丰润区',
    city_level: 3,
    lng: '118.162216',
    lat: '39.832582'
  },
  {
    code: '130209',
    name: '曹妃甸区',
    city_level: 3,
    lng: '118.460379',
    lat: '39.273070'
  },
  {
    code: '130224',
    name: '滦南县',
    city_level: 3,
    lng: '118.682379',
    lat: '39.518997'
  },
  {
    code: '130225',
    name: '乐亭县',
    city_level: 3,
    lng: '118.912571',
    lat: '39.425608'
  },
  {
    code: '130227',
    name: '迁西县',
    city_level: 3,
    lng: '118.314715',
    lat: '40.141500'
  },
  {
    code: '130229',
    name: '玉田县',
    city_level: 3,
    lng: '117.738658',
    lat: '39.900401'
  },
  {
    code: '130281',
    name: '遵化市',
    city_level: 3,
    lng: '117.965892',
    lat: '40.189202'
  },
  {
    code: '130283',
    name: '迁安市',
    city_level: 3,
    lng: '118.701144',
    lat: '39.999175'
  },
  {
    code: '130284',
    name: '滦州市',
    city_level: 3,
    lng: '118.689775',
    lat: '39.740380'
  },
  {
    code: '130300',
    name: '秦皇岛市',
    city_level: 2,
    lng: '119.600492',
    lat: '39.935385'
  },
  {
    code: '130302',
    name: '海港区',
    city_level: 3,
    lng: '119.564962',
    lat: '39.947560'
  },
  {
    code: '130303',
    name: '山海关区',
    city_level: 3,
    lng: '119.775799',
    lat: '39.978849'
  },
  {
    code: '130304',
    name: '北戴河区',
    city_level: 3,
    lng: '119.488914',
    lat: '39.834751'
  },
  {
    code: '130306',
    name: '抚宁区',
    city_level: 3,
    lng: '119.235739',
    lat: '39.889241'
  },
  {
    code: '130321',
    name: '青龙满族自治县',
    city_level: 3,
    lng: '118.949684',
    lat: '40.407578'
  },
  {
    code: '130322',
    name: '昌黎县',
    city_level: 3,
    lng: '119.162694',
    lat: '39.712813'
  },
  {
    code: '130324',
    name: '卢龙县',
    city_level: 3,
    lng: '118.892986',
    lat: '39.891947'
  },
  {
    code: '130400',
    name: '邯郸市',
    city_level: 2,
    lng: '114.538961',
    lat: '36.625657'
  },
  {
    code: '130402',
    name: '邯山区',
    city_level: 3,
    lng: '114.490431',
    lat: '36.580358'
  },
  {
    code: '130403',
    name: '丛台区',
    city_level: 3,
    lng: '114.492897',
    lat: '36.636410'
  },
  {
    code: '130404',
    name: '复兴区',
    city_level: 3,
    lng: '114.462058',
    lat: '36.639022'
  },
  {
    code: '130406',
    name: '峰峰矿区',
    city_level: 2,
    lng: '114.538961',
    lat: '36.625657'
  },
  {
    code: '130407',
    name: '肥乡区',
    city_level: 3,
    lng: '114.805153',
    lat: '36.555779'
  },
  {
    code: '130408',
    name: '永年区',
    city_level: 3,
    lng: '114.496162',
    lat: '36.776413'
  },
  {
    code: '130423',
    name: '临漳县',
    city_level: 3,
    lng: '114.619544',
    lat: '36.335300'
  },
  {
    code: '130424',
    name: '成安县',
    city_level: 3,
    lng: '114.670032',
    lat: '36.444407'
  },
  {
    code: '130425',
    name: '大名县',
    city_level: 3,
    lng: '115.147814',
    lat: '36.285616'
  },
  {
    code: '130426',
    name: '涉县',
    city_level: 3,
    lng: '113.691401',
    lat: '36.584995'
  },
  {
    code: '130427',
    name: '磁县',
    city_level: 3,
    lng: '114.373947',
    lat: '36.374012'
  },
  {
    code: '130430',
    name: '邱县',
    city_level: 3,
    lng: '115.186792',
    lat: '36.811133'
  },
  {
    code: '130431',
    name: '鸡泽县',
    city_level: 3,
    lng: '114.878299',
    lat: '36.920350'
  },
  {
    code: '130432',
    name: '广平县',
    city_level: 3,
    lng: '114.948607',
    lat: '36.483484'
  },
  {
    code: '130433',
    name: '馆陶县',
    city_level: 3,
    lng: '115.282468',
    lat: '36.547557'
  },
  {
    code: '130434',
    name: '魏县',
    city_level: 3,
    lng: '114.938976',
    lat: '36.359861'
  },
  {
    code: '130435',
    name: '曲周县',
    city_level: 3,
    lng: '114.945131',
    lat: '36.780170'
  },
  {
    code: '130481',
    name: '武安市',
    city_level: 3,
    lng: '114.203697',
    lat: '36.696506'
  },
  {
    code: '130500',
    name: '邢台市',
    city_level: 2,
    lng: '114.504844',
    lat: '37.070589'
  },
  {
    code: '130502',
    name: '襄都区',
    city_level: 2,
    lng: '114.504844',
    lat: '37.070589'
  },
  {
    code: '130503',
    name: '信都区',
    city_level: 2,
    lng: '114.504844',
    lat: '37.070589'
  },
  {
    code: '130505',
    name: '任泽区',
    city_level: 2,
    lng: '114.504844',
    lat: '37.070589'
  },
  {
    code: '130506',
    name: '南和区',
    city_level: 2,
    lng: '114.504844',
    lat: '37.070589'
  },
  {
    code: '130522',
    name: '临城县',
    city_level: 3,
    lng: '114.498762',
    lat: '37.444499'
  },
  {
    code: '130523',
    name: '内丘县',
    city_level: 3,
    lng: '114.512128',
    lat: '37.286669'
  },
  {
    code: '130524',
    name: '柏乡县',
    city_level: 3,
    lng: '114.693426',
    lat: '37.482423'
  },
  {
    code: '130525',
    name: '隆尧县',
    city_level: 3,
    lng: '114.770419',
    lat: '37.350173'
  },
  {
    code: '130528',
    name: '宁晋县',
    city_level: 3,
    lng: '114.919301',
    lat: '37.619886'
  },
  {
    code: '130529',
    name: '巨鹿县',
    city_level: 3,
    lng: '115.037478',
    lat: '37.221112'
  },
  {
    code: '130530',
    name: '新河县',
    city_level: 3,
    lng: '115.242014',
    lat: '37.528712'
  },
  {
    code: '130531',
    name: '广宗县',
    city_level: 3,
    lng: '115.142607',
    lat: '37.074660'
  },
  {
    code: '130532',
    name: '平乡县',
    city_level: 3,
    lng: '115.030076',
    lat: '37.063148'
  },
  {
    code: '130533',
    name: '威县',
    city_level: 3,
    lng: '115.266783',
    lat: '36.975321'
  },
  {
    code: '130534',
    name: '清河县',
    city_level: 3,
    lng: '115.667576',
    lat: '37.040087'
  },
  {
    code: '130535',
    name: '临西县',
    city_level: 3,
    lng: '115.501048',
    lat: '36.870813'
  },
  {
    code: '130581',
    name: '南宫市',
    city_level: 3,
    lng: '115.408748',
    lat: '37.359264'
  },
  {
    code: '130582',
    name: '沙河市',
    city_level: 3,
    lng: '114.503335',
    lat: '36.854922'
  },
  {
    code: '130600',
    name: '保定市',
    city_level: 2,
    lng: '115.464806',
    lat: '38.873891'
  },
  {
    code: '130602',
    name: '竞秀区',
    city_level: 3,
    lng: '115.458669',
    lat: '38.877561'
  },
  {
    code: '130606',
    name: '莲池区',
    city_level: 3,
    lng: '115.497342',
    lat: '38.883209'
  },
  {
    code: '130607',
    name: '满城区',
    city_level: 3,
    lng: '115.322351',
    lat: '38.948955'
  },
  {
    code: '130608',
    name: '清苑区',
    city_level: 3,
    lng: '115.489968',
    lat: '38.765041'
  },
  {
    code: '130609',
    name: '徐水区',
    city_level: 3,
    lng: '115.624410',
    lat: '39.026233'
  },
  {
    code: '130623',
    name: '涞水县',
    city_level: 3,
    lng: '115.713905',
    lat: '39.394317'
  },
  {
    code: '130624',
    name: '阜平县',
    city_level: 3,
    lng: '114.195104',
    lat: '38.849152'
  },
  {
    code: '130626',
    name: '定兴县',
    city_level: 3,
    lng: '115.808175',
    lat: '39.263018'
  },
  {
    code: '130627',
    name: '唐县',
    city_level: 3,
    lng: '114.982972',
    lat: '38.748204'
  },
  {
    code: '130628',
    name: '高阳县',
    city_level: 3,
    lng: '115.778965',
    lat: '38.700088'
  },
  {
    code: '130629',
    name: '容城县',
    city_level: 3,
    lng: '115.861657',
    lat: '39.042784'
  },
  {
    code: '130630',
    name: '涞源县',
    city_level: 3,
    lng: '114.694284',
    lat: '39.360247'
  },
  {
    code: '130631',
    name: '望都县',
    city_level: 3,
    lng: '115.154511',
    lat: '38.695736'
  },
  {
    code: '130632',
    name: '安新县',
    city_level: 3,
    lng: '115.935553',
    lat: '38.935364'
  },
  {
    code: '130633',
    name: '易县',
    city_level: 3,
    lng: '115.497509',
    lat: '39.349398'
  },
  {
    code: '130634',
    name: '曲阳县',
    city_level: 3,
    lng: '114.744970',
    lat: '38.622242'
  },
  {
    code: '130635',
    name: '蠡县',
    city_level: 3,
    lng: '115.583855',
    lat: '38.488056'
  },
  {
    code: '130636',
    name: '顺平县',
    city_level: 3,
    lng: '115.135470',
    lat: '38.837487'
  },
  {
    code: '130637',
    name: '博野县',
    city_level: 3,
    lng: '115.464380',
    lat: '38.457364'
  },
  {
    code: '130638',
    name: '雄县',
    city_level: 3,
    lng: '116.108650',
    lat: '38.994550'
  },
  {
    code: '130681',
    name: '涿州市',
    city_level: 3,
    lng: '115.974422',
    lat: '39.485283'
  },
  {
    code: '130682',
    name: '定州市',
    city_level: 3,
    lng: '114.990159',
    lat: '38.516174'
  },
  {
    code: '130683',
    name: '安国市',
    city_level: 3,
    lng: '115.326647',
    lat: '38.418440'
  },
  {
    code: '130684',
    name: '高碑店市',
    city_level: 3,
    lng: '115.873758',
    lat: '39.326521'
  },
  {
    code: '130700',
    name: '张家口市',
    city_level: 2,
    lng: '114.887543',
    lat: '40.824418'
  },
  {
    code: '130702',
    name: '桥东区',
    city_level: 3,
    lng: '114.894341',
    lat: '40.788457'
  },
  {
    code: '130703',
    name: '桥西区',
    city_level: 3,
    lng: '114.869407',
    lat: '40.819564'
  },
  {
    code: '130705',
    name: '宣化区',
    city_level: 3,
    lng: '115.099510',
    lat: '40.608726'
  },
  {
    code: '130706',
    name: '下花园区',
    city_level: 3,
    lng: '115.287352',
    lat: '40.502652'
  },
  {
    code: '130708',
    name: '万全区',
    city_level: 3,
    lng: '114.740560',
    lat: '40.766898'
  },
  {
    code: '130709',
    name: '崇礼区',
    city_level: 3,
    lng: '115.282669',
    lat: '40.974676'
  },
  {
    code: '130722',
    name: '张北县',
    city_level: 3,
    lng: '114.720086',
    lat: '41.158557'
  },
  {
    code: '130723',
    name: '康保县',
    city_level: 3,
    lng: '114.600404',
    lat: '41.852368'
  },
  {
    code: '130724',
    name: '沽源县',
    city_level: 3,
    lng: '115.688692',
    lat: '41.669668'
  },
  {
    code: '130725',
    name: '尚义县',
    city_level: 3,
    lng: '113.969619',
    lat: '41.076227'
  },
  {
    code: '130726',
    name: '蔚县',
    city_level: 3,
    lng: '114.588903',
    lat: '39.840843'
  },
  {
    code: '130727',
    name: '阳原县',
    city_level: 3,
    lng: '114.150388',
    lat: '40.103742'
  },
  {
    code: '130728',
    name: '怀安县',
    city_level: 3,
    lng: '114.385791',
    lat: '40.674193'
  },
  {
    code: '130730',
    name: '怀来县',
    city_level: 3,
    lng: '115.517862',
    lat: '40.415343'
  },
  {
    code: '130731',
    name: '涿鹿县',
    city_level: 3,
    lng: '115.205345',
    lat: '40.379563'
  },
  {
    code: '130732',
    name: '赤城县',
    city_level: 3,
    lng: '115.831499',
    lat: '40.912921'
  },
  {
    code: '130800',
    name: '承德市',
    city_level: 2,
    lng: '117.962410',
    lat: '40.954071'
  },
  {
    code: '130802',
    name: '双桥区',
    city_level: 3,
    lng: '117.943348',
    lat: '40.974650'
  },
  {
    code: '130803',
    name: '双滦区',
    city_level: 3,
    lng: '117.799912',
    lat: '40.959196'
  },
  {
    code: '130804',
    name: '鹰手营子矿区',
    city_level: 3,
    lng: '117.661156',
    lat: '40.546956'
  },
  {
    code: '130821',
    name: '承德县',
    city_level: 3,
    lng: '118.173825',
    lat: '40.768238'
  },
  {
    code: '130822',
    name: '兴隆县',
    city_level: 3,
    lng: '117.500558',
    lat: '40.417358'
  },
  {
    code: '130824',
    name: '滦平县',
    city_level: 3,
    lng: '117.332801',
    lat: '40.941482'
  },
  {
    code: '130825',
    name: '隆化县',
    city_level: 3,
    lng: '117.738938',
    lat: '41.313791'
  },
  {
    code: '130826',
    name: '丰宁满族自治县',
    city_level: 3,
    lng: '116.645988',
    lat: '41.208949'
  },
  {
    code: '130827',
    name: '宽城满族自治县',
    city_level: 3,
    lng: '118.485313',
    lat: '40.611391'
  },
  {
    code: '130828',
    name: '围场满族蒙古族自治县',
    city_level: 3,
    lng: '117.760159',
    lat: '41.938529'
  },
  {
    code: '130881',
    name: '平泉市',
    city_level: 3,
    lng: '118.690239',
    lat: '41.005611'
  },
  {
    code: '130900',
    name: '沧州市',
    city_level: 2,
    lng: '116.838834',
    lat: '38.304477'
  },
  {
    code: '130902',
    name: '新华区',
    city_level: 3,
    lng: '113.293999',
    lat: '33.737365'
  },
  {
    code: '130903',
    name: '运河区',
    city_level: 3,
    lng: '116.832276',
    lat: '38.310143'
  },
  {
    code: '130921',
    name: '沧县',
    city_level: 3,
    lng: '117.007478',
    lat: '38.219856'
  },
  {
    code: '130922',
    name: '青县',
    city_level: 3,
    lng: '116.804306',
    lat: '38.583021'
  },
  {
    code: '130923',
    name: '东光县',
    city_level: 3,
    lng: '116.537067',
    lat: '37.888248'
  },
  {
    code: '130924',
    name: '海兴县',
    city_level: 3,
    lng: '117.496606',
    lat: '38.141582'
  },
  {
    code: '130925',
    name: '盐山县',
    city_level: 3,
    lng: '117.230603',
    lat: '38.058088'
  },
  {
    code: '130926',
    name: '肃宁县',
    city_level: 3,
    lng: '115.829758',
    lat: '38.422802'
  },
  {
    code: '130927',
    name: '南皮县',
    city_level: 3,
    lng: '116.708104',
    lat: '38.038584'
  },
  {
    code: '130928',
    name: '吴桥县',
    city_level: 3,
    lng: '116.391508',
    lat: '37.627661'
  },
  {
    code: '130929',
    name: '献县',
    city_level: 3,
    lng: '116.122802',
    lat: '38.190144'
  },
  {
    code: '130930',
    name: '孟村回族自治县',
    city_level: 3,
    lng: '117.104298',
    lat: '38.053409'
  },
  {
    code: '130981',
    name: '泊头市',
    city_level: 3,
    lng: '116.578368',
    lat: '38.083437'
  },
  {
    code: '130982',
    name: '任丘市',
    city_level: 3,
    lng: '116.082918',
    lat: '38.683592'
  },
  {
    code: '130983',
    name: '黄骅市',
    city_level: 3,
    lng: '117.330048',
    lat: '38.371383'
  },
  {
    code: '130984',
    name: '河间市',
    city_level: 3,
    lng: '116.099518',
    lat: '38.446624'
  },
  {
    code: '131000',
    name: '廊坊市',
    city_level: 2,
    lng: '116.683752',
    lat: '39.538047'
  },
  {
    code: '131002',
    name: '安次区',
    city_level: 3,
    lng: '116.694544',
    lat: '39.502569'
  },
  {
    code: '131003',
    name: '广阳区',
    city_level: 3,
    lng: '116.710690',
    lat: '39.522786'
  },
  {
    code: '131022',
    name: '固安县',
    city_level: 3,
    lng: '116.298657',
    lat: '39.438214'
  },
  {
    code: '131023',
    name: '永清县',
    city_level: 3,
    lng: '116.499028',
    lat: '39.321794'
  },
  {
    code: '131024',
    name: '香河县',
    city_level: 3,
    lng: '117.006093',
    lat: '39.761424'
  },
  {
    code: '131025',
    name: '大城县',
    city_level: 3,
    lng: '116.653794',
    lat: '38.705449'
  },
  {
    code: '131026',
    name: '文安县',
    city_level: 3,
    lng: '116.457858',
    lat: '38.873281'
  },
  {
    code: '131028',
    name: '大厂回族自治县',
    city_level: 3,
    lng: '116.989574',
    lat: '39.886547'
  },
  {
    code: '131081',
    name: '霸州市',
    city_level: 3,
    lng: '116.391386',
    lat: '39.125898'
  },
  {
    code: '131082',
    name: '三河市',
    city_level: 3,
    lng: '117.078295',
    lat: '39.982718'
  },
  {
    code: '131100',
    name: '衡水市',
    city_level: 2,
    lng: '115.670177',
    lat: '37.738920'
  },
  {
    code: '131102',
    name: '桃城区',
    city_level: 3,
    lng: '115.675422',
    lat: '37.735369'
  },
  {
    code: '131103',
    name: '冀州区',
    city_level: 3,
    lng: '115.579170',
    lat: '37.542786'
  },
  {
    code: '131121',
    name: '枣强县',
    city_level: 3,
    lng: '115.724260',
    lat: '37.513417'
  },
  {
    code: '131122',
    name: '武邑县',
    city_level: 3,
    lng: '115.887655',
    lat: '37.801658'
  },
  {
    code: '131123',
    name: '武强县',
    city_level: 3,
    lng: '115.982461',
    lat: '38.041368'
  },
  {
    code: '131124',
    name: '饶阳县',
    city_level: 3,
    lng: '115.725833',
    lat: '38.235892'
  },
  {
    code: '131125',
    name: '安平县',
    city_level: 3,
    lng: '115.519216',
    lat: '38.234510'
  },
  {
    code: '131126',
    name: '故城县',
    city_level: 3,
    lng: '115.965874',
    lat: '37.347410'
  },
  {
    code: '131127',
    name: '景县',
    city_level: 3,
    lng: '116.270648',
    lat: '37.692290'
  },
  {
    code: '131128',
    name: '阜城县',
    city_level: 3,
    lng: '116.144418',
    lat: '37.868872'
  },
  {
    code: '131182',
    name: '深州市',
    city_level: 3,
    lng: '115.559574',
    lat: '38.001536'
  },
  {
    code: '140000',
    name: '山西省',
    city_level: 1,
    lng: '112.562398',
    lat: '37.873531'
  },
  {
    code: '140100',
    name: '太原市',
    city_level: 2,
    lng: '112.548879',
    lat: '37.870590'
  },
  {
    code: '140105',
    name: '小店区',
    city_level: 3,
    lng: '112.564306',
    lat: '37.742941'
  },
  {
    code: '140106',
    name: '迎泽区',
    city_level: 3,
    lng: '112.563400',
    lat: '37.863451'
  },
  {
    code: '140107',
    name: '杏花岭区',
    city_level: 3,
    lng: '112.570605',
    lat: '37.893955'
  },
  {
    code: '140108',
    name: '尖草坪区',
    city_level: 3,
    lng: '112.486691',
    lat: '37.940387'
  },
  {
    code: '140109',
    name: '万柏林区',
    city_level: 3,
    lng: '112.515748',
    lat: '37.859447'
  },
  {
    code: '140110',
    name: '晋源区',
    city_level: 3,
    lng: '112.477940',
    lat: '37.715193'
  },
  {
    code: '140121',
    name: '清徐县',
    city_level: 3,
    lng: '112.358667',
    lat: '37.607443'
  },
  {
    code: '140122',
    name: '阳曲县',
    city_level: 3,
    lng: '112.672953',
    lat: '38.058489'
  },
  {
    code: '140123',
    name: '娄烦县',
    city_level: 3,
    lng: '111.797083',
    lat: '38.067932'
  },
  {
    code: '140181',
    name: '古交市',
    city_level: 3,
    lng: '112.175875',
    lat: '37.907109'
  },
  {
    code: '140200',
    name: '大同市',
    city_level: 2,
    lng: '113.300129',
    lat: '40.076762'
  },
  {
    code: '140212',
    name: '新荣区',
    city_level: 3,
    lng: '113.140005',
    lat: '40.255866'
  },
  {
    code: '140213',
    name: '平城区',
    city_level: 2,
    lng: '113.300129',
    lat: '40.076762'
  },
  {
    code: '140214',
    name: '云冈区',
    city_level: 3,
    lng: '113.130769',
    lat: '40.109395'
  },
  {
    code: '140215',
    name: '云州区',
    city_level: 2,
    lng: '113.300129',
    lat: '40.076762'
  },
  {
    code: '140221',
    name: '阳高县',
    city_level: 3,
    lng: '113.748945',
    lat: '40.361060'
  },
  {
    code: '140222',
    name: '天镇县',
    city_level: 3,
    lng: '114.090867',
    lat: '40.420237'
  },
  {
    code: '140223',
    name: '广灵县',
    city_level: 3,
    lng: '114.274025',
    lat: '39.761749'
  },
  {
    code: '140224',
    name: '灵丘县',
    city_level: 3,
    lng: '114.234350',
    lat: '39.442406'
  },
  {
    code: '140225',
    name: '浑源县',
    city_level: 3,
    lng: '113.699475',
    lat: '39.693407'
  },
  {
    code: '140226',
    name: '左云县',
    city_level: 3,
    lng: '112.703008',
    lat: '40.013442'
  },
  {
    code: '140300',
    name: '阳泉市',
    city_level: 2,
    lng: '113.580519',
    lat: '37.856971'
  },
  {
    code: '140302',
    name: '城区',
    city_level: 3,
    lng: '115.365029',
    lat: '22.778699'
  },
  {
    code: '140303',
    name: '矿区',
    city_level: 2,
    lng: '113.580519',
    lat: '37.856971'
  },
  {
    code: '140311',
    name: '郊区',
    city_level: 3,
    lng: '117.807070',
    lat: '30.908927'
  },
  {
    code: '140321',
    name: '平定县',
    city_level: 3,
    lng: '113.657841',
    lat: '37.786653'
  },
  {
    code: '140322',
    name: '盂县',
    city_level: 3,
    lng: '113.412330',
    lat: '38.085619'
  },
  {
    code: '140400',
    name: '长治市',
    city_level: 2,
    lng: '113.116255',
    lat: '36.195386'
  },
  {
    code: '140403',
    name: '潞州区',
    city_level: 2,
    lng: '113.116255',
    lat: '36.195386'
  },
  {
    code: '140404',
    name: '上党区',
    city_level: 3,
    lng: '113.111210',
    lat: '36.187185'
  },
  {
    code: '140405',
    name: '屯留区',
    city_level: 2,
    lng: '113.116255',
    lat: '36.195386'
  },
  {
    code: '140406',
    name: '潞城区',
    city_level: 2,
    lng: '113.116255',
    lat: '36.195386'
  },
  {
    code: '140423',
    name: '襄垣县',
    city_level: 3,
    lng: '113.051491',
    lat: '36.535817'
  },
  {
    code: '140425',
    name: '平顺县',
    city_level: 3,
    lng: '113.435961',
    lat: '36.200179'
  },
  {
    code: '140426',
    name: '黎城县',
    city_level: 3,
    lng: '113.386544',
    lat: '36.503621'
  },
  {
    code: '140427',
    name: '壶关县',
    city_level: 3,
    lng: '113.207049',
    lat: '36.115449'
  },
  {
    code: '140428',
    name: '长子县',
    city_level: 3,
    lng: '112.877900',
    lat: '36.122334'
  },
  {
    code: '140429',
    name: '武乡县',
    city_level: 3,
    lng: '112.864562',
    lat: '36.837625'
  },
  {
    code: '140430',
    name: '沁县',
    city_level: 3,
    lng: '112.699226',
    lat: '36.756064'
  },
  {
    code: '140431',
    name: '沁源县',
    city_level: 3,
    lng: '112.337446',
    lat: '36.500200'
  },
  {
    code: '140500',
    name: '晋城市',
    city_level: 2,
    lng: '112.851831',
    lat: '35.490701'
  },
  {
    code: '140502',
    name: '城区',
    city_level: 3,
    lng: '115.365029',
    lat: '22.778699'
  },
  {
    code: '140521',
    name: '沁水县',
    city_level: 3,
    lng: '112.186739',
    lat: '35.690141'
  },
  {
    code: '140522',
    name: '阳城县',
    city_level: 3,
    lng: '112.414738',
    lat: '35.486029'
  },
  {
    code: '140524',
    name: '陵川县',
    city_level: 3,
    lng: '113.280688',
    lat: '35.775685'
  },
  {
    code: '140525',
    name: '泽州县',
    city_level: 3,
    lng: '112.899137',
    lat: '35.617221'
  },
  {
    code: '140581',
    name: '高平市',
    city_level: 3,
    lng: '112.923920',
    lat: '35.797997'
  },
  {
    code: '140600',
    name: '朔州市',
    city_level: 2,
    lng: '112.432825',
    lat: '39.331595'
  },
  {
    code: '140602',
    name: '朔城区',
    city_level: 3,
    lng: '112.432250',
    lat: '39.318940'
  },
  {
    code: '140603',
    name: '平鲁区',
    city_level: 3,
    lng: '112.288331',
    lat: '39.512155'
  },
  {
    code: '140621',
    name: '山阴县',
    city_level: 3,
    lng: '112.816600',
    lat: '39.526227'
  },
  {
    code: '140622',
    name: '应县',
    city_level: 3,
    lng: '113.191099',
    lat: '39.554247'
  },
  {
    code: '140623',
    name: '右玉县',
    city_level: 3,
    lng: '112.466989',
    lat: '39.989064'
  },
  {
    code: '140681',
    name: '怀仁市',
    city_level: 3,
    lng: '113.118504',
    lat: '39.810401'
  },
  {
    code: '140700',
    name: '晋中市',
    city_level: 2,
    lng: '112.752694',
    lat: '37.687024'
  },
  {
    code: '140702',
    name: '榆次区',
    city_level: 3,
    lng: '112.708241',
    lat: '37.697792'
  },
  {
    code: '140703',
    name: '太谷区',
    city_level: 2,
    lng: '112.752694',
    lat: '37.687024'
  },
  {
    code: '140721',
    name: '榆社县',
    city_level: 3,
    lng: '112.975287',
    lat: '37.070788'
  },
  {
    code: '140722',
    name: '左权县',
    city_level: 3,
    lng: '113.379372',
    lat: '37.082681'
  },
  {
    code: '140723',
    name: '和顺县',
    city_level: 3,
    lng: '113.570438',
    lat: '37.329664'
  },
  {
    code: '140724',
    name: '昔阳县',
    city_level: 3,
    lng: '113.706875',
    lat: '37.611210'
  },
  {
    code: '140725',
    name: '寿阳县',
    city_level: 3,
    lng: '113.176434',
    lat: '37.895325'
  },
  {
    code: '140727',
    name: '祁县',
    city_level: 3,
    lng: '112.335297',
    lat: '37.358317'
  },
  {
    code: '140728',
    name: '平遥县',
    city_level: 3,
    lng: '112.176273',
    lat: '37.189559'
  },
  {
    code: '140729',
    name: '灵石县',
    city_level: 3,
    lng: '111.778685',
    lat: '36.847860'
  },
  {
    code: '140781',
    name: '介休市',
    city_level: 3,
    lng: '111.916712',
    lat: '37.026945'
  },
  {
    code: '140800',
    name: '运城市',
    city_level: 2,
    lng: '111.007528',
    lat: '35.026412'
  },
  {
    code: '140802',
    name: '盐湖区',
    city_level: 3,
    lng: '110.998272',
    lat: '35.015101'
  },
  {
    code: '140821',
    name: '临猗县',
    city_level: 3,
    lng: '110.774547',
    lat: '35.144277'
  },
  {
    code: '140822',
    name: '万荣县',
    city_level: 3,
    lng: '110.838024',
    lat: '35.415254'
  },
  {
    code: '140823',
    name: '闻喜县',
    city_level: 3,
    lng: '111.224720',
    lat: '35.356644'
  },
  {
    code: '140824',
    name: '稷山县',
    city_level: 3,
    lng: '110.983333',
    lat: '35.604025'
  },
  {
    code: '140825',
    name: '新绛县',
    city_level: 3,
    lng: '111.224778',
    lat: '35.616288'
  },
  {
    code: '140826',
    name: '绛县',
    city_level: 3,
    lng: '111.568236',
    lat: '35.491190'
  },
  {
    code: '140827',
    name: '垣曲县',
    city_level: 3,
    lng: '111.669917',
    lat: '35.297620'
  },
  {
    code: '140828',
    name: '夏县',
    city_level: 3,
    lng: '111.220456',
    lat: '35.141363'
  },
  {
    code: '140829',
    name: '平陆县',
    city_level: 3,
    lng: '111.194133',
    lat: '34.829260'
  },
  {
    code: '140830',
    name: '芮城县',
    city_level: 3,
    lng: '110.694369',
    lat: '34.693580'
  },
  {
    code: '140881',
    name: '永济市',
    city_level: 3,
    lng: '110.447549',
    lat: '34.867050'
  },
  {
    code: '140882',
    name: '河津市',
    city_level: 3,
    lng: '110.712063',
    lat: '35.596383'
  },
  {
    code: '140900',
    name: '忻州市',
    city_level: 2,
    lng: '112.734174',
    lat: '38.416663'
  },
  {
    code: '140902',
    name: '忻府区',
    city_level: 3,
    lng: '112.746046',
    lat: '38.404243'
  },
  {
    code: '140921',
    name: '定襄县',
    city_level: 3,
    lng: '112.957215',
    lat: '38.473548'
  },
  {
    code: '140922',
    name: '五台县',
    city_level: 3,
    lng: '113.255309',
    lat: '38.728315'
  },
  {
    code: '140923',
    name: '代县',
    city_level: 3,
    lng: '112.960282',
    lat: '39.066917'
  },
  {
    code: '140924',
    name: '繁峙县',
    city_level: 3,
    lng: '113.265564',
    lat: '39.188811'
  },
  {
    code: '140925',
    name: '宁武县',
    city_level: 3,
    lng: '112.304722',
    lat: '39.001524'
  },
  {
    code: '140926',
    name: '静乐县',
    city_level: 3,
    lng: '111.939440',
    lat: '38.359036'
  },
  {
    code: '140927',
    name: '神池县',
    city_level: 3,
    lng: '112.211297',
    lat: '39.090553'
  },
  {
    code: '140928',
    name: '五寨县',
    city_level: 3,
    lng: '111.846905',
    lat: '38.910726'
  },
  {
    code: '140929',
    name: '岢岚县',
    city_level: 3,
    lng: '111.572850',
    lat: '38.704180'
  },
  {
    code: '140930',
    name: '河曲县',
    city_level: 3,
    lng: '111.138472',
    lat: '39.384482'
  },
  {
    code: '140931',
    name: '保德县',
    city_level: 3,
    lng: '111.086564',
    lat: '39.022488'
  },
  {
    code: '140932',
    name: '偏关县',
    city_level: 3,
    lng: '111.508831',
    lat: '39.436306'
  },
  {
    code: '140981',
    name: '原平市',
    city_level: 3,
    lng: '112.711059',
    lat: '38.731402'
  },
  {
    code: '141000',
    name: '临汾市',
    city_level: 2,
    lng: '111.518976',
    lat: '36.088005'
  },
  {
    code: '141002',
    name: '尧都区',
    city_level: 3,
    lng: '111.579554',
    lat: '36.078841'
  },
  {
    code: '141021',
    name: '曲沃县',
    city_level: 3,
    lng: '111.475861',
    lat: '35.641087'
  },
  {
    code: '141022',
    name: '翼城县',
    city_level: 3,
    lng: '111.718951',
    lat: '35.738576'
  },
  {
    code: '141023',
    name: '襄汾县',
    city_level: 3,
    lng: '111.441725',
    lat: '35.876293'
  },
  {
    code: '141024',
    name: '洪洞县',
    city_level: 3,
    lng: '111.674966',
    lat: '36.253748'
  },
  {
    code: '141025',
    name: '古县',
    city_level: 3,
    lng: '111.920466',
    lat: '36.266914'
  },
  {
    code: '141026',
    name: '安泽县',
    city_level: 3,
    lng: '112.250144',
    lat: '36.147787'
  },
  {
    code: '141027',
    name: '浮山县',
    city_level: 3,
    lng: '111.848883',
    lat: '35.968124'
  },
  {
    code: '141028',
    name: '吉县',
    city_level: 3,
    lng: '110.681763',
    lat: '36.098188'
  },
  {
    code: '141029',
    name: '乡宁县',
    city_level: 3,
    lng: '110.847021',
    lat: '35.970389'
  },
  {
    code: '141030',
    name: '大宁县',
    city_level: 3,
    lng: '110.752903',
    lat: '36.465133'
  },
  {
    code: '141031',
    name: '隰县',
    city_level: 3,
    lng: '110.940638',
    lat: '36.693331'
  },
  {
    code: '141032',
    name: '永和县',
    city_level: 3,
    lng: '110.632007',
    lat: '36.759507'
  },
  {
    code: '141033',
    name: '蒲县',
    city_level: 3,
    lng: '111.096439',
    lat: '36.411827'
  },
  {
    code: '141034',
    name: '汾西县',
    city_level: 3,
    lng: '111.563951',
    lat: '36.652854'
  },
  {
    code: '141081',
    name: '侯马市',
    city_level: 3,
    lng: '111.372002',
    lat: '35.619105'
  },
  {
    code: '141082',
    name: '霍州市',
    city_level: 3,
    lng: '111.755398',
    lat: '36.568931'
  },
  {
    code: '141100',
    name: '吕梁市',
    city_level: 2,
    lng: '111.144319',
    lat: '37.518314'
  },
  {
    code: '141102',
    name: '离石区',
    city_level: 3,
    lng: '111.150733',
    lat: '37.517641'
  },
  {
    code: '141121',
    name: '文水县',
    city_level: 3,
    lng: '112.028866',
    lat: '37.438102'
  },
  {
    code: '141122',
    name: '交城县',
    city_level: 3,
    lng: '112.155841',
    lat: '37.551970'
  },
  {
    code: '141123',
    name: '兴县',
    city_level: 3,
    lng: '111.127668',
    lat: '38.462390'
  },
  {
    code: '141124',
    name: '临县',
    city_level: 3,
    lng: '110.992094',
    lat: '37.950758'
  },
  {
    code: '141125',
    name: '柳林县',
    city_level: 3,
    lng: '110.889071',
    lat: '37.429832'
  },
  {
    code: '141126',
    name: '石楼县',
    city_level: 3,
    lng: '110.834561',
    lat: '36.997412'
  },
  {
    code: '141127',
    name: '岚县',
    city_level: 3,
    lng: '111.671917',
    lat: '38.279299'
  },
  {
    code: '141128',
    name: '方山县',
    city_level: 3,
    lng: '111.244098',
    lat: '37.894631'
  },
  {
    code: '141129',
    name: '中阳县',
    city_level: 3,
    lng: '111.179657',
    lat: '37.357058'
  },
  {
    code: '141130',
    name: '交口县',
    city_level: 3,
    lng: '111.181151',
    lat: '36.982186'
  },
  {
    code: '141181',
    name: '孝义市',
    city_level: 3,
    lng: '111.778818',
    lat: '37.146294'
  },
  {
    code: '141182',
    name: '汾阳市',
    city_level: 3,
    lng: '111.769894',
    lat: '37.261564'
  },
  {
    code: '150000',
    name: '内蒙古自治区',
    city_level: 1,
    lng: '111.765617',
    lat: '40.817498'
  },
  {
    code: '150100',
    name: '呼和浩特市',
    city_level: 2,
    lng: '111.749180',
    lat: '40.842585'
  },
  {
    code: '150102',
    name: '新城区',
    city_level: 3,
    lng: '108.960747',
    lat: '34.266451'
  },
  {
    code: '150103',
    name: '回民区',
    city_level: 3,
    lng: '111.623692',
    lat: '40.808608'
  },
  {
    code: '150104',
    name: '玉泉区',
    city_level: 3,
    lng: '111.673881',
    lat: '40.753655'
  },
  {
    code: '150105',
    name: '赛罕区',
    city_level: 3,
    lng: '111.701857',
    lat: '40.792097'
  },
  {
    code: '150121',
    name: '土默特左旗',
    city_level: 3,
    lng: '111.163902',
    lat: '40.729573'
  },
  {
    code: '150122',
    name: '托克托县',
    city_level: 3,
    lng: '111.194313',
    lat: '40.277431'
  },
  {
    code: '150123',
    name: '和林格尔县',
    city_level: 3,
    lng: '111.821843',
    lat: '40.378787'
  },
  {
    code: '150124',
    name: '清水河县',
    city_level: 3,
    lng: '111.647609',
    lat: '39.921095'
  },
  {
    code: '150125',
    name: '武川县',
    city_level: 3,
    lng: '111.451303',
    lat: '41.096471'
  },
  {
    code: '150200',
    name: '包头市',
    city_level: 2,
    lng: '109.840347',
    lat: '40.657449'
  },
  {
    code: '150202',
    name: '东河区',
    city_level: 3,
    lng: '110.044142',
    lat: '40.575948'
  },
  {
    code: '150203',
    name: '昆都仑区',
    city_level: 3,
    lng: '109.838178',
    lat: '40.642236'
  },
  {
    code: '150204',
    name: '青山区',
    city_level: 3,
    lng: '114.385539',
    lat: '30.639630'
  },
  {
    code: '150205',
    name: '石拐区',
    city_level: 3,
    lng: '110.060686',
    lat: '40.676645'
  },
  {
    code: '150206',
    name: '白云鄂博矿区',
    city_level: 2,
    lng: '109.840347',
    lat: '40.657449'
  },
  {
    code: '150207',
    name: '九原区',
    city_level: 3,
    lng: '109.968122',
    lat: '40.600581'
  },
  {
    code: '150221',
    name: '土默特右旗',
    city_level: 3,
    lng: '110.524263',
    lat: '40.569426'
  },
  {
    code: '150222',
    name: '固阳县',
    city_level: 3,
    lng: '110.060514',
    lat: '41.034106'
  },
  {
    code: '150223',
    name: '达尔罕茂明安联合旗',
    city_level: 3,
    lng: '110.432626',
    lat: '41.698992'
  },
  {
    code: '150300',
    name: '乌海市',
    city_level: 2,
    lng: '106.794249',
    lat: '39.655388'
  },
  {
    code: '150302',
    name: '海勃湾区',
    city_level: 3,
    lng: '106.822779',
    lat: '39.691156'
  },
  {
    code: '150303',
    name: '海南区',
    city_level: 3,
    lng: '106.891424',
    lat: '39.441364'
  },
  {
    code: '150304',
    name: '乌达区',
    city_level: 3,
    lng: '106.726099',
    lat: '39.505925'
  },
  {
    code: '150400',
    name: '赤峰市',
    city_level: 2,
    lng: '118.886856',
    lat: '42.257817'
  },
  {
    code: '150402',
    name: '红山区',
    city_level: 3,
    lng: '118.967296',
    lat: '42.271963'
  },
  {
    code: '150403',
    name: '元宝山区',
    city_level: 3,
    lng: '119.288611',
    lat: '42.038902'
  },
  {
    code: '150404',
    name: '松山区',
    city_level: 3,
    lng: '118.933235',
    lat: '42.284008'
  },
  {
    code: '150421',
    name: '阿鲁科尔沁旗',
    city_level: 3,
    lng: '120.065700',
    lat: '43.872299'
  },
  {
    code: '150422',
    name: '巴林左旗',
    city_level: 3,
    lng: '119.379490',
    lat: '43.971126'
  },
  {
    code: '150423',
    name: '巴林右旗',
    city_level: 3,
    lng: '118.665180',
    lat: '43.534414'
  },
  {
    code: '150424',
    name: '林西县',
    city_level: 3,
    lng: '118.055450',
    lat: '43.618120'
  },
  {
    code: '150425',
    name: '克什克腾旗',
    city_level: 3,
    lng: '117.545798',
    lat: '43.264989'
  },
  {
    code: '150426',
    name: '翁牛特旗',
    city_level: 3,
    lng: '119.006580',
    lat: '42.936188'
  },
  {
    code: '150428',
    name: '喀喇沁旗',
    city_level: 3,
    lng: '118.701938',
    lat: '41.927364'
  },
  {
    code: '150429',
    name: '宁城县',
    city_level: 3,
    lng: '118.557602',
    lat: '41.415477'
  },
  {
    code: '150430',
    name: '敖汉旗',
    city_level: 3,
    lng: '119.921604',
    lat: '42.290782'
  },
  {
    code: '150500',
    name: '通辽市',
    city_level: 2,
    lng: '122.243444',
    lat: '43.652890'
  },
  {
    code: '150502',
    name: '科尔沁区',
    city_level: 3,
    lng: '122.255675',
    lat: '43.623077'
  },
  {
    code: '150521',
    name: '科尔沁左翼中旗',
    city_level: 3,
    lng: '123.312265',
    lat: '44.126626'
  },
  {
    code: '150522',
    name: '科尔沁左翼后旗',
    city_level: 3,
    lng: '122.356749',
    lat: '42.935159'
  },
  {
    code: '150523',
    name: '开鲁县',
    city_level: 3,
    lng: '121.319309',
    lat: '43.601244'
  },
  {
    code: '150524',
    name: '库伦旗',
    city_level: 3,
    lng: '121.810701',
    lat: '42.735657'
  },
  {
    code: '150525',
    name: '奈曼旗',
    city_level: 3,
    lng: '120.662419',
    lat: '42.845455'
  },
  {
    code: '150526',
    name: '扎鲁特旗',
    city_level: 3,
    lng: '120.911676',
    lat: '44.556389'
  },
  {
    code: '150581',
    name: '霍林郭勒市',
    city_level: 3,
    lng: '119.663534',
    lat: '45.531726'
  },
  {
    code: '150600',
    name: '鄂尔多斯市',
    city_level: 2,
    lng: '109.781327',
    lat: '39.608266'
  },
  {
    code: '150602',
    name: '东胜区',
    city_level: 3,
    lng: '109.963339',
    lat: '39.822507'
  },
  {
    code: '150603',
    name: '康巴什区',
    city_level: 2,
    lng: '109.781327',
    lat: '39.608266'
  },
  {
    code: '150621',
    name: '达拉特旗',
    city_level: 3,
    lng: '110.033833',
    lat: '40.412438'
  },
  {
    code: '150622',
    name: '准格尔旗',
    city_level: 3,
    lng: '111.240171',
    lat: '39.864362'
  },
  {
    code: '150623',
    name: '鄂托克前旗',
    city_level: 3,
    lng: '107.477515',
    lat: '38.182362'
  },
  {
    code: '150624',
    name: '鄂托克旗',
    city_level: 3,
    lng: '107.976161',
    lat: '39.089650'
  },
  {
    code: '150625',
    name: '杭锦旗',
    city_level: 3,
    lng: '108.736208',
    lat: '39.833309'
  },
  {
    code: '150626',
    name: '乌审旗',
    city_level: 3,
    lng: '108.817607',
    lat: '38.604136'
  },
  {
    code: '150627',
    name: '伊金霍洛旗',
    city_level: 3,
    lng: '109.747740',
    lat: '39.564660'
  },
  {
    code: '150700',
    name: '呼伦贝尔市',
    city_level: 2,
    lng: '119.765744',
    lat: '49.211574'
  },
  {
    code: '150702',
    name: '海拉尔区',
    city_level: 3,
    lng: '119.736279',
    lat: '49.212189'
  },
  {
    code: '150703',
    name: '扎赉诺尔区',
    city_level: 3,
    lng: '117.670248',
    lat: '49.510375'
  },
  {
    code: '150721',
    name: '阿荣旗',
    city_level: 3,
    lng: '123.459050',
    lat: '48.126585'
  },
  {
    code: '150722',
    name: '莫力达瓦达斡尔族自治旗',
    city_level: 3,
    lng: '124.519023',
    lat: '48.477729'
  },
  {
    code: '150723',
    name: '鄂伦春自治旗',
    city_level: 3,
    lng: '123.726201',
    lat: '50.591842'
  },
  {
    code: '150724',
    name: '鄂温克族自治旗',
    city_level: 3,
    lng: '119.755213',
    lat: '49.146588'
  },
  {
    code: '150725',
    name: '陈巴尔虎旗',
    city_level: 3,
    lng: '119.424026',
    lat: '49.328916'
  },
  {
    code: '150726',
    name: '新巴尔虎左旗',
    city_level: 3,
    lng: '118.269820',
    lat: '48.218241'
  },
  {
    code: '150727',
    name: '新巴尔虎右旗',
    city_level: 3,
    lng: '116.823690',
    lat: '48.672101'
  },
  {
    code: '150781',
    name: '满洲里市',
    city_level: 3,
    lng: '117.378530',
    lat: '49.597841'
  },
  {
    code: '150782',
    name: '牙克石市',
    city_level: 3,
    lng: '120.735775',
    lat: '49.293222'
  },
  {
    code: '150783',
    name: '扎兰屯市',
    city_level: 3,
    lng: '122.737467',
    lat: '48.013733'
  },
  {
    code: '150784',
    name: '额尔古纳市',
    city_level: 3,
    lng: '120.180506',
    lat: '50.243102'
  },
  {
    code: '150785',
    name: '根河市',
    city_level: 3,
    lng: '121.520388',
    lat: '50.780345'
  },
  {
    code: '150800',
    name: '巴彦淖尔市',
    city_level: 2,
    lng: '107.387657',
    lat: '40.743213'
  },
  {
    code: '150802',
    name: '临河区',
    city_level: 3,
    lng: '107.363919',
    lat: '40.751187'
  },
  {
    code: '150821',
    name: '五原县',
    city_level: 3,
    lng: '108.267562',
    lat: '41.088422'
  },
  {
    code: '150822',
    name: '磴口县',
    city_level: 3,
    lng: '107.008248',
    lat: '40.330524'
  },
  {
    code: '150823',
    name: '乌拉特前旗',
    city_level: 3,
    lng: '108.652119',
    lat: '40.737030'
  },
  {
    code: '150824',
    name: '乌拉特中旗',
    city_level: 3,
    lng: '108.513645',
    lat: '41.587732'
  },
  {
    code: '150825',
    name: '乌拉特后旗',
    city_level: 3,
    lng: '107.074621',
    lat: '41.084283'
  },
  {
    code: '150826',
    name: '杭锦后旗',
    city_level: 3,
    lng: '107.150909',
    lat: '40.885896'
  },
  {
    code: '150900',
    name: '乌兰察布市',
    city_level: 2,
    lng: '113.132585',
    lat: '40.994785'
  },
  {
    code: '150902',
    name: '集宁区',
    city_level: 3,
    lng: '113.123779',
    lat: '40.990689'
  },
  {
    code: '150921',
    name: '卓资县',
    city_level: 3,
    lng: '112.577528',
    lat: '40.894692'
  },
  {
    code: '150922',
    name: '化德县',
    city_level: 3,
    lng: '114.010438',
    lat: '41.904560'
  },
  {
    code: '150923',
    name: '商都县',
    city_level: 3,
    lng: '113.577816',
    lat: '41.562113'
  },
  {
    code: '150924',
    name: '兴和县',
    city_level: 3,
    lng: '113.834173',
    lat: '40.872301'
  },
  {
    code: '150925',
    name: '凉城县',
    city_level: 3,
    lng: '112.503971',
    lat: '40.531555'
  },
  {
    code: '150926',
    name: '察哈尔右翼前旗',
    city_level: 3,
    lng: '113.214733',
    lat: '40.785631'
  },
  {
    code: '150927',
    name: '察哈尔右翼中旗',
    city_level: 3,
    lng: '112.635577',
    lat: '41.277462'
  },
  {
    code: '150928',
    name: '察哈尔右翼后旗',
    city_level: 3,
    lng: '113.191035',
    lat: '41.436069'
  },
  {
    code: '150929',
    name: '四子王旗',
    city_level: 3,
    lng: '111.706618',
    lat: '41.533462'
  },
  {
    code: '150981',
    name: '丰镇市',
    city_level: 3,
    lng: '113.109892',
    lat: '40.436983'
  },
  {
    code: '152200',
    name: '兴安盟',
    city_level: 2,
    lng: '122.067042',
    lat: '46.077561'
  },
  {
    code: '152201',
    name: '乌兰浩特市',
    city_level: 3,
    lng: '122.093123',
    lat: '46.072732'
  },
  {
    code: '152202',
    name: '阿尔山市',
    city_level: 3,
    lng: '119.943575',
    lat: '47.177440'
  },
  {
    code: '152221',
    name: '科尔沁右翼前旗',
    city_level: 3,
    lng: '121.952550',
    lat: '46.079810'
  },
  {
    code: '152222',
    name: '科尔沁右翼中旗',
    city_level: 3,
    lng: '121.476530',
    lat: '45.060837'
  },
  {
    code: '152223',
    name: '扎赉特旗',
    city_level: 3,
    lng: '122.899656',
    lat: '46.723237'
  },
  {
    code: '152224',
    name: '突泉县',
    city_level: 3,
    lng: '121.593799',
    lat: '45.381930'
  },
  {
    code: '152500',
    name: '锡林郭勒盟',
    city_level: 2,
    lng: '116.048222',
    lat: '43.933454'
  },
  {
    code: '152501',
    name: '二连浩特市',
    city_level: 3,
    lng: '111.977943',
    lat: '43.653170'
  },
  {
    code: '152502',
    name: '锡林浩特市',
    city_level: 3,
    lng: '116.086032',
    lat: '43.933411'
  },
  {
    code: '152522',
    name: '阿巴嘎旗',
    city_level: 3,
    lng: '114.950248',
    lat: '44.022995'
  },
  {
    code: '152523',
    name: '苏尼特左旗',
    city_level: 3,
    lng: '113.667248',
    lat: '43.859880'
  },
  {
    code: '152524',
    name: '苏尼特右旗',
    city_level: 3,
    lng: '112.641783',
    lat: '42.742892'
  },
  {
    code: '152525',
    name: '东乌珠穆沁旗',
    city_level: 3,
    lng: '116.974494',
    lat: '45.498221'
  },
  {
    code: '152526',
    name: '西乌珠穆沁旗',
    city_level: 3,
    lng: '117.608911',
    lat: '44.587882'
  },
  {
    code: '152527',
    name: '太仆寺旗',
    city_level: 3,
    lng: '115.282986',
    lat: '41.877136'
  },
  {
    code: '152528',
    name: '镶黄旗',
    city_level: 3,
    lng: '113.847287',
    lat: '42.232371'
  },
  {
    code: '152529',
    name: '正镶白旗',
    city_level: 3,
    lng: '115.029849',
    lat: '42.287471'
  },
  {
    code: '152530',
    name: '正蓝旗',
    city_level: 3,
    lng: '115.992470',
    lat: '42.241638'
  },
  {
    code: '152531',
    name: '多伦县',
    city_level: 3,
    lng: '116.485556',
    lat: '42.203591'
  },
  {
    code: '152900',
    name: '阿拉善盟',
    city_level: 2,
    lng: '105.728969',
    lat: '38.851892'
  },
  {
    code: '152921',
    name: '阿拉善左旗',
    city_level: 3,
    lng: '105.666293',
    lat: '38.833411'
  },
  {
    code: '152922',
    name: '阿拉善右旗',
    city_level: 3,
    lng: '101.666917',
    lat: '39.216186'
  },
  {
    code: '152923',
    name: '额济纳旗',
    city_level: 3,
    lng: '101.055590',
    lat: '41.954347'
  },
  {
    code: '210000',
    name: '辽宁省',
    city_level: 1,
    lng: '123.429440',
    lat: '41.835441'
  },
  {
    code: '210100',
    name: '沈阳市',
    city_level: 2,
    lng: '123.431474',
    lat: '41.805698'
  },
  {
    code: '210102',
    name: '和平区',
    city_level: 3,
    lng: '123.395680',
    lat: '41.789450'
  },
  {
    code: '210103',
    name: '沈河区',
    city_level: 3,
    lng: '123.458897',
    lat: '41.795655'
  },
  {
    code: '210104',
    name: '大东区',
    city_level: 3,
    lng: '123.508858',
    lat: '41.813170'
  },
  {
    code: '210105',
    name: '皇姑区',
    city_level: 3,
    lng: '123.441970',
    lat: '41.824796'
  },
  {
    code: '210106',
    name: '铁西区',
    city_level: 3,
    lng: '124.345722',
    lat: '43.146155'
  },
  {
    code: '210111',
    name: '苏家屯区',
    city_level: 3,
    lng: '123.344031',
    lat: '41.664757'
  },
  {
    code: '210112',
    name: '浑南区',
    city_level: 3,
    lng: '123.449715',
    lat: '41.714914'
  },
  {
    code: '210113',
    name: '沈北新区',
    city_level: 3,
    lng: '123.583197',
    lat: '41.912487'
  },
  {
    code: '210114',
    name: '于洪区',
    city_level: 3,
    lng: '123.308136',
    lat: '41.793743'
  },
  {
    code: '210115',
    name: '辽中区',
    city_level: 3,
    lng: '122.765409',
    lat: '41.516827'
  },
  {
    code: '210123',
    name: '康平县',
    city_level: 3,
    lng: '123.355701',
    lat: '42.741005'
  },
  {
    code: '210124',
    name: '法库县',
    city_level: 3,
    lng: '123.440495',
    lat: '42.500730'
  },
  {
    code: '210181',
    name: '新民市',
    city_level: 3,
    lng: '122.725537',
    lat: '41.932648'
  },
  {
    code: '210200',
    name: '大连市',
    city_level: 2,
    lng: '121.614682',
    lat: '38.914003'
  },
  {
    code: '210202',
    name: '中山区',
    city_level: 3,
    lng: '121.644927',
    lat: '38.918574'
  },
  {
    code: '210203',
    name: '西岗区',
    city_level: 3,
    lng: '121.612325',
    lat: '38.914687'
  },
  {
    code: '210204',
    name: '沙河口区',
    city_level: 3,
    lng: '121.594200',
    lat: '38.904808'
  },
  {
    code: '210211',
    name: '甘井子区',
    city_level: 3,
    lng: '121.525461',
    lat: '38.953351'
  },
  {
    code: '210212',
    name: '旅顺口区',
    city_level: 3,
    lng: '121.261953',
    lat: '38.851705'
  },
  {
    code: '210213',
    name: '金州区',
    city_level: 3,
    lng: '121.782769',
    lat: '39.050460'
  },
  {
    code: '210214',
    name: '普兰店区',
    city_level: 3,
    lng: '122.261770',
    lat: '39.741886'
  },
  {
    code: '210224',
    name: '长海县',
    city_level: 3,
    lng: '122.588494',
    lat: '39.272728'
  },
  {
    code: '210281',
    name: '瓦房店市',
    city_level: 3,
    lng: '121.979603',
    lat: '39.627114'
  },
  {
    code: '210283',
    name: '庄河市',
    city_level: 3,
    lng: '122.967328',
    lat: '39.680811'
  },
  {
    code: '210300',
    name: '鞍山市',
    city_level: 2,
    lng: '122.994329',
    lat: '41.108647'
  },
  {
    code: '210302',
    name: '铁东区',
    city_level: 3,
    lng: '124.409622',
    lat: '43.162048'
  },
  {
    code: '210303',
    name: '铁西区',
    city_level: 3,
    lng: '124.345722',
    lat: '43.146155'
  },
  {
    code: '210304',
    name: '立山区',
    city_level: 3,
    lng: '123.029091',
    lat: '41.150401'
  },
  {
    code: '210311',
    name: '千山区',
    city_level: 3,
    lng: '122.949298',
    lat: '41.068909'
  },
  {
    code: '210321',
    name: '台安县',
    city_level: 3,
    lng: '122.436196',
    lat: '41.412768'
  },
  {
    code: '210323',
    name: '岫岩满族自治县',
    city_level: 3,
    lng: '123.280935',
    lat: '40.290880'
  },
  {
    code: '210381',
    name: '海城市',
    city_level: 3,
    lng: '122.685217',
    lat: '40.882377'
  },
  {
    code: '210400',
    name: '抚顺市',
    city_level: 2,
    lng: '123.957208',
    lat: '41.880872'
  },
  {
    code: '210402',
    name: '新抚区',
    city_level: 3,
    lng: '123.912861',
    lat: '41.862080'
  },
  {
    code: '210403',
    name: '东洲区',
    city_level: 3,
    lng: '124.038685',
    lat: '41.853192'
  },
  {
    code: '210404',
    name: '望花区',
    city_level: 3,
    lng: '123.784206',
    lat: '41.853646'
  },
  {
    code: '210411',
    name: '顺城区',
    city_level: 3,
    lng: '123.945040',
    lat: '41.883375'
  },
  {
    code: '210421',
    name: '抚顺县',
    city_level: 3,
    lng: '124.097979',
    lat: '41.922644'
  },
  {
    code: '210422',
    name: '新宾满族自治县',
    city_level: 3,
    lng: '125.039978',
    lat: '41.734256'
  },
  {
    code: '210423',
    name: '清原满族自治县',
    city_level: 3,
    lng: '124.924083',
    lat: '42.100539'
  },
  {
    code: '210500',
    name: '本溪市',
    city_level: 2,
    lng: '123.766485',
    lat: '41.294175'
  },
  {
    code: '210502',
    name: '平山区',
    city_level: 3,
    lng: '123.768926',
    lat: '41.299702'
  },
  {
    code: '210503',
    name: '溪湖区',
    city_level: 3,
    lng: '123.767647',
    lat: '41.329219'
  },
  {
    code: '210504',
    name: '明山区',
    city_level: 3,
    lng: '123.817212',
    lat: '41.308710'
  },
  {
    code: '210505',
    name: '南芬区',
    city_level: 3,
    lng: '123.744802',
    lat: '41.100445'
  },
  {
    code: '210521',
    name: '本溪满族自治县',
    city_level: 3,
    lng: '124.120436',
    lat: '41.301892'
  },
  {
    code: '210522',
    name: '桓仁满族自治县',
    city_level: 3,
    lng: '125.361007',
    lat: '41.267128'
  },
  {
    code: '210600',
    name: '丹东市',
    city_level: 2,
    lng: '124.354706',
    lat: '40.000499'
  },
  {
    code: '210602',
    name: '元宝区',
    city_level: 3,
    lng: '124.395757',
    lat: '40.136520'
  },
  {
    code: '210603',
    name: '振兴区',
    city_level: 3,
    lng: '124.360264',
    lat: '40.105194'
  },
  {
    code: '210604',
    name: '振安区',
    city_level: 3,
    lng: '124.428162',
    lat: '40.158267'
  },
  {
    code: '210624',
    name: '宽甸满族自治县',
    city_level: 3,
    lng: '124.783660',
    lat: '40.731317'
  },
  {
    code: '210681',
    name: '东港市',
    city_level: 3,
    lng: '124.152705',
    lat: '39.863008'
  },
  {
    code: '210682',
    name: '凤城市',
    city_level: 3,
    lng: '124.066919',
    lat: '40.452298'
  },
  {
    code: '210700',
    name: '锦州市',
    city_level: 2,
    lng: '121.127003',
    lat: '41.095119'
  },
  {
    code: '210702',
    name: '古塔区',
    city_level: 3,
    lng: '121.128279',
    lat: '41.117245'
  },
  {
    code: '210703',
    name: '凌河区',
    city_level: 3,
    lng: '121.150877',
    lat: '41.114990'
  },
  {
    code: '210711',
    name: '太和区',
    city_level: 3,
    lng: '121.103892',
    lat: '41.109147'
  },
  {
    code: '210726',
    name: '黑山县',
    city_level: 3,
    lng: '122.123443',
    lat: '41.666028'
  },
  {
    code: '210727',
    name: '义县',
    city_level: 3,
    lng: '121.239080',
    lat: '41.533087'
  },
  {
    code: '210781',
    name: '凌海市',
    city_level: 3,
    lng: '121.357730',
    lat: '41.173400'
  },
  {
    code: '210782',
    name: '北镇市',
    city_level: 3,
    lng: '121.795962',
    lat: '41.598764'
  },
  {
    code: '210800',
    name: '营口市',
    city_level: 2,
    lng: '122.235417',
    lat: '40.667012'
  },
  {
    code: '210802',
    name: '站前区',
    city_level: 3,
    lng: '122.258961',
    lat: '40.672670'
  },
  {
    code: '210803',
    name: '西市区',
    city_level: 3,
    lng: '122.206414',
    lat: '40.666408'
  },
  {
    code: '210804',
    name: '鲅鱼圈区',
    city_level: 3,
    lng: '122.107814',
    lat: '40.230636'
  },
  {
    code: '210811',
    name: '老边区',
    city_level: 3,
    lng: '122.379967',
    lat: '40.680303'
  },
  {
    code: '210881',
    name: '盖州市',
    city_level: 3,
    lng: '122.348936',
    lat: '40.400611'
  },
  {
    code: '210882',
    name: '大石桥市',
    city_level: 3,
    lng: '122.509131',
    lat: '40.644622'
  },
  {
    code: '210900',
    name: '阜新市',
    city_level: 2,
    lng: '121.670323',
    lat: '42.021619'
  },
  {
    code: '210902',
    name: '海州区',
    city_level: 3,
    lng: '119.193710',
    lat: '34.606756'
  },
  {
    code: '210903',
    name: '新邱区',
    city_level: 3,
    lng: '121.792535',
    lat: '42.087632'
  },
  {
    code: '210904',
    name: '太平区',
    city_level: 3,
    lng: '121.678512',
    lat: '42.010600'
  },
  {
    code: '210905',
    name: '清河门区',
    city_level: 3,
    lng: '121.416105',
    lat: '41.783100'
  },
  {
    code: '210911',
    name: '细河区',
    city_level: 3,
    lng: '121.680540',
    lat: '42.025495'
  },
  {
    code: '210921',
    name: '阜新蒙古族自治县',
    city_level: 3,
    lng: '121.757901',
    lat: '42.065175'
  },
  {
    code: '210922',
    name: '彰武县',
    city_level: 3,
    lng: '122.538793',
    lat: '42.386544'
  },
  {
    code: '211000',
    name: '辽阳市',
    city_level: 2,
    lng: '123.236944',
    lat: '41.267244'
  },
  {
    code: '211002',
    name: '白塔区',
    city_level: 3,
    lng: '123.174325',
    lat: '41.270347'
  },
  {
    code: '211003',
    name: '文圣区',
    city_level: 3,
    lng: '123.185386',
    lat: '41.262608'
  },
  {
    code: '211004',
    name: '宏伟区',
    city_level: 3,
    lng: '123.196672',
    lat: '41.217649'
  },
  {
    code: '211005',
    name: '弓长岭区',
    city_level: 3,
    lng: '123.419804',
    lat: '41.151847'
  },
  {
    code: '211011',
    name: '太子河区',
    city_level: 3,
    lng: '123.181782',
    lat: '41.253277'
  },
  {
    code: '211021',
    name: '辽阳县',
    city_level: 3,
    lng: '123.105695',
    lat: '41.205329'
  },
  {
    code: '211081',
    name: '灯塔市',
    city_level: 3,
    lng: '123.339312',
    lat: '41.426372'
  },
  {
    code: '211100',
    name: '盘锦市',
    city_level: 2,
    lng: '122.070714',
    lat: '41.119997'
  },
  {
    code: '211102',
    name: '双台子区',
    city_level: 3,
    lng: '122.060112',
    lat: '41.190606'
  },
  {
    code: '211103',
    name: '兴隆台区',
    city_level: 3,
    lng: '122.069897',
    lat: '41.158678'
  },
  {
    code: '211104',
    name: '大洼区',
    city_level: 2,
    lng: '122.070714',
    lat: '41.119997'
  },
  {
    code: '211122',
    name: '盘山县',
    city_level: 3,
    lng: '121.996499',
    lat: '41.242873'
  },
  {
    code: '211200',
    name: '铁岭市',
    city_level: 2,
    lng: '123.726166',
    lat: '42.223769'
  },
  {
    code: '211202',
    name: '银州区',
    city_level: 3,
    lng: '123.842305',
    lat: '42.286130'
  },
  {
    code: '211204',
    name: '清河区',
    city_level: 3,
    lng: '119.064767',
    lat: '33.602919'
  },
  {
    code: '211221',
    name: '铁岭县',
    city_level: 3,
    lng: '123.729004',
    lat: '42.223383'
  },
  {
    code: '211223',
    name: '西丰县',
    city_level: 3,
    lng: '124.727393',
    lat: '42.738030'
  },
  {
    code: '211224',
    name: '昌图县',
    city_level: 3,
    lng: '124.111100',
    lat: '42.785791'
  },
  {
    code: '211281',
    name: '调兵山市',
    city_level: 3,
    lng: '123.567117',
    lat: '42.467521'
  },
  {
    code: '211282',
    name: '开原市',
    city_level: 3,
    lng: '124.038268',
    lat: '42.546307'
  },
  {
    code: '211300',
    name: '朝阳市',
    city_level: 2,
    lng: '120.450372',
    lat: '41.573734'
  },
  {
    code: '211302',
    name: '双塔区',
    city_level: 3,
    lng: '120.453744',
    lat: '41.565628'
  },
  {
    code: '211303',
    name: '龙城区',
    city_level: 3,
    lng: '120.413376',
    lat: '41.576749'
  },
  {
    code: '211321',
    name: '朝阳县',
    city_level: 3,
    lng: '120.389862',
    lat: '41.497767'
  },
  {
    code: '211322',
    name: '建平县',
    city_level: 3,
    lng: '119.643280',
    lat: '41.403128'
  },
  {
    code: '211324',
    name: '喀喇沁左翼蒙古族自治县',
    city_level: 3,
    lng: '119.741223',
    lat: '41.128150'
  },
  {
    code: '211381',
    name: '北票市',
    city_level: 3,
    lng: '120.770730',
    lat: '41.800684'
  },
  {
    code: '211382',
    name: '凌源市',
    city_level: 3,
    lng: '119.401574',
    lat: '41.245445'
  },
  {
    code: '211400',
    name: '葫芦岛市',
    city_level: 2,
    lng: '120.836932',
    lat: '40.711052'
  },
  {
    code: '211402',
    name: '连山区',
    city_level: 3,
    lng: '120.869231',
    lat: '40.774461'
  },
  {
    code: '211403',
    name: '龙港区',
    city_level: 3,
    lng: '120.893786',
    lat: '40.735519'
  },
  {
    code: '211404',
    name: '南票区',
    city_level: 3,
    lng: '120.749728',
    lat: '41.107108'
  },
  {
    code: '211421',
    name: '绥中县',
    city_level: 3,
    lng: '120.344229',
    lat: '40.325659'
  },
  {
    code: '211422',
    name: '建昌县',
    city_level: 3,
    lng: '119.837124',
    lat: '40.824368'
  },
  {
    code: '211481',
    name: '兴城市',
    city_level: 3,
    lng: '120.728310',
    lat: '40.614762'
  },
  {
    code: '220000',
    name: '吉林省',
    city_level: 1,
    lng: '125.325990',
    lat: '43.896536'
  },
  {
    code: '220100',
    name: '长春市',
    city_level: 2,
    lng: '125.323544',
    lat: '43.817071'
  },
  {
    code: '220102',
    name: '南关区',
    city_level: 3,
    lng: '125.350173',
    lat: '43.863989'
  },
  {
    code: '220103',
    name: '宽城区',
    city_level: 3,
    lng: '125.326578',
    lat: '43.943614'
  },
  {
    code: '220104',
    name: '朝阳区',
    city_level: 3,
    lng: '125.288319',
    lat: '43.833513'
  },
  {
    code: '220105',
    name: '二道区',
    city_level: 3,
    lng: '125.374217',
    lat: '43.865596'
  },
  {
    code: '220106',
    name: '绿园区',
    city_level: 3,
    lng: '125.256136',
    lat: '43.880975'
  },
  {
    code: '220112',
    name: '双阳区',
    city_level: 3,
    lng: '125.664662',
    lat: '43.525311'
  },
  {
    code: '220113',
    name: '九台区',
    city_level: 3,
    lng: '125.839574',
    lat: '44.151742'
  },
  {
    code: '220122',
    name: '农安县',
    city_level: 3,
    lng: '125.184742',
    lat: '44.432889'
  },
  {
    code: '220182',
    name: '榆树市',
    city_level: 3,
    lng: '126.533146',
    lat: '44.840288'
  },
  {
    code: '220183',
    name: '德惠市',
    city_level: 3,
    lng: '125.728620',
    lat: '44.521785'
  },
  {
    code: '220184',
    name: '公主岭市',
    city_level: 3,
    lng: '124.822830',
    lat: '43.504687'
  },
  {
    code: '220200',
    name: '吉林市',
    city_level: 2,
    lng: '126.549576',
    lat: '43.837883'
  },
  {
    code: '220202',
    name: '昌邑区',
    city_level: 3,
    lng: '126.574710',
    lat: '43.881818'
  },
  {
    code: '220203',
    name: '龙潭区',
    city_level: 3,
    lng: '126.562197',
    lat: '43.910803'
  },
  {
    code: '220204',
    name: '船营区',
    city_level: 3,
    lng: '126.540966',
    lat: '43.833445'
  },
  {
    code: '220211',
    name: '丰满区',
    city_level: 3,
    lng: '126.562274',
    lat: '43.821601'
  },
  {
    code: '220221',
    name: '永吉县',
    city_level: 3,
    lng: '126.497741',
    lat: '43.672582'
  },
  {
    code: '220281',
    name: '蛟河市',
    city_level: 3,
    lng: '127.344501',
    lat: '43.723713'
  },
  {
    code: '220282',
    name: '桦甸市',
    city_level: 3,
    lng: '126.746310',
    lat: '42.972097'
  },
  {
    code: '220283',
    name: '舒兰市',
    city_level: 3,
    lng: '126.965607',
    lat: '44.406106'
  },
  {
    code: '220284',
    name: '磐石市',
    city_level: 3,
    lng: '126.060427',
    lat: '42.946285'
  },
  {
    code: '220300',
    name: '四平市',
    city_level: 2,
    lng: '124.350398',
    lat: '43.166419'
  },
  {
    code: '220302',
    name: '铁西区',
    city_level: 3,
    lng: '124.345722',
    lat: '43.146155'
  },
  {
    code: '220303',
    name: '铁东区',
    city_level: 3,
    lng: '124.409622',
    lat: '43.162048'
  },
  {
    code: '220322',
    name: '梨树县',
    city_level: 3,
    lng: '124.335390',
    lat: '43.307060'
  },
  {
    code: '220323',
    name: '伊通满族自治县',
    city_level: 3,
    lng: '125.305394',
    lat: '43.345754'
  },
  {
    code: '220382',
    name: '双辽市',
    city_level: 3,
    lng: '123.502724',
    lat: '43.518302'
  },
  {
    code: '220400',
    name: '辽源市',
    city_level: 2,
    lng: '125.143532',
    lat: '42.887918'
  },
  {
    code: '220402',
    name: '龙山区',
    city_level: 3,
    lng: '125.136452',
    lat: '42.901534'
  },
  {
    code: '220403',
    name: '西安区',
    city_level: 3,
    lng: '129.616121',
    lat: '44.577625'
  },
  {
    code: '220421',
    name: '东丰县',
    city_level: 3,
    lng: '125.530991',
    lat: '42.676935'
  },
  {
    code: '220422',
    name: '东辽县',
    city_level: 3,
    lng: '124.991521',
    lat: '42.926331'
  },
  {
    code: '220500',
    name: '通化市',
    city_level: 2,
    lng: '125.939697',
    lat: '41.728401'
  },
  {
    code: '220502',
    name: '东昌区',
    city_level: 3,
    lng: '125.955102',
    lat: '41.728498'
  },
  {
    code: '220503',
    name: '二道江区',
    city_level: 3,
    lng: '126.042535',
    lat: '41.774053'
  },
  {
    code: '220521',
    name: '通化县',
    city_level: 3,
    lng: '125.759259',
    lat: '41.679808'
  },
  {
    code: '220523',
    name: '辉南县',
    city_level: 3,
    lng: '126.046912',
    lat: '42.684993'
  },
  {
    code: '220524',
    name: '柳河县',
    city_level: 3,
    lng: '125.744735',
    lat: '42.284606'
  },
  {
    code: '220581',
    name: '梅河口市',
    city_level: 3,
    lng: '125.683832',
    lat: '42.530341'
  },
  {
    code: '220582',
    name: '集安市',
    city_level: 3,
    lng: '126.194031',
    lat: '41.125307'
  },
  {
    code: '220600',
    name: '白山市',
    city_level: 2,
    lng: '126.423587',
    lat: '41.939994'
  },
  {
    code: '220602',
    name: '浑江区',
    city_level: 3,
    lng: '126.416094',
    lat: '41.945409'
  },
  {
    code: '220605',
    name: '江源区',
    city_level: 3,
    lng: '126.591144',
    lat: '42.056773'
  },
  {
    code: '220621',
    name: '抚松县',
    city_level: 3,
    lng: '127.449764',
    lat: '42.221208'
  },
  {
    code: '220622',
    name: '靖宇县',
    city_level: 3,
    lng: '126.813625',
    lat: '42.388760'
  },
  {
    code: '220623',
    name: '长白朝鲜族自治县',
    city_level: 3,
    lng: '128.200789',
    lat: '41.420018'
  },
  {
    code: '220681',
    name: '临江市',
    city_level: 3,
    lng: '126.918087',
    lat: '41.811979'
  },
  {
    code: '220700',
    name: '松原市',
    city_level: 2,
    lng: '124.825117',
    lat: '45.141789'
  },
  {
    code: '220702',
    name: '宁江区',
    city_level: 3,
    lng: '124.817054',
    lat: '45.171792'
  },
  {
    code: '220721',
    name: '前郭尔罗斯蒙古族自治县',
    city_level: 3,
    lng: '124.823242',
    lat: '45.118030'
  },
  {
    code: '220722',
    name: '长岭县',
    city_level: 3,
    lng: '123.967484',
    lat: '44.275895'
  },
  {
    code: '220723',
    name: '乾安县',
    city_level: 3,
    lng: '124.041139',
    lat: '45.003774'
  },
  {
    code: '220781',
    name: '扶余市',
    city_level: 3,
    lng: '126.049774',
    lat: '44.988449'
  },
  {
    code: '220800',
    name: '白城市',
    city_level: 2,
    lng: '122.839024',
    lat: '45.619641'
  },
  {
    code: '220802',
    name: '洮北区',
    city_level: 3,
    lng: '122.851029',
    lat: '45.621716'
  },
  {
    code: '220821',
    name: '镇赉县',
    city_level: 3,
    lng: '123.199890',
    lat: '45.847435'
  },
  {
    code: '220822',
    name: '通榆县',
    city_level: 3,
    lng: '123.088239',
    lat: '44.812911'
  },
  {
    code: '220881',
    name: '洮南市',
    city_level: 3,
    lng: '122.783779',
    lat: '45.339113'
  },
  {
    code: '220882',
    name: '大安市',
    city_level: 3,
    lng: '124.292626',
    lat: '45.506996'
  },
  {
    code: '222400',
    name: '延边朝鲜族自治州',
    city_level: 2,
    lng: '129.508946',
    lat: '42.891253'
  },
  {
    code: '222401',
    name: '延吉市',
    city_level: 3,
    lng: '129.508946',
    lat: '42.891255'
  },
  {
    code: '222402',
    name: '图们市',
    city_level: 3,
    lng: '129.843710',
    lat: '42.968044'
  },
  {
    code: '222403',
    name: '敦化市',
    city_level: 3,
    lng: '128.232013',
    lat: '43.372413'
  },
  {
    code: '222404',
    name: '珲春市',
    city_level: 3,
    lng: '130.366036',
    lat: '42.862821'
  },
  {
    code: '222405',
    name: '龙井市',
    city_level: 3,
    lng: '129.427066',
    lat: '42.766311'
  },
  {
    code: '222406',
    name: '和龙市',
    city_level: 3,
    lng: '129.010106',
    lat: '42.546675'
  },
  {
    code: '222424',
    name: '汪清县',
    city_level: 3,
    lng: '129.771607',
    lat: '43.312522'
  },
  {
    code: '222426',
    name: '安图县',
    city_level: 3,
    lng: '128.899807',
    lat: '43.111964'
  },
  {
    code: '230000',
    name: '黑龙江省',
    city_level: 1,
    lng: '126.661669',
    lat: '45.742347'
  },
  {
    code: '230100',
    name: '哈尔滨市',
    city_level: 2,
    lng: '126.534967',
    lat: '45.803775'
  },
  {
    code: '230102',
    name: '道里区',
    city_level: 3,
    lng: '126.616957',
    lat: '45.755777'
  },
  {
    code: '230103',
    name: '南岗区',
    city_level: 3,
    lng: '126.668788',
    lat: '45.759970'
  },
  {
    code: '230104',
    name: '道外区',
    city_level: 3,
    lng: '126.649451',
    lat: '45.792113'
  },
  {
    code: '230108',
    name: '平房区',
    city_level: 2,
    lng: '126.534967',
    lat: '45.803775'
  },
  {
    code: '230109',
    name: '松北区',
    city_level: 3,
    lng: '126.510275',
    lat: '45.802756'
  },
  {
    code: '230110',
    name: '香坊区',
    city_level: 3,
    lng: '126.662593',
    lat: '45.707716'
  },
  {
    code: '230111',
    name: '呼兰区',
    city_level: 3,
    lng: '126.587709',
    lat: '45.889427'
  },
  {
    code: '230112',
    name: '阿城区',
    city_level: 3,
    lng: '126.958098',
    lat: '45.548670'
  },
  {
    code: '230113',
    name: '双城区',
    city_level: 3,
    lng: '126.312745',
    lat: '45.383263'
  },
  {
    code: '230123',
    name: '依兰县',
    city_level: 3,
    lng: '129.567985',
    lat: '46.324534'
  },
  {
    code: '230124',
    name: '方正县',
    city_level: 3,
    lng: '128.829536',
    lat: '45.851695'
  },
  {
    code: '230125',
    name: '宾县',
    city_level: 3,
    lng: '127.466634',
    lat: '45.745918'
  },
  {
    code: '230126',
    name: '巴彦县',
    city_level: 3,
    lng: '127.403182',
    lat: '46.085379'
  },
  {
    code: '230127',
    name: '木兰县',
    city_level: 3,
    lng: '128.043466',
    lat: '45.950582'
  },
  {
    code: '230128',
    name: '通河县',
    city_level: 3,
    lng: '128.749352',
    lat: '45.972425'
  },
  {
    code: '230129',
    name: '延寿县',
    city_level: 3,
    lng: '128.331644',
    lat: '45.451897'
  },
  {
    code: '230183',
    name: '尚志市',
    city_level: 3,
    lng: '128.009895',
    lat: '45.209586'
  },
  {
    code: '230184',
    name: '五常市',
    city_level: 3,
    lng: '127.167619',
    lat: '44.931992'
  },
  {
    code: '230200',
    name: '齐齐哈尔市',
    city_level: 2,
    lng: '123.918186',
    lat: '47.354348'
  },
  {
    code: '230202',
    name: '龙沙区',
    city_level: 3,
    lng: '123.957531',
    lat: '47.317309'
  },
  {
    code: '230203',
    name: '建华区',
    city_level: 3,
    lng: '123.955464',
    lat: '47.354364'
  },
  {
    code: '230204',
    name: '铁锋区',
    city_level: 3,
    lng: '123.978293',
    lat: '47.340518'
  },
  {
    code: '230205',
    name: '昂昂溪区',
    city_level: 3,
    lng: '123.822401',
    lat: '47.155160'
  },
  {
    code: '230206',
    name: '富拉尔基区',
    city_level: 3,
    lng: '123.629190',
    lat: '47.208843'
  },
  {
    code: '230207',
    name: '碾子山区',
    city_level: 3,
    lng: '122.887775',
    lat: '47.516872'
  },
  {
    code: '230208',
    name: '梅里斯达斡尔族区',
    city_level: 3,
    lng: '123.752910',
    lat: '47.309537'
  },
  {
    code: '230221',
    name: '龙江县',
    city_level: 3,
    lng: '123.205323',
    lat: '47.338665'
  },
  {
    code: '230223',
    name: '依安县',
    city_level: 3,
    lng: '125.306279',
    lat: '47.893548'
  },
  {
    code: '230224',
    name: '泰来县',
    city_level: 3,
    lng: '123.416631',
    lat: '46.393694'
  },
  {
    code: '230225',
    name: '甘南县',
    city_level: 3,
    lng: '123.507429',
    lat: '47.922406'
  },
  {
    code: '230227',
    name: '富裕县',
    city_level: 3,
    lng: '124.473924',
    lat: '47.774550'
  },
  {
    code: '230229',
    name: '克山县',
    city_level: 3,
    lng: '125.875705',
    lat: '48.037031'
  },
  {
    code: '230230',
    name: '克东县',
    city_level: 3,
    lng: '126.248721',
    lat: '48.042060'
  },
  {
    code: '230231',
    name: '拜泉县',
    city_level: 3,
    lng: '126.100213',
    lat: '47.595851'
  },
  {
    code: '230281',
    name: '讷河市',
    city_level: 3,
    lng: '124.884244',
    lat: '48.484099'
  },
  {
    code: '230300',
    name: '鸡西市',
    city_level: 2,
    lng: '130.969333',
    lat: '45.295075'
  },
  {
    code: '230302',
    name: '鸡冠区',
    city_level: 3,
    lng: '130.980894',
    lat: '45.304073'
  },
  {
    code: '230303',
    name: '恒山区',
    city_level: 3,
    lng: '130.904963',
    lat: '45.210668'
  },
  {
    code: '230304',
    name: '滴道区',
    city_level: 3,
    lng: '130.843613',
    lat: '45.348764'
  },
  {
    code: '230305',
    name: '梨树区',
    city_level: 3,
    lng: '130.696990',
    lat: '45.092046'
  },
  {
    code: '230306',
    name: '城子河区',
    city_level: 3,
    lng: '131.011304',
    lat: '45.336970'
  },
  {
    code: '230307',
    name: '麻山区',
    city_level: 3,
    lng: '130.478187',
    lat: '45.212088'
  },
  {
    code: '230321',
    name: '鸡东县',
    city_level: 3,
    lng: '131.124080',
    lat: '45.260412'
  },
  {
    code: '230381',
    name: '虎林市',
    city_level: 3,
    lng: '132.937210',
    lat: '45.762686'
  },
  {
    code: '230382',
    name: '密山市',
    city_level: 3,
    lng: '131.846636',
    lat: '45.529775'
  },
  {
    code: '230400',
    name: '鹤岗市',
    city_level: 2,
    lng: '130.297964',
    lat: '47.349916'
  },
  {
    code: '230402',
    name: '向阳区',
    city_level: 3,
    lng: '130.365346',
    lat: '46.807790'
  },
  {
    code: '230403',
    name: '工农区',
    city_level: 3,
    lng: '130.274684',
    lat: '47.318781'
  },
  {
    code: '230404',
    name: '南山区',
    city_level: 3,
    lng: '113.923552',
    lat: '22.528499'
  },
  {
    code: '230405',
    name: '兴安区',
    city_level: 3,
    lng: '130.239245',
    lat: '47.252850'
  },
  {
    code: '230406',
    name: '东山区',
    city_level: 3,
    lng: '130.317062',
    lat: '47.338535'
  },
  {
    code: '230407',
    name: '兴山区',
    city_level: 3,
    lng: '130.303574',
    lat: '47.357666'
  },
  {
    code: '230421',
    name: '萝北县',
    city_level: 3,
    lng: '130.828626',
    lat: '47.577495'
  },
  {
    code: '230422',
    name: '绥滨县',
    city_level: 3,
    lng: '131.852759',
    lat: '47.289116'
  },
  {
    code: '230500',
    name: '双鸭山市',
    city_level: 2,
    lng: '131.159133',
    lat: '46.646508'
  },
  {
    code: '230502',
    name: '尖山区',
    city_level: 3,
    lng: '131.158416',
    lat: '46.646350'
  },
  {
    code: '230503',
    name: '岭东区',
    city_level: 3,
    lng: '131.164724',
    lat: '46.592721'
  },
  {
    code: '230505',
    name: '四方台区',
    city_level: 3,
    lng: '131.334503',
    lat: '46.594316'
  },
  {
    code: '230506',
    name: '宝山区',
    city_level: 3,
    lng: '121.489431',
    lat: '31.405242'
  },
  {
    code: '230521',
    name: '集贤县',
    city_level: 3,
    lng: '131.140483',
    lat: '46.728377'
  },
  {
    code: '230522',
    name: '友谊县',
    city_level: 3,
    lng: '131.808064',
    lat: '46.767299'
  },
  {
    code: '230523',
    name: '宝清县',
    city_level: 3,
    lng: '132.197243',
    lat: '46.326925'
  },
  {
    code: '230524',
    name: '饶河县',
    city_level: 3,
    lng: '134.013872',
    lat: '46.798164'
  },
  {
    code: '230600',
    name: '大庆市',
    city_level: 2,
    lng: '125.103784',
    lat: '46.589309'
  },
  {
    code: '230602',
    name: '萨尔图区',
    city_level: 3,
    lng: '125.114643',
    lat: '46.596356'
  },
  {
    code: '230603',
    name: '龙凤区',
    city_level: 3,
    lng: '125.135326',
    lat: '46.562247'
  },
  {
    code: '230604',
    name: '让胡路区',
    city_level: 3,
    lng: '124.870597',
    lat: '46.652358'
  },
  {
    code: '230605',
    name: '红岗区',
    city_level: 3,
    lng: '124.891039',
    lat: '46.398418'
  },
  {
    code: '230606',
    name: '大同区',
    city_level: 3,
    lng: '124.812364',
    lat: '46.039828'
  },
  {
    code: '230621',
    name: '肇州县',
    city_level: 3,
    lng: '125.268643',
    lat: '45.699066'
  },
  {
    code: '230622',
    name: '肇源县',
    city_level: 3,
    lng: '125.078223',
    lat: '45.519320'
  },
  {
    code: '230623',
    name: '林甸县',
    city_level: 3,
    lng: '124.863603',
    lat: '47.171717'
  },
  {
    code: '230624',
    name: '杜尔伯特蒙古族自治县',
    city_level: 3,
    lng: '124.442572',
    lat: '46.862817'
  },
  {
    code: '230700',
    name: '伊春市',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536'
  },
  {
    code: '230717',
    name: '伊美区',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536'
  },
  {
    code: '230718',
    name: '乌翠区',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536'
  },
  {
    code: '230719',
    name: '友好区',
    city_level: 3,
    lng: '128.840750',
    lat: '47.853778'
  },
  {
    code: '230722',
    name: '嘉荫县',
    city_level: 3,
    lng: '130.403564',
    lat: '48.889109'
  },
  {
    code: '230723',
    name: '汤旺县',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536'
  },
  {
    code: '230724',
    name: '丰林县',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536'
  },
  {
    code: '230725',
    name: '大箐山县',
    city_level: 3,
    lng: '101.787804',
    lat: '23.163322'
  },
  {
    code: '230726',
    name: '南岔县',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536'
  },
  {
    code: '230751',
    name: '金林区',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536'
  },
  {
    code: '230781',
    name: '铁力市',
    city_level: 3,
    lng: '128.032422',
    lat: '46.986640'
  },
  {
    code: '230800',
    name: '佳木斯市',
    city_level: 2,
    lng: '130.318917',
    lat: '46.799922'
  },
  {
    code: '230803',
    name: '向阳区',
    city_level: 3,
    lng: '130.365346',
    lat: '46.807790'
  },
  {
    code: '230804',
    name: '前进区',
    city_level: 3,
    lng: '130.375063',
    lat: '46.814103'
  },
  {
    code: '230805',
    name: '东风区',
    city_level: 3,
    lng: '130.403664',
    lat: '46.822572'
  },
  {
    code: '230811',
    name: '郊区',
    city_level: 3,
    lng: '117.807070',
    lat: '30.908927'
  },
  {
    code: '230822',
    name: '桦南县',
    city_level: 3,
    lng: '130.553343',
    lat: '46.239185'
  },
  {
    code: '230826',
    name: '桦川县',
    city_level: 3,
    lng: '130.719081',
    lat: '47.023001'
  },
  {
    code: '230828',
    name: '汤原县',
    city_level: 3,
    lng: '129.905072',
    lat: '46.730706'
  },
  {
    code: '230881',
    name: '同江市',
    city_level: 3,
    lng: '132.510919',
    lat: '47.642707'
  },
  {
    code: '230882',
    name: '富锦市',
    city_level: 3,
    lng: '132.037686',
    lat: '47.250108'
  },
  {
    code: '230883',
    name: '抚远市',
    city_level: 3,
    lng: '134.307884',
    lat: '48.364687'
  },
  {
    code: '230900',
    name: '七台河市',
    city_level: 2,
    lng: '131.003138',
    lat: '45.771726'
  },
  {
    code: '230902',
    name: '新兴区',
    city_level: 3,
    lng: '130.932143',
    lat: '45.815930'
  },
  {
    code: '230903',
    name: '桃山区',
    city_level: 3,
    lng: '131.020275',
    lat: '45.765594'
  },
  {
    code: '230904',
    name: '茄子河区',
    city_level: 3,
    lng: '131.068075',
    lat: '45.785215'
  },
  {
    code: '230921',
    name: '勃利县',
    city_level: 3,
    lng: '130.592093',
    lat: '45.755507'
  },
  {
    code: '231000',
    name: '牡丹江市',
    city_level: 2,
    lng: '129.633168',
    lat: '44.551653'
  },
  {
    code: '231002',
    name: '东安区',
    city_level: 3,
    lng: '129.626642',
    lat: '44.581360'
  },
  {
    code: '231003',
    name: '阳明区',
    city_level: 3,
    lng: '129.635615',
    lat: '44.596104'
  },
  {
    code: '231004',
    name: '爱民区',
    city_level: 3,
    lng: '129.591657',
    lat: '44.596053'
  },
  {
    code: '231005',
    name: '西安区',
    city_level: 3,
    lng: '129.616121',
    lat: '44.577625'
  },
  {
    code: '231025',
    name: '林口县',
    city_level: 3,
    lng: '130.284033',
    lat: '45.278046'
  },
  {
    code: '231081',
    name: '绥芬河市',
    city_level: 3,
    lng: '131.152546',
    lat: '44.412309'
  },
  {
    code: '231083',
    name: '海林市',
    city_level: 3,
    lng: '129.380482',
    lat: '44.594213'
  },
  {
    code: '231084',
    name: '宁安市',
    city_level: 3,
    lng: '129.482851',
    lat: '44.340720'
  },
  {
    code: '231085',
    name: '穆棱市',
    city_level: 3,
    lng: '130.524437',
    lat: '44.918813'
  },
  {
    code: '231086',
    name: '东宁市',
    city_level: 3,
    lng: '131.122915',
    lat: '44.087585'
  },
  {
    code: '231100',
    name: '黑河市',
    city_level: 2,
    lng: '127.528560',
    lat: '50.245329'
  },
  {
    code: '231102',
    name: '爱辉区',
    city_level: 3,
    lng: '127.500451',
    lat: '50.252106'
  },
  {
    code: '231123',
    name: '逊克县',
    city_level: 3,
    lng: '128.478750',
    lat: '49.564252'
  },
  {
    code: '231124',
    name: '孙吴县',
    city_level: 3,
    lng: '127.336304',
    lat: '49.425652'
  },
  {
    code: '231181',
    name: '北安市',
    city_level: 3,
    lng: '126.491199',
    lat: '48.241374'
  },
  {
    code: '231182',
    name: '五大连池市',
    city_level: 3,
    lng: '126.205516',
    lat: '48.517257'
  },
  {
    code: '231183',
    name: '嫩江市',
    city_level: 3,
    lng: '125.229904',
    lat: '49.177460'
  },
  {
    code: '231200',
    name: '绥化市',
    city_level: 2,
    lng: '126.968887',
    lat: '46.653845'
  },
  {
    code: '231202',
    name: '北林区',
    city_level: 3,
    lng: '126.985593',
    lat: '46.637344'
  },
  {
    code: '231221',
    name: '望奎县',
    city_level: 3,
    lng: '126.486076',
    lat: '46.832719'
  },
  {
    code: '231222',
    name: '兰西县',
    city_level: 3,
    lng: '126.288113',
    lat: '46.252447'
  },
  {
    code: '231223',
    name: '青冈县',
    city_level: 3,
    lng: '126.113860',
    lat: '46.689671'
  },
  {
    code: '231224',
    name: '庆安县',
    city_level: 3,
    lng: '127.507825',
    lat: '46.880102'
  },
  {
    code: '231225',
    name: '明水县',
    city_level: 3,
    lng: '125.906301',
    lat: '47.173426'
  },
  {
    code: '231226',
    name: '绥棱县',
    city_level: 3,
    lng: '127.114832',
    lat: '47.236015'
  },
  {
    code: '231281',
    name: '安达市',
    city_level: 3,
    lng: '125.352188',
    lat: '46.423508'
  },
  {
    code: '231282',
    name: '肇东市',
    city_level: 3,
    lng: '125.961814',
    lat: '46.051126'
  },
  {
    code: '231283',
    name: '海伦市',
    city_level: 3,
    lng: '126.973143',
    lat: '47.461971'
  },
  {
    code: '232700',
    name: '大兴安岭地区',
    city_level: 2,
    lng: '124.711080',
    lat: '52.335206'
  },
  {
    code: '232701',
    name: '漠河市',
    city_level: 3,
    lng: '122.529619',
    lat: '52.970721'
  },
  {
    code: '232721',
    name: '呼玛县',
    city_level: 3,
    lng: '126.665319',
    lat: '51.725637'
  },
  {
    code: '232722',
    name: '塔河县',
    city_level: 3,
    lng: '124.709996',
    lat: '52.334457'
  },
  {
    code: '310000',
    name: '上海市',
    city_level: 1,
    lng: '121.473701',
    lat: '31.230416'
  },
  {
    code: '310101',
    name: '黄浦区',
    city_level: 3,
    lng: '121.469240',
    lat: '31.229860'
  },
  {
    code: '310104',
    name: '徐汇区',
    city_level: 3,
    lng: '121.437866',
    lat: '31.199190'
  },
  {
    code: '310105',
    name: '长宁区',
    city_level: 3,
    lng: '121.424624',
    lat: '31.220367'
  },
  {
    code: '310106',
    name: '静安区',
    city_level: 3,
    lng: '121.459384',
    lat: '31.247105'
  },
  {
    code: '310107',
    name: '普陀区',
    city_level: 3,
    lng: '122.323867',
    lat: '29.971760'
  },
  {
    code: '310109',
    name: '虹口区',
    city_level: 3,
    lng: '121.505133',
    lat: '31.264600'
  },
  {
    code: '310110',
    name: '杨浦区',
    city_level: 3,
    lng: '121.526077',
    lat: '31.259541'
  },
  {
    code: '310112',
    name: '闵行区',
    city_level: 3,
    lng: '121.381709',
    lat: '31.112813'
  },
  {
    code: '310113',
    name: '宝山区',
    city_level: 3,
    lng: '121.489431',
    lat: '31.405242'
  },
  {
    code: '310114',
    name: '嘉定区',
    city_level: 3,
    lng: '121.265300',
    lat: '31.375602'
  },
  {
    code: '310115',
    name: '浦东新区',
    city_level: 3,
    lng: '121.544379',
    lat: '31.221517'
  },
  {
    code: '310116',
    name: '金山区',
    city_level: 3,
    lng: '121.341970',
    lat: '30.741991'
  },
  {
    code: '310117',
    name: '松江区',
    city_level: 3,
    lng: '121.227747',
    lat: '31.032243'
  },
  {
    code: '310118',
    name: '青浦区',
    city_level: 3,
    lng: '121.124178',
    lat: '31.150681'
  },
  {
    code: '310120',
    name: '奉贤区',
    city_level: 3,
    lng: '121.474042',
    lat: '30.917795'
  },
  {
    code: '310151',
    name: '崇明区',
    city_level: 3,
    lng: '121.397514',
    lat: '31.626945'
  },
  {
    code: '320000',
    name: '江苏省',
    city_level: 1,
    lng: '118.763232',
    lat: '32.061707'
  },
  {
    code: '320100',
    name: '南京市',
    city_level: 2,
    lng: '118.796877',
    lat: '32.060255'
  },
  {
    code: '320102',
    name: '玄武区',
    city_level: 3,
    lng: '118.797861',
    lat: '32.048687'
  },
  {
    code: '320104',
    name: '秦淮区',
    city_level: 3,
    lng: '118.794744',
    lat: '32.039133'
  },
  {
    code: '320105',
    name: '建邺区',
    city_level: 3,
    lng: '118.731694',
    lat: '32.003552'
  },
  {
    code: '320106',
    name: '鼓楼区',
    city_level: 3,
    lng: '114.348307',
    lat: '34.788561'
  },
  {
    code: '320111',
    name: '浦口区',
    city_level: 3,
    lng: '118.627895',
    lat: '32.059093'
  },
  {
    code: '320113',
    name: '栖霞区',
    city_level: 3,
    lng: '118.909246',
    lat: '32.096228'
  },
  {
    code: '320114',
    name: '雨花台区',
    city_level: 3,
    lng: '118.779073',
    lat: '31.991347'
  },
  {
    code: '320115',
    name: '江宁区',
    city_level: 3,
    lng: '118.839685',
    lat: '31.953702'
  },
  {
    code: '320116',
    name: '六合区',
    city_level: 3,
    lng: '118.821401',
    lat: '32.322247'
  },
  {
    code: '320117',
    name: '溧水区',
    city_level: 3,
    lng: '119.028280',
    lat: '31.651133'
  },
  {
    code: '320118',
    name: '高淳区',
    city_level: 3,
    lng: '118.892085',
    lat: '31.328471'
  },
  {
    code: '320200',
    name: '无锡市',
    city_level: 2,
    lng: '120.311910',
    lat: '31.491169'
  },
  {
    code: '320205',
    name: '锡山区',
    city_level: 3,
    lng: '120.357858',
    lat: '31.589715'
  },
  {
    code: '320206',
    name: '惠山区',
    city_level: 3,
    lng: '120.298500',
    lat: '31.681012'
  },
  {
    code: '320211',
    name: '滨湖区',
    city_level: 3,
    lng: '120.271750',
    lat: '31.470483'
  },
  {
    code: '320213',
    name: '梁溪区',
    city_level: 3,
    lng: '120.296591',
    lat: '31.575719'
  },
  {
    code: '320214',
    name: '新吴区',
    city_level: 3,
    lng: '120.352778',
    lat: '31.550957'
  },
  {
    code: '320281',
    name: '江阴市',
    city_level: 3,
    lng: '120.284939',
    lat: '31.920658'
  },
  {
    code: '320282',
    name: '宜兴市',
    city_level: 3,
    lng: '119.823308',
    lat: '31.340637'
  },
  {
    code: '320300',
    name: '徐州市',
    city_level: 2,
    lng: '117.284124',
    lat: '34.205768'
  },
  {
    code: '320302',
    name: '鼓楼区',
    city_level: 3,
    lng: '114.348307',
    lat: '34.788561'
  },
  {
    code: '320303',
    name: '云龙区',
    city_level: 3,
    lng: '117.251560',
    lat: '34.253167'
  },
  {
    code: '320305',
    name: '贾汪区',
    city_level: 3,
    lng: '117.466687',
    lat: '34.435506'
  },
  {
    code: '320311',
    name: '泉山区',
    city_level: 3,
    lng: '117.193805',
    lat: '34.244258'
  },
  {
    code: '320312',
    name: '铜山区',
    city_level: 3,
    lng: '117.169421',
    lat: '34.180700'
  },
  {
    code: '320321',
    name: '丰县',
    city_level: 3,
    lng: '116.595391',
    lat: '34.693906'
  },
  {
    code: '320322',
    name: '沛县',
    city_level: 3,
    lng: '116.937532',
    lat: '34.721656'
  },
  {
    code: '320324',
    name: '睢宁县',
    city_level: 3,
    lng: '117.941563',
    lat: '33.912598'
  },
  {
    code: '320381',
    name: '新沂市',
    city_level: 3,
    lng: '118.354537',
    lat: '34.369580'
  },
  {
    code: '320382',
    name: '邳州市',
    city_level: 3,
    lng: '118.012531',
    lat: '34.338888'
  },
  {
    code: '320400',
    name: '常州市',
    city_level: 2,
    lng: '119.973987',
    lat: '31.810689'
  },
  {
    code: '320402',
    name: '天宁区',
    city_level: 3,
    lng: '119.974991',
    lat: '31.779619'
  },
  {
    code: '320404',
    name: '钟楼区',
    city_level: 3,
    lng: '119.902112',
    lat: '31.802192'
  },
  {
    code: '320411',
    name: '新北区',
    city_level: 3,
    lng: '119.972182',
    lat: '31.830641'
  },
  {
    code: '320412',
    name: '武进区',
    city_level: 3,
    lng: '119.942437',
    lat: '31.701188'
  },
  {
    code: '320413',
    name: '金坛区',
    city_level: 3,
    lng: '119.597897',
    lat: '31.723247'
  },
  {
    code: '320481',
    name: '溧阳市',
    city_level: 3,
    lng: '119.484211',
    lat: '31.416911'
  },
  {
    code: '320500',
    name: '苏州市',
    city_level: 2,
    lng: '120.585315',
    lat: '31.298886'
  },
  {
    code: '320505',
    name: '虎丘区',
    city_level: 3,
    lng: '120.566833',
    lat: '31.294845'
  },
  {
    code: '320506',
    name: '吴中区',
    city_level: 3,
    lng: '120.631898',
    lat: '31.264212'
  },
  {
    code: '320507',
    name: '相城区',
    city_level: 3,
    lng: '120.642663',
    lat: '31.369088'
  },
  {
    code: '320508',
    name: '姑苏区',
    city_level: 3,
    lng: '120.617296',
    lat: '31.336392'
  },
  {
    code: '320509',
    name: '吴江区',
    city_level: 3,
    lng: '120.645158',
    lat: '31.138677'
  },
  {
    code: '320581',
    name: '常熟市',
    city_level: 3,
    lng: '120.752481',
    lat: '31.654376'
  },
  {
    code: '320582',
    name: '张家港市',
    city_level: 3,
    lng: '120.479033',
    lat: '31.899861'
  },
  {
    code: '320583',
    name: '昆山市',
    city_level: 3,
    lng: '120.980737',
    lat: '31.385598'
  },
  {
    code: '320585',
    name: '太仓市',
    city_level: 3,
    lng: '121.130550',
    lat: '31.457735'
  },
  {
    code: '320600',
    name: '南通市',
    city_level: 2,
    lng: '120.894291',
    lat: '31.980171'
  },
  {
    code: '320612',
    name: '通州区',
    city_level: 3,
    lng: '121.075087',
    lat: '32.064161'
  },
  {
    code: '320613',
    name: '崇川区',
    city_level: 3,
    lng: '120.857434',
    lat: '32.009875'
  },
  {
    code: '320614',
    name: '海门区',
    city_level: 3,
    lng: '121.123088',
    lat: '31.883544'
  },
  {
    code: '320623',
    name: '如东县',
    city_level: 3,
    lng: '121.185201',
    lat: '32.331766'
  },
  {
    code: '320681',
    name: '启东市',
    city_level: 3,
    lng: '121.657441',
    lat: '31.808026'
  },
  {
    code: '320682',
    name: '如皋市',
    city_level: 3,
    lng: '120.574946',
    lat: '32.370557'
  },
  {
    code: '320685',
    name: '海安市',
    city_level: 3,
    lng: '120.465996',
    lat: '32.540287'
  },
  {
    code: '320700',
    name: '连云港市',
    city_level: 2,
    lng: '119.221611',
    lat: '34.596653'
  },
  {
    code: '320703',
    name: '连云区',
    city_level: 3,
    lng: '119.338788',
    lat: '34.760249'
  },
  {
    code: '320706',
    name: '海州区',
    city_level: 3,
    lng: '119.193710',
    lat: '34.606756'
  },
  {
    code: '320707',
    name: '赣榆区',
    city_level: 3,
    lng: '119.173331',
    lat: '34.841349'
  },
  {
    code: '320722',
    name: '东海县',
    city_level: 3,
    lng: '118.752842',
    lat: '34.542309'
  },
  {
    code: '320723',
    name: '灌云县',
    city_level: 3,
    lng: '119.239381',
    lat: '34.284381'
  },
  {
    code: '320724',
    name: '灌南县',
    city_level: 3,
    lng: '119.315651',
    lat: '34.087135'
  },
  {
    code: '320800',
    name: '淮安市',
    city_level: 2,
    lng: '119.015285',
    lat: '33.610353'
  },
  {
    code: '320803',
    name: '淮安区',
    city_level: 3,
    lng: '119.141099',
    lat: '33.502869'
  },
  {
    code: '320804',
    name: '淮阴区',
    city_level: 3,
    lng: '119.034725',
    lat: '33.631893'
  },
  {
    code: '320812',
    name: '清江浦区',
    city_level: 3,
    lng: '119.026716',
    lat: '33.552621'
  },
  {
    code: '320813',
    name: '洪泽区',
    city_level: 3,
    lng: '118.867874',
    lat: '33.294975'
  },
  {
    code: '320826',
    name: '涟水县',
    city_level: 3,
    lng: '119.260335',
    lat: '33.780960'
  },
  {
    code: '320830',
    name: '盱眙县',
    city_level: 3,
    lng: '118.544360',
    lat: '33.011971'
  },
  {
    code: '320831',
    name: '金湖县',
    city_level: 3,
    lng: '119.020585',
    lat: '33.025433'
  },
  {
    code: '320900',
    name: '盐城市',
    city_level: 2,
    lng: '120.163561',
    lat: '33.347382'
  },
  {
    code: '320902',
    name: '亭湖区',
    city_level: 3,
    lng: '120.197358',
    lat: '33.390536'
  },
  {
    code: '320903',
    name: '盐都区',
    city_level: 3,
    lng: '120.153898',
    lat: '33.338094'
  },
  {
    code: '320904',
    name: '大丰区',
    city_level: 3,
    lng: '120.500858',
    lat: '33.200331'
  },
  {
    code: '320921',
    name: '响水县',
    city_level: 3,
    lng: '119.578364',
    lat: '34.199479'
  },
  {
    code: '320922',
    name: '滨海县',
    city_level: 3,
    lng: '119.820831',
    lat: '33.990334'
  },
  {
    code: '320923',
    name: '阜宁县',
    city_level: 3,
    lng: '119.802527',
    lat: '33.759325'
  },
  {
    code: '320924',
    name: '射阳县',
    city_level: 3,
    lng: '120.258053',
    lat: '33.774806'
  },
  {
    code: '320925',
    name: '建湖县',
    city_level: 3,
    lng: '119.798581',
    lat: '33.464204'
  },
  {
    code: '320981',
    name: '东台市',
    city_level: 3,
    lng: '120.320331',
    lat: '32.868440'
  },
  {
    code: '321000',
    name: '扬州市',
    city_level: 2,
    lng: '119.412966',
    lat: '32.394210'
  },
  {
    code: '321002',
    name: '广陵区',
    city_level: 3,
    lng: '119.431849',
    lat: '32.394720'
  },
  {
    code: '321003',
    name: '邗江区',
    city_level: 3,
    lng: '119.398015',
    lat: '32.377528'
  },
  {
    code: '321012',
    name: '江都区',
    city_level: 3,
    lng: '119.569989',
    lat: '32.434672'
  },
  {
    code: '321023',
    name: '宝应县',
    city_level: 3,
    lng: '119.360729',
    lat: '33.240392'
  },
  {
    code: '321081',
    name: '仪征市',
    city_level: 3,
    lng: '119.184766',
    lat: '32.272258'
  },
  {
    code: '321084',
    name: '高邮市',
    city_level: 3,
    lng: '119.459161',
    lat: '32.781659'
  },
  {
    code: '321100',
    name: '镇江市',
    city_level: 2,
    lng: '119.425836',
    lat: '32.187849'
  },
  {
    code: '321102',
    name: '京口区',
    city_level: 3,
    lng: '119.470160',
    lat: '32.198280'
  },
  {
    code: '321111',
    name: '润州区',
    city_level: 3,
    lng: '119.411945',
    lat: '32.195333'
  },
  {
    code: '321112',
    name: '丹徒区',
    city_level: 3,
    lng: '119.433854',
    lat: '32.131962'
  },
  {
    code: '321181',
    name: '丹阳市',
    city_level: 3,
    lng: '119.606536',
    lat: '32.010117'
  },
  {
    code: '321182',
    name: '扬中市',
    city_level: 3,
    lng: '119.797635',
    lat: '32.234831'
  },
  {
    code: '321183',
    name: '句容市',
    city_level: 3,
    lng: '119.168695',
    lat: '31.944999'
  },
  {
    code: '321200',
    name: '泰州市',
    city_level: 2,
    lng: '119.923116',
    lat: '32.455778'
  },
  {
    code: '321202',
    name: '海陵区',
    city_level: 3,
    lng: '119.919425',
    lat: '32.491016'
  },
  {
    code: '321203',
    name: '高港区',
    city_level: 3,
    lng: '119.881717',
    lat: '32.318822'
  },
  {
    code: '321204',
    name: '姜堰区',
    city_level: 3,
    lng: '120.127934',
    lat: '32.509155'
  },
  {
    code: '321281',
    name: '兴化市',
    city_level: 3,
    lng: '119.852541',
    lat: '32.910459'
  },
  {
    code: '321282',
    name: '靖江市',
    city_level: 3,
    lng: '120.277138',
    lat: '31.982751'
  },
  {
    code: '321283',
    name: '泰兴市',
    city_level: 3,
    lng: '120.051744',
    lat: '32.171854'
  },
  {
    code: '321300',
    name: '宿迁市',
    city_level: 2,
    lng: '118.275198',
    lat: '33.963232'
  },
  {
    code: '321302',
    name: '宿城区',
    city_level: 3,
    lng: '118.242534',
    lat: '33.963029'
  },
  {
    code: '321311',
    name: '宿豫区',
    city_level: 3,
    lng: '118.330782',
    lat: '33.946822'
  },
  {
    code: '321322',
    name: '沭阳县',
    city_level: 3,
    lng: '118.804784',
    lat: '34.111022'
  },
  {
    code: '321323',
    name: '泗阳县',
    city_level: 3,
    lng: '118.703038',
    lat: '33.723140'
  },
  {
    code: '321324',
    name: '泗洪县',
    city_level: 3,
    lng: '118.223591',
    lat: '33.476051'
  },
  {
    code: '330000',
    name: '浙江省',
    city_level: 1,
    lng: '120.152791',
    lat: '30.267446'
  },
  {
    code: '330100',
    name: '杭州市',
    city_level: 2,
    lng: '120.155070',
    lat: '30.274084'
  },
  {
    code: '330102',
    name: '上城区',
    city_level: 3,
    lng: '120.184349',
    lat: '30.254460'
  },
  {
    code: '330103',
    name: '下城区',
    city_level: 3,
    lng: '120.180895',
    lat: '30.281714'
  },
  {
    code: '330104',
    name: '江干区',
    city_level: 3,
    lng: '120.213843',
    lat: '30.262661'
  },
  {
    code: '330105',
    name: '拱墅区',
    city_level: 3,
    lng: '120.141406',
    lat: '30.319037'
  },
  {
    code: '330106',
    name: '西湖区',
    city_level: 3,
    lng: '115.877233',
    lat: '28.657595'
  },
  {
    code: '330108',
    name: '滨江区',
    city_level: 3,
    lng: '120.146505',
    lat: '30.162450'
  },
  {
    code: '330109',
    name: '萧山区',
    city_level: 3,
    lng: '120.493286',
    lat: '30.283330'
  },
  {
    code: '330110',
    name: '余杭区',
    city_level: 3,
    lng: '120.245433',
    lat: '30.409974'
  },
  {
    code: '330111',
    name: '富阳区',
    city_level: 3,
    lng: '119.960076',
    lat: '30.048692'
  },
  {
    code: '330112',
    name: '临安区',
    city_level: 3,
    lng: '119.718891',
    lat: '30.237881'
  },
  {
    code: '330122',
    name: '桐庐县',
    city_level: 3,
    lng: '119.691434',
    lat: '29.793535'
  },
  {
    code: '330127',
    name: '淳安县',
    city_level: 3,
    lng: '119.041864',
    lat: '29.608772'
  },
  {
    code: '330182',
    name: '建德市',
    city_level: 3,
    lng: '119.281213',
    lat: '29.474884'
  },
  {
    code: '330200',
    name: '宁波市',
    city_level: 2,
    lng: '121.550357',
    lat: '29.874556'
  },
  {
    code: '330203',
    name: '海曙区',
    city_level: 3,
    lng: '121.551066',
    lat: '29.859772'
  },
  {
    code: '330205',
    name: '江北区',
    city_level: 3,
    lng: '106.574271',
    lat: '29.606703'
  },
  {
    code: '330206',
    name: '北仑区',
    city_level: 3,
    lng: '121.844618',
    lat: '29.899044'
  },
  {
    code: '330211',
    name: '镇海区',
    city_level: 3,
    lng: '121.716540',
    lat: '29.948998'
  },
  {
    code: '330212',
    name: '鄞州区',
    city_level: 3,
    lng: '121.546603',
    lat: '29.816511'
  },
  {
    code: '330213',
    name: '奉化区',
    city_level: 3,
    lng: '121.410889',
    lat: '29.662348'
  },
  {
    code: '330225',
    name: '象山县',
    city_level: 3,
    lng: '121.869339',
    lat: '29.476705'
  },
  {
    code: '330226',
    name: '宁海县',
    city_level: 3,
    lng: '121.429477',
    lat: '29.287939'
  },
  {
    code: '330281',
    name: '余姚市',
    city_level: 3,
    lng: '121.154594',
    lat: '30.037858'
  },
  {
    code: '330282',
    name: '慈溪市',
    city_level: 3,
    lng: '121.266579',
    lat: '30.169665'
  },
  {
    code: '330300',
    name: '温州市',
    city_level: 2,
    lng: '120.699366',
    lat: '27.994267'
  },
  {
    code: '330302',
    name: '鹿城区',
    city_level: 3,
    lng: '120.655135',
    lat: '28.015455'
  },
  {
    code: '330303',
    name: '龙湾区',
    city_level: 3,
    lng: '120.811213',
    lat: '27.932747'
  },
  {
    code: '330304',
    name: '瓯海区',
    city_level: 3,
    lng: '120.708827',
    lat: '27.914241'
  },
  {
    code: '330305',
    name: '洞头区',
    city_level: 3,
    lng: '121.157249',
    lat: '27.836154'
  },
  {
    code: '330324',
    name: '永嘉县',
    city_level: 3,
    lng: '120.682144',
    lat: '28.150591'
  },
  {
    code: '330326',
    name: '平阳县',
    city_level: 3,
    lng: '120.565793',
    lat: '27.661918'
  },
  {
    code: '330327',
    name: '苍南县',
    city_level: 3,
    lng: '120.425766',
    lat: '27.518280'
  },
  {
    code: '330328',
    name: '文成县',
    city_level: 3,
    lng: '120.091498',
    lat: '27.786996'
  },
  {
    code: '330329',
    name: '泰顺县',
    city_level: 3,
    lng: '119.717649',
    lat: '27.556884'
  },
  {
    code: '330381',
    name: '瑞安市',
    city_level: 3,
    lng: '120.655148',
    lat: '27.778657'
  },
  {
    code: '330382',
    name: '乐清市',
    city_level: 3,
    lng: '120.967147',
    lat: '28.116083'
  },
  {
    code: '330383',
    name: '龙港市',
    city_level: 3,
    lng: '120.537832',
    lat: '27.561541'
  },
  {
    code: '330400',
    name: '嘉兴市',
    city_level: 2,
    lng: '120.755486',
    lat: '30.746129'
  },
  {
    code: '330402',
    name: '南湖区',
    city_level: 3,
    lng: '120.783025',
    lat: '30.747842'
  },
  {
    code: '330411',
    name: '秀洲区',
    city_level: 3,
    lng: '120.709018',
    lat: '30.765168'
  },
  {
    code: '330421',
    name: '嘉善县',
    city_level: 3,
    lng: '120.925850',
    lat: '30.830898'
  },
  {
    code: '330424',
    name: '海盐县',
    city_level: 3,
    lng: '120.946263',
    lat: '30.526436'
  },
  {
    code: '330481',
    name: '海宁市',
    city_level: 3,
    lng: '120.680757',
    lat: '30.510659'
  },
  {
    code: '330482',
    name: '平湖市',
    city_level: 3,
    lng: '121.015142',
    lat: '30.677233'
  },
  {
    code: '330483',
    name: '桐乡市',
    city_level: 3,
    lng: '120.565099',
    lat: '30.630173'
  },
  {
    code: '330500',
    name: '湖州市',
    city_level: 2,
    lng: '120.086823',
    lat: '30.894348'
  },
  {
    code: '330502',
    name: '吴兴区',
    city_level: 3,
    lng: '120.185838',
    lat: '30.857151'
  },
  {
    code: '330503',
    name: '南浔区',
    city_level: 3,
    lng: '120.418512',
    lat: '30.849690'
  },
  {
    code: '330521',
    name: '德清县',
    city_level: 3,
    lng: '119.977401',
    lat: '30.542510'
  },
  {
    code: '330522',
    name: '长兴县',
    city_level: 3,
    lng: '119.910952',
    lat: '31.026666'
  },
  {
    code: '330523',
    name: '安吉县',
    city_level: 3,
    lng: '119.680353',
    lat: '30.638675'
  },
  {
    code: '330600',
    name: '绍兴市',
    city_level: 2,
    lng: '120.580232',
    lat: '30.029752'
  },
  {
    code: '330602',
    name: '越城区',
    city_level: 3,
    lng: '120.582633',
    lat: '29.988245'
  },
  {
    code: '330603',
    name: '柯桥区',
    city_level: 3,
    lng: '120.495065',
    lat: '30.081942'
  },
  {
    code: '330604',
    name: '上虞区',
    city_level: 3,
    lng: '120.868122',
    lat: '30.033121'
  },
  {
    code: '330624',
    name: '新昌县',
    city_level: 3,
    lng: '120.903866',
    lat: '29.499832'
  },
  {
    code: '330681',
    name: '诸暨市',
    city_level: 3,
    lng: '120.246863',
    lat: '29.708692'
  },
  {
    code: '330683',
    name: '嵊州市',
    city_level: 3,
    lng: '120.831025',
    lat: '29.561410'
  },
  {
    code: '330700',
    name: '金华市',
    city_level: 2,
    lng: '119.647444',
    lat: '29.079059'
  },
  {
    code: '330702',
    name: '婺城区',
    city_level: 3,
    lng: '119.571633',
    lat: '29.086221'
  },
  {
    code: '330703',
    name: '金东区',
    city_level: 3,
    lng: '119.692862',
    lat: '29.099122'
  },
  {
    code: '330723',
    name: '武义县',
    city_level: 3,
    lng: '119.816318',
    lat: '28.892721'
  },
  {
    code: '330726',
    name: '浦江县',
    city_level: 3,
    lng: '119.892222',
    lat: '29.452477'
  },
  {
    code: '330727',
    name: '磐安县',
    city_level: 3,
    lng: '120.450178',
    lat: '29.054048'
  },
  {
    code: '330781',
    name: '兰溪市',
    city_level: 3,
    lng: '119.460526',
    lat: '29.208919'
  },
  {
    code: '330782',
    name: '义乌市',
    city_level: 3,
    lng: '120.075058',
    lat: '29.306841'
  },
  {
    code: '330783',
    name: '东阳市',
    city_level: 3,
    lng: '120.241566',
    lat: '29.289648'
  },
  {
    code: '330784',
    name: '永康市',
    city_level: 2,
    lng: '119.647444',
    lat: '29.079059'
  },
  {
    code: '330800',
    name: '衢州市',
    city_level: 2,
    lng: '118.859457',
    lat: '28.970079'
  },
  {
    code: '330802',
    name: '柯城区',
    city_level: 3,
    lng: '118.871333',
    lat: '28.968504'
  },
  {
    code: '330803',
    name: '衢江区',
    city_level: 3,
    lng: '118.959460',
    lat: '28.979780'
  },
  {
    code: '330822',
    name: '常山县',
    city_level: 3,
    lng: '118.511249',
    lat: '28.901463'
  },
  {
    code: '330824',
    name: '开化县',
    city_level: 3,
    lng: '118.415495',
    lat: '29.137337'
  },
  {
    code: '330825',
    name: '龙游县',
    city_level: 3,
    lng: '119.172304',
    lat: '29.028319'
  },
  {
    code: '330881',
    name: '江山市',
    city_level: 3,
    lng: '118.626926',
    lat: '28.737245'
  },
  {
    code: '330900',
    name: '舟山市',
    city_level: 2,
    lng: '122.207215',
    lat: '29.985295'
  },
  {
    code: '330902',
    name: '定海区',
    city_level: 3,
    lng: '122.106773',
    lat: '30.019858'
  },
  {
    code: '330903',
    name: '普陀区',
    city_level: 3,
    lng: '122.323867',
    lat: '29.971760'
  },
  {
    code: '330921',
    name: '岱山县',
    city_level: 3,
    lng: '122.226237',
    lat: '30.264139'
  },
  {
    code: '330922',
    name: '嵊泗县',
    city_level: 3,
    lng: '122.451382',
    lat: '30.725686'
  },
  {
    code: '331000',
    name: '台州市',
    city_level: 2,
    lng: '121.420757',
    lat: '28.656386'
  },
  {
    code: '331002',
    name: '椒江区',
    city_level: 3,
    lng: '121.442676',
    lat: '28.673726'
  },
  {
    code: '331003',
    name: '黄岩区',
    city_level: 3,
    lng: '121.261893',
    lat: '28.650117'
  },
  {
    code: '331004',
    name: '路桥区',
    city_level: 3,
    lng: '121.365123',
    lat: '28.582655'
  },
  {
    code: '331022',
    name: '三门县',
    city_level: 3,
    lng: '121.395777',
    lat: '29.104873'
  },
  {
    code: '331023',
    name: '天台县',
    city_level: 3,
    lng: '121.006725',
    lat: '29.144079'
  },
  {
    code: '331024',
    name: '仙居县',
    city_level: 3,
    lng: '120.735081',
    lat: '28.849213'
  },
  {
    code: '331081',
    name: '温岭市',
    city_level: 3,
    lng: '121.385604',
    lat: '28.372506'
  },
  {
    code: '331082',
    name: '临海市',
    city_level: 3,
    lng: '121.145047',
    lat: '28.858457'
  },
  {
    code: '331083',
    name: '玉环市',
    city_level: 3,
    lng: '121.232338',
    lat: '28.128420'
  },
  {
    code: '331100',
    name: '丽水市',
    city_level: 2,
    lng: '119.922796',
    lat: '28.467630'
  },
  {
    code: '331102',
    name: '莲都区',
    city_level: 3,
    lng: '119.912612',
    lat: '28.445836'
  },
  {
    code: '331121',
    name: '青田县',
    city_level: 3,
    lng: '120.282989',
    lat: '28.134088'
  },
  {
    code: '331122',
    name: '缙云县',
    city_level: 3,
    lng: '120.091573',
    lat: '28.659279'
  },
  {
    code: '331123',
    name: '遂昌县',
    city_level: 3,
    lng: '119.276104',
    lat: '28.592119'
  },
  {
    code: '331124',
    name: '松阳县',
    city_level: 3,
    lng: '119.482015',
    lat: '28.449171'
  },
  {
    code: '331125',
    name: '云和县',
    city_level: 3,
    lng: '119.573397',
    lat: '28.115790'
  },
  {
    code: '331126',
    name: '庆元县',
    city_level: 3,
    lng: '119.062590',
    lat: '27.619220'
  },
  {
    code: '331127',
    name: '景宁畲族自治县',
    city_level: 3,
    lng: '119.635697',
    lat: '27.973312'
  },
  {
    code: '331181',
    name: '龙泉市',
    city_level: 3,
    lng: '119.141461',
    lat: '28.074623'
  },
  {
    code: '340000',
    name: '安徽省',
    city_level: 1,
    lng: '117.284922',
    lat: '31.861184'
  },
  {
    code: '340100',
    name: '合肥市',
    city_level: 2,
    lng: '117.227239',
    lat: '31.820586'
  },
  {
    code: '340102',
    name: '瑶海区',
    city_level: 3,
    lng: '117.309229',
    lat: '31.858048'
  },
  {
    code: '340103',
    name: '庐阳区',
    city_level: 3,
    lng: '117.264595',
    lat: '31.878641'
  },
  {
    code: '340104',
    name: '蜀山区',
    city_level: 3,
    lng: '117.260536',
    lat: '31.851158'
  },
  {
    code: '340111',
    name: '包河区',
    city_level: 3,
    lng: '117.309658',
    lat: '31.793093'
  },
  {
    code: '340121',
    name: '长丰县',
    city_level: 3,
    lng: '117.167564',
    lat: '32.478018'
  },
  {
    code: '340122',
    name: '肥东县',
    city_level: 3,
    lng: '117.469383',
    lat: '31.887940'
  },
  {
    code: '340123',
    name: '肥西县',
    city_level: 3,
    lng: '117.157981',
    lat: '31.706810'
  },
  {
    code: '340124',
    name: '庐江县',
    city_level: 3,
    lng: '117.287800',
    lat: '31.255550'
  },
  {
    code: '340181',
    name: '巢湖市',
    city_level: 3,
    lng: '117.861800',
    lat: '31.598628'
  },
  {
    code: '340200',
    name: '芜湖市',
    city_level: 2,
    lng: '118.432941',
    lat: '31.352859'
  },
  {
    code: '340202',
    name: '镜湖区',
    city_level: 3,
    lng: '118.385146',
    lat: '31.340404'
  },
  {
    code: '340207',
    name: '鸠江区',
    city_level: 3,
    lng: '118.391734',
    lat: '31.369373'
  },
  {
    code: '340209',
    name: '弋江区',
    city_level: 3,
    lng: '118.372655',
    lat: '31.311757'
  },
  {
    code: '340210',
    name: '湾沚区',
    city_level: 3,
    lng: '118.585300',
    lat: '31.127300'
  },
  {
    code: '340212',
    name: '繁昌区',
    city_level: 3,
    lng: '118.201347',
    lat: '31.080896'
  },
  {
    code: '340223',
    name: '南陵县',
    city_level: 3,
    lng: '118.334360',
    lat: '30.914923'
  },
  {
    code: '340281',
    name: '无为市',
    city_level: 3,
    lng: '117.911430',
    lat: '31.303074'
  },
  {
    code: '340300',
    name: '蚌埠市',
    city_level: 2,
    lng: '117.389719',
    lat: '32.916287'
  },
  {
    code: '340302',
    name: '龙子湖区',
    city_level: 3,
    lng: '117.393790',
    lat: '32.943014'
  },
  {
    code: '340303',
    name: '蚌山区',
    city_level: 3,
    lng: '117.367614',
    lat: '32.944198'
  },
  {
    code: '340304',
    name: '禹会区',
    city_level: 3,
    lng: '117.342451',
    lat: '32.929711'
  },
  {
    code: '340311',
    name: '淮上区',
    city_level: 3,
    lng: '117.359331',
    lat: '32.965435'
  },
  {
    code: '340321',
    name: '怀远县',
    city_level: 3,
    lng: '117.205234',
    lat: '32.970031'
  },
  {
    code: '340322',
    name: '五河县',
    city_level: 3,
    lng: '117.879486',
    lat: '33.127823'
  },
  {
    code: '340323',
    name: '固镇县',
    city_level: 3,
    lng: '117.316955',
    lat: '33.316899'
  },
  {
    code: '340400',
    name: '淮南市',
    city_level: 2,
    lng: '116.999932',
    lat: '32.625478'
  },
  {
    code: '340402',
    name: '大通区',
    city_level: 3,
    lng: '117.053273',
    lat: '32.631533'
  },
  {
    code: '340403',
    name: '田家庵区',
    city_level: 3,
    lng: '117.017409',
    lat: '32.647155'
  },
  {
    code: '340404',
    name: '谢家集区',
    city_level: 3,
    lng: '116.859048',
    lat: '32.599901'
  },
  {
    code: '340405',
    name: '八公山区',
    city_level: 3,
    lng: '116.833490',
    lat: '32.631379'
  },
  {
    code: '340406',
    name: '潘集区',
    city_level: 3,
    lng: '116.834716',
    lat: '32.772080'
  },
  {
    code: '340421',
    name: '凤台县',
    city_level: 3,
    lng: '116.711051',
    lat: '32.709445'
  },
  {
    code: '340422',
    name: '寿县',
    city_level: 3,
    lng: '116.787141',
    lat: '32.573306'
  },
  {
    code: '340500',
    name: '马鞍山市',
    city_level: 2,
    lng: '118.506759',
    lat: '31.670452'
  },
  {
    code: '340503',
    name: '花山区',
    city_level: 3,
    lng: '118.492562',
    lat: '31.719710'
  },
  {
    code: '340504',
    name: '雨山区',
    city_level: 3,
    lng: '118.498560',
    lat: '31.682208'
  },
  {
    code: '340506',
    name: '博望区',
    city_level: 3,
    lng: '118.844538',
    lat: '31.558471'
  },
  {
    code: '340521',
    name: '当涂县',
    city_level: 3,
    lng: '118.497972',
    lat: '31.571213'
  },
  {
    code: '340522',
    name: '含山县',
    city_level: 3,
    lng: '118.101421',
    lat: '31.735599'
  },
  {
    code: '340523',
    name: '和县',
    city_level: 3,
    lng: '118.351405',
    lat: '31.741794'
  },
  {
    code: '340600',
    name: '淮北市',
    city_level: 2,
    lng: '116.798265',
    lat: '33.955844'
  },
  {
    code: '340602',
    name: '杜集区',
    city_level: 3,
    lng: '116.828134',
    lat: '33.991451'
  },
  {
    code: '340603',
    name: '相山区',
    city_level: 3,
    lng: '116.794345',
    lat: '33.959893'
  },
  {
    code: '340604',
    name: '烈山区',
    city_level: 3,
    lng: '116.813042',
    lat: '33.895139'
  },
  {
    code: '340621',
    name: '濉溪县',
    city_level: 3,
    lng: '116.766299',
    lat: '33.915477'
  },
  {
    code: '340700',
    name: '铜陵市',
    city_level: 2,
    lng: '117.812079',
    lat: '30.945429'
  },
  {
    code: '340705',
    name: '铜官区',
    city_level: 3,
    lng: '117.816174',
    lat: '30.927608'
  },
  {
    code: '340706',
    name: '义安区',
    city_level: 2,
    lng: '117.812079',
    lat: '30.945429'
  },
  {
    code: '340711',
    name: '郊区',
    city_level: 3,
    lng: '117.807070',
    lat: '30.908927'
  },
  {
    code: '340722',
    name: '枞阳县',
    city_level: 3,
    lng: '117.250622',
    lat: '30.705963'
  },
  {
    code: '340800',
    name: '安庆市',
    city_level: 2,
    lng: '117.063754',
    lat: '30.543494'
  },
  {
    code: '340802',
    name: '迎江区',
    city_level: 3,
    lng: '117.091150',
    lat: '30.511548'
  },
  {
    code: '340803',
    name: '大观区',
    city_level: 3,
    lng: '117.021670',
    lat: '30.553957'
  },
  {
    code: '340811',
    name: '宜秀区',
    city_level: 3,
    lng: '117.070003',
    lat: '30.541323'
  },
  {
    code: '340822',
    name: '怀宁县',
    city_level: 3,
    lng: '116.829475',
    lat: '30.733825'
  },
  {
    code: '340825',
    name: '太湖县',
    city_level: 3,
    lng: '116.308795',
    lat: '30.454220'
  },
  {
    code: '340826',
    name: '宿松县',
    city_level: 3,
    lng: '116.129105',
    lat: '30.153746'
  },
  {
    code: '340827',
    name: '望江县',
    city_level: 3,
    lng: '116.694183',
    lat: '30.124428'
  },
  {
    code: '340828',
    name: '岳西县',
    city_level: 3,
    lng: '116.359921',
    lat: '30.849442'
  },
  {
    code: '340881',
    name: '桐城市',
    city_level: 3,
    lng: '116.974120',
    lat: '31.035800'
  },
  {
    code: '340882',
    name: '潜山市',
    city_level: 3,
    lng: '116.576770',
    lat: '30.628638'
  },
  {
    code: '341000',
    name: '黄山市',
    city_level: 2,
    lng: '118.337481',
    lat: '29.714655'
  },
  {
    code: '341002',
    name: '屯溪区',
    city_level: 3,
    lng: '118.315329',
    lat: '29.696109'
  },
  {
    code: '341003',
    name: '黄山区',
    city_level: 3,
    lng: '118.141568',
    lat: '30.272942'
  },
  {
    code: '341004',
    name: '徽州区',
    city_level: 3,
    lng: '118.336751',
    lat: '29.827279'
  },
  {
    code: '341021',
    name: '歙县',
    city_level: 3,
    lng: '118.415356',
    lat: '29.861308'
  },
  {
    code: '341022',
    name: '休宁县',
    city_level: 3,
    lng: '118.199179',
    lat: '29.789095'
  },
  {
    code: '341023',
    name: '黟县',
    city_level: 3,
    lng: '117.938373',
    lat: '29.924805'
  },
  {
    code: '341024',
    name: '祁门县',
    city_level: 3,
    lng: '117.717396',
    lat: '29.854055'
  },
  {
    code: '341100',
    name: '滁州市',
    city_level: 2,
    lng: '118.317106',
    lat: '32.301556'
  },
  {
    code: '341102',
    name: '琅琊区',
    city_level: 3,
    lng: '118.305843',
    lat: '32.294530'
  },
  {
    code: '341103',
    name: '南谯区',
    city_level: 3,
    lng: '118.296955',
    lat: '32.329842'
  },
  {
    code: '341122',
    name: '来安县',
    city_level: 3,
    lng: '118.435749',
    lat: '32.452167'
  },
  {
    code: '341124',
    name: '全椒县',
    city_level: 3,
    lng: '118.273090',
    lat: '32.085407'
  },
  {
    code: '341125',
    name: '定远县',
    city_level: 3,
    lng: '117.698563',
    lat: '32.530982'
  },
  {
    code: '341126',
    name: '凤阳县',
    city_level: 3,
    lng: '117.531623',
    lat: '32.874735'
  },
  {
    code: '341181',
    name: '天长市',
    city_level: 3,
    lng: '119.004817',
    lat: '32.667571'
  },
  {
    code: '341182',
    name: '明光市',
    city_level: 3,
    lng: '118.018276',
    lat: '32.781995'
  },
  {
    code: '341200',
    name: '阜阳市',
    city_level: 2,
    lng: '115.814204',
    lat: '32.890124'
  },
  {
    code: '341202',
    name: '颍州区',
    city_level: 3,
    lng: '115.806942',
    lat: '32.883468'
  },
  {
    code: '341203',
    name: '颍东区',
    city_level: 3,
    lng: '115.856735',
    lat: '32.912480'
  },
  {
    code: '341204',
    name: '颍泉区',
    city_level: 3,
    lng: '115.808327',
    lat: '32.924918'
  },
  {
    code: '341221',
    name: '临泉县',
    city_level: 3,
    lng: '115.261473',
    lat: '33.040261'
  },
  {
    code: '341222',
    name: '太和县',
    city_level: 3,
    lng: '115.621934',
    lat: '33.160326'
  },
  {
    code: '341225',
    name: '阜南县',
    city_level: 3,
    lng: '115.595644',
    lat: '32.658297'
  },
  {
    code: '341226',
    name: '颍上县',
    city_level: 3,
    lng: '116.256789',
    lat: '32.653255'
  },
  {
    code: '341282',
    name: '界首市',
    city_level: 3,
    lng: '115.374564',
    lat: '33.257013'
  },
  {
    code: '341300',
    name: '宿州市',
    city_level: 2,
    lng: '116.964356',
    lat: '33.646373'
  },
  {
    code: '341302',
    name: '埇桥区',
    city_level: 3,
    lng: '116.977463',
    lat: '33.640061'
  },
  {
    code: '341321',
    name: '砀山县',
    city_level: 3,
    lng: '116.367095',
    lat: '34.442561'
  },
  {
    code: '341322',
    name: '萧县',
    city_level: 3,
    lng: '116.947290',
    lat: '34.188728'
  },
  {
    code: '341323',
    name: '灵璧县',
    city_level: 3,
    lng: '117.552462',
    lat: '33.552998'
  },
  {
    code: '341324',
    name: '泗县',
    city_level: 3,
    lng: '117.910629',
    lat: '33.482982'
  },
  {
    code: '341500',
    name: '六安市',
    city_level: 2,
    lng: '116.521854',
    lat: '31.733699'
  },
  {
    code: '341502',
    name: '金安区',
    city_level: 3,
    lng: '116.539679',
    lat: '31.749265'
  },
  {
    code: '341503',
    name: '裕安区',
    city_level: 3,
    lng: '116.479920',
    lat: '31.737813'
  },
  {
    code: '341504',
    name: '叶集区',
    city_level: 3,
    lng: '115.908504',
    lat: '31.849165'
  },
  {
    code: '341522',
    name: '霍邱县',
    city_level: 3,
    lng: '116.277912',
    lat: '32.353038'
  },
  {
    code: '341523',
    name: '舒城县',
    city_level: 3,
    lng: '116.948623',
    lat: '31.462027'
  },
  {
    code: '341524',
    name: '金寨县',
    city_level: 3,
    lng: '115.934366',
    lat: '31.727170'
  },
  {
    code: '341525',
    name: '霍山县',
    city_level: 3,
    lng: '116.332951',
    lat: '31.392786'
  },
  {
    code: '341600',
    name: '亳州市',
    city_level: 2,
    lng: '115.778676',
    lat: '33.844582'
  },
  {
    code: '341602',
    name: '谯城区',
    city_level: 3,
    lng: '115.779025',
    lat: '33.876235'
  },
  {
    code: '341621',
    name: '涡阳县',
    city_level: 3,
    lng: '116.215665',
    lat: '33.492921'
  },
  {
    code: '341622',
    name: '蒙城县',
    city_level: 3,
    lng: '116.564248',
    lat: '33.265831'
  },
  {
    code: '341623',
    name: '利辛县',
    city_level: 3,
    lng: '116.208635',
    lat: '33.144724'
  },
  {
    code: '341700',
    name: '池州市',
    city_level: 2,
    lng: '117.491568',
    lat: '30.664800'
  },
  {
    code: '341702',
    name: '贵池区',
    city_level: 3,
    lng: '117.567379',
    lat: '30.687181'
  },
  {
    code: '341721',
    name: '东至县',
    city_level: 3,
    lng: '117.027533',
    lat: '30.111182'
  },
  {
    code: '341722',
    name: '石台县',
    city_level: 3,
    lng: '117.486306',
    lat: '30.210313'
  },
  {
    code: '341723',
    name: '青阳县',
    city_level: 3,
    lng: '117.847362',
    lat: '30.639230'
  },
  {
    code: '341800',
    name: '宣城市',
    city_level: 2,
    lng: '118.758816',
    lat: '30.940718'
  },
  {
    code: '341802',
    name: '宣州区',
    city_level: 3,
    lng: '118.756328',
    lat: '30.946319'
  },
  {
    code: '341821',
    name: '郎溪县',
    city_level: 3,
    lng: '119.179657',
    lat: '31.126412'
  },
  {
    code: '341823',
    name: '泾县',
    city_level: 3,
    lng: '118.419864',
    lat: '30.688578'
  },
  {
    code: '341824',
    name: '绩溪县',
    city_level: 3,
    lng: '118.578519',
    lat: '30.067533'
  },
  {
    code: '341825',
    name: '旌德县',
    city_level: 3,
    lng: '118.540487',
    lat: '30.286350'
  },
  {
    code: '341881',
    name: '宁国市',
    city_level: 3,
    lng: '118.982915',
    lat: '30.633571'
  },
  {
    code: '341882',
    name: '广德市',
    city_level: 3,
    lng: '119.422889',
    lat: '30.876054'
  },
  {
    code: '350000',
    name: '福建省',
    city_level: 1,
    lng: '119.295144',
    lat: '26.100779'
  },
  {
    code: '350100',
    name: '福州市',
    city_level: 2,
    lng: '119.296494',
    lat: '26.074507'
  },
  {
    code: '350102',
    name: '鼓楼区',
    city_level: 3,
    lng: '114.348307',
    lat: '34.788561'
  },
  {
    code: '350103',
    name: '台江区',
    city_level: 3,
    lng: '119.314054',
    lat: '26.052842'
  },
  {
    code: '350104',
    name: '仓山区',
    city_level: 3,
    lng: '119.273546',
    lat: '26.046744'
  },
  {
    code: '350105',
    name: '马尾区',
    city_level: 3,
    lng: '119.455589',
    lat: '25.989500'
  },
  {
    code: '350111',
    name: '晋安区',
    city_level: 3,
    lng: '119.328515',
    lat: '26.082105'
  },
  {
    code: '350112',
    name: '长乐区',
    city_level: 3,
    lng: '119.523337',
    lat: '25.962975'
  },
  {
    code: '350121',
    name: '闽侯县',
    city_level: 3,
    lng: '119.131725',
    lat: '26.150047'
  },
  {
    code: '350122',
    name: '连江县',
    city_level: 3,
    lng: '119.539704',
    lat: '26.197364'
  },
  {
    code: '350123',
    name: '罗源县',
    city_level: 3,
    lng: '119.549776',
    lat: '26.489559'
  },
  {
    code: '350124',
    name: '闽清县',
    city_level: 3,
    lng: '118.863361',
    lat: '26.221198'
  },
  {
    code: '350125',
    name: '永泰县',
    city_level: 3,
    lng: '118.932592',
    lat: '25.866695'
  },
  {
    code: '350128',
    name: '平潭县',
    city_level: 3,
    lng: '119.790168',
    lat: '25.498720'
  },
  {
    code: '350181',
    name: '福清市',
    city_level: 3,
    lng: '119.384334',
    lat: '25.721143'
  },
  {
    code: '350200',
    name: '厦门市',
    city_level: 2,
    lng: '118.089425',
    lat: '24.479833'
  },
  {
    code: '350203',
    name: '思明区',
    city_level: 3,
    lng: '118.082658',
    lat: '24.445567'
  },
  {
    code: '350205',
    name: '海沧区',
    city_level: 3,
    lng: '118.032818',
    lat: '24.484503'
  },
  {
    code: '350206',
    name: '湖里区',
    city_level: 3,
    lng: '118.146769',
    lat: '24.512905'
  },
  {
    code: '350211',
    name: '集美区',
    city_level: 3,
    lng: '118.097337',
    lat: '24.575970'
  },
  {
    code: '350212',
    name: '同安区',
    city_level: 3,
    lng: '118.152149',
    lat: '24.722747'
  },
  {
    code: '350213',
    name: '翔安区',
    city_level: 3,
    lng: '118.248034',
    lat: '24.618544'
  },
  {
    code: '350300',
    name: '莆田市',
    city_level: 2,
    lng: '119.007777',
    lat: '25.454084'
  },
  {
    code: '350302',
    name: '城厢区',
    city_level: 3,
    lng: '118.993885',
    lat: '25.419319'
  },
  {
    code: '350303',
    name: '涵江区',
    city_level: 3,
    lng: '119.116290',
    lat: '25.458720'
  },
  {
    code: '350304',
    name: '荔城区',
    city_level: 3,
    lng: '119.015123',
    lat: '25.431979'
  },
  {
    code: '350305',
    name: '秀屿区',
    city_level: 3,
    lng: '119.105528',
    lat: '25.318672'
  },
  {
    code: '350322',
    name: '仙游县',
    city_level: 3,
    lng: '118.691601',
    lat: '25.362094'
  },
  {
    code: '350400',
    name: '三明市',
    city_level: 2,
    lng: '117.638678',
    lat: '26.263406'
  },
  {
    code: '350402',
    name: '梅列区',
    city_level: 3,
    lng: '117.645856',
    lat: '26.271711'
  },
  {
    code: '350403',
    name: '三元区',
    city_level: 3,
    lng: '117.608045',
    lat: '26.234020'
  },
  {
    code: '350421',
    name: '明溪县',
    city_level: 3,
    lng: '117.201847',
    lat: '26.357379'
  },
  {
    code: '350423',
    name: '清流县',
    city_level: 3,
    lng: '116.816909',
    lat: '26.177797'
  },
  {
    code: '350424',
    name: '宁化县',
    city_level: 3,
    lng: '116.654365',
    lat: '26.261754'
  },
  {
    code: '350425',
    name: '大田县',
    city_level: 3,
    lng: '117.847115',
    lat: '25.692699'
  },
  {
    code: '350426',
    name: '尤溪县',
    city_level: 3,
    lng: '118.190467',
    lat: '26.170171'
  },
  {
    code: '350427',
    name: '沙县',
    city_level: 3,
    lng: '117.792450',
    lat: '26.397300'
  },
  {
    code: '350428',
    name: '将乐县',
    city_level: 3,
    lng: '117.471373',
    lat: '26.728953'
  },
  {
    code: '350429',
    name: '泰宁县',
    city_level: 3,
    lng: '117.175740',
    lat: '26.900259'
  },
  {
    code: '350430',
    name: '建宁县',
    city_level: 3,
    lng: '116.846084',
    lat: '26.830902'
  },
  {
    code: '350481',
    name: '永安市',
    city_level: 3,
    lng: '117.374461',
    lat: '25.976394'
  },
  {
    code: '350500',
    name: '泉州市',
    city_level: 2,
    lng: '118.675675',
    lat: '24.874132'
  },
  {
    code: '350502',
    name: '鲤城区',
    city_level: 3,
    lng: '118.586884',
    lat: '24.907581'
  },
  {
    code: '350503',
    name: '丰泽区',
    city_level: 3,
    lng: '118.668527',
    lat: '24.871018'
  },
  {
    code: '350504',
    name: '洛江区',
    city_level: 3,
    lng: '118.671193',
    lat: '24.939751'
  },
  {
    code: '350505',
    name: '泉港区',
    city_level: 3,
    lng: '118.916309',
    lat: '25.119815'
  },
  {
    code: '350521',
    name: '惠安县',
    city_level: 3,
    lng: '118.796605',
    lat: '25.030781'
  },
  {
    code: '350524',
    name: '安溪县',
    city_level: 3,
    lng: '118.186289',
    lat: '25.055955'
  },
  {
    code: '350525',
    name: '永春县',
    city_level: 3,
    lng: '118.294048',
    lat: '25.321565'
  },
  {
    code: '350526',
    name: '德化县',
    city_level: 3,
    lng: '118.241094',
    lat: '25.491494'
  },
  {
    code: '350527',
    name: '金门县',
    city_level: 3,
    lng: '118.317089',
    lat: '24.432706'
  },
  {
    code: '350581',
    name: '石狮市',
    city_level: 3,
    lng: '118.648066',
    lat: '24.732204'
  },
  {
    code: '350582',
    name: '晋江市',
    city_level: 3,
    lng: '118.552365',
    lat: '24.781681'
  },
  {
    code: '350583',
    name: '南安市',
    city_level: 3,
    lng: '118.386279',
    lat: '24.960385'
  },
  {
    code: '350600',
    name: '漳州市',
    city_level: 2,
    lng: '117.647481',
    lat: '24.512948'
  },
  {
    code: '350602',
    name: '芗城区',
    city_level: 3,
    lng: '117.653975',
    lat: '24.510900'
  },
  {
    code: '350603',
    name: '龙文区',
    city_level: 3,
    lng: '117.709755',
    lat: '24.503113'
  },
  {
    code: '350622',
    name: '云霄县',
    city_level: 3,
    lng: '117.339573',
    lat: '23.957936'
  },
  {
    code: '350623',
    name: '漳浦县',
    city_level: 3,
    lng: '117.613808',
    lat: '24.117102'
  },
  {
    code: '350624',
    name: '诏安县',
    city_level: 3,
    lng: '117.175185',
    lat: '23.711579'
  },
  {
    code: '350625',
    name: '长泰县',
    city_level: 3,
    lng: '117.759153',
    lat: '24.625449'
  },
  {
    code: '350626',
    name: '东山县',
    city_level: 3,
    lng: '117.430061',
    lat: '23.701262'
  },
  {
    code: '350627',
    name: '南靖县',
    city_level: 3,
    lng: '117.357321',
    lat: '24.514654'
  },
  {
    code: '350628',
    name: '平和县',
    city_level: 3,
    lng: '117.314891',
    lat: '24.363437'
  },
  {
    code: '350629',
    name: '华安县',
    city_level: 3,
    lng: '117.534103',
    lat: '25.004425'
  },
  {
    code: '350681',
    name: '龙海市',
    city_level: 3,
    lng: '117.818197',
    lat: '24.446706'
  },
  {
    code: '350700',
    name: '南平市',
    city_level: 3,
    lng: '118.081325',
    lat: '27.382829'
  },
  {
    code: '350702',
    name: '延平区',
    city_level: 3,
    lng: '118.181894',
    lat: '26.637457'
  },
  {
    code: '350703',
    name: '建阳区',
    city_level: 3,
    lng: '118.120464',
    lat: '27.331876'
  },
  {
    code: '350721',
    name: '顺昌县',
    city_level: 3,
    lng: '117.810357',
    lat: '26.793288'
  },
  {
    code: '350722',
    name: '浦城县',
    city_level: 3,
    lng: '118.541256',
    lat: '27.917263'
  },
  {
    code: '350723',
    name: '光泽县',
    city_level: 3,
    lng: '117.334106',
    lat: '27.540988'
  },
  {
    code: '350724',
    name: '松溪县',
    city_level: 3,
    lng: '118.785468',
    lat: '27.526232'
  },
  {
    code: '350725',
    name: '政和县',
    city_level: 3,
    lng: '118.857642',
    lat: '27.366104'
  },
  {
    code: '350781',
    name: '邵武市',
    city_level: 3,
    lng: '117.492534',
    lat: '27.340327'
  },
  {
    code: '350782',
    name: '武夷山市',
    city_level: 3,
    lng: '118.035310',
    lat: '27.756648'
  },
  {
    code: '350783',
    name: '建瓯市',
    city_level: 3,
    lng: '118.304988',
    lat: '27.022727'
  },
  {
    code: '350800',
    name: '龙岩市',
    city_level: 2,
    lng: '117.017536',
    lat: '25.075123'
  },
  {
    code: '350802',
    name: '新罗区',
    city_level: 3,
    lng: '117.037264',
    lat: '25.098292'
  },
  {
    code: '350803',
    name: '永定区',
    city_level: 3,
    lng: '110.537138',
    lat: '29.119856'
  },
  {
    code: '350821',
    name: '长汀县',
    city_level: 3,
    lng: '116.357581',
    lat: '25.833531'
  },
  {
    code: '350823',
    name: '上杭县',
    city_level: 3,
    lng: '116.420099',
    lat: '25.049518'
  },
  {
    code: '350824',
    name: '武平县',
    city_level: 3,
    lng: '116.100408',
    lat: '25.095298'
  },
  {
    code: '350825',
    name: '连城县',
    city_level: 3,
    lng: '116.754473',
    lat: '25.710539'
  },
  {
    code: '350881',
    name: '漳平市',
    city_level: 3,
    lng: '117.419998',
    lat: '25.290185'
  },
  {
    code: '350900',
    name: '宁德市',
    city_level: 2,
    lng: '119.547932',
    lat: '26.665617'
  },
  {
    code: '350902',
    name: '蕉城区',
    city_level: 3,
    lng: '119.526299',
    lat: '26.660611'
  },
  {
    code: '350921',
    name: '霞浦县',
    city_level: 3,
    lng: '120.005643',
    lat: '26.885204'
  },
  {
    code: '350922',
    name: '古田县',
    city_level: 3,
    lng: '118.746284',
    lat: '26.577837'
  },
  {
    code: '350923',
    name: '屏南县',
    city_level: 3,
    lng: '118.985895',
    lat: '26.908276'
  },
  {
    code: '350924',
    name: '寿宁县',
    city_level: 3,
    lng: '119.514987',
    lat: '27.454479'
  },
  {
    code: '350925',
    name: '周宁县',
    city_level: 3,
    lng: '119.339025',
    lat: '27.104591'
  },
  {
    code: '350926',
    name: '柘荣县',
    city_level: 3,
    lng: '119.900609',
    lat: '27.233933'
  },
  {
    code: '350981',
    name: '福安市',
    city_level: 3,
    lng: '119.652171',
    lat: '27.087184'
  },
  {
    code: '350982',
    name: '福鼎市',
    city_level: 3,
    lng: '120.216978',
    lat: '27.324479'
  },
  {
    code: '360000',
    name: '江西省',
    city_level: 1,
    lng: '115.909228',
    lat: '28.675696'
  },
  {
    code: '360100',
    name: '南昌市',
    city_level: 2,
    lng: '115.858197',
    lat: '28.682892'
  },
  {
    code: '360102',
    name: '东湖区',
    city_level: 3,
    lng: '115.899262',
    lat: '28.685085'
  },
  {
    code: '360103',
    name: '西湖区',
    city_level: 3,
    lng: '115.877233',
    lat: '28.657595'
  },
  {
    code: '360104',
    name: '青云谱区',
    city_level: 3,
    lng: '115.925749',
    lat: '28.621169'
  },
  {
    code: '360111',
    name: '青山湖区',
    city_level: 3,
    lng: '115.962144',
    lat: '28.682985'
  },
  {
    code: '360112',
    name: '新建区',
    city_level: 3,
    lng: '115.815278',
    lat: '28.692864'
  },
  {
    code: '360113',
    name: '红谷滩区',
    city_level: 3,
    lng: '115.806324',
    lat: '28.665312'
  },
  {
    code: '360121',
    name: '南昌县',
    city_level: 3,
    lng: '115.944304',
    lat: '28.545602'
  },
  {
    code: '360123',
    name: '安义县',
    city_level: 3,
    lng: '115.549247',
    lat: '28.844507'
  },
  {
    code: '360124',
    name: '进贤县',
    city_level: 3,
    lng: '116.269480',
    lat: '28.366913'
  },
  {
    code: '360200',
    name: '景德镇市',
    city_level: 2,
    lng: '117.178419',
    lat: '29.268835'
  },
  {
    code: '360202',
    name: '昌江区',
    city_level: 3,
    lng: '117.183748',
    lat: '29.273435'
  },
  {
    code: '360203',
    name: '珠山区',
    city_level: 3,
    lng: '117.202893',
    lat: '29.299923'
  },
  {
    code: '360222',
    name: '浮梁县',
    city_level: 3,
    lng: '117.215086',
    lat: '29.351681'
  },
  {
    code: '360281',
    name: '乐平市',
    city_level: 3,
    lng: '117.151796',
    lat: '28.978440'
  },
  {
    code: '360300',
    name: '萍乡市',
    city_level: 2,
    lng: '113.854556',
    lat: '27.622768'
  },
  {
    code: '360302',
    name: '安源区',
    city_level: 3,
    lng: '113.870730',
    lat: '27.615202'
  },
  {
    code: '360313',
    name: '湘东区',
    city_level: 3,
    lng: '113.733047',
    lat: '27.640075'
  },
  {
    code: '360321',
    name: '莲花县',
    city_level: 3,
    lng: '113.961516',
    lat: '27.127658'
  },
  {
    code: '360322',
    name: '上栗县',
    city_level: 3,
    lng: '113.795311',
    lat: '27.880302'
  },
  {
    code: '360323',
    name: '芦溪县',
    city_level: 3,
    lng: '114.029828',
    lat: '27.630806'
  },
  {
    code: '360400',
    name: '九江市',
    city_level: 2,
    lng: '116.001930',
    lat: '29.705077'
  },
  {
    code: '360402',
    name: '濂溪区',
    city_level: 3,
    lng: '115.989197',
    lat: '29.671694'
  },
  {
    code: '360403',
    name: '浔阳区',
    city_level: 3,
    lng: '115.990301',
    lat: '29.727593'
  },
  {
    code: '360404',
    name: '柴桑区',
    city_level: 3,
    lng: '115.911323',
    lat: '29.608431'
  },
  {
    code: '360423',
    name: '武宁县',
    city_level: 3,
    lng: '115.100578',
    lat: '29.256323'
  },
  {
    code: '360424',
    name: '修水县',
    city_level: 3,
    lng: '114.546702',
    lat: '29.026022'
  },
  {
    code: '360425',
    name: '永修县',
    city_level: 3,
    lng: '115.832453',
    lat: '29.012297'
  },
  {
    code: '360426',
    name: '德安县',
    city_level: 3,
    lng: '115.767484',
    lat: '29.298738'
  },
  {
    code: '360428',
    name: '都昌县',
    city_level: 3,
    lng: '116.204099',
    lat: '29.273194'
  },
  {
    code: '360429',
    name: '湖口县',
    city_level: 3,
    lng: '116.251947',
    lat: '29.731101'
  },
  {
    code: '360430',
    name: '彭泽县',
    city_level: 3,
    lng: '116.549359',
    lat: '29.896061'
  },
  {
    code: '360481',
    name: '瑞昌市',
    city_level: 3,
    lng: '115.681337',
    lat: '29.675874'
  },
  {
    code: '360482',
    name: '共青城市',
    city_level: 3,
    lng: '115.808844',
    lat: '29.248317'
  },
  {
    code: '360483',
    name: '庐山市',
    city_level: 3,
    lng: '116.045060',
    lat: '29.448128'
  },
  {
    code: '360500',
    name: '新余市',
    city_level: 2,
    lng: '114.917346',
    lat: '27.817808'
  },
  {
    code: '360502',
    name: '渝水区',
    city_level: 3,
    lng: '114.944646',
    lat: '27.800387'
  },
  {
    code: '360521',
    name: '分宜县',
    city_level: 3,
    lng: '114.692050',
    lat: '27.814758'
  },
  {
    code: '360600',
    name: '鹰潭市',
    city_level: 2,
    lng: '117.069202',
    lat: '28.260189'
  },
  {
    code: '360602',
    name: '月湖区',
    city_level: 3,
    lng: '117.037137',
    lat: '28.239153'
  },
  {
    code: '360603',
    name: '余江区',
    city_level: 3,
    lng: '117.013288',
    lat: '28.206010'
  },
  {
    code: '360681',
    name: '贵溪市',
    city_level: 3,
    lng: '117.245497',
    lat: '28.292519'
  },
  {
    code: '360700',
    name: '赣州市',
    city_level: 2,
    lng: '114.935029',
    lat: '25.831829'
  },
  {
    code: '360702',
    name: '章贡区',
    city_level: 3,
    lng: '114.921171',
    lat: '25.817816'
  },
  {
    code: '360703',
    name: '南康区',
    city_level: 2,
    lng: '114.935029',
    lat: '25.831829'
  },
  {
    code: '360704',
    name: '赣县区',
    city_level: 3,
    lng: '115.018463',
    lat: '25.865431'
  },
  {
    code: '360722',
    name: '信丰县',
    city_level: 3,
    lng: '114.922830',
    lat: '25.386704'
  },
  {
    code: '360723',
    name: '大余县',
    city_level: 3,
    lng: '114.362112',
    lat: '25.401314'
  },
  {
    code: '360724',
    name: '上犹县',
    city_level: 3,
    lng: '114.551138',
    lat: '25.785172'
  },
  {
    code: '360725',
    name: '崇义县',
    city_level: 3,
    lng: '114.308267',
    lat: '25.681784'
  },
  {
    code: '360726',
    name: '安远县',
    city_level: 3,
    lng: '115.393922',
    lat: '25.136927'
  },
  {
    code: '360728',
    name: '定南县',
    city_level: 3,
    lng: '115.027845',
    lat: '24.784410'
  },
  {
    code: '360729',
    name: '全南县',
    city_level: 3,
    lng: '114.530125',
    lat: '24.742403'
  },
  {
    code: '360730',
    name: '宁都县',
    city_level: 3,
    lng: '116.013621',
    lat: '26.470996'
  },
  {
    code: '360731',
    name: '于都县',
    city_level: 3,
    lng: '115.415508',
    lat: '25.952069'
  },
  {
    code: '360732',
    name: '兴国县',
    city_level: 3,
    lng: '115.363190',
    lat: '26.337937'
  },
  {
    code: '360733',
    name: '会昌县',
    city_level: 3,
    lng: '115.786057',
    lat: '25.600272'
  },
  {
    code: '360734',
    name: '寻乌县',
    city_level: 3,
    lng: '115.646636',
    lat: '24.963371'
  },
  {
    code: '360735',
    name: '石城县',
    city_level: 3,
    lng: '116.346995',
    lat: '26.314775'
  },
  {
    code: '360781',
    name: '瑞金市',
    city_level: 3,
    lng: '116.027135',
    lat: '25.885555'
  },
  {
    code: '360783',
    name: '龙南市',
    city_level: 3,
    lng: '114.794567',
    lat: '24.903079'
  },
  {
    code: '360800',
    name: '吉安市',
    city_level: 2,
    lng: '114.992509',
    lat: '27.113443'
  },
  {
    code: '360802',
    name: '吉州区',
    city_level: 3,
    lng: '114.994764',
    lat: '27.143801'
  },
  {
    code: '360803',
    name: '青原区',
    city_level: 3,
    lng: '115.014812',
    lat: '27.081977'
  },
  {
    code: '360821',
    name: '吉安县',
    city_level: 3,
    lng: '114.907733',
    lat: '27.039890'
  },
  {
    code: '360822',
    name: '吉水县',
    city_level: 3,
    lng: '115.135507',
    lat: '27.229632'
  },
  {
    code: '360823',
    name: '峡江县',
    city_level: 3,
    lng: '115.316566',
    lat: '27.582901'
  },
  {
    code: '360824',
    name: '新干县',
    city_level: 3,
    lng: '115.387052',
    lat: '27.740192'
  },
  {
    code: '360825',
    name: '永丰县',
    city_level: 3,
    lng: '115.444320',
    lat: '27.318852'
  },
  {
    code: '360826',
    name: '泰和县',
    city_level: 3,
    lng: '114.908869',
    lat: '26.789960'
  },
  {
    code: '360827',
    name: '遂川县',
    city_level: 3,
    lng: '114.520537',
    lat: '26.313737'
  },
  {
    code: '360828',
    name: '万安县',
    city_level: 3,
    lng: '114.786182',
    lat: '26.458254'
  },
  {
    code: '360829',
    name: '安福县',
    city_level: 3,
    lng: '114.619893',
    lat: '27.392874'
  },
  {
    code: '360830',
    name: '永新县',
    city_level: 3,
    lng: '114.243096',
    lat: '26.945002'
  },
  {
    code: '360881',
    name: '井冈山市',
    city_level: 3,
    lng: '114.289228',
    lat: '26.748081'
  },
  {
    code: '360900',
    name: '宜春市',
    city_level: 2,
    lng: '114.416778',
    lat: '27.815619'
  },
  {
    code: '360902',
    name: '袁州区',
    city_level: 3,
    lng: '114.424657',
    lat: '27.798846'
  },
  {
    code: '360921',
    name: '奉新县',
    city_level: 3,
    lng: '115.400491',
    lat: '28.688423'
  },
  {
    code: '360922',
    name: '万载县',
    city_level: 3,
    lng: '114.445523',
    lat: '28.106004'
  },
  {
    code: '360923',
    name: '上高县',
    city_level: 3,
    lng: '114.947831',
    lat: '28.238876'
  },
  {
    code: '360924',
    name: '宜丰县',
    city_level: 3,
    lng: '114.803515',
    lat: '28.393666'
  },
  {
    code: '360925',
    name: '靖安县',
    city_level: 3,
    lng: '115.362629',
    lat: '28.861479'
  },
  {
    code: '360926',
    name: '铜鼓县',
    city_level: 3,
    lng: '114.371172',
    lat: '28.520770'
  },
  {
    code: '360981',
    name: '丰城市',
    city_level: 3,
    lng: '115.771094',
    lat: '28.159142'
  },
  {
    code: '360982',
    name: '樟树市',
    city_level: 3,
    lng: '115.546385',
    lat: '28.054814'
  },
  {
    code: '360983',
    name: '高安市',
    city_level: 3,
    lng: '115.375616',
    lat: '28.417255'
  },
  {
    code: '361000',
    name: '抚州市',
    city_level: 2,
    lng: '116.358181',
    lat: '27.949217'
  },
  {
    code: '361002',
    name: '临川区',
    city_level: 3,
    lng: '116.371066',
    lat: '27.970596'
  },
  {
    code: '361003',
    name: '东乡区',
    city_level: 3,
    lng: '116.605339',
    lat: '28.232500'
  },
  {
    code: '361021',
    name: '南城县',
    city_level: 3,
    lng: '116.637040',
    lat: '27.569678'
  },
  {
    code: '361022',
    name: '黎川县',
    city_level: 3,
    lng: '116.907681',
    lat: '27.282333'
  },
  {
    code: '361023',
    name: '南丰县',
    city_level: 3,
    lng: '116.525725',
    lat: '27.218445'
  },
  {
    code: '361024',
    name: '崇仁县',
    city_level: 3,
    lng: '116.061101',
    lat: '27.764394'
  },
  {
    code: '361025',
    name: '乐安县',
    city_level: 3,
    lng: '115.830481',
    lat: '27.428765'
  },
  {
    code: '361026',
    name: '宜黄县',
    city_level: 3,
    lng: '116.222128',
    lat: '27.546146'
  },
  {
    code: '361027',
    name: '金溪县',
    city_level: 3,
    lng: '116.755058',
    lat: '27.918959'
  },
  {
    code: '361028',
    name: '资溪县',
    city_level: 3,
    lng: '117.060264',
    lat: '27.706102'
  },
  {
    code: '361030',
    name: '广昌县',
    city_level: 3,
    lng: '116.325757',
    lat: '26.837267'
  },
  {
    code: '361100',
    name: '上饶市',
    city_level: 2,
    lng: '117.943433',
    lat: '28.454863'
  },
  {
    code: '361102',
    name: '信州区',
    city_level: 3,
    lng: '117.966460',
    lat: '28.431002'
  },
  {
    code: '361103',
    name: '广丰区',
    city_level: 3,
    lng: '118.191240',
    lat: '28.436286'
  },
  {
    code: '361104',
    name: '广信区',
    city_level: 2,
    lng: '117.943433',
    lat: '28.454862'
  },
  {
    code: '361123',
    name: '玉山县',
    city_level: 3,
    lng: '118.245124',
    lat: '28.682055'
  },
  {
    code: '361124',
    name: '铅山县',
    city_level: 3,
    lng: '117.709451',
    lat: '28.315217'
  },
  {
    code: '361125',
    name: '横峰县',
    city_level: 3,
    lng: '117.596452',
    lat: '28.407118'
  },
  {
    code: '361126',
    name: '弋阳县',
    city_level: 3,
    lng: '117.449588',
    lat: '28.378044'
  },
  {
    code: '361127',
    name: '余干县',
    city_level: 3,
    lng: '116.695647',
    lat: '28.702302'
  },
  {
    code: '361128',
    name: '鄱阳县',
    city_level: 3,
    lng: '116.699746',
    lat: '29.011699'
  },
  {
    code: '361129',
    name: '万年县',
    city_level: 3,
    lng: '117.058445',
    lat: '28.694582'
  },
  {
    code: '361130',
    name: '婺源县',
    city_level: 3,
    lng: '117.861798',
    lat: '29.248086'
  },
  {
    code: '361181',
    name: '德兴市',
    city_level: 3,
    lng: '117.578713',
    lat: '28.946464'
  },
  {
    code: '370000',
    name: '山东省',
    city_level: 1,
    lng: '117.020359',
    lat: '36.668530'
  },
  {
    code: '370100',
    name: '济南市',
    city_level: 2,
    lng: '117.119999',
    lat: '36.651216'
  },
  {
    code: '370102',
    name: '历下区',
    city_level: 3,
    lng: '117.076455',
    lat: '36.666412'
  },
  {
    code: '370103',
    name: '市中区',
    city_level: 3,
    lng: '103.761330',
    lat: '29.555375'
  },
  {
    code: '370104',
    name: '槐荫区',
    city_level: 3,
    lng: '116.901224',
    lat: '36.651441'
  },
  {
    code: '370105',
    name: '天桥区',
    city_level: 3,
    lng: '116.987492',
    lat: '36.678016'
  },
  {
    code: '370112',
    name: '历城区',
    city_level: 3,
    lng: '117.065237',
    lat: '36.680017'
  },
  {
    code: '370113',
    name: '长清区',
    city_level: 3,
    lng: '116.751937',
    lat: '36.553571'
  },
  {
    code: '370114',
    name: '章丘区',
    city_level: 3,
    lng: '117.540688',
    lat: '36.712090'
  },
  {
    code: '370115',
    name: '济阳区',
    city_level: 3,
    lng: '117.176033',
    lat: '36.976772'
  },
  {
    code: '370116',
    name: '莱芜区',
    city_level: 2,
    lng: '117.676723',
    lat: '36.213813'
  },
  {
    code: '370117',
    name: '钢城区',
    city_level: 3,
    lng: '117.811355',
    lat: '36.058572'
  },
  {
    code: '370124',
    name: '平阴县',
    city_level: 3,
    lng: '116.456187',
    lat: '36.289265'
  },
  {
    code: '370126',
    name: '商河县',
    city_level: 3,
    lng: '117.157207',
    lat: '37.309050'
  },
  {
    code: '370200',
    name: '青岛市',
    city_level: 2,
    lng: '120.382639',
    lat: '36.067082'
  },
  {
    code: '370202',
    name: '市南区',
    city_level: 3,
    lng: '120.385649',
    lat: '36.080305'
  },
  {
    code: '370203',
    name: '市北区',
    city_level: 3,
    lng: '120.374731',
    lat: '36.087609'
  },
  {
    code: '370211',
    name: '黄岛区',
    city_level: 3,
    lng: '120.046190',
    lat: '35.872664'
  },
  {
    code: '370212',
    name: '崂山区',
    city_level: 3,
    lng: '120.468956',
    lat: '36.107538'
  },
  {
    code: '370213',
    name: '李沧区',
    city_level: 3,
    lng: '120.432697',
    lat: '36.145463'
  },
  {
    code: '370214',
    name: '城阳区',
    city_level: 3,
    lng: '120.396310',
    lat: '36.307064'
  },
  {
    code: '370215',
    name: '即墨区',
    city_level: 3,
    lng: '120.447128',
    lat: '36.389639'
  },
  {
    code: '370281',
    name: '胶州市',
    city_level: 3,
    lng: '120.033382',
    lat: '36.264680'
  },
  {
    code: '370283',
    name: '平度市',
    city_level: 3,
    lng: '119.988454',
    lat: '36.776875'
  },
  {
    code: '370285',
    name: '莱西市',
    city_level: 3,
    lng: '120.517690',
    lat: '36.889084'
  },
  {
    code: '370300',
    name: '淄博市',
    city_level: 2,
    lng: '118.054927',
    lat: '36.813487'
  },
  {
    code: '370302',
    name: '淄川区',
    city_level: 3,
    lng: '117.966723',
    lat: '36.643452'
  },
  {
    code: '370303',
    name: '张店区',
    city_level: 3,
    lng: '118.017913',
    lat: '36.806674'
  },
  {
    code: '370304',
    name: '博山区',
    city_level: 3,
    lng: '117.861677',
    lat: '36.494721'
  },
  {
    code: '370305',
    name: '临淄区',
    city_level: 3,
    lng: '118.309118',
    lat: '36.826981'
  },
  {
    code: '370306',
    name: '周村区',
    city_level: 3,
    lng: '117.869886',
    lat: '36.803072'
  },
  {
    code: '370321',
    name: '桓台县',
    city_level: 3,
    lng: '118.097923',
    lat: '36.959804'
  },
  {
    code: '370322',
    name: '高青县',
    city_level: 3,
    lng: '117.826916',
    lat: '37.171063'
  },
  {
    code: '370323',
    name: '沂源县',
    city_level: 3,
    lng: '118.170856',
    lat: '36.185038'
  },
  {
    code: '370400',
    name: '枣庄市',
    city_level: 2,
    lng: '117.323725',
    lat: '34.810487'
  },
  {
    code: '370402',
    name: '市中区',
    city_level: 3,
    lng: '103.761330',
    lat: '29.555375'
  },
  {
    code: '370403',
    name: '薛城区',
    city_level: 3,
    lng: '117.263164',
    lat: '34.795063'
  },
  {
    code: '370404',
    name: '峄城区',
    city_level: 3,
    lng: '117.590816',
    lat: '34.773263'
  },
  {
    code: '370405',
    name: '台儿庄区',
    city_level: 3,
    lng: '117.733832',
    lat: '34.562528'
  },
  {
    code: '370406',
    name: '山亭区',
    city_level: 3,
    lng: '117.461517',
    lat: '35.099528'
  },
  {
    code: '370481',
    name: '滕州市',
    city_level: 3,
    lng: '117.165824',
    lat: '35.114156'
  },
  {
    code: '370500',
    name: '东营市',
    city_level: 2,
    lng: '118.674767',
    lat: '37.434751'
  },
  {
    code: '370502',
    name: '东营区',
    city_level: 3,
    lng: '118.582184',
    lat: '37.448963'
  },
  {
    code: '370503',
    name: '河口区',
    city_level: 3,
    lng: '118.525543',
    lat: '37.886162'
  },
  {
    code: '370505',
    name: '垦利区',
    city_level: 3,
    lng: '118.551315',
    lat: '37.588680'
  },
  {
    code: '370522',
    name: '利津县',
    city_level: 3,
    lng: '118.255273',
    lat: '37.490260'
  },
  {
    code: '370523',
    name: '广饶县',
    city_level: 3,
    lng: '118.407107',
    lat: '37.053555'
  },
  {
    code: '370600',
    name: '烟台市',
    city_level: 2,
    lng: '121.447935',
    lat: '37.463822'
  },
  {
    code: '370602',
    name: '芝罘区',
    city_level: 3,
    lng: '121.400031',
    lat: '37.540687'
  },
  {
    code: '370611',
    name: '福山区',
    city_level: 3,
    lng: '121.267741',
    lat: '37.498246'
  },
  {
    code: '370612',
    name: '牟平区',
    city_level: 3,
    lng: '121.600512',
    lat: '37.386901'
  },
  {
    code: '370613',
    name: '莱山区',
    city_level: 3,
    lng: '121.445151',
    lat: '37.511361'
  },
  {
    code: '370614',
    name: '蓬莱区',
    city_level: 3,
    lng: '120.757579',
    lat: '37.793492'
  },
  {
    code: '370681',
    name: '龙口市',
    city_level: 3,
    lng: '120.477813',
    lat: '37.646108'
  },
  {
    code: '370682',
    name: '莱阳市',
    city_level: 3,
    lng: '120.711673',
    lat: '36.978941'
  },
  {
    code: '370683',
    name: '莱州市',
    city_level: 3,
    lng: '119.942327',
    lat: '37.177017'
  },
  {
    code: '370685',
    name: '招远市',
    city_level: 3,
    lng: '120.434072',
    lat: '37.355469'
  },
  {
    code: '370686',
    name: '栖霞市',
    city_level: 3,
    lng: '120.849675',
    lat: '37.335123'
  },
  {
    code: '370687',
    name: '海阳市',
    city_level: 3,
    lng: '121.158434',
    lat: '36.776378'
  },
  {
    code: '370700',
    name: '潍坊市',
    city_level: 2,
    lng: '119.161755',
    lat: '36.706774'
  },
  {
    code: '370702',
    name: '潍城区',
    city_level: 3,
    lng: '119.024836',
    lat: '36.728100'
  },
  {
    code: '370703',
    name: '寒亭区',
    city_level: 3,
    lng: '119.219734',
    lat: '36.775491'
  },
  {
    code: '370704',
    name: '坊子区',
    city_level: 3,
    lng: '119.166485',
    lat: '36.654448'
  },
  {
    code: '370705',
    name: '奎文区',
    city_level: 3,
    lng: '119.132486',
    lat: '36.707676'
  },
  {
    code: '370724',
    name: '临朐县',
    city_level: 3,
    lng: '118.542982',
    lat: '36.512506'
  },
  {
    code: '370725',
    name: '昌乐县',
    city_level: 3,
    lng: '118.829996',
    lat: '36.706938'
  },
  {
    code: '370781',
    name: '青州市',
    city_level: 3,
    lng: '118.479636',
    lat: '36.684600'
  },
  {
    code: '370782',
    name: '诸城市',
    city_level: 3,
    lng: '119.410103',
    lat: '35.995654'
  },
  {
    code: '370783',
    name: '寿光市',
    city_level: 3,
    lng: '118.790652',
    lat: '36.855480'
  },
  {
    code: '370784',
    name: '安丘市',
    city_level: 3,
    lng: '119.218843',
    lat: '36.478494'
  },
  {
    code: '370785',
    name: '高密市',
    city_level: 3,
    lng: '119.755597',
    lat: '36.382595'
  },
  {
    code: '370786',
    name: '昌邑市',
    city_level: 3,
    lng: '119.398525',
    lat: '36.858820'
  },
  {
    code: '370800',
    name: '济宁市',
    city_level: 2,
    lng: '116.587098',
    lat: '35.414921'
  },
  {
    code: '370811',
    name: '任城区',
    city_level: 3,
    lng: '116.595050',
    lat: '35.406596'
  },
  {
    code: '370812',
    name: '兖州区',
    city_level: 3,
    lng: '116.783834',
    lat: '35.553144'
  },
  {
    code: '370826',
    name: '微山县',
    city_level: 3,
    lng: '117.128828',
    lat: '34.806554'
  },
  {
    code: '370827',
    name: '鱼台县',
    city_level: 3,
    lng: '116.650608',
    lat: '35.012749'
  },
  {
    code: '370828',
    name: '金乡县',
    city_level: 3,
    lng: '116.311532',
    lat: '35.066620'
  },
  {
    code: '370829',
    name: '嘉祥县',
    city_level: 3,
    lng: '116.342442',
    lat: '35.407829'
  },
  {
    code: '370830',
    name: '汶上县',
    city_level: 3,
    lng: '116.489043',
    lat: '35.732799'
  },
  {
    code: '370831',
    name: '泗水县',
    city_level: 3,
    lng: '117.251195',
    lat: '35.664323'
  },
  {
    code: '370832',
    name: '梁山县',
    city_level: 3,
    lng: '116.096044',
    lat: '35.802306'
  },
  {
    code: '370881',
    name: '曲阜市',
    city_level: 3,
    lng: '116.986503',
    lat: '35.580996'
  },
  {
    code: '370883',
    name: '邹城市',
    city_level: 3,
    lng: '117.003743',
    lat: '35.405185'
  },
  {
    code: '370900',
    name: '泰安市',
    city_level: 2,
    lng: '117.087614',
    lat: '36.200252'
  },
  {
    code: '370902',
    name: '泰山区',
    city_level: 3,
    lng: '117.135354',
    lat: '36.192084'
  },
  {
    code: '370911',
    name: '岱岳区',
    city_level: 3,
    lng: '117.041582',
    lat: '36.187990'
  },
  {
    code: '370921',
    name: '宁阳县',
    city_level: 3,
    lng: '116.805797',
    lat: '35.758787'
  },
  {
    code: '370923',
    name: '东平县',
    city_level: 3,
    lng: '116.470304',
    lat: '35.937102'
  },
  {
    code: '370982',
    name: '新泰市',
    city_level: 3,
    lng: '117.767953',
    lat: '35.909032'
  },
  {
    code: '370983',
    name: '肥城市',
    city_level: 3,
    lng: '116.768358',
    lat: '36.182572'
  },
  {
    code: '371000',
    name: '威海市',
    city_level: 2,
    lng: '122.120419',
    lat: '37.513068'
  },
  {
    code: '371002',
    name: '环翠区',
    city_level: 3,
    lng: '122.123444',
    lat: '37.501991'
  },
  {
    code: '371003',
    name: '文登区',
    city_level: 3,
    lng: '122.057941',
    lat: '37.193886'
  },
  {
    code: '371082',
    name: '荣成市',
    city_level: 3,
    lng: '122.486658',
    lat: '37.165160'
  },
  {
    code: '371083',
    name: '乳山市',
    city_level: 3,
    lng: '121.539765',
    lat: '36.919816'
  },
  {
    code: '371100',
    name: '日照市',
    city_level: 2,
    lng: '119.526888',
    lat: '35.416377'
  },
  {
    code: '371102',
    name: '东港区',
    city_level: 3,
    lng: '119.462249',
    lat: '35.425470'
  },
  {
    code: '371103',
    name: '岚山区',
    city_level: 3,
    lng: '119.318929',
    lat: '35.121884'
  },
  {
    code: '371121',
    name: '五莲县',
    city_level: 3,
    lng: '119.206763',
    lat: '35.751927'
  },
  {
    code: '371122',
    name: '莒县',
    city_level: 3,
    lng: '118.837064',
    lat: '35.579868'
  },
  {
    code: '371300',
    name: '临沂市',
    city_level: 2,
    lng: '118.356448',
    lat: '35.104672'
  },
  {
    code: '371302',
    name: '兰山区',
    city_level: 3,
    lng: '118.347731',
    lat: '35.051734'
  },
  {
    code: '371311',
    name: '罗庄区',
    city_level: 3,
    lng: '118.284786',
    lat: '34.996741'
  },
  {
    code: '371312',
    name: '河东区',
    city_level: 3,
    lng: '118.402893',
    lat: '35.089917'
  },
  {
    code: '371321',
    name: '沂南县',
    city_level: 3,
    lng: '118.465213',
    lat: '35.549976'
  },
  {
    code: '371322',
    name: '郯城县',
    city_level: 3,
    lng: '118.367263',
    lat: '34.613557'
  },
  {
    code: '371323',
    name: '沂水县',
    city_level: 3,
    lng: '118.627918',
    lat: '35.790450'
  },
  {
    code: '371324',
    name: '兰陵县',
    city_level: 3,
    lng: '118.070650',
    lat: '34.857149'
  },
  {
    code: '371325',
    name: '费县',
    city_level: 3,
    lng: '117.977325',
    lat: '35.265961'
  },
  {
    code: '371326',
    name: '平邑县',
    city_level: 3,
    lng: '117.640352',
    lat: '35.505943'
  },
  {
    code: '371327',
    name: '莒南县',
    city_level: 3,
    lng: '118.835163',
    lat: '35.174846'
  },
  {
    code: '371328',
    name: '蒙阴县',
    city_level: 3,
    lng: '117.945085',
    lat: '35.710032'
  },
  {
    code: '371329',
    name: '临沭县',
    city_level: 3,
    lng: '118.650782',
    lat: '34.919852'
  },
  {
    code: '371400',
    name: '德州市',
    city_level: 2,
    lng: '116.357464',
    lat: '37.434092'
  },
  {
    code: '371402',
    name: '德城区',
    city_level: 3,
    lng: '116.299471',
    lat: '37.450805'
  },
  {
    code: '371403',
    name: '陵城区',
    city_level: 3,
    lng: '116.576092',
    lat: '37.335794'
  },
  {
    code: '371422',
    name: '宁津县',
    city_level: 3,
    lng: '116.800306',
    lat: '37.652190'
  },
  {
    code: '371423',
    name: '庆云县',
    city_level: 3,
    lng: '117.385257',
    lat: '37.775350'
  },
  {
    code: '371424',
    name: '临邑县',
    city_level: 3,
    lng: '116.866800',
    lat: '37.189798'
  },
  {
    code: '371425',
    name: '齐河县',
    city_level: 3,
    lng: '116.762810',
    lat: '36.783415'
  },
  {
    code: '371426',
    name: '平原县',
    city_level: 3,
    lng: '116.434056',
    lat: '37.165314'
  },
  {
    code: '371427',
    name: '夏津县',
    city_level: 3,
    lng: '116.001726',
    lat: '36.948371'
  },
  {
    code: '371428',
    name: '武城县',
    city_level: 3,
    lng: '116.069302',
    lat: '37.213311'
  },
  {
    code: '371481',
    name: '乐陵市',
    city_level: 3,
    lng: '117.231935',
    lat: '37.729907'
  },
  {
    code: '371482',
    name: '禹城市',
    city_level: 3,
    lng: '116.638327',
    lat: '36.933812'
  },
  {
    code: '371500',
    name: '聊城市',
    city_level: 2,
    lng: '115.985371',
    lat: '36.456703'
  },
  {
    code: '371502',
    name: '东昌府区',
    city_level: 3,
    lng: '115.988491',
    lat: '36.434645'
  },
  {
    code: '371503',
    name: '茌平区',
    city_level: 3,
    lng: '116.255270',
    lat: '36.580689'
  },
  {
    code: '371521',
    name: '阳谷县',
    city_level: 3,
    lng: '115.791820',
    lat: '36.114392'
  },
  {
    code: '371522',
    name: '莘县',
    city_level: 3,
    lng: '115.671191',
    lat: '36.233598'
  },
  {
    code: '371524',
    name: '东阿县',
    city_level: 3,
    lng: '116.247580',
    lat: '36.334917'
  },
  {
    code: '371525',
    name: '冠县',
    city_level: 3,
    lng: '115.442740',
    lat: '36.484009'
  },
  {
    code: '371526',
    name: '高唐县',
    city_level: 3,
    lng: '116.230158',
    lat: '36.846755'
  },
  {
    code: '371581',
    name: '临清市',
    city_level: 3,
    lng: '115.704881',
    lat: '36.838277'
  },
  {
    code: '371600',
    name: '滨州市',
    city_level: 2,
    lng: '117.970703',
    lat: '37.381990'
  },
  {
    code: '371602',
    name: '滨城区',
    city_level: 3,
    lng: '118.019326',
    lat: '37.430724'
  },
  {
    code: '371603',
    name: '沾化区',
    city_level: 3,
    lng: '118.098902',
    lat: '37.699260'
  },
  {
    code: '371621',
    name: '惠民县',
    city_level: 3,
    lng: '117.509921',
    lat: '37.489877'
  },
  {
    code: '371622',
    name: '阳信县',
    city_level: 3,
    lng: '117.578262',
    lat: '37.641106'
  },
  {
    code: '371623',
    name: '无棣县',
    city_level: 3,
    lng: '117.625696',
    lat: '37.770260'
  },
  {
    code: '371625',
    name: '博兴县',
    city_level: 3,
    lng: '118.131815',
    lat: '37.150226'
  },
  {
    code: '371681',
    name: '邹平市',
    city_level: 3,
    lng: '117.736809',
    lat: '36.878029'
  },
  {
    code: '371700',
    name: '菏泽市',
    city_level: 2,
    lng: '115.480656',
    lat: '35.233750'
  },
  {
    code: '371702',
    name: '牡丹区',
    city_level: 3,
    lng: '115.417827',
    lat: '35.252512'
  },
  {
    code: '371703',
    name: '定陶区',
    city_level: 3,
    lng: '115.569619',
    lat: '35.072701'
  },
  {
    code: '371721',
    name: '曹县',
    city_level: 3,
    lng: '115.542328',
    lat: '34.825508'
  },
  {
    code: '371722',
    name: '单县',
    city_level: 3,
    lng: '116.107428',
    lat: '34.778808'
  },
  {
    code: '371723',
    name: '成武县',
    city_level: 3,
    lng: '115.889765',
    lat: '34.952459'
  },
  {
    code: '371724',
    name: '巨野县',
    city_level: 3,
    lng: '116.065396',
    lat: '35.387374'
  },
  {
    code: '371725',
    name: '郓城县',
    city_level: 3,
    lng: '115.943613',
    lat: '35.599758'
  },
  {
    code: '371726',
    name: '鄄城县',
    city_level: 3,
    lng: '115.510193',
    lat: '35.563408'
  },
  {
    code: '371728',
    name: '东明县',
    city_level: 3,
    lng: '115.089905',
    lat: '35.289368'
  },
  {
    code: '410000',
    name: '河南省',
    city_level: 1,
    lng: '113.753602',
    lat: '34.765515'
  },
  {
    code: '410100',
    name: '郑州市',
    city_level: 2,
    lng: '113.625368',
    lat: '34.746599'
  },
  {
    code: '410102',
    name: '中原区',
    city_level: 3,
    lng: '113.612850',
    lat: '34.748257'
  },
  {
    code: '410103',
    name: '二七区',
    city_level: 3,
    lng: '113.640179',
    lat: '34.723930'
  },
  {
    code: '410104',
    name: '管城回族区',
    city_level: 3,
    lng: '113.677548',
    lat: '34.754500'
  },
  {
    code: '410105',
    name: '金水区',
    city_level: 3,
    lng: '113.660555',
    lat: '34.800156'
  },
  {
    code: '410106',
    name: '上街区',
    city_level: 3,
    lng: '113.308961',
    lat: '34.802780'
  },
  {
    code: '410108',
    name: '惠济区',
    city_level: 3,
    lng: '113.616901',
    lat: '34.867458'
  },
  {
    code: '410122',
    name: '中牟县',
    city_level: 3,
    lng: '113.976181',
    lat: '34.718950'
  },
  {
    code: '410181',
    name: '巩义市',
    city_level: 3,
    lng: '113.022497',
    lat: '34.747834'
  },
  {
    code: '410182',
    name: '荥阳市',
    city_level: 3,
    lng: '113.440457',
    lat: '34.745585'
  },
  {
    code: '410183',
    name: '新密市',
    city_level: 3,
    lng: '113.390891',
    lat: '34.539443'
  },
  {
    code: '410184',
    name: '新郑市',
    city_level: 3,
    lng: '113.740529',
    lat: '34.395562'
  },
  {
    code: '410185',
    name: '登封市',
    city_level: 3,
    lng: '113.050492',
    lat: '34.453667'
  },
  {
    code: '410200',
    name: '开封市',
    city_level: 2,
    lng: '114.307581',
    lat: '34.797239'
  },
  {
    code: '410202',
    name: '龙亭区',
    city_level: 3,
    lng: '114.354730',
    lat: '34.815784'
  },
  {
    code: '410203',
    name: '顺河回族区',
    city_level: 3,
    lng: '114.364875',
    lat: '34.800459'
  },
  {
    code: '410204',
    name: '鼓楼区',
    city_level: 3,
    lng: '114.348307',
    lat: '34.788561'
  },
  {
    code: '410205',
    name: '禹王台区',
    city_level: 3,
    lng: '114.348170',
    lat: '34.777104'
  },
  {
    code: '410212',
    name: '祥符区',
    city_level: 3,
    lng: '114.441327',
    lat: '34.756921'
  },
  {
    code: '410221',
    name: '杞县',
    city_level: 3,
    lng: '114.783041',
    lat: '34.549166'
  },
  {
    code: '410222',
    name: '通许县',
    city_level: 3,
    lng: '114.467467',
    lat: '34.480433'
  },
  {
    code: '410223',
    name: '尉氏县',
    city_level: 3,
    lng: '114.193081',
    lat: '34.411494'
  },
  {
    code: '410225',
    name: '兰考县',
    city_level: 3,
    lng: '114.821348',
    lat: '34.822211'
  },
  {
    code: '410300',
    name: '洛阳市',
    city_level: 2,
    lng: '112.454040',
    lat: '34.619682'
  },
  {
    code: '410302',
    name: '老城区',
    city_level: 3,
    lng: '112.469024',
    lat: '34.683646'
  },
  {
    code: '410303',
    name: '西工区',
    city_level: 3,
    lng: '112.428413',
    lat: '34.659900'
  },
  {
    code: '410304',
    name: '瀍河回族区',
    city_level: 3,
    lng: '112.500205',
    lat: '34.679850'
  },
  {
    code: '410305',
    name: '涧西区',
    city_level: 3,
    lng: '112.395756',
    lat: '34.658034'
  },
  {
    code: '410306',
    name: '吉利区',
    city_level: 3,
    lng: '112.589052',
    lat: '34.900889'
  },
  {
    code: '410311',
    name: '洛龙区',
    city_level: 3,
    lng: '112.464173',
    lat: '34.619404'
  },
  {
    code: '410322',
    name: '孟津县',
    city_level: 3,
    lng: '112.445252',
    lat: '34.825307'
  },
  {
    code: '410323',
    name: '新安县',
    city_level: 3,
    lng: '112.132488',
    lat: '34.728584'
  },
  {
    code: '410324',
    name: '栾川县',
    city_level: 3,
    lng: '111.615769',
    lat: '33.785698'
  },
  {
    code: '410325',
    name: '嵩县',
    city_level: 3,
    lng: '112.085634',
    lat: '34.134517'
  },
  {
    code: '410326',
    name: '汝阳县',
    city_level: 3,
    lng: '112.473139',
    lat: '34.153940'
  },
  {
    code: '410327',
    name: '宜阳县',
    city_level: 3,
    lng: '112.179238',
    lat: '34.514645'
  },
  {
    code: '410328',
    name: '洛宁县',
    city_level: 3,
    lng: '111.653039',
    lat: '34.389414'
  },
  {
    code: '410329',
    name: '伊川县',
    city_level: 3,
    lng: '112.425651',
    lat: '34.421460'
  },
  {
    code: '410381',
    name: '偃师市',
    city_level: 3,
    lng: '112.789535',
    lat: '34.727220'
  },
  {
    code: '410400',
    name: '平顶山市',
    city_level: 2,
    lng: '113.192661',
    lat: '33.766169'
  },
  {
    code: '410402',
    name: '新华区',
    city_level: 3,
    lng: '113.293999',
    lat: '33.737365'
  },
  {
    code: '410403',
    name: '卫东区',
    city_level: 3,
    lng: '113.335193',
    lat: '33.734707'
  },
  {
    code: '410404',
    name: '石龙区',
    city_level: 3,
    lng: '112.898818',
    lat: '33.898713'
  },
  {
    code: '410411',
    name: '湛河区',
    city_level: 3,
    lng: '113.320873',
    lat: '33.725681'
  },
  {
    code: '410421',
    name: '宝丰县',
    city_level: 3,
    lng: '113.054754',
    lat: '33.868441'
  },
  {
    code: '410422',
    name: '叶县',
    city_level: 3,
    lng: '113.357239',
    lat: '33.626731'
  },
  {
    code: '410423',
    name: '鲁山县',
    city_level: 3,
    lng: '112.908023',
    lat: '33.738518'
  },
  {
    code: '410425',
    name: '郏县',
    city_level: 3,
    lng: '113.212609',
    lat: '33.971787'
  },
  {
    code: '410481',
    name: '舞钢市',
    city_level: 3,
    lng: '113.524794',
    lat: '33.307776'
  },
  {
    code: '410482',
    name: '汝州市',
    city_level: 3,
    lng: '112.844517',
    lat: '34.167030'
  },
  {
    code: '410500',
    name: '安阳市',
    city_level: 2,
    lng: '114.392392',
    lat: '36.097577'
  },
  {
    code: '410502',
    name: '文峰区',
    city_level: 3,
    lng: '114.357082',
    lat: '36.090468'
  },
  {
    code: '410503',
    name: '北关区',
    city_level: 3,
    lng: '114.355822',
    lat: '36.107255'
  },
  {
    code: '410505',
    name: '殷都区',
    city_level: 3,
    lng: '114.303410',
    lat: '36.109890'
  },
  {
    code: '410506',
    name: '龙安区',
    city_level: 3,
    lng: '114.323522',
    lat: '36.095568'
  },
  {
    code: '410522',
    name: '安阳县',
    city_level: 3,
    lng: '114.130207',
    lat: '36.130585'
  },
  {
    code: '410523',
    name: '汤阴县',
    city_level: 3,
    lng: '114.357763',
    lat: '35.924515'
  },
  {
    code: '410526',
    name: '滑县',
    city_level: 3,
    lng: '114.518997',
    lat: '35.575002'
  },
  {
    code: '410527',
    name: '内黄县',
    city_level: 3,
    lng: '114.901492',
    lat: '35.971653'
  },
  {
    code: '410581',
    name: '林州市',
    city_level: 3,
    lng: '113.820130',
    lat: '36.083047'
  },
  {
    code: '410600',
    name: '鹤壁市',
    city_level: 2,
    lng: '114.297272',
    lat: '35.747225'
  },
  {
    code: '410602',
    name: '鹤山区',
    city_level: 3,
    lng: '114.163367',
    lat: '35.954582'
  },
  {
    code: '410603',
    name: '山城区',
    city_level: 3,
    lng: '114.184428',
    lat: '35.897703'
  },
  {
    code: '410611',
    name: '淇滨区',
    city_level: 3,
    lng: '114.298694',
    lat: '35.741296'
  },
  {
    code: '410621',
    name: '浚县',
    city_level: 3,
    lng: '114.550813',
    lat: '35.676240'
  },
  {
    code: '410622',
    name: '淇县',
    city_level: 3,
    lng: '114.197651',
    lat: '35.607762'
  },
  {
    code: '410700',
    name: '新乡市',
    city_level: 2,
    lng: '113.926800',
    lat: '35.303004'
  },
  {
    code: '410702',
    name: '红旗区',
    city_level: 3,
    lng: '113.875245',
    lat: '35.303851'
  },
  {
    code: '410703',
    name: '卫滨区',
    city_level: 3,
    lng: '113.865780',
    lat: '35.302117'
  },
  {
    code: '410704',
    name: '凤泉区',
    city_level: 3,
    lng: '113.915184',
    lat: '35.383978'
  },
  {
    code: '410711',
    name: '牧野区',
    city_level: 3,
    lng: '113.908772',
    lat: '35.315039'
  },
  {
    code: '410721',
    name: '新乡县',
    city_level: 3,
    lng: '113.805205',
    lat: '35.190836'
  },
  {
    code: '410724',
    name: '获嘉县',
    city_level: 3,
    lng: '113.657433',
    lat: '35.259808'
  },
  {
    code: '410725',
    name: '原阳县',
    city_level: 3,
    lng: '113.940115',
    lat: '35.065587'
  },
  {
    code: '410726',
    name: '延津县',
    city_level: 3,
    lng: '114.205197',
    lat: '35.141956'
  },
  {
    code: '410727',
    name: '封丘县',
    city_level: 3,
    lng: '114.418882',
    lat: '35.041198'
  },
  {
    code: '410781',
    name: '卫辉市',
    city_level: 3,
    lng: '114.064907',
    lat: '35.398494'
  },
  {
    code: '410782',
    name: '辉县市',
    city_level: 3,
    lng: '113.805468',
    lat: '35.462313'
  },
  {
    code: '410783',
    name: '长垣市',
    city_level: 3,
    lng: '114.673805',
    lat: '35.196148'
  },
  {
    code: '410800',
    name: '焦作市',
    city_level: 2,
    lng: '113.241823',
    lat: '35.215892'
  },
  {
    code: '410802',
    name: '解放区',
    city_level: 3,
    lng: '113.230817',
    lat: '35.240282'
  },
  {
    code: '410803',
    name: '中站区',
    city_level: 3,
    lng: '113.182946',
    lat: '35.236820'
  },
  {
    code: '410804',
    name: '马村区',
    city_level: 3,
    lng: '113.322332',
    lat: '35.256108'
  },
  {
    code: '410811',
    name: '山阳区',
    city_level: 3,
    lng: '113.254881',
    lat: '35.214507'
  },
  {
    code: '410821',
    name: '修武县',
    city_level: 3,
    lng: '113.447755',
    lat: '35.223514'
  },
  {
    code: '410822',
    name: '博爱县',
    city_level: 3,
    lng: '113.069211',
    lat: '35.166061'
  },
  {
    code: '410823',
    name: '武陟县',
    city_level: 3,
    lng: '113.401679',
    lat: '35.099378'
  },
  {
    code: '410825',
    name: '温县',
    city_level: 3,
    lng: '113.080530',
    lat: '34.940189'
  },
  {
    code: '410882',
    name: '沁阳市',
    city_level: 3,
    lng: '112.943069',
    lat: '35.090604'
  },
  {
    code: '410883',
    name: '孟州市',
    city_level: 3,
    lng: '112.789612',
    lat: '34.907970'
  },
  {
    code: '410900',
    name: '濮阳市',
    city_level: 2,
    lng: '115.029215',
    lat: '35.761829'
  },
  {
    code: '410902',
    name: '华龙区',
    city_level: 3,
    lng: '115.074151',
    lat: '35.777346'
  },
  {
    code: '410922',
    name: '清丰县',
    city_level: 3,
    lng: '115.104389',
    lat: '35.885180'
  },
  {
    code: '410923',
    name: '南乐县',
    city_level: 3,
    lng: '115.204752',
    lat: '36.069664'
  },
  {
    code: '410926',
    name: '范县',
    city_level: 3,
    lng: '115.504201',
    lat: '35.851907'
  },
  {
    code: '410927',
    name: '台前县',
    city_level: 3,
    lng: '115.871906',
    lat: '35.969390'
  },
  {
    code: '410928',
    name: '濮阳县',
    city_level: 3,
    lng: '115.029078',
    lat: '35.712193'
  },
  {
    code: '411000',
    name: '许昌市',
    city_level: 2,
    lng: '113.852640',
    lat: '34.035506'
  },
  {
    code: '411002',
    name: '魏都区',
    city_level: 3,
    lng: '113.822647',
    lat: '34.025342'
  },
  {
    code: '411003',
    name: '建安区',
    city_level: 3,
    lng: '113.822989',
    lat: '34.124669'
  },
  {
    code: '411024',
    name: '鄢陵县',
    city_level: 3,
    lng: '114.177400',
    lat: '34.102332'
  },
  {
    code: '411025',
    name: '襄城县',
    city_level: 3,
    lng: '113.488847',
    lat: '33.866442'
  },
  {
    code: '411081',
    name: '禹州市',
    city_level: 3,
    lng: '113.488478',
    lat: '34.140701'
  },
  {
    code: '411082',
    name: '长葛市',
    city_level: 3,
    lng: '113.819888',
    lat: '34.194136'
  },
  {
    code: '411100',
    name: '漯河市',
    city_level: 2,
    lng: '114.016539',
    lat: '33.581412'
  },
  {
    code: '411102',
    name: '源汇区',
    city_level: 3,
    lng: '113.910710',
    lat: '33.582190'
  },
  {
    code: '411103',
    name: '郾城区',
    city_level: 3,
    lng: '114.006944',
    lat: '33.587409'
  },
  {
    code: '411104',
    name: '召陵区',
    city_level: 3,
    lng: '114.093902',
    lat: '33.586565'
  },
  {
    code: '411121',
    name: '舞阳县',
    city_level: 3,
    lng: '113.609286',
    lat: '33.437877'
  },
  {
    code: '411122',
    name: '临颍县',
    city_level: 3,
    lng: '113.931203',
    lat: '33.827304'
  },
  {
    code: '411200',
    name: '三门峡市',
    city_level: 2,
    lng: '111.200135',
    lat: '34.772493'
  },
  {
    code: '411202',
    name: '湖滨区',
    city_level: 3,
    lng: '111.188398',
    lat: '34.770886'
  },
  {
    code: '411203',
    name: '陕州区',
    city_level: 3,
    lng: '111.103563',
    lat: '34.720548'
  },
  {
    code: '411221',
    name: '渑池县',
    city_level: 3,
    lng: '111.761504',
    lat: '34.767244'
  },
  {
    code: '411224',
    name: '卢氏县',
    city_level: 3,
    lng: '111.047858',
    lat: '34.054324'
  },
  {
    code: '411281',
    name: '义马市',
    city_level: 3,
    lng: '111.874393',
    lat: '34.747129'
  },
  {
    code: '411282',
    name: '灵宝市',
    city_level: 3,
    lng: '110.894220',
    lat: '34.516828'
  },
  {
    code: '411300',
    name: '南阳市',
    city_level: 2,
    lng: '112.528321',
    lat: '32.990833'
  },
  {
    code: '411302',
    name: '宛城区',
    city_level: 3,
    lng: '112.539559',
    lat: '33.003784'
  },
  {
    code: '411303',
    name: '卧龙区',
    city_level: 3,
    lng: '112.528789',
    lat: '32.989877'
  },
  {
    code: '411321',
    name: '南召县',
    city_level: 3,
    lng: '112.429133',
    lat: '33.489877'
  },
  {
    code: '411322',
    name: '方城县',
    city_level: 3,
    lng: '113.012494',
    lat: '33.254391'
  },
  {
    code: '411323',
    name: '西峡县',
    city_level: 3,
    lng: '111.473530',
    lat: '33.307294'
  },
  {
    code: '411324',
    name: '镇平县',
    city_level: 3,
    lng: '112.234698',
    lat: '33.034111'
  },
  {
    code: '411325',
    name: '内乡县',
    city_level: 3,
    lng: '111.849392',
    lat: '33.044865'
  },
  {
    code: '411326',
    name: '淅川县',
    city_level: 3,
    lng: '111.490964',
    lat: '33.137820'
  },
  {
    code: '411327',
    name: '社旗县',
    city_level: 3,
    lng: '112.948245',
    lat: '33.056109'
  },
  {
    code: '411328',
    name: '唐河县',
    city_level: 3,
    lng: '112.807637',
    lat: '32.681335'
  },
  {
    code: '411329',
    name: '新野县',
    city_level: 3,
    lng: '112.360026',
    lat: '32.520805'
  },
  {
    code: '411330',
    name: '桐柏县',
    city_level: 3,
    lng: '113.428287',
    lat: '32.380073'
  },
  {
    code: '411381',
    name: '邓州市',
    city_level: 3,
    lng: '112.087278',
    lat: '32.687938'
  },
  {
    code: '411400',
    name: '商丘市',
    city_level: 2,
    lng: '115.656370',
    lat: '34.414172'
  },
  {
    code: '411402',
    name: '梁园区',
    city_level: 3,
    lng: '115.613965',
    lat: '34.443893'
  },
  {
    code: '411403',
    name: '睢阳区',
    city_level: 3,
    lng: '115.653302',
    lat: '34.388390'
  },
  {
    code: '411421',
    name: '民权县',
    city_level: 3,
    lng: '115.179594',
    lat: '34.647758'
  },
  {
    code: '411422',
    name: '睢县',
    city_level: 3,
    lng: '115.071879',
    lat: '34.445656'
  },
  {
    code: '411423',
    name: '宁陵县',
    city_level: 3,
    lng: '115.313690',
    lat: '34.460232'
  },
  {
    code: '411424',
    name: '柘城县',
    city_level: 3,
    lng: '115.305843',
    lat: '34.091045'
  },
  {
    code: '411425',
    name: '虞城县',
    city_level: 3,
    lng: '115.840511',
    lat: '34.402512'
  },
  {
    code: '411426',
    name: '夏邑县',
    city_level: 3,
    lng: '116.131447',
    lat: '34.237554'
  },
  {
    code: '411481',
    name: '永城市',
    city_level: 3,
    lng: '116.449500',
    lat: '33.929291'
  },
  {
    code: '411500',
    name: '信阳市',
    city_level: 2,
    lng: '114.091023',
    lat: '32.146983'
  },
  {
    code: '411502',
    name: '浉河区',
    city_level: 3,
    lng: '114.058713',
    lat: '32.116803'
  },
  {
    code: '411503',
    name: '平桥区',
    city_level: 3,
    lng: '114.125656',
    lat: '32.101031'
  },
  {
    code: '411521',
    name: '罗山县',
    city_level: 3,
    lng: '114.513012',
    lat: '32.203073'
  },
  {
    code: '411522',
    name: '光山县',
    city_level: 3,
    lng: '114.919033',
    lat: '32.011103'
  },
  {
    code: '411523',
    name: '新县',
    city_level: 3,
    lng: '114.879239',
    lat: '31.643918'
  },
  {
    code: '411524',
    name: '商城县',
    city_level: 3,
    lng: '115.406862',
    lat: '31.798378'
  },
  {
    code: '411525',
    name: '固始县',
    city_level: 3,
    lng: '115.654482',
    lat: '32.168137'
  },
  {
    code: '411526',
    name: '潢川县',
    city_level: 3,
    lng: '115.051808',
    lat: '32.131383'
  },
  {
    code: '411527',
    name: '淮滨县',
    city_level: 3,
    lng: '115.419538',
    lat: '32.473258'
  },
  {
    code: '411528',
    name: '息县',
    city_level: 3,
    lng: '114.740456',
    lat: '32.342792'
  },
  {
    code: '411600',
    name: '周口市',
    city_level: 2,
    lng: '114.696951',
    lat: '33.626149'
  },
  {
    code: '411602',
    name: '川汇区',
    city_level: 3,
    lng: '114.650628',
    lat: '33.647598'
  },
  {
    code: '411603',
    name: '淮阳区',
    city_level: 3,
    lng: '114.866587',
    lat: '33.730268'
  },
  {
    code: '411621',
    name: '扶沟县',
    city_level: 3,
    lng: '114.394915',
    lat: '34.059862'
  },
  {
    code: '411622',
    name: '西华县',
    city_level: 3,
    lng: '114.425451',
    lat: '33.808682'
  },
  {
    code: '411623',
    name: '商水县',
    city_level: 3,
    lng: '114.611596',
    lat: '33.542480'
  },
  {
    code: '411624',
    name: '沈丘县',
    city_level: 3,
    lng: '115.098583',
    lat: '33.409369'
  },
  {
    code: '411625',
    name: '郸城县',
    city_level: 3,
    lng: '115.177189',
    lat: '33.644743'
  },
  {
    code: '411627',
    name: '太康县',
    city_level: 3,
    lng: '114.837887',
    lat: '34.063798'
  },
  {
    code: '411628',
    name: '鹿邑县',
    city_level: 3,
    lng: '115.484454',
    lat: '33.860000'
  },
  {
    code: '411681',
    name: '项城市',
    city_level: 3,
    lng: '114.875333',
    lat: '33.465838'
  },
  {
    code: '411700',
    name: '驻马店市',
    city_level: 2,
    lng: '114.022298',
    lat: '33.011529'
  },
  {
    code: '411702',
    name: '驿城区',
    city_level: 3,
    lng: '113.993914',
    lat: '32.973054'
  },
  {
    code: '411721',
    name: '西平县',
    city_level: 3,
    lng: '114.021539',
    lat: '33.387685'
  },
  {
    code: '411722',
    name: '上蔡县',
    city_level: 3,
    lng: '114.264381',
    lat: '33.262439'
  },
  {
    code: '411723',
    name: '平舆县',
    city_level: 3,
    lng: '114.619159',
    lat: '32.962710'
  },
  {
    code: '411724',
    name: '正阳县',
    city_level: 3,
    lng: '114.392774',
    lat: '32.605697'
  },
  {
    code: '411725',
    name: '确山县',
    city_level: 3,
    lng: '114.026430',
    lat: '32.802065'
  },
  {
    code: '411726',
    name: '泌阳县',
    city_level: 3,
    lng: '113.327144',
    lat: '32.723975'
  },
  {
    code: '411727',
    name: '汝南县',
    city_level: 3,
    lng: '114.362379',
    lat: '33.006729'
  },
  {
    code: '411728',
    name: '遂平县',
    city_level: 3,
    lng: '114.013182',
    lat: '33.145649'
  },
  {
    code: '411729',
    name: '新蔡县',
    city_level: 3,
    lng: '114.920983',
    lat: '32.811480'
  },
  {
    code: '419001',
    name: '济源市',
    city_level: 3,
    lng: '112.602344',
    lat: '35.06903'
  },
  {
    code: '420000',
    name: '湖北省',
    city_level: 1,
    lng: '114.341861',
    lat: '30.546498'
  },
  {
    code: '420100',
    name: '武汉市',
    city_level: 2,
    lng: '114.305392',
    lat: '30.593098'
  },
  {
    code: '420102',
    name: '江岸区',
    city_level: 3,
    lng: '114.278760',
    lat: '30.592688'
  },
  {
    code: '420103',
    name: '江汉区',
    city_level: 3,
    lng: '114.270871',
    lat: '30.601430'
  },
  {
    code: '420104',
    name: '硚口区',
    city_level: 3,
    lng: '114.265830',
    lat: '30.571236'
  },
  {
    code: '420105',
    name: '汉阳区',
    city_level: 3,
    lng: '114.218724',
    lat: '30.553905'
  },
  {
    code: '420106',
    name: '武昌区',
    city_level: 3,
    lng: '114.316223',
    lat: '30.554235'
  },
  {
    code: '420107',
    name: '青山区',
    city_level: 3,
    lng: '114.385539',
    lat: '30.639630'
  },
  {
    code: '420111',
    name: '洪山区',
    city_level: 3,
    lng: '114.343913',
    lat: '30.500317'
  },
  {
    code: '420112',
    name: '东西湖区',
    city_level: 3,
    lng: '114.136886',
    lat: '30.620020'
  },
  {
    code: '420113',
    name: '汉南区',
    city_level: 3,
    lng: '114.084445',
    lat: '30.308856'
  },
  {
    code: '420114',
    name: '蔡甸区',
    city_level: 3,
    lng: '114.029328',
    lat: '30.582271'
  },
  {
    code: '420115',
    name: '江夏区',
    city_level: 3,
    lng: '114.321551',
    lat: '30.375748'
  },
  {
    code: '420116',
    name: '黄陂区',
    city_level: 3,
    lng: '114.375743',
    lat: '30.882557'
  },
  {
    code: '420117',
    name: '新洲区',
    city_level: 3,
    lng: '114.801107',
    lat: '30.841544'
  },
  {
    code: '420200',
    name: '黄石市',
    city_level: 2,
    lng: '115.038520',
    lat: '30.199652'
  },
  {
    code: '420202',
    name: '黄石港区',
    city_level: 3,
    lng: '115.065978',
    lat: '30.223003'
  },
  {
    code: '420203',
    name: '西塞山区',
    city_level: 3,
    lng: '115.109955',
    lat: '30.204924'
  },
  {
    code: '420204',
    name: '下陆区',
    city_level: 3,
    lng: '114.961327',
    lat: '30.173913'
  },
  {
    code: '420205',
    name: '铁山区',
    city_level: 3,
    lng: '114.901412',
    lat: '30.206592'
  },
  {
    code: '420222',
    name: '阳新县',
    city_level: 3,
    lng: '115.215227',
    lat: '29.830258'
  },
  {
    code: '420281',
    name: '大冶市',
    city_level: 3,
    lng: '114.979875',
    lat: '30.095643'
  },
  {
    code: '420300',
    name: '十堰市',
    city_level: 2,
    lng: '110.797990',
    lat: '32.629397'
  },
  {
    code: '420302',
    name: '茅箭区',
    city_level: 3,
    lng: '110.813621',
    lat: '32.591929'
  },
  {
    code: '420303',
    name: '张湾区',
    city_level: 3,
    lng: '110.769133',
    lat: '32.652297'
  },
  {
    code: '420304',
    name: '郧阳区',
    city_level: 3,
    lng: '110.812044',
    lat: '32.834802'
  },
  {
    code: '420322',
    name: '郧西县',
    city_level: 3,
    lng: '110.425983',
    lat: '32.993182'
  },
  {
    code: '420323',
    name: '竹山县',
    city_level: 3,
    lng: '110.228694',
    lat: '32.224875'
  },
  {
    code: '420324',
    name: '竹溪县',
    city_level: 3,
    lng: '109.715304',
    lat: '32.318255'
  },
  {
    code: '420325',
    name: '房县',
    city_level: 3,
    lng: '110.726670',
    lat: '32.040085'
  },
  {
    code: '420381',
    name: '丹江口市',
    city_level: 3,
    lng: '111.513127',
    lat: '32.540157'
  },
  {
    code: '420500',
    name: '宜昌市',
    city_level: 2,
    lng: '111.286471',
    lat: '30.691967'
  },
  {
    code: '420502',
    name: '西陵区',
    city_level: 3,
    lng: '111.285646',
    lat: '30.710782'
  },
  {
    code: '420503',
    name: '伍家岗区',
    city_level: 3,
    lng: '111.361037',
    lat: '30.644334'
  },
  {
    code: '420504',
    name: '点军区',
    city_level: 3,
    lng: '111.268120',
    lat: '30.693247'
  },
  {
    code: '420505',
    name: '猇亭区',
    city_level: 3,
    lng: '111.434620',
    lat: '30.530903'
  },
  {
    code: '420506',
    name: '夷陵区',
    city_level: 3,
    lng: '111.326380',
    lat: '30.770006'
  },
  {
    code: '420525',
    name: '远安县',
    city_level: 3,
    lng: '111.640508',
    lat: '31.060869'
  },
  {
    code: '420526',
    name: '兴山县',
    city_level: 3,
    lng: '110.746805',
    lat: '31.348196'
  },
  {
    code: '420527',
    name: '秭归县',
    city_level: 3,
    lng: '110.977711',
    lat: '30.825897'
  },
  {
    code: '420528',
    name: '长阳土家族自治县',
    city_level: 3,
    lng: '111.207242',
    lat: '30.472763'
  },
  {
    code: '420529',
    name: '五峰土家族自治县',
    city_level: 3,
    lng: '110.674706',
    lat: '30.199688'
  },
  {
    code: '420581',
    name: '宜都市',
    city_level: 3,
    lng: '111.450006',
    lat: '30.378327'
  },
  {
    code: '420582',
    name: '当阳市',
    city_level: 3,
    lng: '111.788312',
    lat: '30.821266'
  },
  {
    code: '420583',
    name: '枝江市',
    city_level: 3,
    lng: '111.760530',
    lat: '30.425940'
  },
  {
    code: '420600',
    name: '襄阳市',
    city_level: 2,
    lng: '112.122414',
    lat: '32.008986'
  },
  {
    code: '420602',
    name: '襄城区',
    city_level: 3,
    lng: '112.133974',
    lat: '32.010351'
  },
  {
    code: '420606',
    name: '樊城区',
    city_level: 3,
    lng: '112.135684',
    lat: '32.044833'
  },
  {
    code: '420607',
    name: '襄州区',
    city_level: 3,
    lng: '112.211899',
    lat: '32.087298'
  },
  {
    code: '420624',
    name: '南漳县',
    city_level: 3,
    lng: '111.838905',
    lat: '31.774636'
  },
  {
    code: '420625',
    name: '谷城县',
    city_level: 3,
    lng: '111.652982',
    lat: '32.263849'
  },
  {
    code: '420626',
    name: '保康县',
    city_level: 3,
    lng: '111.261309',
    lat: '31.878310'
  },
  {
    code: '420682',
    name: '老河口市',
    city_level: 3,
    lng: '111.683861',
    lat: '32.359068'
  },
  {
    code: '420683',
    name: '枣阳市',
    city_level: 3,
    lng: '112.771959',
    lat: '32.128818'
  },
  {
    code: '420684',
    name: '宜城市',
    city_level: 3,
    lng: '112.257788',
    lat: '31.719806'
  },
  {
    code: '420700',
    name: '鄂州市',
    city_level: 2,
    lng: '114.894843',
    lat: '30.391940'
  },
  {
    code: '420702',
    name: '梁子湖区',
    city_level: 3,
    lng: '114.684731',
    lat: '30.100141'
  },
  {
    code: '420703',
    name: '华容区',
    city_level: 3,
    lng: '114.729878',
    lat: '30.534310'
  },
  {
    code: '420704',
    name: '鄂城区',
    city_level: 3,
    lng: '114.891615',
    lat: '30.400572'
  },
  {
    code: '420800',
    name: '荆门市',
    city_level: 2,
    lng: '112.199265',
    lat: '31.035423'
  },
  {
    code: '420802',
    name: '东宝区',
    city_level: 3,
    lng: '112.201493',
    lat: '31.051852'
  },
  {
    code: '420804',
    name: '掇刀区',
    city_level: 3,
    lng: '112.207833',
    lat: '30.973431'
  },
  {
    code: '420822',
    name: '沙洋县',
    city_level: 3,
    lng: '112.588581',
    lat: '30.709221'
  },
  {
    code: '420881',
    name: '钟祥市',
    city_level: 3,
    lng: '112.588121',
    lat: '31.167820'
  },
  {
    code: '420882',
    name: '京山市',
    city_level: 3,
    lng: '113.119566',
    lat: '31.018457'
  },
  {
    code: '420900',
    name: '孝感市',
    city_level: 2,
    lng: '113.916902',
    lat: '30.924568'
  },
  {
    code: '420902',
    name: '孝南区',
    city_level: 3,
    lng: '113.910958',
    lat: '30.916874'
  },
  {
    code: '420921',
    name: '孝昌县',
    city_level: 3,
    lng: '113.998010',
    lat: '31.258159'
  },
  {
    code: '420922',
    name: '大悟县',
    city_level: 3,
    lng: '114.127022',
    lat: '31.561165'
  },
  {
    code: '420923',
    name: '云梦县',
    city_level: 3,
    lng: '113.753554',
    lat: '31.020983'
  },
  {
    code: '420981',
    name: '应城市',
    city_level: 3,
    lng: '113.562806',
    lat: '30.940176'
  },
  {
    code: '420982',
    name: '安陆市',
    city_level: 3,
    lng: '113.688941',
    lat: '31.255610'
  },
  {
    code: '420984',
    name: '汉川市',
    city_level: 3,
    lng: '113.839149',
    lat: '30.661244'
  },
  {
    code: '421000',
    name: '荆州市',
    city_level: 2,
    lng: '112.239741',
    lat: '30.335165'
  },
  {
    code: '421002',
    name: '沙市区',
    city_level: 3,
    lng: '112.255583',
    lat: '30.311056'
  },
  {
    code: '421003',
    name: '荆州区',
    city_level: 3,
    lng: '112.190185',
    lat: '30.352832'
  },
  {
    code: '421022',
    name: '公安县',
    city_level: 3,
    lng: '112.229648',
    lat: '30.058336'
  },
  {
    code: '421024',
    name: '江陵县',
    city_level: 3,
    lng: '112.424664',
    lat: '30.041822'
  },
  {
    code: '421081',
    name: '石首市',
    city_level: 3,
    lng: '112.425454',
    lat: '29.720938'
  },
  {
    code: '421083',
    name: '洪湖市',
    city_level: 3,
    lng: '113.475980',
    lat: '29.825458'
  },
  {
    code: '421087',
    name: '松滋市',
    city_level: 3,
    lng: '111.756773',
    lat: '30.174522'
  },
  {
    code: '421088',
    name: '监利市',
    city_level: 2,
    lng: '112.239741',
    lat: '30.335165'
  },
  {
    code: '421100',
    name: '黄冈市',
    city_level: 2,
    lng: '114.872316',
    lat: '30.453905'
  },
  {
    code: '421102',
    name: '黄州区',
    city_level: 3,
    lng: '114.879398',
    lat: '30.434050'
  },
  {
    code: '421121',
    name: '团风县',
    city_level: 3,
    lng: '114.872191',
    lat: '30.643569'
  },
  {
    code: '421122',
    name: '红安县',
    city_level: 3,
    lng: '114.618236',
    lat: '31.288153'
  },
  {
    code: '421123',
    name: '罗田县',
    city_level: 3,
    lng: '115.399492',
    lat: '30.783100'
  },
  {
    code: '421124',
    name: '英山县',
    city_level: 3,
    lng: '115.681259',
    lat: '30.734959'
  },
  {
    code: '421125',
    name: '浠水县',
    city_level: 3,
    lng: '115.265535',
    lat: '30.451867'
  },
  {
    code: '421126',
    name: '蕲春县',
    city_level: 3,
    lng: '115.437008',
    lat: '30.225964'
  },
  {
    code: '421127',
    name: '黄梅县',
    city_level: 3,
    lng: '115.944219',
    lat: '30.070454'
  },
  {
    code: '421181',
    name: '麻城市',
    city_level: 3,
    lng: '115.008163',
    lat: '31.172740'
  },
  {
    code: '421182',
    name: '武穴市',
    city_level: 3,
    lng: '115.561217',
    lat: '29.844107'
  },
  {
    code: '421200',
    name: '咸宁市',
    city_level: 2,
    lng: '114.322492',
    lat: '29.841443'
  },
  {
    code: '421202',
    name: '咸安区',
    city_level: 3,
    lng: '114.298711',
    lat: '29.852892'
  },
  {
    code: '421221',
    name: '嘉鱼县',
    city_level: 3,
    lng: '113.939277',
    lat: '29.970737'
  },
  {
    code: '421222',
    name: '通城县',
    city_level: 3,
    lng: '113.816966',
    lat: '29.245269'
  },
  {
    code: '421223',
    name: '崇阳县',
    city_level: 3,
    lng: '114.039828',
    lat: '29.555605'
  },
  {
    code: '421224',
    name: '通山县',
    city_level: 3,
    lng: '114.482606',
    lat: '29.605376'
  },
  {
    code: '421281',
    name: '赤壁市',
    city_level: 3,
    lng: '113.900628',
    lat: '29.724692'
  },
  {
    code: '421300',
    name: '随州市',
    city_level: 2,
    lng: '113.382458',
    lat: '31.690215'
  },
  {
    code: '421303',
    name: '曾都区',
    city_level: 3,
    lng: '113.371121',
    lat: '31.716280'
  },
  {
    code: '421321',
    name: '随县',
    city_level: 3,
    lng: '113.299528',
    lat: '31.853833'
  },
  {
    code: '421381',
    name: '广水市',
    city_level: 3,
    lng: '113.825890',
    lat: '31.616854'
  },
  {
    code: '422800',
    name: '恩施土家族苗族自治州',
    city_level: 2,
    lng: '109.488172',
    lat: '30.272156'
  },
  {
    code: '422801',
    name: '恩施市',
    city_level: 2,
    lng: '109.488172',
    lat: '30.272156'
  },
  {
    code: '422802',
    name: '利川市',
    city_level: 3,
    lng: '108.909996',
    lat: '30.210981'
  },
  {
    code: '422822',
    name: '建始县',
    city_level: 3,
    lng: '109.718202',
    lat: '30.596727'
  },
  {
    code: '422823',
    name: '巴东县',
    city_level: 3,
    lng: '110.340756',
    lat: '31.042324'
  },
  {
    code: '422825',
    name: '宣恩县',
    city_level: 3,
    lng: '109.491485',
    lat: '29.986899'
  },
  {
    code: '422826',
    name: '咸丰县',
    city_level: 3,
    lng: '109.139726',
    lat: '29.665203'
  },
  {
    code: '422827',
    name: '来凤县',
    city_level: 3,
    lng: '109.407828',
    lat: '29.493485'
  },
  {
    code: '422828',
    name: '鹤峰县',
    city_level: 3,
    lng: '110.033662',
    lat: '29.890171'
  },
  {
    code: '429004',
    name: '仙桃市',
    city_level: 3,
    lng: '113.453974',
    lat: '30.364953'
  },
  {
    code: '429005',
    name: '潜江市',
    city_level: 3,
    lng: '112.900287',
    lat: '30.401947'
  },
  {
    code: '429006',
    name: '天门市',
    city_level: 3,
    lng: '113.166558',
    lat: '30.66372'
  },
  {
    code: '429021',
    name: '神农架林区',
    city_level: 3,
    lng: '110.675757',
    lat: '31.744897'
  },
  {
    code: '430000',
    name: '湖南省',
    city_level: 1,
    lng: '112.983810',
    lat: '28.112444'
  },
  {
    code: '430100',
    name: '长沙市',
    city_level: 2,
    lng: '112.938814',
    lat: '28.228209'
  },
  {
    code: '430102',
    name: '芙蓉区',
    city_level: 3,
    lng: '113.032539',
    lat: '28.185386'
  },
  {
    code: '430103',
    name: '天心区',
    city_level: 3,
    lng: '112.996200',
    lat: '28.144470'
  },
  {
    code: '430104',
    name: '岳麓区',
    city_level: 3,
    lng: '112.931420',
    lat: '28.235193'
  },
  {
    code: '430105',
    name: '开福区',
    city_level: 3,
    lng: '112.984200',
    lat: '28.205628'
  },
  {
    code: '430111',
    name: '雨花区',
    city_level: 3,
    lng: '113.038017',
    lat: '28.137710'
  },
  {
    code: '430112',
    name: '望城区',
    city_level: 3,
    lng: '112.819549',
    lat: '28.347458'
  },
  {
    code: '430121',
    name: '长沙县',
    city_level: 3,
    lng: '113.080810',
    lat: '28.246150'
  },
  {
    code: '430181',
    name: '浏阳市',
    city_level: 3,
    lng: '113.643076',
    lat: '28.162833'
  },
  {
    code: '430182',
    name: '宁乡市',
    city_level: 3,
    lng: '112.553185',
    lat: '28.253927'
  },
  {
    code: '430200',
    name: '株洲市',
    city_level: 2,
    lng: '113.134002',
    lat: '27.827550'
  },
  {
    code: '430202',
    name: '荷塘区',
    city_level: 3,
    lng: '113.173487',
    lat: '27.855929'
  },
  {
    code: '430203',
    name: '芦淞区',
    city_level: 3,
    lng: '113.152724',
    lat: '27.785070'
  },
  {
    code: '430204',
    name: '石峰区',
    city_level: 3,
    lng: '113.117732',
    lat: '27.875445'
  },
  {
    code: '430211',
    name: '天元区',
    city_level: 3,
    lng: '113.082216',
    lat: '27.826867'
  },
  {
    code: '430212',
    name: '渌口区',
    city_level: 3,
    lng: '113.139967',
    lat: '27.702526'
  },
  {
    code: '430223',
    name: '攸县',
    city_level: 3,
    lng: '113.396404',
    lat: '27.014607'
  },
  {
    code: '430224',
    name: '茶陵县',
    city_level: 3,
    lng: '113.539280',
    lat: '26.777492'
  },
  {
    code: '430225',
    name: '炎陵县',
    city_level: 3,
    lng: '113.772655',
    lat: '26.489902'
  },
  {
    code: '430281',
    name: '醴陵市',
    city_level: 3,
    lng: '113.496894',
    lat: '27.646130'
  },
  {
    code: '430300',
    name: '湘潭市',
    city_level: 2,
    lng: '112.944049',
    lat: '27.829738'
  },
  {
    code: '430302',
    name: '雨湖区',
    city_level: 3,
    lng: '112.903317',
    lat: '27.854705'
  },
  {
    code: '430304',
    name: '岳塘区',
    city_level: 3,
    lng: '112.925371',
    lat: '27.808646'
  },
  {
    code: '430321',
    name: '湘潭县',
    city_level: 3,
    lng: '112.950781',
    lat: '27.778947'
  },
  {
    code: '430381',
    name: '湘乡市',
    city_level: 3,
    lng: '112.550581',
    lat: '27.718313'
  },
  {
    code: '430382',
    name: '韶山市',
    city_level: 3,
    lng: '112.526670',
    lat: '27.914958'
  },
  {
    code: '430400',
    name: '衡阳市',
    city_level: 2,
    lng: '112.571997',
    lat: '26.893230'
  },
  {
    code: '430405',
    name: '珠晖区',
    city_level: 3,
    lng: '112.620112',
    lat: '26.894657'
  },
  {
    code: '430406',
    name: '雁峰区',
    city_level: 2,
    lng: '112.571997',
    lat: '26.893230'
  },
  {
    code: '430407',
    name: '石鼓区',
    city_level: 3,
    lng: '112.598089',
    lat: '26.943215'
  },
  {
    code: '430408',
    name: '蒸湘区',
    city_level: 3,
    lng: '112.567064',
    lat: '26.911404'
  },
  {
    code: '430412',
    name: '南岳区',
    city_level: 3,
    lng: '112.738604',
    lat: '27.232444'
  },
  {
    code: '430421',
    name: '衡阳县',
    city_level: 3,
    lng: '112.370532',
    lat: '26.969635'
  },
  {
    code: '430422',
    name: '衡南县',
    city_level: 3,
    lng: '112.677877',
    lat: '26.738248'
  },
  {
    code: '430423',
    name: '衡山县',
    city_level: 3,
    lng: '112.868268',
    lat: '27.230291'
  },
  {
    code: '430424',
    name: '衡东县',
    city_level: 3,
    lng: '112.953168',
    lat: '27.081170'
  },
  {
    code: '430426',
    name: '祁东县',
    city_level: 3,
    lng: '112.090357',
    lat: '26.799896'
  },
  {
    code: '430481',
    name: '耒阳市',
    city_level: 3,
    lng: '112.859795',
    lat: '26.422275'
  },
  {
    code: '430482',
    name: '常宁市',
    city_level: 3,
    lng: '112.399995',
    lat: '26.420932'
  },
  {
    code: '430500',
    name: '邵阳市',
    city_level: 2,
    lng: '111.467791',
    lat: '27.238892'
  },
  {
    code: '430502',
    name: '双清区',
    city_level: 3,
    lng: '111.496341',
    lat: '27.232708'
  },
  {
    code: '430503',
    name: '大祥区',
    city_level: 3,
    lng: '111.439091',
    lat: '27.221452'
  },
  {
    code: '430511',
    name: '北塔区',
    city_level: 3,
    lng: '111.452197',
    lat: '27.246489'
  },
  {
    code: '430522',
    name: '新邵县',
    city_level: 3,
    lng: '111.458657',
    lat: '27.320918'
  },
  {
    code: '430523',
    name: '邵阳县',
    city_level: 3,
    lng: '111.273806',
    lat: '26.990637'
  },
  {
    code: '430524',
    name: '隆回县',
    city_level: 3,
    lng: '111.032438',
    lat: '27.113978'
  },
  {
    code: '430525',
    name: '洞口县',
    city_level: 3,
    lng: '110.575846',
    lat: '27.060321'
  },
  {
    code: '430527',
    name: '绥宁县',
    city_level: 3,
    lng: '110.155655',
    lat: '26.581955'
  },
  {
    code: '430528',
    name: '新宁县',
    city_level: 3,
    lng: '110.856623',
    lat: '26.433418'
  },
  {
    code: '430529',
    name: '城步苗族自治县',
    city_level: 3,
    lng: '110.322240',
    lat: '26.390598'
  },
  {
    code: '430581',
    name: '武冈市',
    city_level: 3,
    lng: '110.631884',
    lat: '26.726599'
  },
  {
    code: '430582',
    name: '邵东市',
    city_level: 3,
    lng: '111.743172',
    lat: '27.257273'
  },
  {
    code: '430600',
    name: '岳阳市',
    city_level: 2,
    lng: '113.128958',
    lat: '29.357104'
  },
  {
    code: '430602',
    name: '岳阳楼区',
    city_level: 3,
    lng: '113.129702',
    lat: '29.371903'
  },
  {
    code: '430603',
    name: '云溪区',
    city_level: 3,
    lng: '113.272313',
    lat: '29.472746'
  },
  {
    code: '430611',
    name: '君山区',
    city_level: 3,
    lng: '113.006435',
    lat: '29.461106'
  },
  {
    code: '430621',
    name: '岳阳县',
    city_level: 3,
    lng: '113.116418',
    lat: '29.144067'
  },
  {
    code: '430623',
    name: '华容县',
    city_level: 3,
    lng: '112.540463',
    lat: '29.531057'
  },
  {
    code: '430624',
    name: '湘阴县',
    city_level: 3,
    lng: '112.909426',
    lat: '28.689105'
  },
  {
    code: '430626',
    name: '平江县',
    city_level: 3,
    lng: '113.581234',
    lat: '28.701868'
  },
  {
    code: '430681',
    name: '汨罗市',
    city_level: 3,
    lng: '113.067259',
    lat: '28.806890'
  },
  {
    code: '430682',
    name: '临湘市',
    city_level: 3,
    lng: '113.450423',
    lat: '29.476849'
  },
  {
    code: '430700',
    name: '常德市',
    city_level: 2,
    lng: '111.698497',
    lat: '29.031673'
  },
  {
    code: '430702',
    name: '武陵区',
    city_level: 3,
    lng: '111.683153',
    lat: '29.055163'
  },
  {
    code: '430703',
    name: '鼎城区',
    city_level: 3,
    lng: '111.680783',
    lat: '29.018593'
  },
  {
    code: '430721',
    name: '安乡县',
    city_level: 3,
    lng: '112.171131',
    lat: '29.411309'
  },
  {
    code: '430722',
    name: '汉寿县',
    city_level: 3,
    lng: '111.970514',
    lat: '28.906107'
  },
  {
    code: '430723',
    name: '澧县',
    city_level: 3,
    lng: '111.758702',
    lat: '29.633237'
  },
  {
    code: '430724',
    name: '临澧县',
    city_level: 3,
    lng: '111.647518',
    lat: '29.440793'
  },
  {
    code: '430725',
    name: '桃源县',
    city_level: 3,
    lng: '111.488925',
    lat: '28.902503'
  },
  {
    code: '430726',
    name: '石门县',
    city_level: 3,
    lng: '111.380014',
    lat: '29.584293'
  },
  {
    code: '430781',
    name: '津市市',
    city_level: 3,
    lng: '111.877499',
    lat: '29.605480'
  },
  {
    code: '430800',
    name: '张家界市',
    city_level: 2,
    lng: '110.479191',
    lat: '29.117096'
  },
  {
    code: '430802',
    name: '永定区',
    city_level: 3,
    lng: '110.537138',
    lat: '29.119856'
  },
  {
    code: '430811',
    name: '武陵源区',
    city_level: 3,
    lng: '110.550434',
    lat: '29.345730'
  },
  {
    code: '430821',
    name: '慈利县',
    city_level: 3,
    lng: '111.139711',
    lat: '29.429972'
  },
  {
    code: '430822',
    name: '桑植县',
    city_level: 3,
    lng: '110.204911',
    lat: '29.414264'
  },
  {
    code: '430900',
    name: '益阳市',
    city_level: 2,
    lng: '112.355180',
    lat: '28.553860'
  },
  {
    code: '430902',
    name: '资阳区',
    city_level: 3,
    lng: '112.324322',
    lat: '28.590966'
  },
  {
    code: '430903',
    name: '赫山区',
    city_level: 3,
    lng: '112.374024',
    lat: '28.579343'
  },
  {
    code: '430921',
    name: '南县',
    city_level: 3,
    lng: '112.396241',
    lat: '29.361338'
  },
  {
    code: '430922',
    name: '桃江县',
    city_level: 3,
    lng: '112.155822',
    lat: '28.518085'
  },
  {
    code: '430923',
    name: '安化县',
    city_level: 3,
    lng: '111.212846',
    lat: '28.374107'
  },
  {
    code: '430981',
    name: '沅江市',
    city_level: 3,
    lng: '112.354661',
    lat: '28.845837'
  },
  {
    code: '431000',
    name: '郴州市',
    city_level: 2,
    lng: '113.014717',
    lat: '25.770509'
  },
  {
    code: '431002',
    name: '北湖区',
    city_level: 3,
    lng: '113.011035',
    lat: '25.784054'
  },
  {
    code: '431003',
    name: '苏仙区',
    city_level: 3,
    lng: '113.042441',
    lat: '25.800370'
  },
  {
    code: '431021',
    name: '桂阳县',
    city_level: 3,
    lng: '112.734176',
    lat: '25.754167'
  },
  {
    code: '431022',
    name: '宜章县',
    city_level: 3,
    lng: '112.948811',
    lat: '25.400222'
  },
  {
    code: '431023',
    name: '永兴县',
    city_level: 3,
    lng: '113.116528',
    lat: '26.127151'
  },
  {
    code: '431024',
    name: '嘉禾县',
    city_level: 3,
    lng: '112.369021',
    lat: '25.587520'
  },
  {
    code: '431025',
    name: '临武县',
    city_level: 3,
    lng: '112.563456',
    lat: '25.275560'
  },
  {
    code: '431026',
    name: '汝城县',
    city_level: 3,
    lng: '113.684727',
    lat: '25.532816'
  },
  {
    code: '431027',
    name: '桂东县',
    city_level: 3,
    lng: '113.944614',
    lat: '26.077616'
  },
  {
    code: '431028',
    name: '安仁县',
    city_level: 3,
    lng: '113.269396',
    lat: '26.709056'
  },
  {
    code: '431081',
    name: '资兴市',
    city_level: 3,
    lng: '113.236146',
    lat: '25.976243'
  },
  {
    code: '431100',
    name: '永州市',
    city_level: 2,
    lng: '111.613445',
    lat: '26.420394'
  },
  {
    code: '431102',
    name: '零陵区',
    city_level: 3,
    lng: '111.631109',
    lat: '26.221936'
  },
  {
    code: '431103',
    name: '冷水滩区',
    city_level: 3,
    lng: '111.592143',
    lat: '26.461077'
  },
  {
    code: '431121',
    name: '祁阳县',
    city_level: 3,
    lng: '111.840657',
    lat: '26.580120'
  },
  {
    code: '431122',
    name: '东安县',
    city_level: 3,
    lng: '111.314117',
    lat: '26.394404'
  },
  {
    code: '431123',
    name: '双牌县',
    city_level: 3,
    lng: '111.659967',
    lat: '25.961910'
  },
  {
    code: '431124',
    name: '道县',
    city_level: 3,
    lng: '111.600796',
    lat: '25.526438'
  },
  {
    code: '431125',
    name: '江永县',
    city_level: 3,
    lng: '111.343911',
    lat: '25.273539'
  },
  {
    code: '431126',
    name: '宁远县',
    city_level: 3,
    lng: '111.945805',
    lat: '25.570976'
  },
  {
    code: '431127',
    name: '蓝山县',
    city_level: 3,
    lng: '112.196731',
    lat: '25.369898'
  },
  {
    code: '431128',
    name: '新田县',
    city_level: 3,
    lng: '112.203287',
    lat: '25.904305'
  },
  {
    code: '431129',
    name: '江华瑶族自治县',
    city_level: 3,
    lng: '111.579305',
    lat: '25.185590'
  },
  {
    code: '431200',
    name: '怀化市',
    city_level: 2,
    lng: '109.998488',
    lat: '27.554978'
  },
  {
    code: '431202',
    name: '鹤城区',
    city_level: 3,
    lng: '110.040315',
    lat: '27.578926'
  },
  {
    code: '431221',
    name: '中方县',
    city_level: 3,
    lng: '109.944712',
    lat: '27.440139'
  },
  {
    code: '431222',
    name: '沅陵县',
    city_level: 3,
    lng: '110.393844',
    lat: '28.452686'
  },
  {
    code: '431223',
    name: '辰溪县',
    city_level: 3,
    lng: '110.183917',
    lat: '28.006336'
  },
  {
    code: '431224',
    name: '溆浦县',
    city_level: 3,
    lng: '110.594972',
    lat: '27.908316'
  },
  {
    code: '431225',
    name: '会同县',
    city_level: 3,
    lng: '109.735661',
    lat: '26.887239'
  },
  {
    code: '431226',
    name: '麻阳苗族自治县',
    city_level: 3,
    lng: '109.802587',
    lat: '27.865548'
  },
  {
    code: '431227',
    name: '新晃侗族自治县',
    city_level: 3,
    lng: '109.174932',
    lat: '27.352673'
  },
  {
    code: '431228',
    name: '芷江侗族自治县',
    city_level: 3,
    lng: '109.684629',
    lat: '27.443499'
  },
  {
    code: '431229',
    name: '靖州苗族侗族自治县',
    city_level: 3,
    lng: '109.696311',
    lat: '26.575052'
  },
  {
    code: '431230',
    name: '通道侗族自治县',
    city_level: 3,
    lng: '109.784412',
    lat: '26.158054'
  },
  {
    code: '431281',
    name: '洪江市',
    city_level: 3,
    lng: '109.836669',
    lat: '27.208609'
  },
  {
    code: '431300',
    name: '娄底市',
    city_level: 2,
    lng: '111.993497',
    lat: '27.700062'
  },
  {
    code: '431302',
    name: '娄星区',
    city_level: 3,
    lng: '112.001937',
    lat: '27.729907'
  },
  {
    code: '431321',
    name: '双峰县',
    city_level: 3,
    lng: '112.175246',
    lat: '27.456658'
  },
  {
    code: '431322',
    name: '新化县',
    city_level: 3,
    lng: '111.327412',
    lat: '27.726515'
  },
  {
    code: '431381',
    name: '冷水江市',
    city_level: 3,
    lng: '111.435623',
    lat: '27.685850'
  },
  {
    code: '431382',
    name: '涟源市',
    city_level: 3,
    lng: '111.664316',
    lat: '27.692542'
  },
  {
    code: '433100',
    name: '湘西土家族苗族自治州',
    city_level: 2,
    lng: '109.739172',
    lat: '28.311947'
  },
  {
    code: '433101',
    name: '吉首市',
    city_level: 3,
    lng: '109.926773',
    lat: '28.297201'
  },
  {
    code: '433122',
    name: '泸溪县',
    city_level: 3,
    lng: '110.219610',
    lat: '28.216641'
  },
  {
    code: '433123',
    name: '凤凰县',
    city_level: 3,
    lng: '109.581072',
    lat: '27.958062'
  },
  {
    code: '433124',
    name: '花垣县',
    city_level: 3,
    lng: '109.482078',
    lat: '28.572030'
  },
  {
    code: '433125',
    name: '保靖县',
    city_level: 3,
    lng: '109.660577',
    lat: '28.699774'
  },
  {
    code: '433126',
    name: '古丈县',
    city_level: 3,
    lng: '109.950728',
    lat: '28.616935'
  },
  {
    code: '433127',
    name: '永顺县',
    city_level: 3,
    lng: '109.851254',
    lat: '29.001440'
  },
  {
    code: '433130',
    name: '龙山县',
    city_level: 3,
    lng: '109.443939',
    lat: '29.457663'
  },
  {
    code: '440000',
    name: '广东省',
    city_level: 1,
    lng: '113.266530',
    lat: '23.132191'
  },
  {
    code: '440100',
    name: '广州市',
    city_level: 2,
    lng: '113.264434',
    lat: '23.129162'
  },
  {
    code: '440103',
    name: '荔湾区',
    city_level: 3,
    lng: '113.244261',
    lat: '23.125981'
  },
  {
    code: '440104',
    name: '越秀区',
    city_level: 3,
    lng: '113.266841',
    lat: '23.128524'
  },
  {
    code: '440105',
    name: '海珠区',
    city_level: 3,
    lng: '113.317388',
    lat: '23.083801'
  },
  {
    code: '440106',
    name: '天河区',
    city_level: 3,
    lng: '113.361200',
    lat: '23.124680'
  },
  {
    code: '440111',
    name: '白云区',
    city_level: 3,
    lng: '113.273289',
    lat: '23.157290'
  },
  {
    code: '440112',
    name: '黄埔区',
    city_level: 3,
    lng: '113.459749',
    lat: '23.106402'
  },
  {
    code: '440113',
    name: '番禺区',
    city_level: 3,
    lng: '113.384129',
    lat: '22.937244'
  },
  {
    code: '440114',
    name: '花都区',
    city_level: 3,
    lng: '113.220218',
    lat: '23.404165'
  },
  {
    code: '440115',
    name: '南沙区',
    city_level: 3,
    lng: '113.525165',
    lat: '22.801624'
  },
  {
    code: '440117',
    name: '从化区',
    city_level: 3,
    lng: '113.586605',
    lat: '23.548852'
  },
  {
    code: '440118',
    name: '增城区',
    city_level: 3,
    lng: '113.810860',
    lat: '23.261141'
  },
  {
    code: '440200',
    name: '韶关市',
    city_level: 2,
    lng: '113.597522',
    lat: '24.810403'
  },
  {
    code: '440203',
    name: '武江区',
    city_level: 3,
    lng: '113.587774',
    lat: '24.792924'
  },
  {
    code: '440204',
    name: '浈江区',
    city_level: 3,
    lng: '113.611098',
    lat: '24.804381'
  },
  {
    code: '440205',
    name: '曲江区',
    city_level: 3,
    lng: '113.604549',
    lat: '24.682728'
  },
  {
    code: '440222',
    name: '始兴县',
    city_level: 3,
    lng: '114.061789',
    lat: '24.952977'
  },
  {
    code: '440224',
    name: '仁化县',
    city_level: 3,
    lng: '113.749027',
    lat: '25.085621'
  },
  {
    code: '440229',
    name: '翁源县',
    city_level: 3,
    lng: '114.130342',
    lat: '24.350347'
  },
  {
    code: '440232',
    name: '乳源瑶族自治县',
    city_level: 3,
    lng: '113.275883',
    lat: '24.776078'
  },
  {
    code: '440233',
    name: '新丰县',
    city_level: 3,
    lng: '114.206867',
    lat: '24.059760'
  },
  {
    code: '440281',
    name: '乐昌市',
    city_level: 3,
    lng: '113.347519',
    lat: '25.130136'
  },
  {
    code: '440282',
    name: '南雄市',
    city_level: 3,
    lng: '114.311982',
    lat: '25.117753'
  },
  {
    code: '440300',
    name: '深圳市',
    city_level: 2,
    lng: '114.057868',
    lat: '22.543099'
  },
  {
    code: '440303',
    name: '罗湖区',
    city_level: 3,
    lng: '114.131764',
    lat: '22.548171'
  },
  {
    code: '440304',
    name: '福田区',
    city_level: 3,
    lng: '114.055036',
    lat: '22.521530'
  },
  {
    code: '440305',
    name: '南山区',
    city_level: 3,
    lng: '113.923552',
    lat: '22.528499'
  },
  {
    code: '440306',
    name: '宝安区',
    city_level: 3,
    lng: '113.884020',
    lat: '22.555259'
  },
  {
    code: '440307',
    name: '龙岗区',
    city_level: 3,
    lng: '114.246899',
    lat: '22.720968'
  },
  {
    code: '440308',
    name: '盐田区',
    city_level: 3,
    lng: '114.236875',
    lat: '22.556499'
  },
  {
    code: '440309',
    name: '龙华区',
    city_level: 3,
    lng: '110.328492',
    lat: '20.031007'
  },
  {
    code: '440310',
    name: '坪山区',
    city_level: 2,
    lng: '114.057868',
    lat: '22.543099'
  },
  {
    code: '440311',
    name: '光明区',
    city_level: 3,
    lng: '109.056572',
    lat: '34.297810'
  },
  {
    code: '440400',
    name: '珠海市',
    city_level: 2,
    lng: '113.576726',
    lat: '22.270715'
  },
  {
    code: '440402',
    name: '香洲区',
    city_level: 3,
    lng: '113.543785',
    lat: '22.265811'
  },
  {
    code: '440403',
    name: '斗门区',
    city_level: 3,
    lng: '113.296467',
    lat: '22.209200'
  },
  {
    code: '440404',
    name: '金湾区',
    city_level: 3,
    lng: '113.363393',
    lat: '22.146874'
  },
  {
    code: '440500',
    name: '汕头市',
    city_level: 2,
    lng: '116.681972',
    lat: '23.354091'
  },
  {
    code: '440507',
    name: '龙湖区',
    city_level: 3,
    lng: '116.716534',
    lat: '23.371476'
  },
  {
    code: '440511',
    name: '金平区',
    city_level: 3,
    lng: '116.703426',
    lat: '23.365613'
  },
  {
    code: '440512',
    name: '濠江区',
    city_level: 3,
    lng: '116.726973',
    lat: '23.286079'
  },
  {
    code: '440513',
    name: '潮阳区',
    city_level: 3,
    lng: '116.601515',
    lat: '23.264936'
  },
  {
    code: '440514',
    name: '潮南区',
    city_level: 3,
    lng: '116.433017',
    lat: '23.250425'
  },
  {
    code: '440515',
    name: '澄海区',
    city_level: 3,
    lng: '116.756092',
    lat: '23.465960'
  },
  {
    code: '440523',
    name: '南澳县',
    city_level: 3,
    lng: '117.023374',
    lat: '23.421724'
  },
  {
    code: '440600',
    name: '佛山市',
    city_level: 2,
    lng: '113.121416',
    lat: '23.021548'
  },
  {
    code: '440604',
    name: '禅城区',
    city_level: 3,
    lng: '113.122440',
    lat: '23.009505'
  },
  {
    code: '440605',
    name: '南海区',
    city_level: 3,
    lng: '113.147759',
    lat: '23.025412'
  },
  {
    code: '440606',
    name: '顺德区',
    city_level: 3,
    lng: '113.293359',
    lat: '22.805240'
  },
  {
    code: '440607',
    name: '三水区',
    city_level: 3,
    lng: '112.896668',
    lat: '23.156065'
  },
  {
    code: '440608',
    name: '高明区',
    city_level: 3,
    lng: '112.892578',
    lat: '22.900182'
  },
  {
    code: '440700',
    name: '江门市',
    city_level: 2,
    lng: '113.081901',
    lat: '22.578738'
  },
  {
    code: '440703',
    name: '蓬江区',
    city_level: 3,
    lng: '113.078521',
    lat: '22.595149'
  },
  {
    code: '440704',
    name: '江海区',
    city_level: 3,
    lng: '113.111612',
    lat: '22.560474'
  },
  {
    code: '440705',
    name: '新会区',
    city_level: 3,
    lng: '113.034187',
    lat: '22.458300'
  },
  {
    code: '440781',
    name: '台山市',
    city_level: 3,
    lng: '112.795970',
    lat: '22.251596'
  },
  {
    code: '440783',
    name: '开平市',
    city_level: 3,
    lng: '112.698545',
    lat: '22.376395'
  },
  {
    code: '440784',
    name: '鹤山市',
    city_level: 3,
    lng: '112.964446',
    lat: '22.765392'
  },
  {
    code: '440785',
    name: '恩平市',
    city_level: 3,
    lng: '112.305145',
    lat: '22.183206'
  },
  {
    code: '440800',
    name: '湛江市',
    city_level: 2,
    lng: '110.359377',
    lat: '21.270707'
  },
  {
    code: '440802',
    name: '赤坎区',
    city_level: 3,
    lng: '110.365900',
    lat: '21.266119'
  },
  {
    code: '440803',
    name: '霞山区',
    city_level: 3,
    lng: '110.398070',
    lat: '21.191720'
  },
  {
    code: '440804',
    name: '坡头区',
    city_level: 3,
    lng: '110.455332',
    lat: '21.244721'
  },
  {
    code: '440811',
    name: '麻章区',
    city_level: 3,
    lng: '110.334387',
    lat: '21.263443'
  },
  {
    code: '440823',
    name: '遂溪县',
    city_level: 3,
    lng: '110.250124',
    lat: '21.377246'
  },
  {
    code: '440825',
    name: '徐闻县',
    city_level: 3,
    lng: '110.176750',
    lat: '20.325489'
  },
  {
    code: '440881',
    name: '廉江市',
    city_level: 3,
    lng: '110.286209',
    lat: '21.609700'
  },
  {
    code: '440882',
    name: '雷州市',
    city_level: 3,
    lng: '110.096749',
    lat: '20.914278'
  },
  {
    code: '440883',
    name: '吴川市',
    city_level: 3,
    lng: '110.778411',
    lat: '21.441808'
  },
  {
    code: '440900',
    name: '茂名市',
    city_level: 2,
    lng: '110.925456',
    lat: '21.662999'
  },
  {
    code: '440902',
    name: '茂南区',
    city_level: 3,
    lng: '110.918026',
    lat: '21.641337'
  },
  {
    code: '440904',
    name: '电白区',
    city_level: 3,
    lng: '111.013556',
    lat: '21.514164'
  },
  {
    code: '440981',
    name: '高州市',
    city_level: 3,
    lng: '110.853302',
    lat: '21.917982'
  },
  {
    code: '440982',
    name: '化州市',
    city_level: 3,
    lng: '110.639569',
    lat: '21.664044'
  },
  {
    code: '440983',
    name: '信宜市',
    city_level: 3,
    lng: '110.947044',
    lat: '22.354385'
  },
  {
    code: '441200',
    name: '肇庆市',
    city_level: 2,
    lng: '112.465091',
    lat: '23.047191'
  },
  {
    code: '441202',
    name: '端州区',
    city_level: 3,
    lng: '112.484848',
    lat: '23.052101'
  },
  {
    code: '441203',
    name: '鼎湖区',
    city_level: 3,
    lng: '112.567588',
    lat: '23.158447'
  },
  {
    code: '441204',
    name: '高要区',
    city_level: 3,
    lng: '112.457771',
    lat: '23.025668'
  },
  {
    code: '441223',
    name: '广宁县',
    city_level: 3,
    lng: '112.440690',
    lat: '23.634676'
  },
  {
    code: '441224',
    name: '怀集县',
    city_level: 3,
    lng: '112.184652',
    lat: '23.911899'
  },
  {
    code: '441225',
    name: '封开县',
    city_level: 3,
    lng: '111.512343',
    lat: '23.424033'
  },
  {
    code: '441226',
    name: '德庆县',
    city_level: 3,
    lng: '111.785937',
    lat: '23.143722'
  },
  {
    code: '441284',
    name: '四会市',
    city_level: 3,
    lng: '112.733773',
    lat: '23.326504'
  },
  {
    code: '441300',
    name: '惠州市',
    city_level: 2,
    lng: '114.416196',
    lat: '23.111847'
  },
  {
    code: '441302',
    name: '惠城区',
    city_level: 3,
    lng: '114.382485',
    lat: '23.084109'
  },
  {
    code: '441303',
    name: '惠阳区',
    city_level: 3,
    lng: '114.456696',
    lat: '22.788734'
  },
  {
    code: '441322',
    name: '博罗县',
    city_level: 3,
    lng: '114.289496',
    lat: '23.172899'
  },
  {
    code: '441323',
    name: '惠东县',
    city_level: 3,
    lng: '114.720079',
    lat: '22.984975'
  },
  {
    code: '441324',
    name: '龙门县',
    city_level: 3,
    lng: '114.254913',
    lat: '23.727732'
  },
  {
    code: '441400',
    name: '梅州市',
    city_level: 2,
    lng: '116.122238',
    lat: '24.288615'
  },
  {
    code: '441402',
    name: '梅江区',
    city_level: 3,
    lng: '116.116616',
    lat: '24.310384'
  },
  {
    code: '441403',
    name: '梅县区',
    city_level: 3,
    lng: '116.082183',
    lat: '24.265312'
  },
  {
    code: '441422',
    name: '大埔县',
    city_level: 3,
    lng: '116.695195',
    lat: '24.347783'
  },
  {
    code: '441423',
    name: '丰顺县',
    city_level: 3,
    lng: '116.182299',
    lat: '23.739526'
  },
  {
    code: '441424',
    name: '五华县',
    city_level: 3,
    lng: '115.776983',
    lat: '23.930769'
  },
  {
    code: '441426',
    name: '平远县',
    city_level: 3,
    lng: '115.891638',
    lat: '24.567262'
  },
  {
    code: '441427',
    name: '蕉岭县',
    city_level: 3,
    lng: '116.171356',
    lat: '24.658700'
  },
  {
    code: '441481',
    name: '兴宁市',
    city_level: 3,
    lng: '115.723805',
    lat: '24.126159'
  },
  {
    code: '441500',
    name: '汕尾市',
    city_level: 2,
    lng: '115.375278',
    lat: '22.786211'
  },
  {
    code: '441502',
    name: '城区',
    city_level: 3,
    lng: '115.365029',
    lat: '22.778699'
  },
  {
    code: '441521',
    name: '海丰县',
    city_level: 3,
    lng: '115.323436',
    lat: '22.966586'
  },
  {
    code: '441523',
    name: '陆河县',
    city_level: 3,
    lng: '115.660143',
    lat: '23.301617'
  },
  {
    code: '441581',
    name: '陆丰市',
    city_level: 3,
    lng: '115.652260',
    lat: '22.917576'
  },
  {
    code: '441600',
    name: '河源市',
    city_level: 2,
    lng: '114.700447',
    lat: '23.743538'
  },
  {
    code: '441602',
    name: '源城区',
    city_level: 3,
    lng: '114.702517',
    lat: '23.733969'
  },
  {
    code: '441621',
    name: '紫金县',
    city_level: 3,
    lng: '115.184061',
    lat: '23.635271'
  },
  {
    code: '441622',
    name: '龙川县',
    city_level: 3,
    lng: '115.259872',
    lat: '24.100066'
  },
  {
    code: '441623',
    name: '连平县',
    city_level: 3,
    lng: '114.488714',
    lat: '24.369588'
  },
  {
    code: '441624',
    name: '和平县',
    city_level: 3,
    lng: '114.938684',
    lat: '24.442180'
  },
  {
    code: '441625',
    name: '东源县',
    city_level: 3,
    lng: '114.746380',
    lat: '23.788393'
  },
  {
    code: '441700',
    name: '阳江市',
    city_level: 2,
    lng: '111.982232',
    lat: '21.857958'
  },
  {
    code: '441702',
    name: '江城区',
    city_level: 3,
    lng: '111.955059',
    lat: '21.861787'
  },
  {
    code: '441704',
    name: '阳东区',
    city_level: 3,
    lng: '112.006338',
    lat: '21.868358'
  },
  {
    code: '441721',
    name: '阳西县',
    city_level: 3,
    lng: '111.617849',
    lat: '21.752396'
  },
  {
    code: '441781',
    name: '阳春市',
    city_level: 3,
    lng: '111.791539',
    lat: '22.170438'
  },
  {
    code: '441800',
    name: '清远市',
    city_level: 2,
    lng: '113.056031',
    lat: '23.681763'
  },
  {
    code: '441802',
    name: '清城区',
    city_level: 3,
    lng: '113.062692',
    lat: '23.697899'
  },
  {
    code: '441803',
    name: '清新区',
    city_level: 3,
    lng: '113.017749',
    lat: '23.734673'
  },
  {
    code: '441821',
    name: '佛冈县',
    city_level: 3,
    lng: '113.531607',
    lat: '23.879192'
  },
  {
    code: '441823',
    name: '阳山县',
    city_level: 3,
    lng: '112.641363',
    lat: '24.465359'
  },
  {
    code: '441825',
    name: '连山壮族瑶族自治县',
    city_level: 3,
    lng: '112.093617',
    lat: '24.570491'
  },
  {
    code: '441826',
    name: '连南瑶族自治县',
    city_level: 3,
    lng: '112.287012',
    lat: '24.726017'
  },
  {
    code: '441881',
    name: '英德市',
    city_level: 3,
    lng: '113.402376',
    lat: '24.205024'
  },
  {
    code: '441882',
    name: '连州市',
    city_level: 3,
    lng: '112.377361',
    lat: '24.780966'
  },
  {
    code: '441900',
    name: '东莞市',
    city_level: 2,
    lng: '113.751765',
    lat: '23.020536'
  },
  {
    code: '442000',
    name: '中山市',
    city_level: 2,
    lng: '113.392782',
    lat: '22.517645'
  },
  {
    code: '445100',
    name: '潮州市',
    city_level: 2,
    lng: '116.622603',
    lat: '23.656950'
  },
  {
    code: '445102',
    name: '湘桥区',
    city_level: 3,
    lng: '116.628632',
    lat: '23.674536'
  },
  {
    code: '445103',
    name: '潮安区',
    city_level: 3,
    lng: '116.678204',
    lat: '23.462613'
  },
  {
    code: '445122',
    name: '饶平县',
    city_level: 3,
    lng: '117.003900',
    lat: '23.663824'
  },
  {
    code: '445200',
    name: '揭阳市',
    city_level: 2,
    lng: '116.372831',
    lat: '23.549993'
  },
  {
    code: '445202',
    name: '榕城区',
    city_level: 3,
    lng: '116.367035',
    lat: '23.525229'
  },
  {
    code: '445203',
    name: '揭东区',
    city_level: 3,
    lng: '116.412015',
    lat: '23.566127'
  },
  {
    code: '445222',
    name: '揭西县',
    city_level: 3,
    lng: '115.841838',
    lat: '23.431294'
  },
  {
    code: '445224',
    name: '惠来县',
    city_level: 3,
    lng: '116.295150',
    lat: '23.033267'
  },
  {
    code: '445281',
    name: '普宁市',
    city_level: 3,
    lng: '116.166004',
    lat: '23.297642'
  },
  {
    code: '445300',
    name: '云浮市',
    city_level: 2,
    lng: '112.044491',
    lat: '22.915094'
  },
  {
    code: '445302',
    name: '云城区',
    city_level: 3,
    lng: '112.043832',
    lat: '22.928097'
  },
  {
    code: '445303',
    name: '云安区',
    city_level: 3,
    lng: '112.003209',
    lat: '23.071020'
  },
  {
    code: '445321',
    name: '新兴县',
    city_level: 3,
    lng: '112.225335',
    lat: '22.695690'
  },
  {
    code: '445322',
    name: '郁南县',
    city_level: 3,
    lng: '111.535249',
    lat: '23.234627'
  },
  {
    code: '445381',
    name: '罗定市',
    city_level: 3,
    lng: '111.569667',
    lat: '22.768939'
  },
  {
    code: '450000',
    name: '广西壮族自治区',
    city_level: 1,
    lng: '108.327546',
    lat: '22.815478'
  },
  {
    code: '450100',
    name: '南宁市',
    city_level: 2,
    lng: '108.366543',
    lat: '22.817002'
  },
  {
    code: '450102',
    name: '兴宁区',
    city_level: 3,
    lng: '108.368871',
    lat: '22.854021'
  },
  {
    code: '450103',
    name: '青秀区',
    city_level: 3,
    lng: '108.494024',
    lat: '22.785879'
  },
  {
    code: '450105',
    name: '江南区',
    city_level: 3,
    lng: '108.273158',
    lat: '22.781632'
  },
  {
    code: '450107',
    name: '西乡塘区',
    city_level: 3,
    lng: '108.306886',
    lat: '22.832770'
  },
  {
    code: '450108',
    name: '良庆区',
    city_level: 3,
    lng: '108.322102',
    lat: '22.759090'
  },
  {
    code: '450109',
    name: '邕宁区',
    city_level: 3,
    lng: '108.487369',
    lat: '22.758390'
  },
  {
    code: '450110',
    name: '武鸣区',
    city_level: 3,
    lng: '108.274712',
    lat: '23.158693'
  },
  {
    code: '450123',
    name: '隆安县',
    city_level: 3,
    lng: '107.696153',
    lat: '23.166028'
  },
  {
    code: '450124',
    name: '马山县',
    city_level: 3,
    lng: '108.176979',
    lat: '23.708192'
  },
  {
    code: '450125',
    name: '上林县',
    city_level: 3,
    lng: '108.604921',
    lat: '23.431936'
  },
  {
    code: '450126',
    name: '宾阳县',
    city_level: 3,
    lng: '108.810326',
    lat: '23.217832'
  },
  {
    code: '450127',
    name: '横县',
    city_level: 3,
    lng: '109.261384',
    lat: '22.679932'
  },
  {
    code: '450200',
    name: '柳州市',
    city_level: 2,
    lng: '109.415953',
    lat: '24.325502'
  },
  {
    code: '450202',
    name: '城中区',
    city_level: 3,
    lng: '101.784554',
    lat: '36.621181'
  },
  {
    code: '450203',
    name: '鱼峰区',
    city_level: 3,
    lng: '109.452442',
    lat: '24.318517'
  },
  {
    code: '450204',
    name: '柳南区',
    city_level: 3,
    lng: '109.385519',
    lat: '24.336229'
  },
  {
    code: '450205',
    name: '柳北区',
    city_level: 3,
    lng: '109.402050',
    lat: '24.362691'
  },
  {
    code: '450206',
    name: '柳江区',
    city_level: 3,
    lng: '109.326380',
    lat: '24.254892'
  },
  {
    code: '450222',
    name: '柳城县',
    city_level: 3,
    lng: '109.244730',
    lat: '24.651518'
  },
  {
    code: '450223',
    name: '鹿寨县',
    city_level: 3,
    lng: '109.750638',
    lat: '24.472897'
  },
  {
    code: '450224',
    name: '融安县',
    city_level: 3,
    lng: '109.397538',
    lat: '25.224550'
  },
  {
    code: '450225',
    name: '融水苗族自治县',
    city_level: 3,
    lng: '109.256334',
    lat: '25.065934'
  },
  {
    code: '450226',
    name: '三江侗族自治县',
    city_level: 3,
    lng: '109.607675',
    lat: '25.783198'
  },
  {
    code: '450300',
    name: '桂林市',
    city_level: 2,
    lng: '110.290194',
    lat: '25.273566'
  },
  {
    code: '450302',
    name: '秀峰区',
    city_level: 3,
    lng: '110.264183',
    lat: '25.273625'
  },
  {
    code: '450303',
    name: '叠彩区',
    city_level: 3,
    lng: '110.301723',
    lat: '25.314001'
  },
  {
    code: '450304',
    name: '象山区',
    city_level: 3,
    lng: '110.281082',
    lat: '25.261687'
  },
  {
    code: '450305',
    name: '七星区',
    city_level: 3,
    lng: '110.317826',
    lat: '25.252701'
  },
  {
    code: '450311',
    name: '雁山区',
    city_level: 3,
    lng: '110.286690',
    lat: '25.101935'
  },
  {
    code: '450312',
    name: '临桂区',
    city_level: 3,
    lng: '110.212463',
    lat: '25.238628'
  },
  {
    code: '450321',
    name: '阳朔县',
    city_level: 3,
    lng: '110.496593',
    lat: '24.778481'
  },
  {
    code: '450323',
    name: '灵川县',
    city_level: 3,
    lng: '110.325636',
    lat: '25.409747'
  },
  {
    code: '450324',
    name: '全州县',
    city_level: 3,
    lng: '111.072926',
    lat: '25.928617'
  },
  {
    code: '450325',
    name: '兴安县',
    city_level: 3,
    lng: '110.671670',
    lat: '25.611705'
  },
  {
    code: '450326',
    name: '永福县',
    city_level: 3,
    lng: '109.983076',
    lat: '24.979856'
  },
  {
    code: '450327',
    name: '灌阳县',
    city_level: 3,
    lng: '111.160851',
    lat: '25.489383'
  },
  {
    code: '450328',
    name: '龙胜各族自治县',
    city_level: 3,
    lng: '110.011238',
    lat: '25.797931'
  },
  {
    code: '450329',
    name: '资源县',
    city_level: 3,
    lng: '110.652700',
    lat: '26.042443'
  },
  {
    code: '450330',
    name: '平乐县',
    city_level: 3,
    lng: '110.643305',
    lat: '24.633362'
  },
  {
    code: '450332',
    name: '恭城瑶族自治县',
    city_level: 3,
    lng: '110.828410',
    lat: '24.831682'
  },
  {
    code: '450381',
    name: '荔浦市',
    city_level: 2,
    lng: '110.290194',
    lat: '25.273566'
  },
  {
    code: '450400',
    name: '梧州市',
    city_level: 2,
    lng: '111.279115',
    lat: '23.476962'
  },
  {
    code: '450403',
    name: '万秀区',
    city_level: 3,
    lng: '111.320542',
    lat: '23.472962'
  },
  {
    code: '450405',
    name: '长洲区',
    city_level: 3,
    lng: '111.274777',
    lat: '23.485695'
  },
  {
    code: '450406',
    name: '龙圩区',
    city_level: 3,
    lng: '111.246035',
    lat: '23.409960'
  },
  {
    code: '450421',
    name: '苍梧县',
    city_level: 3,
    lng: '111.544008',
    lat: '23.845097'
  },
  {
    code: '450422',
    name: '藤县',
    city_level: 3,
    lng: '110.914849',
    lat: '23.374984'
  },
  {
    code: '450423',
    name: '蒙山县',
    city_level: 3,
    lng: '110.525003',
    lat: '24.193570'
  },
  {
    code: '450481',
    name: '岑溪市',
    city_level: 3,
    lng: '110.994913',
    lat: '22.918350'
  },
  {
    code: '450500',
    name: '北海市',
    city_level: 2,
    lng: '109.119927',
    lat: '21.481254'
  },
  {
    code: '450502',
    name: '海城区',
    city_level: 3,
    lng: '109.117210',
    lat: '21.475005'
  },
  {
    code: '450503',
    name: '银海区',
    city_level: 3,
    lng: '109.139990',
    lat: '21.449219'
  },
  {
    code: '450512',
    name: '铁山港区',
    city_level: 3,
    lng: '109.421581',
    lat: '21.529128'
  },
  {
    code: '450521',
    name: '合浦县',
    city_level: 3,
    lng: '109.207336',
    lat: '21.660936'
  },
  {
    code: '450600',
    name: '防城港市',
    city_level: 2,
    lng: '108.353846',
    lat: '21.686860'
  },
  {
    code: '450602',
    name: '港口区',
    city_level: 3,
    lng: '108.380144',
    lat: '21.643384'
  },
  {
    code: '450603',
    name: '防城区',
    city_level: 3,
    lng: '108.353499',
    lat: '21.769212'
  },
  {
    code: '450621',
    name: '上思县',
    city_level: 3,
    lng: '107.983626',
    lat: '22.153672'
  },
  {
    code: '450681',
    name: '东兴市',
    city_level: 3,
    lng: '107.971826',
    lat: '21.547822'
  },
  {
    code: '450700',
    name: '钦州市',
    city_level: 2,
    lng: '108.654146',
    lat: '21.979933'
  },
  {
    code: '450702',
    name: '钦南区',
    city_level: 3,
    lng: '108.657210',
    lat: '21.938860'
  },
  {
    code: '450703',
    name: '钦北区',
    city_level: 3,
    lng: '108.449110',
    lat: '22.132761'
  },
  {
    code: '450721',
    name: '灵山县',
    city_level: 3,
    lng: '109.291007',
    lat: '22.416537'
  },
  {
    code: '450722',
    name: '浦北县',
    city_level: 3,
    lng: '109.556953',
    lat: '22.271651'
  },
  {
    code: '450800',
    name: '贵港市',
    city_level: 2,
    lng: '109.598926',
    lat: '23.111530'
  },
  {
    code: '450802',
    name: '港北区',
    city_level: 3,
    lng: '109.572240',
    lat: '23.111531'
  },
  {
    code: '450803',
    name: '港南区',
    city_level: 3,
    lng: '109.599557',
    lat: '23.075573'
  },
  {
    code: '450804',
    name: '覃塘区',
    city_level: 3,
    lng: '109.452662',
    lat: '23.127149'
  },
  {
    code: '450821',
    name: '平南县',
    city_level: 3,
    lng: '110.392168',
    lat: '23.539127'
  },
  {
    code: '450881',
    name: '桂平市',
    city_level: 3,
    lng: '110.079379',
    lat: '23.394326'
  },
  {
    code: '450900',
    name: '玉林市',
    city_level: 2,
    lng: '110.164756',
    lat: '22.636379'
  },
  {
    code: '450902',
    name: '玉州区',
    city_level: 3,
    lng: '110.151088',
    lat: '22.628062'
  },
  {
    code: '450903',
    name: '福绵区',
    city_level: 3,
    lng: '110.059439',
    lat: '22.585557'
  },
  {
    code: '450921',
    name: '容县',
    city_level: 3,
    lng: '110.557874',
    lat: '22.857823'
  },
  {
    code: '450922',
    name: '陆川县',
    city_level: 3,
    lng: '110.264052',
    lat: '22.321048'
  },
  {
    code: '450923',
    name: '博白县',
    city_level: 3,
    lng: '109.975985',
    lat: '22.273048'
  },
  {
    code: '450924',
    name: '兴业县',
    city_level: 3,
    lng: '109.876855',
    lat: '22.820746'
  },
  {
    code: '450981',
    name: '北流市',
    city_level: 3,
    lng: '110.354215',
    lat: '22.708311'
  },
  {
    code: '451000',
    name: '百色市',
    city_level: 2,
    lng: '106.618201',
    lat: '23.902333'
  },
  {
    code: '451002',
    name: '右江区',
    city_level: 3,
    lng: '106.618645',
    lat: '23.901383'
  },
  {
    code: '451003',
    name: '田阳区',
    city_level: 3,
    lng: '106.916803',
    lat: '23.736475'
  },
  {
    code: '451022',
    name: '田东县',
    city_level: 3,
    lng: '107.126081',
    lat: '23.597194'
  },
  {
    code: '451024',
    name: '德保县',
    city_level: 3,
    lng: '106.615374',
    lat: '23.323450'
  },
  {
    code: '451026',
    name: '那坡县',
    city_level: 3,
    lng: '105.832530',
    lat: '23.387441'
  },
  {
    code: '451027',
    name: '凌云县',
    city_level: 3,
    lng: '106.561310',
    lat: '24.347557'
  },
  {
    code: '451028',
    name: '乐业县',
    city_level: 3,
    lng: '106.556519',
    lat: '24.776827'
  },
  {
    code: '451029',
    name: '田林县',
    city_level: 3,
    lng: '106.228538',
    lat: '24.294488'
  },
  {
    code: '451030',
    name: '西林县',
    city_level: 3,
    lng: '105.093837',
    lat: '24.489810'
  },
  {
    code: '451031',
    name: '隆林各族自治县',
    city_level: 3,
    lng: '105.344040',
    lat: '24.770896'
  },
  {
    code: '451081',
    name: '靖西市',
    city_level: 3,
    lng: '106.417549',
    lat: '23.134766'
  },
  {
    code: '451082',
    name: '平果市',
    city_level: 3,
    lng: '107.575202',
    lat: '23.326765'
  },
  {
    code: '451100',
    name: '贺州市',
    city_level: 2,
    lng: '111.566694',
    lat: '24.403582'
  },
  {
    code: '451102',
    name: '八步区',
    city_level: 3,
    lng: '111.552077',
    lat: '24.411811'
  },
  {
    code: '451103',
    name: '平桂区',
    city_level: 2,
    lng: '111.566694',
    lat: '24.403582'
  },
  {
    code: '451121',
    name: '昭平县',
    city_level: 3,
    lng: '110.811287',
    lat: '24.169480'
  },
  {
    code: '451122',
    name: '钟山县',
    city_level: 3,
    lng: '111.303111',
    lat: '24.526022'
  },
  {
    code: '451123',
    name: '富川瑶族自治县',
    city_level: 3,
    lng: '111.277389',
    lat: '24.814444'
  },
  {
    code: '451200',
    name: '河池市',
    city_level: 2,
    lng: '108.085261',
    lat: '24.692931'
  },
  {
    code: '451202',
    name: '金城江区',
    city_level: 3,
    lng: '108.037277',
    lat: '24.689703'
  },
  {
    code: '451203',
    name: '宜州区',
    city_level: 3,
    lng: '108.653969',
    lat: '24.492193'
  },
  {
    code: '451221',
    name: '南丹县',
    city_level: 3,
    lng: '107.540722',
    lat: '24.974486'
  },
  {
    code: '451222',
    name: '天峨县',
    city_level: 3,
    lng: '107.173802',
    lat: '24.999108'
  },
  {
    code: '451223',
    name: '凤山县',
    city_level: 3,
    lng: '107.042191',
    lat: '24.546876'
  },
  {
    code: '451224',
    name: '东兰县',
    city_level: 3,
    lng: '107.374294',
    lat: '24.510842'
  },
  {
    code: '451225',
    name: '罗城仫佬族自治县',
    city_level: 3,
    lng: '108.904761',
    lat: '24.777421'
  },
  {
    code: '451226',
    name: '环江毛南族自治县',
    city_level: 3,
    lng: '108.258028',
    lat: '24.825664'
  },
  {
    code: '451227',
    name: '巴马瑶族自治县',
    city_level: 3,
    lng: '107.258588',
    lat: '24.142299'
  },
  {
    code: '451228',
    name: '都安瑶族自治县',
    city_level: 3,
    lng: '108.105312',
    lat: '23.932675'
  },
  {
    code: '451229',
    name: '大化瑶族自治县',
    city_level: 3,
    lng: '107.998150',
    lat: '23.736457'
  },
  {
    code: '451300',
    name: '来宾市',
    city_level: 2,
    lng: '109.221465',
    lat: '23.750306'
  },
  {
    code: '451302',
    name: '兴宾区',
    city_level: 3,
    lng: '109.233247',
    lat: '23.736153'
  },
  {
    code: '451321',
    name: '忻城县',
    city_level: 3,
    lng: '108.665666',
    lat: '24.066235'
  },
  {
    code: '451322',
    name: '象州县',
    city_level: 3,
    lng: '109.683985',
    lat: '23.958528'
  },
  {
    code: '451323',
    name: '武宣县',
    city_level: 3,
    lng: '109.663207',
    lat: '23.594110'
  },
  {
    code: '451324',
    name: '金秀瑶族自治县',
    city_level: 3,
    lng: '110.189462',
    lat: '24.130374'
  },
  {
    code: '451381',
    name: '合山市',
    city_level: 3,
    lng: '108.886082',
    lat: '23.806536'
  },
  {
    code: '451400',
    name: '崇左市',
    city_level: 2,
    lng: '107.364711',
    lat: '22.376532'
  },
  {
    code: '451402',
    name: '江州区',
    city_level: 3,
    lng: '107.353437',
    lat: '22.405325'
  },
  {
    code: '451421',
    name: '扶绥县',
    city_level: 3,
    lng: '107.904187',
    lat: '22.635013'
  },
  {
    code: '451422',
    name: '宁明县',
    city_level: 3,
    lng: '107.076457',
    lat: '22.140192'
  },
  {
    code: '451423',
    name: '龙州县',
    city_level: 3,
    lng: '106.854451',
    lat: '22.342797'
  },
  {
    code: '451424',
    name: '大新县',
    city_level: 3,
    lng: '107.200654',
    lat: '22.829288'
  },
  {
    code: '451425',
    name: '天等县',
    city_level: 3,
    lng: '107.143433',
    lat: '23.081394'
  },
  {
    code: '451481',
    name: '凭祥市',
    city_level: 3,
    lng: '106.766293',
    lat: '22.094485'
  },
  {
    code: '460000',
    name: '海南省',
    city_level: 1,
    lng: '110.349228',
    lat: '20.017377'
  },
  {
    code: '460100',
    name: '海口市',
    city_level: 2,
    lng: '110.198293',
    lat: '20.044001'
  },
  {
    code: '460105',
    name: '秀英区',
    city_level: 3,
    lng: '110.293561',
    lat: '20.007969'
  },
  {
    code: '460106',
    name: '龙华区',
    city_level: 3,
    lng: '110.328492',
    lat: '20.031007'
  },
  {
    code: '460107',
    name: '琼山区',
    city_level: 3,
    lng: '110.353972',
    lat: '20.003170'
  },
  {
    code: '460108',
    name: '美兰区',
    city_level: 3,
    lng: '110.366357',
    lat: '20.029083'
  },
  {
    code: '460200',
    name: '三亚市',
    city_level: 2,
    lng: '109.511909',
    lat: '18.252847'
  },
  {
    code: '460202',
    name: '海棠区',
    city_level: 3,
    lng: '109.760778',
    lat: '18.407516'
  },
  {
    code: '460203',
    name: '吉阳区',
    city_level: 3,
    lng: '109.578330',
    lat: '18.281397'
  },
  {
    code: '460204',
    name: '天涯区',
    city_level: 3,
    lng: '109.506357',
    lat: '18.247340'
  },
  {
    code: '460205',
    name: '崖州区',
    city_level: 3,
    lng: '109.174313',
    lat: '18.352212'
  },
  {
    code: '460300',
    name: '三沙市',
    city_level: 2,
    lng: '112.338695',
    lat: '16.831839'
  },
  {
    code: '460400',
    name: '儋州市',
    city_level: 2,
    lng: '109.580811',
    lat: '19.521134'
  },
  {
    code: '469001',
    name: '五指山市',
    city_level: 3,
    lng: '109.516925',
    lat: '18.775146'
  },
  {
    code: '469002',
    name: '琼海市',
    city_level: 3,
    lng: '110.474581',
    lat: '19.25914'
  },
  {
    code: '469005',
    name: '文昌市',
    city_level: 3,
    lng: '110.797717',
    lat: '19.543422'
  },
  {
    code: '469006',
    name: '万宁市',
    city_level: 3,
    lng: '110.391073',
    lat: '18.795143'
  },
  {
    code: '469007',
    name: '东方市',
    city_level: 3,
    lng: '108.651815',
    lat: '19.095351'
  },
  {
    code: '469021',
    name: '定安县',
    city_level: 3,
    lng: '110.358891',
    lat: '19.681434'
  },
  {
    code: '469022',
    name: '屯昌县',
    city_level: 3,
    lng: '110.103415',
    lat: '19.351766'
  },
  {
    code: '469023',
    name: '澄迈县',
    city_level: 3,
    lng: '110.006755',
    lat: '19.738521'
  },
  {
    code: '469024',
    name: '临高县',
    city_level: 3,
    lng: '109.690508',
    lat: '19.912026'
  },
  {
    code: '469025',
    name: '白沙黎族自治县',
    city_level: 3,
    lng: '109.451484',
    lat: '19.224823'
  },
  {
    code: '469026',
    name: '昌江黎族自治县',
    city_level: 3,
    lng: '109.055724',
    lat: '19.298062'
  },
  {
    code: '469027',
    name: '乐东黎族自治县',
    city_level: 3,
    lng: '109.173055',
    lat: '18.750260'
  },
  {
    code: '469028',
    name: '陵水黎族自治县',
    city_level: 3,
    lng: '110.037504',
    lat: '18.506048'
  },
  {
    code: '469029',
    name: '保亭黎族苗族自治县',
    city_level: 3,
    lng: '109.702392',
    lat: '18.638189'
  },
  {
    code: '469030',
    name: '琼中黎族苗族自治县',
    city_level: 3,
    lng: '109.838389',
    lat: '19.033369'
  },
  {
    code: '500000',
    name: '重庆市',
    city_level: 1,
    lng: '106.551556',
    lat: '29.563009'
  },
  {
    code: '500100',
    name: '重庆市',
    city_level: 2,
    lng: '106.551556',
    lat: '29.563009'
  },
  {
    code: '500101',
    name: '万州区',
    city_level: 3,
    lng: '108.408661',
    lat: '30.807667'
  },
  {
    code: '500102',
    name: '涪陵区',
    city_level: 3,
    lng: '107.389298',
    lat: '29.703113'
  },
  {
    code: '500103',
    name: '渝中区',
    city_level: 3,
    lng: '106.568892',
    lat: '29.552750'
  },
  {
    code: '500104',
    name: '大渡口区',
    city_level: 3,
    lng: '106.482347',
    lat: '29.484527'
  },
  {
    code: '500105',
    name: '江北区',
    city_level: 3,
    lng: '106.574271',
    lat: '29.606703'
  },
  {
    code: '500106',
    name: '沙坪坝区',
    city_level: 3,
    lng: '106.456878',
    lat: '29.541145'
  },
  {
    code: '500107',
    name: '九龙坡区',
    city_level: 3,
    lng: '106.531920',
    lat: '29.514190'
  },
  {
    code: '500108',
    name: '南岸区',
    city_level: 3,
    lng: '106.644428',
    lat: '29.500297'
  },
  {
    code: '500109',
    name: '北碚区',
    city_level: 3,
    lng: '106.445122',
    lat: '29.822616'
  },
  {
    code: '500110',
    name: '綦江区',
    city_level: 3,
    lng: '106.651362',
    lat: '29.028067'
  },
  {
    code: '500111',
    name: '大足区',
    city_level: 3,
    lng: '105.721733',
    lat: '29.707032'
  },
  {
    code: '500112',
    name: '渝北区',
    city_level: 3,
    lng: '106.631187',
    lat: '29.718143'
  },
  {
    code: '500113',
    name: '巴南区',
    city_level: 3,
    lng: '106.533840',
    lat: '29.375528'
  },
  {
    code: '500114',
    name: '黔江区',
    city_level: 3,
    lng: '108.770678',
    lat: '29.533610'
  },
  {
    code: '500115',
    name: '长寿区',
    city_level: 3,
    lng: '107.081283',
    lat: '29.857996'
  },
  {
    code: '500116',
    name: '江津区',
    city_level: 3,
    lng: '106.259281',
    lat: '29.290069'
  },
  {
    code: '500117',
    name: '合川区',
    city_level: 3,
    lng: '106.276130',
    lat: '29.972084'
  },
  {
    code: '500118',
    name: '永川区',
    city_level: 3,
    lng: '105.927376',
    lat: '29.356117'
  },
  {
    code: '500119',
    name: '南川区',
    city_level: 3,
    lng: '107.099266',
    lat: '29.157891'
  },
  {
    code: '500120',
    name: '璧山区',
    city_level: 3,
    lng: '106.227305',
    lat: '29.592024'
  },
  {
    code: '500151',
    name: '铜梁区',
    city_level: 3,
    lng: '106.056404',
    lat: '29.844811'
  },
  {
    code: '500152',
    name: '潼南区',
    city_level: 3,
    lng: '105.840556',
    lat: '30.191013'
  },
  {
    code: '500153',
    name: '荣昌区',
    city_level: 3,
    lng: '105.594623',
    lat: '29.405002'
  },
  {
    code: '500154',
    name: '开州区',
    city_level: 3,
    lng: '108.393135',
    lat: '31.160711'
  },
  {
    code: '500155',
    name: '梁平区',
    city_level: 3,
    lng: '107.800034',
    lat: '30.672167'
  },
  {
    code: '500156',
    name: '武隆区',
    city_level: 3,
    lng: '107.756546',
    lat: '29.323761'
  },
  {
    code: '500229',
    name: '城口县',
    city_level: 3,
    lng: '108.664214',
    lat: '31.947633'
  },
  {
    code: '500230',
    name: '丰都县',
    city_level: 3,
    lng: '107.730895',
    lat: '29.863500'
  },
  {
    code: '500231',
    name: '垫江县',
    city_level: 3,
    lng: '107.333390',
    lat: '30.327717'
  },
  {
    code: '500233',
    name: '忠县',
    city_level: 3,
    lng: '108.039002',
    lat: '30.299560'
  },
  {
    code: '500235',
    name: '云阳县',
    city_level: 3,
    lng: '108.697324',
    lat: '30.930613'
  },
  {
    code: '500236',
    name: '奉节县',
    city_level: 3,
    lng: '109.463987',
    lat: '31.018498'
  },
  {
    code: '500237',
    name: '巫山县',
    city_level: 3,
    lng: '109.879153',
    lat: '31.074834'
  },
  {
    code: '500238',
    name: '巫溪县',
    city_level: 3,
    lng: '109.570062',
    lat: '31.398604'
  },
  {
    code: '500240',
    name: '石柱土家族自治县',
    city_level: 3,
    lng: '108.114069',
    lat: '29.999285'
  },
  {
    code: '500241',
    name: '秀山土家族苗族自治县',
    city_level: 3,
    lng: '109.007094',
    lat: '28.447997'
  },
  {
    code: '500242',
    name: '酉阳土家族苗族自治县',
    city_level: 3,
    lng: '108.767747',
    lat: '28.841244'
  },
  {
    code: '500243',
    name: '彭水苗族土家族自治县',
    city_level: 3,
    lng: '108.165538',
    lat: '29.293902'
  },
  {
    code: '510000',
    name: '四川省',
    city_level: 1,
    lng: '104.075931',
    lat: '30.651651'
  },
  {
    code: '510100',
    name: '成都市',
    city_level: 2,
    lng: '104.066541',
    lat: '30.572269'
  },
  {
    code: '510104',
    name: '锦江区',
    city_level: 3,
    lng: '104.080989',
    lat: '30.657689'
  },
  {
    code: '510105',
    name: '青羊区',
    city_level: 3,
    lng: '104.062499',
    lat: '30.674406'
  },
  {
    code: '510106',
    name: '金牛区',
    city_level: 3,
    lng: '104.117422',
    lat: '30.763102'
  },
  {
    code: '510107',
    name: '武侯区',
    city_level: 3,
    lng: '104.043390',
    lat: '30.641982'
  },
  {
    code: '510108',
    name: '成华区',
    city_level: 3,
    lng: '104.101255',
    lat: '30.660122'
  },
  {
    code: '510112',
    name: '龙泉驿区',
    city_level: 3,
    lng: '104.274632',
    lat: '30.556507'
  },
  {
    code: '510113',
    name: '青白江区',
    city_level: 3,
    lng: '104.250877',
    lat: '30.878681'
  },
  {
    code: '510114',
    name: '新都区',
    city_level: 3,
    lng: '104.158705',
    lat: '30.823499'
  },
  {
    code: '510115',
    name: '温江区',
    city_level: 3,
    lng: '103.837104',
    lat: '30.690460'
  },
  {
    code: '510116',
    name: '双流区',
    city_level: 3,
    lng: '103.951908',
    lat: '30.333027'
  },
  {
    code: '510117',
    name: '郫都区',
    city_level: 3,
    lng: '103.901092',
    lat: '30.795854'
  },
  {
    code: '510118',
    name: '新津区',
    city_level: 3,
    lng: '103.812447',
    lat: '30.414284'
  },
  {
    code: '510121',
    name: '金堂县',
    city_level: 3,
    lng: '104.412005',
    lat: '30.862017'
  },
  {
    code: '510129',
    name: '大邑县',
    city_level: 3,
    lng: '103.511875',
    lat: '30.572269'
  },
  {
    code: '510131',
    name: '蒲江县',
    city_level: 3,
    lng: '103.506498',
    lat: '30.196789'
  },
  {
    code: '510181',
    name: '都江堰市',
    city_level: 3,
    lng: '103.650219',
    lat: '30.975943'
  },
  {
    code: '510182',
    name: '彭州市',
    city_level: 3,
    lng: '103.958013',
    lat: '30.990165'
  },
  {
    code: '510183',
    name: '邛崃市',
    city_level: 3,
    lng: '103.464156',
    lat: '30.410275'
  },
  {
    code: '510184',
    name: '崇州市',
    city_level: 3,
    lng: '103.673001',
    lat: '30.630122'
  },
  {
    code: '510185',
    name: '简阳市',
    city_level: 3,
    lng: '104.546773',
    lat: '30.410754'
  },
  {
    code: '510300',
    name: '自贡市',
    city_level: 2,
    lng: '104.778442',
    lat: '29.339030'
  },
  {
    code: '510302',
    name: '自流井区',
    city_level: 3,
    lng: '104.777191',
    lat: '29.337430'
  },
  {
    code: '510303',
    name: '贡井区',
    city_level: 3,
    lng: '104.715117',
    lat: '29.345546'
  },
  {
    code: '510304',
    name: '大安区',
    city_level: 3,
    lng: '104.773968',
    lat: '29.363634'
  },
  {
    code: '510311',
    name: '沿滩区',
    city_level: 3,
    lng: '104.874073',
    lat: '29.272581'
  },
  {
    code: '510321',
    name: '荣县',
    city_level: 3,
    lng: '104.417388',
    lat: '29.445410'
  },
  {
    code: '510322',
    name: '富顺县',
    city_level: 3,
    lng: '104.975048',
    lat: '29.181430'
  },
  {
    code: '510400',
    name: '攀枝花市',
    city_level: 2,
    lng: '101.718637',
    lat: '26.582347'
  },
  {
    code: '510402',
    name: '东区',
    city_level: 2,
    lng: '101.718637',
    lat: '26.582347'
  },
  {
    code: '510403',
    name: '西区',
    city_level: 2,
    lng: '101.718637',
    lat: '26.582347'
  },
  {
    code: '510411',
    name: '仁和区',
    city_level: 3,
    lng: '101.738528',
    lat: '26.497765'
  },
  {
    code: '510421',
    name: '米易县',
    city_level: 3,
    lng: '102.110339',
    lat: '26.890689'
  },
  {
    code: '510422',
    name: '盐边县',
    city_level: 3,
    lng: '101.855071',
    lat: '26.683213'
  },
  {
    code: '510500',
    name: '泸州市',
    city_level: 2,
    lng: '105.442258',
    lat: '28.871811'
  },
  {
    code: '510502',
    name: '江阳区',
    city_level: 3,
    lng: '105.435009',
    lat: '28.878818'
  },
  {
    code: '510503',
    name: '纳溪区',
    city_level: 3,
    lng: '105.371151',
    lat: '28.773428'
  },
  {
    code: '510504',
    name: '龙马潭区',
    city_level: 3,
    lng: '105.437765',
    lat: '28.913221'
  },
  {
    code: '510521',
    name: '泸县',
    city_level: 3,
    lng: '105.381893',
    lat: '29.151534'
  },
  {
    code: '510522',
    name: '合江县',
    city_level: 3,
    lng: '105.831067',
    lat: '28.811203'
  },
  {
    code: '510524',
    name: '叙永县',
    city_level: 3,
    lng: '105.444765',
    lat: '28.155801'
  },
  {
    code: '510525',
    name: '古蔺县',
    city_level: 3,
    lng: '105.812602',
    lat: '28.038802'
  },
  {
    code: '510600',
    name: '德阳市',
    city_level: 2,
    lng: '104.397894',
    lat: '31.126855'
  },
  {
    code: '510603',
    name: '旌阳区',
    city_level: 3,
    lng: '104.416943',
    lat: '31.142498'
  },
  {
    code: '510604',
    name: '罗江区',
    city_level: 3,
    lng: '104.510249',
    lat: '31.317045'
  },
  {
    code: '510623',
    name: '中江县',
    city_level: 3,
    lng: '104.678749',
    lat: '31.033051'
  },
  {
    code: '510681',
    name: '广汉市',
    city_level: 3,
    lng: '104.282331',
    lat: '30.976165'
  },
  {
    code: '510682',
    name: '什邡市',
    city_level: 3,
    lng: '104.167501',
    lat: '31.126780'
  },
  {
    code: '510683',
    name: '绵竹市',
    city_level: 3,
    lng: '104.220750',
    lat: '31.338077'
  },
  {
    code: '510700',
    name: '绵阳市',
    city_level: 2,
    lng: '104.679114',
    lat: '31.467450'
  },
  {
    code: '510703',
    name: '涪城区',
    city_level: 3,
    lng: '104.750215',
    lat: '31.470830'
  },
  {
    code: '510704',
    name: '游仙区',
    city_level: 3,
    lng: '104.766393',
    lat: '31.473779'
  },
  {
    code: '510705',
    name: '安州区',
    city_level: 3,
    lng: '104.567187',
    lat: '31.534886'
  },
  {
    code: '510722',
    name: '三台县',
    city_level: 3,
    lng: '105.094586',
    lat: '31.095979'
  },
  {
    code: '510723',
    name: '盐亭县',
    city_level: 3,
    lng: '105.389453',
    lat: '31.208363'
  },
  {
    code: '510725',
    name: '梓潼县',
    city_level: 3,
    lng: '105.170845',
    lat: '31.642718'
  },
  {
    code: '510726',
    name: '北川羌族自治县',
    city_level: 3,
    lng: '104.467970',
    lat: '31.617203'
  },
  {
    code: '510727',
    name: '平武县',
    city_level: 3,
    lng: '104.555583',
    lat: '32.409675'
  },
  {
    code: '510781',
    name: '江油市',
    city_level: 3,
    lng: '104.745877',
    lat: '31.778025'
  },
  {
    code: '510800',
    name: '广元市',
    city_level: 2,
    lng: '105.843357',
    lat: '32.435435'
  },
  {
    code: '510802',
    name: '利州区',
    city_level: 3,
    lng: '105.845218',
    lat: '32.433898'
  },
  {
    code: '510811',
    name: '昭化区',
    city_level: 3,
    lng: '105.964121',
    lat: '32.322788'
  },
  {
    code: '510812',
    name: '朝天区',
    city_level: 3,
    lng: '105.890445',
    lat: '32.643982'
  },
  {
    code: '510821',
    name: '旺苍县',
    city_level: 3,
    lng: '106.289905',
    lat: '32.228917'
  },
  {
    code: '510822',
    name: '青川县',
    city_level: 3,
    lng: '105.238842',
    lat: '32.575485'
  },
  {
    code: '510823',
    name: '剑阁县',
    city_level: 3,
    lng: '105.524766',
    lat: '32.287723'
  },
  {
    code: '510824',
    name: '苍溪县',
    city_level: 3,
    lng: '105.934801',
    lat: '31.732432'
  },
  {
    code: '510900',
    name: '遂宁市',
    city_level: 2,
    lng: '105.592898',
    lat: '30.532847'
  },
  {
    code: '510903',
    name: '船山区',
    city_level: 3,
    lng: '105.568297',
    lat: '30.525475'
  },
  {
    code: '510904',
    name: '安居区',
    city_level: 3,
    lng: '105.456342',
    lat: '30.355379'
  },
  {
    code: '510921',
    name: '蓬溪县',
    city_level: 3,
    lng: '105.707570',
    lat: '30.757575'
  },
  {
    code: '510923',
    name: '大英县',
    city_level: 3,
    lng: '105.236904',
    lat: '30.594337'
  },
  {
    code: '510981',
    name: '射洪市',
    city_level: 3,
    lng: '105.376018',
    lat: '30.867054'
  },
  {
    code: '511000',
    name: '内江市',
    city_level: 2,
    lng: '105.058433',
    lat: '29.580228'
  },
  {
    code: '511002',
    name: '市中区',
    city_level: 3,
    lng: '103.761330',
    lat: '29.555375'
  },
  {
    code: '511011',
    name: '东兴区',
    city_level: 3,
    lng: '105.075490',
    lat: '29.592756'
  },
  {
    code: '511024',
    name: '威远县',
    city_level: 3,
    lng: '104.668879',
    lat: '29.527440'
  },
  {
    code: '511025',
    name: '资中县',
    city_level: 3,
    lng: '104.851944',
    lat: '29.764059'
  },
  {
    code: '511083',
    name: '隆昌市',
    city_level: 3,
    lng: '105.288071',
    lat: '29.338161'
  },
  {
    code: '511100',
    name: '乐山市',
    city_level: 2,
    lng: '103.765568',
    lat: '29.552106'
  },
  {
    code: '511102',
    name: '市中区',
    city_level: 3,
    lng: '103.761330',
    lat: '29.555375'
  },
  {
    code: '511111',
    name: '沙湾区',
    city_level: 3,
    lng: '103.549991',
    lat: '29.413091'
  },
  {
    code: '511112',
    name: '五通桥区',
    city_level: 3,
    lng: '103.818009',
    lat: '29.406932'
  },
  {
    code: '511113',
    name: '金口河区',
    city_level: 3,
    lng: '103.078621',
    lat: '29.244345'
  },
  {
    code: '511123',
    name: '犍为县',
    city_level: 3,
    lng: '103.949326',
    lat: '29.208171'
  },
  {
    code: '511124',
    name: '井研县',
    city_level: 3,
    lng: '104.069726',
    lat: '29.651287'
  },
  {
    code: '511126',
    name: '夹江县',
    city_level: 3,
    lng: '103.571657',
    lat: '29.737630'
  },
  {
    code: '511129',
    name: '沐川县',
    city_level: 3,
    lng: '103.902335',
    lat: '28.956647'
  },
  {
    code: '511132',
    name: '峨边彝族自治县',
    city_level: 3,
    lng: '103.262048',
    lat: '29.230425'
  },
  {
    code: '511133',
    name: '马边彝族自治县',
    city_level: 3,
    lng: '103.546348',
    lat: '28.835521'
  },
  {
    code: '511181',
    name: '峨眉山市',
    city_level: 3,
    lng: '103.484504',
    lat: '29.601199'
  },
  {
    code: '511300',
    name: '南充市',
    city_level: 2,
    lng: '106.110698',
    lat: '30.837793'
  },
  {
    code: '511302',
    name: '顺庆区',
    city_level: 3,
    lng: '106.092399',
    lat: '30.796486'
  },
  {
    code: '511303',
    name: '高坪区',
    city_level: 3,
    lng: '106.118808',
    lat: '30.781623'
  },
  {
    code: '511304',
    name: '嘉陵区',
    city_level: 3,
    lng: '106.071793',
    lat: '30.758748'
  },
  {
    code: '511321',
    name: '南部县',
    city_level: 3,
    lng: '106.036584',
    lat: '31.347467'
  },
  {
    code: '511322',
    name: '营山县',
    city_level: 3,
    lng: '106.565420',
    lat: '31.076461'
  },
  {
    code: '511323',
    name: '蓬安县',
    city_level: 3,
    lng: '106.412151',
    lat: '31.029097'
  },
  {
    code: '511324',
    name: '仪陇县',
    city_level: 3,
    lng: '106.303042',
    lat: '31.271562'
  },
  {
    code: '511325',
    name: '西充县',
    city_level: 3,
    lng: '105.900878',
    lat: '30.995669'
  },
  {
    code: '511381',
    name: '阆中市',
    city_level: 3,
    lng: '106.005047',
    lat: '31.558357'
  },
  {
    code: '511400',
    name: '眉山市',
    city_level: 2,
    lng: '103.848538',
    lat: '30.075439'
  },
  {
    code: '511402',
    name: '东坡区',
    city_level: 3,
    lng: '103.831752',
    lat: '30.042345'
  },
  {
    code: '511403',
    name: '彭山区',
    city_level: 3,
    lng: '103.872950',
    lat: '30.193056'
  },
  {
    code: '511421',
    name: '仁寿县',
    city_level: 3,
    lng: '104.134082',
    lat: '29.995630'
  },
  {
    code: '511423',
    name: '洪雅县',
    city_level: 3,
    lng: '103.372863',
    lat: '29.904890'
  },
  {
    code: '511424',
    name: '丹棱县',
    city_level: 3,
    lng: '103.512733',
    lat: '30.014448'
  },
  {
    code: '511425',
    name: '青神县',
    city_level: 3,
    lng: '103.846688',
    lat: '29.831358'
  },
  {
    code: '511500',
    name: '宜宾市',
    city_level: 2,
    lng: '104.643215',
    lat: '28.751768'
  },
  {
    code: '511502',
    name: '翠屏区',
    city_level: 3,
    lng: '104.620009',
    lat: '28.765690'
  },
  {
    code: '511503',
    name: '南溪区',
    city_level: 2,
    lng: '104.643215',
    lat: '28.751768'
  },
  {
    code: '511504',
    name: '叙州区',
    city_level: 2,
    lng: '104.643215',
    lat: '28.751768'
  },
  {
    code: '511523',
    name: '江安县',
    city_level: 2,
    lng: '104.643215',
    lat: '28.751768'
  },
  {
    code: '511524',
    name: '长宁县',
    city_level: 3,
    lng: '104.921174',
    lat: '28.582169'
  },
  {
    code: '511525',
    name: '高县',
    city_level: 3,
    lng: '104.517739',
    lat: '28.436226'
  },
  {
    code: '511526',
    name: '珙县',
    city_level: 3,
    lng: '104.709202',
    lat: '28.438630'
  },
  {
    code: '511527',
    name: '筠连县',
    city_level: 2,
    lng: '104.643215',
    lat: '28.751768'
  },
  {
    code: '511528',
    name: '兴文县',
    city_level: 3,
    lng: '105.236549',
    lat: '28.302988'
  },
  {
    code: '511529',
    name: '屏山县',
    city_level: 3,
    lng: '104.345974',
    lat: '28.828482'
  },
  {
    code: '511600',
    name: '广安市',
    city_level: 2,
    lng: '106.633212',
    lat: '30.455961'
  },
  {
    code: '511602',
    name: '广安区',
    city_level: 3,
    lng: '106.641608',
    lat: '30.474003'
  },
  {
    code: '511603',
    name: '前锋区',
    city_level: 3,
    lng: '106.893277',
    lat: '30.496300'
  },
  {
    code: '511621',
    name: '岳池县',
    city_level: 3,
    lng: '106.440114',
    lat: '30.537863'
  },
  {
    code: '511622',
    name: '武胜县',
    city_level: 3,
    lng: '106.295764',
    lat: '30.348772'
  },
  {
    code: '511623',
    name: '邻水县',
    city_level: 3,
    lng: '106.930380',
    lat: '30.334769'
  },
  {
    code: '511681',
    name: '华蓥市',
    city_level: 3,
    lng: '106.783088',
    lat: '30.390435'
  },
  {
    code: '511700',
    name: '达州市',
    city_level: 2,
    lng: '107.468023',
    lat: '31.209571'
  },
  {
    code: '511702',
    name: '通川区',
    city_level: 3,
    lng: '107.504517',
    lat: '31.214724'
  },
  {
    code: '511703',
    name: '达川区',
    city_level: 3,
    lng: '107.511845',
    lat: '31.196118'
  },
  {
    code: '511722',
    name: '宣汉县',
    city_level: 3,
    lng: '107.727191',
    lat: '31.353835'
  },
  {
    code: '511723',
    name: '开江县',
    city_level: 3,
    lng: '107.868736',
    lat: '31.082987'
  },
  {
    code: '511724',
    name: '大竹县',
    city_level: 3,
    lng: '107.204744',
    lat: '30.736266'
  },
  {
    code: '511725',
    name: '渠县',
    city_level: 3,
    lng: '106.972996',
    lat: '30.836659'
  },
  {
    code: '511781',
    name: '万源市',
    city_level: 3,
    lng: '108.034657',
    lat: '32.081631'
  },
  {
    code: '511800',
    name: '雅安市',
    city_level: 2,
    lng: '103.013261',
    lat: '29.980537'
  },
  {
    code: '511802',
    name: '雨城区',
    city_level: 3,
    lng: '103.033083',
    lat: '30.005447'
  },
  {
    code: '511803',
    name: '名山区',
    city_level: 3,
    lng: '103.109185',
    lat: '30.069954'
  },
  {
    code: '511822',
    name: '荥经县',
    city_level: 3,
    lng: '102.846738',
    lat: '29.792931'
  },
  {
    code: '511823',
    name: '汉源县',
    city_level: 3,
    lng: '102.645453',
    lat: '29.347187'
  },
  {
    code: '511824',
    name: '石棉县',
    city_level: 3,
    lng: '102.359462',
    lat: '29.227874'
  },
  {
    code: '511825',
    name: '天全县',
    city_level: 3,
    lng: '102.758317',
    lat: '30.066713'
  },
  {
    code: '511826',
    name: '芦山县',
    city_level: 3,
    lng: '102.928260',
    lat: '30.144084'
  },
  {
    code: '511827',
    name: '宝兴县',
    city_level: 3,
    lng: '102.814531',
    lat: '30.368126'
  },
  {
    code: '511900',
    name: '巴中市',
    city_level: 2,
    lng: '106.747477',
    lat: '31.867903'
  },
  {
    code: '511902',
    name: '巴州区',
    city_level: 3,
    lng: '106.768878',
    lat: '31.851478'
  },
  {
    code: '511903',
    name: '恩阳区',
    city_level: 3,
    lng: '106.655347',
    lat: '31.786691'
  },
  {
    code: '511921',
    name: '通江县',
    city_level: 3,
    lng: '107.245033',
    lat: '31.911705'
  },
  {
    code: '511922',
    name: '南江县',
    city_level: 3,
    lng: '106.828697',
    lat: '32.346589'
  },
  {
    code: '511923',
    name: '平昌县',
    city_level: 3,
    lng: '107.104008',
    lat: '31.560874'
  },
  {
    code: '512000',
    name: '资阳市',
    city_level: 2,
    lng: '104.627636',
    lat: '30.128901'
  },
  {
    code: '512002',
    name: '雁江区',
    city_level: 3,
    lng: '104.677096',
    lat: '30.108210'
  },
  {
    code: '512021',
    name: '安岳县',
    city_level: 3,
    lng: '105.335613',
    lat: '30.097246'
  },
  {
    code: '512022',
    name: '乐至县',
    city_level: 3,
    lng: '105.019916',
    lat: '30.275487'
  },
  {
    code: '513200',
    name: '阿坝藏族羌族自治州',
    city_level: 2,
    lng: '102.224653',
    lat: '31.899413'
  },
  {
    code: '513201',
    name: '马尔康市',
    city_level: 3,
    lng: '102.206504',
    lat: '31.905813'
  },
  {
    code: '513221',
    name: '汶川县',
    city_level: 3,
    lng: '103.590387',
    lat: '31.476822'
  },
  {
    code: '513222',
    name: '理县',
    city_level: 3,
    lng: '103.166853',
    lat: '31.436473'
  },
  {
    code: '513223',
    name: '茂县',
    city_level: 3,
    lng: '103.853522',
    lat: '31.681154'
  },
  {
    code: '513224',
    name: '松潘县',
    city_level: 3,
    lng: '103.604698',
    lat: '32.655325'
  },
  {
    code: '513225',
    name: '九寨沟县',
    city_level: 3,
    lng: '104.243841',
    lat: '33.252056'
  },
  {
    code: '513226',
    name: '金川县',
    city_level: 3,
    lng: '102.063829',
    lat: '31.476277'
  },
  {
    code: '513227',
    name: '小金县',
    city_level: 3,
    lng: '102.364373',
    lat: '30.999031'
  },
  {
    code: '513228',
    name: '黑水县',
    city_level: 3,
    lng: '102.990108',
    lat: '32.061895'
  },
  {
    code: '513230',
    name: '壤塘县',
    city_level: 3,
    lng: '100.978526',
    lat: '32.265796'
  },
  {
    code: '513231',
    name: '阿坝县',
    city_level: 3,
    lng: '101.706655',
    lat: '32.902459'
  },
  {
    code: '513232',
    name: '若尔盖县',
    city_level: 3,
    lng: '102.961798',
    lat: '33.575892'
  },
  {
    code: '513233',
    name: '红原县',
    city_level: 3,
    lng: '102.544405',
    lat: '32.790891'
  },
  {
    code: '513300',
    name: '甘孜藏族自治州',
    city_level: 2,
    lng: '101.962310',
    lat: '30.049520'
  },
  {
    code: '513301',
    name: '康定市',
    city_level: 3,
    lng: '101.957146',
    lat: '29.998436'
  },
  {
    code: '513322',
    name: '泸定县',
    city_level: 3,
    lng: '102.234618',
    lat: '29.914160'
  },
  {
    code: '513323',
    name: '丹巴县',
    city_level: 3,
    lng: '101.890358',
    lat: '30.878577'
  },
  {
    code: '513324',
    name: '九龙县',
    city_level: 3,
    lng: '101.507294',
    lat: '29.000348'
  },
  {
    code: '513325',
    name: '雅江县',
    city_level: 3,
    lng: '101.014425',
    lat: '30.031533'
  },
  {
    code: '513326',
    name: '道孚县',
    city_level: 3,
    lng: '101.125237',
    lat: '30.979545'
  },
  {
    code: '513327',
    name: '炉霍县',
    city_level: 3,
    lng: '100.676372',
    lat: '31.391790'
  },
  {
    code: '513328',
    name: '甘孜县',
    city_level: 3,
    lng: '99.992671',
    lat: '31.622934'
  },
  {
    code: '513329',
    name: '新龙县',
    city_level: 3,
    lng: '100.311369',
    lat: '30.939169'
  },
  {
    code: '513330',
    name: '德格县',
    city_level: 3,
    lng: '98.580915',
    lat: '31.806118'
  },
  {
    code: '513331',
    name: '白玉县',
    city_level: 3,
    lng: '98.824182',
    lat: '31.209913'
  },
  {
    code: '513332',
    name: '石渠县',
    city_level: 3,
    lng: '98.102900',
    lat: '32.978960'
  },
  {
    code: '513333',
    name: '色达县',
    city_level: 3,
    lng: '100.332743',
    lat: '32.268129'
  },
  {
    code: '513334',
    name: '理塘县',
    city_level: 3,
    lng: '100.269818',
    lat: '29.996049'
  },
  {
    code: '513335',
    name: '巴塘县',
    city_level: 3,
    lng: '99.110712',
    lat: '30.004677'
  },
  {
    code: '513336',
    name: '乡城县',
    city_level: 3,
    lng: '99.798435',
    lat: '28.931172'
  },
  {
    code: '513337',
    name: '稻城县',
    city_level: 3,
    lng: '100.298403',
    lat: '29.037007'
  },
  {
    code: '513338',
    name: '得荣县',
    city_level: 3,
    lng: '99.286335',
    lat: '28.713037'
  },
  {
    code: '513400',
    name: '凉山彝族自治州',
    city_level: 2,
    lng: '102.267335',
    lat: '27.881610'
  },
  {
    code: '513401',
    name: '西昌市',
    city_level: 3,
    lng: '102.264449',
    lat: '27.894504'
  },
  {
    code: '513422',
    name: '木里藏族自治县',
    city_level: 3,
    lng: '101.280206',
    lat: '27.928835'
  },
  {
    code: '513423',
    name: '盐源县',
    city_level: 3,
    lng: '101.509188',
    lat: '27.422645'
  },
  {
    code: '513424',
    name: '德昌县',
    city_level: 3,
    lng: '102.175670',
    lat: '27.402839'
  },
  {
    code: '513425',
    name: '会理县',
    city_level: 3,
    lng: '102.244683',
    lat: '26.655026'
  },
  {
    code: '513426',
    name: '会东县',
    city_level: 3,
    lng: '102.577961',
    lat: '26.634669'
  },
  {
    code: '513427',
    name: '宁南县',
    city_level: 3,
    lng: '102.759634',
    lat: '27.066384'
  },
  {
    code: '513428',
    name: '普格县',
    city_level: 3,
    lng: '102.540901',
    lat: '27.376413'
  },
  {
    code: '513429',
    name: '布拖县',
    city_level: 3,
    lng: '102.811631',
    lat: '27.706192'
  },
  {
    code: '513430',
    name: '金阳县',
    city_level: 3,
    lng: '103.248772',
    lat: '27.696861'
  },
  {
    code: '513431',
    name: '昭觉县',
    city_level: 3,
    lng: '102.842611',
    lat: '28.014088'
  },
  {
    code: '513432',
    name: '喜德县',
    city_level: 3,
    lng: '102.412518',
    lat: '28.306726'
  },
  {
    code: '513433',
    name: '冕宁县',
    city_level: 3,
    lng: '102.177010',
    lat: '28.549657'
  },
  {
    code: '513434',
    name: '越西县',
    city_level: 3,
    lng: '102.507680',
    lat: '28.639801'
  },
  {
    code: '513435',
    name: '甘洛县',
    city_level: 3,
    lng: '102.771749',
    lat: '28.966069'
  },
  {
    code: '513436',
    name: '美姑县',
    city_level: 3,
    lng: '103.132180',
    lat: '28.328640'
  },
  {
    code: '513437',
    name: '雷波县',
    city_level: 3,
    lng: '103.571696',
    lat: '28.262683'
  },
  {
    code: '520000',
    name: '贵州省',
    city_level: 1,
    lng: '106.707410',
    lat: '26.598194'
  },
  {
    code: '520100',
    name: '贵阳市',
    city_level: 2,
    lng: '106.630153',
    lat: '26.647661'
  },
  {
    code: '520102',
    name: '南明区',
    city_level: 3,
    lng: '106.688760',
    lat: '26.570062'
  },
  {
    code: '520103',
    name: '云岩区',
    city_level: 3,
    lng: '106.724426',
    lat: '26.604594'
  },
  {
    code: '520111',
    name: '花溪区',
    city_level: 3,
    lng: '106.670260',
    lat: '26.409818'
  },
  {
    code: '520112',
    name: '乌当区',
    city_level: 3,
    lng: '106.750625',
    lat: '26.630845'
  },
  {
    code: '520113',
    name: '白云区',
    city_level: 3,
    lng: '106.623007',
    lat: '26.678562'
  },
  {
    code: '520115',
    name: '观山湖区',
    city_level: 3,
    lng: '106.622453',
    lat: '26.601450'
  },
  {
    code: '520121',
    name: '开阳县',
    city_level: 3,
    lng: '106.965090',
    lat: '27.057764'
  },
  {
    code: '520122',
    name: '息烽县',
    city_level: 3,
    lng: '106.740408',
    lat: '27.090479'
  },
  {
    code: '520123',
    name: '修文县',
    city_level: 3,
    lng: '106.592108',
    lat: '26.838926'
  },
  {
    code: '520181',
    name: '清镇市',
    city_level: 3,
    lng: '106.470715',
    lat: '26.556079'
  },
  {
    code: '520200',
    name: '六盘水市',
    city_level: 2,
    lng: '104.830359',
    lat: '26.592666'
  },
  {
    code: '520201',
    name: '钟山区',
    city_level: 3,
    lng: '104.843555',
    lat: '26.574979'
  },
  {
    code: '520203',
    name: '六枝特区',
    city_level: 3,
    lng: '105.480029',
    lat: '26.201228'
  },
  {
    code: '520204',
    name: '水城区',
    city_level: 3,
    lng: '105.348750',
    lat: '26.080788'
  },
  {
    code: '520281',
    name: '盘州市',
    city_level: 2,
    lng: '104.830359',
    lat: '26.592666'
  },
  {
    code: '520300',
    name: '遵义市',
    city_level: 2,
    lng: '106.927389',
    lat: '27.725654'
  },
  {
    code: '520302',
    name: '红花岗区',
    city_level: 3,
    lng: '106.893709',
    lat: '27.644755'
  },
  {
    code: '520303',
    name: '汇川区',
    city_level: 3,
    lng: '106.934270',
    lat: '27.750125'
  },
  {
    code: '520304',
    name: '播州区',
    city_level: 2,
    lng: '106.927389',
    lat: '27.725654'
  },
  {
    code: '520322',
    name: '桐梓县',
    city_level: 3,
    lng: '106.825644',
    lat: '28.133583'
  },
  {
    code: '520323',
    name: '绥阳县',
    city_level: 3,
    lng: '107.191222',
    lat: '27.946222'
  },
  {
    code: '520324',
    name: '正安县',
    city_level: 3,
    lng: '107.453945',
    lat: '28.553285'
  },
  {
    code: '520325',
    name: '道真仡佬族苗族自治县',
    city_level: 3,
    lng: '107.613133',
    lat: '28.862425'
  },
  {
    code: '520326',
    name: '务川仡佬族苗族自治县',
    city_level: 3,
    lng: '107.898957',
    lat: '28.563086'
  },
  {
    code: '520327',
    name: '凤冈县',
    city_level: 3,
    lng: '107.716356',
    lat: '27.954695'
  },
  {
    code: '520328',
    name: '湄潭县',
    city_level: 3,
    lng: '107.465407',
    lat: '27.749055'
  },
  {
    code: '520329',
    name: '余庆县',
    city_level: 3,
    lng: '107.905278',
    lat: '27.215420'
  },
  {
    code: '520330',
    name: '习水县',
    city_level: 3,
    lng: '106.197138',
    lat: '28.331270'
  },
  {
    code: '520381',
    name: '赤水市',
    city_level: 3,
    lng: '105.697472',
    lat: '28.590337'
  },
  {
    code: '520382',
    name: '仁怀市',
    city_level: 3,
    lng: '106.400342',
    lat: '27.791650'
  },
  {
    code: '520400',
    name: '安顺市',
    city_level: 2,
    lng: '105.947593',
    lat: '26.253072'
  },
  {
    code: '520402',
    name: '西秀区',
    city_level: 3,
    lng: '105.965535',
    lat: '26.245433'
  },
  {
    code: '520403',
    name: '平坝区',
    city_level: 3,
    lng: '106.255557',
    lat: '26.405502'
  },
  {
    code: '520422',
    name: '普定县',
    city_level: 3,
    lng: '105.743196',
    lat: '26.301446'
  },
  {
    code: '520423',
    name: '镇宁布依族苗族自治县',
    city_level: 3,
    lng: '105.770402',
    lat: '26.057362'
  },
  {
    code: '520424',
    name: '关岭布依族苗族自治县',
    city_level: 3,
    lng: '105.618745',
    lat: '25.943856'
  },
  {
    code: '520425',
    name: '紫云苗族布依族自治县',
    city_level: 3,
    lng: '106.084340',
    lat: '25.751052'
  },
  {
    code: '520500',
    name: '毕节市',
    city_level: 2,
    lng: '105.283992',
    lat: '27.302589'
  },
  {
    code: '520502',
    name: '七星关区',
    city_level: 3,
    lng: '105.305138',
    lat: '27.298494'
  },
  {
    code: '520521',
    name: '大方县',
    city_level: 3,
    lng: '105.611656',
    lat: '27.137097'
  },
  {
    code: '520522',
    name: '黔西县',
    city_level: 3,
    lng: '106.033544',
    lat: '27.007713'
  },
  {
    code: '520523',
    name: '金沙县',
    city_level: 3,
    lng: '106.220228',
    lat: '27.459214'
  },
  {
    code: '520524',
    name: '织金县',
    city_level: 3,
    lng: '105.770542',
    lat: '26.663450'
  },
  {
    code: '520525',
    name: '纳雍县',
    city_level: 3,
    lng: '105.382715',
    lat: '26.777645'
  },
  {
    code: '520526',
    name: '威宁彝族回族苗族自治县',
    city_level: 3,
    lng: '104.278733',
    lat: '26.856193'
  },
  {
    code: '520527',
    name: '赫章县',
    city_level: 3,
    lng: '104.727418',
    lat: '27.123079'
  },
  {
    code: '520600',
    name: '铜仁市',
    city_level: 2,
    lng: '109.189598',
    lat: '27.731514'
  },
  {
    code: '520602',
    name: '碧江区',
    city_level: 3,
    lng: '109.181122',
    lat: '27.690653'
  },
  {
    code: '520603',
    name: '万山区',
    city_level: 3,
    lng: '109.213644',
    lat: '27.517896'
  },
  {
    code: '520621',
    name: '江口县',
    city_level: 3,
    lng: '108.839557',
    lat: '27.699650'
  },
  {
    code: '520622',
    name: '玉屏侗族自治县',
    city_level: 3,
    lng: '108.914680',
    lat: '27.243012'
  },
  {
    code: '520623',
    name: '石阡县',
    city_level: 3,
    lng: '108.223612',
    lat: '27.513829'
  },
  {
    code: '520624',
    name: '思南县',
    city_level: 3,
    lng: '108.253873',
    lat: '27.937560'
  },
  {
    code: '520625',
    name: '印江土家族苗族自治县',
    city_level: 3,
    lng: '108.409752',
    lat: '27.994247'
  },
  {
    code: '520626',
    name: '德江县',
    city_level: 3,
    lng: '108.119807',
    lat: '28.263964'
  },
  {
    code: '520627',
    name: '沿河土家族自治县',
    city_level: 3,
    lng: '108.503870',
    lat: '28.563928'
  },
  {
    code: '520628',
    name: '松桃苗族自治县',
    city_level: 3,
    lng: '109.202886',
    lat: '28.154071'
  },
  {
    code: '522300',
    name: '黔西南布依族苗族自治州',
    city_level: 2,
    lng: '104.906396',
    lat: '25.087825'
  },
  {
    code: '522301',
    name: '兴义市',
    city_level: 3,
    lng: '104.895467',
    lat: '25.092040'
  },
  {
    code: '522302',
    name: '兴仁市',
    city_level: 3,
    lng: '105.192780',
    lat: '25.431377'
  },
  {
    code: '522323',
    name: '普安县',
    city_level: 3,
    lng: '104.953063',
    lat: '25.784135'
  },
  {
    code: '522324',
    name: '晴隆县',
    city_level: 3,
    lng: '105.218991',
    lat: '25.834784'
  },
  {
    code: '522325',
    name: '贞丰县',
    city_level: 3,
    lng: '105.649864',
    lat: '25.385760'
  },
  {
    code: '522326',
    name: '望谟县',
    city_level: 3,
    lng: '106.093850',
    lat: '25.171024'
  },
  {
    code: '522327',
    name: '册亨县',
    city_level: 3,
    lng: '105.811593',
    lat: '24.983663'
  },
  {
    code: '522328',
    name: '安龙县',
    city_level: 3,
    lng: '105.442701',
    lat: '25.099014'
  },
  {
    code: '522600',
    name: '黔东南苗族侗族自治州',
    city_level: 2,
    lng: '107.982859',
    lat: '26.583442'
  },
  {
    code: '522601',
    name: '凯里市',
    city_level: 3,
    lng: '107.981212',
    lat: '26.566867'
  },
  {
    code: '522622',
    name: '黄平县',
    city_level: 3,
    lng: '107.916412',
    lat: '26.905396'
  },
  {
    code: '522623',
    name: '施秉县',
    city_level: 3,
    lng: '108.124380',
    lat: '27.032920'
  },
  {
    code: '522624',
    name: '三穗县',
    city_level: 3,
    lng: '108.675267',
    lat: '26.952968'
  },
  {
    code: '522625',
    name: '镇远县',
    city_level: 3,
    lng: '108.429691',
    lat: '27.049110'
  },
  {
    code: '522626',
    name: '岑巩县',
    city_level: 3,
    lng: '108.816060',
    lat: '27.173887'
  },
  {
    code: '522627',
    name: '天柱县',
    city_level: 3,
    lng: '109.207757',
    lat: '26.909678'
  },
  {
    code: '522628',
    name: '锦屏县',
    city_level: 3,
    lng: '109.200534',
    lat: '26.676233'
  },
  {
    code: '522629',
    name: '剑河县',
    city_level: 3,
    lng: '108.441501',
    lat: '26.728274'
  },
  {
    code: '522630',
    name: '台江县',
    city_level: 3,
    lng: '108.321245',
    lat: '26.667525'
  },
  {
    code: '522631',
    name: '黎平县',
    city_level: 3,
    lng: '109.136658',
    lat: '26.230424'
  },
  {
    code: '522632',
    name: '榕江县',
    city_level: 3,
    lng: '108.521881',
    lat: '25.931893'
  },
  {
    code: '522633',
    name: '从江县',
    city_level: 3,
    lng: '108.905329',
    lat: '25.753009'
  },
  {
    code: '522634',
    name: '雷山县',
    city_level: 3,
    lng: '108.077540',
    lat: '26.378443'
  },
  {
    code: '522635',
    name: '麻江县',
    city_level: 3,
    lng: '107.589359',
    lat: '26.491105'
  },
  {
    code: '522636',
    name: '丹寨县',
    city_level: 3,
    lng: '107.788728',
    lat: '26.198320'
  },
  {
    code: '522700',
    name: '黔南布依族苗族自治州',
    city_level: 2,
    lng: '107.522097',
    lat: '26.254092'
  },
  {
    code: '522701',
    name: '都匀市',
    city_level: 3,
    lng: '107.518847',
    lat: '26.259427'
  },
  {
    code: '522702',
    name: '福泉市',
    city_level: 3,
    lng: '107.520386',
    lat: '26.686335'
  },
  {
    code: '522722',
    name: '荔波县',
    city_level: 3,
    lng: '107.886450',
    lat: '25.410654'
  },
  {
    code: '522723',
    name: '贵定县',
    city_level: 3,
    lng: '107.232889',
    lat: '26.557071'
  },
  {
    code: '522725',
    name: '瓮安县',
    city_level: 3,
    lng: '107.471555',
    lat: '27.078472'
  },
  {
    code: '522726',
    name: '独山县',
    city_level: 3,
    lng: '107.545048',
    lat: '25.822132'
  },
  {
    code: '522727',
    name: '平塘县',
    city_level: 3,
    lng: '107.323077',
    lat: '25.831955'
  },
  {
    code: '522728',
    name: '罗甸县',
    city_level: 3,
    lng: '106.751418',
    lat: '25.424845'
  },
  {
    code: '522729',
    name: '长顺县',
    city_level: 3,
    lng: '106.447376',
    lat: '26.022116'
  },
  {
    code: '522730',
    name: '龙里县',
    city_level: 3,
    lng: '106.979524',
    lat: '26.453154'
  },
  {
    code: '522731',
    name: '惠水县',
    city_level: 3,
    lng: '106.657089',
    lat: '26.132061'
  },
  {
    code: '522732',
    name: '三都水族自治县',
    city_level: 3,
    lng: '107.869749',
    lat: '25.983202'
  },
  {
    code: '530000',
    name: '云南省',
    city_level: 1,
    lng: '102.710002',
    lat: '25.045806'
  },
  {
    code: '530100',
    name: '昆明市',
    city_level: 2,
    lng: '102.832891',
    lat: '24.880095'
  },
  {
    code: '530102',
    name: '五华区',
    city_level: 3,
    lng: '102.706959',
    lat: '25.043652'
  },
  {
    code: '530103',
    name: '盘龙区',
    city_level: 3,
    lng: '102.751907',
    lat: '25.116106'
  },
  {
    code: '530111',
    name: '官渡区',
    city_level: 3,
    lng: '102.743812',
    lat: '25.015105'
  },
  {
    code: '530112',
    name: '西山区',
    city_level: 3,
    lng: '102.664376',
    lat: '25.038297'
  },
  {
    code: '530113',
    name: '东川区',
    city_level: 3,
    lng: '103.187825',
    lat: '26.082873'
  },
  {
    code: '530114',
    name: '呈贡区',
    city_level: 3,
    lng: '102.821468',
    lat: '24.885532'
  },
  {
    code: '530115',
    name: '晋宁区',
    city_level: 3,
    lng: '102.594986',
    lat: '24.666945'
  },
  {
    code: '530124',
    name: '富民县',
    city_level: 3,
    lng: '102.497600',
    lat: '25.221935'
  },
  {
    code: '530125',
    name: '宜良县',
    city_level: 3,
    lng: '103.141296',
    lat: '24.919715'
  },
  {
    code: '530126',
    name: '石林彝族自治县',
    city_level: 3,
    lng: '103.290785',
    lat: '24.771798'
  },
  {
    code: '530127',
    name: '嵩明县',
    city_level: 3,
    lng: '103.036908',
    lat: '25.338644'
  },
  {
    code: '530128',
    name: '禄劝彝族苗族自治县',
    city_level: 3,
    lng: '102.471518',
    lat: '25.551332'
  },
  {
    code: '530129',
    name: '寻甸回族彝族自治县',
    city_level: 3,
    lng: '103.256604',
    lat: '25.558175'
  },
  {
    code: '530181',
    name: '安宁市',
    city_level: 3,
    lng: '102.478494',
    lat: '24.919493'
  },
  {
    code: '530300',
    name: '曲靖市',
    city_level: 2,
    lng: '103.796167',
    lat: '25.489999'
  },
  {
    code: '530302',
    name: '麒麟区',
    city_level: 3,
    lng: '103.805012',
    lat: '25.495241'
  },
  {
    code: '530303',
    name: '沾益区',
    city_level: 2,
    lng: '103.796167',
    lat: '25.489999'
  },
  {
    code: '530304',
    name: '马龙区',
    city_level: 3,
    lng: '104.520734',
    lat: '26.403423'
  },
  {
    code: '530322',
    name: '陆良县',
    city_level: 3,
    lng: '103.666663',
    lat: '25.030051'
  },
  {
    code: '530323',
    name: '师宗县',
    city_level: 3,
    lng: '103.985478',
    lat: '24.822403'
  },
  {
    code: '530324',
    name: '罗平县',
    city_level: 3,
    lng: '104.308675',
    lat: '24.884626'
  },
  {
    code: '530325',
    name: '富源县',
    city_level: 3,
    lng: '104.255015',
    lat: '25.674238'
  },
  {
    code: '530326',
    name: '会泽县',
    city_level: 3,
    lng: '103.297361',
    lat: '26.417947'
  },
  {
    code: '530381',
    name: '宣威市',
    city_level: 3,
    lng: '104.104497',
    lat: '26.219759'
  },
  {
    code: '530400',
    name: '玉溪市',
    city_level: 2,
    lng: '102.546543',
    lat: '24.352036'
  },
  {
    code: '530402',
    name: '红塔区',
    city_level: 3,
    lng: '102.540179',
    lat: '24.341098'
  },
  {
    code: '530403',
    name: '江川区',
    city_level: 3,
    lng: '102.753760',
    lat: '24.287586'
  },
  {
    code: '530423',
    name: '通海县',
    city_level: 3,
    lng: '102.760039',
    lat: '24.112205'
  },
  {
    code: '530424',
    name: '华宁县',
    city_level: 3,
    lng: '102.928835',
    lat: '24.192761'
  },
  {
    code: '530425',
    name: '易门县',
    city_level: 3,
    lng: '102.162531',
    lat: '24.671651'
  },
  {
    code: '530426',
    name: '峨山彝族自治县',
    city_level: 3,
    lng: '102.405819',
    lat: '24.168957'
  },
  {
    code: '530427',
    name: '新平彝族傣族自治县',
    city_level: 3,
    lng: '101.990157',
    lat: '24.070051'
  },
  {
    code: '530428',
    name: '元江哈尼族彝族傣族自治县',
    city_level: 3,
    lng: '101.998103',
    lat: '23.596503'
  },
  {
    code: '530481',
    name: '澄江市',
    city_level: 3,
    lng: '102.910673',
    lat: '24.671778'
  },
  {
    code: '530500',
    name: '保山市',
    city_level: 2,
    lng: '99.161761',
    lat: '25.112046'
  },
  {
    code: '530502',
    name: '隆阳区',
    city_level: 3,
    lng: '99.165607',
    lat: '25.121154'
  },
  {
    code: '530521',
    name: '施甸县',
    city_level: 3,
    lng: '99.189221',
    lat: '24.723064'
  },
  {
    code: '530523',
    name: '龙陵县',
    city_level: 3,
    lng: '98.689230',
    lat: '24.586766'
  },
  {
    code: '530524',
    name: '昌宁县',
    city_level: 3,
    lng: '99.605142',
    lat: '24.827839'
  },
  {
    code: '530581',
    name: '腾冲市',
    city_level: 3,
    lng: '98.485333',
    lat: '25.033111'
  },
  {
    code: '530600',
    name: '昭通市',
    city_level: 2,
    lng: '103.717465',
    lat: '27.338257'
  },
  {
    code: '530602',
    name: '昭阳区',
    city_level: 3,
    lng: '103.706539',
    lat: '27.320075'
  },
  {
    code: '530621',
    name: '鲁甸县',
    city_level: 3,
    lng: '103.558042',
    lat: '27.186659'
  },
  {
    code: '530622',
    name: '巧家县',
    city_level: 3,
    lng: '102.930164',
    lat: '26.908461'
  },
  {
    code: '530623',
    name: '盐津县',
    city_level: 3,
    lng: '104.234442',
    lat: '28.108710'
  },
  {
    code: '530624',
    name: '大关县',
    city_level: 3,
    lng: '103.891146',
    lat: '27.747978'
  },
  {
    code: '530625',
    name: '永善县',
    city_level: 3,
    lng: '103.638067',
    lat: '28.229113'
  },
  {
    code: '530626',
    name: '绥江县',
    city_level: 3,
    lng: '103.968978',
    lat: '28.592100'
  },
  {
    code: '530627',
    name: '镇雄县',
    city_level: 3,
    lng: '104.873579',
    lat: '27.441668'
  },
  {
    code: '530628',
    name: '彝良县',
    city_level: 3,
    lng: '104.048289',
    lat: '27.625419'
  },
  {
    code: '530629',
    name: '威信县',
    city_level: 3,
    lng: '105.049027',
    lat: '27.846901'
  },
  {
    code: '530681',
    name: '水富市',
    city_level: 2,
    lng: '103.717465',
    lat: '27.338257'
  },
  {
    code: '530700',
    name: '丽江市',
    city_level: 2,
    lng: '100.227750',
    lat: '26.855047'
  },
  {
    code: '530702',
    name: '古城区',
    city_level: 3,
    lng: '100.225766',
    lat: '26.877190'
  },
  {
    code: '530721',
    name: '玉龙纳西族自治县',
    city_level: 3,
    lng: '100.236955',
    lat: '26.821460'
  },
  {
    code: '530722',
    name: '永胜县',
    city_level: 3,
    lng: '100.750795',
    lat: '26.684225'
  },
  {
    code: '530723',
    name: '华坪县',
    city_level: 3,
    lng: '101.266195',
    lat: '26.629211'
  },
  {
    code: '530724',
    name: '宁蒗彝族自治县',
    city_level: 3,
    lng: '100.852001',
    lat: '27.282071'
  },
  {
    code: '530800',
    name: '普洱市',
    city_level: 2,
    lng: '100.966512',
    lat: '22.825065'
  },
  {
    code: '530802',
    name: '思茅区',
    city_level: 3,
    lng: '100.977165',
    lat: '22.786910'
  },
  {
    code: '530821',
    name: '宁洱哈尼族彝族自治县',
    city_level: 3,
    lng: '101.045317',
    lat: '23.061750'
  },
  {
    code: '530822',
    name: '墨江哈尼族自治县',
    city_level: 3,
    lng: '101.692461',
    lat: '23.431894'
  },
  {
    code: '530823',
    name: '景东彝族自治县',
    city_level: 3,
    lng: '100.833877',
    lat: '24.446731'
  },
  {
    code: '530824',
    name: '景谷傣族彝族自治县',
    city_level: 3,
    lng: '100.702871',
    lat: '23.497028'
  },
  {
    code: '530825',
    name: '镇沅彝族哈尼族拉祜族自治县',
    city_level: 3,
    lng: '101.108595',
    lat: '24.004442'
  },
  {
    code: '530826',
    name: '江城哈尼族彝族自治县',
    city_level: 3,
    lng: '101.862120',
    lat: '22.585868'
  },
  {
    code: '530827',
    name: '孟连傣族拉祜族佤族自治县',
    city_level: 3,
    lng: '99.584157',
    lat: '22.329100'
  },
  {
    code: '530828',
    name: '澜沧拉祜族自治县',
    city_level: 3,
    lng: '99.931975',
    lat: '22.555905'
  },
  {
    code: '530829',
    name: '西盟佤族自治县',
    city_level: 3,
    lng: '99.590124',
    lat: '22.644508'
  },
  {
    code: '530900',
    name: '临沧市',
    city_level: 2,
    lng: '100.079583',
    lat: '23.877573'
  },
  {
    code: '530902',
    name: '临翔区',
    city_level: 3,
    lng: '100.082523',
    lat: '23.895137'
  },
  {
    code: '530921',
    name: '凤庆县',
    city_level: 3,
    lng: '99.928460',
    lat: '24.580424'
  },
  {
    code: '530922',
    name: '云县',
    city_level: 3,
    lng: '100.123248',
    lat: '24.437061'
  },
  {
    code: '530923',
    name: '永德县',
    city_level: 3,
    lng: '99.259340',
    lat: '24.018357'
  },
  {
    code: '530924',
    name: '镇康县',
    city_level: 3,
    lng: '98.825285',
    lat: '23.762584'
  },
  {
    code: '530925',
    name: '双江拉祜族佤族布朗族傣族自治县',
    city_level: 3,
    lng: '99.827698',
    lat: '23.473499'
  },
  {
    code: '530926',
    name: '耿马傣族佤族自治县',
    city_level: 3,
    lng: '99.397127',
    lat: '23.538092'
  },
  {
    code: '530927',
    name: '沧源佤族自治县',
    city_level: 3,
    lng: '99.246197',
    lat: '23.146712'
  },
  {
    code: '532300',
    name: '楚雄彝族自治州',
    city_level: 2,
    lng: '101.528068',
    lat: '25.045532'
  },
  {
    code: '532301',
    name: '楚雄市',
    city_level: 2,
    lng: '101.528068',
    lat: '25.045532'
  },
  {
    code: '532322',
    name: '双柏县',
    city_level: 3,
    lng: '101.641937',
    lat: '24.688875'
  },
  {
    code: '532323',
    name: '牟定县',
    city_level: 3,
    lng: '101.546566',
    lat: '25.313122'
  },
  {
    code: '532324',
    name: '南华县',
    city_level: 3,
    lng: '101.273577',
    lat: '25.192293'
  },
  {
    code: '532325',
    name: '姚安县',
    city_level: 3,
    lng: '101.241728',
    lat: '25.504173'
  },
  {
    code: '532326',
    name: '大姚县',
    city_level: 3,
    lng: '101.336617',
    lat: '25.729513'
  },
  {
    code: '532327',
    name: '永仁县',
    city_level: 3,
    lng: '101.666133',
    lat: '26.049464'
  },
  {
    code: '532328',
    name: '元谋县',
    city_level: 3,
    lng: '101.874520',
    lat: '25.704338'
  },
  {
    code: '532329',
    name: '武定县',
    city_level: 3,
    lng: '102.404338',
    lat: '25.530389'
  },
  {
    code: '532331',
    name: '禄丰县',
    city_level: 3,
    lng: '102.079027',
    lat: '25.150111'
  },
  {
    code: '532500',
    name: '红河哈尼族彝族自治州',
    city_level: 2,
    lng: '103.374799',
    lat: '23.363130'
  },
  {
    code: '532501',
    name: '个旧市',
    city_level: 3,
    lng: '103.160034',
    lat: '23.359121'
  },
  {
    code: '532502',
    name: '开远市',
    city_level: 3,
    lng: '103.267143',
    lat: '23.714316'
  },
  {
    code: '532503',
    name: '蒙自市',
    city_level: 3,
    lng: '103.364905',
    lat: '23.396201'
  },
  {
    code: '532504',
    name: '弥勒市',
    city_level: 3,
    lng: '103.414874',
    lat: '24.411912'
  },
  {
    code: '532523',
    name: '屏边苗族自治县',
    city_level: 3,
    lng: '103.687612',
    lat: '22.983560'
  },
  {
    code: '532524',
    name: '建水县',
    city_level: 3,
    lng: '102.826557',
    lat: '23.634700'
  },
  {
    code: '532525',
    name: '石屏县',
    city_level: 3,
    lng: '102.494984',
    lat: '23.705936'
  },
  {
    code: '532527',
    name: '泸西县',
    city_level: 3,
    lng: '103.766196',
    lat: '24.532025'
  },
  {
    code: '532528',
    name: '元阳县',
    city_level: 3,
    lng: '102.835223',
    lat: '23.219932'
  },
  {
    code: '532529',
    name: '红河县',
    city_level: 3,
    lng: '102.420600',
    lat: '23.369161'
  },
  {
    code: '532530',
    name: '金平苗族瑶族傣族自治县',
    city_level: 3,
    lng: '103.226448',
    lat: '22.779543'
  },
  {
    code: '532531',
    name: '绿春县',
    city_level: 3,
    lng: '102.392463',
    lat: '22.993718'
  },
  {
    code: '532532',
    name: '河口瑶族自治县',
    city_level: 3,
    lng: '103.939350',
    lat: '22.529404'
  },
  {
    code: '532600',
    name: '文山壮族苗族自治州',
    city_level: 2,
    lng: '104.216248',
    lat: '23.400733'
  },
  {
    code: '532601',
    name: '文山市',
    city_level: 2,
    lng: '104.216248',
    lat: '23.400733'
  },
  {
    code: '532622',
    name: '砚山县',
    city_level: 3,
    lng: '104.337242',
    lat: '23.605865'
  },
  {
    code: '532623',
    name: '西畴县',
    city_level: 3,
    lng: '104.672597',
    lat: '23.437782'
  },
  {
    code: '532624',
    name: '麻栗坡县',
    city_level: 3,
    lng: '104.702799',
    lat: '23.125714'
  },
  {
    code: '532625',
    name: '马关县',
    city_level: 3,
    lng: '104.394158',
    lat: '23.012915'
  },
  {
    code: '532626',
    name: '丘北县',
    city_level: 3,
    lng: '104.195820',
    lat: '24.041919'
  },
  {
    code: '532627',
    name: '广南县',
    city_level: 3,
    lng: '105.054981',
    lat: '24.045941'
  },
  {
    code: '532628',
    name: '富宁县',
    city_level: 3,
    lng: '105.630999',
    lat: '23.625283'
  },
  {
    code: '532800',
    name: '西双版纳傣族自治州',
    city_level: 2,
    lng: '100.797777',
    lat: '22.007351'
  },
  {
    code: '532801',
    name: '景洪市',
    city_level: 3,
    lng: '100.771679',
    lat: '22.000143'
  },
  {
    code: '532822',
    name: '勐海县',
    city_level: 3,
    lng: '100.452548',
    lat: '21.957354'
  },
  {
    code: '532823',
    name: '勐腊县',
    city_level: 3,
    lng: '101.564636',
    lat: '21.459233'
  },
  {
    code: '532900',
    name: '大理白族自治州',
    city_level: 2,
    lng: '100.267638',
    lat: '25.606486'
  },
  {
    code: '532901',
    name: '大理市',
    city_level: 2,
    lng: '100.267638',
    lat: '25.606486'
  },
  {
    code: '532922',
    name: '漾濞彝族自治县',
    city_level: 3,
    lng: '99.958015',
    lat: '25.670148'
  },
  {
    code: '532923',
    name: '祥云县',
    city_level: 3,
    lng: '100.550946',
    lat: '25.483850'
  },
  {
    code: '532924',
    name: '宾川县',
    city_level: 3,
    lng: '100.575412',
    lat: '25.827182'
  },
  {
    code: '532925',
    name: '弥渡县',
    city_level: 3,
    lng: '100.490991',
    lat: '25.343804'
  },
  {
    code: '532926',
    name: '南涧彝族自治县',
    city_level: 3,
    lng: '100.509036',
    lat: '25.043510'
  },
  {
    code: '532927',
    name: '巍山彝族回族自治县',
    city_level: 3,
    lng: '100.307175',
    lat: '25.227212'
  },
  {
    code: '532928',
    name: '永平县',
    city_level: 3,
    lng: '99.541236',
    lat: '25.464681'
  },
  {
    code: '532929',
    name: '云龙县',
    city_level: 3,
    lng: '99.371121',
    lat: '25.885596'
  },
  {
    code: '532930',
    name: '洱源县',
    city_level: 3,
    lng: '99.951054',
    lat: '26.111160'
  },
  {
    code: '532931',
    name: '剑川县',
    city_level: 3,
    lng: '99.905559',
    lat: '26.537033'
  },
  {
    code: '532932',
    name: '鹤庆县',
    city_level: 3,
    lng: '100.176498',
    lat: '26.560231'
  },
  {
    code: '533100',
    name: '德宏傣族景颇族自治州',
    city_level: 2,
    lng: '98.584895',
    lat: '24.433353'
  },
  {
    code: '533102',
    name: '瑞丽市',
    city_level: 3,
    lng: '97.855477',
    lat: '24.017836'
  },
  {
    code: '533103',
    name: '芒市',
    city_level: 3,
    lng: '98.588086',
    lat: '24.433690'
  },
  {
    code: '533122',
    name: '梁河县',
    city_level: 3,
    lng: '98.296657',
    lat: '24.804232'
  },
  {
    code: '533123',
    name: '盈江县',
    city_level: 3,
    lng: '97.943613',
    lat: '24.691283'
  },
  {
    code: '533124',
    name: '陇川县',
    city_level: 3,
    lng: '97.792105',
    lat: '24.182965'
  },
  {
    code: '533300',
    name: '怒江傈僳族自治州',
    city_level: 2,
    lng: '98.853097',
    lat: '25.852547'
  },
  {
    code: '533301',
    name: '泸水市',
    city_level: 3,
    lng: '98.857977',
    lat: '25.822880'
  },
  {
    code: '533323',
    name: '福贡县',
    city_level: 3,
    lng: '98.869132',
    lat: '26.901832'
  },
  {
    code: '533324',
    name: '贡山独龙族怒族自治县',
    city_level: 3,
    lng: '98.665965',
    lat: '27.740999'
  },
  {
    code: '533325',
    name: '兰坪白族普米族自治县',
    city_level: 3,
    lng: '99.416677',
    lat: '26.453571'
  },
  {
    code: '533400',
    name: '迪庆藏族自治州',
    city_level: 2,
    lng: '99.702234',
    lat: '27.818882'
  },
  {
    code: '533401',
    name: '香格里拉市',
    city_level: 3,
    lng: '99.700836',
    lat: '27.829743'
  },
  {
    code: '533422',
    name: '德钦县',
    city_level: 3,
    lng: '98.911561',
    lat: '28.486162'
  },
  {
    code: '533423',
    name: '维西傈僳族自治县',
    city_level: 3,
    lng: '99.287173',
    lat: '27.177162'
  },
  {
    code: '540000',
    name: '西藏自治区',
    city_level: 1,
    lng: '91.117212',
    lat: '29.646922'
  },
  {
    code: '540100',
    name: '拉萨市',
    city_level: 2,
    lng: '91.140856',
    lat: '29.645554'
  },
  {
    code: '540102',
    name: '城关区',
    city_level: 3,
    lng: '103.825255',
    lat: '36.057054'
  },
  {
    code: '540103',
    name: '堆龙德庆区',
    city_level: 2,
    lng: '91.140856',
    lat: '29.645554'
  },
  {
    code: '540104',
    name: '达孜区',
    city_level: 3,
    lng: '91.500130',
    lat: '29.819293'
  },
  {
    code: '540121',
    name: '林周县',
    city_level: 3,
    lng: '91.265288',
    lat: '29.893545'
  },
  {
    code: '540122',
    name: '当雄县',
    city_level: 3,
    lng: '91.101162',
    lat: '30.473119'
  },
  {
    code: '540123',
    name: '尼木县',
    city_level: 3,
    lng: '90.164524',
    lat: '29.431832'
  },
  {
    code: '540124',
    name: '曲水县',
    city_level: 3,
    lng: '90.743853',
    lat: '29.353059'
  },
  {
    code: '540127',
    name: '墨竹工卡县',
    city_level: 3,
    lng: '91.730866',
    lat: '29.834132'
  },
  {
    code: '540200',
    name: '日喀则市',
    city_level: 2,
    lng: '88.880583',
    lat: '29.266869'
  },
  {
    code: '540202',
    name: '桑珠孜区',
    city_level: 3,
    lng: '88.887270',
    lat: '29.270389'
  },
  {
    code: '540221',
    name: '南木林县',
    city_level: 3,
    lng: '89.099243',
    lat: '29.682331'
  },
  {
    code: '540222',
    name: '江孜县',
    city_level: 3,
    lng: '89.605574',
    lat: '28.911659'
  },
  {
    code: '540223',
    name: '定日县',
    city_level: 3,
    lng: '87.126120',
    lat: '28.658743'
  },
  {
    code: '540224',
    name: '萨迦县',
    city_level: 3,
    lng: '88.021674',
    lat: '28.899664'
  },
  {
    code: '540225',
    name: '拉孜县',
    city_level: 3,
    lng: '87.637041',
    lat: '29.081660'
  },
  {
    code: '540226',
    name: '昂仁县',
    city_level: 3,
    lng: '87.236051',
    lat: '29.294802'
  },
  {
    code: '540227',
    name: '谢通门县',
    city_level: 3,
    lng: '88.261620',
    lat: '29.432641'
  },
  {
    code: '540228',
    name: '白朗县',
    city_level: 3,
    lng: '89.097806',
    lat: '28.814286'
  },
  {
    code: '540229',
    name: '仁布县',
    city_level: 3,
    lng: '89.841984',
    lat: '29.230933'
  },
  {
    code: '540230',
    name: '康马县',
    city_level: 3,
    lng: '89.681663',
    lat: '28.555627'
  },
  {
    code: '540231',
    name: '定结县',
    city_level: 3,
    lng: '87.765872',
    lat: '28.364159'
  },
  {
    code: '540232',
    name: '仲巴县',
    city_level: 3,
    lng: '84.031530',
    lat: '29.770279'
  },
  {
    code: '540233',
    name: '亚东县',
    city_level: 3,
    lng: '88.907094',
    lat: '27.484806'
  },
  {
    code: '540234',
    name: '吉隆县',
    city_level: 3,
    lng: '85.297535',
    lat: '28.852394'
  },
  {
    code: '540235',
    name: '聂拉木县',
    city_level: 3,
    lng: '85.982237',
    lat: '28.155186'
  },
  {
    code: '540236',
    name: '萨嘎县',
    city_level: 3,
    lng: '85.232941',
    lat: '29.328818'
  },
  {
    code: '540237',
    name: '岗巴县',
    city_level: 3,
    lng: '88.520031',
    lat: '28.274601'
  },
  {
    code: '540300',
    name: '昌都市',
    city_level: 2,
    lng: '97.172020',
    lat: '31.140969'
  },
  {
    code: '540302',
    name: '卡若区',
    city_level: 3,
    lng: '97.180437',
    lat: '31.138507'
  },
  {
    code: '540321',
    name: '江达县',
    city_level: 3,
    lng: '98.218430',
    lat: '31.499202'
  },
  {
    code: '540322',
    name: '贡觉县',
    city_level: 3,
    lng: '98.270970',
    lat: '30.860099'
  },
  {
    code: '540323',
    name: '类乌齐县',
    city_level: 3,
    lng: '96.600246',
    lat: '31.211601'
  },
  {
    code: '540324',
    name: '丁青县',
    city_level: 3,
    lng: '95.595761',
    lat: '31.412405'
  },
  {
    code: '540325',
    name: '察雅县',
    city_level: 3,
    lng: '97.568752',
    lat: '30.653943'
  },
  {
    code: '540326',
    name: '八宿县',
    city_level: 3,
    lng: '96.917836',
    lat: '30.053209'
  },
  {
    code: '540327',
    name: '左贡县',
    city_level: 3,
    lng: '97.841022',
    lat: '29.671069'
  },
  {
    code: '540328',
    name: '芒康县',
    city_level: 3,
    lng: '98.593113',
    lat: '29.679908'
  },
  {
    code: '540329',
    name: '洛隆县',
    city_level: 3,
    lng: '95.824567',
    lat: '30.741571'
  },
  {
    code: '540330',
    name: '边坝县',
    city_level: 3,
    lng: '94.707800',
    lat: '30.933652'
  },
  {
    code: '540400',
    name: '林芝市',
    city_level: 2,
    lng: '94.361558',
    lat: '29.648943'
  },
  {
    code: '540402',
    name: '巴宜区',
    city_level: 3,
    lng: '94.360994',
    lat: '29.653727'
  },
  {
    code: '540421',
    name: '工布江达县',
    city_level: 3,
    lng: '93.246077',
    lat: '29.885280'
  },
  {
    code: '540422',
    name: '米林县',
    city_level: 2,
    lng: '94.361490',
    lat: '29.649128'
  },
  {
    code: '540423',
    name: '墨脱县',
    city_level: 3,
    lng: '95.332241',
    lat: '29.325734'
  },
  {
    code: '540424',
    name: '波密县',
    city_level: 3,
    lng: '95.768158',
    lat: '29.858766'
  },
  {
    code: '540425',
    name: '察隅县',
    city_level: 3,
    lng: '97.465014',
    lat: '28.660234'
  },
  {
    code: '540426',
    name: '朗县',
    city_level: 3,
    lng: '93.074702',
    lat: '29.046337'
  },
  {
    code: '540500',
    name: '山南市',
    city_level: 2,
    lng: '91.773134',
    lat: '29.237137'
  },
  {
    code: '540502',
    name: '乃东区',
    city_level: 3,
    lng: '91.761539',
    lat: '29.224904'
  },
  {
    code: '540521',
    name: '扎囊县',
    city_level: 3,
    lng: '91.337250',
    lat: '29.245114'
  },
  {
    code: '540522',
    name: '贡嘎县',
    city_level: 3,
    lng: '90.984140',
    lat: '29.289455'
  },
  {
    code: '540523',
    name: '桑日县',
    city_level: 3,
    lng: '92.015818',
    lat: '29.259189'
  },
  {
    code: '540524',
    name: '琼结县',
    city_level: 3,
    lng: '91.683881',
    lat: '29.024625'
  },
  {
    code: '540525',
    name: '曲松县',
    city_level: 3,
    lng: '92.203739',
    lat: '29.062826'
  },
  {
    code: '540526',
    name: '措美县',
    city_level: 3,
    lng: '91.433509',
    lat: '28.438202'
  },
  {
    code: '540527',
    name: '洛扎县',
    city_level: 3,
    lng: '90.859992',
    lat: '28.385713'
  },
  {
    code: '540528',
    name: '加查县',
    city_level: 3,
    lng: '92.593993',
    lat: '29.140290'
  },
  {
    code: '540529',
    name: '隆子县',
    city_level: 2,
    lng: '91.773134',
    lat: '29.237137'
  },
  {
    code: '540530',
    name: '错那县',
    city_level: 2,
    lng: '91.773134',
    lat: '29.237137'
  },
  {
    code: '540531',
    name: '浪卡子县',
    city_level: 3,
    lng: '90.397977',
    lat: '28.968031'
  },
  {
    code: '540600',
    name: '那曲市',
    city_level: 2,
    lng: '92.060211',
    lat: '31.476004'
  },
  {
    code: '540602',
    name: '色尼区',
    city_level: 2,
    lng: '92.060211',
    lat: '31.476004'
  },
  {
    code: '540621',
    name: '嘉黎县',
    city_level: 3,
    lng: '93.232528',
    lat: '30.640815'
  },
  {
    code: '540622',
    name: '比如县',
    city_level: 3,
    lng: '93.679639',
    lat: '31.480250'
  },
  {
    code: '540623',
    name: '聂荣县',
    city_level: 3,
    lng: '92.303346',
    lat: '32.107772'
  },
  {
    code: '540624',
    name: '安多县',
    city_level: 3,
    lng: '91.682330',
    lat: '32.265176'
  },
  {
    code: '540625',
    name: '申扎县',
    city_level: 3,
    lng: '88.709853',
    lat: '30.930505'
  },
  {
    code: '540626',
    name: '索县',
    city_level: 3,
    lng: '93.785631',
    lat: '31.886918'
  },
  {
    code: '540627',
    name: '班戈县',
    city_level: 3,
    lng: '90.009957',
    lat: '31.392411'
  },
  {
    code: '540628',
    name: '巴青县',
    city_level: 3,
    lng: '94.053463',
    lat: '31.918563'
  },
  {
    code: '540629',
    name: '尼玛县',
    city_level: 3,
    lng: '87.236772',
    lat: '31.784701'
  },
  {
    code: '540630',
    name: '双湖县',
    city_level: 3,
    lng: '88.837642',
    lat: '33.188515'
  },
  {
    code: '542500',
    name: '阿里地区',
    city_level: 2,
    lng: '80.105804',
    lat: '32.501111'
  },
  {
    code: '542521',
    name: '普兰县',
    city_level: 3,
    lng: '81.176237',
    lat: '30.294402'
  },
  {
    code: '542522',
    name: '札达县',
    city_level: 3,
    lng: '79.802706',
    lat: '31.479217'
  },
  {
    code: '542523',
    name: '噶尔县',
    city_level: 3,
    lng: '80.096419',
    lat: '32.491488'
  },
  {
    code: '542524',
    name: '日土县',
    city_level: 3,
    lng: '79.732427',
    lat: '33.381359'
  },
  {
    code: '542525',
    name: '革吉县',
    city_level: 3,
    lng: '81.145433',
    lat: '32.387233'
  },
  {
    code: '542526',
    name: '改则县',
    city_level: 3,
    lng: '84.062590',
    lat: '32.302713'
  },
  {
    code: '542527',
    name: '措勤县',
    city_level: 3,
    lng: '85.159494',
    lat: '31.016769'
  },
  {
    code: '610000',
    name: '陕西省',
    city_level: 1,
    lng: '108.954239',
    lat: '34.265472'
  },
  {
    code: '610100',
    name: '西安市',
    city_level: 2,
    lng: '108.940174',
    lat: '34.341568'
  },
  {
    code: '610102',
    name: '新城区',
    city_level: 3,
    lng: '108.960747',
    lat: '34.266451'
  },
  {
    code: '610103',
    name: '碑林区',
    city_level: 3,
    lng: '108.934235',
    lat: '34.230769'
  },
  {
    code: '610104',
    name: '莲湖区',
    city_level: 3,
    lng: '108.944041',
    lat: '34.264995'
  },
  {
    code: '610111',
    name: '灞桥区',
    city_level: 3,
    lng: '109.064671',
    lat: '34.273409'
  },
  {
    code: '610112',
    name: '未央区',
    city_level: 3,
    lng: '108.946850',
    lat: '34.292873'
  },
  {
    code: '610113',
    name: '雁塔区',
    city_level: 3,
    lng: '108.926593',
    lat: '34.213389'
  },
  {
    code: '610114',
    name: '阎良区',
    city_level: 3,
    lng: '109.226102',
    lat: '34.662234'
  },
  {
    code: '610115',
    name: '临潼区',
    city_level: 3,
    lng: '109.214238',
    lat: '34.367275'
  },
  {
    code: '610116',
    name: '长安区',
    city_level: 3,
    lng: '108.906917',
    lat: '34.159016'
  },
  {
    code: '610117',
    name: '高陵区',
    city_level: 3,
    lng: '109.088297',
    lat: '34.534830'
  },
  {
    code: '610118',
    name: '鄠邑区',
    city_level: 3,
    lng: '108.605011',
    lat: '34.108626'
  },
  {
    code: '610122',
    name: '蓝田县',
    city_level: 3,
    lng: '109.323479',
    lat: '34.151624'
  },
  {
    code: '610124',
    name: '周至县',
    city_level: 3,
    lng: '108.222154',
    lat: '34.163621'
  },
  {
    code: '610200',
    name: '铜川市',
    city_level: 2,
    lng: '108.945233',
    lat: '34.896756'
  },
  {
    code: '610202',
    name: '王益区',
    city_level: 3,
    lng: '109.075578',
    lat: '35.068964'
  },
  {
    code: '610203',
    name: '印台区',
    city_level: 3,
    lng: '109.099975',
    lat: '35.114492'
  },
  {
    code: '610204',
    name: '耀州区',
    city_level: 3,
    lng: '108.980514',
    lat: '34.908916'
  },
  {
    code: '610222',
    name: '宜君县',
    city_level: 3,
    lng: '109.116932',
    lat: '35.398577'
  },
  {
    code: '610300',
    name: '宝鸡市',
    city_level: 2,
    lng: '107.237974',
    lat: '34.361979'
  },
  {
    code: '610302',
    name: '渭滨区',
    city_level: 3,
    lng: '107.149968',
    lat: '34.371184'
  },
  {
    code: '610303',
    name: '金台区',
    city_level: 3,
    lng: '107.146806',
    lat: '34.376069'
  },
  {
    code: '610304',
    name: '陈仓区',
    city_level: 3,
    lng: '107.363567',
    lat: '34.354701'
  },
  {
    code: '610322',
    name: '凤翔县',
    city_level: 3,
    lng: '107.400737',
    lat: '34.521218'
  },
  {
    code: '610323',
    name: '岐山县',
    city_level: 3,
    lng: '107.621054',
    lat: '34.443459'
  },
  {
    code: '610324',
    name: '扶风县',
    city_level: 3,
    lng: '107.900219',
    lat: '34.375411'
  },
  {
    code: '610326',
    name: '眉县',
    city_level: 3,
    lng: '107.749767',
    lat: '34.274247'
  },
  {
    code: '610327',
    name: '陇县',
    city_level: 3,
    lng: '106.864397',
    lat: '34.893050'
  },
  {
    code: '610328',
    name: '千阳县',
    city_level: 3,
    lng: '107.132442',
    lat: '34.642381'
  },
  {
    code: '610329',
    name: '麟游县',
    city_level: 3,
    lng: '107.793525',
    lat: '34.677902'
  },
  {
    code: '610330',
    name: '凤县',
    city_level: 3,
    lng: '106.515756',
    lat: '33.908469'
  },
  {
    code: '610331',
    name: '太白县',
    city_level: 3,
    lng: '107.319116',
    lat: '34.058401'
  },
  {
    code: '610400',
    name: '咸阳市',
    city_level: 2,
    lng: '108.708991',
    lat: '34.329605'
  },
  {
    code: '610402',
    name: '秦都区',
    city_level: 3,
    lng: '108.706272',
    lat: '34.329567'
  },
  {
    code: '610403',
    name: '杨陵区',
    city_level: 3,
    lng: '108.084732',
    lat: '34.272117'
  },
  {
    code: '610404',
    name: '渭城区',
    city_level: 3,
    lng: '108.737213',
    lat: '34.361988'
  },
  {
    code: '610422',
    name: '三原县',
    city_level: 3,
    lng: '108.940509',
    lat: '34.617382'
  },
  {
    code: '610423',
    name: '泾阳县',
    city_level: 3,
    lng: '108.842623',
    lat: '34.527114'
  },
  {
    code: '610424',
    name: '乾县',
    city_level: 3,
    lng: '108.239473',
    lat: '34.527551'
  },
  {
    code: '610425',
    name: '礼泉县',
    city_level: 3,
    lng: '108.425018',
    lat: '34.481764'
  },
  {
    code: '610426',
    name: '永寿县',
    city_level: 3,
    lng: '108.142311',
    lat: '34.691979'
  },
  {
    code: '610428',
    name: '长武县',
    city_level: 3,
    lng: '107.798757',
    lat: '35.205886'
  },
  {
    code: '610429',
    name: '旬邑县',
    city_level: 3,
    lng: '108.333986',
    lat: '35.111978'
  },
  {
    code: '610430',
    name: '淳化县',
    city_level: 3,
    lng: '108.580681',
    lat: '34.799250'
  },
  {
    code: '610431',
    name: '武功县',
    city_level: 3,
    lng: '108.200398',
    lat: '34.260204'
  },
  {
    code: '610481',
    name: '兴平市',
    city_level: 3,
    lng: '108.490475',
    lat: '34.299221'
  },
  {
    code: '610482',
    name: '彬州市',
    city_level: 2,
    lng: '108.708991',
    lat: '34.329605'
  },
  {
    code: '610500',
    name: '渭南市',
    city_level: 2,
    lng: '109.509786',
    lat: '34.499995'
  },
  {
    code: '610502',
    name: '临渭区',
    city_level: 3,
    lng: '109.492726',
    lat: '34.498192'
  },
  {
    code: '610503',
    name: '华州区',
    city_level: 3,
    lng: '109.761417',
    lat: '34.511949'
  },
  {
    code: '610522',
    name: '潼关县',
    city_level: 3,
    lng: '110.246350',
    lat: '34.544296'
  },
  {
    code: '610523',
    name: '大荔县',
    city_level: 3,
    lng: '109.941658',
    lat: '34.797184'
  },
  {
    code: '610524',
    name: '合阳县',
    city_level: 3,
    lng: '110.149466',
    lat: '35.237986'
  },
  {
    code: '610525',
    name: '澄城县',
    city_level: 3,
    lng: '109.932350',
    lat: '35.190245'
  },
  {
    code: '610526',
    name: '蒲城县',
    city_level: 3,
    lng: '109.586506',
    lat: '34.955855'
  },
  {
    code: '610527',
    name: '白水县',
    city_level: 3,
    lng: '109.590671',
    lat: '35.177452'
  },
  {
    code: '610528',
    name: '富平县',
    city_level: 3,
    lng: '109.180331',
    lat: '34.751086'
  },
  {
    code: '610581',
    name: '韩城市',
    city_level: 3,
    lng: '110.442847',
    lat: '35.476788'
  },
  {
    code: '610582',
    name: '华阴市',
    city_level: 3,
    lng: '110.092301',
    lat: '34.566096'
  },
  {
    code: '610600',
    name: '延安市',
    city_level: 2,
    lng: '109.489727',
    lat: '36.585455'
  },
  {
    code: '610602',
    name: '宝塔区',
    city_level: 3,
    lng: '109.493106',
    lat: '36.591266'
  },
  {
    code: '610603',
    name: '安塞区',
    city_level: 3,
    lng: '109.325340',
    lat: '36.864410'
  },
  {
    code: '610621',
    name: '延长县',
    city_level: 3,
    lng: '110.012334',
    lat: '36.579313'
  },
  {
    code: '610622',
    name: '延川县',
    city_level: 3,
    lng: '110.193514',
    lat: '36.878117'
  },
  {
    code: '610625',
    name: '志丹县',
    city_level: 3,
    lng: '108.768432',
    lat: '36.822194'
  },
  {
    code: '610626',
    name: '吴起县',
    city_level: 3,
    lng: '108.175933',
    lat: '36.927216'
  },
  {
    code: '610627',
    name: '甘泉县',
    city_level: 3,
    lng: '109.351020',
    lat: '36.276526'
  },
  {
    code: '610628',
    name: '富县',
    city_level: 3,
    lng: '109.379711',
    lat: '35.988010'
  },
  {
    code: '610629',
    name: '洛川县',
    city_level: 3,
    lng: '109.432369',
    lat: '35.761975'
  },
  {
    code: '610630',
    name: '宜川县',
    city_level: 3,
    lng: '110.168963',
    lat: '36.050178'
  },
  {
    code: '610631',
    name: '黄龙县',
    city_level: 3,
    lng: '109.840373',
    lat: '35.584467'
  },
  {
    code: '610632',
    name: '黄陵县',
    city_level: 3,
    lng: '109.262961',
    lat: '35.579428'
  },
  {
    code: '610681',
    name: '子长市',
    city_level: 2,
    lng: '109.489727',
    lat: '36.585455'
  },
  {
    code: '610700',
    name: '汉中市',
    city_level: 2,
    lng: '107.023323',
    lat: '33.067480'
  },
  {
    code: '610702',
    name: '汉台区',
    city_level: 3,
    lng: '107.031856',
    lat: '33.067771'
  },
  {
    code: '610703',
    name: '南郑区',
    city_level: 2,
    lng: '107.023323',
    lat: '33.067480'
  },
  {
    code: '610722',
    name: '城固县',
    city_level: 3,
    lng: '107.333930',
    lat: '33.157131'
  },
  {
    code: '610723',
    name: '洋县',
    city_level: 3,
    lng: '107.545837',
    lat: '33.222739'
  },
  {
    code: '610724',
    name: '西乡县',
    city_level: 3,
    lng: '107.766614',
    lat: '32.983101'
  },
  {
    code: '610725',
    name: '勉县',
    city_level: 3,
    lng: '106.673221',
    lat: '33.153553'
  },
  {
    code: '610726',
    name: '宁强县',
    city_level: 3,
    lng: '106.257171',
    lat: '32.829694'
  },
  {
    code: '610727',
    name: '略阳县',
    city_level: 3,
    lng: '106.156718',
    lat: '33.327281'
  },
  {
    code: '610728',
    name: '镇巴县',
    city_level: 3,
    lng: '107.895035',
    lat: '32.536704'
  },
  {
    code: '610729',
    name: '留坝县',
    city_level: 3,
    lng: '106.920808',
    lat: '33.617571'
  },
  {
    code: '610730',
    name: '佛坪县',
    city_level: 3,
    lng: '107.990539',
    lat: '33.524359'
  },
  {
    code: '610800',
    name: '榆林市',
    city_level: 2,
    lng: '109.734589',
    lat: '38.285390'
  },
  {
    code: '610802',
    name: '榆阳区',
    city_level: 3,
    lng: '109.720309',
    lat: '38.277029'
  },
  {
    code: '610803',
    name: '横山区',
    city_level: 3,
    lng: '109.294346',
    lat: '37.962209'
  },
  {
    code: '610822',
    name: '府谷县',
    city_level: 3,
    lng: '111.067366',
    lat: '39.028116'
  },
  {
    code: '610824',
    name: '靖边县',
    city_level: 3,
    lng: '108.793988',
    lat: '37.599438'
  },
  {
    code: '610825',
    name: '定边县',
    city_level: 3,
    lng: '107.601267',
    lat: '37.594612'
  },
  {
    code: '610826',
    name: '绥德县',
    city_level: 3,
    lng: '110.263362',
    lat: '37.502940'
  },
  {
    code: '610827',
    name: '米脂县',
    city_level: 3,
    lng: '110.183754',
    lat: '37.755417'
  },
  {
    code: '610828',
    name: '佳县',
    city_level: 3,
    lng: '110.491345',
    lat: '38.019511'
  },
  {
    code: '610829',
    name: '吴堡县',
    city_level: 3,
    lng: '110.739673',
    lat: '37.452068'
  },
  {
    code: '610830',
    name: '清涧县',
    city_level: 3,
    lng: '110.121209',
    lat: '37.088878'
  },
  {
    code: '610831',
    name: '子洲县',
    city_level: 3,
    lng: '110.035250',
    lat: '37.610683'
  },
  {
    code: '610881',
    name: '神木市',
    city_level: 3,
    lng: '110.497002',
    lat: '38.835640'
  },
  {
    code: '610900',
    name: '安康市',
    city_level: 2,
    lng: '109.029022',
    lat: '32.684714'
  },
  {
    code: '610902',
    name: '汉滨区',
    city_level: 3,
    lng: '109.026836',
    lat: '32.695173'
  },
  {
    code: '610921',
    name: '汉阴县',
    city_level: 3,
    lng: '108.508745',
    lat: '32.893026'
  },
  {
    code: '610922',
    name: '石泉县',
    city_level: 3,
    lng: '108.247887',
    lat: '33.038408'
  },
  {
    code: '610923',
    name: '宁陕县',
    city_level: 3,
    lng: '108.314283',
    lat: '33.310527'
  },
  {
    code: '610924',
    name: '紫阳县',
    city_level: 3,
    lng: '108.534229',
    lat: '32.520246'
  },
  {
    code: '610925',
    name: '岚皋县',
    city_level: 3,
    lng: '108.902049',
    lat: '32.307001'
  },
  {
    code: '610926',
    name: '平利县',
    city_level: 3,
    lng: '109.361864',
    lat: '32.388854'
  },
  {
    code: '610927',
    name: '镇坪县',
    city_level: 3,
    lng: '109.526873',
    lat: '31.883672'
  },
  {
    code: '610928',
    name: '旬阳县',
    city_level: 3,
    lng: '109.365265',
    lat: '32.834086'
  },
  {
    code: '610929',
    name: '白河县',
    city_level: 3,
    lng: '110.112629',
    lat: '32.809026'
  },
  {
    code: '611000',
    name: '商洛市',
    city_level: 2,
    lng: '109.940477',
    lat: '33.870422'
  },
  {
    code: '611002',
    name: '商州区',
    city_level: 3,
    lng: '109.941241',
    lat: '33.862703'
  },
  {
    code: '611021',
    name: '洛南县',
    city_level: 3,
    lng: '110.148509',
    lat: '34.090838'
  },
  {
    code: '611022',
    name: '丹凤县',
    city_level: 3,
    lng: '110.331676',
    lat: '33.698185'
  },
  {
    code: '611023',
    name: '商南县',
    city_level: 3,
    lng: '110.881807',
    lat: '33.530995'
  },
  {
    code: '611024',
    name: '山阳县',
    city_level: 3,
    lng: '109.882290',
    lat: '33.532172'
  },
  {
    code: '611025',
    name: '镇安县',
    city_level: 3,
    lng: '109.152893',
    lat: '33.423357'
  },
  {
    code: '611026',
    name: '柞水县',
    city_level: 3,
    lng: '109.114207',
    lat: '33.686110'
  },
  {
    code: '620000',
    name: '甘肃省',
    city_level: 1,
    lng: '103.826308',
    lat: '36.059421'
  },
  {
    code: '620100',
    name: '兰州市',
    city_level: 2,
    lng: '103.834303',
    lat: '36.061089'
  },
  {
    code: '620102',
    name: '城关区',
    city_level: 3,
    lng: '103.825255',
    lat: '36.057054'
  },
  {
    code: '620103',
    name: '七里河区',
    city_level: 3,
    lng: '103.785866',
    lat: '36.065915'
  },
  {
    code: '620104',
    name: '西固区',
    city_level: 3,
    lng: '103.627964',
    lat: '36.088431'
  },
  {
    code: '620105',
    name: '安宁区',
    city_level: 3,
    lng: '103.719090',
    lat: '36.103927'
  },
  {
    code: '620111',
    name: '红古区',
    city_level: 3,
    lng: '102.859323',
    lat: '36.345669'
  },
  {
    code: '620121',
    name: '永登县',
    city_level: 3,
    lng: '103.260380',
    lat: '36.736513'
  },
  {
    code: '620122',
    name: '皋兰县',
    city_level: 3,
    lng: '103.947377',
    lat: '36.332663'
  },
  {
    code: '620123',
    name: '榆中县',
    city_level: 3,
    lng: '104.112527',
    lat: '35.843056'
  },
  {
    code: '620200',
    name: '嘉峪关市',
    city_level: 2,
    lng: '98.289152',
    lat: '39.773130'
  },
  {
    code: '620300',
    name: '金昌市',
    city_level: 2,
    lng: '102.188043',
    lat: '38.520089'
  },
  {
    code: '620302',
    name: '金川区',
    city_level: 3,
    lng: '102.194089',
    lat: '38.521085'
  },
  {
    code: '620321',
    name: '永昌县',
    city_level: 3,
    lng: '101.984649',
    lat: '38.243170'
  },
  {
    code: '620400',
    name: '白银市',
    city_level: 2,
    lng: '104.138559',
    lat: '36.544756'
  },
  {
    code: '620402',
    name: '白银区',
    city_level: 3,
    lng: '104.148556',
    lat: '36.535398'
  },
  {
    code: '620403',
    name: '平川区',
    city_level: 3,
    lng: '104.825208',
    lat: '36.728304'
  },
  {
    code: '620421',
    name: '靖远县',
    city_level: 3,
    lng: '104.676774',
    lat: '36.571366'
  },
  {
    code: '620422',
    name: '会宁县',
    city_level: 3,
    lng: '105.053358',
    lat: '35.692823'
  },
  {
    code: '620423',
    name: '景泰县',
    city_level: 3,
    lng: '104.063091',
    lat: '37.183804'
  },
  {
    code: '620500',
    name: '天水市',
    city_level: 2,
    lng: '105.724947',
    lat: '34.580863'
  },
  {
    code: '620502',
    name: '秦州区',
    city_level: 3,
    lng: '105.724199',
    lat: '34.580892'
  },
  {
    code: '620503',
    name: '麦积区',
    city_level: 3,
    lng: '105.889557',
    lat: '34.570384'
  },
  {
    code: '620521',
    name: '清水县',
    city_level: 3,
    lng: '106.137293',
    lat: '34.749865'
  },
  {
    code: '620522',
    name: '秦安县',
    city_level: 3,
    lng: '105.674983',
    lat: '34.858916'
  },
  {
    code: '620523',
    name: '甘谷县',
    city_level: 3,
    lng: '105.340864',
    lat: '34.745465'
  },
  {
    code: '620524',
    name: '武山县',
    city_level: 3,
    lng: '104.890782',
    lat: '34.721380'
  },
  {
    code: '620525',
    name: '张家川回族自治县',
    city_level: 3,
    lng: '106.204518',
    lat: '34.988037'
  },
  {
    code: '620600',
    name: '武威市',
    city_level: 2,
    lng: '102.638011',
    lat: '37.928264'
  },
  {
    code: '620602',
    name: '凉州区',
    city_level: 3,
    lng: '102.729890',
    lat: '37.820619'
  },
  {
    code: '620621',
    name: '民勤县',
    city_level: 3,
    lng: '103.093792',
    lat: '38.624350'
  },
  {
    code: '620622',
    name: '古浪县',
    city_level: 3,
    lng: '102.897533',
    lat: '37.470120'
  },
  {
    code: '620623',
    name: '天祝藏族自治县',
    city_level: 3,
    lng: '103.141757',
    lat: '36.971740'
  },
  {
    code: '620700',
    name: '张掖市',
    city_level: 2,
    lng: '100.449818',
    lat: '38.925875'
  },
  {
    code: '620702',
    name: '甘州区',
    city_level: 3,
    lng: '100.478058',
    lat: '38.929763'
  },
  {
    code: '620721',
    name: '肃南裕固族自治县',
    city_level: 3,
    lng: '99.615601',
    lat: '38.836932'
  },
  {
    code: '620722',
    name: '民乐县',
    city_level: 3,
    lng: '100.812860',
    lat: '38.430794'
  },
  {
    code: '620723',
    name: '临泽县',
    city_level: 3,
    lng: '100.164445',
    lat: '39.152642'
  },
  {
    code: '620724',
    name: '高台县',
    city_level: 3,
    lng: '99.819317',
    lat: '39.377733'
  },
  {
    code: '620725',
    name: '山丹县',
    city_level: 3,
    lng: '101.088575',
    lat: '38.784758'
  },
  {
    code: '620800',
    name: '平凉市',
    city_level: 2,
    lng: '106.665240',
    lat: '35.543051'
  },
  {
    code: '620802',
    name: '崆峒区',
    city_level: 3,
    lng: '106.674767',
    lat: '35.542491'
  },
  {
    code: '620821',
    name: '泾川县',
    city_level: 3,
    lng: '107.367850',
    lat: '35.332666'
  },
  {
    code: '620822',
    name: '灵台县',
    city_level: 3,
    lng: '107.621124',
    lat: '35.065399'
  },
  {
    code: '620823',
    name: '崇信县',
    city_level: 3,
    lng: '107.035409',
    lat: '35.302123'
  },
  {
    code: '620825',
    name: '庄浪县',
    city_level: 3,
    lng: '106.036687',
    lat: '35.202385'
  },
  {
    code: '620826',
    name: '静宁县',
    city_level: 3,
    lng: '105.732556',
    lat: '35.521977'
  },
  {
    code: '620881',
    name: '华亭市',
    city_level: 3,
    lng: '106.662460',
    lat: '35.219951'
  },
  {
    code: '620900',
    name: '酒泉市',
    city_level: 2,
    lng: '98.494483',
    lat: '39.732410'
  },
  {
    code: '620902',
    name: '肃州区',
    city_level: 3,
    lng: '98.507850',
    lat: '39.745071'
  },
  {
    code: '620921',
    name: '金塔县',
    city_level: 3,
    lng: '98.903270',
    lat: '39.983599'
  },
  {
    code: '620922',
    name: '瓜州县',
    city_level: 3,
    lng: '95.782306',
    lat: '40.520545'
  },
  {
    code: '620923',
    name: '肃北蒙古族自治县',
    city_level: 3,
    lng: '94.876579',
    lat: '39.512450'
  },
  {
    code: '620924',
    name: '阿克塞哈萨克族自治县',
    city_level: 3,
    lng: '94.340204',
    lat: '39.633943'
  },
  {
    code: '620981',
    name: '玉门市',
    city_level: 2,
    lng: '98.494483',
    lat: '39.732410'
  },
  {
    code: '620982',
    name: '敦煌市',
    city_level: 3,
    lng: '94.661967',
    lat: '40.142128'
  },
  {
    code: '621000',
    name: '庆阳市',
    city_level: 2,
    lng: '107.643631',
    lat: '35.709077'
  },
  {
    code: '621002',
    name: '西峰区',
    city_level: 3,
    lng: '107.651077',
    lat: '35.730652'
  },
  {
    code: '621021',
    name: '庆城县',
    city_level: 3,
    lng: '107.881802',
    lat: '36.016299'
  },
  {
    code: '621022',
    name: '环县',
    city_level: 3,
    lng: '107.308501',
    lat: '36.568435'
  },
  {
    code: '621023',
    name: '华池县',
    city_level: 3,
    lng: '107.990035',
    lat: '36.461355'
  },
  {
    code: '621024',
    name: '合水县',
    city_level: 3,
    lng: '108.019530',
    lat: '35.819243'
  },
  {
    code: '621025',
    name: '正宁县',
    city_level: 3,
    lng: '108.359976',
    lat: '35.491890'
  },
  {
    code: '621026',
    name: '宁县',
    city_level: 3,
    lng: '107.928369',
    lat: '35.502177'
  },
  {
    code: '621027',
    name: '镇原县',
    city_level: 3,
    lng: '107.200832',
    lat: '35.677462'
  },
  {
    code: '621100',
    name: '定西市',
    city_level: 2,
    lng: '104.626282',
    lat: '35.580662'
  },
  {
    code: '621102',
    name: '安定区',
    city_level: 3,
    lng: '104.610668',
    lat: '35.580629'
  },
  {
    code: '621121',
    name: '通渭县',
    city_level: 3,
    lng: '105.242061',
    lat: '35.210831'
  },
  {
    code: '621122',
    name: '陇西县',
    city_level: 3,
    lng: '104.634984',
    lat: '35.003940'
  },
  {
    code: '621123',
    name: '渭源县',
    city_level: 3,
    lng: '104.215467',
    lat: '35.136755'
  },
  {
    code: '621124',
    name: '临洮县',
    city_level: 3,
    lng: '103.859565',
    lat: '35.394989'
  },
  {
    code: '621125',
    name: '漳县',
    city_level: 3,
    lng: '104.471572',
    lat: '34.848444'
  },
  {
    code: '621126',
    name: '岷县',
    city_level: 3,
    lng: '104.036880',
    lat: '34.438076'
  },
  {
    code: '621200',
    name: '陇南市',
    city_level: 2,
    lng: '104.921841',
    lat: '33.400684'
  },
  {
    code: '621202',
    name: '武都区',
    city_level: 3,
    lng: '104.926337',
    lat: '33.392211'
  },
  {
    code: '621221',
    name: '成县',
    city_level: 3,
    lng: '105.742203',
    lat: '33.750477'
  },
  {
    code: '621222',
    name: '文县',
    city_level: 3,
    lng: '104.683434',
    lat: '32.943815'
  },
  {
    code: '621223',
    name: '宕昌县',
    city_level: 3,
    lng: '104.393385',
    lat: '34.047261'
  },
  {
    code: '621224',
    name: '康县',
    city_level: 3,
    lng: '105.609169',
    lat: '33.329136'
  },
  {
    code: '621225',
    name: '西和县',
    city_level: 3,
    lng: '105.298756',
    lat: '34.014215'
  },
  {
    code: '621226',
    name: '礼县',
    city_level: 3,
    lng: '105.178640',
    lat: '34.189345'
  },
  {
    code: '621227',
    name: '徽县',
    city_level: 3,
    lng: '106.087780',
    lat: '33.768826'
  },
  {
    code: '621228',
    name: '两当县',
    city_level: 3,
    lng: '106.304967',
    lat: '33.908917'
  },
  {
    code: '622900',
    name: '临夏回族自治州',
    city_level: 2,
    lng: '103.210538',
    lat: '35.601182'
  },
  {
    code: '622901',
    name: '临夏市',
    city_level: 2,
    lng: '103.210538',
    lat: '35.601182'
  },
  {
    code: '622921',
    name: '临夏县',
    city_level: 3,
    lng: '102.995502',
    lat: '35.491637'
  },
  {
    code: '622922',
    name: '康乐县',
    city_level: 3,
    lng: '103.708354',
    lat: '35.370505'
  },
  {
    code: '622923',
    name: '永靖县',
    city_level: 3,
    lng: '103.285854',
    lat: '35.958306'
  },
  {
    code: '622924',
    name: '广河县',
    city_level: 3,
    lng: '103.575834',
    lat: '35.488052'
  },
  {
    code: '622925',
    name: '和政县',
    city_level: 3,
    lng: '103.350997',
    lat: '35.424603'
  },
  {
    code: '622926',
    name: '东乡族自治县',
    city_level: 3,
    lng: '103.389346',
    lat: '35.663752'
  },
  {
    code: '622927',
    name: '积石山保安族东乡族撒拉族自治县',
    city_level: 3,
    lng: '102.875843',
    lat: '35.717661'
  },
  {
    code: '623000',
    name: '甘南藏族自治州',
    city_level: 2,
    lng: '102.911027',
    lat: '34.983385'
  },
  {
    code: '623001',
    name: '合作市',
    city_level: 3,
    lng: '102.910882',
    lat: '35.000399'
  },
  {
    code: '623021',
    name: '临潭县',
    city_level: 3,
    lng: '103.353919',
    lat: '34.692747'
  },
  {
    code: '623022',
    name: '卓尼县',
    city_level: 3,
    lng: '103.507109',
    lat: '34.589588'
  },
  {
    code: '623023',
    name: '舟曲县',
    city_level: 3,
    lng: '104.371586',
    lat: '33.785259'
  },
  {
    code: '623024',
    name: '迭部县',
    city_level: 3,
    lng: '103.221870',
    lat: '34.055939'
  },
  {
    code: '623025',
    name: '玛曲县',
    city_level: 3,
    lng: '102.072698',
    lat: '33.997712'
  },
  {
    code: '623026',
    name: '碌曲县',
    city_level: 3,
    lng: '102.487327',
    lat: '34.590944'
  },
  {
    code: '623027',
    name: '夏河县',
    city_level: 3,
    lng: '102.521807',
    lat: '35.202503'
  },
  {
    code: '630000',
    name: '青海省',
    city_level: 1,
    lng: '101.780199',
    lat: '36.620901'
  },
  {
    code: '630100',
    name: '西宁市',
    city_level: 2,
    lng: '101.778228',
    lat: '36.617144'
  },
  {
    code: '630102',
    name: '城东区',
    city_level: 3,
    lng: '101.803717',
    lat: '36.599745'
  },
  {
    code: '630103',
    name: '城中区',
    city_level: 3,
    lng: '101.784554',
    lat: '36.621181'
  },
  {
    code: '630104',
    name: '城西区',
    city_level: 3,
    lng: '101.765843',
    lat: '36.628305'
  },
  {
    code: '630105',
    name: '城北区',
    city_level: 3,
    lng: '101.766228',
    lat: '36.650038'
  },
  {
    code: '630106',
    name: '湟中区',
    city_level: 2,
    lng: '101.778228',
    lat: '36.617144'
  },
  {
    code: '630121',
    name: '大通回族土族自治县',
    city_level: 3,
    lng: '101.685643',
    lat: '36.926955'
  },
  {
    code: '630123',
    name: '湟源县',
    city_level: 3,
    lng: '101.256464',
    lat: '36.682427'
  },
  {
    code: '630200',
    name: '海东市',
    city_level: 2,
    lng: '102.104287',
    lat: '36.502039'
  },
  {
    code: '630202',
    name: '乐都区',
    city_level: 3,
    lng: '102.401725',
    lat: '36.482058'
  },
  {
    code: '630203',
    name: '平安区',
    city_level: 3,
    lng: '102.108835',
    lat: '36.500563'
  },
  {
    code: '630222',
    name: '民和回族土族自治县',
    city_level: 3,
    lng: '102.830892',
    lat: '36.320321'
  },
  {
    code: '630223',
    name: '互助土族自治县',
    city_level: 3,
    lng: '101.959271',
    lat: '36.844249'
  },
  {
    code: '630224',
    name: '化隆回族自治县',
    city_level: 3,
    lng: '102.264143',
    lat: '36.094908'
  },
  {
    code: '630225',
    name: '循化撒拉族自治县',
    city_level: 3,
    lng: '102.485646',
    lat: '35.848586'
  },
  {
    code: '632200',
    name: '海北藏族自治州',
    city_level: 2,
    lng: '100.900997',
    lat: '36.954413'
  },
  {
    code: '632221',
    name: '门源回族自治县',
    city_level: 3,
    lng: '101.622364',
    lat: '37.376449'
  },
  {
    code: '632222',
    name: '祁连县',
    city_level: 3,
    lng: '100.253211',
    lat: '38.177112'
  },
  {
    code: '632223',
    name: '海晏县',
    city_level: 3,
    lng: '100.994430',
    lat: '36.896467'
  },
  {
    code: '632224',
    name: '刚察县',
    city_level: 3,
    lng: '100.145833',
    lat: '37.325470'
  },
  {
    code: '632300',
    name: '黄南藏族自治州',
    city_level: 2,
    lng: '102.015248',
    lat: '35.519548'
  },
  {
    code: '632301',
    name: '同仁市',
    city_level: 3,
    lng: '102.018323',
    lat: '35.516063'
  },
  {
    code: '632322',
    name: '尖扎县',
    city_level: 3,
    lng: '102.031183',
    lat: '35.938299'
  },
  {
    code: '632323',
    name: '泽库县',
    city_level: 3,
    lng: '101.466689',
    lat: '35.035313'
  },
  {
    code: '632324',
    name: '河南蒙古族自治县',
    city_level: 3,
    lng: '101.616308',
    lat: '34.734773'
  },
  {
    code: '632500',
    name: '海南藏族自治州',
    city_level: 2,
    lng: '100.620373',
    lat: '36.286437'
  },
  {
    code: '632521',
    name: '共和县',
    city_level: 3,
    lng: '100.620031',
    lat: '36.284107'
  },
  {
    code: '632522',
    name: '同德县',
    city_level: 3,
    lng: '100.578052',
    lat: '35.254791'
  },
  {
    code: '632523',
    name: '贵德县',
    city_level: 3,
    lng: '101.435256',
    lat: '36.040680'
  },
  {
    code: '632524',
    name: '兴海县',
    city_level: 3,
    lng: '99.987966',
    lat: '35.588613'
  },
  {
    code: '632525',
    name: '贵南县',
    city_level: 3,
    lng: '100.747503',
    lat: '35.586715'
  },
  {
    code: '632600',
    name: '果洛藏族自治州',
    city_level: 2,
    lng: '100.244808',
    lat: '34.471431'
  },
  {
    code: '632621',
    name: '玛沁县',
    city_level: 3,
    lng: '100.238888',
    lat: '34.477433'
  },
  {
    code: '632622',
    name: '班玛县',
    city_level: 3,
    lng: '100.737138',
    lat: '32.932723'
  },
  {
    code: '632623',
    name: '甘德县',
    city_level: 3,
    lng: '99.900905',
    lat: '33.969219'
  },
  {
    code: '632624',
    name: '达日县',
    city_level: 3,
    lng: '99.651392',
    lat: '33.748921'
  },
  {
    code: '632625',
    name: '久治县',
    city_level: 3,
    lng: '101.482831',
    lat: '33.429471'
  },
  {
    code: '632626',
    name: '玛多县',
    city_level: 3,
    lng: '98.209206',
    lat: '34.915946'
  },
  {
    code: '632700',
    name: '玉树藏族自治州',
    city_level: 2,
    lng: '97.091934',
    lat: '33.011674'
  },
  {
    code: '632701',
    name: '玉树市',
    city_level: 3,
    lng: '97.008785',
    lat: '32.993107'
  },
  {
    code: '632722',
    name: '杂多县',
    city_level: 3,
    lng: '95.300723',
    lat: '32.893185'
  },
  {
    code: '632723',
    name: '称多县',
    city_level: 3,
    lng: '97.110832',
    lat: '33.369218'
  },
  {
    code: '632724',
    name: '治多县',
    city_level: 3,
    lng: '95.613080',
    lat: '33.852751'
  },
  {
    code: '632725',
    name: '囊谦县',
    city_level: 3,
    lng: '96.480650',
    lat: '32.203246'
  },
  {
    code: '632726',
    name: '曲麻莱县',
    city_level: 3,
    lng: '95.797367',
    lat: '34.126429'
  },
  {
    code: '632800',
    name: '海西蒙古族藏族自治州',
    city_level: 2,
    lng: '97.369751',
    lat: '37.377139'
  },
  {
    code: '632801',
    name: '格尔木市',
    city_level: 3,
    lng: '94.928484',
    lat: '36.406404'
  },
  {
    code: '632802',
    name: '德令哈市',
    city_level: 3,
    lng: '97.360985',
    lat: '37.369436'
  },
  {
    code: '632803',
    name: '茫崖市',
    city_level: 2,
    lng: '97.369751',
    lat: '37.377139'
  },
  {
    code: '632821',
    name: '乌兰县',
    city_level: 3,
    lng: '98.480195',
    lat: '36.929749'
  },
  {
    code: '632822',
    name: '都兰县',
    city_level: 3,
    lng: '98.095844',
    lat: '36.302496'
  },
  {
    code: '632823',
    name: '天峻县',
    city_level: 3,
    lng: '99.022984',
    lat: '37.300851'
  },
  {
    code: '640000',
    name: '宁夏回族自治区',
    city_level: 1,
    lng: '106.258754',
    lat: '38.471317'
  },
  {
    code: '640100',
    name: '银川市',
    city_level: 2,
    lng: '106.230909',
    lat: '38.487193'
  },
  {
    code: '640104',
    name: '兴庆区',
    city_level: 3,
    lng: '106.288650',
    lat: '38.473610'
  },
  {
    code: '640105',
    name: '西夏区',
    city_level: 3,
    lng: '106.156394',
    lat: '38.496040'
  },
  {
    code: '640106',
    name: '金凤区',
    city_level: 3,
    lng: '106.242542',
    lat: '38.473305'
  },
  {
    code: '640121',
    name: '永宁县',
    city_level: 3,
    lng: '106.253145',
    lat: '38.277373'
  },
  {
    code: '640122',
    name: '贺兰县',
    city_level: 3,
    lng: '106.349828',
    lat: '38.554344'
  },
  {
    code: '640181',
    name: '灵武市',
    city_level: 3,
    lng: '106.340054',
    lat: '38.102655'
  },
  {
    code: '640200',
    name: '石嘴山市',
    city_level: 2,
    lng: '106.383303',
    lat: '38.983236'
  },
  {
    code: '640202',
    name: '大武口区',
    city_level: 3,
    lng: '106.367861',
    lat: '39.019060'
  },
  {
    code: '640205',
    name: '惠农区',
    city_level: 3,
    lng: '106.781176',
    lat: '39.239302'
  },
  {
    code: '640221',
    name: '平罗县',
    city_level: 3,
    lng: '106.523474',
    lat: '38.913544'
  },
  {
    code: '640300',
    name: '吴忠市',
    city_level: 2,
    lng: '106.198393',
    lat: '37.997460'
  },
  {
    code: '640302',
    name: '利通区',
    city_level: 3,
    lng: '106.212566',
    lat: '37.983458'
  },
  {
    code: '640303',
    name: '红寺堡区',
    city_level: 3,
    lng: '106.062114',
    lat: '37.425702'
  },
  {
    code: '640323',
    name: '盐池县',
    city_level: 3,
    lng: '107.407359',
    lat: '37.783205'
  },
  {
    code: '640324',
    name: '同心县',
    city_level: 3,
    lng: '105.914458',
    lat: '36.980575'
  },
  {
    code: '640381',
    name: '青铜峡市',
    city_level: 3,
    lng: '106.078818',
    lat: '38.021302'
  },
  {
    code: '640400',
    name: '固原市',
    city_level: 2,
    lng: '106.242610',
    lat: '36.015855'
  },
  {
    code: '640402',
    name: '原州区',
    city_level: 3,
    lng: '106.287782',
    lat: '36.003740'
  },
  {
    code: '640422',
    name: '西吉县',
    city_level: 3,
    lng: '105.729085',
    lat: '35.963913'
  },
  {
    code: '640423',
    name: '隆德县',
    city_level: 3,
    lng: '106.111595',
    lat: '35.625915'
  },
  {
    code: '640424',
    name: '泾源县',
    city_level: 3,
    lng: '106.330646',
    lat: '35.498160'
  },
  {
    code: '640425',
    name: '彭阳县',
    city_level: 3,
    lng: '106.638340',
    lat: '35.849565'
  },
  {
    code: '640500',
    name: '中卫市',
    city_level: 2,
    lng: '105.196902',
    lat: '37.499972'
  },
  {
    code: '640502',
    name: '沙坡头区',
    city_level: 3,
    lng: '105.190536',
    lat: '37.514564'
  },
  {
    code: '640521',
    name: '中宁县',
    city_level: 3,
    lng: '105.685285',
    lat: '37.491505'
  },
  {
    code: '640522',
    name: '海原县',
    city_level: 3,
    lng: '105.643487',
    lat: '36.565033'
  },
  {
    code: '650000',
    name: '新疆维吾尔自治区',
    city_level: 1,
    lng: '87.627704',
    lat: '43.793026'
  },
  {
    code: '650100',
    name: '乌鲁木齐市',
    city_level: 2,
    lng: '87.616848',
    lat: '43.825592'
  },
  {
    code: '650102',
    name: '天山区',
    city_level: 3,
    lng: '87.631676',
    lat: '43.794399'
  },
  {
    code: '650103',
    name: '沙依巴克区',
    city_level: 3,
    lng: '87.598195',
    lat: '43.800939'
  },
  {
    code: '650104',
    name: '新市区',
    city_level: 3,
    lng: '87.573916',
    lat: '43.843752'
  },
  {
    code: '650105',
    name: '水磨沟区',
    city_level: 3,
    lng: '87.642482',
    lat: '43.832459'
  },
  {
    code: '650106',
    name: '头屯河区',
    city_level: 2,
    lng: '87.616848',
    lat: '43.825592'
  },
  {
    code: '650107',
    name: '达坂城区',
    city_level: 3,
    lng: '88.311099',
    lat: '43.363668'
  },
  {
    code: '650109',
    name: '米东区',
    city_level: 3,
    lng: '87.655755',
    lat: '43.973579'
  },
  {
    code: '650121',
    name: '乌鲁木齐县',
    city_level: 3,
    lng: '87.409417',
    lat: '43.471360'
  },
  {
    code: '650200',
    name: '克拉玛依市',
    city_level: 2,
    lng: '84.889207',
    lat: '45.579888'
  },
  {
    code: '650202',
    name: '独山子区',
    city_level: 3,
    lng: '84.886974',
    lat: '44.328096'
  },
  {
    code: '650203',
    name: '克拉玛依区',
    city_level: 3,
    lng: '84.867844',
    lat: '45.602526'
  },
  {
    code: '650204',
    name: '白碱滩区',
    city_level: 3,
    lng: '85.119186',
    lat: '45.685473'
  },
  {
    code: '650205',
    name: '乌尔禾区',
    city_level: 3,
    lng: '85.693742',
    lat: '46.089148'
  },
  {
    code: '650400',
    name: '吐鲁番市',
    city_level: 2,
    lng: '89.189651',
    lat: '42.951382'
  },
  {
    code: '650402',
    name: '高昌区',
    city_level: 3,
    lng: '89.182342',
    lat: '42.947635'
  },
  {
    code: '650421',
    name: '鄯善县',
    city_level: 3,
    lng: '90.213330',
    lat: '42.868744'
  },
  {
    code: '650422',
    name: '托克逊县',
    city_level: 3,
    lng: '88.638816',
    lat: '42.792454'
  },
  {
    code: '650500',
    name: '哈密市',
    city_level: 2,
    lng: '93.514916',
    lat: '42.818501'
  },
  {
    code: '650502',
    name: '伊州区',
    city_level: 3,
    lng: '93.514797',
    lat: '42.827255'
  },
  {
    code: '650521',
    name: '巴里坤哈萨克自治县',
    city_level: 3,
    lng: '93.016624',
    lat: '43.598762'
  },
  {
    code: '650522',
    name: '伊吾县',
    city_level: 3,
    lng: '94.697074',
    lat: '43.254978'
  },
  {
    code: '652300',
    name: '昌吉回族自治州',
    city_level: 2,
    lng: '87.308224',
    lat: '44.011182'
  },
  {
    code: '652301',
    name: '昌吉市',
    city_level: 3,
    lng: '87.304112',
    lat: '44.013183'
  },
  {
    code: '652302',
    name: '阜康市',
    city_level: 3,
    lng: '87.946894',
    lat: '44.168576'
  },
  {
    code: '652323',
    name: '呼图壁县',
    city_level: 3,
    lng: '86.898902',
    lat: '44.191428'
  },
  {
    code: '652324',
    name: '玛纳斯县',
    city_level: 3,
    lng: '86.213997',
    lat: '44.303893'
  },
  {
    code: '652325',
    name: '奇台县',
    city_level: 3,
    lng: '89.593967',
    lat: '44.022066'
  },
  {
    code: '652327',
    name: '吉木萨尔县',
    city_level: 3,
    lng: '89.180437',
    lat: '44.000497'
  },
  {
    code: '652328',
    name: '木垒哈萨克自治县',
    city_level: 3,
    lng: '90.286028',
    lat: '43.834689'
  },
  {
    code: '652700',
    name: '博尔塔拉蒙古自治州',
    city_level: 2,
    lng: '82.066159',
    lat: '44.905588'
  },
  {
    code: '652701',
    name: '博乐市',
    city_level: 3,
    lng: '82.051005',
    lat: '44.853870'
  },
  {
    code: '652702',
    name: '阿拉山口市',
    city_level: 3,
    lng: '82.559396',
    lat: '45.172228'
  },
  {
    code: '652722',
    name: '精河县',
    city_level: 3,
    lng: '82.894195',
    lat: '44.600408'
  },
  {
    code: '652723',
    name: '温泉县',
    city_level: 3,
    lng: '81.024816',
    lat: '44.968857'
  },
  {
    code: '652800',
    name: '巴音郭楞蒙古自治州',
    city_level: 2,
    lng: '86.145298',
    lat: '41.764115'
  },
  {
    code: '652801',
    name: '库尔勒市',
    city_level: 3,
    lng: '86.174633',
    lat: '41.725892'
  },
  {
    code: '652822',
    name: '轮台县',
    city_level: 3,
    lng: '84.252156',
    lat: '41.777702'
  },
  {
    code: '652823',
    name: '尉犁县',
    city_level: 3,
    lng: '86.261321',
    lat: '41.343933'
  },
  {
    code: '652824',
    name: '若羌县',
    city_level: 3,
    lng: '88.167152',
    lat: '39.023242'
  },
  {
    code: '652825',
    name: '且末县',
    city_level: 3,
    lng: '85.529702',
    lat: '38.145486'
  },
  {
    code: '652826',
    name: '焉耆回族自治县',
    city_level: 3,
    lng: '86.574067',
    lat: '42.059759'
  },
  {
    code: '652827',
    name: '和静县',
    city_level: 3,
    lng: '86.384065',
    lat: '42.323625'
  },
  {
    code: '652828',
    name: '和硕县',
    city_level: 3,
    lng: '86.863963',
    lat: '42.268371'
  },
  {
    code: '652829',
    name: '博湖县',
    city_level: 3,
    lng: '86.631998',
    lat: '41.980152'
  },
  {
    code: '652900',
    name: '阿克苏地区',
    city_level: 2,
    lng: '80.260605',
    lat: '41.168779'
  },
  {
    code: '652901',
    name: '阿克苏市',
    city_level: 2,
    lng: '80.260605',
    lat: '41.168779'
  },
  {
    code: '652902',
    name: '库车市',
    city_level: 3,
    lng: '83.060231',
    lat: '41.720265'
  },
  {
    code: '652922',
    name: '温宿县',
    city_level: 3,
    lng: '80.238959',
    lat: '41.276688'
  },
  {
    code: '652924',
    name: '沙雅县',
    city_level: 3,
    lng: '82.781819',
    lat: '41.221667'
  },
  {
    code: '652925',
    name: '新和县',
    city_level: 3,
    lng: '82.609220',
    lat: '41.548118'
  },
  {
    code: '652926',
    name: '拜城县',
    city_level: 3,
    lng: '81.874156',
    lat: '41.796910'
  },
  {
    code: '652927',
    name: '乌什县',
    city_level: 3,
    lng: '79.224445',
    lat: '41.214652'
  },
  {
    code: '652928',
    name: '阿瓦提县',
    city_level: 3,
    lng: '80.373137',
    lat: '40.644529'
  },
  {
    code: '652929',
    name: '柯坪县',
    city_level: 3,
    lng: '79.047291',
    lat: '40.508340'
  },
  {
    code: '653000',
    name: '克孜勒苏柯尔克孜自治州',
    city_level: 2,
    lng: '76.167819',
    lat: '39.714526'
  },
  {
    code: '653001',
    name: '阿图什市',
    city_level: 3,
    lng: '76.168400',
    lat: '39.716160'
  },
  {
    code: '653022',
    name: '阿克陶县',
    city_level: 3,
    lng: '75.947396',
    lat: '39.147785'
  },
  {
    code: '653023',
    name: '阿合奇县',
    city_level: 3,
    lng: '78.446253',
    lat: '40.936936'
  },
  {
    code: '653024',
    name: '乌恰县',
    city_level: 3,
    lng: '75.259228',
    lat: '39.719310'
  },
  {
    code: '653100',
    name: '喀什地区',
    city_level: 2,
    lng: '75.989755',
    lat: '39.470400'
  },
  {
    code: '653101',
    name: '喀什市',
    city_level: 2,
    lng: '75.989755',
    lat: '39.470400'
  },
  {
    code: '653121',
    name: '疏附县',
    city_level: 3,
    lng: '75.862814',
    lat: '39.375044'
  },
  {
    code: '653122',
    name: '疏勒县',
    city_level: 3,
    lng: '76.048139',
    lat: '39.401385'
  },
  {
    code: '653123',
    name: '英吉沙县',
    city_level: 3,
    lng: '76.175729',
    lat: '38.930382'
  },
  {
    code: '653124',
    name: '泽普县',
    city_level: 3,
    lng: '77.260103',
    lat: '38.184955'
  },
  {
    code: '653125',
    name: '莎车县',
    city_level: 3,
    lng: '77.245761',
    lat: '38.414217'
  },
  {
    code: '653126',
    name: '叶城县',
    city_level: 3,
    lng: '77.413836',
    lat: '37.882989'
  },
  {
    code: '653127',
    name: '麦盖提县',
    city_level: 3,
    lng: '77.648006',
    lat: '38.902232'
  },
  {
    code: '653128',
    name: '岳普湖县',
    city_level: 3,
    lng: '76.773163',
    lat: '39.224200'
  },
  {
    code: '653129',
    name: '伽师县',
    city_level: 3,
    lng: '76.723720',
    lat: '39.488182'
  },
  {
    code: '653130',
    name: '巴楚县',
    city_level: 3,
    lng: '78.549297',
    lat: '39.785155'
  },
  {
    code: '653131',
    name: '塔什库尔干塔吉克自治县',
    city_level: 3,
    lng: '75.229889',
    lat: '37.772094'
  },
  {
    code: '653200',
    name: '和田地区',
    city_level: 2,
    lng: '79.922211',
    lat: '37.114157'
  },
  {
    code: '653201',
    name: '和田市',
    city_level: 3,
    lng: '79.913534',
    lat: '37.112149'
  },
  {
    code: '653221',
    name: '和田县',
    city_level: 3,
    lng: '79.819070',
    lat: '37.120031'
  },
  {
    code: '653222',
    name: '墨玉县',
    city_level: 3,
    lng: '79.728841',
    lat: '37.277340'
  },
  {
    code: '653223',
    name: '皮山县',
    city_level: 3,
    lng: '78.283669',
    lat: '37.621450'
  },
  {
    code: '653224',
    name: '洛浦县',
    city_level: 3,
    lng: '80.188986',
    lat: '37.073667'
  },
  {
    code: '653225',
    name: '策勒县',
    city_level: 3,
    lng: '80.806159',
    lat: '36.998335'
  },
  {
    code: '653226',
    name: '于田县',
    city_level: 3,
    lng: '81.677418',
    lat: '36.857081'
  },
  {
    code: '653227',
    name: '民丰县',
    city_level: 3,
    lng: '82.695862',
    lat: '37.064080'
  },
  {
    code: '654000',
    name: '伊犁哈萨克自治州',
    city_level: 2,
    lng: '81.324136',
    lat: '43.916823'
  },
  {
    code: '654002',
    name: '伊宁市',
    city_level: 3,
    lng: '81.277950',
    lat: '43.908558'
  },
  {
    code: '654003',
    name: '奎屯市',
    city_level: 3,
    lng: '84.903267',
    lat: '44.426529'
  },
  {
    code: '654004',
    name: '霍尔果斯市',
    city_level: 3,
    lng: '80.420759',
    lat: '44.201669'
  },
  {
    code: '654021',
    name: '伊宁县',
    city_level: 3,
    lng: '81.527453',
    lat: '43.977138'
  },
  {
    code: '654022',
    name: '察布查尔锡伯自治县',
    city_level: 3,
    lng: '81.151337',
    lat: '43.840726'
  },
  {
    code: '654023',
    name: '霍城县',
    city_level: 3,
    lng: '80.874181',
    lat: '44.053592'
  },
  {
    code: '654024',
    name: '巩留县',
    city_level: 3,
    lng: '82.231718',
    lat: '43.482628'
  },
  {
    code: '654025',
    name: '新源县',
    city_level: 3,
    lng: '83.260770',
    lat: '43.429930'
  },
  {
    code: '654026',
    name: '昭苏县',
    city_level: 3,
    lng: '81.130975',
    lat: '43.157293'
  },
  {
    code: '654027',
    name: '特克斯县',
    city_level: 3,
    lng: '81.836206',
    lat: '43.217184'
  },
  {
    code: '654028',
    name: '尼勒克县',
    city_level: 3,
    lng: '82.511810',
    lat: '43.800247'
  },
  {
    code: '654200',
    name: '塔城地区',
    city_level: 2,
    lng: '82.980316',
    lat: '46.745364'
  },
  {
    code: '654201',
    name: '塔城市',
    city_level: 3,
    lng: '82.978928',
    lat: '46.748523'
  },
  {
    code: '654202',
    name: '乌苏市',
    city_level: 3,
    lng: '84.713736',
    lat: '44.418887'
  },
  {
    code: '654221',
    name: '额敏县',
    city_level: 3,
    lng: '83.628303',
    lat: '46.524673'
  },
  {
    code: '654223',
    name: '沙湾县',
    city_level: 3,
    lng: '85.619416',
    lat: '44.326388'
  },
  {
    code: '654224',
    name: '托里县',
    city_level: 3,
    lng: '83.606951',
    lat: '45.947638'
  },
  {
    code: '654225',
    name: '裕民县',
    city_level: 3,
    lng: '82.982668',
    lat: '46.201104'
  },
  {
    code: '654226',
    name: '和布克赛尔蒙古自治县',
    city_level: 3,
    lng: '85.728328',
    lat: '46.793235'
  },
  {
    code: '654300',
    name: '阿勒泰地区',
    city_level: 2,
    lng: '88.141253',
    lat: '47.844924'
  },
  {
    code: '654301',
    name: '阿勒泰市',
    city_level: 3,
    lng: '88.131842',
    lat: '47.827309'
  },
  {
    code: '654321',
    name: '布尔津县',
    city_level: 3,
    lng: '86.874897',
    lat: '47.701850'
  },
  {
    code: '654322',
    name: '富蕴县',
    city_level: 3,
    lng: '89.530734',
    lat: '46.995158'
  },
  {
    code: '654323',
    name: '福海县',
    city_level: 3,
    lng: '87.486703',
    lat: '47.111919'
  },
  {
    code: '654324',
    name: '哈巴河县',
    city_level: 3,
    lng: '86.418621',
    lat: '48.060846'
  },
  {
    code: '654325',
    name: '青河县',
    city_level: 3,
    lng: '90.382961',
    lat: '46.674205'
  },
  {
    code: '654326',
    name: '吉木乃县',
    city_level: 3,
    lng: '85.874096',
    lat: '47.443101'
  },
  {
    code: '659001',
    name: '石河子市',
    city_level: 3,
    lng: '86.08046',
    lat: '44.305428'
  },
  {
    code: '659002',
    name: '阿拉尔市',
    city_level: 3,
    lng: '81.280527',
    lat: '40.547653'
  },
  {
    code: '659003',
    name: '图木舒克市',
    city_level: 3,
    lng: '79.074089',
    lat: '39.868968'
  },
  {
    code: '659004',
    name: '五家渠市',
    city_level: 3,
    lng: '87.54324',
    lat: '44.166756'
  },
  {
    code: '659005',
    name: '北屯市',
    city_level: 3,
    lng: '87.832595',
    lat: '47.326883'
  },
  {
    code: '659006',
    name: '铁门关市',
    city_level: 3,
    lng: '85.669533',
    lat: '41.8633'
  },
  {
    code: '659007',
    name: '双河市',
    city_level: 3,
    lng: '82.353656',
    lat: '44.840524'
  },
  {
    code: '659008',
    name: '可克达拉市',
    city_level: 3,
    lng: '81.044542',
    lat: '43.944798'
  },
  {
    code: '659009',
    name: '昆玉市',
    city_level: 3,
    lng: '79.291083',
    lat: '37.209642'
  },
  {
    code: '659010',
    name: '胡杨河市',
    city_level: 3,
    lng: '85.997154',
    lat: '46.451208'
  },
  {
    code: '710000',
    name: '台湾省',
    city_level: 1,
    lng: '121.508903',
    lat: '25.044319'
  },
  {
    code: '810000',
    name: '香港特别行政区',
    city_level: 1,
    lng: '114.163825',
    lat: '22.276284'
  },
  {
    code: '820000',
    name: '澳门特别行政区',
    city_level: 1,
    lng: '113.543028',
    lat: '22.186835'
  }
]
const province_list = [
  {
    code: '110000',
    name: '北京市',
    city_level: 1,
    lng: '116.407526',
    lat: '39.904030',
    count: 0
  },
  {
    code: '120000',
    name: '天津市',
    city_level: 1,
    lng: '117.200983',
    lat: '39.084158',
    count: 0
  },
  {
    code: '130000',
    name: '河北省',
    city_level: 1,
    lng: '114.468664',
    lat: '38.037057',
    count: 0
  },
  {
    code: '140000',
    name: '山西省',
    city_level: 1,
    lng: '112.562398',
    lat: '37.873531',
    count: 0
  },
  {
    code: '150000',
    name: '内蒙古自治区',
    city_level: 1,
    lng: '111.765617',
    lat: '40.817498',
    count: 0
  },
  {
    code: '210000',
    name: '辽宁省',
    city_level: 1,
    lng: '123.429440',
    lat: '41.835441',
    count: 0
  },
  {
    code: '220000',
    name: '吉林省',
    city_level: 1,
    lng: '125.325990',
    lat: '43.896536',
    count: 0
  },
  {
    code: '230000',
    name: '黑龙江省',
    city_level: 1,
    lng: '126.661669',
    lat: '45.742347',
    count: 0
  },
  {
    code: '310000',
    name: '上海市',
    city_level: 2,
    lng: '121.473701',
    lat: '31.230416',
    count: 0
  },
  {
    code: '320000',
    name: '江苏省',
    city_level: 1,
    lng: '118.763232',
    lat: '32.061707',
    count: 0
  },
  {
    code: '330000',
    name: '浙江省',
    city_level: 1,
    lng: '120.152791',
    lat: '30.267446',
    count: 0
  },
  {
    code: '340000',
    name: '安徽省',
    city_level: 1,
    lng: '117.284922',
    lat: '31.861184',
    count: 0
  },
  {
    code: '350000',
    name: '福建省',
    city_level: 1,
    lng: '119.295144',
    lat: '26.100779',
    count: 0
  },
  {
    code: '360000',
    name: '江西省',
    city_level: 1,
    lng: '115.909228',
    lat: '28.675696',
    count: 0
  },
  {
    code: '370000',
    name: '山东省',
    city_level: 1,
    lng: '117.020359',
    lat: '36.668530',
    count: 0
  },
  {
    code: '410000',
    name: '河南省',
    city_level: 1,
    lng: '113.753602',
    lat: '34.765515',
    count: 0
  },
  {
    code: '420000',
    name: '湖北省',
    city_level: 1,
    lng: '114.341861',
    lat: '30.546498',
    count: 0
  },
  {
    code: '430000',
    name: '湖南省',
    city_level: 1,
    lng: '112.983810',
    lat: '28.112444',
    count: 0
  },
  {
    code: '440000',
    name: '广东省',
    city_level: 1,
    lng: '113.266530',
    lat: '23.132191',
    count: 0
  },
  {
    code: '450000',
    name: '广西壮族自治区',
    city_level: 1,
    lng: '108.327546',
    lat: '22.815478',
    count: 0
  },
  {
    code: '460000',
    name: '海南省',
    city_level: 1,
    lng: '110.349228',
    lat: '20.017377',
    count: 0
  },
  {
    code: '500000',
    name: '重庆市',
    city_level: 1,
    lng: '106.551556',
    lat: '29.563009',
    count: 0
  },
  {
    code: '510000',
    name: '四川省',
    city_level: 1,
    lng: '104.075931',
    lat: '30.651651',
    count: 0
  },
  {
    code: '520000',
    name: '贵州省',
    city_level: 1,
    lng: '106.707410',
    lat: '26.598194',
    count: 0
  },
  {
    code: '530000',
    name: '云南省',
    city_level: 1,
    lng: '102.710002',
    lat: '25.045806',
    count: 0
  },
  {
    code: '540000',
    name: '西藏自治区',
    city_level: 1,
    lng: '91.117212',
    lat: '29.646922',
    count: 0
  },
  {
    code: '610000',
    name: '陕西省',
    city_level: 1,
    lng: '108.954239',
    lat: '34.265472',
    count: 0
  },
  {
    code: '620000',
    name: '甘肃省',
    city_level: 1,
    lng: '103.826308',
    lat: '36.059421',
    count: 0
  },
  {
    code: '630000',
    name: '青海省',
    city_level: 1,
    lng: '101.780199',
    lat: '36.620901',
    count: 0
  },
  {
    code: '640000',
    name: '宁夏回族自治区',
    city_level: 1,
    lng: '106.258754',
    lat: '38.471317',
    count: 0
  },
  {
    code: '650000',
    name: '新疆维吾尔自治区',
    city_level: 1,
    lng: '87.627704',
    lat: '43.793026',
    count: 0
  },
  {
    code: '710000',
    name: '台湾省',
    city_level: 1,
    lng: '121.508903',
    lat: '25.044319',
    count: 0
  },
  {
    code: '810000',
    name: '香港特别行政区',
    city_level: 1,
    lng: '114.163825',
    lat: '22.276284',
    count: 0
  },
  {
    code: '820000',
    name: '澳门特别行政区',
    city_level: 1,
    lng: '113.543028',
    lat: '22.186835',
    count: 0
  }
]
const city_list = [
  {
    code: '500100',
    name: '重庆市',
    city_level: 2,
    lng: '106.551556',
    lat: '29.563009',
    count: 0
  },
  {
    code: '110100',
    name: '北京市',
    city_level: 2,
    lng: '116.407526',
    lat: '39.904030',
    count: 0
  },
  {
    code: '120100',
    name: '天津市',
    city_level: 2,
    lng: '117.200983',
    lat: '39.084158'
  },
  {
    code: '310100',
    name: '上海市',
    city_level: 2,
    lng: '121.473701',
    lat: '31.230416',
    count: 0
  },
  {
    code: '130100',
    name: '石家庄市',
    city_level: 2,
    lng: '114.514859',
    lat: '38.042306',
    count: 0
  },
  {
    code: '130200',
    name: '唐山市',
    city_level: 2,
    lng: '118.180193',
    lat: '39.630867',
    count: 0
  },
  {
    code: '130300',
    name: '秦皇岛市',
    city_level: 2,
    lng: '119.600492',
    lat: '39.935385',
    count: 0
  },
  {
    code: '130400',
    name: '邯郸市',
    city_level: 2,
    lng: '114.538961',
    lat: '36.625657',
    count: 0
  },
  {
    code: '130500',
    name: '邢台市',
    city_level: 2,
    lng: '114.504844',
    lat: '37.070589',
    count: 0
  },
  {
    code: '130600',
    name: '保定市',
    city_level: 2,
    lng: '115.464806',
    lat: '38.873891',
    count: 0
  },
  {
    code: '130700',
    name: '张家口市',
    city_level: 2,
    lng: '114.887543',
    lat: '40.824418',
    count: 0
  },
  {
    code: '130800',
    name: '承德市',
    city_level: 2,
    lng: '117.962410',
    lat: '40.954071',
    count: 0
  },
  {
    code: '130900',
    name: '沧州市',
    city_level: 2,
    lng: '116.838834',
    lat: '38.304477',
    count: 0
  },
  {
    code: '131000',
    name: '廊坊市',
    city_level: 2,
    lng: '116.683752',
    lat: '39.538047',
    count: 0
  },
  {
    code: '131100',
    name: '衡水市',
    city_level: 2,
    lng: '115.670177',
    lat: '37.738920',
    count: 0
  },
  {
    code: '140100',
    name: '太原市',
    city_level: 2,
    lng: '112.548879',
    lat: '37.870590',
    count: 0
  },
  {
    code: '140200',
    name: '大同市',
    city_level: 2,
    lng: '113.300129',
    lat: '40.076762',
    count: 0
  },
  {
    code: '140300',
    name: '阳泉市',
    city_level: 2,
    lng: '113.580519',
    lat: '37.856971',
    count: 0
  },
  {
    code: '140400',
    name: '长治市',
    city_level: 2,
    lng: '113.116255',
    lat: '36.195386',
    count: 0
  },
  {
    code: '140500',
    name: '晋城市',
    city_level: 2,
    lng: '112.851831',
    lat: '35.490701',
    count: 0
  },
  {
    code: '140600',
    name: '朔州市',
    city_level: 2,
    lng: '112.432825',
    lat: '39.331595',
    count: 0
  },
  {
    code: '140700',
    name: '晋中市',
    city_level: 2,
    lng: '112.752694',
    lat: '37.687024',
    count: 0
  },
  {
    code: '140800',
    name: '运城市',
    city_level: 2,
    lng: '111.007528',
    lat: '35.026412',
    count: 0
  },
  {
    code: '140900',
    name: '忻州市',
    city_level: 2,
    lng: '112.734174',
    lat: '38.416663',
    count: 0
  },
  {
    code: '141000',
    name: '临汾市',
    city_level: 2,
    lng: '111.518976',
    lat: '36.088005',
    count: 0
  },
  {
    code: '141100',
    name: '吕梁市',
    city_level: 2,
    lng: '111.144319',
    lat: '37.518314',
    count: 0
  },
  {
    code: '150100',
    name: '呼和浩特市',
    city_level: 2,
    lng: '111.749180',
    lat: '40.842585',
    count: 0
  },
  {
    code: '150200',
    name: '包头市',
    city_level: 2,
    lng: '109.840347',
    lat: '40.657449',
    count: 0
  },
  {
    code: '150300',
    name: '乌海市',
    city_level: 2,
    lng: '106.794249',
    lat: '39.655388',
    count: 0
  },
  {
    code: '150400',
    name: '赤峰市',
    city_level: 2,
    lng: '118.886856',
    lat: '42.257817',
    count: 0
  },
  {
    code: '150500',
    name: '通辽市',
    city_level: 2,
    lng: '122.243444',
    lat: '43.652890',
    count: 0
  },
  {
    code: '150600',
    name: '鄂尔多斯市',
    city_level: 2,
    lng: '109.781327',
    lat: '39.608266',
    count: 0
  },
  {
    code: '150700',
    name: '呼伦贝尔市',
    city_level: 2,
    lng: '119.765744',
    lat: '49.211574',
    count: 0
  },
  {
    code: '150800',
    name: '巴彦淖尔市',
    city_level: 2,
    lng: '107.387657',
    lat: '40.743213',
    count: 0
  },
  {
    code: '150900',
    name: '乌兰察布市',
    city_level: 2,
    lng: '113.132585',
    lat: '40.994785',
    count: 0
  },
  {
    code: '152200',
    name: '兴安盟',
    city_level: 2,
    lng: '122.067042',
    lat: '46.077561',
    count: 0
  },
  {
    code: '152500',
    name: '锡林郭勒盟',
    city_level: 2,
    lng: '116.048222',
    lat: '43.933454',
    count: 0
  },
  {
    code: '152900',
    name: '阿拉善盟',
    city_level: 2,
    lng: '105.728969',
    lat: '38.851892',
    count: 0
  },
  {
    code: '210100',
    name: '沈阳市',
    city_level: 2,
    lng: '123.431474',
    lat: '41.805698',
    count: 0
  },
  {
    code: '210200',
    name: '大连市',
    city_level: 2,
    lng: '121.614682',
    lat: '38.914003',
    count: 0
  },
  {
    code: '210300',
    name: '鞍山市',
    city_level: 2,
    lng: '122.994329',
    lat: '41.108647',
    count: 0
  },
  {
    code: '210400',
    name: '抚顺市',
    city_level: 2,
    lng: '123.957208',
    lat: '41.880872',
    count: 0
  },
  {
    code: '210500',
    name: '本溪市',
    city_level: 2,
    lng: '123.766485',
    lat: '41.294175',
    count: 0
  },
  {
    code: '210600',
    name: '丹东市',
    city_level: 2,
    lng: '124.354706',
    lat: '40.000499',
    count: 0
  },
  {
    code: '210700',
    name: '锦州市',
    city_level: 2,
    lng: '121.127003',
    lat: '41.095119',
    count: 0
  },
  {
    code: '210800',
    name: '营口市',
    city_level: 2,
    lng: '122.235417',
    lat: '40.667012',
    count: 0
  },
  {
    code: '210900',
    name: '阜新市',
    city_level: 2,
    lng: '121.670323',
    lat: '42.021619',
    count: 0
  },
  {
    code: '211000',
    name: '辽阳市',
    city_level: 2,
    lng: '123.236944',
    lat: '41.267244',
    count: 0
  },
  {
    code: '211100',
    name: '盘锦市',
    city_level: 2,
    lng: '122.070714',
    lat: '41.119997',
    count: 0
  },
  {
    code: '211200',
    name: '铁岭市',
    city_level: 2,
    lng: '123.726166',
    lat: '42.223769',
    count: 0
  },
  {
    code: '211300',
    name: '朝阳市',
    city_level: 2,
    lng: '120.450372',
    lat: '41.573734',
    count: 0
  },
  {
    code: '211400',
    name: '葫芦岛市',
    city_level: 2,
    lng: '120.836932',
    lat: '40.711052',
    count: 0
  },
  {
    code: '220100',
    name: '长春市',
    city_level: 2,
    lng: '125.323544',
    lat: '43.817071',
    count: 0
  },
  {
    code: '220200',
    name: '吉林市',
    city_level: 2,
    lng: '126.549576',
    lat: '43.837883',
    count: 0
  },
  {
    code: '220300',
    name: '四平市',
    city_level: 2,
    lng: '124.350398',
    lat: '43.166419',
    count: 0
  },
  {
    code: '220400',
    name: '辽源市',
    city_level: 2,
    lng: '125.143532',
    lat: '42.887918',
    count: 0
  },
  {
    code: '220500',
    name: '通化市',
    city_level: 2,
    lng: '125.939697',
    lat: '41.728401',
    count: 0
  },
  {
    code: '220600',
    name: '白山市',
    city_level: 2,
    lng: '126.423587',
    lat: '41.939994',
    count: 0
  },
  {
    code: '220700',
    name: '松原市',
    city_level: 2,
    lng: '124.825117',
    lat: '45.141789',
    count: 0
  },
  {
    code: '220800',
    name: '白城市',
    city_level: 2,
    lng: '122.839024',
    lat: '45.619641',
    count: 0
  },
  {
    code: '222400',
    name: '延边朝鲜族自治州',
    city_level: 2,
    lng: '129.508946',
    lat: '42.891253',
    count: 0
  },
  {
    code: '230100',
    name: '哈尔滨市',
    city_level: 2,
    lng: '126.534967',
    lat: '45.803775',
    count: 0
  },
  {
    code: '230200',
    name: '齐齐哈尔市',
    city_level: 2,
    lng: '123.918186',
    lat: '47.354348',
    count: 0
  },
  {
    code: '230300',
    name: '鸡西市',
    city_level: 2,
    lng: '130.969333',
    lat: '45.295075',
    count: 0
  },
  {
    code: '230400',
    name: '鹤岗市',
    city_level: 2,
    lng: '130.297964',
    lat: '47.349916',
    count: 0
  },
  {
    code: '230500',
    name: '双鸭山市',
    city_level: 2,
    lng: '131.159133',
    lat: '46.646508',
    count: 0
  },
  {
    code: '230600',
    name: '大庆市',
    city_level: 2,
    lng: '125.103784',
    lat: '46.589309',
    count: 0
  },
  {
    code: '230700',
    name: '伊春市',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536',
    count: 0
  },
  {
    code: '230800',
    name: '佳木斯市',
    city_level: 2,
    lng: '130.318917',
    lat: '46.799922',
    count: 0
  },
  {
    code: '230900',
    name: '七台河市',
    city_level: 2,
    lng: '131.003138',
    lat: '45.771726',
    count: 0
  },
  {
    code: '231000',
    name: '牡丹江市',
    city_level: 2,
    lng: '129.633168',
    lat: '44.551653',
    count: 0
  },
  {
    code: '231100',
    name: '黑河市',
    city_level: 2,
    lng: '127.528560',
    lat: '50.245329',
    count: 0
  },
  {
    code: '231200',
    name: '绥化市',
    city_level: 2,
    lng: '126.968887',
    lat: '46.653845',
    count: 0
  },
  {
    code: '232700',
    name: '大兴安岭地区',
    city_level: 2,
    lng: '124.711080',
    lat: '52.335206',
    count: 0
  },
  {
    code: '320100',
    name: '南京市',
    city_level: 2,
    lng: '118.796877',
    lat: '32.060255',
    count: 0
  },
  {
    code: '320200',
    name: '无锡市',
    city_level: 2,
    lng: '120.311910',
    lat: '31.491169',
    count: 0
  },
  {
    code: '320300',
    name: '徐州市',
    city_level: 2,
    lng: '117.284124',
    lat: '34.205768',
    count: 0
  },
  {
    code: '320400',
    name: '常州市',
    city_level: 2,
    lng: '119.973987',
    lat: '31.810689',
    count: 0
  },
  {
    code: '320500',
    name: '苏州市',
    city_level: 2,
    lng: '120.585315',
    lat: '31.298886',
    count: 0
  },
  {
    code: '320600',
    name: '南通市',
    city_level: 2,
    lng: '120.894291',
    lat: '31.980171',
    count: 0
  },
  {
    code: '320700',
    name: '连云港市',
    city_level: 2,
    lng: '119.221611',
    lat: '34.596653',
    count: 0
  },
  {
    code: '320800',
    name: '淮安市',
    city_level: 2,
    lng: '119.015285',
    lat: '33.610353',
    count: 0
  },
  {
    code: '320900',
    name: '盐城市',
    city_level: 2,
    lng: '120.163561',
    lat: '33.347382',
    count: 0
  },
  {
    code: '321000',
    name: '扬州市',
    city_level: 2,
    lng: '119.412966',
    lat: '32.394210',
    count: 0
  },
  {
    code: '321100',
    name: '镇江市',
    city_level: 2,
    lng: '119.425836',
    lat: '32.187849',
    count: 0
  },
  {
    code: '321200',
    name: '泰州市',
    city_level: 2,
    lng: '119.923116',
    lat: '32.455778',
    count: 0
  },
  {
    code: '321300',
    name: '宿迁市',
    city_level: 2,
    lng: '118.275198',
    lat: '33.963232',
    count: 0
  },
  {
    code: '330100',
    name: '杭州市',
    city_level: 2,
    lng: '120.155070',
    lat: '30.274084',
    count: 0
  },
  {
    code: '330200',
    name: '宁波市',
    city_level: 2,
    lng: '121.550357',
    lat: '29.874556',
    count: 0
  },
  {
    code: '330300',
    name: '温州市',
    city_level: 2,
    lng: '120.699366',
    lat: '27.994267',
    count: 0
  },
  {
    code: '330400',
    name: '嘉兴市',
    city_level: 2,
    lng: '120.755486',
    lat: '30.746129',
    count: 0
  },
  {
    code: '330500',
    name: '湖州市',
    city_level: 2,
    lng: '120.086823',
    lat: '30.894348',
    count: 0
  },
  {
    code: '330600',
    name: '绍兴市',
    city_level: 2,
    lng: '120.580232',
    lat: '30.029752',
    count: 0
  },
  {
    code: '330700',
    name: '金华市',
    city_level: 2,
    lng: '119.647444',
    lat: '29.079059',
    count: 0
  },
  {
    code: '330800',
    name: '衢州市',
    city_level: 2,
    lng: '118.859457',
    lat: '28.970079',
    count: 0
  },
  {
    code: '330900',
    name: '舟山市',
    city_level: 2,
    lng: '122.207215',
    lat: '29.985295',
    count: 0
  },
  {
    code: '331000',
    name: '台州市',
    city_level: 2,
    lng: '121.420757',
    lat: '28.656386',
    count: 0
  },
  {
    code: '331100',
    name: '丽水市',
    city_level: 2,
    lng: '119.922796',
    lat: '28.467630',
    count: 0
  },
  {
    code: '340100',
    name: '合肥市',
    city_level: 2,
    lng: '117.227239',
    lat: '31.820586',
    count: 0
  },
  {
    code: '340200',
    name: '芜湖市',
    city_level: 2,
    lng: '118.432941',
    lat: '31.352859',
    count: 0
  },
  {
    code: '340300',
    name: '蚌埠市',
    city_level: 2,
    lng: '117.389719',
    lat: '32.916287',
    count: 0
  },
  {
    code: '340400',
    name: '淮南市',
    city_level: 2,
    lng: '116.999932',
    lat: '32.625478',
    count: 0
  },
  {
    code: '340500',
    name: '马鞍山市',
    city_level: 2,
    lng: '118.506759',
    lat: '31.670452',
    count: 0
  },
  {
    code: '340600',
    name: '淮北市',
    city_level: 2,
    lng: '116.798265',
    lat: '33.955844',
    count: 0
  },
  {
    code: '340700',
    name: '铜陵市',
    city_level: 2,
    lng: '117.812079',
    lat: '30.945429',
    count: 0
  },
  {
    code: '340800',
    name: '安庆市',
    city_level: 2,
    lng: '117.063754',
    lat: '30.543494',
    count: 0
  },
  {
    code: '341000',
    name: '黄山市',
    city_level: 2,
    lng: '118.337481',
    lat: '29.714655',
    count: 0
  },
  {
    code: '341100',
    name: '滁州市',
    city_level: 2,
    lng: '118.317106',
    lat: '32.301556',
    count: 0
  },
  {
    code: '341200',
    name: '阜阳市',
    city_level: 2,
    lng: '115.814204',
    lat: '32.890124',
    count: 0
  },
  {
    code: '341300',
    name: '宿州市',
    city_level: 2,
    lng: '116.964356',
    lat: '33.646373',
    count: 0
  },
  {
    code: '341500',
    name: '六安市',
    city_level: 2,
    lng: '116.521854',
    lat: '31.733699',
    count: 0
  },
  {
    code: '341600',
    name: '亳州市',
    city_level: 2,
    lng: '115.778676',
    lat: '33.844582',
    count: 0
  },
  {
    code: '341700',
    name: '池州市',
    city_level: 2,
    lng: '117.491568',
    lat: '30.664800',
    count: 0
  },
  {
    code: '341800',
    name: '宣城市',
    city_level: 2,
    lng: '118.758816',
    lat: '30.940718',
    count: 0
  },
  {
    code: '350100',
    name: '福州市',
    city_level: 2,
    lng: '119.296494',
    lat: '26.074507',
    count: 0
  },
  {
    code: '350200',
    name: '厦门市',
    city_level: 2,
    lng: '118.089425',
    lat: '24.479833',
    count: 0
  },
  {
    code: '350300',
    name: '莆田市',
    city_level: 2,
    lng: '119.007777',
    lat: '25.454084',
    count: 0
  },
  {
    code: '350400',
    name: '三明市',
    city_level: 2,
    lng: '117.638678',
    lat: '26.263406',
    count: 0
  },
  {
    code: '350500',
    name: '泉州市',
    city_level: 2,
    lng: '118.675675',
    lat: '24.874132',
    count: 0
  },
  {
    code: '350600',
    name: '漳州市',
    city_level: 2,
    lng: '117.647481',
    lat: '24.512948',
    count: 0
  },
  {
    code: '350700',
    name: '南平市',
    city_level: 3,
    lng: '118.081325',
    lat: '27.382829',
    count: 0
  },
  {
    code: '350800',
    name: '龙岩市',
    city_level: 2,
    lng: '117.017536',
    lat: '25.075123',
    count: 0
  },
  {
    code: '350900',
    name: '宁德市',
    city_level: 2,
    lng: '119.547932',
    lat: '26.665617',
    count: 0
  },
  {
    code: '360100',
    name: '南昌市',
    city_level: 2,
    lng: '115.858197',
    lat: '28.682892',
    count: 0
  },
  {
    code: '360200',
    name: '景德镇市',
    city_level: 2,
    lng: '117.178419',
    lat: '29.268835',
    count: 0
  },
  {
    code: '360300',
    name: '萍乡市',
    city_level: 2,
    lng: '113.854556',
    lat: '27.622768',
    count: 0
  },
  {
    code: '360400',
    name: '九江市',
    city_level: 2,
    lng: '116.001930',
    lat: '29.705077',
    count: 0
  },
  {
    code: '360500',
    name: '新余市',
    city_level: 2,
    lng: '114.917346',
    lat: '27.817808',
    count: 0
  },
  {
    code: '360600',
    name: '鹰潭市',
    city_level: 2,
    lng: '117.069202',
    lat: '28.260189',
    count: 0
  },
  {
    code: '360700',
    name: '赣州市',
    city_level: 2,
    lng: '114.935029',
    lat: '25.831829',
    count: 0
  },
  {
    code: '360800',
    name: '吉安市',
    city_level: 2,
    lng: '114.992509',
    lat: '27.113443',
    count: 0
  },
  {
    code: '360900',
    name: '宜春市',
    city_level: 2,
    lng: '114.416778',
    lat: '27.815619',
    count: 0
  },
  {
    code: '361000',
    name: '抚州市',
    city_level: 2,
    lng: '116.358181',
    lat: '27.949217',
    count: 0
  },
  {
    code: '361100',
    name: '上饶市',
    city_level: 2,
    lng: '117.943433',
    lat: '28.454863',
    count: 0
  },
  {
    code: '370100',
    name: '济南市',
    city_level: 2,
    lng: '117.119999',
    lat: '36.651216',
    count: 0
  },
  {
    code: '370200',
    name: '青岛市',
    city_level: 2,
    lng: '120.382639',
    lat: '36.067082',
    count: 0
  },
  {
    code: '370300',
    name: '淄博市',
    city_level: 2,
    lng: '118.054927',
    lat: '36.813487',
    count: 0
  },
  {
    code: '370400',
    name: '枣庄市',
    city_level: 2,
    lng: '117.323725',
    lat: '34.810487',
    count: 0
  },
  {
    code: '370500',
    name: '东营市',
    city_level: 2,
    lng: '118.674767',
    lat: '37.434751',
    count: 0
  },
  {
    code: '370600',
    name: '烟台市',
    city_level: 2,
    lng: '121.447935',
    lat: '37.463822',
    count: 0
  },
  {
    code: '370700',
    name: '潍坊市',
    city_level: 2,
    lng: '119.161755',
    lat: '36.706774',
    count: 0
  },
  {
    code: '370800',
    name: '济宁市',
    city_level: 2,
    lng: '116.587098',
    lat: '35.414921',
    count: 0
  },
  {
    code: '370900',
    name: '泰安市',
    city_level: 2,
    lng: '117.087614',
    lat: '36.200252',
    count: 0
  },
  {
    code: '371000',
    name: '威海市',
    city_level: 2,
    lng: '122.120419',
    lat: '37.513068',
    count: 0
  },
  {
    code: '371100',
    name: '日照市',
    city_level: 2,
    lng: '119.526888',
    lat: '35.416377',
    count: 0
  },
  {
    code: '371300',
    name: '临沂市',
    city_level: 2,
    lng: '118.356448',
    lat: '35.104672',
    count: 0
  },
  {
    code: '371400',
    name: '德州市',
    city_level: 2,
    lng: '116.357464',
    lat: '37.434092',
    count: 0
  },
  {
    code: '371500',
    name: '聊城市',
    city_level: 2,
    lng: '115.985371',
    lat: '36.456703',
    count: 0
  },
  {
    code: '371600',
    name: '滨州市',
    city_level: 2,
    lng: '117.970703',
    lat: '37.381990',
    count: 0
  },
  {
    code: '371700',
    name: '菏泽市',
    city_level: 2,
    lng: '115.480656',
    lat: '35.233750',
    count: 0
  },
  {
    code: '410100',
    name: '郑州市',
    city_level: 2,
    lng: '113.625368',
    lat: '34.746599',
    count: 0
  },
  {
    code: '410200',
    name: '开封市',
    city_level: 2,
    lng: '114.307581',
    lat: '34.797239',
    count: 0
  },
  {
    code: '410300',
    name: '洛阳市',
    city_level: 2,
    lng: '112.454040',
    lat: '34.619682',
    count: 0
  },
  {
    code: '410400',
    name: '平顶山市',
    city_level: 2,
    lng: '113.192661',
    lat: '33.766169',
    count: 0
  },
  {
    code: '410500',
    name: '安阳市',
    city_level: 2,
    lng: '114.392392',
    lat: '36.097577',
    count: 0
  },
  {
    code: '410600',
    name: '鹤壁市',
    city_level: 2,
    lng: '114.297272',
    lat: '35.747225',
    count: 0
  },
  {
    code: '410700',
    name: '新乡市',
    city_level: 2,
    lng: '113.926800',
    lat: '35.303004',
    count: 0
  },
  {
    code: '410800',
    name: '焦作市',
    city_level: 2,
    lng: '113.241823',
    lat: '35.215892',
    count: 0
  },
  {
    code: '410900',
    name: '濮阳市',
    city_level: 2,
    lng: '115.029215',
    lat: '35.761829',
    count: 0
  },
  {
    code: '411000',
    name: '许昌市',
    city_level: 2,
    lng: '113.852640',
    lat: '34.035506',
    count: 0
  },
  {
    code: '411100',
    name: '漯河市',
    city_level: 2,
    lng: '114.016539',
    lat: '33.581412',
    count: 0
  },
  {
    code: '411200',
    name: '三门峡市',
    city_level: 2,
    lng: '111.200135',
    lat: '34.772493',
    count: 0
  },
  {
    code: '411300',
    name: '南阳市',
    city_level: 2,
    lng: '112.528321',
    lat: '32.990833',
    count: 0
  },
  {
    code: '411400',
    name: '商丘市',
    city_level: 2,
    lng: '115.656370',
    lat: '34.414172',
    count: 0
  },
  {
    code: '411500',
    name: '信阳市',
    city_level: 2,
    lng: '114.091023',
    lat: '32.146983',
    count: 0
  },
  {
    code: '411600',
    name: '周口市',
    city_level: 2,
    lng: '114.696951',
    lat: '33.626149',
    count: 0
  },
  {
    code: '411700',
    name: '驻马店市',
    city_level: 2,
    lng: '114.022298',
    lat: '33.011529',
    count: 0
  },
  {
    code: '420100',
    name: '武汉市',
    city_level: 2,
    lng: '114.305392',
    lat: '30.593098',
    count: 0
  },
  {
    code: '420200',
    name: '黄石市',
    city_level: 2,
    lng: '115.038520',
    lat: '30.199652',
    count: 0
  },
  {
    code: '420300',
    name: '十堰市',
    city_level: 2,
    lng: '110.797990',
    lat: '32.629397',
    count: 0
  },
  {
    code: '420500',
    name: '宜昌市',
    city_level: 2,
    lng: '111.286471',
    lat: '30.691967',
    count: 0
  },
  {
    code: '420600',
    name: '襄阳市',
    city_level: 2,
    lng: '112.122414',
    lat: '32.008986',
    count: 0
  },
  {
    code: '420700',
    name: '鄂州市',
    city_level: 2,
    lng: '114.894843',
    lat: '30.391940',
    count: 0
  },
  {
    code: '420800',
    name: '荆门市',
    city_level: 2,
    lng: '112.199265',
    lat: '31.035423',
    count: 0
  },
  {
    code: '420900',
    name: '孝感市',
    city_level: 2,
    lng: '113.916902',
    lat: '30.924568',
    count: 0
  },
  {
    code: '421000',
    name: '荆州市',
    city_level: 2,
    lng: '112.239741',
    lat: '30.335165',
    count: 0
  },
  {
    code: '421100',
    name: '黄冈市',
    city_level: 2,
    lng: '114.872316',
    lat: '30.453905',
    count: 0
  },
  {
    code: '421200',
    name: '咸宁市',
    city_level: 2,
    lng: '114.322492',
    lat: '29.841443',
    count: 0
  },
  {
    code: '421300',
    name: '随州市',
    city_level: 2,
    lng: '113.382458',
    lat: '31.690215',
    count: 0
  },
  {
    code: '422800',
    name: '恩施土家族苗族自治州',
    city_level: 2,
    lng: '109.488172',
    lat: '30.272156',
    count: 0
  },
  {
    code: '430100',
    name: '长沙市',
    city_level: 2,
    lng: '112.938814',
    lat: '28.228209',
    count: 0
  },
  {
    code: '430200',
    name: '株洲市',
    city_level: 2,
    lng: '113.134002',
    lat: '27.827550',
    count: 0
  },
  {
    code: '430300',
    name: '湘潭市',
    city_level: 2,
    lng: '112.944049',
    lat: '27.829738',
    count: 0
  },
  {
    code: '430400',
    name: '衡阳市',
    city_level: 2,
    lng: '112.571997',
    lat: '26.893230',
    count: 0
  },
  {
    code: '430500',
    name: '邵阳市',
    city_level: 2,
    lng: '111.467791',
    lat: '27.238892',
    count: 0
  },
  {
    code: '430600',
    name: '岳阳市',
    city_level: 2,
    lng: '113.128958',
    lat: '29.357104',
    count: 0
  },
  {
    code: '430700',
    name: '常德市',
    city_level: 2,
    lng: '111.698497',
    lat: '29.031673',
    count: 0
  },
  {
    code: '430800',
    name: '张家界市',
    city_level: 2,
    lng: '110.479191',
    lat: '29.117096',
    count: 0
  },
  {
    code: '430900',
    name: '益阳市',
    city_level: 2,
    lng: '112.355180',
    lat: '28.553860',
    count: 0
  },
  {
    code: '431000',
    name: '郴州市',
    city_level: 2,
    lng: '113.014717',
    lat: '25.770509',
    count: 0
  },
  {
    code: '431100',
    name: '永州市',
    city_level: 2,
    lng: '111.613445',
    lat: '26.420394',
    count: 0
  },
  {
    code: '431200',
    name: '怀化市',
    city_level: 2,
    lng: '109.998488',
    lat: '27.554978',
    count: 0
  },
  {
    code: '431300',
    name: '娄底市',
    city_level: 2,
    lng: '111.993497',
    lat: '27.700062',
    count: 0
  },
  {
    code: '433100',
    name: '湘西土家族苗族自治州',
    city_level: 2,
    lng: '109.739172',
    lat: '28.311947',
    count: 0
  },
  {
    code: '440100',
    name: '广州市',
    city_level: 2,
    lng: '113.264434',
    lat: '23.129162',
    count: 0
  },
  {
    code: '440200',
    name: '韶关市',
    city_level: 2,
    lng: '113.597522',
    lat: '24.810403',
    count: 0
  },
  {
    code: '440300',
    name: '深圳市',
    city_level: 2,
    lng: '114.057868',
    lat: '22.543099',
    count: 0
  },
  {
    code: '440400',
    name: '珠海市',
    city_level: 2,
    lng: '113.576726',
    lat: '22.270715',
    count: 0
  },
  {
    code: '440500',
    name: '汕头市',
    city_level: 2,
    lng: '116.681972',
    lat: '23.354091',
    count: 0
  },
  {
    code: '440600',
    name: '佛山市',
    city_level: 2,
    lng: '113.121416',
    lat: '23.021548',
    count: 0
  },
  {
    code: '440700',
    name: '江门市',
    city_level: 2,
    lng: '113.081901',
    lat: '22.578738',
    count: 0
  },
  {
    code: '440800',
    name: '湛江市',
    city_level: 2,
    lng: '110.359377',
    lat: '21.270707',
    count: 0
  },
  {
    code: '440900',
    name: '茂名市',
    city_level: 2,
    lng: '110.925456',
    lat: '21.662999',
    count: 0
  },
  {
    code: '441200',
    name: '肇庆市',
    city_level: 2,
    lng: '112.465091',
    lat: '23.047191',
    count: 0
  },
  {
    code: '441300',
    name: '惠州市',
    city_level: 2,
    lng: '114.416196',
    lat: '23.111847',
    count: 0
  },
  {
    code: '441400',
    name: '梅州市',
    city_level: 2,
    lng: '116.122238',
    lat: '24.288615',
    count: 0
  },
  {
    code: '441500',
    name: '汕尾市',
    city_level: 2,
    lng: '115.375278',
    lat: '22.786211',
    count: 0
  },
  {
    code: '441600',
    name: '河源市',
    city_level: 2,
    lng: '114.700447',
    lat: '23.743538',
    count: 0
  },
  {
    code: '441700',
    name: '阳江市',
    city_level: 2,
    lng: '111.982232',
    lat: '21.857958',
    count: 0
  },
  {
    code: '441800',
    name: '清远市',
    city_level: 2,
    lng: '113.056031',
    lat: '23.681763',
    count: 0
  },
  {
    code: '441900',
    name: '东莞市',
    city_level: 2,
    lng: '113.751765',
    lat: '23.020536',
    count: 0
  },
  {
    code: '442000',
    name: '中山市',
    city_level: 2,
    lng: '113.392782',
    lat: '22.517645',
    count: 0
  },
  {
    code: '445100',
    name: '潮州市',
    city_level: 2,
    lng: '116.622603',
    lat: '23.656950',
    count: 0
  },
  {
    code: '445200',
    name: '揭阳市',
    city_level: 2,
    lng: '116.372831',
    lat: '23.549993',
    count: 0
  },
  {
    code: '445300',
    name: '云浮市',
    city_level: 2,
    lng: '112.044491',
    lat: '22.915094',
    count: 0
  },
  {
    code: '450100',
    name: '南宁市',
    city_level: 2,
    lng: '108.366543',
    lat: '22.817002',
    count: 0
  },
  {
    code: '450200',
    name: '柳州市',
    city_level: 2,
    lng: '109.415953',
    lat: '24.325502',
    count: 0
  },
  {
    code: '450300',
    name: '桂林市',
    city_level: 2,
    lng: '110.290194',
    lat: '25.273566',
    count: 0
  },
  {
    code: '450400',
    name: '梧州市',
    city_level: 2,
    lng: '111.279115',
    lat: '23.476962',
    count: 0
  },
  {
    code: '450500',
    name: '北海市',
    city_level: 2,
    lng: '109.119927',
    lat: '21.481254',
    count: 0
  },
  {
    code: '450600',
    name: '防城港市',
    city_level: 2,
    lng: '108.353846',
    lat: '21.686860',
    count: 0
  },
  {
    code: '450700',
    name: '钦州市',
    city_level: 2,
    lng: '108.654146',
    lat: '21.979933',
    count: 0
  },
  {
    code: '450800',
    name: '贵港市',
    city_level: 2,
    lng: '109.598926',
    lat: '23.111530',
    count: 0
  },
  {
    code: '450900',
    name: '玉林市',
    city_level: 2,
    lng: '110.164756',
    lat: '22.636379',
    count: 0
  },
  {
    code: '451000',
    name: '百色市',
    city_level: 2,
    lng: '106.618201',
    lat: '23.902333',
    count: 0
  },
  {
    code: '451100',
    name: '贺州市',
    city_level: 2,
    lng: '111.566694',
    lat: '24.403582',
    count: 0
  },
  {
    code: '451200',
    name: '河池市',
    city_level: 2,
    lng: '108.085261',
    lat: '24.692931',
    count: 0
  },
  {
    code: '451300',
    name: '来宾市',
    city_level: 2,
    lng: '109.221465',
    lat: '23.750306',
    count: 0
  },
  {
    code: '451400',
    name: '崇左市',
    city_level: 2,
    lng: '107.364711',
    lat: '22.376532',
    count: 0
  },
  {
    code: '460100',
    name: '海口市',
    city_level: 2,
    lng: '110.198293',
    lat: '20.044001',
    count: 0
  },
  {
    code: '460200',
    name: '三亚市',
    city_level: 2,
    lng: '109.511909',
    lat: '18.252847',
    count: 0
  },
  {
    code: '460300',
    name: '三沙市',
    city_level: 2,
    lng: '112.338695',
    lat: '16.831839',
    count: 0
  },
  {
    code: '460400',
    name: '儋州市',
    city_level: 2,
    lng: '109.580811',
    lat: '19.521134',
    count: 0
  },
  {
    code: '469001',
    name: '五指山市',
    city_level: 2,
    lng: '109.516925',
    lat: '18.775146',
    count: 0
  },
  {
    code: '469002',
    name: '琼海市',
    city_level: 2,
    lng: '110.474581',
    lat: '19.25914',
    count: 0
  },
  {
    code: '469005',
    name: '文昌市',
    city_level: 2,
    lng: '110.797717',
    lat: '19.543422',
    count: 0
  },
  {
    code: '469006',
    name: '万宁市',
    city_level: 2,
    lng: '110.391073',
    lat: '18.795143',
    count: 0
  },
  {
    code: '469007',
    name: '东方市',
    city_level: 2,
    lng: '108.651815',
    lat: '19.095351',
    count: 0
  },
  {
    code: '469021',
    name: '定安县',
    city_level: 2,
    lng: '110.358891',
    lat: '19.681434',
    count: 0
  },
  {
    code: '469022',
    name: '屯昌县',
    city_level: 2,
    lng: '110.103415',
    lat: '19.351766',
    count: 0
  },
  {
    code: '469023',
    name: '澄迈县',
    city_level: 2,
    lng: '110.006755',
    lat: '19.738521',
    count: 0
  },
  {
    code: '469024',
    name: '临高县',
    city_level: 2,
    lng: '109.690508',
    lat: '19.912026',
    count: 0
  },
  {
    code: '469025',
    name: '白沙黎族自治县',
    city_level: 2,
    lng: '109.451484',
    lat: '19.224823',
    count: 0
  },
  {
    code: '469026',
    name: '昌江黎族自治县',
    city_level: 2,
    lng: '109.055724',
    lat: '19.298062',
    count: 0
  },
  {
    code: '469027',
    name: '乐东黎族自治县',
    city_level: 2,
    lng: '109.173055',
    lat: '18.750260',
    count: 0
  },
  {
    code: '469028',
    name: '陵水黎族自治县',
    city_level: 2,
    lng: '110.037504',
    lat: '18.506048',
    count: 0
  },
  {
    code: '469029',
    name: '保亭黎族苗族自治县',
    city_level: 2,
    lng: '109.702392',
    lat: '18.638189',
    count: 0
  },
  {
    code: '469030',
    name: '琼中黎族苗族自治县',
    city_level: 2,
    lng: '109.838389',
    lat: '19.033369',
    count: 0
  },
  {
    code: '510100',
    name: '成都市',
    city_level: 2,
    lng: '104.066541',
    lat: '30.572269',
    count: 0
  },
  {
    code: '510300',
    name: '自贡市',
    city_level: 2,
    lng: '104.778442',
    lat: '29.339030',
    count: 0
  },
  {
    code: '510400',
    name: '攀枝花市',
    city_level: 2,
    lng: '101.718637',
    lat: '26.582347',
    count: 0
  },
  {
    code: '510500',
    name: '泸州市',
    city_level: 2,
    lng: '105.442258',
    lat: '28.871811',
    count: 0
  },
  {
    code: '510600',
    name: '德阳市',
    city_level: 2,
    lng: '104.397894',
    lat: '31.126855',
    count: 0
  },
  {
    code: '510700',
    name: '绵阳市',
    city_level: 2,
    lng: '104.679114',
    lat: '31.467450',
    count: 0
  },
  {
    code: '510800',
    name: '广元市',
    city_level: 2,
    lng: '105.843357',
    lat: '32.435435',
    count: 0
  },
  {
    code: '510900',
    name: '遂宁市',
    city_level: 2,
    lng: '105.592898',
    lat: '30.532847',
    count: 0
  },
  {
    code: '511000',
    name: '内江市',
    city_level: 2,
    lng: '105.058433',
    lat: '29.580228',
    count: 0
  },
  {
    code: '511100',
    name: '乐山市',
    city_level: 2,
    lng: '103.765568',
    lat: '29.552106',
    count: 0
  },
  {
    code: '511300',
    name: '南充市',
    city_level: 2,
    lng: '106.110698',
    lat: '30.837793',
    count: 0
  },
  {
    code: '511400',
    name: '眉山市',
    city_level: 2,
    lng: '103.848538',
    lat: '30.075439',
    count: 0
  },
  {
    code: '511500',
    name: '宜宾市',
    city_level: 2,
    lng: '104.643215',
    lat: '28.751768',
    count: 0
  },
  {
    code: '511600',
    name: '广安市',
    city_level: 2,
    lng: '106.633212',
    lat: '30.455961',
    count: 0
  },
  {
    code: '511700',
    name: '达州市',
    city_level: 2,
    lng: '107.468023',
    lat: '31.209571',
    count: 0
  },
  {
    code: '511800',
    name: '雅安市',
    city_level: 2,
    lng: '103.013261',
    lat: '29.980537',
    count: 0
  },
  {
    code: '511900',
    name: '巴中市',
    city_level: 2,
    lng: '106.747477',
    lat: '31.867903',
    count: 0
  },
  {
    code: '512000',
    name: '资阳市',
    city_level: 2,
    lng: '104.627636',
    lat: '30.128901',
    count: 0
  },
  {
    code: '513200',
    name: '阿坝藏族羌族自治州',
    city_level: 2,
    lng: '102.224653',
    lat: '31.899413',
    count: 0
  },
  {
    code: '513300',
    name: '甘孜藏族自治州',
    city_level: 2,
    lng: '101.962310',
    lat: '30.049520',
    count: 0
  },
  {
    code: '513400',
    name: '凉山彝族自治州',
    city_level: 2,
    lng: '102.267335',
    lat: '27.881610',
    count: 0
  },
  {
    code: '520100',
    name: '贵阳市',
    city_level: 2,
    lng: '106.630153',
    lat: '26.647661',
    count: 0
  },
  {
    code: '520200',
    name: '六盘水市',
    city_level: 2,
    lng: '104.830359',
    lat: '26.592666',
    count: 0
  },
  {
    code: '520300',
    name: '遵义市',
    city_level: 2,
    lng: '106.927389',
    lat: '27.725654',
    count: 0
  },
  {
    code: '520400',
    name: '安顺市',
    city_level: 2,
    lng: '105.947593',
    lat: '26.253072',
    count: 0
  },
  {
    code: '520500',
    name: '毕节市',
    city_level: 2,
    lng: '105.283992',
    lat: '27.302589',
    count: 0
  },
  {
    code: '520600',
    name: '铜仁市',
    city_level: 2,
    lng: '109.189598',
    lat: '27.731514',
    count: 0
  },
  {
    code: '522300',
    name: '黔西南布依族苗族自治州',
    city_level: 2,
    lng: '104.906396',
    lat: '25.087825',
    count: 0
  },
  {
    code: '522600',
    name: '黔东南苗族侗族自治州',
    city_level: 2,
    lng: '107.982859',
    lat: '26.583442',
    count: 0
  },
  {
    code: '522700',
    name: '黔南布依族苗族自治州',
    city_level: 2,
    lng: '107.522097',
    lat: '26.254092',
    count: 0
  },
  {
    code: '530100',
    name: '昆明市',
    city_level: 2,
    lng: '102.832891',
    lat: '24.880095',
    count: 0
  },
  {
    code: '530300',
    name: '曲靖市',
    city_level: 2,
    lng: '103.796167',
    lat: '25.489999',
    count: 0
  },
  {
    code: '530400',
    name: '玉溪市',
    city_level: 2,
    lng: '102.546543',
    lat: '24.352036',
    count: 0
  },
  {
    code: '530500',
    name: '保山市',
    city_level: 2,
    lng: '99.161761',
    lat: '25.112046',
    count: 0
  },
  {
    code: '530600',
    name: '昭通市',
    city_level: 2,
    lng: '103.717465',
    lat: '27.338257',
    count: 0
  },
  {
    code: '530700',
    name: '丽江市',
    city_level: 2,
    lng: '100.227750',
    lat: '26.855047',
    count: 0
  },
  {
    code: '530800',
    name: '普洱市',
    city_level: 2,
    lng: '100.966512',
    lat: '22.825065',
    count: 0
  },
  {
    code: '530900',
    name: '临沧市',
    city_level: 2,
    lng: '100.079583',
    lat: '23.877573',
    count: 0
  },
  {
    code: '532300',
    name: '楚雄彝族自治州',
    city_level: 2,
    lng: '101.528068',
    lat: '25.045532',
    count: 0
  },
  {
    code: '532500',
    name: '红河哈尼族彝族自治州',
    city_level: 2,
    lng: '103.374799',
    lat: '23.363130',
    count: 0
  },
  {
    code: '532600',
    name: '文山壮族苗族自治州',
    city_level: 2,
    lng: '104.216248',
    lat: '23.400733',
    count: 0
  },
  {
    code: '532800',
    name: '西双版纳傣族自治州',
    city_level: 2,
    lng: '100.797777',
    lat: '22.007351',
    count: 0
  },
  {
    code: '532900',
    name: '大理白族自治州',
    city_level: 2,
    lng: '100.267638',
    lat: '25.606486',
    count: 0
  },
  {
    code: '533100',
    name: '德宏傣族景颇族自治州',
    city_level: 2,
    lng: '98.584895',
    lat: '24.433353',
    count: 0
  },
  {
    code: '533300',
    name: '怒江傈僳族自治州',
    city_level: 2,
    lng: '98.853097',
    lat: '25.852547',
    count: 0
  },
  {
    code: '533400',
    name: '迪庆藏族自治州',
    city_level: 2,
    lng: '99.702234',
    lat: '27.818882',
    count: 0
  },
  {
    code: '540100',
    name: '拉萨市',
    city_level: 2,
    lng: '91.140856',
    lat: '29.645554',
    count: 0
  },
  {
    code: '540200',
    name: '日喀则市',
    city_level: 2,
    lng: '88.880583',
    lat: '29.266869',
    count: 0
  },
  {
    code: '540300',
    name: '昌都市',
    city_level: 2,
    lng: '97.172020',
    lat: '31.140969',
    count: 0
  },
  {
    code: '540400',
    name: '林芝市',
    city_level: 2,
    lng: '94.361558',
    lat: '29.648943',
    count: 0
  },
  {
    code: '540500',
    name: '山南市',
    city_level: 2,
    lng: '91.773134',
    lat: '29.237137',
    count: 0
  },
  {
    code: '540600',
    name: '那曲市',
    city_level: 2,
    lng: '92.060211',
    lat: '31.476004',
    count: 0
  },
  {
    code: '542500',
    name: '阿里地区',
    city_level: 2,
    lng: '80.105804',
    lat: '32.501111',
    count: 0
  },
  {
    code: '610100',
    name: '西安市',
    city_level: 2,
    lng: '108.940174',
    lat: '34.341568',
    count: 0
  },
  {
    code: '610200',
    name: '铜川市',
    city_level: 2,
    lng: '108.945233',
    lat: '34.896756',
    count: 0
  },
  {
    code: '610300',
    name: '宝鸡市',
    city_level: 2,
    lng: '107.237974',
    lat: '34.361979',
    count: 0
  },
  {
    code: '610400',
    name: '咸阳市',
    city_level: 2,
    lng: '108.708991',
    lat: '34.329605',
    count: 0
  },
  {
    code: '610500',
    name: '渭南市',
    city_level: 2,
    lng: '109.509786',
    lat: '34.499995',
    count: 0
  },
  {
    code: '610600',
    name: '延安市',
    city_level: 2,
    lng: '109.489727',
    lat: '36.585455',
    count: 0
  },
  {
    code: '610700',
    name: '汉中市',
    city_level: 2,
    lng: '107.023323',
    lat: '33.067480',
    count: 0
  },
  {
    code: '610800',
    name: '榆林市',
    city_level: 2,
    lng: '109.734589',
    lat: '38.285390',
    count: 0
  },
  {
    code: '610900',
    name: '安康市',
    city_level: 2,
    lng: '109.029022',
    lat: '32.684714',
    count: 0
  },
  {
    code: '611000',
    name: '商洛市',
    city_level: 2,
    lng: '109.940477',
    lat: '33.870422',
    count: 0
  },
  {
    code: '620100',
    name: '兰州市',
    city_level: 2,
    lng: '103.834303',
    lat: '36.061089',
    count: 0
  },
  {
    code: '620200',
    name: '嘉峪关市',
    city_level: 2,
    lng: '98.289152',
    lat: '39.773130',
    count: 0
  },
  {
    code: '620300',
    name: '金昌市',
    city_level: 2,
    lng: '102.188043',
    lat: '38.520089',
    count: 0
  },
  {
    code: '620400',
    name: '白银市',
    city_level: 2,
    lng: '104.138559',
    lat: '36.544756',
    count: 0
  },
  {
    code: '620500',
    name: '天水市',
    city_level: 2,
    lng: '105.724947',
    lat: '34.580863',
    count: 0
  },
  {
    code: '620600',
    name: '武威市',
    city_level: 2,
    lng: '102.638011',
    lat: '37.928264',
    count: 0
  },
  {
    code: '620700',
    name: '张掖市',
    city_level: 2,
    lng: '100.449818',
    lat: '38.925875',
    count: 0
  },
  {
    code: '620800',
    name: '平凉市',
    city_level: 2,
    lng: '106.665240',
    lat: '35.543051',
    count: 0
  },
  {
    code: '620900',
    name: '酒泉市',
    city_level: 2,
    lng: '98.494483',
    lat: '39.732410',
    count: 0
  },
  {
    code: '621000',
    name: '庆阳市',
    city_level: 2,
    lng: '107.643631',
    lat: '35.709077',
    count: 0
  },
  {
    code: '621100',
    name: '定西市',
    city_level: 2,
    lng: '104.626282',
    lat: '35.580662',
    count: 0
  },
  {
    code: '621200',
    name: '陇南市',
    city_level: 2,
    lng: '104.921841',
    lat: '33.400684',
    count: 0
  },
  {
    code: '622900',
    name: '临夏回族自治州',
    city_level: 2,
    lng: '103.210538',
    lat: '35.601182',
    count: 0
  },
  {
    code: '623000',
    name: '甘南藏族自治州',
    city_level: 2,
    lng: '102.911027',
    lat: '34.983385',
    count: 0
  },
  {
    code: '630100',
    name: '西宁市',
    city_level: 2,
    lng: '101.778228',
    lat: '36.617144',
    count: 0
  },
  {
    code: '630200',
    name: '海东市',
    city_level: 2,
    lng: '102.104287',
    lat: '36.502039',
    count: 0
  },
  {
    code: '632200',
    name: '海北藏族自治州',
    city_level: 2,
    lng: '100.900997',
    lat: '36.954413',
    count: 0
  },
  {
    code: '632300',
    name: '黄南藏族自治州',
    city_level: 2,
    lng: '102.015248',
    lat: '35.519548',
    count: 0
  },
  {
    code: '632500',
    name: '海南藏族自治州',
    city_level: 2,
    lng: '100.620373',
    lat: '36.286437',
    count: 0
  },
  {
    code: '632600',
    name: '果洛藏族自治州',
    city_level: 2,
    lng: '100.244808',
    lat: '34.471431',
    count: 0
  },
  {
    code: '632700',
    name: '玉树藏族自治州',
    city_level: 2,
    lng: '97.091934',
    lat: '33.011674',
    count: 0
  },
  {
    code: '632800',
    name: '海西蒙古族藏族自治州',
    city_level: 2,
    lng: '97.369751',
    lat: '37.377139',
    count: 0
  },
  {
    code: '640100',
    name: '银川市',
    city_level: 2,
    lng: '106.230909',
    lat: '38.487193',
    count: 0
  },
  {
    code: '640200',
    name: '石嘴山市',
    city_level: 2,
    lng: '106.383303',
    lat: '38.983236',
    count: 0
  },
  {
    code: '640300',
    name: '吴忠市',
    city_level: 2,
    lng: '106.198393',
    lat: '37.997460',
    count: 0
  },
  {
    code: '640400',
    name: '固原市',
    city_level: 2,
    lng: '106.242610',
    lat: '36.015855',
    count: 0
  },
  {
    code: '640500',
    name: '中卫市',
    city_level: 2,
    lng: '105.196902',
    lat: '37.499972',
    count: 0
  },
  {
    code: '650100',
    name: '乌鲁木齐市',
    city_level: 2,
    lng: '87.616848',
    lat: '43.825592',
    count: 0
  },
  {
    code: '650200',
    name: '克拉玛依市',
    city_level: 2,
    lng: '84.889207',
    lat: '45.579888',
    count: 0
  },
  {
    code: '650400',
    name: '吐鲁番市',
    city_level: 2,
    lng: '89.189651',
    lat: '42.951382',
    count: 0
  },
  {
    code: '650500',
    name: '哈密市',
    city_level: 2,
    lng: '93.514916',
    lat: '42.818501',
    count: 0
  },
  {
    code: '652300',
    name: '昌吉回族自治州',
    city_level: 2,
    lng: '87.308224',
    lat: '44.011182',
    count: 0
  },
  {
    code: '652700',
    name: '博尔塔拉蒙古自治州',
    city_level: 2,
    lng: '82.066159',
    lat: '44.905588',
    count: 0
  },
  {
    code: '652800',
    name: '巴音郭楞蒙古自治州',
    city_level: 2,
    lng: '86.145298',
    lat: '41.764115',
    count: 0
  },
  {
    code: '652900',
    name: '阿克苏地区',
    city_level: 2,
    lng: '80.260605',
    lat: '41.168779',
    count: 0
  },
  {
    code: '653000',
    name: '克孜勒苏柯尔克孜自治州',
    city_level: 2,
    lng: '76.167819',
    lat: '39.714526',
    count: 0
  },
  {
    code: '653100',
    name: '喀什地区',
    city_level: 2,
    lng: '75.989755',
    lat: '39.470400',
    count: 0
  },
  {
    code: '653200',
    name: '和田地区',
    city_level: 2,
    lng: '79.922211',
    lat: '37.114157',
    count: 0
  },
  {
    code: '654000',
    name: '伊犁哈萨克自治州',
    city_level: 2,
    lng: '81.324136',
    lat: '43.916823',
    count: 0
  },
  {
    code: '654200',
    name: '塔城地区',
    city_level: 2,
    lng: '82.980316',
    lat: '46.745364',
    count: 0
  },
  {
    code: '654300',
    name: '阿勒泰地区',
    city_level: 2,
    lng: '88.141253',
    lat: '47.844924',
    count: 0
  },
  {
    code: '659001',
    name: '石河子市',
    city_level: 3,
    lng: '86.08046',
    lat: '44.305428'
  },
  {
    code: '659002',
    name: '阿拉尔市',
    city_level: 3,
    lng: '81.280527',
    lat: '40.547653'
  },
  {
    code: '659003',
    name: '图木舒克市',
    city_level: 3,
    lng: '79.074089',
    lat: '39.868968'
  },
  {
    code: '659004',
    name: '五家渠市',
    city_level: 3,
    lng: '87.54324',
    lat: '44.166756'
  },
  {
    code: '659005',
    name: '北屯市',
    city_level: 3,
    lng: '87.832595',
    lat: '47.326883'
  },
  {
    code: '659006',
    name: '铁门关市',
    city_level: 3,
    lng: '85.669533',
    lat: '41.8633'
  },
  {
    code: '659007',
    name: '双河市',
    city_level: 3,
    lng: '82.353656',
    lat: '44.840524'
  },
  {
    code: '659008',
    name: '可克达拉市',
    city_level: 3,
    lng: '81.044542',
    lat: '43.944798'
  },
  {
    code: '659009',
    name: '昆玉市',
    city_level: 3,
    lng: '79.291083',
    lat: '37.209642'
  },
  {
    code: '659010',
    name: '胡杨河市',
    city_level: 3,
    lng: '85.997154',
    lat: '46.451208'
  },
  {
    code: '710000',
    name: '台湾省',
    city_level: 2,
    lng: '121.508903',
    lat: '25.044319'
  },
  {
    code: '810000',
    name: '香港特别行政区',
    city_level: 2,
    lng: '114.163825',
    lat: '22.276284'
  },
  {
    code: '820000',
    name: '澳门特别行政区',
    city_level: 2,
    lng: '113.543028',
    lat: '22.186835'
  }
]
const district_list = [
  {
    code: '110101',
    name: '东城区',
    city_level: 3,
    lng: '116.416357',
    lat: '39.928353',
    count: 0
  },
  {
    code: '110102',
    name: '西城区',
    city_level: 3,
    lng: '116.365868',
    lat: '39.912289',
    count: 0
  },
  {
    code: '110105',
    name: '朝阳区',
    city_level: 3,
    lng: '116.601144',
    lat: '39.948574',
    count: 0
  },
  {
    code: '110106',
    name: '丰台区',
    city_level: 3,
    lng: '116.287149',
    lat: '39.858427',
    count: 0
  },
  {
    code: '110107',
    name: '石景山区',
    city_level: 3,
    lng: '116.222982',
    lat: '39.906611',
    count: 0
  },
  {
    code: '110108',
    name: '海淀区',
    city_level: 3,
    lng: '116.329519',
    lat: '39.972134',
    count: 0
  },
  {
    code: '110109',
    name: '门头沟区',
    city_level: 3,
    lng: '116.102009',
    lat: '39.940646',
    count: 0
  },
  {
    code: '110111',
    name: '房山区',
    city_level: 3,
    lng: '116.143267',
    lat: '39.749144',
    count: 0
  },
  {
    code: '110112',
    name: '通州区',
    city_level: 3,
    lng: '121.075087',
    lat: '32.064161',
    count: 0
  },
  {
    code: '110113',
    name: '顺义区',
    city_level: 3,
    lng: '116.654561',
    lat: '40.130347',
    count: 0
  },
  {
    code: '110114',
    name: '昌平区',
    city_level: 3,
    lng: '116.231204',
    lat: '40.220660',
    count: 0
  },
  {
    code: '110115',
    name: '大兴区',
    city_level: 3,
    lng: '116.341014',
    lat: '39.784747',
    count: 0
  },
  {
    code: '110116',
    name: '怀柔区',
    city_level: 3,
    lng: '116.642349',
    lat: '40.315704',
    count: 0
  },
  {
    code: '110117',
    name: '平谷区',
    city_level: 3,
    lng: '117.121383',
    lat: '40.140701',
    count: 0
  },
  {
    code: '110118',
    name: '密云区',
    city_level: 3,
    lng: '116.843177',
    lat: '40.376834',
    count: 0
  },
  {
    code: '110119',
    name: '延庆区',
    city_level: 3,
    lng: '115.974848',
    lat: '40.456951',
    count: 0
  },
  {
    code: '120101',
    name: '和平区',
    city_level: 3,
    lng: '123.395680',
    lat: '41.789450',
    count: 0
  },
  {
    code: '120102',
    name: '河东区',
    city_level: 3,
    lng: '118.402893',
    lat: '35.089917',
    count: 0
  },
  {
    code: '120103',
    name: '河西区',
    city_level: 3,
    lng: '117.223372',
    lat: '39.109563',
    count: 0
  },
  {
    code: '120104',
    name: '南开区',
    city_level: 3,
    lng: '117.150738',
    lat: '39.138203',
    count: 0
  },
  {
    code: '120105',
    name: '河北区',
    city_level: 3,
    lng: '117.196648',
    lat: '39.147869',
    count: 0
  },
  {
    code: '120106',
    name: '红桥区',
    city_level: 3,
    lng: '117.151533',
    lat: '39.167345',
    count: 0
  },
  {
    code: '120110',
    name: '东丽区',
    city_level: 3,
    lng: '117.314324',
    lat: '39.086569',
    count: 0
  },
  {
    code: '120111',
    name: '西青区',
    city_level: 3,
    lng: '117.013949',
    lat: '39.141591',
    count: 0
  },
  {
    code: '120112',
    name: '津南区',
    city_level: 3,
    lng: '117.391976',
    lat: '38.984526',
    count: 0
  },
  {
    code: '120113',
    name: '北辰区',
    city_level: 3,
    lng: '117.139343',
    lat: '39.217817',
    count: 0
  },
  {
    code: '120114',
    name: '武清区',
    city_level: 3,
    lng: '117.044388',
    lat: '39.384119',
    count: 0
  },
  {
    code: '120115',
    name: '宝坻区',
    city_level: 3,
    lng: '117.309863',
    lat: '39.717379',
    count: 0
  },
  {
    code: '120116',
    name: '滨海新区',
    city_level: 3,
    lng: '117.780670',
    lat: '39.246083',
    count: 0
  },
  {
    code: '120117',
    name: '宁河区',
    city_level: 3,
    lng: '117.826625',
    lat: '39.329858',
    count: 0
  },
  {
    code: '120118',
    name: '静海区',
    city_level: 3,
    lng: '116.974130',
    lat: '38.947512',
    count: 0
  },
  {
    code: '120119',
    name: '蓟州区',
    city_level: 3,
    lng: '117.408307',
    lat: '40.046060',
    count: 0
  },
  {
    code: '130102',
    name: '长安区',
    city_level: 3,
    lng: '108.906917',
    lat: '34.159016',
    count: 0
  },
  {
    code: '130104',
    name: '桥西区',
    city_level: 3,
    lng: '114.869407',
    lat: '40.819564',
    count: 0
  },
  {
    code: '130105',
    name: '新华区',
    city_level: 3,
    lng: '113.293999',
    lat: '33.737365',
    count: 0
  },
  {
    code: '130107',
    name: '井陉矿区',
    city_level: 2,
    lng: '114.514859',
    lat: '38.042306',
    count: 0
  },
  {
    code: '130108',
    name: '裕华区',
    city_level: 3,
    lng: '114.531362',
    lat: '38.006453',
    count: 0
  },
  {
    code: '130109',
    name: '藁城区',
    city_level: 3,
    lng: '114.847075',
    lat: '38.021567',
    count: 0
  },
  {
    code: '130110',
    name: '鹿泉区',
    city_level: 3,
    lng: '114.313724',
    lat: '38.085958',
    count: 0
  },
  {
    code: '130111',
    name: '栾城区',
    city_level: 3,
    lng: '114.648318',
    lat: '37.900200',
    count: 0
  },
  {
    code: '130121',
    name: '井陉县',
    city_level: 3,
    lng: '114.145240',
    lat: '38.032148',
    count: 0
  },
  {
    code: '130123',
    name: '正定县',
    city_level: 3,
    lng: '114.570941',
    lat: '38.146445',
    count: 0
  },
  {
    code: '130125',
    name: '行唐县',
    city_level: 3,
    lng: '114.552737',
    lat: '38.438411',
    count: 0
  },
  {
    code: '130126',
    name: '灵寿县',
    city_level: 3,
    lng: '114.376140',
    lat: '38.306110',
    count: 0
  },
  {
    code: '130127',
    name: '高邑县',
    city_level: 3,
    lng: '114.611433',
    lat: '37.615159',
    count: 0
  },
  {
    code: '130128',
    name: '深泽县',
    city_level: 3,
    lng: '115.200910',
    lat: '38.184072',
    count: 0
  },
  {
    code: '130129',
    name: '赞皇县',
    city_level: 3,
    lng: '114.386155',
    lat: '37.665576',
    count: 0
  },
  {
    code: '130130',
    name: '无极县',
    city_level: 3,
    lng: '114.976337',
    lat: '38.179141',
    count: 0
  },
  {
    code: '130131',
    name: '平山县',
    city_level: 3,
    lng: '114.199134',
    lat: '38.247144',
    count: 0
  },
  {
    code: '130132',
    name: '元氏县',
    city_level: 3,
    lng: '114.525580',
    lat: '37.766651',
    count: 0
  },
  {
    code: '130133',
    name: '赵县',
    city_level: 3,
    lng: '114.776187',
    lat: '37.756498',
    count: 0
  },
  {
    code: '130181',
    name: '辛集市',
    city_level: 3,
    lng: '115.218057',
    lat: '37.943315',
    count: 0
  },
  {
    code: '130183',
    name: '晋州市',
    city_level: 3,
    lng: '115.044185',
    lat: '38.033629',
    count: 0
  },
  {
    code: '130184',
    name: '新乐市',
    city_level: 3,
    lng: '114.684014',
    lat: '38.343296',
    count: 0
  },
  {
    code: '130202',
    name: '路南区',
    city_level: 3,
    lng: '118.154354',
    lat: '39.625059',
    count: 0
  },
  {
    code: '130203',
    name: '路北区',
    city_level: 3,
    lng: '118.200692',
    lat: '39.624437',
    count: 0
  },
  {
    code: '130204',
    name: '古冶区',
    city_level: 3,
    lng: '118.447635',
    lat: '39.733578',
    count: 0
  },
  {
    code: '130205',
    name: '开平区',
    city_level: 3,
    lng: '118.261842',
    lat: '39.671001',
    count: 0
  },
  {
    code: '130207',
    name: '丰南区',
    city_level: 3,
    lng: '118.085169',
    lat: '39.576031',
    count: 0
  },
  {
    code: '130208',
    name: '丰润区',
    city_level: 3,
    lng: '118.162216',
    lat: '39.832582',
    count: 0
  },
  {
    code: '130209',
    name: '曹妃甸区',
    city_level: 3,
    lng: '118.460379',
    lat: '39.273070',
    count: 0
  },
  {
    code: '130224',
    name: '滦南县',
    city_level: 3,
    lng: '118.682379',
    lat: '39.518997',
    count: 0
  },
  {
    code: '130225',
    name: '乐亭县',
    city_level: 3,
    lng: '118.912571',
    lat: '39.425608',
    count: 0
  },
  {
    code: '130227',
    name: '迁西县',
    city_level: 3,
    lng: '118.314715',
    lat: '40.141500',
    count: 0
  },
  {
    code: '130229',
    name: '玉田县',
    city_level: 3,
    lng: '117.738658',
    lat: '39.900401',
    count: 0
  },
  {
    code: '130281',
    name: '遵化市',
    city_level: 3,
    lng: '117.965892',
    lat: '40.189202',
    count: 0
  },
  {
    code: '130283',
    name: '迁安市',
    city_level: 3,
    lng: '118.701144',
    lat: '39.999175',
    count: 0
  },
  {
    code: '130284',
    name: '滦州市',
    city_level: 3,
    lng: '118.689775',
    lat: '39.740380',
    count: 0
  },
  {
    code: '130302',
    name: '海港区',
    city_level: 3,
    lng: '119.564962',
    lat: '39.947560',
    count: 0
  },
  {
    code: '130303',
    name: '山海关区',
    city_level: 3,
    lng: '119.775799',
    lat: '39.978849',
    count: 0
  },
  {
    code: '130304',
    name: '北戴河区',
    city_level: 3,
    lng: '119.488914',
    lat: '39.834751',
    count: 0
  },
  {
    code: '130306',
    name: '抚宁区',
    city_level: 3,
    lng: '119.235739',
    lat: '39.889241',
    count: 0
  },
  {
    code: '130321',
    name: '青龙满族自治县',
    city_level: 3,
    lng: '118.949684',
    lat: '40.407578',
    count: 0
  },
  {
    code: '130322',
    name: '昌黎县',
    city_level: 3,
    lng: '119.162694',
    lat: '39.712813',
    count: 0
  },
  {
    code: '130324',
    name: '卢龙县',
    city_level: 3,
    lng: '118.892986',
    lat: '39.891947',
    count: 0
  },
  {
    code: '130402',
    name: '邯山区',
    city_level: 3,
    lng: '114.490431',
    lat: '36.580358',
    count: 0
  },
  {
    code: '130403',
    name: '丛台区',
    city_level: 3,
    lng: '114.492897',
    lat: '36.636410',
    count: 0
  },
  {
    code: '130404',
    name: '复兴区',
    city_level: 3,
    lng: '114.462058',
    lat: '36.639022',
    count: 0
  },
  {
    code: '130406',
    name: '峰峰矿区',
    city_level: 2,
    lng: '114.538961',
    lat: '36.625657',
    count: 0
  },
  {
    code: '130407',
    name: '肥乡区',
    city_level: 3,
    lng: '114.805153',
    lat: '36.555779',
    count: 0
  },
  {
    code: '130408',
    name: '永年区',
    city_level: 3,
    lng: '114.496162',
    lat: '36.776413',
    count: 0
  },
  {
    code: '130423',
    name: '临漳县',
    city_level: 3,
    lng: '114.619544',
    lat: '36.335300',
    count: 0
  },
  {
    code: '130424',
    name: '成安县',
    city_level: 3,
    lng: '114.670032',
    lat: '36.444407',
    count: 0
  },
  {
    code: '130425',
    name: '大名县',
    city_level: 3,
    lng: '115.147814',
    lat: '36.285616',
    count: 0
  },
  {
    code: '130426',
    name: '涉县',
    city_level: 3,
    lng: '113.691401',
    lat: '36.584995',
    count: 0
  },
  {
    code: '130427',
    name: '磁县',
    city_level: 3,
    lng: '114.373947',
    lat: '36.374012',
    count: 0
  },
  {
    code: '130430',
    name: '邱县',
    city_level: 3,
    lng: '115.186792',
    lat: '36.811133',
    count: 0
  },
  {
    code: '130431',
    name: '鸡泽县',
    city_level: 3,
    lng: '114.878299',
    lat: '36.920350',
    count: 0
  },
  {
    code: '130432',
    name: '广平县',
    city_level: 3,
    lng: '114.948607',
    lat: '36.483484',
    count: 0
  },
  {
    code: '130433',
    name: '馆陶县',
    city_level: 3,
    lng: '115.282468',
    lat: '36.547557',
    count: 0
  },
  {
    code: '130434',
    name: '魏县',
    city_level: 3,
    lng: '114.938976',
    lat: '36.359861',
    count: 0
  },
  {
    code: '130435',
    name: '曲周县',
    city_level: 3,
    lng: '114.945131',
    lat: '36.780170',
    count: 0
  },
  {
    code: '130481',
    name: '武安市',
    city_level: 3,
    lng: '114.203697',
    lat: '36.696506',
    count: 0
  },
  {
    code: '130502',
    name: '襄都区',
    city_level: 2,
    lng: '114.504844',
    lat: '37.070589',
    count: 0
  },
  {
    code: '130503',
    name: '信都区',
    city_level: 2,
    lng: '114.504844',
    lat: '37.070589',
    count: 0
  },
  {
    code: '130505',
    name: '任泽区',
    city_level: 2,
    lng: '114.504844',
    lat: '37.070589',
    count: 0
  },
  {
    code: '130506',
    name: '南和区',
    city_level: 2,
    lng: '114.504844',
    lat: '37.070589',
    count: 0
  },
  {
    code: '130522',
    name: '临城县',
    city_level: 3,
    lng: '114.498762',
    lat: '37.444499',
    count: 0
  },
  {
    code: '130523',
    name: '内丘县',
    city_level: 3,
    lng: '114.512128',
    lat: '37.286669',
    count: 0
  },
  {
    code: '130524',
    name: '柏乡县',
    city_level: 3,
    lng: '114.693426',
    lat: '37.482423',
    count: 0
  },
  {
    code: '130525',
    name: '隆尧县',
    city_level: 3,
    lng: '114.770419',
    lat: '37.350173',
    count: 0
  },
  {
    code: '130528',
    name: '宁晋县',
    city_level: 3,
    lng: '114.919301',
    lat: '37.619886',
    count: 0
  },
  {
    code: '130529',
    name: '巨鹿县',
    city_level: 3,
    lng: '115.037478',
    lat: '37.221112',
    count: 0
  },
  {
    code: '130530',
    name: '新河县',
    city_level: 3,
    lng: '115.242014',
    lat: '37.528712',
    count: 0
  },
  {
    code: '130531',
    name: '广宗县',
    city_level: 3,
    lng: '115.142607',
    lat: '37.074660',
    count: 0
  },
  {
    code: '130532',
    name: '平乡县',
    city_level: 3,
    lng: '115.030076',
    lat: '37.063148',
    count: 0
  },
  {
    code: '130533',
    name: '威县',
    city_level: 3,
    lng: '115.266783',
    lat: '36.975321',
    count: 0
  },
  {
    code: '130534',
    name: '清河县',
    city_level: 3,
    lng: '115.667576',
    lat: '37.040087',
    count: 0
  },
  {
    code: '130535',
    name: '临西县',
    city_level: 3,
    lng: '115.501048',
    lat: '36.870813',
    count: 0
  },
  {
    code: '130581',
    name: '南宫市',
    city_level: 3,
    lng: '115.408748',
    lat: '37.359264',
    count: 0
  },
  {
    code: '130582',
    name: '沙河市',
    city_level: 3,
    lng: '114.503335',
    lat: '36.854922',
    count: 0
  },
  {
    code: '130602',
    name: '竞秀区',
    city_level: 3,
    lng: '115.458669',
    lat: '38.877561',
    count: 0
  },
  {
    code: '130606',
    name: '莲池区',
    city_level: 3,
    lng: '115.497342',
    lat: '38.883209',
    count: 0
  },
  {
    code: '130607',
    name: '满城区',
    city_level: 3,
    lng: '115.322351',
    lat: '38.948955',
    count: 0
  },
  {
    code: '130608',
    name: '清苑区',
    city_level: 3,
    lng: '115.489968',
    lat: '38.765041',
    count: 0
  },
  {
    code: '130609',
    name: '徐水区',
    city_level: 3,
    lng: '115.624410',
    lat: '39.026233',
    count: 0
  },
  {
    code: '130623',
    name: '涞水县',
    city_level: 3,
    lng: '115.713905',
    lat: '39.394317',
    count: 0
  },
  {
    code: '130624',
    name: '阜平县',
    city_level: 3,
    lng: '114.195104',
    lat: '38.849152',
    count: 0
  },
  {
    code: '130626',
    name: '定兴县',
    city_level: 3,
    lng: '115.808175',
    lat: '39.263018',
    count: 0
  },
  {
    code: '130627',
    name: '唐县',
    city_level: 3,
    lng: '114.982972',
    lat: '38.748204',
    count: 0
  },
  {
    code: '130628',
    name: '高阳县',
    city_level: 3,
    lng: '115.778965',
    lat: '38.700088',
    count: 0
  },
  {
    code: '130629',
    name: '容城县',
    city_level: 3,
    lng: '115.861657',
    lat: '39.042784',
    count: 0
  },
  {
    code: '130630',
    name: '涞源县',
    city_level: 3,
    lng: '114.694284',
    lat: '39.360247',
    count: 0
  },
  {
    code: '130631',
    name: '望都县',
    city_level: 3,
    lng: '115.154511',
    lat: '38.695736',
    count: 0
  },
  {
    code: '130632',
    name: '安新县',
    city_level: 3,
    lng: '115.935553',
    lat: '38.935364',
    count: 0
  },
  {
    code: '130633',
    name: '易县',
    city_level: 3,
    lng: '115.497509',
    lat: '39.349398',
    count: 0
  },
  {
    code: '130634',
    name: '曲阳县',
    city_level: 3,
    lng: '114.744970',
    lat: '38.622242',
    count: 0
  },
  {
    code: '130635',
    name: '蠡县',
    city_level: 3,
    lng: '115.583855',
    lat: '38.488056',
    count: 0
  },
  {
    code: '130636',
    name: '顺平县',
    city_level: 3,
    lng: '115.135470',
    lat: '38.837487',
    count: 0
  },
  {
    code: '130637',
    name: '博野县',
    city_level: 3,
    lng: '115.464380',
    lat: '38.457364',
    count: 0
  },
  {
    code: '130638',
    name: '雄县',
    city_level: 3,
    lng: '116.108650',
    lat: '38.994550',
    count: 0
  },
  {
    code: '130681',
    name: '涿州市',
    city_level: 3,
    lng: '115.974422',
    lat: '39.485283',
    count: 0
  },
  {
    code: '130682',
    name: '定州市',
    city_level: 3,
    lng: '114.990159',
    lat: '38.516174',
    count: 0
  },
  {
    code: '130683',
    name: '安国市',
    city_level: 3,
    lng: '115.326647',
    lat: '38.418440',
    count: 0
  },
  {
    code: '130684',
    name: '高碑店市',
    city_level: 3,
    lng: '115.873758',
    lat: '39.326521',
    count: 0
  },
  {
    code: '130702',
    name: '桥东区',
    city_level: 3,
    lng: '114.894341',
    lat: '40.788457',
    count: 0
  },
  {
    code: '130703',
    name: '桥西区',
    city_level: 3,
    lng: '114.869407',
    lat: '40.819564',
    count: 0
  },
  {
    code: '130705',
    name: '宣化区',
    city_level: 3,
    lng: '115.099510',
    lat: '40.608726',
    count: 0
  },
  {
    code: '130706',
    name: '下花园区',
    city_level: 3,
    lng: '115.287352',
    lat: '40.502652',
    count: 0
  },
  {
    code: '130708',
    name: '万全区',
    city_level: 3,
    lng: '114.740560',
    lat: '40.766898',
    count: 0
  },
  {
    code: '130709',
    name: '崇礼区',
    city_level: 3,
    lng: '115.282669',
    lat: '40.974676',
    count: 0
  },
  {
    code: '130722',
    name: '张北县',
    city_level: 3,
    lng: '114.720086',
    lat: '41.158557',
    count: 0
  },
  {
    code: '130723',
    name: '康保县',
    city_level: 3,
    lng: '114.600404',
    lat: '41.852368',
    count: 0
  },
  {
    code: '130724',
    name: '沽源县',
    city_level: 3,
    lng: '115.688692',
    lat: '41.669668',
    count: 0
  },
  {
    code: '130725',
    name: '尚义县',
    city_level: 3,
    lng: '113.969619',
    lat: '41.076227',
    count: 0
  },
  {
    code: '130726',
    name: '蔚县',
    city_level: 3,
    lng: '114.588903',
    lat: '39.840843',
    count: 0
  },
  {
    code: '130727',
    name: '阳原县',
    city_level: 3,
    lng: '114.150388',
    lat: '40.103742',
    count: 0
  },
  {
    code: '130728',
    name: '怀安县',
    city_level: 3,
    lng: '114.385791',
    lat: '40.674193',
    count: 0
  },
  {
    code: '130730',
    name: '怀来县',
    city_level: 3,
    lng: '115.517862',
    lat: '40.415343',
    count: 0
  },
  {
    code: '130731',
    name: '涿鹿县',
    city_level: 3,
    lng: '115.205345',
    lat: '40.379563',
    count: 0
  },
  {
    code: '130732',
    name: '赤城县',
    city_level: 3,
    lng: '115.831499',
    lat: '40.912921',
    count: 0
  },
  {
    code: '130802',
    name: '双桥区',
    city_level: 3,
    lng: '117.943348',
    lat: '40.974650',
    count: 0
  },
  {
    code: '130803',
    name: '双滦区',
    city_level: 3,
    lng: '117.799912',
    lat: '40.959196',
    count: 0
  },
  {
    code: '130804',
    name: '鹰手营子矿区',
    city_level: 3,
    lng: '117.661156',
    lat: '40.546956',
    count: 0
  },
  {
    code: '130821',
    name: '承德县',
    city_level: 3,
    lng: '118.173825',
    lat: '40.768238',
    count: 0
  },
  {
    code: '130822',
    name: '兴隆县',
    city_level: 3,
    lng: '117.500558',
    lat: '40.417358',
    count: 0
  },
  {
    code: '130824',
    name: '滦平县',
    city_level: 3,
    lng: '117.332801',
    lat: '40.941482',
    count: 0
  },
  {
    code: '130825',
    name: '隆化县',
    city_level: 3,
    lng: '117.738938',
    lat: '41.313791',
    count: 0
  },
  {
    code: '130826',
    name: '丰宁满族自治县',
    city_level: 3,
    lng: '116.645988',
    lat: '41.208949',
    count: 0
  },
  {
    code: '130827',
    name: '宽城满族自治县',
    city_level: 3,
    lng: '118.485313',
    lat: '40.611391',
    count: 0
  },
  {
    code: '130828',
    name: '围场满族蒙古族自治县',
    city_level: 3,
    lng: '117.760159',
    lat: '41.938529',
    count: 0
  },
  {
    code: '130881',
    name: '平泉市',
    city_level: 3,
    lng: '118.690239',
    lat: '41.005611',
    count: 0
  },
  {
    code: '130902',
    name: '新华区',
    city_level: 3,
    lng: '113.293999',
    lat: '33.737365',
    count: 0
  },
  {
    code: '130903',
    name: '运河区',
    city_level: 3,
    lng: '116.832276',
    lat: '38.310143',
    count: 0
  },
  {
    code: '130921',
    name: '沧县',
    city_level: 3,
    lng: '117.007478',
    lat: '38.219856',
    count: 0
  },
  {
    code: '130922',
    name: '青县',
    city_level: 3,
    lng: '116.804306',
    lat: '38.583021',
    count: 0
  },
  {
    code: '130923',
    name: '东光县',
    city_level: 3,
    lng: '116.537067',
    lat: '37.888248',
    count: 0
  },
  {
    code: '130924',
    name: '海兴县',
    city_level: 3,
    lng: '117.496606',
    lat: '38.141582',
    count: 0
  },
  {
    code: '130925',
    name: '盐山县',
    city_level: 3,
    lng: '117.230603',
    lat: '38.058088',
    count: 0
  },
  {
    code: '130926',
    name: '肃宁县',
    city_level: 3,
    lng: '115.829758',
    lat: '38.422802',
    count: 0
  },
  {
    code: '130927',
    name: '南皮县',
    city_level: 3,
    lng: '116.708104',
    lat: '38.038584',
    count: 0
  },
  {
    code: '130928',
    name: '吴桥县',
    city_level: 3,
    lng: '116.391508',
    lat: '37.627661',
    count: 0
  },
  {
    code: '130929',
    name: '献县',
    city_level: 3,
    lng: '116.122802',
    lat: '38.190144',
    count: 0
  },
  {
    code: '130930',
    name: '孟村回族自治县',
    city_level: 3,
    lng: '117.104298',
    lat: '38.053409',
    count: 0
  },
  {
    code: '130981',
    name: '泊头市',
    city_level: 3,
    lng: '116.578368',
    lat: '38.083437',
    count: 0
  },
  {
    code: '130982',
    name: '任丘市',
    city_level: 3,
    lng: '116.082918',
    lat: '38.683592',
    count: 0
  },
  {
    code: '130983',
    name: '黄骅市',
    city_level: 3,
    lng: '117.330048',
    lat: '38.371383',
    count: 0
  },
  {
    code: '130984',
    name: '河间市',
    city_level: 3,
    lng: '116.099518',
    lat: '38.446624',
    count: 0
  },
  {
    code: '131002',
    name: '安次区',
    city_level: 3,
    lng: '116.694544',
    lat: '39.502569',
    count: 0
  },
  {
    code: '131003',
    name: '广阳区',
    city_level: 3,
    lng: '116.710690',
    lat: '39.522786',
    count: 0
  },
  {
    code: '131022',
    name: '固安县',
    city_level: 3,
    lng: '116.298657',
    lat: '39.438214',
    count: 0
  },
  {
    code: '131023',
    name: '永清县',
    city_level: 3,
    lng: '116.499028',
    lat: '39.321794',
    count: 0
  },
  {
    code: '131024',
    name: '香河县',
    city_level: 3,
    lng: '117.006093',
    lat: '39.761424',
    count: 0
  },
  {
    code: '131025',
    name: '大城县',
    city_level: 3,
    lng: '116.653794',
    lat: '38.705449',
    count: 0
  },
  {
    code: '131026',
    name: '文安县',
    city_level: 3,
    lng: '116.457858',
    lat: '38.873281',
    count: 0
  },
  {
    code: '131028',
    name: '大厂回族自治县',
    city_level: 3,
    lng: '116.989574',
    lat: '39.886547',
    count: 0
  },
  {
    code: '131081',
    name: '霸州市',
    city_level: 3,
    lng: '116.391386',
    lat: '39.125898',
    count: 0
  },
  {
    code: '131082',
    name: '三河市',
    city_level: 3,
    lng: '117.078295',
    lat: '39.982718',
    count: 0
  },
  {
    code: '131102',
    name: '桃城区',
    city_level: 3,
    lng: '115.675422',
    lat: '37.735369',
    count: 0
  },
  {
    code: '131103',
    name: '冀州区',
    city_level: 3,
    lng: '115.579170',
    lat: '37.542786',
    count: 0
  },
  {
    code: '131121',
    name: '枣强县',
    city_level: 3,
    lng: '115.724260',
    lat: '37.513417',
    count: 0
  },
  {
    code: '131122',
    name: '武邑县',
    city_level: 3,
    lng: '115.887655',
    lat: '37.801658',
    count: 0
  },
  {
    code: '131123',
    name: '武强县',
    city_level: 3,
    lng: '115.982461',
    lat: '38.041368',
    count: 0
  },
  {
    code: '131124',
    name: '饶阳县',
    city_level: 3,
    lng: '115.725833',
    lat: '38.235892',
    count: 0
  },
  {
    code: '131125',
    name: '安平县',
    city_level: 3,
    lng: '115.519216',
    lat: '38.234510',
    count: 0
  },
  {
    code: '131126',
    name: '故城县',
    city_level: 3,
    lng: '115.965874',
    lat: '37.347410',
    count: 0
  },
  {
    code: '131127',
    name: '景县',
    city_level: 3,
    lng: '116.270648',
    lat: '37.692290',
    count: 0
  },
  {
    code: '131128',
    name: '阜城县',
    city_level: 3,
    lng: '116.144418',
    lat: '37.868872',
    count: 0
  },
  {
    code: '131182',
    name: '深州市',
    city_level: 3,
    lng: '115.559574',
    lat: '38.001536',
    count: 0
  },
  {
    code: '140105',
    name: '小店区',
    city_level: 3,
    lng: '112.564306',
    lat: '37.742941',
    count: 0
  },
  {
    code: '140106',
    name: '迎泽区',
    city_level: 3,
    lng: '112.563400',
    lat: '37.863451',
    count: 0
  },
  {
    code: '140107',
    name: '杏花岭区',
    city_level: 3,
    lng: '112.570605',
    lat: '37.893955',
    count: 0
  },
  {
    code: '140108',
    name: '尖草坪区',
    city_level: 3,
    lng: '112.486691',
    lat: '37.940387',
    count: 0
  },
  {
    code: '140109',
    name: '万柏林区',
    city_level: 3,
    lng: '112.515748',
    lat: '37.859447',
    count: 0
  },
  {
    code: '140110',
    name: '晋源区',
    city_level: 3,
    lng: '112.477940',
    lat: '37.715193',
    count: 0
  },
  {
    code: '140121',
    name: '清徐县',
    city_level: 3,
    lng: '112.358667',
    lat: '37.607443',
    count: 0
  },
  {
    code: '140122',
    name: '阳曲县',
    city_level: 3,
    lng: '112.672953',
    lat: '38.058489',
    count: 0
  },
  {
    code: '140123',
    name: '娄烦县',
    city_level: 3,
    lng: '111.797083',
    lat: '38.067932',
    count: 0
  },
  {
    code: '140181',
    name: '古交市',
    city_level: 3,
    lng: '112.175875',
    lat: '37.907109',
    count: 0
  },
  {
    code: '140212',
    name: '新荣区',
    city_level: 3,
    lng: '113.140005',
    lat: '40.255866',
    count: 0
  },
  {
    code: '140213',
    name: '平城区',
    city_level: 2,
    lng: '113.300129',
    lat: '40.076762',
    count: 0
  },
  {
    code: '140214',
    name: '云冈区',
    city_level: 3,
    lng: '113.130769',
    lat: '40.109395',
    count: 0
  },
  {
    code: '140215',
    name: '云州区',
    city_level: 2,
    lng: '113.300129',
    lat: '40.076762',
    count: 0
  },
  {
    code: '140221',
    name: '阳高县',
    city_level: 3,
    lng: '113.748945',
    lat: '40.361060',
    count: 0
  },
  {
    code: '140222',
    name: '天镇县',
    city_level: 3,
    lng: '114.090867',
    lat: '40.420237',
    count: 0
  },
  {
    code: '140223',
    name: '广灵县',
    city_level: 3,
    lng: '114.274025',
    lat: '39.761749',
    count: 0
  },
  {
    code: '140224',
    name: '灵丘县',
    city_level: 3,
    lng: '114.234350',
    lat: '39.442406',
    count: 0
  },
  {
    code: '140225',
    name: '浑源县',
    city_level: 3,
    lng: '113.699475',
    lat: '39.693407',
    count: 0
  },
  {
    code: '140226',
    name: '左云县',
    city_level: 3,
    lng: '112.703008',
    lat: '40.013442',
    count: 0
  },
  {
    code: '140302',
    name: '城区',
    city_level: 3,
    lng: '115.365029',
    lat: '22.778699',
    count: 0
  },
  {
    code: '140303',
    name: '矿区',
    city_level: 2,
    lng: '113.580519',
    lat: '37.856971',
    count: 0
  },
  {
    code: '140311',
    name: '郊区',
    city_level: 3,
    lng: '117.807070',
    lat: '30.908927',
    count: 0
  },
  {
    code: '140321',
    name: '平定县',
    city_level: 3,
    lng: '113.657841',
    lat: '37.786653',
    count: 0
  },
  {
    code: '140322',
    name: '盂县',
    city_level: 3,
    lng: '113.412330',
    lat: '38.085619',
    count: 0
  },
  {
    code: '140403',
    name: '潞州区',
    city_level: 2,
    lng: '113.116255',
    lat: '36.195386',
    count: 0
  },
  {
    code: '140404',
    name: '上党区',
    city_level: 3,
    lng: '113.111210',
    lat: '36.187185',
    count: 0
  },
  {
    code: '140405',
    name: '屯留区',
    city_level: 2,
    lng: '113.116255',
    lat: '36.195386',
    count: 0
  },
  {
    code: '140406',
    name: '潞城区',
    city_level: 2,
    lng: '113.116255',
    lat: '36.195386',
    count: 0
  },
  {
    code: '140423',
    name: '襄垣县',
    city_level: 3,
    lng: '113.051491',
    lat: '36.535817',
    count: 0
  },
  {
    code: '140425',
    name: '平顺县',
    city_level: 3,
    lng: '113.435961',
    lat: '36.200179',
    count: 0
  },
  {
    code: '140426',
    name: '黎城县',
    city_level: 3,
    lng: '113.386544',
    lat: '36.503621',
    count: 0
  },
  {
    code: '140427',
    name: '壶关县',
    city_level: 3,
    lng: '113.207049',
    lat: '36.115449',
    count: 0
  },
  {
    code: '140428',
    name: '长子县',
    city_level: 3,
    lng: '112.877900',
    lat: '36.122334',
    count: 0
  },
  {
    code: '140429',
    name: '武乡县',
    city_level: 3,
    lng: '112.864562',
    lat: '36.837625',
    count: 0
  },
  {
    code: '140430',
    name: '沁县',
    city_level: 3,
    lng: '112.699226',
    lat: '36.756064',
    count: 0
  },
  {
    code: '140431',
    name: '沁源县',
    city_level: 3,
    lng: '112.337446',
    lat: '36.500200',
    count: 0
  },
  {
    code: '140502',
    name: '城区',
    city_level: 3,
    lng: '115.365029',
    lat: '22.778699',
    count: 0
  },
  {
    code: '140521',
    name: '沁水县',
    city_level: 3,
    lng: '112.186739',
    lat: '35.690141',
    count: 0
  },
  {
    code: '140522',
    name: '阳城县',
    city_level: 3,
    lng: '112.414738',
    lat: '35.486029',
    count: 0
  },
  {
    code: '140524',
    name: '陵川县',
    city_level: 3,
    lng: '113.280688',
    lat: '35.775685',
    count: 0
  },
  {
    code: '140525',
    name: '泽州县',
    city_level: 3,
    lng: '112.899137',
    lat: '35.617221',
    count: 0
  },
  {
    code: '140581',
    name: '高平市',
    city_level: 3,
    lng: '112.923920',
    lat: '35.797997',
    count: 0
  },
  {
    code: '140602',
    name: '朔城区',
    city_level: 3,
    lng: '112.432250',
    lat: '39.318940',
    count: 0
  },
  {
    code: '140603',
    name: '平鲁区',
    city_level: 3,
    lng: '112.288331',
    lat: '39.512155',
    count: 0
  },
  {
    code: '140621',
    name: '山阴县',
    city_level: 3,
    lng: '112.816600',
    lat: '39.526227',
    count: 0
  },
  {
    code: '140622',
    name: '应县',
    city_level: 3,
    lng: '113.191099',
    lat: '39.554247',
    count: 0
  },
  {
    code: '140623',
    name: '右玉县',
    city_level: 3,
    lng: '112.466989',
    lat: '39.989064',
    count: 0
  },
  {
    code: '140681',
    name: '怀仁市',
    city_level: 3,
    lng: '113.118504',
    lat: '39.810401',
    count: 0
  },
  {
    code: '140702',
    name: '榆次区',
    city_level: 3,
    lng: '112.708241',
    lat: '37.697792',
    count: 0
  },
  {
    code: '140703',
    name: '太谷区',
    city_level: 2,
    lng: '112.752694',
    lat: '37.687024',
    count: 0
  },
  {
    code: '140721',
    name: '榆社县',
    city_level: 3,
    lng: '112.975287',
    lat: '37.070788',
    count: 0
  },
  {
    code: '140722',
    name: '左权县',
    city_level: 3,
    lng: '113.379372',
    lat: '37.082681',
    count: 0
  },
  {
    code: '140723',
    name: '和顺县',
    city_level: 3,
    lng: '113.570438',
    lat: '37.329664',
    count: 0
  },
  {
    code: '140724',
    name: '昔阳县',
    city_level: 3,
    lng: '113.706875',
    lat: '37.611210',
    count: 0
  },
  {
    code: '140725',
    name: '寿阳县',
    city_level: 3,
    lng: '113.176434',
    lat: '37.895325',
    count: 0
  },
  {
    code: '140727',
    name: '祁县',
    city_level: 3,
    lng: '112.335297',
    lat: '37.358317',
    count: 0
  },
  {
    code: '140728',
    name: '平遥县',
    city_level: 3,
    lng: '112.176273',
    lat: '37.189559',
    count: 0
  },
  {
    code: '140729',
    name: '灵石县',
    city_level: 3,
    lng: '111.778685',
    lat: '36.847860',
    count: 0
  },
  {
    code: '140781',
    name: '介休市',
    city_level: 3,
    lng: '111.916712',
    lat: '37.026945',
    count: 0
  },
  {
    code: '140802',
    name: '盐湖区',
    city_level: 3,
    lng: '110.998272',
    lat: '35.015101',
    count: 0
  },
  {
    code: '140821',
    name: '临猗县',
    city_level: 3,
    lng: '110.774547',
    lat: '35.144277',
    count: 0
  },
  {
    code: '140822',
    name: '万荣县',
    city_level: 3,
    lng: '110.838024',
    lat: '35.415254',
    count: 0
  },
  {
    code: '140823',
    name: '闻喜县',
    city_level: 3,
    lng: '111.224720',
    lat: '35.356644',
    count: 0
  },
  {
    code: '140824',
    name: '稷山县',
    city_level: 3,
    lng: '110.983333',
    lat: '35.604025',
    count: 0
  },
  {
    code: '140825',
    name: '新绛县',
    city_level: 3,
    lng: '111.224778',
    lat: '35.616288',
    count: 0
  },
  {
    code: '140826',
    name: '绛县',
    city_level: 3,
    lng: '111.568236',
    lat: '35.491190',
    count: 0
  },
  {
    code: '140827',
    name: '垣曲县',
    city_level: 3,
    lng: '111.669917',
    lat: '35.297620',
    count: 0
  },
  {
    code: '140828',
    name: '夏县',
    city_level: 3,
    lng: '111.220456',
    lat: '35.141363',
    count: 0
  },
  {
    code: '140829',
    name: '平陆县',
    city_level: 3,
    lng: '111.194133',
    lat: '34.829260',
    count: 0
  },
  {
    code: '140830',
    name: '芮城县',
    city_level: 3,
    lng: '110.694369',
    lat: '34.693580',
    count: 0
  },
  {
    code: '140881',
    name: '永济市',
    city_level: 3,
    lng: '110.447549',
    lat: '34.867050',
    count: 0
  },
  {
    code: '140882',
    name: '河津市',
    city_level: 3,
    lng: '110.712063',
    lat: '35.596383',
    count: 0
  },
  {
    code: '140902',
    name: '忻府区',
    city_level: 3,
    lng: '112.746046',
    lat: '38.404243',
    count: 0
  },
  {
    code: '140921',
    name: '定襄县',
    city_level: 3,
    lng: '112.957215',
    lat: '38.473548',
    count: 0
  },
  {
    code: '140922',
    name: '五台县',
    city_level: 3,
    lng: '113.255309',
    lat: '38.728315',
    count: 0
  },
  {
    code: '140923',
    name: '代县',
    city_level: 3,
    lng: '112.960282',
    lat: '39.066917',
    count: 0
  },
  {
    code: '140924',
    name: '繁峙县',
    city_level: 3,
    lng: '113.265564',
    lat: '39.188811',
    count: 0
  },
  {
    code: '140925',
    name: '宁武县',
    city_level: 3,
    lng: '112.304722',
    lat: '39.001524',
    count: 0
  },
  {
    code: '140926',
    name: '静乐县',
    city_level: 3,
    lng: '111.939440',
    lat: '38.359036',
    count: 0
  },
  {
    code: '140927',
    name: '神池县',
    city_level: 3,
    lng: '112.211297',
    lat: '39.090553',
    count: 0
  },
  {
    code: '140928',
    name: '五寨县',
    city_level: 3,
    lng: '111.846905',
    lat: '38.910726',
    count: 0
  },
  {
    code: '140929',
    name: '岢岚县',
    city_level: 3,
    lng: '111.572850',
    lat: '38.704180',
    count: 0
  },
  {
    code: '140930',
    name: '河曲县',
    city_level: 3,
    lng: '111.138472',
    lat: '39.384482',
    count: 0
  },
  {
    code: '140931',
    name: '保德县',
    city_level: 3,
    lng: '111.086564',
    lat: '39.022488',
    count: 0
  },
  {
    code: '140932',
    name: '偏关县',
    city_level: 3,
    lng: '111.508831',
    lat: '39.436306',
    count: 0
  },
  {
    code: '140981',
    name: '原平市',
    city_level: 3,
    lng: '112.711059',
    lat: '38.731402',
    count: 0
  },
  {
    code: '141002',
    name: '尧都区',
    city_level: 3,
    lng: '111.579554',
    lat: '36.078841',
    count: 0
  },
  {
    code: '141021',
    name: '曲沃县',
    city_level: 3,
    lng: '111.475861',
    lat: '35.641087',
    count: 0
  },
  {
    code: '141022',
    name: '翼城县',
    city_level: 3,
    lng: '111.718951',
    lat: '35.738576',
    count: 0
  },
  {
    code: '141023',
    name: '襄汾县',
    city_level: 3,
    lng: '111.441725',
    lat: '35.876293',
    count: 0
  },
  {
    code: '141024',
    name: '洪洞县',
    city_level: 3,
    lng: '111.674966',
    lat: '36.253748',
    count: 0
  },
  {
    code: '141025',
    name: '古县',
    city_level: 3,
    lng: '111.920466',
    lat: '36.266914',
    count: 0
  },
  {
    code: '141026',
    name: '安泽县',
    city_level: 3,
    lng: '112.250144',
    lat: '36.147787',
    count: 0
  },
  {
    code: '141027',
    name: '浮山县',
    city_level: 3,
    lng: '111.848883',
    lat: '35.968124',
    count: 0
  },
  {
    code: '141028',
    name: '吉县',
    city_level: 3,
    lng: '110.681763',
    lat: '36.098188',
    count: 0
  },
  {
    code: '141029',
    name: '乡宁县',
    city_level: 3,
    lng: '110.847021',
    lat: '35.970389',
    count: 0
  },
  {
    code: '141030',
    name: '大宁县',
    city_level: 3,
    lng: '110.752903',
    lat: '36.465133',
    count: 0
  },
  {
    code: '141031',
    name: '隰县',
    city_level: 3,
    lng: '110.940638',
    lat: '36.693331',
    count: 0
  },
  {
    code: '141032',
    name: '永和县',
    city_level: 3,
    lng: '110.632007',
    lat: '36.759507',
    count: 0
  },
  {
    code: '141033',
    name: '蒲县',
    city_level: 3,
    lng: '111.096439',
    lat: '36.411827',
    count: 0
  },
  {
    code: '141034',
    name: '汾西县',
    city_level: 3,
    lng: '111.563951',
    lat: '36.652854',
    count: 0
  },
  {
    code: '141081',
    name: '侯马市',
    city_level: 3,
    lng: '111.372002',
    lat: '35.619105',
    count: 0
  },
  {
    code: '141082',
    name: '霍州市',
    city_level: 3,
    lng: '111.755398',
    lat: '36.568931',
    count: 0
  },
  {
    code: '141102',
    name: '离石区',
    city_level: 3,
    lng: '111.150733',
    lat: '37.517641',
    count: 0
  },
  {
    code: '141121',
    name: '文水县',
    city_level: 3,
    lng: '112.028866',
    lat: '37.438102',
    count: 0
  },
  {
    code: '141122',
    name: '交城县',
    city_level: 3,
    lng: '112.155841',
    lat: '37.551970',
    count: 0
  },
  {
    code: '141123',
    name: '兴县',
    city_level: 3,
    lng: '111.127668',
    lat: '38.462390',
    count: 0
  },
  {
    code: '141124',
    name: '临县',
    city_level: 3,
    lng: '110.992094',
    lat: '37.950758',
    count: 0
  },
  {
    code: '141125',
    name: '柳林县',
    city_level: 3,
    lng: '110.889071',
    lat: '37.429832',
    count: 0
  },
  {
    code: '141126',
    name: '石楼县',
    city_level: 3,
    lng: '110.834561',
    lat: '36.997412',
    count: 0
  },
  {
    code: '141127',
    name: '岚县',
    city_level: 3,
    lng: '111.671917',
    lat: '38.279299',
    count: 0
  },
  {
    code: '141128',
    name: '方山县',
    city_level: 3,
    lng: '111.244098',
    lat: '37.894631',
    count: 0
  },
  {
    code: '141129',
    name: '中阳县',
    city_level: 3,
    lng: '111.179657',
    lat: '37.357058',
    count: 0
  },
  {
    code: '141130',
    name: '交口县',
    city_level: 3,
    lng: '111.181151',
    lat: '36.982186',
    count: 0
  },
  {
    code: '141181',
    name: '孝义市',
    city_level: 3,
    lng: '111.778818',
    lat: '37.146294',
    count: 0
  },
  {
    code: '141182',
    name: '汾阳市',
    city_level: 3,
    lng: '111.769894',
    lat: '37.261564',
    count: 0
  },
  {
    code: '150102',
    name: '新城区',
    city_level: 3,
    lng: '108.960747',
    lat: '34.266451',
    count: 0
  },
  {
    code: '150103',
    name: '回民区',
    city_level: 3,
    lng: '111.623692',
    lat: '40.808608',
    count: 0
  },
  {
    code: '150104',
    name: '玉泉区',
    city_level: 3,
    lng: '111.673881',
    lat: '40.753655',
    count: 0
  },
  {
    code: '150105',
    name: '赛罕区',
    city_level: 3,
    lng: '111.701857',
    lat: '40.792097',
    count: 0
  },
  {
    code: '150121',
    name: '土默特左旗',
    city_level: 3,
    lng: '111.163902',
    lat: '40.729573',
    count: 0
  },
  {
    code: '150122',
    name: '托克托县',
    city_level: 3,
    lng: '111.194313',
    lat: '40.277431',
    count: 0
  },
  {
    code: '150123',
    name: '和林格尔县',
    city_level: 3,
    lng: '111.821843',
    lat: '40.378787',
    count: 0
  },
  {
    code: '150124',
    name: '清水河县',
    city_level: 3,
    lng: '111.647609',
    lat: '39.921095',
    count: 0
  },
  {
    code: '150125',
    name: '武川县',
    city_level: 3,
    lng: '111.451303',
    lat: '41.096471',
    count: 0
  },
  {
    code: '150202',
    name: '东河区',
    city_level: 3,
    lng: '110.044142',
    lat: '40.575948',
    count: 0
  },
  {
    code: '150203',
    name: '昆都仑区',
    city_level: 3,
    lng: '109.838178',
    lat: '40.642236',
    count: 0
  },
  {
    code: '150204',
    name: '青山区',
    city_level: 3,
    lng: '114.385539',
    lat: '30.639630',
    count: 0
  },
  {
    code: '150205',
    name: '石拐区',
    city_level: 3,
    lng: '110.060686',
    lat: '40.676645',
    count: 0
  },
  {
    code: '150206',
    name: '白云鄂博矿区',
    city_level: 2,
    lng: '109.840347',
    lat: '40.657449',
    count: 0
  },
  {
    code: '150207',
    name: '九原区',
    city_level: 3,
    lng: '109.968122',
    lat: '40.600581',
    count: 0
  },
  {
    code: '150221',
    name: '土默特右旗',
    city_level: 3,
    lng: '110.524263',
    lat: '40.569426',
    count: 0
  },
  {
    code: '150222',
    name: '固阳县',
    city_level: 3,
    lng: '110.060514',
    lat: '41.034106',
    count: 0
  },
  {
    code: '150223',
    name: '达尔罕茂明安联合旗',
    city_level: 3,
    lng: '110.432626',
    lat: '41.698992',
    count: 0
  },
  {
    code: '150302',
    name: '海勃湾区',
    city_level: 3,
    lng: '106.822779',
    lat: '39.691156',
    count: 0
  },
  {
    code: '150303',
    name: '海南区',
    city_level: 3,
    lng: '106.891424',
    lat: '39.441364',
    count: 0
  },
  {
    code: '150304',
    name: '乌达区',
    city_level: 3,
    lng: '106.726099',
    lat: '39.505925',
    count: 0
  },
  {
    code: '150402',
    name: '红山区',
    city_level: 3,
    lng: '118.967296',
    lat: '42.271963',
    count: 0
  },
  {
    code: '150403',
    name: '元宝山区',
    city_level: 3,
    lng: '119.288611',
    lat: '42.038902',
    count: 0
  },
  {
    code: '150404',
    name: '松山区',
    city_level: 3,
    lng: '118.933235',
    lat: '42.284008',
    count: 0
  },
  {
    code: '150421',
    name: '阿鲁科尔沁旗',
    city_level: 3,
    lng: '120.065700',
    lat: '43.872299',
    count: 0
  },
  {
    code: '150422',
    name: '巴林左旗',
    city_level: 3,
    lng: '119.379490',
    lat: '43.971126',
    count: 0
  },
  {
    code: '150423',
    name: '巴林右旗',
    city_level: 3,
    lng: '118.665180',
    lat: '43.534414',
    count: 0
  },
  {
    code: '150424',
    name: '林西县',
    city_level: 3,
    lng: '118.055450',
    lat: '43.618120',
    count: 0
  },
  {
    code: '150425',
    name: '克什克腾旗',
    city_level: 3,
    lng: '117.545798',
    lat: '43.264989',
    count: 0
  },
  {
    code: '150426',
    name: '翁牛特旗',
    city_level: 3,
    lng: '119.006580',
    lat: '42.936188',
    count: 0
  },
  {
    code: '150428',
    name: '喀喇沁旗',
    city_level: 3,
    lng: '118.701938',
    lat: '41.927364',
    count: 0
  },
  {
    code: '150429',
    name: '宁城县',
    city_level: 3,
    lng: '118.557602',
    lat: '41.415477',
    count: 0
  },
  {
    code: '150430',
    name: '敖汉旗',
    city_level: 3,
    lng: '119.921604',
    lat: '42.290782',
    count: 0
  },
  {
    code: '150502',
    name: '科尔沁区',
    city_level: 3,
    lng: '122.255675',
    lat: '43.623077',
    count: 0
  },
  {
    code: '150521',
    name: '科尔沁左翼中旗',
    city_level: 3,
    lng: '123.312265',
    lat: '44.126626',
    count: 0
  },
  {
    code: '150522',
    name: '科尔沁左翼后旗',
    city_level: 3,
    lng: '122.356749',
    lat: '42.935159',
    count: 0
  },
  {
    code: '150523',
    name: '开鲁县',
    city_level: 3,
    lng: '121.319309',
    lat: '43.601244',
    count: 0
  },
  {
    code: '150524',
    name: '库伦旗',
    city_level: 3,
    lng: '121.810701',
    lat: '42.735657',
    count: 0
  },
  {
    code: '150525',
    name: '奈曼旗',
    city_level: 3,
    lng: '120.662419',
    lat: '42.845455',
    count: 0
  },
  {
    code: '150526',
    name: '扎鲁特旗',
    city_level: 3,
    lng: '120.911676',
    lat: '44.556389',
    count: 0
  },
  {
    code: '150581',
    name: '霍林郭勒市',
    city_level: 3,
    lng: '119.663534',
    lat: '45.531726',
    count: 0
  },
  {
    code: '150602',
    name: '东胜区',
    city_level: 3,
    lng: '109.963339',
    lat: '39.822507',
    count: 0
  },
  {
    code: '150603',
    name: '康巴什区',
    city_level: 2,
    lng: '109.781327',
    lat: '39.608266',
    count: 0
  },
  {
    code: '150621',
    name: '达拉特旗',
    city_level: 3,
    lng: '110.033833',
    lat: '40.412438',
    count: 0
  },
  {
    code: '150622',
    name: '准格尔旗',
    city_level: 3,
    lng: '111.240171',
    lat: '39.864362',
    count: 0
  },
  {
    code: '150623',
    name: '鄂托克前旗',
    city_level: 3,
    lng: '107.477515',
    lat: '38.182362',
    count: 0
  },
  {
    code: '150624',
    name: '鄂托克旗',
    city_level: 3,
    lng: '107.976161',
    lat: '39.089650',
    count: 0
  },
  {
    code: '150625',
    name: '杭锦旗',
    city_level: 3,
    lng: '108.736208',
    lat: '39.833309',
    count: 0
  },
  {
    code: '150626',
    name: '乌审旗',
    city_level: 3,
    lng: '108.817607',
    lat: '38.604136',
    count: 0
  },
  {
    code: '150627',
    name: '伊金霍洛旗',
    city_level: 3,
    lng: '109.747740',
    lat: '39.564660',
    count: 0
  },
  {
    code: '150702',
    name: '海拉尔区',
    city_level: 3,
    lng: '119.736279',
    lat: '49.212189',
    count: 0
  },
  {
    code: '150703',
    name: '扎赉诺尔区',
    city_level: 3,
    lng: '117.670248',
    lat: '49.510375',
    count: 0
  },
  {
    code: '150721',
    name: '阿荣旗',
    city_level: 3,
    lng: '123.459050',
    lat: '48.126585',
    count: 0
  },
  {
    code: '150722',
    name: '莫力达瓦达斡尔族自治旗',
    city_level: 3,
    lng: '124.519023',
    lat: '48.477729',
    count: 0
  },
  {
    code: '150723',
    name: '鄂伦春自治旗',
    city_level: 3,
    lng: '123.726201',
    lat: '50.591842',
    count: 0
  },
  {
    code: '150724',
    name: '鄂温克族自治旗',
    city_level: 3,
    lng: '119.755213',
    lat: '49.146588',
    count: 0
  },
  {
    code: '150725',
    name: '陈巴尔虎旗',
    city_level: 3,
    lng: '119.424026',
    lat: '49.328916',
    count: 0
  },
  {
    code: '150726',
    name: '新巴尔虎左旗',
    city_level: 3,
    lng: '118.269820',
    lat: '48.218241',
    count: 0
  },
  {
    code: '150727',
    name: '新巴尔虎右旗',
    city_level: 3,
    lng: '116.823690',
    lat: '48.672101',
    count: 0
  },
  {
    code: '150781',
    name: '满洲里市',
    city_level: 3,
    lng: '117.378530',
    lat: '49.597841',
    count: 0
  },
  {
    code: '150782',
    name: '牙克石市',
    city_level: 3,
    lng: '120.735775',
    lat: '49.293222',
    count: 0
  },
  {
    code: '150783',
    name: '扎兰屯市',
    city_level: 3,
    lng: '122.737467',
    lat: '48.013733',
    count: 0
  },
  {
    code: '150784',
    name: '额尔古纳市',
    city_level: 3,
    lng: '120.180506',
    lat: '50.243102',
    count: 0
  },
  {
    code: '150785',
    name: '根河市',
    city_level: 3,
    lng: '121.520388',
    lat: '50.780345',
    count: 0
  },
  {
    code: '150802',
    name: '临河区',
    city_level: 3,
    lng: '107.363919',
    lat: '40.751187',
    count: 0
  },
  {
    code: '150821',
    name: '五原县',
    city_level: 3,
    lng: '108.267562',
    lat: '41.088422',
    count: 0
  },
  {
    code: '150822',
    name: '磴口县',
    city_level: 3,
    lng: '107.008248',
    lat: '40.330524',
    count: 0
  },
  {
    code: '150823',
    name: '乌拉特前旗',
    city_level: 3,
    lng: '108.652119',
    lat: '40.737030',
    count: 0
  },
  {
    code: '150824',
    name: '乌拉特中旗',
    city_level: 3,
    lng: '108.513645',
    lat: '41.587732',
    count: 0
  },
  {
    code: '150825',
    name: '乌拉特后旗',
    city_level: 3,
    lng: '107.074621',
    lat: '41.084283',
    count: 0
  },
  {
    code: '150826',
    name: '杭锦后旗',
    city_level: 3,
    lng: '107.150909',
    lat: '40.885896',
    count: 0
  },
  {
    code: '150902',
    name: '集宁区',
    city_level: 3,
    lng: '113.123779',
    lat: '40.990689',
    count: 0
  },
  {
    code: '150921',
    name: '卓资县',
    city_level: 3,
    lng: '112.577528',
    lat: '40.894692',
    count: 0
  },
  {
    code: '150922',
    name: '化德县',
    city_level: 3,
    lng: '114.010438',
    lat: '41.904560',
    count: 0
  },
  {
    code: '150923',
    name: '商都县',
    city_level: 3,
    lng: '113.577816',
    lat: '41.562113',
    count: 0
  },
  {
    code: '150924',
    name: '兴和县',
    city_level: 3,
    lng: '113.834173',
    lat: '40.872301',
    count: 0
  },
  {
    code: '150925',
    name: '凉城县',
    city_level: 3,
    lng: '112.503971',
    lat: '40.531555',
    count: 0
  },
  {
    code: '150926',
    name: '察哈尔右翼前旗',
    city_level: 3,
    lng: '113.214733',
    lat: '40.785631',
    count: 0
  },
  {
    code: '150927',
    name: '察哈尔右翼中旗',
    city_level: 3,
    lng: '112.635577',
    lat: '41.277462',
    count: 0
  },
  {
    code: '150928',
    name: '察哈尔右翼后旗',
    city_level: 3,
    lng: '113.191035',
    lat: '41.436069',
    count: 0
  },
  {
    code: '150929',
    name: '四子王旗',
    city_level: 3,
    lng: '111.706618',
    lat: '41.533462',
    count: 0
  },
  {
    code: '150981',
    name: '丰镇市',
    city_level: 3,
    lng: '113.109892',
    lat: '40.436983',
    count: 0
  },
  {
    code: '152201',
    name: '乌兰浩特市',
    city_level: 3,
    lng: '122.093123',
    lat: '46.072732',
    count: 0
  },
  {
    code: '152202',
    name: '阿尔山市',
    city_level: 3,
    lng: '119.943575',
    lat: '47.177440',
    count: 0
  },
  {
    code: '152221',
    name: '科尔沁右翼前旗',
    city_level: 3,
    lng: '121.952550',
    lat: '46.079810',
    count: 0
  },
  {
    code: '152222',
    name: '科尔沁右翼中旗',
    city_level: 3,
    lng: '121.476530',
    lat: '45.060837',
    count: 0
  },
  {
    code: '152223',
    name: '扎赉特旗',
    city_level: 3,
    lng: '122.899656',
    lat: '46.723237',
    count: 0
  },
  {
    code: '152224',
    name: '突泉县',
    city_level: 3,
    lng: '121.593799',
    lat: '45.381930',
    count: 0
  },
  {
    code: '152501',
    name: '二连浩特市',
    city_level: 3,
    lng: '111.977943',
    lat: '43.653170',
    count: 0
  },
  {
    code: '152502',
    name: '锡林浩特市',
    city_level: 3,
    lng: '116.086032',
    lat: '43.933411',
    count: 0
  },
  {
    code: '152522',
    name: '阿巴嘎旗',
    city_level: 3,
    lng: '114.950248',
    lat: '44.022995',
    count: 0
  },
  {
    code: '152523',
    name: '苏尼特左旗',
    city_level: 3,
    lng: '113.667248',
    lat: '43.859880',
    count: 0
  },
  {
    code: '152524',
    name: '苏尼特右旗',
    city_level: 3,
    lng: '112.641783',
    lat: '42.742892',
    count: 0
  },
  {
    code: '152525',
    name: '东乌珠穆沁旗',
    city_level: 3,
    lng: '116.974494',
    lat: '45.498221',
    count: 0
  },
  {
    code: '152526',
    name: '西乌珠穆沁旗',
    city_level: 3,
    lng: '117.608911',
    lat: '44.587882',
    count: 0
  },
  {
    code: '152527',
    name: '太仆寺旗',
    city_level: 3,
    lng: '115.282986',
    lat: '41.877136',
    count: 0
  },
  {
    code: '152528',
    name: '镶黄旗',
    city_level: 3,
    lng: '113.847287',
    lat: '42.232371',
    count: 0
  },
  {
    code: '152529',
    name: '正镶白旗',
    city_level: 3,
    lng: '115.029849',
    lat: '42.287471',
    count: 0
  },
  {
    code: '152530',
    name: '正蓝旗',
    city_level: 3,
    lng: '115.992470',
    lat: '42.241638',
    count: 0
  },
  {
    code: '152531',
    name: '多伦县',
    city_level: 3,
    lng: '116.485556',
    lat: '42.203591',
    count: 0
  },
  {
    code: '152921',
    name: '阿拉善左旗',
    city_level: 3,
    lng: '105.666293',
    lat: '38.833411',
    count: 0
  },
  {
    code: '152922',
    name: '阿拉善右旗',
    city_level: 3,
    lng: '101.666917',
    lat: '39.216186',
    count: 0
  },
  {
    code: '152923',
    name: '额济纳旗',
    city_level: 3,
    lng: '101.055590',
    lat: '41.954347',
    count: 0
  },
  {
    code: '210102',
    name: '和平区',
    city_level: 3,
    lng: '123.395680',
    lat: '41.789450',
    count: 0
  },
  {
    code: '210103',
    name: '沈河区',
    city_level: 3,
    lng: '123.458897',
    lat: '41.795655',
    count: 0
  },
  {
    code: '210104',
    name: '大东区',
    city_level: 3,
    lng: '123.508858',
    lat: '41.813170',
    count: 0
  },
  {
    code: '210105',
    name: '皇姑区',
    city_level: 3,
    lng: '123.441970',
    lat: '41.824796',
    count: 0
  },
  {
    code: '210106',
    name: '铁西区',
    city_level: 3,
    lng: '124.345722',
    lat: '43.146155',
    count: 0
  },
  {
    code: '210111',
    name: '苏家屯区',
    city_level: 3,
    lng: '123.344031',
    lat: '41.664757',
    count: 0
  },
  {
    code: '210112',
    name: '浑南区',
    city_level: 3,
    lng: '123.449715',
    lat: '41.714914',
    count: 0
  },
  {
    code: '210113',
    name: '沈北新区',
    city_level: 3,
    lng: '123.583197',
    lat: '41.912487',
    count: 0
  },
  {
    code: '210114',
    name: '于洪区',
    city_level: 3,
    lng: '123.308136',
    lat: '41.793743',
    count: 0
  },
  {
    code: '210115',
    name: '辽中区',
    city_level: 3,
    lng: '122.765409',
    lat: '41.516827',
    count: 0
  },
  {
    code: '210123',
    name: '康平县',
    city_level: 3,
    lng: '123.355701',
    lat: '42.741005',
    count: 0
  },
  {
    code: '210124',
    name: '法库县',
    city_level: 3,
    lng: '123.440495',
    lat: '42.500730',
    count: 0
  },
  {
    code: '210181',
    name: '新民市',
    city_level: 3,
    lng: '122.725537',
    lat: '41.932648',
    count: 0
  },
  {
    code: '210202',
    name: '中山区',
    city_level: 3,
    lng: '121.644927',
    lat: '38.918574',
    count: 0
  },
  {
    code: '210203',
    name: '西岗区',
    city_level: 3,
    lng: '121.612325',
    lat: '38.914687',
    count: 0
  },
  {
    code: '210204',
    name: '沙河口区',
    city_level: 3,
    lng: '121.594200',
    lat: '38.904808',
    count: 0
  },
  {
    code: '210211',
    name: '甘井子区',
    city_level: 3,
    lng: '121.525461',
    lat: '38.953351',
    count: 0
  },
  {
    code: '210212',
    name: '旅顺口区',
    city_level: 3,
    lng: '121.261953',
    lat: '38.851705',
    count: 0
  },
  {
    code: '210213',
    name: '金州区',
    city_level: 3,
    lng: '121.782769',
    lat: '39.050460',
    count: 0
  },
  {
    code: '210214',
    name: '普兰店区',
    city_level: 3,
    lng: '122.261770',
    lat: '39.741886',
    count: 0
  },
  {
    code: '210224',
    name: '长海县',
    city_level: 3,
    lng: '122.588494',
    lat: '39.272728',
    count: 0
  },
  {
    code: '210281',
    name: '瓦房店市',
    city_level: 3,
    lng: '121.979603',
    lat: '39.627114',
    count: 0
  },
  {
    code: '210283',
    name: '庄河市',
    city_level: 3,
    lng: '122.967328',
    lat: '39.680811',
    count: 0
  },
  {
    code: '210302',
    name: '铁东区',
    city_level: 3,
    lng: '124.409622',
    lat: '43.162048',
    count: 0
  },
  {
    code: '210303',
    name: '铁西区',
    city_level: 3,
    lng: '124.345722',
    lat: '43.146155',
    count: 0
  },
  {
    code: '210304',
    name: '立山区',
    city_level: 3,
    lng: '123.029091',
    lat: '41.150401',
    count: 0
  },
  {
    code: '210311',
    name: '千山区',
    city_level: 3,
    lng: '122.949298',
    lat: '41.068909',
    count: 0
  },
  {
    code: '210321',
    name: '台安县',
    city_level: 3,
    lng: '122.436196',
    lat: '41.412768',
    count: 0
  },
  {
    code: '210323',
    name: '岫岩满族自治县',
    city_level: 3,
    lng: '123.280935',
    lat: '40.290880',
    count: 0
  },
  {
    code: '210381',
    name: '海城市',
    city_level: 3,
    lng: '122.685217',
    lat: '40.882377',
    count: 0
  },
  {
    code: '210402',
    name: '新抚区',
    city_level: 3,
    lng: '123.912861',
    lat: '41.862080',
    count: 0
  },
  {
    code: '210403',
    name: '东洲区',
    city_level: 3,
    lng: '124.038685',
    lat: '41.853192',
    count: 0
  },
  {
    code: '210404',
    name: '望花区',
    city_level: 3,
    lng: '123.784206',
    lat: '41.853646',
    count: 0
  },
  {
    code: '210411',
    name: '顺城区',
    city_level: 3,
    lng: '123.945040',
    lat: '41.883375',
    count: 0
  },
  {
    code: '210421',
    name: '抚顺县',
    city_level: 3,
    lng: '124.097979',
    lat: '41.922644',
    count: 0
  },
  {
    code: '210422',
    name: '新宾满族自治县',
    city_level: 3,
    lng: '125.039978',
    lat: '41.734256',
    count: 0
  },
  {
    code: '210423',
    name: '清原满族自治县',
    city_level: 3,
    lng: '124.924083',
    lat: '42.100539',
    count: 0
  },
  {
    code: '210502',
    name: '平山区',
    city_level: 3,
    lng: '123.768926',
    lat: '41.299702',
    count: 0
  },
  {
    code: '210503',
    name: '溪湖区',
    city_level: 3,
    lng: '123.767647',
    lat: '41.329219',
    count: 0
  },
  {
    code: '210504',
    name: '明山区',
    city_level: 3,
    lng: '123.817212',
    lat: '41.308710',
    count: 0
  },
  {
    code: '210505',
    name: '南芬区',
    city_level: 3,
    lng: '123.744802',
    lat: '41.100445',
    count: 0
  },
  {
    code: '210521',
    name: '本溪满族自治县',
    city_level: 3,
    lng: '124.120436',
    lat: '41.301892',
    count: 0
  },
  {
    code: '210522',
    name: '桓仁满族自治县',
    city_level: 3,
    lng: '125.361007',
    lat: '41.267128',
    count: 0
  },
  {
    code: '210602',
    name: '元宝区',
    city_level: 3,
    lng: '124.395757',
    lat: '40.136520',
    count: 0
  },
  {
    code: '210603',
    name: '振兴区',
    city_level: 3,
    lng: '124.360264',
    lat: '40.105194',
    count: 0
  },
  {
    code: '210604',
    name: '振安区',
    city_level: 3,
    lng: '124.428162',
    lat: '40.158267',
    count: 0
  },
  {
    code: '210624',
    name: '宽甸满族自治县',
    city_level: 3,
    lng: '124.783660',
    lat: '40.731317',
    count: 0
  },
  {
    code: '210681',
    name: '东港市',
    city_level: 3,
    lng: '124.152705',
    lat: '39.863008',
    count: 0
  },
  {
    code: '210682',
    name: '凤城市',
    city_level: 3,
    lng: '124.066919',
    lat: '40.452298',
    count: 0
  },
  {
    code: '210702',
    name: '古塔区',
    city_level: 3,
    lng: '121.128279',
    lat: '41.117245',
    count: 0
  },
  {
    code: '210703',
    name: '凌河区',
    city_level: 3,
    lng: '121.150877',
    lat: '41.114990',
    count: 0
  },
  {
    code: '210711',
    name: '太和区',
    city_level: 3,
    lng: '121.103892',
    lat: '41.109147',
    count: 0
  },
  {
    code: '210726',
    name: '黑山县',
    city_level: 3,
    lng: '122.123443',
    lat: '41.666028',
    count: 0
  },
  {
    code: '210727',
    name: '义县',
    city_level: 3,
    lng: '121.239080',
    lat: '41.533087',
    count: 0
  },
  {
    code: '210781',
    name: '凌海市',
    city_level: 3,
    lng: '121.357730',
    lat: '41.173400',
    count: 0
  },
  {
    code: '210782',
    name: '北镇市',
    city_level: 3,
    lng: '121.795962',
    lat: '41.598764',
    count: 0
  },
  {
    code: '210802',
    name: '站前区',
    city_level: 3,
    lng: '122.258961',
    lat: '40.672670',
    count: 0
  },
  {
    code: '210803',
    name: '西市区',
    city_level: 3,
    lng: '122.206414',
    lat: '40.666408',
    count: 0
  },
  {
    code: '210804',
    name: '鲅鱼圈区',
    city_level: 3,
    lng: '122.107814',
    lat: '40.230636',
    count: 0
  },
  {
    code: '210811',
    name: '老边区',
    city_level: 3,
    lng: '122.379967',
    lat: '40.680303',
    count: 0
  },
  {
    code: '210881',
    name: '盖州市',
    city_level: 3,
    lng: '122.348936',
    lat: '40.400611',
    count: 0
  },
  {
    code: '210882',
    name: '大石桥市',
    city_level: 3,
    lng: '122.509131',
    lat: '40.644622',
    count: 0
  },
  {
    code: '210902',
    name: '海州区',
    city_level: 3,
    lng: '119.193710',
    lat: '34.606756',
    count: 0
  },
  {
    code: '210903',
    name: '新邱区',
    city_level: 3,
    lng: '121.792535',
    lat: '42.087632',
    count: 0
  },
  {
    code: '210904',
    name: '太平区',
    city_level: 3,
    lng: '121.678512',
    lat: '42.010600',
    count: 0
  },
  {
    code: '210905',
    name: '清河门区',
    city_level: 3,
    lng: '121.416105',
    lat: '41.783100',
    count: 0
  },
  {
    code: '210911',
    name: '细河区',
    city_level: 3,
    lng: '121.680540',
    lat: '42.025495',
    count: 0
  },
  {
    code: '210921',
    name: '阜新蒙古族自治县',
    city_level: 3,
    lng: '121.757901',
    lat: '42.065175',
    count: 0
  },
  {
    code: '210922',
    name: '彰武县',
    city_level: 3,
    lng: '122.538793',
    lat: '42.386544',
    count: 0
  },
  {
    code: '211002',
    name: '白塔区',
    city_level: 3,
    lng: '123.174325',
    lat: '41.270347',
    count: 0
  },
  {
    code: '211003',
    name: '文圣区',
    city_level: 3,
    lng: '123.185386',
    lat: '41.262608',
    count: 0
  },
  {
    code: '211004',
    name: '宏伟区',
    city_level: 3,
    lng: '123.196672',
    lat: '41.217649',
    count: 0
  },
  {
    code: '211005',
    name: '弓长岭区',
    city_level: 3,
    lng: '123.419804',
    lat: '41.151847',
    count: 0
  },
  {
    code: '211011',
    name: '太子河区',
    city_level: 3,
    lng: '123.181782',
    lat: '41.253277',
    count: 0
  },
  {
    code: '211021',
    name: '辽阳县',
    city_level: 3,
    lng: '123.105695',
    lat: '41.205329',
    count: 0
  },
  {
    code: '211081',
    name: '灯塔市',
    city_level: 3,
    lng: '123.339312',
    lat: '41.426372',
    count: 0
  },
  {
    code: '211102',
    name: '双台子区',
    city_level: 3,
    lng: '122.060112',
    lat: '41.190606',
    count: 0
  },
  {
    code: '211103',
    name: '兴隆台区',
    city_level: 3,
    lng: '122.069897',
    lat: '41.158678',
    count: 0
  },
  {
    code: '211104',
    name: '大洼区',
    city_level: 2,
    lng: '122.070714',
    lat: '41.119997',
    count: 0
  },
  {
    code: '211122',
    name: '盘山县',
    city_level: 3,
    lng: '121.996499',
    lat: '41.242873',
    count: 0
  },
  {
    code: '211202',
    name: '银州区',
    city_level: 3,
    lng: '123.842305',
    lat: '42.286130',
    count: 0
  },
  {
    code: '211204',
    name: '清河区',
    city_level: 3,
    lng: '119.064767',
    lat: '33.602919',
    count: 0
  },
  {
    code: '211221',
    name: '铁岭县',
    city_level: 3,
    lng: '123.729004',
    lat: '42.223383',
    count: 0
  },
  {
    code: '211223',
    name: '西丰县',
    city_level: 3,
    lng: '124.727393',
    lat: '42.738030',
    count: 0
  },
  {
    code: '211224',
    name: '昌图县',
    city_level: 3,
    lng: '124.111100',
    lat: '42.785791',
    count: 0
  },
  {
    code: '211281',
    name: '调兵山市',
    city_level: 3,
    lng: '123.567117',
    lat: '42.467521',
    count: 0
  },
  {
    code: '211282',
    name: '开原市',
    city_level: 3,
    lng: '124.038268',
    lat: '42.546307',
    count: 0
  },
  {
    code: '211302',
    name: '双塔区',
    city_level: 3,
    lng: '120.453744',
    lat: '41.565628',
    count: 0
  },
  {
    code: '211303',
    name: '龙城区',
    city_level: 3,
    lng: '120.413376',
    lat: '41.576749',
    count: 0
  },
  {
    code: '211321',
    name: '朝阳县',
    city_level: 3,
    lng: '120.389862',
    lat: '41.497767',
    count: 0
  },
  {
    code: '211322',
    name: '建平县',
    city_level: 3,
    lng: '119.643280',
    lat: '41.403128',
    count: 0
  },
  {
    code: '211324',
    name: '喀喇沁左翼蒙古族自治县',
    city_level: 3,
    lng: '119.741223',
    lat: '41.128150',
    count: 0
  },
  {
    code: '211381',
    name: '北票市',
    city_level: 3,
    lng: '120.770730',
    lat: '41.800684',
    count: 0
  },
  {
    code: '211382',
    name: '凌源市',
    city_level: 3,
    lng: '119.401574',
    lat: '41.245445',
    count: 0
  },
  {
    code: '211402',
    name: '连山区',
    city_level: 3,
    lng: '120.869231',
    lat: '40.774461',
    count: 0
  },
  {
    code: '211403',
    name: '龙港区',
    city_level: 3,
    lng: '120.893786',
    lat: '40.735519',
    count: 0
  },
  {
    code: '211404',
    name: '南票区',
    city_level: 3,
    lng: '120.749728',
    lat: '41.107108',
    count: 0
  },
  {
    code: '211421',
    name: '绥中县',
    city_level: 3,
    lng: '120.344229',
    lat: '40.325659',
    count: 0
  },
  {
    code: '211422',
    name: '建昌县',
    city_level: 3,
    lng: '119.837124',
    lat: '40.824368',
    count: 0
  },
  {
    code: '211481',
    name: '兴城市',
    city_level: 3,
    lng: '120.728310',
    lat: '40.614762',
    count: 0
  },
  {
    code: '220102',
    name: '南关区',
    city_level: 3,
    lng: '125.350173',
    lat: '43.863989',
    count: 0
  },
  {
    code: '220103',
    name: '宽城区',
    city_level: 3,
    lng: '125.326578',
    lat: '43.943614',
    count: 0
  },
  {
    code: '220104',
    name: '朝阳区',
    city_level: 3,
    lng: '125.288319',
    lat: '43.833513',
    count: 0
  },
  {
    code: '220105',
    name: '二道区',
    city_level: 3,
    lng: '125.374217',
    lat: '43.865596',
    count: 0
  },
  {
    code: '220106',
    name: '绿园区',
    city_level: 3,
    lng: '125.256136',
    lat: '43.880975',
    count: 0
  },
  {
    code: '220112',
    name: '双阳区',
    city_level: 3,
    lng: '125.664662',
    lat: '43.525311',
    count: 0
  },
  {
    code: '220113',
    name: '九台区',
    city_level: 3,
    lng: '125.839574',
    lat: '44.151742',
    count: 0
  },
  {
    code: '220122',
    name: '农安县',
    city_level: 3,
    lng: '125.184742',
    lat: '44.432889',
    count: 0
  },
  {
    code: '220182',
    name: '榆树市',
    city_level: 3,
    lng: '126.533146',
    lat: '44.840288',
    count: 0
  },
  {
    code: '220183',
    name: '德惠市',
    city_level: 3,
    lng: '125.728620',
    lat: '44.521785',
    count: 0
  },
  {
    code: '220184',
    name: '公主岭市',
    city_level: 3,
    lng: '124.822830',
    lat: '43.504687',
    count: 0
  },
  {
    code: '220202',
    name: '昌邑区',
    city_level: 3,
    lng: '126.574710',
    lat: '43.881818',
    count: 0
  },
  {
    code: '220203',
    name: '龙潭区',
    city_level: 3,
    lng: '126.562197',
    lat: '43.910803',
    count: 0
  },
  {
    code: '220204',
    name: '船营区',
    city_level: 3,
    lng: '126.540966',
    lat: '43.833445',
    count: 0
  },
  {
    code: '220211',
    name: '丰满区',
    city_level: 3,
    lng: '126.562274',
    lat: '43.821601',
    count: 0
  },
  {
    code: '220221',
    name: '永吉县',
    city_level: 3,
    lng: '126.497741',
    lat: '43.672582',
    count: 0
  },
  {
    code: '220281',
    name: '蛟河市',
    city_level: 3,
    lng: '127.344501',
    lat: '43.723713',
    count: 0
  },
  {
    code: '220282',
    name: '桦甸市',
    city_level: 3,
    lng: '126.746310',
    lat: '42.972097',
    count: 0
  },
  {
    code: '220283',
    name: '舒兰市',
    city_level: 3,
    lng: '126.965607',
    lat: '44.406106',
    count: 0
  },
  {
    code: '220284',
    name: '磐石市',
    city_level: 3,
    lng: '126.060427',
    lat: '42.946285',
    count: 0
  },
  {
    code: '220302',
    name: '铁西区',
    city_level: 3,
    lng: '124.345722',
    lat: '43.146155',
    count: 0
  },
  {
    code: '220303',
    name: '铁东区',
    city_level: 3,
    lng: '124.409622',
    lat: '43.162048',
    count: 0
  },
  {
    code: '220322',
    name: '梨树县',
    city_level: 3,
    lng: '124.335390',
    lat: '43.307060',
    count: 0
  },
  {
    code: '220323',
    name: '伊通满族自治县',
    city_level: 3,
    lng: '125.305394',
    lat: '43.345754',
    count: 0
  },
  {
    code: '220382',
    name: '双辽市',
    city_level: 3,
    lng: '123.502724',
    lat: '43.518302',
    count: 0
  },
  {
    code: '220402',
    name: '龙山区',
    city_level: 3,
    lng: '125.136452',
    lat: '42.901534',
    count: 0
  },
  {
    code: '220403',
    name: '西安区',
    city_level: 3,
    lng: '129.616121',
    lat: '44.577625',
    count: 0
  },
  {
    code: '220421',
    name: '东丰县',
    city_level: 3,
    lng: '125.530991',
    lat: '42.676935',
    count: 0
  },
  {
    code: '220422',
    name: '东辽县',
    city_level: 3,
    lng: '124.991521',
    lat: '42.926331',
    count: 0
  },
  {
    code: '220502',
    name: '东昌区',
    city_level: 3,
    lng: '125.955102',
    lat: '41.728498',
    count: 0
  },
  {
    code: '220503',
    name: '二道江区',
    city_level: 3,
    lng: '126.042535',
    lat: '41.774053',
    count: 0
  },
  {
    code: '220521',
    name: '通化县',
    city_level: 3,
    lng: '125.759259',
    lat: '41.679808',
    count: 0
  },
  {
    code: '220523',
    name: '辉南县',
    city_level: 3,
    lng: '126.046912',
    lat: '42.684993',
    count: 0
  },
  {
    code: '220524',
    name: '柳河县',
    city_level: 3,
    lng: '125.744735',
    lat: '42.284606',
    count: 0
  },
  {
    code: '220581',
    name: '梅河口市',
    city_level: 3,
    lng: '125.683832',
    lat: '42.530341',
    count: 0
  },
  {
    code: '220582',
    name: '集安市',
    city_level: 3,
    lng: '126.194031',
    lat: '41.125307',
    count: 0
  },
  {
    code: '220602',
    name: '浑江区',
    city_level: 3,
    lng: '126.416094',
    lat: '41.945409',
    count: 0
  },
  {
    code: '220605',
    name: '江源区',
    city_level: 3,
    lng: '126.591144',
    lat: '42.056773',
    count: 0
  },
  {
    code: '220621',
    name: '抚松县',
    city_level: 3,
    lng: '127.449764',
    lat: '42.221208',
    count: 0
  },
  {
    code: '220622',
    name: '靖宇县',
    city_level: 3,
    lng: '126.813625',
    lat: '42.388760',
    count: 0
  },
  {
    code: '220623',
    name: '长白朝鲜族自治县',
    city_level: 3,
    lng: '128.200789',
    lat: '41.420018',
    count: 0
  },
  {
    code: '220681',
    name: '临江市',
    city_level: 3,
    lng: '126.918087',
    lat: '41.811979',
    count: 0
  },
  {
    code: '220702',
    name: '宁江区',
    city_level: 3,
    lng: '124.817054',
    lat: '45.171792',
    count: 0
  },
  {
    code: '220721',
    name: '前郭尔罗斯蒙古族自治县',
    city_level: 3,
    lng: '124.823242',
    lat: '45.118030',
    count: 0
  },
  {
    code: '220722',
    name: '长岭县',
    city_level: 3,
    lng: '123.967484',
    lat: '44.275895',
    count: 0
  },
  {
    code: '220723',
    name: '乾安县',
    city_level: 3,
    lng: '124.041139',
    lat: '45.003774',
    count: 0
  },
  {
    code: '220781',
    name: '扶余市',
    city_level: 3,
    lng: '126.049774',
    lat: '44.988449',
    count: 0
  },
  {
    code: '220802',
    name: '洮北区',
    city_level: 3,
    lng: '122.851029',
    lat: '45.621716',
    count: 0
  },
  {
    code: '220821',
    name: '镇赉县',
    city_level: 3,
    lng: '123.199890',
    lat: '45.847435',
    count: 0
  },
  {
    code: '220822',
    name: '通榆县',
    city_level: 3,
    lng: '123.088239',
    lat: '44.812911',
    count: 0
  },
  {
    code: '220881',
    name: '洮南市',
    city_level: 3,
    lng: '122.783779',
    lat: '45.339113',
    count: 0
  },
  {
    code: '220882',
    name: '大安市',
    city_level: 3,
    lng: '124.292626',
    lat: '45.506996',
    count: 0
  },
  {
    code: '222401',
    name: '延吉市',
    city_level: 3,
    lng: '129.508946',
    lat: '42.891255',
    count: 0
  },
  {
    code: '222402',
    name: '图们市',
    city_level: 3,
    lng: '129.843710',
    lat: '42.968044',
    count: 0
  },
  {
    code: '222403',
    name: '敦化市',
    city_level: 3,
    lng: '128.232013',
    lat: '43.372413',
    count: 0
  },
  {
    code: '222404',
    name: '珲春市',
    city_level: 3,
    lng: '130.366036',
    lat: '42.862821',
    count: 0
  },
  {
    code: '222405',
    name: '龙井市',
    city_level: 3,
    lng: '129.427066',
    lat: '42.766311',
    count: 0
  },
  {
    code: '222406',
    name: '和龙市',
    city_level: 3,
    lng: '129.010106',
    lat: '42.546675',
    count: 0
  },
  {
    code: '222424',
    name: '汪清县',
    city_level: 3,
    lng: '129.771607',
    lat: '43.312522',
    count: 0
  },
  {
    code: '222426',
    name: '安图县',
    city_level: 3,
    lng: '128.899807',
    lat: '43.111964',
    count: 0
  },
  {
    code: '230102',
    name: '道里区',
    city_level: 3,
    lng: '126.616957',
    lat: '45.755777',
    count: 0
  },
  {
    code: '230103',
    name: '南岗区',
    city_level: 3,
    lng: '126.668788',
    lat: '45.759970',
    count: 0
  },
  {
    code: '230104',
    name: '道外区',
    city_level: 3,
    lng: '126.649451',
    lat: '45.792113',
    count: 0
  },
  {
    code: '230108',
    name: '平房区',
    city_level: 2,
    lng: '126.534967',
    lat: '45.803775',
    count: 0
  },
  {
    code: '230109',
    name: '松北区',
    city_level: 3,
    lng: '126.510275',
    lat: '45.802756',
    count: 0
  },
  {
    code: '230110',
    name: '香坊区',
    city_level: 3,
    lng: '126.662593',
    lat: '45.707716',
    count: 0
  },
  {
    code: '230111',
    name: '呼兰区',
    city_level: 3,
    lng: '126.587709',
    lat: '45.889427',
    count: 0
  },
  {
    code: '230112',
    name: '阿城区',
    city_level: 3,
    lng: '126.958098',
    lat: '45.548670',
    count: 0
  },
  {
    code: '230113',
    name: '双城区',
    city_level: 3,
    lng: '126.312745',
    lat: '45.383263',
    count: 0
  },
  {
    code: '230123',
    name: '依兰县',
    city_level: 3,
    lng: '129.567985',
    lat: '46.324534',
    count: 0
  },
  {
    code: '230124',
    name: '方正县',
    city_level: 3,
    lng: '128.829536',
    lat: '45.851695',
    count: 0
  },
  {
    code: '230125',
    name: '宾县',
    city_level: 3,
    lng: '127.466634',
    lat: '45.745918',
    count: 0
  },
  {
    code: '230126',
    name: '巴彦县',
    city_level: 3,
    lng: '127.403182',
    lat: '46.085379',
    count: 0
  },
  {
    code: '230127',
    name: '木兰县',
    city_level: 3,
    lng: '128.043466',
    lat: '45.950582',
    count: 0
  },
  {
    code: '230128',
    name: '通河县',
    city_level: 3,
    lng: '128.749352',
    lat: '45.972425',
    count: 0
  },
  {
    code: '230129',
    name: '延寿县',
    city_level: 3,
    lng: '128.331644',
    lat: '45.451897',
    count: 0
  },
  {
    code: '230183',
    name: '尚志市',
    city_level: 3,
    lng: '128.009895',
    lat: '45.209586',
    count: 0
  },
  {
    code: '230184',
    name: '五常市',
    city_level: 3,
    lng: '127.167619',
    lat: '44.931992',
    count: 0
  },
  {
    code: '230202',
    name: '龙沙区',
    city_level: 3,
    lng: '123.957531',
    lat: '47.317309',
    count: 0
  },
  {
    code: '230203',
    name: '建华区',
    city_level: 3,
    lng: '123.955464',
    lat: '47.354364',
    count: 0
  },
  {
    code: '230204',
    name: '铁锋区',
    city_level: 3,
    lng: '123.978293',
    lat: '47.340518',
    count: 0
  },
  {
    code: '230205',
    name: '昂昂溪区',
    city_level: 3,
    lng: '123.822401',
    lat: '47.155160',
    count: 0
  },
  {
    code: '230206',
    name: '富拉尔基区',
    city_level: 3,
    lng: '123.629190',
    lat: '47.208843',
    count: 0
  },
  {
    code: '230207',
    name: '碾子山区',
    city_level: 3,
    lng: '122.887775',
    lat: '47.516872',
    count: 0
  },
  {
    code: '230208',
    name: '梅里斯达斡尔族区',
    city_level: 3,
    lng: '123.752910',
    lat: '47.309537',
    count: 0
  },
  {
    code: '230221',
    name: '龙江县',
    city_level: 3,
    lng: '123.205323',
    lat: '47.338665',
    count: 0
  },
  {
    code: '230223',
    name: '依安县',
    city_level: 3,
    lng: '125.306279',
    lat: '47.893548',
    count: 0
  },
  {
    code: '230224',
    name: '泰来县',
    city_level: 3,
    lng: '123.416631',
    lat: '46.393694',
    count: 0
  },
  {
    code: '230225',
    name: '甘南县',
    city_level: 3,
    lng: '123.507429',
    lat: '47.922406',
    count: 0
  },
  {
    code: '230227',
    name: '富裕县',
    city_level: 3,
    lng: '124.473924',
    lat: '47.774550',
    count: 0
  },
  {
    code: '230229',
    name: '克山县',
    city_level: 3,
    lng: '125.875705',
    lat: '48.037031',
    count: 0
  },
  {
    code: '230230',
    name: '克东县',
    city_level: 3,
    lng: '126.248721',
    lat: '48.042060',
    count: 0
  },
  {
    code: '230231',
    name: '拜泉县',
    city_level: 3,
    lng: '126.100213',
    lat: '47.595851',
    count: 0
  },
  {
    code: '230281',
    name: '讷河市',
    city_level: 3,
    lng: '124.884244',
    lat: '48.484099',
    count: 0
  },
  {
    code: '230302',
    name: '鸡冠区',
    city_level: 3,
    lng: '130.980894',
    lat: '45.304073',
    count: 0
  },
  {
    code: '230303',
    name: '恒山区',
    city_level: 3,
    lng: '130.904963',
    lat: '45.210668',
    count: 0
  },
  {
    code: '230304',
    name: '滴道区',
    city_level: 3,
    lng: '130.843613',
    lat: '45.348764',
    count: 0
  },
  {
    code: '230305',
    name: '梨树区',
    city_level: 3,
    lng: '130.696990',
    lat: '45.092046',
    count: 0
  },
  {
    code: '230306',
    name: '城子河区',
    city_level: 3,
    lng: '131.011304',
    lat: '45.336970',
    count: 0
  },
  {
    code: '230307',
    name: '麻山区',
    city_level: 3,
    lng: '130.478187',
    lat: '45.212088',
    count: 0
  },
  {
    code: '230321',
    name: '鸡东县',
    city_level: 3,
    lng: '131.124080',
    lat: '45.260412',
    count: 0
  },
  {
    code: '230381',
    name: '虎林市',
    city_level: 3,
    lng: '132.937210',
    lat: '45.762686',
    count: 0
  },
  {
    code: '230382',
    name: '密山市',
    city_level: 3,
    lng: '131.846636',
    lat: '45.529775',
    count: 0
  },
  {
    code: '230402',
    name: '向阳区',
    city_level: 3,
    lng: '130.365346',
    lat: '46.807790',
    count: 0
  },
  {
    code: '230403',
    name: '工农区',
    city_level: 3,
    lng: '130.274684',
    lat: '47.318781',
    count: 0
  },
  {
    code: '230404',
    name: '南山区',
    city_level: 3,
    lng: '113.923552',
    lat: '22.528499',
    count: 0
  },
  {
    code: '230405',
    name: '兴安区',
    city_level: 3,
    lng: '130.239245',
    lat: '47.252850',
    count: 0
  },
  {
    code: '230406',
    name: '东山区',
    city_level: 3,
    lng: '130.317062',
    lat: '47.338535',
    count: 0
  },
  {
    code: '230407',
    name: '兴山区',
    city_level: 3,
    lng: '130.303574',
    lat: '47.357666',
    count: 0
  },
  {
    code: '230421',
    name: '萝北县',
    city_level: 3,
    lng: '130.828626',
    lat: '47.577495',
    count: 0
  },
  {
    code: '230422',
    name: '绥滨县',
    city_level: 3,
    lng: '131.852759',
    lat: '47.289116',
    count: 0
  },
  {
    code: '230502',
    name: '尖山区',
    city_level: 3,
    lng: '131.158416',
    lat: '46.646350',
    count: 0
  },
  {
    code: '230503',
    name: '岭东区',
    city_level: 3,
    lng: '131.164724',
    lat: '46.592721',
    count: 0
  },
  {
    code: '230505',
    name: '四方台区',
    city_level: 3,
    lng: '131.334503',
    lat: '46.594316',
    count: 0
  },
  {
    code: '230506',
    name: '宝山区',
    city_level: 3,
    lng: '121.489431',
    lat: '31.405242',
    count: 0
  },
  {
    code: '230521',
    name: '集贤县',
    city_level: 3,
    lng: '131.140483',
    lat: '46.728377',
    count: 0
  },
  {
    code: '230522',
    name: '友谊县',
    city_level: 3,
    lng: '131.808064',
    lat: '46.767299',
    count: 0
  },
  {
    code: '230523',
    name: '宝清县',
    city_level: 3,
    lng: '132.197243',
    lat: '46.326925',
    count: 0
  },
  {
    code: '230524',
    name: '饶河县',
    city_level: 3,
    lng: '134.013872',
    lat: '46.798164',
    count: 0
  },
  {
    code: '230602',
    name: '萨尔图区',
    city_level: 3,
    lng: '125.114643',
    lat: '46.596356',
    count: 0
  },
  {
    code: '230603',
    name: '龙凤区',
    city_level: 3,
    lng: '125.135326',
    lat: '46.562247',
    count: 0
  },
  {
    code: '230604',
    name: '让胡路区',
    city_level: 3,
    lng: '124.870597',
    lat: '46.652358',
    count: 0
  },
  {
    code: '230605',
    name: '红岗区',
    city_level: 3,
    lng: '124.891039',
    lat: '46.398418',
    count: 0
  },
  {
    code: '230606',
    name: '大同区',
    city_level: 3,
    lng: '124.812364',
    lat: '46.039828',
    count: 0
  },
  {
    code: '230621',
    name: '肇州县',
    city_level: 3,
    lng: '125.268643',
    lat: '45.699066',
    count: 0
  },
  {
    code: '230622',
    name: '肇源县',
    city_level: 3,
    lng: '125.078223',
    lat: '45.519320',
    count: 0
  },
  {
    code: '230623',
    name: '林甸县',
    city_level: 3,
    lng: '124.863603',
    lat: '47.171717',
    count: 0
  },
  {
    code: '230624',
    name: '杜尔伯特蒙古族自治县',
    city_level: 3,
    lng: '124.442572',
    lat: '46.862817',
    count: 0
  },
  {
    code: '230717',
    name: '伊美区',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536',
    count: 0
  },
  {
    code: '230718',
    name: '乌翠区',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536',
    count: 0
  },
  {
    code: '230719',
    name: '友好区',
    city_level: 3,
    lng: '128.840750',
    lat: '47.853778',
    count: 0
  },
  {
    code: '230722',
    name: '嘉荫县',
    city_level: 3,
    lng: '130.403564',
    lat: '48.889109',
    count: 0
  },
  {
    code: '230723',
    name: '汤旺县',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536',
    count: 0
  },
  {
    code: '230724',
    name: '丰林县',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536',
    count: 0
  },
  {
    code: '230725',
    name: '大箐山县',
    city_level: 3,
    lng: '101.787804',
    lat: '23.163322',
    count: 0
  },
  {
    code: '230726',
    name: '南岔县',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536',
    count: 0
  },
  {
    code: '230751',
    name: '金林区',
    city_level: 2,
    lng: '128.841147',
    lat: '47.727536',
    count: 0
  },
  {
    code: '230781',
    name: '铁力市',
    city_level: 3,
    lng: '128.032422',
    lat: '46.986640',
    count: 0
  },
  {
    code: '230803',
    name: '向阳区',
    city_level: 3,
    lng: '130.365346',
    lat: '46.807790',
    count: 0
  },
  {
    code: '230804',
    name: '前进区',
    city_level: 3,
    lng: '130.375063',
    lat: '46.814103',
    count: 0
  },
  {
    code: '230805',
    name: '东风区',
    city_level: 3,
    lng: '130.403664',
    lat: '46.822572',
    count: 0
  },
  {
    code: '230811',
    name: '郊区',
    city_level: 3,
    lng: '117.807070',
    lat: '30.908927',
    count: 0
  },
  {
    code: '230822',
    name: '桦南县',
    city_level: 3,
    lng: '130.553343',
    lat: '46.239185',
    count: 0
  },
  {
    code: '230826',
    name: '桦川县',
    city_level: 3,
    lng: '130.719081',
    lat: '47.023001',
    count: 0
  },
  {
    code: '230828',
    name: '汤原县',
    city_level: 3,
    lng: '129.905072',
    lat: '46.730706',
    count: 0
  },
  {
    code: '230881',
    name: '同江市',
    city_level: 3,
    lng: '132.510919',
    lat: '47.642707',
    count: 0
  },
  {
    code: '230882',
    name: '富锦市',
    city_level: 3,
    lng: '132.037686',
    lat: '47.250108',
    count: 0
  },
  {
    code: '230883',
    name: '抚远市',
    city_level: 3,
    lng: '134.307884',
    lat: '48.364687',
    count: 0
  },
  {
    code: '230902',
    name: '新兴区',
    city_level: 3,
    lng: '130.932143',
    lat: '45.815930',
    count: 0
  },
  {
    code: '230903',
    name: '桃山区',
    city_level: 3,
    lng: '131.020275',
    lat: '45.765594',
    count: 0
  },
  {
    code: '230904',
    name: '茄子河区',
    city_level: 3,
    lng: '131.068075',
    lat: '45.785215',
    count: 0
  },
  {
    code: '230921',
    name: '勃利县',
    city_level: 3,
    lng: '130.592093',
    lat: '45.755507',
    count: 0
  },
  {
    code: '231002',
    name: '东安区',
    city_level: 3,
    lng: '129.626642',
    lat: '44.581360',
    count: 0
  },
  {
    code: '231003',
    name: '阳明区',
    city_level: 3,
    lng: '129.635615',
    lat: '44.596104',
    count: 0
  },
  {
    code: '231004',
    name: '爱民区',
    city_level: 3,
    lng: '129.591657',
    lat: '44.596053',
    count: 0
  },
  {
    code: '231005',
    name: '西安区',
    city_level: 3,
    lng: '129.616121',
    lat: '44.577625',
    count: 0
  },
  {
    code: '231025',
    name: '林口县',
    city_level: 3,
    lng: '130.284033',
    lat: '45.278046',
    count: 0
  },
  {
    code: '231081',
    name: '绥芬河市',
    city_level: 3,
    lng: '131.152546',
    lat: '44.412309',
    count: 0
  },
  {
    code: '231083',
    name: '海林市',
    city_level: 3,
    lng: '129.380482',
    lat: '44.594213',
    count: 0
  },
  {
    code: '231084',
    name: '宁安市',
    city_level: 3,
    lng: '129.482851',
    lat: '44.340720',
    count: 0
  },
  {
    code: '231085',
    name: '穆棱市',
    city_level: 3,
    lng: '130.524437',
    lat: '44.918813',
    count: 0
  },
  {
    code: '231086',
    name: '东宁市',
    city_level: 3,
    lng: '131.122915',
    lat: '44.087585',
    count: 0
  },
  {
    code: '231102',
    name: '爱辉区',
    city_level: 3,
    lng: '127.500451',
    lat: '50.252106',
    count: 0
  },
  {
    code: '231123',
    name: '逊克县',
    city_level: 3,
    lng: '128.478750',
    lat: '49.564252',
    count: 0
  },
  {
    code: '231124',
    name: '孙吴县',
    city_level: 3,
    lng: '127.336304',
    lat: '49.425652',
    count: 0
  },
  {
    code: '231181',
    name: '北安市',
    city_level: 3,
    lng: '126.491199',
    lat: '48.241374',
    count: 0
  },
  {
    code: '231182',
    name: '五大连池市',
    city_level: 3,
    lng: '126.205516',
    lat: '48.517257',
    count: 0
  },
  {
    code: '231183',
    name: '嫩江市',
    city_level: 3,
    lng: '125.229904',
    lat: '49.177460',
    count: 0
  },
  {
    code: '231202',
    name: '北林区',
    city_level: 3,
    lng: '126.985593',
    lat: '46.637344',
    count: 0
  },
  {
    code: '231221',
    name: '望奎县',
    city_level: 3,
    lng: '126.486076',
    lat: '46.832719',
    count: 0
  },
  {
    code: '231222',
    name: '兰西县',
    city_level: 3,
    lng: '126.288113',
    lat: '46.252447',
    count: 0
  },
  {
    code: '231223',
    name: '青冈县',
    city_level: 3,
    lng: '126.113860',
    lat: '46.689671',
    count: 0
  },
  {
    code: '231224',
    name: '庆安县',
    city_level: 3,
    lng: '127.507825',
    lat: '46.880102',
    count: 0
  },
  {
    code: '231225',
    name: '明水县',
    city_level: 3,
    lng: '125.906301',
    lat: '47.173426',
    count: 0
  },
  {
    code: '231226',
    name: '绥棱县',
    city_level: 3,
    lng: '127.114832',
    lat: '47.236015',
    count: 0
  },
  {
    code: '231281',
    name: '安达市',
    city_level: 3,
    lng: '125.352188',
    lat: '46.423508',
    count: 0
  },
  {
    code: '231282',
    name: '肇东市',
    city_level: 3,
    lng: '125.961814',
    lat: '46.051126',
    count: 0
  },
  {
    code: '231283',
    name: '海伦市',
    city_level: 3,
    lng: '126.973143',
    lat: '47.461971',
    count: 0
  },
  {
    code: '232701',
    name: '漠河市',
    city_level: 3,
    lng: '122.529619',
    lat: '52.970721',
    count: 0
  },
  {
    code: '232721',
    name: '呼玛县',
    city_level: 3,
    lng: '126.665319',
    lat: '51.725637',
    count: 0
  },
  {
    code: '232722',
    name: '塔河县',
    city_level: 3,
    lng: '124.709996',
    lat: '52.334457',
    count: 0
  },
  {
    code: '310101',
    name: '黄浦区',
    city_level: 3,
    lng: '121.469240',
    lat: '31.229860',
    count: 0
  },
  {
    code: '310104',
    name: '徐汇区',
    city_level: 3,
    lng: '121.437866',
    lat: '31.199190',
    count: 0
  },
  {
    code: '310105',
    name: '长宁区',
    city_level: 3,
    lng: '121.424624',
    lat: '31.220367',
    count: 0
  },
  {
    code: '310106',
    name: '静安区',
    city_level: 3,
    lng: '121.459384',
    lat: '31.247105',
    count: 0
  },
  {
    code: '310107',
    name: '普陀区',
    city_level: 3,
    lng: '122.323867',
    lat: '29.971760',
    count: 0
  },
  {
    code: '310109',
    name: '虹口区',
    city_level: 3,
    lng: '121.505133',
    lat: '31.264600',
    count: 0
  },
  {
    code: '310110',
    name: '杨浦区',
    city_level: 3,
    lng: '121.526077',
    lat: '31.259541',
    count: 0
  },
  {
    code: '310112',
    name: '闵行区',
    city_level: 3,
    lng: '121.381709',
    lat: '31.112813',
    count: 0
  },
  {
    code: '310113',
    name: '宝山区',
    city_level: 3,
    lng: '121.489431',
    lat: '31.405242',
    count: 0
  },
  {
    code: '310114',
    name: '嘉定区',
    city_level: 3,
    lng: '121.265300',
    lat: '31.375602',
    count: 0
  },
  {
    code: '310115',
    name: '浦东新区',
    city_level: 3,
    lng: '121.544379',
    lat: '31.221517',
    count: 0
  },
  {
    code: '310116',
    name: '金山区',
    city_level: 3,
    lng: '121.341970',
    lat: '30.741991',
    count: 0
  },
  {
    code: '310117',
    name: '松江区',
    city_level: 3,
    lng: '121.227747',
    lat: '31.032243',
    count: 0
  },
  {
    code: '310118',
    name: '青浦区',
    city_level: 3,
    lng: '121.124178',
    lat: '31.150681',
    count: 0
  },
  {
    code: '310120',
    name: '奉贤区',
    city_level: 3,
    lng: '121.474042',
    lat: '30.917795',
    count: 0
  },
  {
    code: '310151',
    name: '崇明区',
    city_level: 3,
    lng: '121.397514',
    lat: '31.626945',
    count: 0
  },
  {
    code: '320102',
    name: '玄武区',
    city_level: 3,
    lng: '118.797861',
    lat: '32.048687',
    count: 0
  },
  {
    code: '320104',
    name: '秦淮区',
    city_level: 3,
    lng: '118.794744',
    lat: '32.039133',
    count: 0
  },
  {
    code: '320105',
    name: '建邺区',
    city_level: 3,
    lng: '118.731694',
    lat: '32.003552',
    count: 0
  },
  {
    code: '320106',
    name: '鼓楼区',
    city_level: 3,
    lng: '114.348307',
    lat: '34.788561',
    count: 0
  },
  {
    code: '320111',
    name: '浦口区',
    city_level: 3,
    lng: '118.627895',
    lat: '32.059093',
    count: 0
  },
  {
    code: '320113',
    name: '栖霞区',
    city_level: 3,
    lng: '118.909246',
    lat: '32.096228',
    count: 0
  },
  {
    code: '320114',
    name: '雨花台区',
    city_level: 3,
    lng: '118.779073',
    lat: '31.991347',
    count: 0
  },
  {
    code: '320115',
    name: '江宁区',
    city_level: 3,
    lng: '118.839685',
    lat: '31.953702',
    count: 0
  },
  {
    code: '320116',
    name: '六合区',
    city_level: 3,
    lng: '118.821401',
    lat: '32.322247',
    count: 0
  },
  {
    code: '320117',
    name: '溧水区',
    city_level: 3,
    lng: '119.028280',
    lat: '31.651133',
    count: 0
  },
  {
    code: '320118',
    name: '高淳区',
    city_level: 3,
    lng: '118.892085',
    lat: '31.328471',
    count: 0
  },
  {
    code: '320205',
    name: '锡山区',
    city_level: 3,
    lng: '120.357858',
    lat: '31.589715',
    count: 0
  },
  {
    code: '320206',
    name: '惠山区',
    city_level: 3,
    lng: '120.298500',
    lat: '31.681012',
    count: 0
  },
  {
    code: '320211',
    name: '滨湖区',
    city_level: 3,
    lng: '120.271750',
    lat: '31.470483',
    count: 0
  },
  {
    code: '320213',
    name: '梁溪区',
    city_level: 3,
    lng: '120.296591',
    lat: '31.575719',
    count: 0
  },
  {
    code: '320214',
    name: '新吴区',
    city_level: 3,
    lng: '120.352778',
    lat: '31.550957',
    count: 0
  },
  {
    code: '320281',
    name: '江阴市',
    city_level: 3,
    lng: '120.284939',
    lat: '31.920658',
    count: 0
  },
  {
    code: '320282',
    name: '宜兴市',
    city_level: 3,
    lng: '119.823308',
    lat: '31.340637',
    count: 0
  },
  {
    code: '320302',
    name: '鼓楼区',
    city_level: 3,
    lng: '114.348307',
    lat: '34.788561',
    count: 0
  },
  {
    code: '320303',
    name: '云龙区',
    city_level: 3,
    lng: '117.251560',
    lat: '34.253167',
    count: 0
  },
  {
    code: '320305',
    name: '贾汪区',
    city_level: 3,
    lng: '117.466687',
    lat: '34.435506',
    count: 0
  },
  {
    code: '320311',
    name: '泉山区',
    city_level: 3,
    lng: '117.193805',
    lat: '34.244258',
    count: 0
  },
  {
    code: '320312',
    name: '铜山区',
    city_level: 3,
    lng: '117.169421',
    lat: '34.180700',
    count: 0
  },
  {
    code: '320321',
    name: '丰县',
    city_level: 3,
    lng: '116.595391',
    lat: '34.693906',
    count: 0
  },
  {
    code: '320322',
    name: '沛县',
    city_level: 3,
    lng: '116.937532',
    lat: '34.721656',
    count: 0
  },
  {
    code: '320324',
    name: '睢宁县',
    city_level: 3,
    lng: '117.941563',
    lat: '33.912598',
    count: 0
  },
  {
    code: '320381',
    name: '新沂市',
    city_level: 3,
    lng: '118.354537',
    lat: '34.369580',
    count: 0
  },
  {
    code: '320382',
    name: '邳州市',
    city_level: 3,
    lng: '118.012531',
    lat: '34.338888',
    count: 0
  },
  {
    code: '320402',
    name: '天宁区',
    city_level: 3,
    lng: '119.974991',
    lat: '31.779619',
    count: 0
  },
  {
    code: '320404',
    name: '钟楼区',
    city_level: 3,
    lng: '119.902112',
    lat: '31.802192',
    count: 0
  },
  {
    code: '320411',
    name: '新北区',
    city_level: 3,
    lng: '119.972182',
    lat: '31.830641',
    count: 0
  },
  {
    code: '320412',
    name: '武进区',
    city_level: 3,
    lng: '119.942437',
    lat: '31.701188',
    count: 0
  },
  {
    code: '320413',
    name: '金坛区',
    city_level: 3,
    lng: '119.597897',
    lat: '31.723247',
    count: 0
  },
  {
    code: '320481',
    name: '溧阳市',
    city_level: 3,
    lng: '119.484211',
    lat: '31.416911',
    count: 0
  },
  {
    code: '320505',
    name: '虎丘区',
    city_level: 3,
    lng: '120.566833',
    lat: '31.294845',
    count: 0
  },
  {
    code: '320506',
    name: '吴中区',
    city_level: 3,
    lng: '120.631898',
    lat: '31.264212',
    count: 0
  },
  {
    code: '320507',
    name: '相城区',
    city_level: 3,
    lng: '120.642663',
    lat: '31.369088',
    count: 0
  },
  {
    code: '320508',
    name: '姑苏区',
    city_level: 3,
    lng: '120.617296',
    lat: '31.336392',
    count: 0
  },
  {
    code: '320509',
    name: '吴江区',
    city_level: 3,
    lng: '120.645158',
    lat: '31.138677',
    count: 0
  },
  {
    code: '320581',
    name: '常熟市',
    city_level: 3,
    lng: '120.752481',
    lat: '31.654376',
    count: 0
  },
  {
    code: '320582',
    name: '张家港市',
    city_level: 3,
    lng: '120.479033',
    lat: '31.899861',
    count: 0
  },
  {
    code: '320583',
    name: '昆山市',
    city_level: 3,
    lng: '120.980737',
    lat: '31.385598',
    count: 0
  },
  {
    code: '320585',
    name: '太仓市',
    city_level: 3,
    lng: '121.130550',
    lat: '31.457735',
    count: 0
  },
  {
    code: '320612',
    name: '通州区',
    city_level: 3,
    lng: '121.075087',
    lat: '32.064161',
    count: 0
  },
  {
    code: '320613',
    name: '崇川区',
    city_level: 3,
    lng: '120.857434',
    lat: '32.009875',
    count: 0
  },
  {
    code: '320614',
    name: '海门区',
    city_level: 3,
    lng: '121.123088',
    lat: '31.883544',
    count: 0
  },
  {
    code: '320623',
    name: '如东县',
    city_level: 3,
    lng: '121.185201',
    lat: '32.331766',
    count: 0
  },
  {
    code: '320681',
    name: '启东市',
    city_level: 3,
    lng: '121.657441',
    lat: '31.808026',
    count: 0
  },
  {
    code: '320682',
    name: '如皋市',
    city_level: 3,
    lng: '120.574946',
    lat: '32.370557',
    count: 0
  },
  {
    code: '320685',
    name: '海安市',
    city_level: 3,
    lng: '120.465996',
    lat: '32.540287',
    count: 0
  },
  {
    code: '320703',
    name: '连云区',
    city_level: 3,
    lng: '119.338788',
    lat: '34.760249',
    count: 0
  },
  {
    code: '320706',
    name: '海州区',
    city_level: 3,
    lng: '119.193710',
    lat: '34.606756',
    count: 0
  },
  {
    code: '320707',
    name: '赣榆区',
    city_level: 3,
    lng: '119.173331',
    lat: '34.841349',
    count: 0
  },
  {
    code: '320722',
    name: '东海县',
    city_level: 3,
    lng: '118.752842',
    lat: '34.542309',
    count: 0
  },
  {
    code: '320723',
    name: '灌云县',
    city_level: 3,
    lng: '119.239381',
    lat: '34.284381',
    count: 0
  },
  {
    code: '320724',
    name: '灌南县',
    city_level: 3,
    lng: '119.315651',
    lat: '34.087135',
    count: 0
  },
  {
    code: '320803',
    name: '淮安区',
    city_level: 3,
    lng: '119.141099',
    lat: '33.502869',
    count: 0
  },
  {
    code: '320804',
    name: '淮阴区',
    city_level: 3,
    lng: '119.034725',
    lat: '33.631893',
    count: 0
  },
  {
    code: '320812',
    name: '清江浦区',
    city_level: 3,
    lng: '119.026716',
    lat: '33.552621',
    count: 0
  },
  {
    code: '320813',
    name: '洪泽区',
    city_level: 3,
    lng: '118.867874',
    lat: '33.294975',
    count: 0
  },
  {
    code: '320826',
    name: '涟水县',
    city_level: 3,
    lng: '119.260335',
    lat: '33.780960',
    count: 0
  },
  {
    code: '320830',
    name: '盱眙县',
    city_level: 3,
    lng: '118.544360',
    lat: '33.011971',
    count: 0
  },
  {
    code: '320831',
    name: '金湖县',
    city_level: 3,
    lng: '119.020585',
    lat: '33.025433',
    count: 0
  },
  {
    code: '320902',
    name: '亭湖区',
    city_level: 3,
    lng: '120.197358',
    lat: '33.390536',
    count: 0
  },
  {
    code: '320903',
    name: '盐都区',
    city_level: 3,
    lng: '120.153898',
    lat: '33.338094',
    count: 0
  },
  {
    code: '320904',
    name: '大丰区',
    city_level: 3,
    lng: '120.500858',
    lat: '33.200331',
    count: 0
  },
  {
    code: '320921',
    name: '响水县',
    city_level: 3,
    lng: '119.578364',
    lat: '34.199479',
    count: 0
  },
  {
    code: '320922',
    name: '滨海县',
    city_level: 3,
    lng: '119.820831',
    lat: '33.990334',
    count: 0
  },
  {
    code: '320923',
    name: '阜宁县',
    city_level: 3,
    lng: '119.802527',
    lat: '33.759325',
    count: 0
  },
  {
    code: '320924',
    name: '射阳县',
    city_level: 3,
    lng: '120.258053',
    lat: '33.774806',
    count: 0
  },
  {
    code: '320925',
    name: '建湖县',
    city_level: 3,
    lng: '119.798581',
    lat: '33.464204',
    count: 0
  },
  {
    code: '320981',
    name: '东台市',
    city_level: 3,
    lng: '120.320331',
    lat: '32.868440',
    count: 0
  },
  {
    code: '321002',
    name: '广陵区',
    city_level: 3,
    lng: '119.431849',
    lat: '32.394720',
    count: 0
  },
  {
    code: '321003',
    name: '邗江区',
    city_level: 3,
    lng: '119.398015',
    lat: '32.377528',
    count: 0
  },
  {
    code: '321012',
    name: '江都区',
    city_level: 3,
    lng: '119.569989',
    lat: '32.434672',
    count: 0
  },
  {
    code: '321023',
    name: '宝应县',
    city_level: 3,
    lng: '119.360729',
    lat: '33.240392',
    count: 0
  },
  {
    code: '321081',
    name: '仪征市',
    city_level: 3,
    lng: '119.184766',
    lat: '32.272258',
    count: 0
  },
  {
    code: '321084',
    name: '高邮市',
    city_level: 3,
    lng: '119.459161',
    lat: '32.781659',
    count: 0
  },
  {
    code: '321102',
    name: '京口区',
    city_level: 3,
    lng: '119.470160',
    lat: '32.198280',
    count: 0
  },
  {
    code: '321111',
    name: '润州区',
    city_level: 3,
    lng: '119.411945',
    lat: '32.195333',
    count: 0
  },
  {
    code: '321112',
    name: '丹徒区',
    city_level: 3,
    lng: '119.433854',
    lat: '32.131962',
    count: 0
  },
  {
    code: '321181',
    name: '丹阳市',
    city_level: 3,
    lng: '119.606536',
    lat: '32.010117',
    count: 0
  },
  {
    code: '321182',
    name: '扬中市',
    city_level: 3,
    lng: '119.797635',
    lat: '32.234831',
    count: 0
  },
  {
    code: '321183',
    name: '句容市',
    city_level: 3,
    lng: '119.168695',
    lat: '31.944999',
    count: 0
  },
  {
    code: '321202',
    name: '海陵区',
    city_level: 3,
    lng: '119.919425',
    lat: '32.491016',
    count: 0
  },
  {
    code: '321203',
    name: '高港区',
    city_level: 3,
    lng: '119.881717',
    lat: '32.318822',
    count: 0
  },
  {
    code: '321204',
    name: '姜堰区',
    city_level: 3,
    lng: '120.127934',
    lat: '32.509155',
    count: 0
  },
  {
    code: '321281',
    name: '兴化市',
    city_level: 3,
    lng: '119.852541',
    lat: '32.910459',
    count: 0
  },
  {
    code: '321282',
    name: '靖江市',
    city_level: 3,
    lng: '120.277138',
    lat: '31.982751',
    count: 0
  },
  {
    code: '321283',
    name: '泰兴市',
    city_level: 3,
    lng: '120.051744',
    lat: '32.171854',
    count: 0
  },
  {
    code: '321302',
    name: '宿城区',
    city_level: 3,
    lng: '118.242534',
    lat: '33.963029',
    count: 0
  },
  {
    code: '321311',
    name: '宿豫区',
    city_level: 3,
    lng: '118.330782',
    lat: '33.946822',
    count: 0
  },
  {
    code: '321322',
    name: '沭阳县',
    city_level: 3,
    lng: '118.804784',
    lat: '34.111022',
    count: 0
  },
  {
    code: '321323',
    name: '泗阳县',
    city_level: 3,
    lng: '118.703038',
    lat: '33.723140',
    count: 0
  },
  {
    code: '321324',
    name: '泗洪县',
    city_level: 3,
    lng: '118.223591',
    lat: '33.476051',
    count: 0
  },
  {
    code: '330102',
    name: '上城区',
    city_level: 3,
    lng: '120.184349',
    lat: '30.254460',
    count: 0
  },
  {
    code: '330103',
    name: '下城区',
    city_level: 3,
    lng: '120.180895',
    lat: '30.281714',
    count: 0
  },
  {
    code: '330104',
    name: '江干区',
    city_level: 3,
    lng: '120.213843',
    lat: '30.262661',
    count: 0
  },
  {
    code: '330105',
    name: '拱墅区',
    city_level: 3,
    lng: '120.141406',
    lat: '30.319037',
    count: 0
  },
  {
    code: '330106',
    name: '西湖区',
    city_level: 3,
    lng: '115.877233',
    lat: '28.657595',
    count: 0
  },
  {
    code: '330108',
    name: '滨江区',
    city_level: 3,
    lng: '120.146505',
    lat: '30.162450',
    count: 0
  },
  {
    code: '330109',
    name: '萧山区',
    city_level: 3,
    lng: '120.493286',
    lat: '30.283330',
    count: 0
  },
  {
    code: '330110',
    name: '余杭区',
    city_level: 3,
    lng: '120.245433',
    lat: '30.409974',
    count: 0
  },
  {
    code: '330111',
    name: '富阳区',
    city_level: 3,
    lng: '119.960076',
    lat: '30.048692',
    count: 0
  },
  {
    code: '330112',
    name: '临安区',
    city_level: 3,
    lng: '119.718891',
    lat: '30.237881',
    count: 0
  },
  {
    code: '330122',
    name: '桐庐县',
    city_level: 3,
    lng: '119.691434',
    lat: '29.793535',
    count: 0
  },
  {
    code: '330127',
    name: '淳安县',
    city_level: 3,
    lng: '119.041864',
    lat: '29.608772',
    count: 0
  },
  {
    code: '330182',
    name: '建德市',
    city_level: 3,
    lng: '119.281213',
    lat: '29.474884',
    count: 0
  },
  {
    code: '330203',
    name: '海曙区',
    city_level: 3,
    lng: '121.551066',
    lat: '29.859772',
    count: 0
  },
  {
    code: '330205',
    name: '江北区',
    city_level: 3,
    lng: '106.574271',
    lat: '29.606703',
    count: 0
  },
  {
    code: '330206',
    name: '北仑区',
    city_level: 3,
    lng: '121.844618',
    lat: '29.899044',
    count: 0
  },
  {
    code: '330211',
    name: '镇海区',
    city_level: 3,
    lng: '121.716540',
    lat: '29.948998',
    count: 0
  },
  {
    code: '330212',
    name: '鄞州区',
    city_level: 3,
    lng: '121.546603',
    lat: '29.816511',
    count: 0
  },
  {
    code: '330213',
    name: '奉化区',
    city_level: 3,
    lng: '121.410889',
    lat: '29.662348',
    count: 0
  },
  {
    code: '330225',
    name: '象山县',
    city_level: 3,
    lng: '121.869339',
    lat: '29.476705',
    count: 0
  },
  {
    code: '330226',
    name: '宁海县',
    city_level: 3,
    lng: '121.429477',
    lat: '29.287939',
    count: 0
  },
  {
    code: '330281',
    name: '余姚市',
    city_level: 3,
    lng: '121.154594',
    lat: '30.037858',
    count: 0
  },
  {
    code: '330282',
    name: '慈溪市',
    city_level: 3,
    lng: '121.266579',
    lat: '30.169665',
    count: 0
  },
  {
    code: '330302',
    name: '鹿城区',
    city_level: 3,
    lng: '120.655135',
    lat: '28.015455',
    count: 0
  },
  {
    code: '330303',
    name: '龙湾区',
    city_level: 3,
    lng: '120.811213',
    lat: '27.932747',
    count: 0
  },
  {
    code: '330304',
    name: '瓯海区',
    city_level: 3,
    lng: '120.708827',
    lat: '27.914241',
    count: 0
  },
  {
    code: '330305',
    name: '洞头区',
    city_level: 3,
    lng: '121.157249',
    lat: '27.836154',
    count: 0
  },
  {
    code: '330324',
    name: '永嘉县',
    city_level: 3,
    lng: '120.682144',
    lat: '28.150591',
    count: 0
  },
  {
    code: '330326',
    name: '平阳县',
    city_level: 3,
    lng: '120.565793',
    lat: '27.661918',
    count: 0
  },
  {
    code: '330327',
    name: '苍南县',
    city_level: 3,
    lng: '120.425766',
    lat: '27.518280',
    count: 0
  },
  {
    code: '330328',
    name: '文成县',
    city_level: 3,
    lng: '120.091498',
    lat: '27.786996',
    count: 0
  },
  {
    code: '330329',
    name: '泰顺县',
    city_level: 3,
    lng: '119.717649',
    lat: '27.556884',
    count: 0
  },
  {
    code: '330381',
    name: '瑞安市',
    city_level: 3,
    lng: '120.655148',
    lat: '27.778657',
    count: 0
  },
  {
    code: '330382',
    name: '乐清市',
    city_level: 3,
    lng: '120.967147',
    lat: '28.116083',
    count: 0
  },
  {
    code: '330383',
    name: '龙港市',
    city_level: 3,
    lng: '120.537832',
    lat: '27.561541',
    count: 0
  },
  {
    code: '330402',
    name: '南湖区',
    city_level: 3,
    lng: '120.783025',
    lat: '30.747842',
    count: 0
  },
  {
    code: '330411',
    name: '秀洲区',
    city_level: 3,
    lng: '120.709018',
    lat: '30.765168',
    count: 0
  },
  {
    code: '330421',
    name: '嘉善县',
    city_level: 3,
    lng: '120.925850',
    lat: '30.830898',
    count: 0
  },
  {
    code: '330424',
    name: '海盐县',
    city_level: 3,
    lng: '120.946263',
    lat: '30.526436',
    count: 0
  },
  {
    code: '330481',
    name: '海宁市',
    city_level: 3,
    lng: '120.680757',
    lat: '30.510659',
    count: 0
  },
  {
    code: '330482',
    name: '平湖市',
    city_level: 3,
    lng: '121.015142',
    lat: '30.677233',
    count: 0
  },
  {
    code: '330483',
    name: '桐乡市',
    city_level: 3,
    lng: '120.565099',
    lat: '30.630173',
    count: 0
  },
  {
    code: '330502',
    name: '吴兴区',
    city_level: 3,
    lng: '120.185838',
    lat: '30.857151',
    count: 0
  },
  {
    code: '330503',
    name: '南浔区',
    city_level: 3,
    lng: '120.418512',
    lat: '30.849690',
    count: 0
  },
  {
    code: '330521',
    name: '德清县',
    city_level: 3,
    lng: '119.977401',
    lat: '30.542510',
    count: 0
  },
  {
    code: '330522',
    name: '长兴县',
    city_level: 3,
    lng: '119.910952',
    lat: '31.026666',
    count: 0
  },
  {
    code: '330523',
    name: '安吉县',
    city_level: 3,
    lng: '119.680353',
    lat: '30.638675',
    count: 0
  },
  {
    code: '330602',
    name: '越城区',
    city_level: 3,
    lng: '120.582633',
    lat: '29.988245',
    count: 0
  },
  {
    code: '330603',
    name: '柯桥区',
    city_level: 3,
    lng: '120.495065',
    lat: '30.081942',
    count: 0
  },
  {
    code: '330604',
    name: '上虞区',
    city_level: 3,
    lng: '120.868122',
    lat: '30.033121',
    count: 0
  },
  {
    code: '330624',
    name: '新昌县',
    city_level: 3,
    lng: '120.903866',
    lat: '29.499832',
    count: 0
  },
  {
    code: '330681',
    name: '诸暨市',
    city_level: 3,
    lng: '120.246863',
    lat: '29.708692',
    count: 0
  },
  {
    code: '330683',
    name: '嵊州市',
    city_level: 3,
    lng: '120.831025',
    lat: '29.561410',
    count: 0
  },
  {
    code: '330702',
    name: '婺城区',
    city_level: 3,
    lng: '119.571633',
    lat: '29.086221',
    count: 0
  },
  {
    code: '330703',
    name: '金东区',
    city_level: 3,
    lng: '119.692862',
    lat: '29.099122',
    count: 0
  },
  {
    code: '330723',
    name: '武义县',
    city_level: 3,
    lng: '119.816318',
    lat: '28.892721',
    count: 0
  },
  {
    code: '330726',
    name: '浦江县',
    city_level: 3,
    lng: '119.892222',
    lat: '29.452477',
    count: 0
  },
  {
    code: '330727',
    name: '磐安县',
    city_level: 3,
    lng: '120.450178',
    lat: '29.054048',
    count: 0
  },
  {
    code: '330781',
    name: '兰溪市',
    city_level: 3,
    lng: '119.460526',
    lat: '29.208919',
    count: 0
  },
  {
    code: '330782',
    name: '义乌市',
    city_level: 3,
    lng: '120.075058',
    lat: '29.306841',
    count: 0
  },
  {
    code: '330783',
    name: '东阳市',
    city_level: 3,
    lng: '120.241566',
    lat: '29.289648',
    count: 0
  },
  {
    code: '330784',
    name: '永康市',
    city_level: 2,
    lng: '119.647444',
    lat: '29.079059',
    count: 0
  },
  {
    code: '330802',
    name: '柯城区',
    city_level: 3,
    lng: '118.871333',
    lat: '28.968504',
    count: 0
  },
  {
    code: '330803',
    name: '衢江区',
    city_level: 3,
    lng: '118.959460',
    lat: '28.979780',
    count: 0
  },
  {
    code: '330822',
    name: '常山县',
    city_level: 3,
    lng: '118.511249',
    lat: '28.901463',
    count: 0
  },
  {
    code: '330824',
    name: '开化县',
    city_level: 3,
    lng: '118.415495',
    lat: '29.137337',
    count: 0
  },
  {
    code: '330825',
    name: '龙游县',
    city_level: 3,
    lng: '119.172304',
    lat: '29.028319',
    count: 0
  },
  {
    code: '330881',
    name: '江山市',
    city_level: 3,
    lng: '118.626926',
    lat: '28.737245',
    count: 0
  },
  {
    code: '330902',
    name: '定海区',
    city_level: 3,
    lng: '122.106773',
    lat: '30.019858',
    count: 0
  },
  {
    code: '330903',
    name: '普陀区',
    city_level: 3,
    lng: '122.323867',
    lat: '29.971760',
    count: 0
  },
  {
    code: '330921',
    name: '岱山县',
    city_level: 3,
    lng: '122.226237',
    lat: '30.264139',
    count: 0
  },
  {
    code: '330922',
    name: '嵊泗县',
    city_level: 3,
    lng: '122.451382',
    lat: '30.725686',
    count: 0
  },
  {
    code: '331002',
    name: '椒江区',
    city_level: 3,
    lng: '121.442676',
    lat: '28.673726',
    count: 0
  },
  {
    code: '331003',
    name: '黄岩区',
    city_level: 3,
    lng: '121.261893',
    lat: '28.650117',
    count: 0
  },
  {
    code: '331004',
    name: '路桥区',
    city_level: 3,
    lng: '121.365123',
    lat: '28.582655',
    count: 0
  },
  {
    code: '331022',
    name: '三门县',
    city_level: 3,
    lng: '121.395777',
    lat: '29.104873',
    count: 0
  },
  {
    code: '331023',
    name: '天台县',
    city_level: 3,
    lng: '121.006725',
    lat: '29.144079',
    count: 0
  },
  {
    code: '331024',
    name: '仙居县',
    city_level: 3,
    lng: '120.735081',
    lat: '28.849213',
    count: 0
  },
  {
    code: '331081',
    name: '温岭市',
    city_level: 3,
    lng: '121.385604',
    lat: '28.372506',
    count: 0
  },
  {
    code: '331082',
    name: '临海市',
    city_level: 3,
    lng: '121.145047',
    lat: '28.858457',
    count: 0
  },
  {
    code: '331083',
    name: '玉环市',
    city_level: 3,
    lng: '121.232338',
    lat: '28.128420',
    count: 0
  },
  {
    code: '331102',
    name: '莲都区',
    city_level: 3,
    lng: '119.912612',
    lat: '28.445836',
    count: 0
  },
  {
    code: '331121',
    name: '青田县',
    city_level: 3,
    lng: '120.282989',
    lat: '28.134088',
    count: 0
  },
  {
    code: '331122',
    name: '缙云县',
    city_level: 3,
    lng: '120.091573',
    lat: '28.659279',
    count: 0
  },
  {
    code: '331123',
    name: '遂昌县',
    city_level: 3,
    lng: '119.276104',
    lat: '28.592119',
    count: 0
  },
  {
    code: '331124',
    name: '松阳县',
    city_level: 3,
    lng: '119.482015',
    lat: '28.449171',
    count: 0
  },
  {
    code: '331125',
    name: '云和县',
    city_level: 3,
    lng: '119.573397',
    lat: '28.115790',
    count: 0
  },
  {
    code: '331126',
    name: '庆元县',
    city_level: 3,
    lng: '119.062590',
    lat: '27.619220',
    count: 0
  },
  {
    code: '331127',
    name: '景宁畲族自治县',
    city_level: 3,
    lng: '119.635697',
    lat: '27.973312',
    count: 0
  },
  {
    code: '331181',
    name: '龙泉市',
    city_level: 3,
    lng: '119.141461',
    lat: '28.074623',
    count: 0
  },
  {
    code: '340102',
    name: '瑶海区',
    city_level: 3,
    lng: '117.309229',
    lat: '31.858048',
    count: 0
  },
  {
    code: '340103',
    name: '庐阳区',
    city_level: 3,
    lng: '117.264595',
    lat: '31.878641',
    count: 0
  },
  {
    code: '340104',
    name: '蜀山区',
    city_level: 3,
    lng: '117.260536',
    lat: '31.851158',
    count: 0
  },
  {
    code: '340111',
    name: '包河区',
    city_level: 3,
    lng: '117.309658',
    lat: '31.793093',
    count: 0
  },
  {
    code: '340121',
    name: '长丰县',
    city_level: 3,
    lng: '117.167564',
    lat: '32.478018',
    count: 0
  },
  {
    code: '340122',
    name: '肥东县',
    city_level: 3,
    lng: '117.469383',
    lat: '31.887940',
    count: 0
  },
  {
    code: '340123',
    name: '肥西县',
    city_level: 3,
    lng: '117.157981',
    lat: '31.706810',
    count: 0
  },
  {
    code: '340124',
    name: '庐江县',
    city_level: 3,
    lng: '117.287800',
    lat: '31.255550',
    count: 0
  },
  {
    code: '340181',
    name: '巢湖市',
    city_level: 3,
    lng: '117.861800',
    lat: '31.598628',
    count: 0
  },
  {
    code: '340202',
    name: '镜湖区',
    city_level: 3,
    lng: '118.385146',
    lat: '31.340404',
    count: 0
  },
  {
    code: '340207',
    name: '鸠江区',
    city_level: 3,
    lng: '118.391734',
    lat: '31.369373',
    count: 0
  },
  {
    code: '340209',
    name: '弋江区',
    city_level: 3,
    lng: '118.372655',
    lat: '31.311757',
    count: 0
  },
  {
    code: '340210',
    name: '湾沚区',
    city_level: 3,
    lng: '118.585300',
    lat: '31.127300',
    count: 0
  },
  {
    code: '340212',
    name: '繁昌区',
    city_level: 3,
    lng: '118.201347',
    lat: '31.080896',
    count: 0
  },
  {
    code: '340223',
    name: '南陵县',
    city_level: 3,
    lng: '118.334360',
    lat: '30.914923',
    count: 0
  },
  {
    code: '340281',
    name: '无为市',
    city_level: 3,
    lng: '117.911430',
    lat: '31.303074',
    count: 0
  },
  {
    code: '340302',
    name: '龙子湖区',
    city_level: 3,
    lng: '117.393790',
    lat: '32.943014',
    count: 0
  },
  {
    code: '340303',
    name: '蚌山区',
    city_level: 3,
    lng: '117.367614',
    lat: '32.944198',
    count: 0
  },
  {
    code: '340304',
    name: '禹会区',
    city_level: 3,
    lng: '117.342451',
    lat: '32.929711',
    count: 0
  },
  {
    code: '340311',
    name: '淮上区',
    city_level: 3,
    lng: '117.359331',
    lat: '32.965435',
    count: 0
  },
  {
    code: '340321',
    name: '怀远县',
    city_level: 3,
    lng: '117.205234',
    lat: '32.970031',
    count: 0
  },
  {
    code: '340322',
    name: '五河县',
    city_level: 3,
    lng: '117.879486',
    lat: '33.127823',
    count: 0
  },
  {
    code: '340323',
    name: '固镇县',
    city_level: 3,
    lng: '117.316955',
    lat: '33.316899',
    count: 0
  },
  {
    code: '340402',
    name: '大通区',
    city_level: 3,
    lng: '117.053273',
    lat: '32.631533',
    count: 0
  },
  {
    code: '340403',
    name: '田家庵区',
    city_level: 3,
    lng: '117.017409',
    lat: '32.647155',
    count: 0
  },
  {
    code: '340404',
    name: '谢家集区',
    city_level: 3,
    lng: '116.859048',
    lat: '32.599901',
    count: 0
  },
  {
    code: '340405',
    name: '八公山区',
    city_level: 3,
    lng: '116.833490',
    lat: '32.631379',
    count: 0
  },
  {
    code: '340406',
    name: '潘集区',
    city_level: 3,
    lng: '116.834716',
    lat: '32.772080',
    count: 0
  },
  {
    code: '340421',
    name: '凤台县',
    city_level: 3,
    lng: '116.711051',
    lat: '32.709445',
    count: 0
  },
  {
    code: '340422',
    name: '寿县',
    city_level: 3,
    lng: '116.787141',
    lat: '32.573306',
    count: 0
  },
  {
    code: '340503',
    name: '花山区',
    city_level: 3,
    lng: '118.492562',
    lat: '31.719710',
    count: 0
  },
  {
    code: '340504',
    name: '雨山区',
    city_level: 3,
    lng: '118.498560',
    lat: '31.682208',
    count: 0
  },
  {
    code: '340506',
    name: '博望区',
    city_level: 3,
    lng: '118.844538',
    lat: '31.558471',
    count: 0
  },
  {
    code: '340521',
    name: '当涂县',
    city_level: 3,
    lng: '118.497972',
    lat: '31.571213',
    count: 0
  },
  {
    code: '340522',
    name: '含山县',
    city_level: 3,
    lng: '118.101421',
    lat: '31.735599',
    count: 0
  },
  {
    code: '340523',
    name: '和县',
    city_level: 3,
    lng: '118.351405',
    lat: '31.741794',
    count: 0
  },
  {
    code: '340602',
    name: '杜集区',
    city_level: 3,
    lng: '116.828134',
    lat: '33.991451',
    count: 0
  },
  {
    code: '340603',
    name: '相山区',
    city_level: 3,
    lng: '116.794345',
    lat: '33.959893',
    count: 0
  },
  {
    code: '340604',
    name: '烈山区',
    city_level: 3,
    lng: '116.813042',
    lat: '33.895139',
    count: 0
  },
  {
    code: '340621',
    name: '濉溪县',
    city_level: 3,
    lng: '116.766299',
    lat: '33.915477',
    count: 0
  },
  {
    code: '340705',
    name: '铜官区',
    city_level: 3,
    lng: '117.816174',
    lat: '30.927608',
    count: 0
  },
  {
    code: '340706',
    name: '义安区',
    city_level: 2,
    lng: '117.812079',
    lat: '30.945429',
    count: 0
  },
  {
    code: '340711',
    name: '郊区',
    city_level: 3,
    lng: '117.807070',
    lat: '30.908927',
    count: 0
  },
  {
    code: '340722',
    name: '枞阳县',
    city_level: 3,
    lng: '117.250622',
    lat: '30.705963',
    count: 0
  },
  {
    code: '340802',
    name: '迎江区',
    city_level: 3,
    lng: '117.091150',
    lat: '30.511548',
    count: 0
  },
  {
    code: '340803',
    name: '大观区',
    city_level: 3,
    lng: '117.021670',
    lat: '30.553957',
    count: 0
  },
  {
    code: '340811',
    name: '宜秀区',
    city_level: 3,
    lng: '117.070003',
    lat: '30.541323',
    count: 0
  },
  {
    code: '340822',
    name: '怀宁县',
    city_level: 3,
    lng: '116.829475',
    lat: '30.733825',
    count: 0
  },
  {
    code: '340825',
    name: '太湖县',
    city_level: 3,
    lng: '116.308795',
    lat: '30.454220',
    count: 0
  },
  {
    code: '340826',
    name: '宿松县',
    city_level: 3,
    lng: '116.129105',
    lat: '30.153746',
    count: 0
  },
  {
    code: '340827',
    name: '望江县',
    city_level: 3,
    lng: '116.694183',
    lat: '30.124428',
    count: 0
  },
  {
    code: '340828',
    name: '岳西县',
    city_level: 3,
    lng: '116.359921',
    lat: '30.849442',
    count: 0
  },
  {
    code: '340881',
    name: '桐城市',
    city_level: 3,
    lng: '116.974120',
    lat: '31.035800',
    count: 0
  },
  {
    code: '340882',
    name: '潜山市',
    city_level: 3,
    lng: '116.576770',
    lat: '30.628638',
    count: 0
  },
  {
    code: '341002',
    name: '屯溪区',
    city_level: 3,
    lng: '118.315329',
    lat: '29.696109',
    count: 0
  },
  {
    code: '341003',
    name: '黄山区',
    city_level: 3,
    lng: '118.141568',
    lat: '30.272942',
    count: 0
  },
  {
    code: '341004',
    name: '徽州区',
    city_level: 3,
    lng: '118.336751',
    lat: '29.827279',
    count: 0
  },
  {
    code: '341021',
    name: '歙县',
    city_level: 3,
    lng: '118.415356',
    lat: '29.861308',
    count: 0
  },
  {
    code: '341022',
    name: '休宁县',
    city_level: 3,
    lng: '118.199179',
    lat: '29.789095',
    count: 0
  },
  {
    code: '341023',
    name: '黟县',
    city_level: 3,
    lng: '117.938373',
    lat: '29.924805',
    count: 0
  },
  {
    code: '341024',
    name: '祁门县',
    city_level: 3,
    lng: '117.717396',
    lat: '29.854055',
    count: 0
  },
  {
    code: '341102',
    name: '琅琊区',
    city_level: 3,
    lng: '118.305843',
    lat: '32.294530',
    count: 0
  },
  {
    code: '341103',
    name: '南谯区',
    city_level: 3,
    lng: '118.296955',
    lat: '32.329842',
    count: 0
  },
  {
    code: '341122',
    name: '来安县',
    city_level: 3,
    lng: '118.435749',
    lat: '32.452167',
    count: 0
  },
  {
    code: '341124',
    name: '全椒县',
    city_level: 3,
    lng: '118.273090',
    lat: '32.085407',
    count: 0
  },
  {
    code: '341125',
    name: '定远县',
    city_level: 3,
    lng: '117.698563',
    lat: '32.530982',
    count: 0
  },
  {
    code: '341126',
    name: '凤阳县',
    city_level: 3,
    lng: '117.531623',
    lat: '32.874735',
    count: 0
  },
  {
    code: '341181',
    name: '天长市',
    city_level: 3,
    lng: '119.004817',
    lat: '32.667571',
    count: 0
  },
  {
    code: '341182',
    name: '明光市',
    city_level: 3,
    lng: '118.018276',
    lat: '32.781995',
    count: 0
  },
  {
    code: '341202',
    name: '颍州区',
    city_level: 3,
    lng: '115.806942',
    lat: '32.883468',
    count: 0
  },
  {
    code: '341203',
    name: '颍东区',
    city_level: 3,
    lng: '115.856735',
    lat: '32.912480',
    count: 0
  },
  {
    code: '341204',
    name: '颍泉区',
    city_level: 3,
    lng: '115.808327',
    lat: '32.924918',
    count: 0
  },
  {
    code: '341221',
    name: '临泉县',
    city_level: 3,
    lng: '115.261473',
    lat: '33.040261',
    count: 0
  },
  {
    code: '341222',
    name: '太和县',
    city_level: 3,
    lng: '115.621934',
    lat: '33.160326',
    count: 0
  },
  {
    code: '341225',
    name: '阜南县',
    city_level: 3,
    lng: '115.595644',
    lat: '32.658297',
    count: 0
  },
  {
    code: '341226',
    name: '颍上县',
    city_level: 3,
    lng: '116.256789',
    lat: '32.653255',
    count: 0
  },
  {
    code: '341282',
    name: '界首市',
    city_level: 3,
    lng: '115.374564',
    lat: '33.257013',
    count: 0
  },
  {
    code: '341302',
    name: '埇桥区',
    city_level: 3,
    lng: '116.977463',
    lat: '33.640061',
    count: 0
  },
  {
    code: '341321',
    name: '砀山县',
    city_level: 3,
    lng: '116.367095',
    lat: '34.442561',
    count: 0
  },
  {
    code: '341322',
    name: '萧县',
    city_level: 3,
    lng: '116.947290',
    lat: '34.188728',
    count: 0
  },
  {
    code: '341323',
    name: '灵璧县',
    city_level: 3,
    lng: '117.552462',
    lat: '33.552998',
    count: 0
  },
  {
    code: '341324',
    name: '泗县',
    city_level: 3,
    lng: '117.910629',
    lat: '33.482982',
    count: 0
  },
  {
    code: '341502',
    name: '金安区',
    city_level: 3,
    lng: '116.539679',
    lat: '31.749265',
    count: 0
  },
  {
    code: '341503',
    name: '裕安区',
    city_level: 3,
    lng: '116.479920',
    lat: '31.737813',
    count: 0
  },
  {
    code: '341504',
    name: '叶集区',
    city_level: 3,
    lng: '115.908504',
    lat: '31.849165',
    count: 0
  },
  {
    code: '341522',
    name: '霍邱县',
    city_level: 3,
    lng: '116.277912',
    lat: '32.353038',
    count: 0
  },
  {
    code: '341523',
    name: '舒城县',
    city_level: 3,
    lng: '116.948623',
    lat: '31.462027',
    count: 0
  },
  {
    code: '341524',
    name: '金寨县',
    city_level: 3,
    lng: '115.934366',
    lat: '31.727170',
    count: 0
  },
  {
    code: '341525',
    name: '霍山县',
    city_level: 3,
    lng: '116.332951',
    lat: '31.392786',
    count: 0
  },
  {
    code: '341602',
    name: '谯城区',
    city_level: 3,
    lng: '115.779025',
    lat: '33.876235',
    count: 0
  },
  {
    code: '341621',
    name: '涡阳县',
    city_level: 3,
    lng: '116.215665',
    lat: '33.492921',
    count: 0
  },
  {
    code: '341622',
    name: '蒙城县',
    city_level: 3,
    lng: '116.564248',
    lat: '33.265831',
    count: 0
  },
  {
    code: '341623',
    name: '利辛县',
    city_level: 3,
    lng: '116.208635',
    lat: '33.144724',
    count: 0
  },
  {
    code: '341702',
    name: '贵池区',
    city_level: 3,
    lng: '117.567379',
    lat: '30.687181',
    count: 0
  },
  {
    code: '341721',
    name: '东至县',
    city_level: 3,
    lng: '117.027533',
    lat: '30.111182',
    count: 0
  },
  {
    code: '341722',
    name: '石台县',
    city_level: 3,
    lng: '117.486306',
    lat: '30.210313',
    count: 0
  },
  {
    code: '341723',
    name: '青阳县',
    city_level: 3,
    lng: '117.847362',
    lat: '30.639230',
    count: 0
  },
  {
    code: '341802',
    name: '宣州区',
    city_level: 3,
    lng: '118.756328',
    lat: '30.946319',
    count: 0
  },
  {
    code: '341821',
    name: '郎溪县',
    city_level: 3,
    lng: '119.179657',
    lat: '31.126412',
    count: 0
  },
  {
    code: '341823',
    name: '泾县',
    city_level: 3,
    lng: '118.419864',
    lat: '30.688578',
    count: 0
  },
  {
    code: '341824',
    name: '绩溪县',
    city_level: 3,
    lng: '118.578519',
    lat: '30.067533',
    count: 0
  },
  {
    code: '341825',
    name: '旌德县',
    city_level: 3,
    lng: '118.540487',
    lat: '30.286350',
    count: 0
  },
  {
    code: '341881',
    name: '宁国市',
    city_level: 3,
    lng: '118.982915',
    lat: '30.633571',
    count: 0
  },
  {
    code: '341882',
    name: '广德市',
    city_level: 3,
    lng: '119.422889',
    lat: '30.876054',
    count: 0
  },
  {
    code: '350102',
    name: '鼓楼区',
    city_level: 3,
    lng: '114.348307',
    lat: '34.788561',
    count: 0
  },
  {
    code: '350103',
    name: '台江区',
    city_level: 3,
    lng: '119.314054',
    lat: '26.052842',
    count: 0
  },
  {
    code: '350104',
    name: '仓山区',
    city_level: 3,
    lng: '119.273546',
    lat: '26.046744',
    count: 0
  },
  {
    code: '350105',
    name: '马尾区',
    city_level: 3,
    lng: '119.455589',
    lat: '25.989500',
    count: 0
  },
  {
    code: '350111',
    name: '晋安区',
    city_level: 3,
    lng: '119.328515',
    lat: '26.082105',
    count: 0
  },
  {
    code: '350112',
    name: '长乐区',
    city_level: 3,
    lng: '119.523337',
    lat: '25.962975',
    count: 0
  },
  {
    code: '350121',
    name: '闽侯县',
    city_level: 3,
    lng: '119.131725',
    lat: '26.150047',
    count: 0
  },
  {
    code: '350122',
    name: '连江县',
    city_level: 3,
    lng: '119.539704',
    lat: '26.197364',
    count: 0
  },
  {
    code: '350123',
    name: '罗源县',
    city_level: 3,
    lng: '119.549776',
    lat: '26.489559',
    count: 0
  },
  {
    code: '350124',
    name: '闽清县',
    city_level: 3,
    lng: '118.863361',
    lat: '26.221198',
    count: 0
  },
  {
    code: '350125',
    name: '永泰县',
    city_level: 3,
    lng: '118.932592',
    lat: '25.866695',
    count: 0
  },
  {
    code: '350128',
    name: '平潭县',
    city_level: 3,
    lng: '119.790168',
    lat: '25.498720',
    count: 0
  },
  {
    code: '350181',
    name: '福清市',
    city_level: 3,
    lng: '119.384334',
    lat: '25.721143',
    count: 0
  },
  {
    code: '350203',
    name: '思明区',
    city_level: 3,
    lng: '118.082658',
    lat: '24.445567',
    count: 0
  },
  {
    code: '350205',
    name: '海沧区',
    city_level: 3,
    lng: '118.032818',
    lat: '24.484503',
    count: 0
  },
  {
    code: '350206',
    name: '湖里区',
    city_level: 3,
    lng: '118.146769',
    lat: '24.512905',
    count: 0
  },
  {
    code: '350211',
    name: '集美区',
    city_level: 3,
    lng: '118.097337',
    lat: '24.575970',
    count: 0
  },
  {
    code: '350212',
    name: '同安区',
    city_level: 3,
    lng: '118.152149',
    lat: '24.722747',
    count: 0
  },
  {
    code: '350213',
    name: '翔安区',
    city_level: 3,
    lng: '118.248034',
    lat: '24.618544',
    count: 0
  },
  {
    code: '350302',
    name: '城厢区',
    city_level: 3,
    lng: '118.993885',
    lat: '25.419319',
    count: 0
  },
  {
    code: '350303',
    name: '涵江区',
    city_level: 3,
    lng: '119.116290',
    lat: '25.458720',
    count: 0
  },
  {
    code: '350304',
    name: '荔城区',
    city_level: 3,
    lng: '119.015123',
    lat: '25.431979',
    count: 0
  },
  {
    code: '350305',
    name: '秀屿区',
    city_level: 3,
    lng: '119.105528',
    lat: '25.318672',
    count: 0
  },
  {
    code: '350322',
    name: '仙游县',
    city_level: 3,
    lng: '118.691601',
    lat: '25.362094',
    count: 0
  },
  {
    code: '350402',
    name: '梅列区',
    city_level: 3,
    lng: '117.645856',
    lat: '26.271711',
    count: 0
  },
  {
    code: '350403',
    name: '三元区',
    city_level: 3,
    lng: '117.608045',
    lat: '26.234020',
    count: 0
  },
  {
    code: '350421',
    name: '明溪县',
    city_level: 3,
    lng: '117.201847',
    lat: '26.357379',
    count: 0
  },
  {
    code: '350423',
    name: '清流县',
    city_level: 3,
    lng: '116.816909',
    lat: '26.177797',
    count: 0
  },
  {
    code: '350424',
    name: '宁化县',
    city_level: 3,
    lng: '116.654365',
    lat: '26.261754',
    count: 0
  },
  {
    code: '350425',
    name: '大田县',
    city_level: 3,
    lng: '117.847115',
    lat: '25.692699',
    count: 0
  },
  {
    code: '350426',
    name: '尤溪县',
    city_level: 3,
    lng: '118.190467',
    lat: '26.170171',
    count: 0
  },
  {
    code: '350427',
    name: '沙县',
    city_level: 3,
    lng: '117.792450',
    lat: '26.397300',
    count: 0
  },
  {
    code: '350428',
    name: '将乐县',
    city_level: 3,
    lng: '117.471373',
    lat: '26.728953',
    count: 0
  },
  {
    code: '350429',
    name: '泰宁县',
    city_level: 3,
    lng: '117.175740',
    lat: '26.900259',
    count: 0
  },
  {
    code: '350430',
    name: '建宁县',
    city_level: 3,
    lng: '116.846084',
    lat: '26.830902',
    count: 0
  },
  {
    code: '350481',
    name: '永安市',
    city_level: 3,
    lng: '117.374461',
    lat: '25.976394',
    count: 0
  },
  {
    code: '350502',
    name: '鲤城区',
    city_level: 3,
    lng: '118.586884',
    lat: '24.907581',
    count: 0
  },
  {
    code: '350503',
    name: '丰泽区',
    city_level: 3,
    lng: '118.668527',
    lat: '24.871018',
    count: 0
  },
  {
    code: '350504',
    name: '洛江区',
    city_level: 3,
    lng: '118.671193',
    lat: '24.939751',
    count: 0
  },
  {
    code: '350505',
    name: '泉港区',
    city_level: 3,
    lng: '118.916309',
    lat: '25.119815',
    count: 0
  },
  {
    code: '350521',
    name: '惠安县',
    city_level: 3,
    lng: '118.796605',
    lat: '25.030781',
    count: 0
  },
  {
    code: '350524',
    name: '安溪县',
    city_level: 3,
    lng: '118.186289',
    lat: '25.055955',
    count: 0
  },
  {
    code: '350525',
    name: '永春县',
    city_level: 3,
    lng: '118.294048',
    lat: '25.321565',
    count: 0
  },
  {
    code: '350526',
    name: '德化县',
    city_level: 3,
    lng: '118.241094',
    lat: '25.491494',
    count: 0
  },
  {
    code: '350527',
    name: '金门县',
    city_level: 3,
    lng: '118.317089',
    lat: '24.432706',
    count: 0
  },
  {
    code: '350581',
    name: '石狮市',
    city_level: 3,
    lng: '118.648066',
    lat: '24.732204',
    count: 0
  },
  {
    code: '350582',
    name: '晋江市',
    city_level: 3,
    lng: '118.552365',
    lat: '24.781681',
    count: 0
  },
  {
    code: '350583',
    name: '南安市',
    city_level: 3,
    lng: '118.386279',
    lat: '24.960385',
    count: 0
  },
  {
    code: '350602',
    name: '芗城区',
    city_level: 3,
    lng: '117.653975',
    lat: '24.510900',
    count: 0
  },
  {
    code: '350603',
    name: '龙文区',
    city_level: 3,
    lng: '117.709755',
    lat: '24.503113',
    count: 0
  },
  {
    code: '350622',
    name: '云霄县',
    city_level: 3,
    lng: '117.339573',
    lat: '23.957936',
    count: 0
  },
  {
    code: '350623',
    name: '漳浦县',
    city_level: 3,
    lng: '117.613808',
    lat: '24.117102',
    count: 0
  },
  {
    code: '350624',
    name: '诏安县',
    city_level: 3,
    lng: '117.175185',
    lat: '23.711579',
    count: 0
  },
  {
    code: '350625',
    name: '长泰县',
    city_level: 3,
    lng: '117.759153',
    lat: '24.625449',
    count: 0
  },
  {
    code: '350626',
    name: '东山县',
    city_level: 3,
    lng: '117.430061',
    lat: '23.701262',
    count: 0
  },
  {
    code: '350627',
    name: '南靖县',
    city_level: 3,
    lng: '117.357321',
    lat: '24.514654',
    count: 0
  },
  {
    code: '350628',
    name: '平和县',
    city_level: 3,
    lng: '117.314891',
    lat: '24.363437',
    count: 0
  },
  {
    code: '350629',
    name: '华安县',
    city_level: 3,
    lng: '117.534103',
    lat: '25.004425',
    count: 0
  },
  {
    code: '350681',
    name: '龙海市',
    city_level: 3,
    lng: '117.818197',
    lat: '24.446706',
    count: 0
  },
  {
    code: '350702',
    name: '延平区',
    city_level: 3,
    lng: '118.181894',
    lat: '26.637457',
    count: 0
  },
  {
    code: '350703',
    name: '建阳区',
    city_level: 3,
    lng: '118.120464',
    lat: '27.331876',
    count: 0
  },
  {
    code: '350721',
    name: '顺昌县',
    city_level: 3,
    lng: '117.810357',
    lat: '26.793288',
    count: 0
  },
  {
    code: '350722',
    name: '浦城县',
    city_level: 3,
    lng: '118.541256',
    lat: '27.917263',
    count: 0
  },
  {
    code: '350723',
    name: '光泽县',
    city_level: 3,
    lng: '117.334106',
    lat: '27.540988',
    count: 0
  },
  {
    code: '350724',
    name: '松溪县',
    city_level: 3,
    lng: '118.785468',
    lat: '27.526232',
    count: 0
  },
  {
    code: '350725',
    name: '政和县',
    city_level: 3,
    lng: '118.857642',
    lat: '27.366104',
    count: 0
  },
  {
    code: '350781',
    name: '邵武市',
    city_level: 3,
    lng: '117.492534',
    lat: '27.340327',
    count: 0
  },
  {
    code: '350782',
    name: '武夷山市',
    city_level: 3,
    lng: '118.035310',
    lat: '27.756648',
    count: 0
  },
  {
    code: '350783',
    name: '建瓯市',
    city_level: 3,
    lng: '118.304988',
    lat: '27.022727',
    count: 0
  },
  {
    code: '350802',
    name: '新罗区',
    city_level: 3,
    lng: '117.037264',
    lat: '25.098292',
    count: 0
  },
  {
    code: '350803',
    name: '永定区',
    city_level: 3,
    lng: '110.537138',
    lat: '29.119856',
    count: 0
  },
  {
    code: '350821',
    name: '长汀县',
    city_level: 3,
    lng: '116.357581',
    lat: '25.833531',
    count: 0
  },
  {
    code: '350823',
    name: '上杭县',
    city_level: 3,
    lng: '116.420099',
    lat: '25.049518',
    count: 0
  },
  {
    code: '350824',
    name: '武平县',
    city_level: 3,
    lng: '116.100408',
    lat: '25.095298',
    count: 0
  },
  {
    code: '350825',
    name: '连城县',
    city_level: 3,
    lng: '116.754473',
    lat: '25.710539',
    count: 0
  },
  {
    code: '350881',
    name: '漳平市',
    city_level: 3,
    lng: '117.419998',
    lat: '25.290185',
    count: 0
  },
  {
    code: '350902',
    name: '蕉城区',
    city_level: 3,
    lng: '119.526299',
    lat: '26.660611',
    count: 0
  },
  {
    code: '350921',
    name: '霞浦县',
    city_level: 3,
    lng: '120.005643',
    lat: '26.885204',
    count: 0
  },
  {
    code: '350922',
    name: '古田县',
    city_level: 3,
    lng: '118.746284',
    lat: '26.577837',
    count: 0
  },
  {
    code: '350923',
    name: '屏南县',
    city_level: 3,
    lng: '118.985895',
    lat: '26.908276',
    count: 0
  },
  {
    code: '350924',
    name: '寿宁县',
    city_level: 3,
    lng: '119.514987',
    lat: '27.454479',
    count: 0
  },
  {
    code: '350925',
    name: '周宁县',
    city_level: 3,
    lng: '119.339025',
    lat: '27.104591',
    count: 0
  },
  {
    code: '350926',
    name: '柘荣县',
    city_level: 3,
    lng: '119.900609',
    lat: '27.233933',
    count: 0
  },
  {
    code: '350981',
    name: '福安市',
    city_level: 3,
    lng: '119.652171',
    lat: '27.087184',
    count: 0
  },
  {
    code: '350982',
    name: '福鼎市',
    city_level: 3,
    lng: '120.216978',
    lat: '27.324479',
    count: 0
  },
  {
    code: '360102',
    name: '东湖区',
    city_level: 3,
    lng: '115.899262',
    lat: '28.685085',
    count: 0
  },
  {
    code: '360103',
    name: '西湖区',
    city_level: 3,
    lng: '115.877233',
    lat: '28.657595',
    count: 0
  },
  {
    code: '360104',
    name: '青云谱区',
    city_level: 3,
    lng: '115.925749',
    lat: '28.621169',
    count: 0
  },
  {
    code: '360111',
    name: '青山湖区',
    city_level: 3,
    lng: '115.962144',
    lat: '28.682985',
    count: 0
  },
  {
    code: '360112',
    name: '新建区',
    city_level: 3,
    lng: '115.815278',
    lat: '28.692864',
    count: 0
  },
  {
    code: '360113',
    name: '红谷滩区',
    city_level: 3,
    lng: '115.806324',
    lat: '28.665312',
    count: 0
  },
  {
    code: '360121',
    name: '南昌县',
    city_level: 3,
    lng: '115.944304',
    lat: '28.545602',
    count: 0
  },
  {
    code: '360123',
    name: '安义县',
    city_level: 3,
    lng: '115.549247',
    lat: '28.844507',
    count: 0
  },
  {
    code: '360124',
    name: '进贤县',
    city_level: 3,
    lng: '116.269480',
    lat: '28.366913',
    count: 0
  },
  {
    code: '360202',
    name: '昌江区',
    city_level: 3,
    lng: '117.183748',
    lat: '29.273435',
    count: 0
  },
  {
    code: '360203',
    name: '珠山区',
    city_level: 3,
    lng: '117.202893',
    lat: '29.299923',
    count: 0
  },
  {
    code: '360222',
    name: '浮梁县',
    city_level: 3,
    lng: '117.215086',
    lat: '29.351681',
    count: 0
  },
  {
    code: '360281',
    name: '乐平市',
    city_level: 3,
    lng: '117.151796',
    lat: '28.978440',
    count: 0
  },
  {
    code: '360302',
    name: '安源区',
    city_level: 3,
    lng: '113.870730',
    lat: '27.615202',
    count: 0
  },
  {
    code: '360313',
    name: '湘东区',
    city_level: 3,
    lng: '113.733047',
    lat: '27.640075',
    count: 0
  },
  {
    code: '360321',
    name: '莲花县',
    city_level: 3,
    lng: '113.961516',
    lat: '27.127658',
    count: 0
  },
  {
    code: '360322',
    name: '上栗县',
    city_level: 3,
    lng: '113.795311',
    lat: '27.880302',
    count: 0
  },
  {
    code: '360323',
    name: '芦溪县',
    city_level: 3,
    lng: '114.029828',
    lat: '27.630806',
    count: 0
  },
  {
    code: '360402',
    name: '濂溪区',
    city_level: 3,
    lng: '115.989197',
    lat: '29.671694',
    count: 0
  },
  {
    code: '360403',
    name: '浔阳区',
    city_level: 3,
    lng: '115.990301',
    lat: '29.727593',
    count: 0
  },
  {
    code: '360404',
    name: '柴桑区',
    city_level: 3,
    lng: '115.911323',
    lat: '29.608431',
    count: 0
  },
  {
    code: '360423',
    name: '武宁县',
    city_level: 3,
    lng: '115.100578',
    lat: '29.256323',
    count: 0
  },
  {
    code: '360424',
    name: '修水县',
    city_level: 3,
    lng: '114.546702',
    lat: '29.026022',
    count: 0
  },
  {
    code: '360425',
    name: '永修县',
    city_level: 3,
    lng: '115.832453',
    lat: '29.012297',
    count: 0
  },
  {
    code: '360426',
    name: '德安县',
    city_level: 3,
    lng: '115.767484',
    lat: '29.298738',
    count: 0
  },
  {
    code: '360428',
    name: '都昌县',
    city_level: 3,
    lng: '116.204099',
    lat: '29.273194',
    count: 0
  },
  {
    code: '360429',
    name: '湖口县',
    city_level: 3,
    lng: '116.251947',
    lat: '29.731101',
    count: 0
  },
  {
    code: '360430',
    name: '彭泽县',
    city_level: 3,
    lng: '116.549359',
    lat: '29.896061',
    count: 0
  },
  {
    code: '360481',
    name: '瑞昌市',
    city_level: 3,
    lng: '115.681337',
    lat: '29.675874',
    count: 0
  },
  {
    code: '360482',
    name: '共青城市',
    city_level: 3,
    lng: '115.808844',
    lat: '29.248317',
    count: 0
  },
  {
    code: '360483',
    name: '庐山市',
    city_level: 3,
    lng: '116.045060',
    lat: '29.448128',
    count: 0
  },
  {
    code: '360502',
    name: '渝水区',
    city_level: 3,
    lng: '114.944646',
    lat: '27.800387',
    count: 0
  },
  {
    code: '360521',
    name: '分宜县',
    city_level: 3,
    lng: '114.692050',
    lat: '27.814758',
    count: 0
  },
  {
    code: '360602',
    name: '月湖区',
    city_level: 3,
    lng: '117.037137',
    lat: '28.239153',
    count: 0
  },
  {
    code: '360603',
    name: '余江区',
    city_level: 3,
    lng: '117.013288',
    lat: '28.206010',
    count: 0
  },
  {
    code: '360681',
    name: '贵溪市',
    city_level: 3,
    lng: '117.245497',
    lat: '28.292519',
    count: 0
  },
  {
    code: '360702',
    name: '章贡区',
    city_level: 3,
    lng: '114.921171',
    lat: '25.817816',
    count: 0
  },
  {
    code: '360703',
    name: '南康区',
    city_level: 2,
    lng: '114.935029',
    lat: '25.831829',
    count: 0
  },
  {
    code: '360704',
    name: '赣县区',
    city_level: 3,
    lng: '115.018463',
    lat: '25.865431',
    count: 0
  },
  {
    code: '360722',
    name: '信丰县',
    city_level: 3,
    lng: '114.922830',
    lat: '25.386704',
    count: 0
  },
  {
    code: '360723',
    name: '大余县',
    city_level: 3,
    lng: '114.362112',
    lat: '25.401314',
    count: 0
  },
  {
    code: '360724',
    name: '上犹县',
    city_level: 3,
    lng: '114.551138',
    lat: '25.785172',
    count: 0
  },
  {
    code: '360725',
    name: '崇义县',
    city_level: 3,
    lng: '114.308267',
    lat: '25.681784',
    count: 0
  },
  {
    code: '360726',
    name: '安远县',
    city_level: 3,
    lng: '115.393922',
    lat: '25.136927',
    count: 0
  },
  {
    code: '360728',
    name: '定南县',
    city_level: 3,
    lng: '115.027845',
    lat: '24.784410',
    count: 0
  },
  {
    code: '360729',
    name: '全南县',
    city_level: 3,
    lng: '114.530125',
    lat: '24.742403',
    count: 0
  },
  {
    code: '360730',
    name: '宁都县',
    city_level: 3,
    lng: '116.013621',
    lat: '26.470996',
    count: 0
  },
  {
    code: '360731',
    name: '于都县',
    city_level: 3,
    lng: '115.415508',
    lat: '25.952069',
    count: 0
  },
  {
    code: '360732',
    name: '兴国县',
    city_level: 3,
    lng: '115.363190',
    lat: '26.337937',
    count: 0
  },
  {
    code: '360733',
    name: '会昌县',
    city_level: 3,
    lng: '115.786057',
    lat: '25.600272',
    count: 0
  },
  {
    code: '360734',
    name: '寻乌县',
    city_level: 3,
    lng: '115.646636',
    lat: '24.963371',
    count: 0
  },
  {
    code: '360735',
    name: '石城县',
    city_level: 3,
    lng: '116.346995',
    lat: '26.314775',
    count: 0
  },
  {
    code: '360781',
    name: '瑞金市',
    city_level: 3,
    lng: '116.027135',
    lat: '25.885555',
    count: 0
  },
  {
    code: '360783',
    name: '龙南市',
    city_level: 3,
    lng: '114.794567',
    lat: '24.903079',
    count: 0
  },
  {
    code: '360802',
    name: '吉州区',
    city_level: 3,
    lng: '114.994764',
    lat: '27.143801',
    count: 0
  },
  {
    code: '360803',
    name: '青原区',
    city_level: 3,
    lng: '115.014812',
    lat: '27.081977',
    count: 0
  },
  {
    code: '360821',
    name: '吉安县',
    city_level: 3,
    lng: '114.907733',
    lat: '27.039890',
    count: 0
  },
  {
    code: '360822',
    name: '吉水县',
    city_level: 3,
    lng: '115.135507',
    lat: '27.229632',
    count: 0
  },
  {
    code: '360823',
    name: '峡江县',
    city_level: 3,
    lng: '115.316566',
    lat: '27.582901',
    count: 0
  },
  {
    code: '360824',
    name: '新干县',
    city_level: 3,
    lng: '115.387052',
    lat: '27.740192',
    count: 0
  },
  {
    code: '360825',
    name: '永丰县',
    city_level: 3,
    lng: '115.444320',
    lat: '27.318852',
    count: 0
  },
  {
    code: '360826',
    name: '泰和县',
    city_level: 3,
    lng: '114.908869',
    lat: '26.789960',
    count: 0
  },
  {
    code: '360827',
    name: '遂川县',
    city_level: 3,
    lng: '114.520537',
    lat: '26.313737',
    count: 0
  },
  {
    code: '360828',
    name: '万安县',
    city_level: 3,
    lng: '114.786182',
    lat: '26.458254',
    count: 0
  },
  {
    code: '360829',
    name: '安福县',
    city_level: 3,
    lng: '114.619893',
    lat: '27.392874',
    count: 0
  },
  {
    code: '360830',
    name: '永新县',
    city_level: 3,
    lng: '114.243096',
    lat: '26.945002',
    count: 0
  },
  {
    code: '360881',
    name: '井冈山市',
    city_level: 3,
    lng: '114.289228',
    lat: '26.748081',
    count: 0
  },
  {
    code: '360902',
    name: '袁州区',
    city_level: 3,
    lng: '114.424657',
    lat: '27.798846',
    count: 0
  },
  {
    code: '360921',
    name: '奉新县',
    city_level: 3,
    lng: '115.400491',
    lat: '28.688423',
    count: 0
  },
  {
    code: '360922',
    name: '万载县',
    city_level: 3,
    lng: '114.445523',
    lat: '28.106004',
    count: 0
  },
  {
    code: '360923',
    name: '上高县',
    city_level: 3,
    lng: '114.947831',
    lat: '28.238876',
    count: 0
  },
  {
    code: '360924',
    name: '宜丰县',
    city_level: 3,
    lng: '114.803515',
    lat: '28.393666',
    count: 0
  },
  {
    code: '360925',
    name: '靖安县',
    city_level: 3,
    lng: '115.362629',
    lat: '28.861479',
    count: 0
  },
  {
    code: '360926',
    name: '铜鼓县',
    city_level: 3,
    lng: '114.371172',
    lat: '28.520770',
    count: 0
  },
  {
    code: '360981',
    name: '丰城市',
    city_level: 3,
    lng: '115.771094',
    lat: '28.159142',
    count: 0
  },
  {
    code: '360982',
    name: '樟树市',
    city_level: 3,
    lng: '115.546385',
    lat: '28.054814',
    count: 0
  },
  {
    code: '360983',
    name: '高安市',
    city_level: 3,
    lng: '115.375616',
    lat: '28.417255',
    count: 0
  },
  {
    code: '361002',
    name: '临川区',
    city_level: 3,
    lng: '116.371066',
    lat: '27.970596',
    count: 0
  },
  {
    code: '361003',
    name: '东乡区',
    city_level: 3,
    lng: '116.605339',
    lat: '28.232500',
    count: 0
  },
  {
    code: '361021',
    name: '南城县',
    city_level: 3,
    lng: '116.637040',
    lat: '27.569678',
    count: 0
  },
  {
    code: '361022',
    name: '黎川县',
    city_level: 3,
    lng: '116.907681',
    lat: '27.282333',
    count: 0
  },
  {
    code: '361023',
    name: '南丰县',
    city_level: 3,
    lng: '116.525725',
    lat: '27.218445',
    count: 0
  },
  {
    code: '361024',
    name: '崇仁县',
    city_level: 3,
    lng: '116.061101',
    lat: '27.764394',
    count: 0
  },
  {
    code: '361025',
    name: '乐安县',
    city_level: 3,
    lng: '115.830481',
    lat: '27.428765',
    count: 0
  },
  {
    code: '361026',
    name: '宜黄县',
    city_level: 3,
    lng: '116.222128',
    lat: '27.546146',
    count: 0
  },
  {
    code: '361027',
    name: '金溪县',
    city_level: 3,
    lng: '116.755058',
    lat: '27.918959',
    count: 0
  },
  {
    code: '361028',
    name: '资溪县',
    city_level: 3,
    lng: '117.060264',
    lat: '27.706102',
    count: 0
  },
  {
    code: '361030',
    name: '广昌县',
    city_level: 3,
    lng: '116.325757',
    lat: '26.837267',
    count: 0
  },
  {
    code: '361102',
    name: '信州区',
    city_level: 3,
    lng: '117.966460',
    lat: '28.431002',
    count: 0
  },
  {
    code: '361103',
    name: '广丰区',
    city_level: 3,
    lng: '118.191240',
    lat: '28.436286',
    count: 0
  },
  {
    code: '361104',
    name: '广信区',
    city_level: 2,
    lng: '117.943433',
    lat: '28.454862',
    count: 0
  },
  {
    code: '361123',
    name: '玉山县',
    city_level: 3,
    lng: '118.245124',
    lat: '28.682055',
    count: 0
  },
  {
    code: '361124',
    name: '铅山县',
    city_level: 3,
    lng: '117.709451',
    lat: '28.315217',
    count: 0
  },
  {
    code: '361125',
    name: '横峰县',
    city_level: 3,
    lng: '117.596452',
    lat: '28.407118',
    count: 0
  },
  {
    code: '361126',
    name: '弋阳县',
    city_level: 3,
    lng: '117.449588',
    lat: '28.378044',
    count: 0
  },
  {
    code: '361127',
    name: '余干县',
    city_level: 3,
    lng: '116.695647',
    lat: '28.702302',
    count: 0
  },
  {
    code: '361128',
    name: '鄱阳县',
    city_level: 3,
    lng: '116.699746',
    lat: '29.011699',
    count: 0
  },
  {
    code: '361129',
    name: '万年县',
    city_level: 3,
    lng: '117.058445',
    lat: '28.694582',
    count: 0
  },
  {
    code: '361130',
    name: '婺源县',
    city_level: 3,
    lng: '117.861798',
    lat: '29.248086',
    count: 0
  },
  {
    code: '361181',
    name: '德兴市',
    city_level: 3,
    lng: '117.578713',
    lat: '28.946464',
    count: 0
  },
  {
    code: '370102',
    name: '历下区',
    city_level: 3,
    lng: '117.076455',
    lat: '36.666412',
    count: 0
  },
  {
    code: '370103',
    name: '市中区',
    city_level: 3,
    lng: '103.761330',
    lat: '29.555375',
    count: 0
  },
  {
    code: '370104',
    name: '槐荫区',
    city_level: 3,
    lng: '116.901224',
    lat: '36.651441',
    count: 0
  },
  {
    code: '370105',
    name: '天桥区',
    city_level: 3,
    lng: '116.987492',
    lat: '36.678016',
    count: 0
  },
  {
    code: '370112',
    name: '历城区',
    city_level: 3,
    lng: '117.065237',
    lat: '36.680017',
    count: 0
  },
  {
    code: '370113',
    name: '长清区',
    city_level: 3,
    lng: '116.751937',
    lat: '36.553571',
    count: 0
  },
  {
    code: '370114',
    name: '章丘区',
    city_level: 3,
    lng: '117.540688',
    lat: '36.712090',
    count: 0
  },
  {
    code: '370115',
    name: '济阳区',
    city_level: 3,
    lng: '117.176033',
    lat: '36.976772',
    count: 0
  },
  {
    code: '370116',
    name: '莱芜区',
    city_level: 2,
    lng: '117.676723',
    lat: '36.213813',
    count: 0
  },
  {
    code: '370117',
    name: '钢城区',
    city_level: 3,
    lng: '117.811355',
    lat: '36.058572',
    count: 0
  },
  {
    code: '370124',
    name: '平阴县',
    city_level: 3,
    lng: '116.456187',
    lat: '36.289265',
    count: 0
  },
  {
    code: '370126',
    name: '商河县',
    city_level: 3,
    lng: '117.157207',
    lat: '37.309050',
    count: 0
  },
  {
    code: '370202',
    name: '市南区',
    city_level: 3,
    lng: '120.385649',
    lat: '36.080305',
    count: 0
  },
  {
    code: '370203',
    name: '市北区',
    city_level: 3,
    lng: '120.374731',
    lat: '36.087609',
    count: 0
  },
  {
    code: '370211',
    name: '黄岛区',
    city_level: 3,
    lng: '120.046190',
    lat: '35.872664',
    count: 0
  },
  {
    code: '370212',
    name: '崂山区',
    city_level: 3,
    lng: '120.468956',
    lat: '36.107538',
    count: 0
  },
  {
    code: '370213',
    name: '李沧区',
    city_level: 3,
    lng: '120.432697',
    lat: '36.145463',
    count: 0
  },
  {
    code: '370214',
    name: '城阳区',
    city_level: 3,
    lng: '120.396310',
    lat: '36.307064',
    count: 0
  },
  {
    code: '370215',
    name: '即墨区',
    city_level: 3,
    lng: '120.447128',
    lat: '36.389639',
    count: 0
  },
  {
    code: '370281',
    name: '胶州市',
    city_level: 3,
    lng: '120.033382',
    lat: '36.264680',
    count: 0
  },
  {
    code: '370283',
    name: '平度市',
    city_level: 3,
    lng: '119.988454',
    lat: '36.776875',
    count: 0
  },
  {
    code: '370285',
    name: '莱西市',
    city_level: 3,
    lng: '120.517690',
    lat: '36.889084',
    count: 0
  },
  {
    code: '370302',
    name: '淄川区',
    city_level: 3,
    lng: '117.966723',
    lat: '36.643452',
    count: 0
  },
  {
    code: '370303',
    name: '张店区',
    city_level: 3,
    lng: '118.017913',
    lat: '36.806674',
    count: 0
  },
  {
    code: '370304',
    name: '博山区',
    city_level: 3,
    lng: '117.861677',
    lat: '36.494721',
    count: 0
  },
  {
    code: '370305',
    name: '临淄区',
    city_level: 3,
    lng: '118.309118',
    lat: '36.826981',
    count: 0
  },
  {
    code: '370306',
    name: '周村区',
    city_level: 3,
    lng: '117.869886',
    lat: '36.803072',
    count: 0
  },
  {
    code: '370321',
    name: '桓台县',
    city_level: 3,
    lng: '118.097923',
    lat: '36.959804',
    count: 0
  },
  {
    code: '370322',
    name: '高青县',
    city_level: 3,
    lng: '117.826916',
    lat: '37.171063',
    count: 0
  },
  {
    code: '370323',
    name: '沂源县',
    city_level: 3,
    lng: '118.170856',
    lat: '36.185038',
    count: 0
  },
  {
    code: '370402',
    name: '市中区',
    city_level: 3,
    lng: '103.761330',
    lat: '29.555375',
    count: 0
  },
  {
    code: '370403',
    name: '薛城区',
    city_level: 3,
    lng: '117.263164',
    lat: '34.795063',
    count: 0
  },
  {
    code: '370404',
    name: '峄城区',
    city_level: 3,
    lng: '117.590816',
    lat: '34.773263',
    count: 0
  },
  {
    code: '370405',
    name: '台儿庄区',
    city_level: 3,
    lng: '117.733832',
    lat: '34.562528',
    count: 0
  },
  {
    code: '370406',
    name: '山亭区',
    city_level: 3,
    lng: '117.461517',
    lat: '35.099528',
    count: 0
  },
  {
    code: '370481',
    name: '滕州市',
    city_level: 3,
    lng: '117.165824',
    lat: '35.114156',
    count: 0
  },
  {
    code: '370502',
    name: '东营区',
    city_level: 3,
    lng: '118.582184',
    lat: '37.448963',
    count: 0
  },
  {
    code: '370503',
    name: '河口区',
    city_level: 3,
    lng: '118.525543',
    lat: '37.886162',
    count: 0
  },
  {
    code: '370505',
    name: '垦利区',
    city_level: 3,
    lng: '118.551315',
    lat: '37.588680',
    count: 0
  },
  {
    code: '370522',
    name: '利津县',
    city_level: 3,
    lng: '118.255273',
    lat: '37.490260',
    count: 0
  },
  {
    code: '370523',
    name: '广饶县',
    city_level: 3,
    lng: '118.407107',
    lat: '37.053555',
    count: 0
  },
  {
    code: '370602',
    name: '芝罘区',
    city_level: 3,
    lng: '121.400031',
    lat: '37.540687',
    count: 0
  },
  {
    code: '370611',
    name: '福山区',
    city_level: 3,
    lng: '121.267741',
    lat: '37.498246',
    count: 0
  },
  {
    code: '370612',
    name: '牟平区',
    city_level: 3,
    lng: '121.600512',
    lat: '37.386901',
    count: 0
  },
  {
    code: '370613',
    name: '莱山区',
    city_level: 3,
    lng: '121.445151',
    lat: '37.511361',
    count: 0
  },
  {
    code: '370614',
    name: '蓬莱区',
    city_level: 3,
    lng: '120.757579',
    lat: '37.793492',
    count: 0
  },
  {
    code: '370681',
    name: '龙口市',
    city_level: 3,
    lng: '120.477813',
    lat: '37.646108',
    count: 0
  },
  {
    code: '370682',
    name: '莱阳市',
    city_level: 3,
    lng: '120.711673',
    lat: '36.978941',
    count: 0
  },
  {
    code: '370683',
    name: '莱州市',
    city_level: 3,
    lng: '119.942327',
    lat: '37.177017',
    count: 0
  },
  {
    code: '370685',
    name: '招远市',
    city_level: 3,
    lng: '120.434072',
    lat: '37.355469',
    count: 0
  },
  {
    code: '370686',
    name: '栖霞市',
    city_level: 3,
    lng: '120.849675',
    lat: '37.335123',
    count: 0
  },
  {
    code: '370687',
    name: '海阳市',
    city_level: 3,
    lng: '121.158434',
    lat: '36.776378',
    count: 0
  },
  {
    code: '370702',
    name: '潍城区',
    city_level: 3,
    lng: '119.024836',
    lat: '36.728100',
    count: 0
  },
  {
    code: '370703',
    name: '寒亭区',
    city_level: 3,
    lng: '119.219734',
    lat: '36.775491',
    count: 0
  },
  {
    code: '370704',
    name: '坊子区',
    city_level: 3,
    lng: '119.166485',
    lat: '36.654448',
    count: 0
  },
  {
    code: '370705',
    name: '奎文区',
    city_level: 3,
    lng: '119.132486',
    lat: '36.707676',
    count: 0
  },
  {
    code: '370724',
    name: '临朐县',
    city_level: 3,
    lng: '118.542982',
    lat: '36.512506',
    count: 0
  },
  {
    code: '370725',
    name: '昌乐县',
    city_level: 3,
    lng: '118.829996',
    lat: '36.706938',
    count: 0
  },
  {
    code: '370781',
    name: '青州市',
    city_level: 3,
    lng: '118.479636',
    lat: '36.684600',
    count: 0
  },
  {
    code: '370782',
    name: '诸城市',
    city_level: 3,
    lng: '119.410103',
    lat: '35.995654',
    count: 0
  },
  {
    code: '370783',
    name: '寿光市',
    city_level: 3,
    lng: '118.790652',
    lat: '36.855480',
    count: 0
  },
  {
    code: '370784',
    name: '安丘市',
    city_level: 3,
    lng: '119.218843',
    lat: '36.478494',
    count: 0
  },
  {
    code: '370785',
    name: '高密市',
    city_level: 3,
    lng: '119.755597',
    lat: '36.382595',
    count: 0
  },
  {
    code: '370786',
    name: '昌邑市',
    city_level: 3,
    lng: '119.398525',
    lat: '36.858820',
    count: 0
  },
  {
    code: '370811',
    name: '任城区',
    city_level: 3,
    lng: '116.595050',
    lat: '35.406596',
    count: 0
  },
  {
    code: '370812',
    name: '兖州区',
    city_level: 3,
    lng: '116.783834',
    lat: '35.553144',
    count: 0
  },
  {
    code: '370826',
    name: '微山县',
    city_level: 3,
    lng: '117.128828',
    lat: '34.806554',
    count: 0
  },
  {
    code: '370827',
    name: '鱼台县',
    city_level: 3,
    lng: '116.650608',
    lat: '35.012749',
    count: 0
  },
  {
    code: '370828',
    name: '金乡县',
    city_level: 3,
    lng: '116.311532',
    lat: '35.066620',
    count: 0
  },
  {
    code: '370829',
    name: '嘉祥县',
    city_level: 3,
    lng: '116.342442',
    lat: '35.407829',
    count: 0
  },
  {
    code: '370830',
    name: '汶上县',
    city_level: 3,
    lng: '116.489043',
    lat: '35.732799',
    count: 0
  },
  {
    code: '370831',
    name: '泗水县',
    city_level: 3,
    lng: '117.251195',
    lat: '35.664323',
    count: 0
  },
  {
    code: '370832',
    name: '梁山县',
    city_level: 3,
    lng: '116.096044',
    lat: '35.802306',
    count: 0
  },
  {
    code: '370881',
    name: '曲阜市',
    city_level: 3,
    lng: '116.986503',
    lat: '35.580996',
    count: 0
  },
  {
    code: '370883',
    name: '邹城市',
    city_level: 3,
    lng: '117.003743',
    lat: '35.405185',
    count: 0
  },
  {
    code: '370902',
    name: '泰山区',
    city_level: 3,
    lng: '117.135354',
    lat: '36.192084',
    count: 0
  },
  {
    code: '370911',
    name: '岱岳区',
    city_level: 3,
    lng: '117.041582',
    lat: '36.187990',
    count: 0
  },
  {
    code: '370921',
    name: '宁阳县',
    city_level: 3,
    lng: '116.805797',
    lat: '35.758787',
    count: 0
  },
  {
    code: '370923',
    name: '东平县',
    city_level: 3,
    lng: '116.470304',
    lat: '35.937102',
    count: 0
  },
  {
    code: '370982',
    name: '新泰市',
    city_level: 3,
    lng: '117.767953',
    lat: '35.909032',
    count: 0
  },
  {
    code: '370983',
    name: '肥城市',
    city_level: 3,
    lng: '116.768358',
    lat: '36.182572',
    count: 0
  },
  {
    code: '371002',
    name: '环翠区',
    city_level: 3,
    lng: '122.123444',
    lat: '37.501991',
    count: 0
  },
  {
    code: '371003',
    name: '文登区',
    city_level: 3,
    lng: '122.057941',
    lat: '37.193886',
    count: 0
  },
  {
    code: '371082',
    name: '荣成市',
    city_level: 3,
    lng: '122.486658',
    lat: '37.165160',
    count: 0
  },
  {
    code: '371083',
    name: '乳山市',
    city_level: 3,
    lng: '121.539765',
    lat: '36.919816',
    count: 0
  },
  {
    code: '371102',
    name: '东港区',
    city_level: 3,
    lng: '119.462249',
    lat: '35.425470',
    count: 0
  },
  {
    code: '371103',
    name: '岚山区',
    city_level: 3,
    lng: '119.318929',
    lat: '35.121884',
    count: 0
  },
  {
    code: '371121',
    name: '五莲县',
    city_level: 3,
    lng: '119.206763',
    lat: '35.751927',
    count: 0
  },
  {
    code: '371122',
    name: '莒县',
    city_level: 3,
    lng: '118.837064',
    lat: '35.579868',
    count: 0
  },
  {
    code: '371302',
    name: '兰山区',
    city_level: 3,
    lng: '118.347731',
    lat: '35.051734',
    count: 0
  },
  {
    code: '371311',
    name: '罗庄区',
    city_level: 3,
    lng: '118.284786',
    lat: '34.996741',
    count: 0
  },
  {
    code: '371312',
    name: '河东区',
    city_level: 3,
    lng: '118.402893',
    lat: '35.089917',
    count: 0
  },
  {
    code: '371321',
    name: '沂南县',
    city_level: 3,
    lng: '118.465213',
    lat: '35.549976',
    count: 0
  },
  {
    code: '371322',
    name: '郯城县',
    city_level: 3,
    lng: '118.367263',
    lat: '34.613557',
    count: 0
  },
  {
    code: '371323',
    name: '沂水县',
    city_level: 3,
    lng: '118.627918',
    lat: '35.790450',
    count: 0
  },
  {
    code: '371324',
    name: '兰陵县',
    city_level: 3,
    lng: '118.070650',
    lat: '34.857149',
    count: 0
  },
  {
    code: '371325',
    name: '费县',
    city_level: 3,
    lng: '117.977325',
    lat: '35.265961',
    count: 0
  },
  {
    code: '371326',
    name: '平邑县',
    city_level: 3,
    lng: '117.640352',
    lat: '35.505943',
    count: 0
  },
  {
    code: '371327',
    name: '莒南县',
    city_level: 3,
    lng: '118.835163',
    lat: '35.174846',
    count: 0
  },
  {
    code: '371328',
    name: '蒙阴县',
    city_level: 3,
    lng: '117.945085',
    lat: '35.710032',
    count: 0
  },
  {
    code: '371329',
    name: '临沭县',
    city_level: 3,
    lng: '118.650782',
    lat: '34.919852',
    count: 0
  },
  {
    code: '371402',
    name: '德城区',
    city_level: 3,
    lng: '116.299471',
    lat: '37.450805',
    count: 0
  },
  {
    code: '371403',
    name: '陵城区',
    city_level: 3,
    lng: '116.576092',
    lat: '37.335794',
    count: 0
  },
  {
    code: '371422',
    name: '宁津县',
    city_level: 3,
    lng: '116.800306',
    lat: '37.652190',
    count: 0
  },
  {
    code: '371423',
    name: '庆云县',
    city_level: 3,
    lng: '117.385257',
    lat: '37.775350',
    count: 0
  },
  {
    code: '371424',
    name: '临邑县',
    city_level: 3,
    lng: '116.866800',
    lat: '37.189798',
    count: 0
  },
  {
    code: '371425',
    name: '齐河县',
    city_level: 3,
    lng: '116.762810',
    lat: '36.783415',
    count: 0
  },
  {
    code: '371426',
    name: '平原县',
    city_level: 3,
    lng: '116.434056',
    lat: '37.165314',
    count: 0
  },
  {
    code: '371427',
    name: '夏津县',
    city_level: 3,
    lng: '116.001726',
    lat: '36.948371',
    count: 0
  },
  {
    code: '371428',
    name: '武城县',
    city_level: 3,
    lng: '116.069302',
    lat: '37.213311',
    count: 0
  },
  {
    code: '371481',
    name: '乐陵市',
    city_level: 3,
    lng: '117.231935',
    lat: '37.729907',
    count: 0
  },
  {
    code: '371482',
    name: '禹城市',
    city_level: 3,
    lng: '116.638327',
    lat: '36.933812',
    count: 0
  },
  {
    code: '371502',
    name: '东昌府区',
    city_level: 3,
    lng: '115.988491',
    lat: '36.434645',
    count: 0
  },
  {
    code: '371503',
    name: '茌平区',
    city_level: 3,
    lng: '116.255270',
    lat: '36.580689',
    count: 0
  },
  {
    code: '371521',
    name: '阳谷县',
    city_level: 3,
    lng: '115.791820',
    lat: '36.114392',
    count: 0
  },
  {
    code: '371522',
    name: '莘县',
    city_level: 3,
    lng: '115.671191',
    lat: '36.233598',
    count: 0
  },
  {
    code: '371524',
    name: '东阿县',
    city_level: 3,
    lng: '116.247580',
    lat: '36.334917',
    count: 0
  },
  {
    code: '371525',
    name: '冠县',
    city_level: 3,
    lng: '115.442740',
    lat: '36.484009',
    count: 0
  },
  {
    code: '371526',
    name: '高唐县',
    city_level: 3,
    lng: '116.230158',
    lat: '36.846755',
    count: 0
  },
  {
    code: '371581',
    name: '临清市',
    city_level: 3,
    lng: '115.704881',
    lat: '36.838277',
    count: 0
  },
  {
    code: '371602',
    name: '滨城区',
    city_level: 3,
    lng: '118.019326',
    lat: '37.430724',
    count: 0
  },
  {
    code: '371603',
    name: '沾化区',
    city_level: 3,
    lng: '118.098902',
    lat: '37.699260',
    count: 0
  },
  {
    code: '371621',
    name: '惠民县',
    city_level: 3,
    lng: '117.509921',
    lat: '37.489877',
    count: 0
  },
  {
    code: '371622',
    name: '阳信县',
    city_level: 3,
    lng: '117.578262',
    lat: '37.641106',
    count: 0
  },
  {
    code: '371623',
    name: '无棣县',
    city_level: 3,
    lng: '117.625696',
    lat: '37.770260',
    count: 0
  },
  {
    code: '371625',
    name: '博兴县',
    city_level: 3,
    lng: '118.131815',
    lat: '37.150226',
    count: 0
  },
  {
    code: '371681',
    name: '邹平市',
    city_level: 3,
    lng: '117.736809',
    lat: '36.878029',
    count: 0
  },
  {
    code: '371702',
    name: '牡丹区',
    city_level: 3,
    lng: '115.417827',
    lat: '35.252512',
    count: 0
  },
  {
    code: '371703',
    name: '定陶区',
    city_level: 3,
    lng: '115.569619',
    lat: '35.072701',
    count: 0
  },
  {
    code: '371721',
    name: '曹县',
    city_level: 3,
    lng: '115.542328',
    lat: '34.825508',
    count: 0
  },
  {
    code: '371722',
    name: '单县',
    city_level: 3,
    lng: '116.107428',
    lat: '34.778808',
    count: 0
  },
  {
    code: '371723',
    name: '成武县',
    city_level: 3,
    lng: '115.889765',
    lat: '34.952459',
    count: 0
  },
  {
    code: '371724',
    name: '巨野县',
    city_level: 3,
    lng: '116.065396',
    lat: '35.387374',
    count: 0
  },
  {
    code: '371725',
    name: '郓城县',
    city_level: 3,
    lng: '115.943613',
    lat: '35.599758',
    count: 0
  },
  {
    code: '371726',
    name: '鄄城县',
    city_level: 3,
    lng: '115.510193',
    lat: '35.563408',
    count: 0
  },
  {
    code: '371728',
    name: '东明县',
    city_level: 3,
    lng: '115.089905',
    lat: '35.289368',
    count: 0
  },
  {
    code: '410102',
    name: '中原区',
    city_level: 3,
    lng: '113.612850',
    lat: '34.748257',
    count: 0
  },
  {
    code: '410103',
    name: '二七区',
    city_level: 3,
    lng: '113.640179',
    lat: '34.723930',
    count: 0
  },
  {
    code: '410104',
    name: '管城回族区',
    city_level: 3,
    lng: '113.677548',
    lat: '34.754500',
    count: 0
  },
  {
    code: '410105',
    name: '金水区',
    city_level: 3,
    lng: '113.660555',
    lat: '34.800156',
    count: 0
  },
  {
    code: '410106',
    name: '上街区',
    city_level: 3,
    lng: '113.308961',
    lat: '34.802780',
    count: 0
  },
  {
    code: '410108',
    name: '惠济区',
    city_level: 3,
    lng: '113.616901',
    lat: '34.867458',
    count: 0
  },
  {
    code: '410122',
    name: '中牟县',
    city_level: 3,
    lng: '113.976181',
    lat: '34.718950',
    count: 0
  },
  {
    code: '410181',
    name: '巩义市',
    city_level: 3,
    lng: '113.022497',
    lat: '34.747834',
    count: 0
  },
  {
    code: '410182',
    name: '荥阳市',
    city_level: 3,
    lng: '113.440457',
    lat: '34.745585',
    count: 0
  },
  {
    code: '410183',
    name: '新密市',
    city_level: 3,
    lng: '113.390891',
    lat: '34.539443',
    count: 0
  },
  {
    code: '410184',
    name: '新郑市',
    city_level: 3,
    lng: '113.740529',
    lat: '34.395562',
    count: 0
  },
  {
    code: '410185',
    name: '登封市',
    city_level: 3,
    lng: '113.050492',
    lat: '34.453667',
    count: 0
  },
  {
    code: '410202',
    name: '龙亭区',
    city_level: 3,
    lng: '114.354730',
    lat: '34.815784',
    count: 0
  },
  {
    code: '410203',
    name: '顺河回族区',
    city_level: 3,
    lng: '114.364875',
    lat: '34.800459',
    count: 0
  },
  {
    code: '410204',
    name: '鼓楼区',
    city_level: 3,
    lng: '114.348307',
    lat: '34.788561',
    count: 0
  },
  {
    code: '410205',
    name: '禹王台区',
    city_level: 3,
    lng: '114.348170',
    lat: '34.777104',
    count: 0
  },
  {
    code: '410212',
    name: '祥符区',
    city_level: 3,
    lng: '114.441327',
    lat: '34.756921',
    count: 0
  },
  {
    code: '410221',
    name: '杞县',
    city_level: 3,
    lng: '114.783041',
    lat: '34.549166',
    count: 0
  },
  {
    code: '410222',
    name: '通许县',
    city_level: 3,
    lng: '114.467467',
    lat: '34.480433',
    count: 0
  },
  {
    code: '410223',
    name: '尉氏县',
    city_level: 3,
    lng: '114.193081',
    lat: '34.411494',
    count: 0
  },
  {
    code: '410225',
    name: '兰考县',
    city_level: 3,
    lng: '114.821348',
    lat: '34.822211',
    count: 0
  },
  {
    code: '410302',
    name: '老城区',
    city_level: 3,
    lng: '112.469024',
    lat: '34.683646',
    count: 0
  },
  {
    code: '410303',
    name: '西工区',
    city_level: 3,
    lng: '112.428413',
    lat: '34.659900',
    count: 0
  },
  {
    code: '410304',
    name: '瀍河回族区',
    city_level: 3,
    lng: '112.500205',
    lat: '34.679850',
    count: 0
  },
  {
    code: '410305',
    name: '涧西区',
    city_level: 3,
    lng: '112.395756',
    lat: '34.658034',
    count: 0
  },
  {
    code: '410306',
    name: '吉利区',
    city_level: 3,
    lng: '112.589052',
    lat: '34.900889',
    count: 0
  },
  {
    code: '410311',
    name: '洛龙区',
    city_level: 3,
    lng: '112.464173',
    lat: '34.619404',
    count: 0
  },
  {
    code: '410322',
    name: '孟津县',
    city_level: 3,
    lng: '112.445252',
    lat: '34.825307',
    count: 0
  },
  {
    code: '410323',
    name: '新安县',
    city_level: 3,
    lng: '112.132488',
    lat: '34.728584',
    count: 0
  },
  {
    code: '410324',
    name: '栾川县',
    city_level: 3,
    lng: '111.615769',
    lat: '33.785698',
    count: 0
  },
  {
    code: '410325',
    name: '嵩县',
    city_level: 3,
    lng: '112.085634',
    lat: '34.134517',
    count: 0
  },
  {
    code: '410326',
    name: '汝阳县',
    city_level: 3,
    lng: '112.473139',
    lat: '34.153940',
    count: 0
  },
  {
    code: '410327',
    name: '宜阳县',
    city_level: 3,
    lng: '112.179238',
    lat: '34.514645',
    count: 0
  },
  {
    code: '410328',
    name: '洛宁县',
    city_level: 3,
    lng: '111.653039',
    lat: '34.389414',
    count: 0
  },
  {
    code: '410329',
    name: '伊川县',
    city_level: 3,
    lng: '112.425651',
    lat: '34.421460',
    count: 0
  },
  {
    code: '410381',
    name: '偃师市',
    city_level: 3,
    lng: '112.789535',
    lat: '34.727220',
    count: 0
  },
  {
    code: '410402',
    name: '新华区',
    city_level: 3,
    lng: '113.293999',
    lat: '33.737365',
    count: 0
  },
  {
    code: '410403',
    name: '卫东区',
    city_level: 3,
    lng: '113.335193',
    lat: '33.734707',
    count: 0
  },
  {
    code: '410404',
    name: '石龙区',
    city_level: 3,
    lng: '112.898818',
    lat: '33.898713',
    count: 0
  },
  {
    code: '410411',
    name: '湛河区',
    city_level: 3,
    lng: '113.320873',
    lat: '33.725681',
    count: 0
  },
  {
    code: '410421',
    name: '宝丰县',
    city_level: 3,
    lng: '113.054754',
    lat: '33.868441',
    count: 0
  },
  {
    code: '410422',
    name: '叶县',
    city_level: 3,
    lng: '113.357239',
    lat: '33.626731',
    count: 0
  },
  {
    code: '410423',
    name: '鲁山县',
    city_level: 3,
    lng: '112.908023',
    lat: '33.738518',
    count: 0
  },
  {
    code: '410425',
    name: '郏县',
    city_level: 3,
    lng: '113.212609',
    lat: '33.971787',
    count: 0
  },
  {
    code: '410481',
    name: '舞钢市',
    city_level: 3,
    lng: '113.524794',
    lat: '33.307776',
    count: 0
  },
  {
    code: '410482',
    name: '汝州市',
    city_level: 3,
    lng: '112.844517',
    lat: '34.167030',
    count: 0
  },
  {
    code: '410502',
    name: '文峰区',
    city_level: 3,
    lng: '114.357082',
    lat: '36.090468',
    count: 0
  },
  {
    code: '410503',
    name: '北关区',
    city_level: 3,
    lng: '114.355822',
    lat: '36.107255',
    count: 0
  },
  {
    code: '410505',
    name: '殷都区',
    city_level: 3,
    lng: '114.303410',
    lat: '36.109890',
    count: 0
  },
  {
    code: '410506',
    name: '龙安区',
    city_level: 3,
    lng: '114.323522',
    lat: '36.095568',
    count: 0
  },
  {
    code: '410522',
    name: '安阳县',
    city_level: 3,
    lng: '114.130207',
    lat: '36.130585',
    count: 0
  },
  {
    code: '410523',
    name: '汤阴县',
    city_level: 3,
    lng: '114.357763',
    lat: '35.924515',
    count: 0
  },
  {
    code: '410526',
    name: '滑县',
    city_level: 3,
    lng: '114.518997',
    lat: '35.575002',
    count: 0
  },
  {
    code: '410527',
    name: '内黄县',
    city_level: 3,
    lng: '114.901492',
    lat: '35.971653',
    count: 0
  },
  {
    code: '410581',
    name: '林州市',
    city_level: 3,
    lng: '113.820130',
    lat: '36.083047',
    count: 0
  },
  {
    code: '410602',
    name: '鹤山区',
    city_level: 3,
    lng: '114.163367',
    lat: '35.954582',
    count: 0
  },
  {
    code: '410603',
    name: '山城区',
    city_level: 3,
    lng: '114.184428',
    lat: '35.897703',
    count: 0
  },
  {
    code: '410611',
    name: '淇滨区',
    city_level: 3,
    lng: '114.298694',
    lat: '35.741296',
    count: 0
  },
  {
    code: '410621',
    name: '浚县',
    city_level: 3,
    lng: '114.550813',
    lat: '35.676240',
    count: 0
  },
  {
    code: '410622',
    name: '淇县',
    city_level: 3,
    lng: '114.197651',
    lat: '35.607762',
    count: 0
  },
  {
    code: '410702',
    name: '红旗区',
    city_level: 3,
    lng: '113.875245',
    lat: '35.303851',
    count: 0
  },
  {
    code: '410703',
    name: '卫滨区',
    city_level: 3,
    lng: '113.865780',
    lat: '35.302117',
    count: 0
  },
  {
    code: '410704',
    name: '凤泉区',
    city_level: 3,
    lng: '113.915184',
    lat: '35.383978',
    count: 0
  },
  {
    code: '410711',
    name: '牧野区',
    city_level: 3,
    lng: '113.908772',
    lat: '35.315039',
    count: 0
  },
  {
    code: '410721',
    name: '新乡县',
    city_level: 3,
    lng: '113.805205',
    lat: '35.190836',
    count: 0
  },
  {
    code: '410724',
    name: '获嘉县',
    city_level: 3,
    lng: '113.657433',
    lat: '35.259808',
    count: 0
  },
  {
    code: '410725',
    name: '原阳县',
    city_level: 3,
    lng: '113.940115',
    lat: '35.065587',
    count: 0
  },
  {
    code: '410726',
    name: '延津县',
    city_level: 3,
    lng: '114.205197',
    lat: '35.141956',
    count: 0
  },
  {
    code: '410727',
    name: '封丘县',
    city_level: 3,
    lng: '114.418882',
    lat: '35.041198',
    count: 0
  },
  {
    code: '410781',
    name: '卫辉市',
    city_level: 3,
    lng: '114.064907',
    lat: '35.398494',
    count: 0
  },
  {
    code: '410782',
    name: '辉县市',
    city_level: 3,
    lng: '113.805468',
    lat: '35.462313',
    count: 0
  },
  {
    code: '410783',
    name: '长垣市',
    city_level: 3,
    lng: '114.673805',
    lat: '35.196148',
    count: 0
  },
  {
    code: '410802',
    name: '解放区',
    city_level: 3,
    lng: '113.230817',
    lat: '35.240282',
    count: 0
  },
  {
    code: '410803',
    name: '中站区',
    city_level: 3,
    lng: '113.182946',
    lat: '35.236820',
    count: 0
  },
  {
    code: '410804',
    name: '马村区',
    city_level: 3,
    lng: '113.322332',
    lat: '35.256108',
    count: 0
  },
  {
    code: '410811',
    name: '山阳区',
    city_level: 3,
    lng: '113.254881',
    lat: '35.214507',
    count: 0
  },
  {
    code: '410821',
    name: '修武县',
    city_level: 3,
    lng: '113.447755',
    lat: '35.223514',
    count: 0
  },
  {
    code: '410822',
    name: '博爱县',
    city_level: 3,
    lng: '113.069211',
    lat: '35.166061',
    count: 0
  },
  {
    code: '410823',
    name: '武陟县',
    city_level: 3,
    lng: '113.401679',
    lat: '35.099378',
    count: 0
  },
  {
    code: '410825',
    name: '温县',
    city_level: 3,
    lng: '113.080530',
    lat: '34.940189',
    count: 0
  },
  {
    code: '410882',
    name: '沁阳市',
    city_level: 3,
    lng: '112.943069',
    lat: '35.090604',
    count: 0
  },
  {
    code: '410883',
    name: '孟州市',
    city_level: 3,
    lng: '112.789612',
    lat: '34.907970',
    count: 0
  },
  {
    code: '410902',
    name: '华龙区',
    city_level: 3,
    lng: '115.074151',
    lat: '35.777346',
    count: 0
  },
  {
    code: '410922',
    name: '清丰县',
    city_level: 3,
    lng: '115.104389',
    lat: '35.885180',
    count: 0
  },
  {
    code: '410923',
    name: '南乐县',
    city_level: 3,
    lng: '115.204752',
    lat: '36.069664',
    count: 0
  },
  {
    code: '410926',
    name: '范县',
    city_level: 3,
    lng: '115.504201',
    lat: '35.851907',
    count: 0
  },
  {
    code: '410927',
    name: '台前县',
    city_level: 3,
    lng: '115.871906',
    lat: '35.969390',
    count: 0
  },
  {
    code: '410928',
    name: '濮阳县',
    city_level: 3,
    lng: '115.029078',
    lat: '35.712193',
    count: 0
  },
  {
    code: '411002',
    name: '魏都区',
    city_level: 3,
    lng: '113.822647',
    lat: '34.025342',
    count: 0
  },
  {
    code: '411003',
    name: '建安区',
    city_level: 3,
    lng: '113.822989',
    lat: '34.124669',
    count: 0
  },
  {
    code: '411024',
    name: '鄢陵县',
    city_level: 3,
    lng: '114.177400',
    lat: '34.102332',
    count: 0
  },
  {
    code: '411025',
    name: '襄城县',
    city_level: 3,
    lng: '113.488847',
    lat: '33.866442',
    count: 0
  },
  {
    code: '411081',
    name: '禹州市',
    city_level: 3,
    lng: '113.488478',
    lat: '34.140701',
    count: 0
  },
  {
    code: '411082',
    name: '长葛市',
    city_level: 3,
    lng: '113.819888',
    lat: '34.194136',
    count: 0
  },
  {
    code: '411102',
    name: '源汇区',
    city_level: 3,
    lng: '113.910710',
    lat: '33.582190',
    count: 0
  },
  {
    code: '411103',
    name: '郾城区',
    city_level: 3,
    lng: '114.006944',
    lat: '33.587409',
    count: 0
  },
  {
    code: '411104',
    name: '召陵区',
    city_level: 3,
    lng: '114.093902',
    lat: '33.586565',
    count: 0
  },
  {
    code: '411121',
    name: '舞阳县',
    city_level: 3,
    lng: '113.609286',
    lat: '33.437877',
    count: 0
  },
  {
    code: '411122',
    name: '临颍县',
    city_level: 3,
    lng: '113.931203',
    lat: '33.827304',
    count: 0
  },
  {
    code: '411202',
    name: '湖滨区',
    city_level: 3,
    lng: '111.188398',
    lat: '34.770886',
    count: 0
  },
  {
    code: '411203',
    name: '陕州区',
    city_level: 3,
    lng: '111.103563',
    lat: '34.720548',
    count: 0
  },
  {
    code: '411221',
    name: '渑池县',
    city_level: 3,
    lng: '111.761504',
    lat: '34.767244',
    count: 0
  },
  {
    code: '411224',
    name: '卢氏县',
    city_level: 3,
    lng: '111.047858',
    lat: '34.054324',
    count: 0
  },
  {
    code: '411281',
    name: '义马市',
    city_level: 3,
    lng: '111.874393',
    lat: '34.747129',
    count: 0
  },
  {
    code: '411282',
    name: '灵宝市',
    city_level: 3,
    lng: '110.894220',
    lat: '34.516828',
    count: 0
  },
  {
    code: '411302',
    name: '宛城区',
    city_level: 3,
    lng: '112.539559',
    lat: '33.003784',
    count: 0
  },
  {
    code: '411303',
    name: '卧龙区',
    city_level: 3,
    lng: '112.528789',
    lat: '32.989877',
    count: 0
  },
  {
    code: '411321',
    name: '南召县',
    city_level: 3,
    lng: '112.429133',
    lat: '33.489877',
    count: 0
  },
  {
    code: '411322',
    name: '方城县',
    city_level: 3,
    lng: '113.012494',
    lat: '33.254391',
    count: 0
  },
  {
    code: '411323',
    name: '西峡县',
    city_level: 3,
    lng: '111.473530',
    lat: '33.307294',
    count: 0
  },
  {
    code: '411324',
    name: '镇平县',
    city_level: 3,
    lng: '112.234698',
    lat: '33.034111',
    count: 0
  },
  {
    code: '411325',
    name: '内乡县',
    city_level: 3,
    lng: '111.849392',
    lat: '33.044865',
    count: 0
  },
  {
    code: '411326',
    name: '淅川县',
    city_level: 3,
    lng: '111.490964',
    lat: '33.137820',
    count: 0
  },
  {
    code: '411327',
    name: '社旗县',
    city_level: 3,
    lng: '112.948245',
    lat: '33.056109',
    count: 0
  },
  {
    code: '411328',
    name: '唐河县',
    city_level: 3,
    lng: '112.807637',
    lat: '32.681335',
    count: 0
  },
  {
    code: '411329',
    name: '新野县',
    city_level: 3,
    lng: '112.360026',
    lat: '32.520805',
    count: 0
  },
  {
    code: '411330',
    name: '桐柏县',
    city_level: 3,
    lng: '113.428287',
    lat: '32.380073',
    count: 0
  },
  {
    code: '411381',
    name: '邓州市',
    city_level: 3,
    lng: '112.087278',
    lat: '32.687938',
    count: 0
  },
  {
    code: '411402',
    name: '梁园区',
    city_level: 3,
    lng: '115.613965',
    lat: '34.443893',
    count: 0
  },
  {
    code: '411403',
    name: '睢阳区',
    city_level: 3,
    lng: '115.653302',
    lat: '34.388390',
    count: 0
  },
  {
    code: '411421',
    name: '民权县',
    city_level: 3,
    lng: '115.179594',
    lat: '34.647758',
    count: 0
  },
  {
    code: '411422',
    name: '睢县',
    city_level: 3,
    lng: '115.071879',
    lat: '34.445656',
    count: 0
  },
  {
    code: '411423',
    name: '宁陵县',
    city_level: 3,
    lng: '115.313690',
    lat: '34.460232',
    count: 0
  },
  {
    code: '411424',
    name: '柘城县',
    city_level: 3,
    lng: '115.305843',
    lat: '34.091045',
    count: 0
  },
  {
    code: '411425',
    name: '虞城县',
    city_level: 3,
    lng: '115.840511',
    lat: '34.402512',
    count: 0
  },
  {
    code: '411426',
    name: '夏邑县',
    city_level: 3,
    lng: '116.131447',
    lat: '34.237554',
    count: 0
  },
  {
    code: '411481',
    name: '永城市',
    city_level: 3,
    lng: '116.449500',
    lat: '33.929291',
    count: 0
  },
  {
    code: '411502',
    name: '浉河区',
    city_level: 3,
    lng: '114.058713',
    lat: '32.116803',
    count: 0
  },
  {
    code: '411503',
    name: '平桥区',
    city_level: 3,
    lng: '114.125656',
    lat: '32.101031',
    count: 0
  },
  {
    code: '411521',
    name: '罗山县',
    city_level: 3,
    lng: '114.513012',
    lat: '32.203073',
    count: 0
  },
  {
    code: '411522',
    name: '光山县',
    city_level: 3,
    lng: '114.919033',
    lat: '32.011103',
    count: 0
  },
  {
    code: '411523',
    name: '新县',
    city_level: 3,
    lng: '114.879239',
    lat: '31.643918',
    count: 0
  },
  {
    code: '411524',
    name: '商城县',
    city_level: 3,
    lng: '115.406862',
    lat: '31.798378',
    count: 0
  },
  {
    code: '411525',
    name: '固始县',
    city_level: 3,
    lng: '115.654482',
    lat: '32.168137',
    count: 0
  },
  {
    code: '411526',
    name: '潢川县',
    city_level: 3,
    lng: '115.051808',
    lat: '32.131383',
    count: 0
  },
  {
    code: '411527',
    name: '淮滨县',
    city_level: 3,
    lng: '115.419538',
    lat: '32.473258',
    count: 0
  },
  {
    code: '411528',
    name: '息县',
    city_level: 3,
    lng: '114.740456',
    lat: '32.342792',
    count: 0
  },
  {
    code: '411602',
    name: '川汇区',
    city_level: 3,
    lng: '114.650628',
    lat: '33.647598',
    count: 0
  },
  {
    code: '411603',
    name: '淮阳区',
    city_level: 3,
    lng: '114.866587',
    lat: '33.730268',
    count: 0
  },
  {
    code: '411621',
    name: '扶沟县',
    city_level: 3,
    lng: '114.394915',
    lat: '34.059862',
    count: 0
  },
  {
    code: '411622',
    name: '西华县',
    city_level: 3,
    lng: '114.425451',
    lat: '33.808682',
    count: 0
  },
  {
    code: '411623',
    name: '商水县',
    city_level: 3,
    lng: '114.611596',
    lat: '33.542480',
    count: 0
  },
  {
    code: '411624',
    name: '沈丘县',
    city_level: 3,
    lng: '115.098583',
    lat: '33.409369',
    count: 0
  },
  {
    code: '411625',
    name: '郸城县',
    city_level: 3,
    lng: '115.177189',
    lat: '33.644743',
    count: 0
  },
  {
    code: '411627',
    name: '太康县',
    city_level: 3,
    lng: '114.837887',
    lat: '34.063798',
    count: 0
  },
  {
    code: '411628',
    name: '鹿邑县',
    city_level: 3,
    lng: '115.484454',
    lat: '33.860000',
    count: 0
  },
  {
    code: '411681',
    name: '项城市',
    city_level: 3,
    lng: '114.875333',
    lat: '33.465838',
    count: 0
  },
  {
    code: '411702',
    name: '驿城区',
    city_level: 3,
    lng: '113.993914',
    lat: '32.973054',
    count: 0
  },
  {
    code: '411721',
    name: '西平县',
    city_level: 3,
    lng: '114.021539',
    lat: '33.387685',
    count: 0
  },
  {
    code: '411722',
    name: '上蔡县',
    city_level: 3,
    lng: '114.264381',
    lat: '33.262439',
    count: 0
  },
  {
    code: '411723',
    name: '平舆县',
    city_level: 3,
    lng: '114.619159',
    lat: '32.962710',
    count: 0
  },
  {
    code: '411724',
    name: '正阳县',
    city_level: 3,
    lng: '114.392774',
    lat: '32.605697',
    count: 0
  },
  {
    code: '411725',
    name: '确山县',
    city_level: 3,
    lng: '114.026430',
    lat: '32.802065',
    count: 0
  },
  {
    code: '411726',
    name: '泌阳县',
    city_level: 3,
    lng: '113.327144',
    lat: '32.723975',
    count: 0
  },
  {
    code: '411727',
    name: '汝南县',
    city_level: 3,
    lng: '114.362379',
    lat: '33.006729',
    count: 0
  },
  {
    code: '411728',
    name: '遂平县',
    city_level: 3,
    lng: '114.013182',
    lat: '33.145649',
    count: 0
  },
  {
    code: '411729',
    name: '新蔡县',
    city_level: 3,
    lng: '114.920983',
    lat: '32.811480',
    count: 0
  },
  {
    code: '419001',
    name: '济源市',
    city_level: 3,
    lng: '112.602344',
    lat: '35.06903',
    count: 0
  },
  {
    code: '420102',
    name: '江岸区',
    city_level: 3,
    lng: '114.278760',
    lat: '30.592688',
    count: 0
  },
  {
    code: '420103',
    name: '江汉区',
    city_level: 3,
    lng: '114.270871',
    lat: '30.601430',
    count: 0
  },
  {
    code: '420104',
    name: '硚口区',
    city_level: 3,
    lng: '114.265830',
    lat: '30.571236',
    count: 0
  },
  {
    code: '420105',
    name: '汉阳区',
    city_level: 3,
    lng: '114.218724',
    lat: '30.553905',
    count: 0
  },
  {
    code: '420106',
    name: '武昌区',
    city_level: 3,
    lng: '114.316223',
    lat: '30.554235',
    count: 0
  },
  {
    code: '420107',
    name: '青山区',
    city_level: 3,
    lng: '114.385539',
    lat: '30.639630',
    count: 0
  },
  {
    code: '420111',
    name: '洪山区',
    city_level: 3,
    lng: '114.343913',
    lat: '30.500317',
    count: 0
  },
  {
    code: '420112',
    name: '东西湖区',
    city_level: 3,
    lng: '114.136886',
    lat: '30.620020',
    count: 0
  },
  {
    code: '420113',
    name: '汉南区',
    city_level: 3,
    lng: '114.084445',
    lat: '30.308856',
    count: 0
  },
  {
    code: '420114',
    name: '蔡甸区',
    city_level: 3,
    lng: '114.029328',
    lat: '30.582271',
    count: 0
  },
  {
    code: '420115',
    name: '江夏区',
    city_level: 3,
    lng: '114.321551',
    lat: '30.375748',
    count: 0
  },
  {
    code: '420116',
    name: '黄陂区',
    city_level: 3,
    lng: '114.375743',
    lat: '30.882557',
    count: 0
  },
  {
    code: '420117',
    name: '新洲区',
    city_level: 3,
    lng: '114.801107',
    lat: '30.841544',
    count: 0
  },
  {
    code: '420202',
    name: '黄石港区',
    city_level: 3,
    lng: '115.065978',
    lat: '30.223003',
    count: 0
  },
  {
    code: '420203',
    name: '西塞山区',
    city_level: 3,
    lng: '115.109955',
    lat: '30.204924',
    count: 0
  },
  {
    code: '420204',
    name: '下陆区',
    city_level: 3,
    lng: '114.961327',
    lat: '30.173913',
    count: 0
  },
  {
    code: '420205',
    name: '铁山区',
    city_level: 3,
    lng: '114.901412',
    lat: '30.206592',
    count: 0
  },
  {
    code: '420222',
    name: '阳新县',
    city_level: 3,
    lng: '115.215227',
    lat: '29.830258',
    count: 0
  },
  {
    code: '420281',
    name: '大冶市',
    city_level: 3,
    lng: '114.979875',
    lat: '30.095643',
    count: 0
  },
  {
    code: '420302',
    name: '茅箭区',
    city_level: 3,
    lng: '110.813621',
    lat: '32.591929',
    count: 0
  },
  {
    code: '420303',
    name: '张湾区',
    city_level: 3,
    lng: '110.769133',
    lat: '32.652297',
    count: 0
  },
  {
    code: '420304',
    name: '郧阳区',
    city_level: 3,
    lng: '110.812044',
    lat: '32.834802',
    count: 0
  },
  {
    code: '420322',
    name: '郧西县',
    city_level: 3,
    lng: '110.425983',
    lat: '32.993182',
    count: 0
  },
  {
    code: '420323',
    name: '竹山县',
    city_level: 3,
    lng: '110.228694',
    lat: '32.224875',
    count: 0
  },
  {
    code: '420324',
    name: '竹溪县',
    city_level: 3,
    lng: '109.715304',
    lat: '32.318255',
    count: 0
  },
  {
    code: '420325',
    name: '房县',
    city_level: 3,
    lng: '110.726670',
    lat: '32.040085',
    count: 0
  },
  {
    code: '420381',
    name: '丹江口市',
    city_level: 3,
    lng: '111.513127',
    lat: '32.540157',
    count: 0
  },
  {
    code: '420502',
    name: '西陵区',
    city_level: 3,
    lng: '111.285646',
    lat: '30.710782',
    count: 0
  },
  {
    code: '420503',
    name: '伍家岗区',
    city_level: 3,
    lng: '111.361037',
    lat: '30.644334',
    count: 0
  },
  {
    code: '420504',
    name: '点军区',
    city_level: 3,
    lng: '111.268120',
    lat: '30.693247',
    count: 0
  },
  {
    code: '420505',
    name: '猇亭区',
    city_level: 3,
    lng: '111.434620',
    lat: '30.530903',
    count: 0
  },
  {
    code: '420506',
    name: '夷陵区',
    city_level: 3,
    lng: '111.326380',
    lat: '30.770006',
    count: 0
  },
  {
    code: '420525',
    name: '远安县',
    city_level: 3,
    lng: '111.640508',
    lat: '31.060869',
    count: 0
  },
  {
    code: '420526',
    name: '兴山县',
    city_level: 3,
    lng: '110.746805',
    lat: '31.348196',
    count: 0
  },
  {
    code: '420527',
    name: '秭归县',
    city_level: 3,
    lng: '110.977711',
    lat: '30.825897',
    count: 0
  },
  {
    code: '420528',
    name: '长阳土家族自治县',
    city_level: 3,
    lng: '111.207242',
    lat: '30.472763',
    count: 0
  },
  {
    code: '420529',
    name: '五峰土家族自治县',
    city_level: 3,
    lng: '110.674706',
    lat: '30.199688',
    count: 0
  },
  {
    code: '420581',
    name: '宜都市',
    city_level: 3,
    lng: '111.450006',
    lat: '30.378327',
    count: 0
  },
  {
    code: '420582',
    name: '当阳市',
    city_level: 3,
    lng: '111.788312',
    lat: '30.821266',
    count: 0
  },
  {
    code: '420583',
    name: '枝江市',
    city_level: 3,
    lng: '111.760530',
    lat: '30.425940',
    count: 0
  },
  {
    code: '420602',
    name: '襄城区',
    city_level: 3,
    lng: '112.133974',
    lat: '32.010351',
    count: 0
  },
  {
    code: '420606',
    name: '樊城区',
    city_level: 3,
    lng: '112.135684',
    lat: '32.044833',
    count: 0
  },
  {
    code: '420607',
    name: '襄州区',
    city_level: 3,
    lng: '112.211899',
    lat: '32.087298',
    count: 0
  },
  {
    code: '420624',
    name: '南漳县',
    city_level: 3,
    lng: '111.838905',
    lat: '31.774636',
    count: 0
  },
  {
    code: '420625',
    name: '谷城县',
    city_level: 3,
    lng: '111.652982',
    lat: '32.263849',
    count: 0
  },
  {
    code: '420626',
    name: '保康县',
    city_level: 3,
    lng: '111.261309',
    lat: '31.878310',
    count: 0
  },
  {
    code: '420682',
    name: '老河口市',
    city_level: 3,
    lng: '111.683861',
    lat: '32.359068',
    count: 0
  },
  {
    code: '420683',
    name: '枣阳市',
    city_level: 3,
    lng: '112.771959',
    lat: '32.128818',
    count: 0
  },
  {
    code: '420684',
    name: '宜城市',
    city_level: 3,
    lng: '112.257788',
    lat: '31.719806',
    count: 0
  },
  {
    code: '420702',
    name: '梁子湖区',
    city_level: 3,
    lng: '114.684731',
    lat: '30.100141',
    count: 0
  },
  {
    code: '420703',
    name: '华容区',
    city_level: 3,
    lng: '114.729878',
    lat: '30.534310',
    count: 0
  },
  {
    code: '420704',
    name: '鄂城区',
    city_level: 3,
    lng: '114.891615',
    lat: '30.400572',
    count: 0
  },
  {
    code: '420802',
    name: '东宝区',
    city_level: 3,
    lng: '112.201493',
    lat: '31.051852',
    count: 0
  },
  {
    code: '420804',
    name: '掇刀区',
    city_level: 3,
    lng: '112.207833',
    lat: '30.973431',
    count: 0
  },
  {
    code: '420822',
    name: '沙洋县',
    city_level: 3,
    lng: '112.588581',
    lat: '30.709221',
    count: 0
  },
  {
    code: '420881',
    name: '钟祥市',
    city_level: 3,
    lng: '112.588121',
    lat: '31.167820',
    count: 0
  },
  {
    code: '420882',
    name: '京山市',
    city_level: 3,
    lng: '113.119566',
    lat: '31.018457',
    count: 0
  },
  {
    code: '420902',
    name: '孝南区',
    city_level: 3,
    lng: '113.910958',
    lat: '30.916874',
    count: 0
  },
  {
    code: '420921',
    name: '孝昌县',
    city_level: 3,
    lng: '113.998010',
    lat: '31.258159',
    count: 0
  },
  {
    code: '420922',
    name: '大悟县',
    city_level: 3,
    lng: '114.127022',
    lat: '31.561165',
    count: 0
  },
  {
    code: '420923',
    name: '云梦县',
    city_level: 3,
    lng: '113.753554',
    lat: '31.020983',
    count: 0
  },
  {
    code: '420981',
    name: '应城市',
    city_level: 3,
    lng: '113.562806',
    lat: '30.940176',
    count: 0
  },
  {
    code: '420982',
    name: '安陆市',
    city_level: 3,
    lng: '113.688941',
    lat: '31.255610',
    count: 0
  },
  {
    code: '420984',
    name: '汉川市',
    city_level: 3,
    lng: '113.839149',
    lat: '30.661244',
    count: 0
  },
  {
    code: '421002',
    name: '沙市区',
    city_level: 3,
    lng: '112.255583',
    lat: '30.311056',
    count: 0
  },
  {
    code: '421003',
    name: '荆州区',
    city_level: 3,
    lng: '112.190185',
    lat: '30.352832',
    count: 0
  },
  {
    code: '421022',
    name: '公安县',
    city_level: 3,
    lng: '112.229648',
    lat: '30.058336',
    count: 0
  },
  {
    code: '421024',
    name: '江陵县',
    city_level: 3,
    lng: '112.424664',
    lat: '30.041822',
    count: 0
  },
  {
    code: '421081',
    name: '石首市',
    city_level: 3,
    lng: '112.425454',
    lat: '29.720938',
    count: 0
  },
  {
    code: '421083',
    name: '洪湖市',
    city_level: 3,
    lng: '113.475980',
    lat: '29.825458',
    count: 0
  },
  {
    code: '421087',
    name: '松滋市',
    city_level: 3,
    lng: '111.756773',
    lat: '30.174522',
    count: 0
  },
  {
    code: '421088',
    name: '监利市',
    city_level: 2,
    lng: '112.239741',
    lat: '30.335165',
    count: 0
  },
  {
    code: '421102',
    name: '黄州区',
    city_level: 3,
    lng: '114.879398',
    lat: '30.434050',
    count: 0
  },
  {
    code: '421121',
    name: '团风县',
    city_level: 3,
    lng: '114.872191',
    lat: '30.643569',
    count: 0
  },
  {
    code: '421122',
    name: '红安县',
    city_level: 3,
    lng: '114.618236',
    lat: '31.288153',
    count: 0
  },
  {
    code: '421123',
    name: '罗田县',
    city_level: 3,
    lng: '115.399492',
    lat: '30.783100',
    count: 0
  },
  {
    code: '421124',
    name: '英山县',
    city_level: 3,
    lng: '115.681259',
    lat: '30.734959',
    count: 0
  },
  {
    code: '421125',
    name: '浠水县',
    city_level: 3,
    lng: '115.265535',
    lat: '30.451867',
    count: 0
  },
  {
    code: '421126',
    name: '蕲春县',
    city_level: 3,
    lng: '115.437008',
    lat: '30.225964',
    count: 0
  },
  {
    code: '421127',
    name: '黄梅县',
    city_level: 3,
    lng: '115.944219',
    lat: '30.070454',
    count: 0
  },
  {
    code: '421181',
    name: '麻城市',
    city_level: 3,
    lng: '115.008163',
    lat: '31.172740',
    count: 0
  },
  {
    code: '421182',
    name: '武穴市',
    city_level: 3,
    lng: '115.561217',
    lat: '29.844107',
    count: 0
  },
  {
    code: '421202',
    name: '咸安区',
    city_level: 3,
    lng: '114.298711',
    lat: '29.852892',
    count: 0
  },
  {
    code: '421221',
    name: '嘉鱼县',
    city_level: 3,
    lng: '113.939277',
    lat: '29.970737',
    count: 0
  },
  {
    code: '421222',
    name: '通城县',
    city_level: 3,
    lng: '113.816966',
    lat: '29.245269',
    count: 0
  },
  {
    code: '421223',
    name: '崇阳县',
    city_level: 3,
    lng: '114.039828',
    lat: '29.555605',
    count: 0
  },
  {
    code: '421224',
    name: '通山县',
    city_level: 3,
    lng: '114.482606',
    lat: '29.605376',
    count: 0
  },
  {
    code: '421281',
    name: '赤壁市',
    city_level: 3,
    lng: '113.900628',
    lat: '29.724692',
    count: 0
  },
  {
    code: '421303',
    name: '曾都区',
    city_level: 3,
    lng: '113.371121',
    lat: '31.716280',
    count: 0
  },
  {
    code: '421321',
    name: '随县',
    city_level: 3,
    lng: '113.299528',
    lat: '31.853833',
    count: 0
  },
  {
    code: '421381',
    name: '广水市',
    city_level: 3,
    lng: '113.825890',
    lat: '31.616854',
    count: 0
  },
  {
    code: '422801',
    name: '恩施市',
    city_level: 2,
    lng: '109.488172',
    lat: '30.272156',
    count: 0
  },
  {
    code: '422802',
    name: '利川市',
    city_level: 3,
    lng: '108.909996',
    lat: '30.210981',
    count: 0
  },
  {
    code: '422822',
    name: '建始县',
    city_level: 3,
    lng: '109.718202',
    lat: '30.596727',
    count: 0
  },
  {
    code: '422823',
    name: '巴东县',
    city_level: 3,
    lng: '110.340756',
    lat: '31.042324',
    count: 0
  },
  {
    code: '422825',
    name: '宣恩县',
    city_level: 3,
    lng: '109.491485',
    lat: '29.986899',
    count: 0
  },
  {
    code: '422826',
    name: '咸丰县',
    city_level: 3,
    lng: '109.139726',
    lat: '29.665203',
    count: 0
  },
  {
    code: '422827',
    name: '来凤县',
    city_level: 3,
    lng: '109.407828',
    lat: '29.493485',
    count: 0
  },
  {
    code: '422828',
    name: '鹤峰县',
    city_level: 3,
    lng: '110.033662',
    lat: '29.890171',
    count: 0
  },
  {
    code: '429004',
    name: '仙桃市',
    city_level: 3,
    lng: '113.453974',
    lat: '30.364953',
    count: 0
  },
  {
    code: '429005',
    name: '潜江市',
    city_level: 3,
    lng: '112.900287',
    lat: '30.401947',
    count: 0
  },
  {
    code: '429006',
    name: '天门市',
    city_level: 3,
    lng: '113.166558',
    lat: '30.66372',
    count: 0
  },
  {
    code: '429021',
    name: '神农架林区',
    city_level: 3,
    lng: '110.675757',
    lat: '31.744897',
    count: 0
  },
  {
    code: '430102',
    name: '芙蓉区',
    city_level: 3,
    lng: '113.032539',
    lat: '28.185386',
    count: 0
  },
  {
    code: '430103',
    name: '天心区',
    city_level: 3,
    lng: '112.996200',
    lat: '28.144470',
    count: 0
  },
  {
    code: '430104',
    name: '岳麓区',
    city_level: 3,
    lng: '112.931420',
    lat: '28.235193',
    count: 0
  },
  {
    code: '430105',
    name: '开福区',
    city_level: 3,
    lng: '112.984200',
    lat: '28.205628',
    count: 0
  },
  {
    code: '430111',
    name: '雨花区',
    city_level: 3,
    lng: '113.038017',
    lat: '28.137710',
    count: 0
  },
  {
    code: '430112',
    name: '望城区',
    city_level: 3,
    lng: '112.819549',
    lat: '28.347458',
    count: 0
  },
  {
    code: '430121',
    name: '长沙县',
    city_level: 3,
    lng: '113.080810',
    lat: '28.246150',
    count: 0
  },
  {
    code: '430181',
    name: '浏阳市',
    city_level: 3,
    lng: '113.643076',
    lat: '28.162833',
    count: 0
  },
  {
    code: '430182',
    name: '宁乡市',
    city_level: 3,
    lng: '112.553185',
    lat: '28.253927',
    count: 0
  },
  {
    code: '430202',
    name: '荷塘区',
    city_level: 3,
    lng: '113.173487',
    lat: '27.855929',
    count: 0
  },
  {
    code: '430203',
    name: '芦淞区',
    city_level: 3,
    lng: '113.152724',
    lat: '27.785070',
    count: 0
  },
  {
    code: '430204',
    name: '石峰区',
    city_level: 3,
    lng: '113.117732',
    lat: '27.875445',
    count: 0
  },
  {
    code: '430211',
    name: '天元区',
    city_level: 3,
    lng: '113.082216',
    lat: '27.826867',
    count: 0
  },
  {
    code: '430212',
    name: '渌口区',
    city_level: 3,
    lng: '113.139967',
    lat: '27.702526',
    count: 0
  },
  {
    code: '430223',
    name: '攸县',
    city_level: 3,
    lng: '113.396404',
    lat: '27.014607',
    count: 0
  },
  {
    code: '430224',
    name: '茶陵县',
    city_level: 3,
    lng: '113.539280',
    lat: '26.777492',
    count: 0
  },
  {
    code: '430225',
    name: '炎陵县',
    city_level: 3,
    lng: '113.772655',
    lat: '26.489902',
    count: 0
  },
  {
    code: '430281',
    name: '醴陵市',
    city_level: 3,
    lng: '113.496894',
    lat: '27.646130',
    count: 0
  },
  {
    code: '430302',
    name: '雨湖区',
    city_level: 3,
    lng: '112.903317',
    lat: '27.854705',
    count: 0
  },
  {
    code: '430304',
    name: '岳塘区',
    city_level: 3,
    lng: '112.925371',
    lat: '27.808646',
    count: 0
  },
  {
    code: '430321',
    name: '湘潭县',
    city_level: 3,
    lng: '112.950781',
    lat: '27.778947',
    count: 0
  },
  {
    code: '430381',
    name: '湘乡市',
    city_level: 3,
    lng: '112.550581',
    lat: '27.718313',
    count: 0
  },
  {
    code: '430382',
    name: '韶山市',
    city_level: 3,
    lng: '112.526670',
    lat: '27.914958',
    count: 0
  },
  {
    code: '430405',
    name: '珠晖区',
    city_level: 3,
    lng: '112.620112',
    lat: '26.894657',
    count: 0
  },
  {
    code: '430406',
    name: '雁峰区',
    city_level: 2,
    lng: '112.571997',
    lat: '26.893230',
    count: 0
  },
  {
    code: '430407',
    name: '石鼓区',
    city_level: 3,
    lng: '112.598089',
    lat: '26.943215',
    count: 0
  },
  {
    code: '430408',
    name: '蒸湘区',
    city_level: 3,
    lng: '112.567064',
    lat: '26.911404',
    count: 0
  },
  {
    code: '430412',
    name: '南岳区',
    city_level: 3,
    lng: '112.738604',
    lat: '27.232444',
    count: 0
  },
  {
    code: '430421',
    name: '衡阳县',
    city_level: 3,
    lng: '112.370532',
    lat: '26.969635',
    count: 0
  },
  {
    code: '430422',
    name: '衡南县',
    city_level: 3,
    lng: '112.677877',
    lat: '26.738248',
    count: 0
  },
  {
    code: '430423',
    name: '衡山县',
    city_level: 3,
    lng: '112.868268',
    lat: '27.230291',
    count: 0
  },
  {
    code: '430424',
    name: '衡东县',
    city_level: 3,
    lng: '112.953168',
    lat: '27.081170',
    count: 0
  },
  {
    code: '430426',
    name: '祁东县',
    city_level: 3,
    lng: '112.090357',
    lat: '26.799896',
    count: 0
  },
  {
    code: '430481',
    name: '耒阳市',
    city_level: 3,
    lng: '112.859795',
    lat: '26.422275',
    count: 0
  },
  {
    code: '430482',
    name: '常宁市',
    city_level: 3,
    lng: '112.399995',
    lat: '26.420932',
    count: 0
  },
  {
    code: '430502',
    name: '双清区',
    city_level: 3,
    lng: '111.496341',
    lat: '27.232708',
    count: 0
  },
  {
    code: '430503',
    name: '大祥区',
    city_level: 3,
    lng: '111.439091',
    lat: '27.221452',
    count: 0
  },
  {
    code: '430511',
    name: '北塔区',
    city_level: 3,
    lng: '111.452197',
    lat: '27.246489',
    count: 0
  },
  {
    code: '430522',
    name: '新邵县',
    city_level: 3,
    lng: '111.458657',
    lat: '27.320918',
    count: 0
  },
  {
    code: '430523',
    name: '邵阳县',
    city_level: 3,
    lng: '111.273806',
    lat: '26.990637',
    count: 0
  },
  {
    code: '430524',
    name: '隆回县',
    city_level: 3,
    lng: '111.032438',
    lat: '27.113978',
    count: 0
  },
  {
    code: '430525',
    name: '洞口县',
    city_level: 3,
    lng: '110.575846',
    lat: '27.060321',
    count: 0
  },
  {
    code: '430527',
    name: '绥宁县',
    city_level: 3,
    lng: '110.155655',
    lat: '26.581955',
    count: 0
  },
  {
    code: '430528',
    name: '新宁县',
    city_level: 3,
    lng: '110.856623',
    lat: '26.433418',
    count: 0
  },
  {
    code: '430529',
    name: '城步苗族自治县',
    city_level: 3,
    lng: '110.322240',
    lat: '26.390598',
    count: 0
  },
  {
    code: '430581',
    name: '武冈市',
    city_level: 3,
    lng: '110.631884',
    lat: '26.726599',
    count: 0
  },
  {
    code: '430582',
    name: '邵东市',
    city_level: 3,
    lng: '111.743172',
    lat: '27.257273',
    count: 0
  },
  {
    code: '430602',
    name: '岳阳楼区',
    city_level: 3,
    lng: '113.129702',
    lat: '29.371903',
    count: 0
  },
  {
    code: '430603',
    name: '云溪区',
    city_level: 3,
    lng: '113.272313',
    lat: '29.472746',
    count: 0
  },
  {
    code: '430611',
    name: '君山区',
    city_level: 3,
    lng: '113.006435',
    lat: '29.461106',
    count: 0
  },
  {
    code: '430621',
    name: '岳阳县',
    city_level: 3,
    lng: '113.116418',
    lat: '29.144067',
    count: 0
  },
  {
    code: '430623',
    name: '华容县',
    city_level: 3,
    lng: '112.540463',
    lat: '29.531057',
    count: 0
  },
  {
    code: '430624',
    name: '湘阴县',
    city_level: 3,
    lng: '112.909426',
    lat: '28.689105',
    count: 0
  },
  {
    code: '430626',
    name: '平江县',
    city_level: 3,
    lng: '113.581234',
    lat: '28.701868',
    count: 0
  },
  {
    code: '430681',
    name: '汨罗市',
    city_level: 3,
    lng: '113.067259',
    lat: '28.806890',
    count: 0
  },
  {
    code: '430682',
    name: '临湘市',
    city_level: 3,
    lng: '113.450423',
    lat: '29.476849',
    count: 0
  },
  {
    code: '430702',
    name: '武陵区',
    city_level: 3,
    lng: '111.683153',
    lat: '29.055163',
    count: 0
  },
  {
    code: '430703',
    name: '鼎城区',
    city_level: 3,
    lng: '111.680783',
    lat: '29.018593',
    count: 0
  },
  {
    code: '430721',
    name: '安乡县',
    city_level: 3,
    lng: '112.171131',
    lat: '29.411309',
    count: 0
  },
  {
    code: '430722',
    name: '汉寿县',
    city_level: 3,
    lng: '111.970514',
    lat: '28.906107',
    count: 0
  },
  {
    code: '430723',
    name: '澧县',
    city_level: 3,
    lng: '111.758702',
    lat: '29.633237',
    count: 0
  },
  {
    code: '430724',
    name: '临澧县',
    city_level: 3,
    lng: '111.647518',
    lat: '29.440793',
    count: 0
  },
  {
    code: '430725',
    name: '桃源县',
    city_level: 3,
    lng: '111.488925',
    lat: '28.902503',
    count: 0
  },
  {
    code: '430726',
    name: '石门县',
    city_level: 3,
    lng: '111.380014',
    lat: '29.584293',
    count: 0
  },
  {
    code: '430781',
    name: '津市市',
    city_level: 3,
    lng: '111.877499',
    lat: '29.605480',
    count: 0
  },
  {
    code: '430802',
    name: '永定区',
    city_level: 3,
    lng: '110.537138',
    lat: '29.119856',
    count: 0
  },
  {
    code: '430811',
    name: '武陵源区',
    city_level: 3,
    lng: '110.550434',
    lat: '29.345730',
    count: 0
  },
  {
    code: '430821',
    name: '慈利县',
    city_level: 3,
    lng: '111.139711',
    lat: '29.429972',
    count: 0
  },
  {
    code: '430822',
    name: '桑植县',
    city_level: 3,
    lng: '110.204911',
    lat: '29.414264',
    count: 0
  },
  {
    code: '430902',
    name: '资阳区',
    city_level: 3,
    lng: '112.324322',
    lat: '28.590966',
    count: 0
  },
  {
    code: '430903',
    name: '赫山区',
    city_level: 3,
    lng: '112.374024',
    lat: '28.579343',
    count: 0
  },
  {
    code: '430921',
    name: '南县',
    city_level: 3,
    lng: '112.396241',
    lat: '29.361338',
    count: 0
  },
  {
    code: '430922',
    name: '桃江县',
    city_level: 3,
    lng: '112.155822',
    lat: '28.518085',
    count: 0
  },
  {
    code: '430923',
    name: '安化县',
    city_level: 3,
    lng: '111.212846',
    lat: '28.374107',
    count: 0
  },
  {
    code: '430981',
    name: '沅江市',
    city_level: 3,
    lng: '112.354661',
    lat: '28.845837',
    count: 0
  },
  {
    code: '431002',
    name: '北湖区',
    city_level: 3,
    lng: '113.011035',
    lat: '25.784054',
    count: 0
  },
  {
    code: '431003',
    name: '苏仙区',
    city_level: 3,
    lng: '113.042441',
    lat: '25.800370',
    count: 0
  },
  {
    code: '431021',
    name: '桂阳县',
    city_level: 3,
    lng: '112.734176',
    lat: '25.754167',
    count: 0
  },
  {
    code: '431022',
    name: '宜章县',
    city_level: 3,
    lng: '112.948811',
    lat: '25.400222',
    count: 0
  },
  {
    code: '431023',
    name: '永兴县',
    city_level: 3,
    lng: '113.116528',
    lat: '26.127151',
    count: 0
  },
  {
    code: '431024',
    name: '嘉禾县',
    city_level: 3,
    lng: '112.369021',
    lat: '25.587520',
    count: 0
  },
  {
    code: '431025',
    name: '临武县',
    city_level: 3,
    lng: '112.563456',
    lat: '25.275560',
    count: 0
  },
  {
    code: '431026',
    name: '汝城县',
    city_level: 3,
    lng: '113.684727',
    lat: '25.532816',
    count: 0
  },
  {
    code: '431027',
    name: '桂东县',
    city_level: 3,
    lng: '113.944614',
    lat: '26.077616',
    count: 0
  },
  {
    code: '431028',
    name: '安仁县',
    city_level: 3,
    lng: '113.269396',
    lat: '26.709056',
    count: 0
  },
  {
    code: '431081',
    name: '资兴市',
    city_level: 3,
    lng: '113.236146',
    lat: '25.976243',
    count: 0
  },
  {
    code: '431102',
    name: '零陵区',
    city_level: 3,
    lng: '111.631109',
    lat: '26.221936',
    count: 0
  },
  {
    code: '431103',
    name: '冷水滩区',
    city_level: 3,
    lng: '111.592143',
    lat: '26.461077',
    count: 0
  },
  {
    code: '431121',
    name: '祁阳县',
    city_level: 3,
    lng: '111.840657',
    lat: '26.580120',
    count: 0
  },
  {
    code: '431122',
    name: '东安县',
    city_level: 3,
    lng: '111.314117',
    lat: '26.394404',
    count: 0
  },
  {
    code: '431123',
    name: '双牌县',
    city_level: 3,
    lng: '111.659967',
    lat: '25.961910',
    count: 0
  },
  {
    code: '431124',
    name: '道县',
    city_level: 3,
    lng: '111.600796',
    lat: '25.526438',
    count: 0
  },
  {
    code: '431125',
    name: '江永县',
    city_level: 3,
    lng: '111.343911',
    lat: '25.273539',
    count: 0
  },
  {
    code: '431126',
    name: '宁远县',
    city_level: 3,
    lng: '111.945805',
    lat: '25.570976',
    count: 0
  },
  {
    code: '431127',
    name: '蓝山县',
    city_level: 3,
    lng: '112.196731',
    lat: '25.369898',
    count: 0
  },
  {
    code: '431128',
    name: '新田县',
    city_level: 3,
    lng: '112.203287',
    lat: '25.904305',
    count: 0
  },
  {
    code: '431129',
    name: '江华瑶族自治县',
    city_level: 3,
    lng: '111.579305',
    lat: '25.185590',
    count: 0
  },
  {
    code: '431202',
    name: '鹤城区',
    city_level: 3,
    lng: '110.040315',
    lat: '27.578926',
    count: 0
  },
  {
    code: '431221',
    name: '中方县',
    city_level: 3,
    lng: '109.944712',
    lat: '27.440139',
    count: 0
  },
  {
    code: '431222',
    name: '沅陵县',
    city_level: 3,
    lng: '110.393844',
    lat: '28.452686',
    count: 0
  },
  {
    code: '431223',
    name: '辰溪县',
    city_level: 3,
    lng: '110.183917',
    lat: '28.006336',
    count: 0
  },
  {
    code: '431224',
    name: '溆浦县',
    city_level: 3,
    lng: '110.594972',
    lat: '27.908316',
    count: 0
  },
  {
    code: '431225',
    name: '会同县',
    city_level: 3,
    lng: '109.735661',
    lat: '26.887239',
    count: 0
  },
  {
    code: '431226',
    name: '麻阳苗族自治县',
    city_level: 3,
    lng: '109.802587',
    lat: '27.865548',
    count: 0
  },
  {
    code: '431227',
    name: '新晃侗族自治县',
    city_level: 3,
    lng: '109.174932',
    lat: '27.352673',
    count: 0
  },
  {
    code: '431228',
    name: '芷江侗族自治县',
    city_level: 3,
    lng: '109.684629',
    lat: '27.443499',
    count: 0
  },
  {
    code: '431229',
    name: '靖州苗族侗族自治县',
    city_level: 3,
    lng: '109.696311',
    lat: '26.575052',
    count: 0
  },
  {
    code: '431230',
    name: '通道侗族自治县',
    city_level: 3,
    lng: '109.784412',
    lat: '26.158054',
    count: 0
  },
  {
    code: '431281',
    name: '洪江市',
    city_level: 3,
    lng: '109.836669',
    lat: '27.208609',
    count: 0
  },
  {
    code: '431302',
    name: '娄星区',
    city_level: 3,
    lng: '112.001937',
    lat: '27.729907',
    count: 0
  },
  {
    code: '431321',
    name: '双峰县',
    city_level: 3,
    lng: '112.175246',
    lat: '27.456658',
    count: 0
  },
  {
    code: '431322',
    name: '新化县',
    city_level: 3,
    lng: '111.327412',
    lat: '27.726515',
    count: 0
  },
  {
    code: '431381',
    name: '冷水江市',
    city_level: 3,
    lng: '111.435623',
    lat: '27.685850',
    count: 0
  },
  {
    code: '431382',
    name: '涟源市',
    city_level: 3,
    lng: '111.664316',
    lat: '27.692542',
    count: 0
  },
  {
    code: '433101',
    name: '吉首市',
    city_level: 3,
    lng: '109.926773',
    lat: '28.297201',
    count: 0
  },
  {
    code: '433122',
    name: '泸溪县',
    city_level: 3,
    lng: '110.219610',
    lat: '28.216641',
    count: 0
  },
  {
    code: '433123',
    name: '凤凰县',
    city_level: 3,
    lng: '109.581072',
    lat: '27.958062',
    count: 0
  },
  {
    code: '433124',
    name: '花垣县',
    city_level: 3,
    lng: '109.482078',
    lat: '28.572030',
    count: 0
  },
  {
    code: '433125',
    name: '保靖县',
    city_level: 3,
    lng: '109.660577',
    lat: '28.699774',
    count: 0
  },
  {
    code: '433126',
    name: '古丈县',
    city_level: 3,
    lng: '109.950728',
    lat: '28.616935',
    count: 0
  },
  {
    code: '433127',
    name: '永顺县',
    city_level: 3,
    lng: '109.851254',
    lat: '29.001440',
    count: 0
  },
  {
    code: '433130',
    name: '龙山县',
    city_level: 3,
    lng: '109.443939',
    lat: '29.457663',
    count: 0
  },
  {
    code: '440103',
    name: '荔湾区',
    city_level: 3,
    lng: '113.244261',
    lat: '23.125981',
    count: 0
  },
  {
    code: '440104',
    name: '越秀区',
    city_level: 3,
    lng: '113.266841',
    lat: '23.128524',
    count: 0
  },
  {
    code: '440105',
    name: '海珠区',
    city_level: 3,
    lng: '113.317388',
    lat: '23.083801',
    count: 0
  },
  {
    code: '440106',
    name: '天河区',
    city_level: 3,
    lng: '113.361200',
    lat: '23.124680',
    count: 0
  },
  {
    code: '440111',
    name: '白云区',
    city_level: 3,
    lng: '113.273289',
    lat: '23.157290',
    count: 0
  },
  {
    code: '440112',
    name: '黄埔区',
    city_level: 3,
    lng: '113.459749',
    lat: '23.106402',
    count: 0
  },
  {
    code: '440113',
    name: '番禺区',
    city_level: 3,
    lng: '113.384129',
    lat: '22.937244',
    count: 0
  },
  {
    code: '440114',
    name: '花都区',
    city_level: 3,
    lng: '113.220218',
    lat: '23.404165',
    count: 0
  },
  {
    code: '440115',
    name: '南沙区',
    city_level: 3,
    lng: '113.525165',
    lat: '22.801624',
    count: 0
  },
  {
    code: '440117',
    name: '从化区',
    city_level: 3,
    lng: '113.586605',
    lat: '23.548852',
    count: 0
  },
  {
    code: '440118',
    name: '增城区',
    city_level: 3,
    lng: '113.810860',
    lat: '23.261141',
    count: 0
  },
  {
    code: '440203',
    name: '武江区',
    city_level: 3,
    lng: '113.587774',
    lat: '24.792924',
    count: 0
  },
  {
    code: '440204',
    name: '浈江区',
    city_level: 3,
    lng: '113.611098',
    lat: '24.804381',
    count: 0
  },
  {
    code: '440205',
    name: '曲江区',
    city_level: 3,
    lng: '113.604549',
    lat: '24.682728',
    count: 0
  },
  {
    code: '440222',
    name: '始兴县',
    city_level: 3,
    lng: '114.061789',
    lat: '24.952977',
    count: 0
  },
  {
    code: '440224',
    name: '仁化县',
    city_level: 3,
    lng: '113.749027',
    lat: '25.085621',
    count: 0
  },
  {
    code: '440229',
    name: '翁源县',
    city_level: 3,
    lng: '114.130342',
    lat: '24.350347',
    count: 0
  },
  {
    code: '440232',
    name: '乳源瑶族自治县',
    city_level: 3,
    lng: '113.275883',
    lat: '24.776078',
    count: 0
  },
  {
    code: '440233',
    name: '新丰县',
    city_level: 3,
    lng: '114.206867',
    lat: '24.059760',
    count: 0
  },
  {
    code: '440281',
    name: '乐昌市',
    city_level: 3,
    lng: '113.347519',
    lat: '25.130136',
    count: 0
  },
  {
    code: '440282',
    name: '南雄市',
    city_level: 3,
    lng: '114.311982',
    lat: '25.117753',
    count: 0
  },
  {
    code: '440303',
    name: '罗湖区',
    city_level: 3,
    lng: '114.131764',
    lat: '22.548171',
    count: 0
  },
  {
    code: '440304',
    name: '福田区',
    city_level: 3,
    lng: '114.055036',
    lat: '22.521530',
    count: 0
  },
  {
    code: '440305',
    name: '南山区',
    city_level: 3,
    lng: '113.923552',
    lat: '22.528499',
    count: 0
  },
  {
    code: '440306',
    name: '宝安区',
    city_level: 3,
    lng: '113.884020',
    lat: '22.555259',
    count: 0
  },
  {
    code: '440307',
    name: '龙岗区',
    city_level: 3,
    lng: '114.246899',
    lat: '22.720968',
    count: 0
  },
  {
    code: '440308',
    name: '盐田区',
    city_level: 3,
    lng: '114.236875',
    lat: '22.556499',
    count: 0
  },
  {
    code: '440309',
    name: '龙华区',
    city_level: 3,
    lng: '110.328492',
    lat: '20.031007',
    count: 0
  },
  {
    code: '440310',
    name: '坪山区',
    city_level: 2,
    lng: '114.057868',
    lat: '22.543099',
    count: 0
  },
  {
    code: '440311',
    name: '光明区',
    city_level: 3,
    lng: '109.056572',
    lat: '34.297810',
    count: 0
  },
  {
    code: '440402',
    name: '香洲区',
    city_level: 3,
    lng: '113.543785',
    lat: '22.265811',
    count: 0
  },
  {
    code: '440403',
    name: '斗门区',
    city_level: 3,
    lng: '113.296467',
    lat: '22.209200',
    count: 0
  },
  {
    code: '440404',
    name: '金湾区',
    city_level: 3,
    lng: '113.363393',
    lat: '22.146874',
    count: 0
  },
  {
    code: '440507',
    name: '龙湖区',
    city_level: 3,
    lng: '116.716534',
    lat: '23.371476',
    count: 0
  },
  {
    code: '440511',
    name: '金平区',
    city_level: 3,
    lng: '116.703426',
    lat: '23.365613',
    count: 0
  },
  {
    code: '440512',
    name: '濠江区',
    city_level: 3,
    lng: '116.726973',
    lat: '23.286079',
    count: 0
  },
  {
    code: '440513',
    name: '潮阳区',
    city_level: 3,
    lng: '116.601515',
    lat: '23.264936',
    count: 0
  },
  {
    code: '440514',
    name: '潮南区',
    city_level: 3,
    lng: '116.433017',
    lat: '23.250425',
    count: 0
  },
  {
    code: '440515',
    name: '澄海区',
    city_level: 3,
    lng: '116.756092',
    lat: '23.465960',
    count: 0
  },
  {
    code: '440523',
    name: '南澳县',
    city_level: 3,
    lng: '117.023374',
    lat: '23.421724',
    count: 0
  },
  {
    code: '440604',
    name: '禅城区',
    city_level: 3,
    lng: '113.122440',
    lat: '23.009505',
    count: 0
  },
  {
    code: '440605',
    name: '南海区',
    city_level: 3,
    lng: '113.147759',
    lat: '23.025412',
    count: 0
  },
  {
    code: '440606',
    name: '顺德区',
    city_level: 3,
    lng: '113.293359',
    lat: '22.805240',
    count: 0
  },
  {
    code: '440607',
    name: '三水区',
    city_level: 3,
    lng: '112.896668',
    lat: '23.156065',
    count: 0
  },
  {
    code: '440608',
    name: '高明区',
    city_level: 3,
    lng: '112.892578',
    lat: '22.900182',
    count: 0
  },
  {
    code: '440703',
    name: '蓬江区',
    city_level: 3,
    lng: '113.078521',
    lat: '22.595149',
    count: 0
  },
  {
    code: '440704',
    name: '江海区',
    city_level: 3,
    lng: '113.111612',
    lat: '22.560474',
    count: 0
  },
  {
    code: '440705',
    name: '新会区',
    city_level: 3,
    lng: '113.034187',
    lat: '22.458300',
    count: 0
  },
  {
    code: '440781',
    name: '台山市',
    city_level: 3,
    lng: '112.795970',
    lat: '22.251596',
    count: 0
  },
  {
    code: '440783',
    name: '开平市',
    city_level: 3,
    lng: '112.698545',
    lat: '22.376395',
    count: 0
  },
  {
    code: '440784',
    name: '鹤山市',
    city_level: 3,
    lng: '112.964446',
    lat: '22.765392',
    count: 0
  },
  {
    code: '440785',
    name: '恩平市',
    city_level: 3,
    lng: '112.305145',
    lat: '22.183206',
    count: 0
  },
  {
    code: '440802',
    name: '赤坎区',
    city_level: 3,
    lng: '110.365900',
    lat: '21.266119',
    count: 0
  },
  {
    code: '440803',
    name: '霞山区',
    city_level: 3,
    lng: '110.398070',
    lat: '21.191720',
    count: 0
  },
  {
    code: '440804',
    name: '坡头区',
    city_level: 3,
    lng: '110.455332',
    lat: '21.244721',
    count: 0
  },
  {
    code: '440811',
    name: '麻章区',
    city_level: 3,
    lng: '110.334387',
    lat: '21.263443',
    count: 0
  },
  {
    code: '440823',
    name: '遂溪县',
    city_level: 3,
    lng: '110.250124',
    lat: '21.377246',
    count: 0
  },
  {
    code: '440825',
    name: '徐闻县',
    city_level: 3,
    lng: '110.176750',
    lat: '20.325489',
    count: 0
  },
  {
    code: '440881',
    name: '廉江市',
    city_level: 3,
    lng: '110.286209',
    lat: '21.609700',
    count: 0
  },
  {
    code: '440882',
    name: '雷州市',
    city_level: 3,
    lng: '110.096749',
    lat: '20.914278',
    count: 0
  },
  {
    code: '440883',
    name: '吴川市',
    city_level: 3,
    lng: '110.778411',
    lat: '21.441808',
    count: 0
  },
  {
    code: '440902',
    name: '茂南区',
    city_level: 3,
    lng: '110.918026',
    lat: '21.641337',
    count: 0
  },
  {
    code: '440904',
    name: '电白区',
    city_level: 3,
    lng: '111.013556',
    lat: '21.514164',
    count: 0
  },
  {
    code: '440981',
    name: '高州市',
    city_level: 3,
    lng: '110.853302',
    lat: '21.917982',
    count: 0
  },
  {
    code: '440982',
    name: '化州市',
    city_level: 3,
    lng: '110.639569',
    lat: '21.664044',
    count: 0
  },
  {
    code: '440983',
    name: '信宜市',
    city_level: 3,
    lng: '110.947044',
    lat: '22.354385',
    count: 0
  },
  {
    code: '441202',
    name: '端州区',
    city_level: 3,
    lng: '112.484848',
    lat: '23.052101',
    count: 0
  },
  {
    code: '441203',
    name: '鼎湖区',
    city_level: 3,
    lng: '112.567588',
    lat: '23.158447',
    count: 0
  },
  {
    code: '441204',
    name: '高要区',
    city_level: 3,
    lng: '112.457771',
    lat: '23.025668',
    count: 0
  },
  {
    code: '441223',
    name: '广宁县',
    city_level: 3,
    lng: '112.440690',
    lat: '23.634676',
    count: 0
  },
  {
    code: '441224',
    name: '怀集县',
    city_level: 3,
    lng: '112.184652',
    lat: '23.911899',
    count: 0
  },
  {
    code: '441225',
    name: '封开县',
    city_level: 3,
    lng: '111.512343',
    lat: '23.424033',
    count: 0
  },
  {
    code: '441226',
    name: '德庆县',
    city_level: 3,
    lng: '111.785937',
    lat: '23.143722',
    count: 0
  },
  {
    code: '441284',
    name: '四会市',
    city_level: 3,
    lng: '112.733773',
    lat: '23.326504',
    count: 0
  },
  {
    code: '441302',
    name: '惠城区',
    city_level: 3,
    lng: '114.382485',
    lat: '23.084109',
    count: 0
  },
  {
    code: '441303',
    name: '惠阳区',
    city_level: 3,
    lng: '114.456696',
    lat: '22.788734',
    count: 0
  },
  {
    code: '441322',
    name: '博罗县',
    city_level: 3,
    lng: '114.289496',
    lat: '23.172899',
    count: 0
  },
  {
    code: '441323',
    name: '惠东县',
    city_level: 3,
    lng: '114.720079',
    lat: '22.984975',
    count: 0
  },
  {
    code: '441324',
    name: '龙门县',
    city_level: 3,
    lng: '114.254913',
    lat: '23.727732',
    count: 0
  },
  {
    code: '441402',
    name: '梅江区',
    city_level: 3,
    lng: '116.116616',
    lat: '24.310384',
    count: 0
  },
  {
    code: '441403',
    name: '梅县区',
    city_level: 3,
    lng: '116.082183',
    lat: '24.265312',
    count: 0
  },
  {
    code: '441422',
    name: '大埔县',
    city_level: 3,
    lng: '116.695195',
    lat: '24.347783',
    count: 0
  },
  {
    code: '441423',
    name: '丰顺县',
    city_level: 3,
    lng: '116.182299',
    lat: '23.739526',
    count: 0
  },
  {
    code: '441424',
    name: '五华县',
    city_level: 3,
    lng: '115.776983',
    lat: '23.930769',
    count: 0
  },
  {
    code: '441426',
    name: '平远县',
    city_level: 3,
    lng: '115.891638',
    lat: '24.567262',
    count: 0
  },
  {
    code: '441427',
    name: '蕉岭县',
    city_level: 3,
    lng: '116.171356',
    lat: '24.658700',
    count: 0
  },
  {
    code: '441481',
    name: '兴宁市',
    city_level: 3,
    lng: '115.723805',
    lat: '24.126159',
    count: 0
  },
  {
    code: '441502',
    name: '城区',
    city_level: 3,
    lng: '115.365029',
    lat: '22.778699',
    count: 0
  },
  {
    code: '441521',
    name: '海丰县',
    city_level: 3,
    lng: '115.323436',
    lat: '22.966586',
    count: 0
  },
  {
    code: '441523',
    name: '陆河县',
    city_level: 3,
    lng: '115.660143',
    lat: '23.301617',
    count: 0
  },
  {
    code: '441581',
    name: '陆丰市',
    city_level: 3,
    lng: '115.652260',
    lat: '22.917576',
    count: 0
  },
  {
    code: '441602',
    name: '源城区',
    city_level: 3,
    lng: '114.702517',
    lat: '23.733969',
    count: 0
  },
  {
    code: '441621',
    name: '紫金县',
    city_level: 3,
    lng: '115.184061',
    lat: '23.635271',
    count: 0
  },
  {
    code: '441622',
    name: '龙川县',
    city_level: 3,
    lng: '115.259872',
    lat: '24.100066',
    count: 0
  },
  {
    code: '441623',
    name: '连平县',
    city_level: 3,
    lng: '114.488714',
    lat: '24.369588',
    count: 0
  },
  {
    code: '441624',
    name: '和平县',
    city_level: 3,
    lng: '114.938684',
    lat: '24.442180',
    count: 0
  },
  {
    code: '441625',
    name: '东源县',
    city_level: 3,
    lng: '114.746380',
    lat: '23.788393',
    count: 0
  },
  {
    code: '441702',
    name: '江城区',
    city_level: 3,
    lng: '111.955059',
    lat: '21.861787',
    count: 0
  },
  {
    code: '441704',
    name: '阳东区',
    city_level: 3,
    lng: '112.006338',
    lat: '21.868358',
    count: 0
  },
  {
    code: '441721',
    name: '阳西县',
    city_level: 3,
    lng: '111.617849',
    lat: '21.752396',
    count: 0
  },
  {
    code: '441781',
    name: '阳春市',
    city_level: 3,
    lng: '111.791539',
    lat: '22.170438',
    count: 0
  },
  {
    code: '441802',
    name: '清城区',
    city_level: 3,
    lng: '113.062692',
    lat: '23.697899',
    count: 0
  },
  {
    code: '441803',
    name: '清新区',
    city_level: 3,
    lng: '113.017749',
    lat: '23.734673',
    count: 0
  },
  {
    code: '441821',
    name: '佛冈县',
    city_level: 3,
    lng: '113.531607',
    lat: '23.879192',
    count: 0
  },
  {
    code: '441823',
    name: '阳山县',
    city_level: 3,
    lng: '112.641363',
    lat: '24.465359',
    count: 0
  },
  {
    code: '441825',
    name: '连山壮族瑶族自治县',
    city_level: 3,
    lng: '112.093617',
    lat: '24.570491',
    count: 0
  },
  {
    code: '441826',
    name: '连南瑶族自治县',
    city_level: 3,
    lng: '112.287012',
    lat: '24.726017',
    count: 0
  },
  {
    code: '441881',
    name: '英德市',
    city_level: 3,
    lng: '113.402376',
    lat: '24.205024',
    count: 0
  },
  {
    code: '441882',
    name: '连州市',
    city_level: 3,
    lng: '112.377361',
    lat: '24.780966',
    count: 0
  },
  {
    code: '445102',
    name: '湘桥区',
    city_level: 3,
    lng: '116.628632',
    lat: '23.674536',
    count: 0
  },
  {
    code: '445103',
    name: '潮安区',
    city_level: 3,
    lng: '116.678204',
    lat: '23.462613',
    count: 0
  },
  {
    code: '445122',
    name: '饶平县',
    city_level: 3,
    lng: '117.003900',
    lat: '23.663824',
    count: 0
  },
  {
    code: '445202',
    name: '榕城区',
    city_level: 3,
    lng: '116.367035',
    lat: '23.525229',
    count: 0
  },
  {
    code: '445203',
    name: '揭东区',
    city_level: 3,
    lng: '116.412015',
    lat: '23.566127',
    count: 0
  },
  {
    code: '445222',
    name: '揭西县',
    city_level: 3,
    lng: '115.841838',
    lat: '23.431294',
    count: 0
  },
  {
    code: '445224',
    name: '惠来县',
    city_level: 3,
    lng: '116.295150',
    lat: '23.033267',
    count: 0
  },
  {
    code: '445281',
    name: '普宁市',
    city_level: 3,
    lng: '116.166004',
    lat: '23.297642',
    count: 0
  },
  {
    code: '445302',
    name: '云城区',
    city_level: 3,
    lng: '112.043832',
    lat: '22.928097',
    count: 0
  },
  {
    code: '445303',
    name: '云安区',
    city_level: 3,
    lng: '112.003209',
    lat: '23.071020',
    count: 0
  },
  {
    code: '445321',
    name: '新兴县',
    city_level: 3,
    lng: '112.225335',
    lat: '22.695690',
    count: 0
  },
  {
    code: '445322',
    name: '郁南县',
    city_level: 3,
    lng: '111.535249',
    lat: '23.234627',
    count: 0
  },
  {
    code: '445381',
    name: '罗定市',
    city_level: 3,
    lng: '111.569667',
    lat: '22.768939',
    count: 0
  },
  {
    code: '450102',
    name: '兴宁区',
    city_level: 3,
    lng: '108.368871',
    lat: '22.854021',
    count: 0
  },
  {
    code: '450103',
    name: '青秀区',
    city_level: 3,
    lng: '108.494024',
    lat: '22.785879',
    count: 0
  },
  {
    code: '450105',
    name: '江南区',
    city_level: 3,
    lng: '108.273158',
    lat: '22.781632',
    count: 0
  },
  {
    code: '450107',
    name: '西乡塘区',
    city_level: 3,
    lng: '108.306886',
    lat: '22.832770',
    count: 0
  },
  {
    code: '450108',
    name: '良庆区',
    city_level: 3,
    lng: '108.322102',
    lat: '22.759090',
    count: 0
  },
  {
    code: '450109',
    name: '邕宁区',
    city_level: 3,
    lng: '108.487369',
    lat: '22.758390',
    count: 0
  },
  {
    code: '450110',
    name: '武鸣区',
    city_level: 3,
    lng: '108.274712',
    lat: '23.158693',
    count: 0
  },
  {
    code: '450123',
    name: '隆安县',
    city_level: 3,
    lng: '107.696153',
    lat: '23.166028',
    count: 0
  },
  {
    code: '450124',
    name: '马山县',
    city_level: 3,
    lng: '108.176979',
    lat: '23.708192',
    count: 0
  },
  {
    code: '450125',
    name: '上林县',
    city_level: 3,
    lng: '108.604921',
    lat: '23.431936',
    count: 0
  },
  {
    code: '450126',
    name: '宾阳县',
    city_level: 3,
    lng: '108.810326',
    lat: '23.217832',
    count: 0
  },
  {
    code: '450127',
    name: '横县',
    city_level: 3,
    lng: '109.261384',
    lat: '22.679932',
    count: 0
  },
  {
    code: '450202',
    name: '城中区',
    city_level: 3,
    lng: '101.784554',
    lat: '36.621181',
    count: 0
  },
  {
    code: '450203',
    name: '鱼峰区',
    city_level: 3,
    lng: '109.452442',
    lat: '24.318517',
    count: 0
  },
  {
    code: '450204',
    name: '柳南区',
    city_level: 3,
    lng: '109.385519',
    lat: '24.336229',
    count: 0
  },
  {
    code: '450205',
    name: '柳北区',
    city_level: 3,
    lng: '109.402050',
    lat: '24.362691',
    count: 0
  },
  {
    code: '450206',
    name: '柳江区',
    city_level: 3,
    lng: '109.326380',
    lat: '24.254892',
    count: 0
  },
  {
    code: '450222',
    name: '柳城县',
    city_level: 3,
    lng: '109.244730',
    lat: '24.651518',
    count: 0
  },
  {
    code: '450223',
    name: '鹿寨县',
    city_level: 3,
    lng: '109.750638',
    lat: '24.472897',
    count: 0
  },
  {
    code: '450224',
    name: '融安县',
    city_level: 3,
    lng: '109.397538',
    lat: '25.224550',
    count: 0
  },
  {
    code: '450225',
    name: '融水苗族自治县',
    city_level: 3,
    lng: '109.256334',
    lat: '25.065934',
    count: 0
  },
  {
    code: '450226',
    name: '三江侗族自治县',
    city_level: 3,
    lng: '109.607675',
    lat: '25.783198',
    count: 0
  },
  {
    code: '450302',
    name: '秀峰区',
    city_level: 3,
    lng: '110.264183',
    lat: '25.273625',
    count: 0
  },
  {
    code: '450303',
    name: '叠彩区',
    city_level: 3,
    lng: '110.301723',
    lat: '25.314001',
    count: 0
  },
  {
    code: '450304',
    name: '象山区',
    city_level: 3,
    lng: '110.281082',
    lat: '25.261687',
    count: 0
  },
  {
    code: '450305',
    name: '七星区',
    city_level: 3,
    lng: '110.317826',
    lat: '25.252701',
    count: 0
  },
  {
    code: '450311',
    name: '雁山区',
    city_level: 3,
    lng: '110.286690',
    lat: '25.101935',
    count: 0
  },
  {
    code: '450312',
    name: '临桂区',
    city_level: 3,
    lng: '110.212463',
    lat: '25.238628',
    count: 0
  },
  {
    code: '450321',
    name: '阳朔县',
    city_level: 3,
    lng: '110.496593',
    lat: '24.778481',
    count: 0
  },
  {
    code: '450323',
    name: '灵川县',
    city_level: 3,
    lng: '110.325636',
    lat: '25.409747',
    count: 0
  },
  {
    code: '450324',
    name: '全州县',
    city_level: 3,
    lng: '111.072926',
    lat: '25.928617',
    count: 0
  },
  {
    code: '450325',
    name: '兴安县',
    city_level: 3,
    lng: '110.671670',
    lat: '25.611705',
    count: 0
  },
  {
    code: '450326',
    name: '永福县',
    city_level: 3,
    lng: '109.983076',
    lat: '24.979856',
    count: 0
  },
  {
    code: '450327',
    name: '灌阳县',
    city_level: 3,
    lng: '111.160851',
    lat: '25.489383',
    count: 0
  },
  {
    code: '450328',
    name: '龙胜各族自治县',
    city_level: 3,
    lng: '110.011238',
    lat: '25.797931',
    count: 0
  },
  {
    code: '450329',
    name: '资源县',
    city_level: 3,
    lng: '110.652700',
    lat: '26.042443',
    count: 0
  },
  {
    code: '450330',
    name: '平乐县',
    city_level: 3,
    lng: '110.643305',
    lat: '24.633362',
    count: 0
  },
  {
    code: '450332',
    name: '恭城瑶族自治县',
    city_level: 3,
    lng: '110.828410',
    lat: '24.831682',
    count: 0
  },
  {
    code: '450381',
    name: '荔浦市',
    city_level: 2,
    lng: '110.290194',
    lat: '25.273566',
    count: 0
  },
  {
    code: '450403',
    name: '万秀区',
    city_level: 3,
    lng: '111.320542',
    lat: '23.472962',
    count: 0
  },
  {
    code: '450405',
    name: '长洲区',
    city_level: 3,
    lng: '111.274777',
    lat: '23.485695',
    count: 0
  },
  {
    code: '450406',
    name: '龙圩区',
    city_level: 3,
    lng: '111.246035',
    lat: '23.409960',
    count: 0
  },
  {
    code: '450421',
    name: '苍梧县',
    city_level: 3,
    lng: '111.544008',
    lat: '23.845097',
    count: 0
  },
  {
    code: '450422',
    name: '藤县',
    city_level: 3,
    lng: '110.914849',
    lat: '23.374984',
    count: 0
  },
  {
    code: '450423',
    name: '蒙山县',
    city_level: 3,
    lng: '110.525003',
    lat: '24.193570',
    count: 0
  },
  {
    code: '450481',
    name: '岑溪市',
    city_level: 3,
    lng: '110.994913',
    lat: '22.918350',
    count: 0
  },
  {
    code: '450502',
    name: '海城区',
    city_level: 3,
    lng: '109.117210',
    lat: '21.475005',
    count: 0
  },
  {
    code: '450503',
    name: '银海区',
    city_level: 3,
    lng: '109.139990',
    lat: '21.449219',
    count: 0
  },
  {
    code: '450512',
    name: '铁山港区',
    city_level: 3,
    lng: '109.421581',
    lat: '21.529128',
    count: 0
  },
  {
    code: '450521',
    name: '合浦县',
    city_level: 3,
    lng: '109.207336',
    lat: '21.660936',
    count: 0
  },
  {
    code: '450602',
    name: '港口区',
    city_level: 3,
    lng: '108.380144',
    lat: '21.643384',
    count: 0
  },
  {
    code: '450603',
    name: '防城区',
    city_level: 3,
    lng: '108.353499',
    lat: '21.769212',
    count: 0
  },
  {
    code: '450621',
    name: '上思县',
    city_level: 3,
    lng: '107.983626',
    lat: '22.153672',
    count: 0
  },
  {
    code: '450681',
    name: '东兴市',
    city_level: 3,
    lng: '107.971826',
    lat: '21.547822',
    count: 0
  },
  {
    code: '450702',
    name: '钦南区',
    city_level: 3,
    lng: '108.657210',
    lat: '21.938860',
    count: 0
  },
  {
    code: '450703',
    name: '钦北区',
    city_level: 3,
    lng: '108.449110',
    lat: '22.132761',
    count: 0
  },
  {
    code: '450721',
    name: '灵山县',
    city_level: 3,
    lng: '109.291007',
    lat: '22.416537',
    count: 0
  },
  {
    code: '450722',
    name: '浦北县',
    city_level: 3,
    lng: '109.556953',
    lat: '22.271651',
    count: 0
  },
  {
    code: '450802',
    name: '港北区',
    city_level: 3,
    lng: '109.572240',
    lat: '23.111531',
    count: 0
  },
  {
    code: '450803',
    name: '港南区',
    city_level: 3,
    lng: '109.599557',
    lat: '23.075573',
    count: 0
  },
  {
    code: '450804',
    name: '覃塘区',
    city_level: 3,
    lng: '109.452662',
    lat: '23.127149',
    count: 0
  },
  {
    code: '450821',
    name: '平南县',
    city_level: 3,
    lng: '110.392168',
    lat: '23.539127',
    count: 0
  },
  {
    code: '450881',
    name: '桂平市',
    city_level: 3,
    lng: '110.079379',
    lat: '23.394326',
    count: 0
  },
  {
    code: '450902',
    name: '玉州区',
    city_level: 3,
    lng: '110.151088',
    lat: '22.628062',
    count: 0
  },
  {
    code: '450903',
    name: '福绵区',
    city_level: 3,
    lng: '110.059439',
    lat: '22.585557',
    count: 0
  },
  {
    code: '450921',
    name: '容县',
    city_level: 3,
    lng: '110.557874',
    lat: '22.857823',
    count: 0
  },
  {
    code: '450922',
    name: '陆川县',
    city_level: 3,
    lng: '110.264052',
    lat: '22.321048',
    count: 0
  },
  {
    code: '450923',
    name: '博白县',
    city_level: 3,
    lng: '109.975985',
    lat: '22.273048',
    count: 0
  },
  {
    code: '450924',
    name: '兴业县',
    city_level: 3,
    lng: '109.876855',
    lat: '22.820746',
    count: 0
  },
  {
    code: '450981',
    name: '北流市',
    city_level: 3,
    lng: '110.354215',
    lat: '22.708311',
    count: 0
  },
  {
    code: '451002',
    name: '右江区',
    city_level: 3,
    lng: '106.618645',
    lat: '23.901383',
    count: 0
  },
  {
    code: '451003',
    name: '田阳区',
    city_level: 3,
    lng: '106.916803',
    lat: '23.736475',
    count: 0
  },
  {
    code: '451022',
    name: '田东县',
    city_level: 3,
    lng: '107.126081',
    lat: '23.597194',
    count: 0
  },
  {
    code: '451024',
    name: '德保县',
    city_level: 3,
    lng: '106.615374',
    lat: '23.323450',
    count: 0
  },
  {
    code: '451026',
    name: '那坡县',
    city_level: 3,
    lng: '105.832530',
    lat: '23.387441',
    count: 0
  },
  {
    code: '451027',
    name: '凌云县',
    city_level: 3,
    lng: '106.561310',
    lat: '24.347557',
    count: 0
  },
  {
    code: '451028',
    name: '乐业县',
    city_level: 3,
    lng: '106.556519',
    lat: '24.776827',
    count: 0
  },
  {
    code: '451029',
    name: '田林县',
    city_level: 3,
    lng: '106.228538',
    lat: '24.294488',
    count: 0
  },
  {
    code: '451030',
    name: '西林县',
    city_level: 3,
    lng: '105.093837',
    lat: '24.489810',
    count: 0
  },
  {
    code: '451031',
    name: '隆林各族自治县',
    city_level: 3,
    lng: '105.344040',
    lat: '24.770896',
    count: 0
  },
  {
    code: '451081',
    name: '靖西市',
    city_level: 3,
    lng: '106.417549',
    lat: '23.134766',
    count: 0
  },
  {
    code: '451082',
    name: '平果市',
    city_level: 3,
    lng: '107.575202',
    lat: '23.326765',
    count: 0
  },
  {
    code: '451102',
    name: '八步区',
    city_level: 3,
    lng: '111.552077',
    lat: '24.411811',
    count: 0
  },
  {
    code: '451103',
    name: '平桂区',
    city_level: 2,
    lng: '111.566694',
    lat: '24.403582',
    count: 0
  },
  {
    code: '451121',
    name: '昭平县',
    city_level: 3,
    lng: '110.811287',
    lat: '24.169480',
    count: 0
  },
  {
    code: '451122',
    name: '钟山县',
    city_level: 3,
    lng: '111.303111',
    lat: '24.526022',
    count: 0
  },
  {
    code: '451123',
    name: '富川瑶族自治县',
    city_level: 3,
    lng: '111.277389',
    lat: '24.814444',
    count: 0
  },
  {
    code: '451202',
    name: '金城江区',
    city_level: 3,
    lng: '108.037277',
    lat: '24.689703',
    count: 0
  },
  {
    code: '451203',
    name: '宜州区',
    city_level: 3,
    lng: '108.653969',
    lat: '24.492193',
    count: 0
  },
  {
    code: '451221',
    name: '南丹县',
    city_level: 3,
    lng: '107.540722',
    lat: '24.974486',
    count: 0
  },
  {
    code: '451222',
    name: '天峨县',
    city_level: 3,
    lng: '107.173802',
    lat: '24.999108',
    count: 0
  },
  {
    code: '451223',
    name: '凤山县',
    city_level: 3,
    lng: '107.042191',
    lat: '24.546876',
    count: 0
  },
  {
    code: '451224',
    name: '东兰县',
    city_level: 3,
    lng: '107.374294',
    lat: '24.510842',
    count: 0
  },
  {
    code: '451225',
    name: '罗城仫佬族自治县',
    city_level: 3,
    lng: '108.904761',
    lat: '24.777421',
    count: 0
  },
  {
    code: '451226',
    name: '环江毛南族自治县',
    city_level: 3,
    lng: '108.258028',
    lat: '24.825664',
    count: 0
  },
  {
    code: '451227',
    name: '巴马瑶族自治县',
    city_level: 3,
    lng: '107.258588',
    lat: '24.142299',
    count: 0
  },
  {
    code: '451228',
    name: '都安瑶族自治县',
    city_level: 3,
    lng: '108.105312',
    lat: '23.932675',
    count: 0
  },
  {
    code: '451229',
    name: '大化瑶族自治县',
    city_level: 3,
    lng: '107.998150',
    lat: '23.736457',
    count: 0
  },
  {
    code: '451302',
    name: '兴宾区',
    city_level: 3,
    lng: '109.233247',
    lat: '23.736153',
    count: 0
  },
  {
    code: '451321',
    name: '忻城县',
    city_level: 3,
    lng: '108.665666',
    lat: '24.066235',
    count: 0
  },
  {
    code: '451322',
    name: '象州县',
    city_level: 3,
    lng: '109.683985',
    lat: '23.958528',
    count: 0
  },
  {
    code: '451323',
    name: '武宣县',
    city_level: 3,
    lng: '109.663207',
    lat: '23.594110',
    count: 0
  },
  {
    code: '451324',
    name: '金秀瑶族自治县',
    city_level: 3,
    lng: '110.189462',
    lat: '24.130374',
    count: 0
  },
  {
    code: '451381',
    name: '合山市',
    city_level: 3,
    lng: '108.886082',
    lat: '23.806536',
    count: 0
  },
  {
    code: '451402',
    name: '江州区',
    city_level: 3,
    lng: '107.353437',
    lat: '22.405325',
    count: 0
  },
  {
    code: '451421',
    name: '扶绥县',
    city_level: 3,
    lng: '107.904187',
    lat: '22.635013',
    count: 0
  },
  {
    code: '451422',
    name: '宁明县',
    city_level: 3,
    lng: '107.076457',
    lat: '22.140192',
    count: 0
  },
  {
    code: '451423',
    name: '龙州县',
    city_level: 3,
    lng: '106.854451',
    lat: '22.342797',
    count: 0
  },
  {
    code: '451424',
    name: '大新县',
    city_level: 3,
    lng: '107.200654',
    lat: '22.829288',
    count: 0
  },
  {
    code: '451425',
    name: '天等县',
    city_level: 3,
    lng: '107.143433',
    lat: '23.081394',
    count: 0
  },
  {
    code: '451481',
    name: '凭祥市',
    city_level: 3,
    lng: '106.766293',
    lat: '22.094485',
    count: 0
  },
  {
    code: '460105',
    name: '秀英区',
    city_level: 3,
    lng: '110.293561',
    lat: '20.007969',
    count: 0
  },
  {
    code: '460106',
    name: '龙华区',
    city_level: 3,
    lng: '110.328492',
    lat: '20.031007',
    count: 0
  },
  {
    code: '460107',
    name: '琼山区',
    city_level: 3,
    lng: '110.353972',
    lat: '20.003170',
    count: 0
  },
  {
    code: '460108',
    name: '美兰区',
    city_level: 3,
    lng: '110.366357',
    lat: '20.029083',
    count: 0
  },
  {
    code: '460202',
    name: '海棠区',
    city_level: 3,
    lng: '109.760778',
    lat: '18.407516',
    count: 0
  },
  {
    code: '460203',
    name: '吉阳区',
    city_level: 3,
    lng: '109.578330',
    lat: '18.281397',
    count: 0
  },
  {
    code: '460204',
    name: '天涯区',
    city_level: 3,
    lng: '109.506357',
    lat: '18.247340',
    count: 0
  },
  {
    code: '460205',
    name: '崖州区',
    city_level: 3,
    lng: '109.174313',
    lat: '18.352212',
    count: 0
  },
  {
    code: '469001',
    name: '五指山市',
    city_level: 3,
    lng: '109.516925',
    lat: '18.775146',
    count: 0
  },
  {
    code: '469002',
    name: '琼海市',
    city_level: 3,
    lng: '110.474581',
    lat: '19.25914',
    count: 0
  },
  {
    code: '469005',
    name: '文昌市',
    city_level: 3,
    lng: '110.797717',
    lat: '19.543422',
    count: 0
  },
  {
    code: '469006',
    name: '万宁市',
    city_level: 3,
    lng: '110.391073',
    lat: '18.795143',
    count: 0
  },
  {
    code: '469007',
    name: '东方市',
    city_level: 3,
    lng: '108.651815',
    lat: '19.095351',
    count: 0
  },
  {
    code: '469021',
    name: '定安县',
    city_level: 3,
    lng: '110.358891',
    lat: '19.681434',
    count: 0
  },
  {
    code: '469022',
    name: '屯昌县',
    city_level: 3,
    lng: '110.103415',
    lat: '19.351766',
    count: 0
  },
  {
    code: '469023',
    name: '澄迈县',
    city_level: 3,
    lng: '110.006755',
    lat: '19.738521',
    count: 0
  },
  {
    code: '469024',
    name: '临高县',
    city_level: 3,
    lng: '109.690508',
    lat: '19.912026',
    count: 0
  },
  {
    code: '469025',
    name: '白沙黎族自治县',
    city_level: 3,
    lng: '109.451484',
    lat: '19.224823',
    count: 0
  },
  {
    code: '469026',
    name: '昌江黎族自治县',
    city_level: 3,
    lng: '109.055724',
    lat: '19.298062',
    count: 0
  },
  {
    code: '469027',
    name: '乐东黎族自治县',
    city_level: 3,
    lng: '109.173055',
    lat: '18.750260',
    count: 0
  },
  {
    code: '469028',
    name: '陵水黎族自治县',
    city_level: 3,
    lng: '110.037504',
    lat: '18.506048',
    count: 0
  },
  {
    code: '469029',
    name: '保亭黎族苗族自治县',
    city_level: 3,
    lng: '109.702392',
    lat: '18.638189',
    count: 0
  },
  {
    code: '469030',
    name: '琼中黎族苗族自治县',
    city_level: 3,
    lng: '109.838389',
    lat: '19.033369',
    count: 0
  },
  {
    code: '500101',
    name: '万州区',
    city_level: 3,
    lng: '108.408661',
    lat: '30.807667',
    count: 0
  },
  {
    code: '500102',
    name: '涪陵区',
    city_level: 3,
    lng: '107.389298',
    lat: '29.703113',
    count: 0
  },
  {
    code: '500103',
    name: '渝中区',
    city_level: 3,
    lng: '106.568892',
    lat: '29.552750',
    count: 0
  },
  {
    code: '500104',
    name: '大渡口区',
    city_level: 3,
    lng: '106.482347',
    lat: '29.484527',
    count: 0
  },
  {
    code: '500105',
    name: '江北区',
    city_level: 3,
    lng: '106.574271',
    lat: '29.606703',
    count: 0
  },
  {
    code: '500106',
    name: '沙坪坝区',
    city_level: 3,
    lng: '106.456878',
    lat: '29.541145',
    count: 0
  },
  {
    code: '500107',
    name: '九龙坡区',
    city_level: 3,
    lng: '106.531920',
    lat: '29.514190',
    count: 0
  },
  {
    code: '500108',
    name: '南岸区',
    city_level: 3,
    lng: '106.644428',
    lat: '29.500297',
    count: 0
  },
  {
    code: '500109',
    name: '北碚区',
    city_level: 3,
    lng: '106.445122',
    lat: '29.822616',
    count: 0
  },
  {
    code: '500110',
    name: '綦江区',
    city_level: 3,
    lng: '106.651362',
    lat: '29.028067',
    count: 0
  },
  {
    code: '500111',
    name: '大足区',
    city_level: 3,
    lng: '105.721733',
    lat: '29.707032',
    count: 0
  },
  {
    code: '500112',
    name: '渝北区',
    city_level: 3,
    lng: '106.631187',
    lat: '29.718143',
    count: 0
  },
  {
    code: '500113',
    name: '巴南区',
    city_level: 3,
    lng: '106.533840',
    lat: '29.375528',
    count: 0
  },
  {
    code: '500114',
    name: '黔江区',
    city_level: 3,
    lng: '108.770678',
    lat: '29.533610',
    count: 0
  },
  {
    code: '500115',
    name: '长寿区',
    city_level: 3,
    lng: '107.081283',
    lat: '29.857996',
    count: 0
  },
  {
    code: '500116',
    name: '江津区',
    city_level: 3,
    lng: '106.259281',
    lat: '29.290069',
    count: 0
  },
  {
    code: '500117',
    name: '合川区',
    city_level: 3,
    lng: '106.276130',
    lat: '29.972084',
    count: 0
  },
  {
    code: '500118',
    name: '永川区',
    city_level: 3,
    lng: '105.927376',
    lat: '29.356117',
    count: 0
  },
  {
    code: '500119',
    name: '南川区',
    city_level: 3,
    lng: '107.099266',
    lat: '29.157891',
    count: 0
  },
  {
    code: '500120',
    name: '璧山区',
    city_level: 3,
    lng: '106.227305',
    lat: '29.592024',
    count: 0
  },
  {
    code: '500151',
    name: '铜梁区',
    city_level: 3,
    lng: '106.056404',
    lat: '29.844811',
    count: 0
  },
  {
    code: '500152',
    name: '潼南区',
    city_level: 3,
    lng: '105.840556',
    lat: '30.191013',
    count: 0
  },
  {
    code: '500153',
    name: '荣昌区',
    city_level: 3,
    lng: '105.594623',
    lat: '29.405002',
    count: 0
  },
  {
    code: '500154',
    name: '开州区',
    city_level: 3,
    lng: '108.393135',
    lat: '31.160711',
    count: 0
  },
  {
    code: '500155',
    name: '梁平区',
    city_level: 3,
    lng: '107.800034',
    lat: '30.672167',
    count: 0
  },
  {
    code: '500156',
    name: '武隆区',
    city_level: 3,
    lng: '107.756546',
    lat: '29.323761',
    count: 0
  },
  {
    code: '500229',
    name: '城口县',
    city_level: 3,
    lng: '108.664214',
    lat: '31.947633',
    count: 0
  },
  {
    code: '500230',
    name: '丰都县',
    city_level: 3,
    lng: '107.730895',
    lat: '29.863500',
    count: 0
  },
  {
    code: '500231',
    name: '垫江县',
    city_level: 3,
    lng: '107.333390',
    lat: '30.327717',
    count: 0
  },
  {
    code: '500233',
    name: '忠县',
    city_level: 3,
    lng: '108.039002',
    lat: '30.299560',
    count: 0
  },
  {
    code: '500235',
    name: '云阳县',
    city_level: 3,
    lng: '108.697324',
    lat: '30.930613',
    count: 0
  },
  {
    code: '500236',
    name: '奉节县',
    city_level: 3,
    lng: '109.463987',
    lat: '31.018498',
    count: 0
  },
  {
    code: '500237',
    name: '巫山县',
    city_level: 3,
    lng: '109.879153',
    lat: '31.074834',
    count: 0
  },
  {
    code: '500238',
    name: '巫溪县',
    city_level: 3,
    lng: '109.570062',
    lat: '31.398604',
    count: 0
  },
  {
    code: '500240',
    name: '石柱土家族自治县',
    city_level: 3,
    lng: '108.114069',
    lat: '29.999285',
    count: 0
  },
  {
    code: '500241',
    name: '秀山土家族苗族自治县',
    city_level: 3,
    lng: '109.007094',
    lat: '28.447997',
    count: 0
  },
  {
    code: '500242',
    name: '酉阳土家族苗族自治县',
    city_level: 3,
    lng: '108.767747',
    lat: '28.841244',
    count: 0
  },
  {
    code: '500243',
    name: '彭水苗族土家族自治县',
    city_level: 3,
    lng: '108.165538',
    lat: '29.293902',
    count: 0
  },
  {
    code: '510104',
    name: '锦江区',
    city_level: 3,
    lng: '104.080989',
    lat: '30.657689',
    count: 0
  },
  {
    code: '510105',
    name: '青羊区',
    city_level: 3,
    lng: '104.062499',
    lat: '30.674406',
    count: 0
  },
  {
    code: '510106',
    name: '金牛区',
    city_level: 3,
    lng: '104.117422',
    lat: '30.763102',
    count: 0
  },
  {
    code: '510107',
    name: '武侯区',
    city_level: 3,
    lng: '104.043390',
    lat: '30.641982',
    count: 0
  },
  {
    code: '510108',
    name: '成华区',
    city_level: 3,
    lng: '104.101255',
    lat: '30.660122',
    count: 0
  },
  {
    code: '510112',
    name: '龙泉驿区',
    city_level: 3,
    lng: '104.274632',
    lat: '30.556507',
    count: 0
  },
  {
    code: '510113',
    name: '青白江区',
    city_level: 3,
    lng: '104.250877',
    lat: '30.878681',
    count: 0
  },
  {
    code: '510114',
    name: '新都区',
    city_level: 3,
    lng: '104.158705',
    lat: '30.823499',
    count: 0
  },
  {
    code: '510115',
    name: '温江区',
    city_level: 3,
    lng: '103.837104',
    lat: '30.690460',
    count: 0
  },
  {
    code: '510116',
    name: '双流区',
    city_level: 3,
    lng: '103.951908',
    lat: '30.333027',
    count: 0
  },
  {
    code: '510117',
    name: '郫都区',
    city_level: 3,
    lng: '103.901092',
    lat: '30.795854',
    count: 0
  },
  {
    code: '510118',
    name: '新津区',
    city_level: 3,
    lng: '103.812447',
    lat: '30.414284',
    count: 0
  },
  {
    code: '510121',
    name: '金堂县',
    city_level: 3,
    lng: '104.412005',
    lat: '30.862017',
    count: 0
  },
  {
    code: '510129',
    name: '大邑县',
    city_level: 3,
    lng: '103.511875',
    lat: '30.572269',
    count: 0
  },
  {
    code: '510131',
    name: '蒲江县',
    city_level: 3,
    lng: '103.506498',
    lat: '30.196789',
    count: 0
  },
  {
    code: '510181',
    name: '都江堰市',
    city_level: 3,
    lng: '103.650219',
    lat: '30.975943',
    count: 0
  },
  {
    code: '510182',
    name: '彭州市',
    city_level: 3,
    lng: '103.958013',
    lat: '30.990165',
    count: 0
  },
  {
    code: '510183',
    name: '邛崃市',
    city_level: 3,
    lng: '103.464156',
    lat: '30.410275',
    count: 0
  },
  {
    code: '510184',
    name: '崇州市',
    city_level: 3,
    lng: '103.673001',
    lat: '30.630122',
    count: 0
  },
  {
    code: '510185',
    name: '简阳市',
    city_level: 3,
    lng: '104.546773',
    lat: '30.410754',
    count: 0
  },
  {
    code: '510302',
    name: '自流井区',
    city_level: 3,
    lng: '104.777191',
    lat: '29.337430',
    count: 0
  },
  {
    code: '510303',
    name: '贡井区',
    city_level: 3,
    lng: '104.715117',
    lat: '29.345546',
    count: 0
  },
  {
    code: '510304',
    name: '大安区',
    city_level: 3,
    lng: '104.773968',
    lat: '29.363634',
    count: 0
  },
  {
    code: '510311',
    name: '沿滩区',
    city_level: 3,
    lng: '104.874073',
    lat: '29.272581',
    count: 0
  },
  {
    code: '510321',
    name: '荣县',
    city_level: 3,
    lng: '104.417388',
    lat: '29.445410',
    count: 0
  },
  {
    code: '510322',
    name: '富顺县',
    city_level: 3,
    lng: '104.975048',
    lat: '29.181430',
    count: 0
  },
  {
    code: '510402',
    name: '东区',
    city_level: 2,
    lng: '101.718637',
    lat: '26.582347',
    count: 0
  },
  {
    code: '510403',
    name: '西区',
    city_level: 2,
    lng: '101.718637',
    lat: '26.582347',
    count: 0
  },
  {
    code: '510411',
    name: '仁和区',
    city_level: 3,
    lng: '101.738528',
    lat: '26.497765',
    count: 0
  },
  {
    code: '510421',
    name: '米易县',
    city_level: 3,
    lng: '102.110339',
    lat: '26.890689',
    count: 0
  },
  {
    code: '510422',
    name: '盐边县',
    city_level: 3,
    lng: '101.855071',
    lat: '26.683213',
    count: 0
  },
  {
    code: '510502',
    name: '江阳区',
    city_level: 3,
    lng: '105.435009',
    lat: '28.878818',
    count: 0
  },
  {
    code: '510503',
    name: '纳溪区',
    city_level: 3,
    lng: '105.371151',
    lat: '28.773428',
    count: 0
  },
  {
    code: '510504',
    name: '龙马潭区',
    city_level: 3,
    lng: '105.437765',
    lat: '28.913221',
    count: 0
  },
  {
    code: '510521',
    name: '泸县',
    city_level: 3,
    lng: '105.381893',
    lat: '29.151534',
    count: 0
  },
  {
    code: '510522',
    name: '合江县',
    city_level: 3,
    lng: '105.831067',
    lat: '28.811203',
    count: 0
  },
  {
    code: '510524',
    name: '叙永县',
    city_level: 3,
    lng: '105.444765',
    lat: '28.155801',
    count: 0
  },
  {
    code: '510525',
    name: '古蔺县',
    city_level: 3,
    lng: '105.812602',
    lat: '28.038802',
    count: 0
  },
  {
    code: '510603',
    name: '旌阳区',
    city_level: 3,
    lng: '104.416943',
    lat: '31.142498',
    count: 0
  },
  {
    code: '510604',
    name: '罗江区',
    city_level: 3,
    lng: '104.510249',
    lat: '31.317045',
    count: 0
  },
  {
    code: '510623',
    name: '中江县',
    city_level: 3,
    lng: '104.678749',
    lat: '31.033051',
    count: 0
  },
  {
    code: '510681',
    name: '广汉市',
    city_level: 3,
    lng: '104.282331',
    lat: '30.976165',
    count: 0
  },
  {
    code: '510682',
    name: '什邡市',
    city_level: 3,
    lng: '104.167501',
    lat: '31.126780',
    count: 0
  },
  {
    code: '510683',
    name: '绵竹市',
    city_level: 3,
    lng: '104.220750',
    lat: '31.338077',
    count: 0
  },
  {
    code: '510703',
    name: '涪城区',
    city_level: 3,
    lng: '104.750215',
    lat: '31.470830',
    count: 0
  },
  {
    code: '510704',
    name: '游仙区',
    city_level: 3,
    lng: '104.766393',
    lat: '31.473779',
    count: 0
  },
  {
    code: '510705',
    name: '安州区',
    city_level: 3,
    lng: '104.567187',
    lat: '31.534886',
    count: 0
  },
  {
    code: '510722',
    name: '三台县',
    city_level: 3,
    lng: '105.094586',
    lat: '31.095979',
    count: 0
  },
  {
    code: '510723',
    name: '盐亭县',
    city_level: 3,
    lng: '105.389453',
    lat: '31.208363',
    count: 0
  },
  {
    code: '510725',
    name: '梓潼县',
    city_level: 3,
    lng: '105.170845',
    lat: '31.642718',
    count: 0
  },
  {
    code: '510726',
    name: '北川羌族自治县',
    city_level: 3,
    lng: '104.467970',
    lat: '31.617203',
    count: 0
  },
  {
    code: '510727',
    name: '平武县',
    city_level: 3,
    lng: '104.555583',
    lat: '32.409675',
    count: 0
  },
  {
    code: '510781',
    name: '江油市',
    city_level: 3,
    lng: '104.745877',
    lat: '31.778025',
    count: 0
  },
  {
    code: '510802',
    name: '利州区',
    city_level: 3,
    lng: '105.845218',
    lat: '32.433898',
    count: 0
  },
  {
    code: '510811',
    name: '昭化区',
    city_level: 3,
    lng: '105.964121',
    lat: '32.322788',
    count: 0
  },
  {
    code: '510812',
    name: '朝天区',
    city_level: 3,
    lng: '105.890445',
    lat: '32.643982',
    count: 0
  },
  {
    code: '510821',
    name: '旺苍县',
    city_level: 3,
    lng: '106.289905',
    lat: '32.228917',
    count: 0
  },
  {
    code: '510822',
    name: '青川县',
    city_level: 3,
    lng: '105.238842',
    lat: '32.575485',
    count: 0
  },
  {
    code: '510823',
    name: '剑阁县',
    city_level: 3,
    lng: '105.524766',
    lat: '32.287723',
    count: 0
  },
  {
    code: '510824',
    name: '苍溪县',
    city_level: 3,
    lng: '105.934801',
    lat: '31.732432',
    count: 0
  },
  {
    code: '510903',
    name: '船山区',
    city_level: 3,
    lng: '105.568297',
    lat: '30.525475',
    count: 0
  },
  {
    code: '510904',
    name: '安居区',
    city_level: 3,
    lng: '105.456342',
    lat: '30.355379',
    count: 0
  },
  {
    code: '510921',
    name: '蓬溪县',
    city_level: 3,
    lng: '105.707570',
    lat: '30.757575',
    count: 0
  },
  {
    code: '510923',
    name: '大英县',
    city_level: 3,
    lng: '105.236904',
    lat: '30.594337',
    count: 0
  },
  {
    code: '510981',
    name: '射洪市',
    city_level: 3,
    lng: '105.376018',
    lat: '30.867054',
    count: 0
  },
  {
    code: '511002',
    name: '市中区',
    city_level: 3,
    lng: '103.761330',
    lat: '29.555375',
    count: 0
  },
  {
    code: '511011',
    name: '东兴区',
    city_level: 3,
    lng: '105.075490',
    lat: '29.592756',
    count: 0
  },
  {
    code: '511024',
    name: '威远县',
    city_level: 3,
    lng: '104.668879',
    lat: '29.527440',
    count: 0
  },
  {
    code: '511025',
    name: '资中县',
    city_level: 3,
    lng: '104.851944',
    lat: '29.764059',
    count: 0
  },
  {
    code: '511083',
    name: '隆昌市',
    city_level: 3,
    lng: '105.288071',
    lat: '29.338161',
    count: 0
  },
  {
    code: '511102',
    name: '市中区',
    city_level: 3,
    lng: '103.761330',
    lat: '29.555375',
    count: 0
  },
  {
    code: '511111',
    name: '沙湾区',
    city_level: 3,
    lng: '103.549991',
    lat: '29.413091',
    count: 0
  },
  {
    code: '511112',
    name: '五通桥区',
    city_level: 3,
    lng: '103.818009',
    lat: '29.406932',
    count: 0
  },
  {
    code: '511113',
    name: '金口河区',
    city_level: 3,
    lng: '103.078621',
    lat: '29.244345',
    count: 0
  },
  {
    code: '511123',
    name: '犍为县',
    city_level: 3,
    lng: '103.949326',
    lat: '29.208171',
    count: 0
  },
  {
    code: '511124',
    name: '井研县',
    city_level: 3,
    lng: '104.069726',
    lat: '29.651287',
    count: 0
  },
  {
    code: '511126',
    name: '夹江县',
    city_level: 3,
    lng: '103.571657',
    lat: '29.737630',
    count: 0
  },
  {
    code: '511129',
    name: '沐川县',
    city_level: 3,
    lng: '103.902335',
    lat: '28.956647',
    count: 0
  },
  {
    code: '511132',
    name: '峨边彝族自治县',
    city_level: 3,
    lng: '103.262048',
    lat: '29.230425',
    count: 0
  },
  {
    code: '511133',
    name: '马边彝族自治县',
    city_level: 3,
    lng: '103.546348',
    lat: '28.835521',
    count: 0
  },
  {
    code: '511181',
    name: '峨眉山市',
    city_level: 3,
    lng: '103.484504',
    lat: '29.601199',
    count: 0
  },
  {
    code: '511302',
    name: '顺庆区',
    city_level: 3,
    lng: '106.092399',
    lat: '30.796486',
    count: 0
  },
  {
    code: '511303',
    name: '高坪区',
    city_level: 3,
    lng: '106.118808',
    lat: '30.781623',
    count: 0
  },
  {
    code: '511304',
    name: '嘉陵区',
    city_level: 3,
    lng: '106.071793',
    lat: '30.758748',
    count: 0
  },
  {
    code: '511321',
    name: '南部县',
    city_level: 3,
    lng: '106.036584',
    lat: '31.347467',
    count: 0
  },
  {
    code: '511322',
    name: '营山县',
    city_level: 3,
    lng: '106.565420',
    lat: '31.076461',
    count: 0
  },
  {
    code: '511323',
    name: '蓬安县',
    city_level: 3,
    lng: '106.412151',
    lat: '31.029097',
    count: 0
  },
  {
    code: '511324',
    name: '仪陇县',
    city_level: 3,
    lng: '106.303042',
    lat: '31.271562',
    count: 0
  },
  {
    code: '511325',
    name: '西充县',
    city_level: 3,
    lng: '105.900878',
    lat: '30.995669',
    count: 0
  },
  {
    code: '511381',
    name: '阆中市',
    city_level: 3,
    lng: '106.005047',
    lat: '31.558357',
    count: 0
  },
  {
    code: '511402',
    name: '东坡区',
    city_level: 3,
    lng: '103.831752',
    lat: '30.042345',
    count: 0
  },
  {
    code: '511403',
    name: '彭山区',
    city_level: 3,
    lng: '103.872950',
    lat: '30.193056',
    count: 0
  },
  {
    code: '511421',
    name: '仁寿县',
    city_level: 3,
    lng: '104.134082',
    lat: '29.995630',
    count: 0
  },
  {
    code: '511423',
    name: '洪雅县',
    city_level: 3,
    lng: '103.372863',
    lat: '29.904890',
    count: 0
  },
  {
    code: '511424',
    name: '丹棱县',
    city_level: 3,
    lng: '103.512733',
    lat: '30.014448',
    count: 0
  },
  {
    code: '511425',
    name: '青神县',
    city_level: 3,
    lng: '103.846688',
    lat: '29.831358',
    count: 0
  },
  {
    code: '511502',
    name: '翠屏区',
    city_level: 3,
    lng: '104.620009',
    lat: '28.765690',
    count: 0
  },
  {
    code: '511503',
    name: '南溪区',
    city_level: 2,
    lng: '104.643215',
    lat: '28.751768',
    count: 0
  },
  {
    code: '511504',
    name: '叙州区',
    city_level: 2,
    lng: '104.643215',
    lat: '28.751768',
    count: 0
  },
  {
    code: '511523',
    name: '江安县',
    city_level: 2,
    lng: '104.643215',
    lat: '28.751768',
    count: 0
  },
  {
    code: '511524',
    name: '长宁县',
    city_level: 3,
    lng: '104.921174',
    lat: '28.582169',
    count: 0
  },
  {
    code: '511525',
    name: '高县',
    city_level: 3,
    lng: '104.517739',
    lat: '28.436226',
    count: 0
  },
  {
    code: '511526',
    name: '珙县',
    city_level: 3,
    lng: '104.709202',
    lat: '28.438630',
    count: 0
  },
  {
    code: '511527',
    name: '筠连县',
    city_level: 2,
    lng: '104.643215',
    lat: '28.751768',
    count: 0
  },
  {
    code: '511528',
    name: '兴文县',
    city_level: 3,
    lng: '105.236549',
    lat: '28.302988',
    count: 0
  },
  {
    code: '511529',
    name: '屏山县',
    city_level: 3,
    lng: '104.345974',
    lat: '28.828482',
    count: 0
  },
  {
    code: '511602',
    name: '广安区',
    city_level: 3,
    lng: '106.641608',
    lat: '30.474003',
    count: 0
  },
  {
    code: '511603',
    name: '前锋区',
    city_level: 3,
    lng: '106.893277',
    lat: '30.496300',
    count: 0
  },
  {
    code: '511621',
    name: '岳池县',
    city_level: 3,
    lng: '106.440114',
    lat: '30.537863',
    count: 0
  },
  {
    code: '511622',
    name: '武胜县',
    city_level: 3,
    lng: '106.295764',
    lat: '30.348772',
    count: 0
  },
  {
    code: '511623',
    name: '邻水县',
    city_level: 3,
    lng: '106.930380',
    lat: '30.334769',
    count: 0
  },
  {
    code: '511681',
    name: '华蓥市',
    city_level: 3,
    lng: '106.783088',
    lat: '30.390435',
    count: 0
  },
  {
    code: '511702',
    name: '通川区',
    city_level: 3,
    lng: '107.504517',
    lat: '31.214724',
    count: 0
  },
  {
    code: '511703',
    name: '达川区',
    city_level: 3,
    lng: '107.511845',
    lat: '31.196118',
    count: 0
  },
  {
    code: '511722',
    name: '宣汉县',
    city_level: 3,
    lng: '107.727191',
    lat: '31.353835',
    count: 0
  },
  {
    code: '511723',
    name: '开江县',
    city_level: 3,
    lng: '107.868736',
    lat: '31.082987',
    count: 0
  },
  {
    code: '511724',
    name: '大竹县',
    city_level: 3,
    lng: '107.204744',
    lat: '30.736266',
    count: 0
  },
  {
    code: '511725',
    name: '渠县',
    city_level: 3,
    lng: '106.972996',
    lat: '30.836659',
    count: 0
  },
  {
    code: '511781',
    name: '万源市',
    city_level: 3,
    lng: '108.034657',
    lat: '32.081631',
    count: 0
  },
  {
    code: '511802',
    name: '雨城区',
    city_level: 3,
    lng: '103.033083',
    lat: '30.005447',
    count: 0
  },
  {
    code: '511803',
    name: '名山区',
    city_level: 3,
    lng: '103.109185',
    lat: '30.069954',
    count: 0
  },
  {
    code: '511822',
    name: '荥经县',
    city_level: 3,
    lng: '102.846738',
    lat: '29.792931',
    count: 0
  },
  {
    code: '511823',
    name: '汉源县',
    city_level: 3,
    lng: '102.645453',
    lat: '29.347187',
    count: 0
  },
  {
    code: '511824',
    name: '石棉县',
    city_level: 3,
    lng: '102.359462',
    lat: '29.227874',
    count: 0
  },
  {
    code: '511825',
    name: '天全县',
    city_level: 3,
    lng: '102.758317',
    lat: '30.066713',
    count: 0
  },
  {
    code: '511826',
    name: '芦山县',
    city_level: 3,
    lng: '102.928260',
    lat: '30.144084',
    count: 0
  },
  {
    code: '511827',
    name: '宝兴县',
    city_level: 3,
    lng: '102.814531',
    lat: '30.368126',
    count: 0
  },
  {
    code: '511902',
    name: '巴州区',
    city_level: 3,
    lng: '106.768878',
    lat: '31.851478',
    count: 0
  },
  {
    code: '511903',
    name: '恩阳区',
    city_level: 3,
    lng: '106.655347',
    lat: '31.786691',
    count: 0
  },
  {
    code: '511921',
    name: '通江县',
    city_level: 3,
    lng: '107.245033',
    lat: '31.911705',
    count: 0
  },
  {
    code: '511922',
    name: '南江县',
    city_level: 3,
    lng: '106.828697',
    lat: '32.346589',
    count: 0
  },
  {
    code: '511923',
    name: '平昌县',
    city_level: 3,
    lng: '107.104008',
    lat: '31.560874',
    count: 0
  },
  {
    code: '512002',
    name: '雁江区',
    city_level: 3,
    lng: '104.677096',
    lat: '30.108210',
    count: 0
  },
  {
    code: '512021',
    name: '安岳县',
    city_level: 3,
    lng: '105.335613',
    lat: '30.097246',
    count: 0
  },
  {
    code: '512022',
    name: '乐至县',
    city_level: 3,
    lng: '105.019916',
    lat: '30.275487',
    count: 0
  },
  {
    code: '513201',
    name: '马尔康市',
    city_level: 3,
    lng: '102.206504',
    lat: '31.905813',
    count: 0
  },
  {
    code: '513221',
    name: '汶川县',
    city_level: 3,
    lng: '103.590387',
    lat: '31.476822',
    count: 0
  },
  {
    code: '513222',
    name: '理县',
    city_level: 3,
    lng: '103.166853',
    lat: '31.436473',
    count: 0
  },
  {
    code: '513223',
    name: '茂县',
    city_level: 3,
    lng: '103.853522',
    lat: '31.681154',
    count: 0
  },
  {
    code: '513224',
    name: '松潘县',
    city_level: 3,
    lng: '103.604698',
    lat: '32.655325',
    count: 0
  },
  {
    code: '513225',
    name: '九寨沟县',
    city_level: 3,
    lng: '104.243841',
    lat: '33.252056',
    count: 0
  },
  {
    code: '513226',
    name: '金川县',
    city_level: 3,
    lng: '102.063829',
    lat: '31.476277',
    count: 0
  },
  {
    code: '513227',
    name: '小金县',
    city_level: 3,
    lng: '102.364373',
    lat: '30.999031',
    count: 0
  },
  {
    code: '513228',
    name: '黑水县',
    city_level: 3,
    lng: '102.990108',
    lat: '32.061895',
    count: 0
  },
  {
    code: '513230',
    name: '壤塘县',
    city_level: 3,
    lng: '100.978526',
    lat: '32.265796',
    count: 0
  },
  {
    code: '513231',
    name: '阿坝县',
    city_level: 3,
    lng: '101.706655',
    lat: '32.902459',
    count: 0
  },
  {
    code: '513232',
    name: '若尔盖县',
    city_level: 3,
    lng: '102.961798',
    lat: '33.575892',
    count: 0
  },
  {
    code: '513233',
    name: '红原县',
    city_level: 3,
    lng: '102.544405',
    lat: '32.790891',
    count: 0
  },
  {
    code: '513301',
    name: '康定市',
    city_level: 3,
    lng: '101.957146',
    lat: '29.998436',
    count: 0
  },
  {
    code: '513322',
    name: '泸定县',
    city_level: 3,
    lng: '102.234618',
    lat: '29.914160',
    count: 0
  },
  {
    code: '513323',
    name: '丹巴县',
    city_level: 3,
    lng: '101.890358',
    lat: '30.878577',
    count: 0
  },
  {
    code: '513324',
    name: '九龙县',
    city_level: 3,
    lng: '101.507294',
    lat: '29.000348',
    count: 0
  },
  {
    code: '513325',
    name: '雅江县',
    city_level: 3,
    lng: '101.014425',
    lat: '30.031533',
    count: 0
  },
  {
    code: '513326',
    name: '道孚县',
    city_level: 3,
    lng: '101.125237',
    lat: '30.979545',
    count: 0
  },
  {
    code: '513327',
    name: '炉霍县',
    city_level: 3,
    lng: '100.676372',
    lat: '31.391790',
    count: 0
  },
  {
    code: '513328',
    name: '甘孜县',
    city_level: 3,
    lng: '99.992671',
    lat: '31.622934',
    count: 0
  },
  {
    code: '513329',
    name: '新龙县',
    city_level: 3,
    lng: '100.311369',
    lat: '30.939169',
    count: 0
  },
  {
    code: '513330',
    name: '德格县',
    city_level: 3,
    lng: '98.580915',
    lat: '31.806118',
    count: 0
  },
  {
    code: '513331',
    name: '白玉县',
    city_level: 3,
    lng: '98.824182',
    lat: '31.209913',
    count: 0
  },
  {
    code: '513332',
    name: '石渠县',
    city_level: 3,
    lng: '98.102900',
    lat: '32.978960',
    count: 0
  },
  {
    code: '513333',
    name: '色达县',
    city_level: 3,
    lng: '100.332743',
    lat: '32.268129',
    count: 0
  },
  {
    code: '513334',
    name: '理塘县',
    city_level: 3,
    lng: '100.269818',
    lat: '29.996049',
    count: 0
  },
  {
    code: '513335',
    name: '巴塘县',
    city_level: 3,
    lng: '99.110712',
    lat: '30.004677',
    count: 0
  },
  {
    code: '513336',
    name: '乡城县',
    city_level: 3,
    lng: '99.798435',
    lat: '28.931172',
    count: 0
  },
  {
    code: '513337',
    name: '稻城县',
    city_level: 3,
    lng: '100.298403',
    lat: '29.037007',
    count: 0
  },
  {
    code: '513338',
    name: '得荣县',
    city_level: 3,
    lng: '99.286335',
    lat: '28.713037',
    count: 0
  },
  {
    code: '513401',
    name: '西昌市',
    city_level: 3,
    lng: '102.264449',
    lat: '27.894504',
    count: 0
  },
  {
    code: '513422',
    name: '木里藏族自治县',
    city_level: 3,
    lng: '101.280206',
    lat: '27.928835',
    count: 0
  },
  {
    code: '513423',
    name: '盐源县',
    city_level: 3,
    lng: '101.509188',
    lat: '27.422645',
    count: 0
  },
  {
    code: '513424',
    name: '德昌县',
    city_level: 3,
    lng: '102.175670',
    lat: '27.402839',
    count: 0
  },
  {
    code: '513425',
    name: '会理县',
    city_level: 3,
    lng: '102.244683',
    lat: '26.655026',
    count: 0
  },
  {
    code: '513426',
    name: '会东县',
    city_level: 3,
    lng: '102.577961',
    lat: '26.634669',
    count: 0
  },
  {
    code: '513427',
    name: '宁南县',
    city_level: 3,
    lng: '102.759634',
    lat: '27.066384',
    count: 0
  },
  {
    code: '513428',
    name: '普格县',
    city_level: 3,
    lng: '102.540901',
    lat: '27.376413',
    count: 0
  },
  {
    code: '513429',
    name: '布拖县',
    city_level: 3,
    lng: '102.811631',
    lat: '27.706192',
    count: 0
  },
  {
    code: '513430',
    name: '金阳县',
    city_level: 3,
    lng: '103.248772',
    lat: '27.696861',
    count: 0
  },
  {
    code: '513431',
    name: '昭觉县',
    city_level: 3,
    lng: '102.842611',
    lat: '28.014088',
    count: 0
  },
  {
    code: '513432',
    name: '喜德县',
    city_level: 3,
    lng: '102.412518',
    lat: '28.306726',
    count: 0
  },
  {
    code: '513433',
    name: '冕宁县',
    city_level: 3,
    lng: '102.177010',
    lat: '28.549657',
    count: 0
  },
  {
    code: '513434',
    name: '越西县',
    city_level: 3,
    lng: '102.507680',
    lat: '28.639801',
    count: 0
  },
  {
    code: '513435',
    name: '甘洛县',
    city_level: 3,
    lng: '102.771749',
    lat: '28.966069',
    count: 0
  },
  {
    code: '513436',
    name: '美姑县',
    city_level: 3,
    lng: '103.132180',
    lat: '28.328640',
    count: 0
  },
  {
    code: '513437',
    name: '雷波县',
    city_level: 3,
    lng: '103.571696',
    lat: '28.262683',
    count: 0
  },
  {
    code: '520102',
    name: '南明区',
    city_level: 3,
    lng: '106.688760',
    lat: '26.570062',
    count: 0
  },
  {
    code: '520103',
    name: '云岩区',
    city_level: 3,
    lng: '106.724426',
    lat: '26.604594',
    count: 0
  },
  {
    code: '520111',
    name: '花溪区',
    city_level: 3,
    lng: '106.670260',
    lat: '26.409818',
    count: 0
  },
  {
    code: '520112',
    name: '乌当区',
    city_level: 3,
    lng: '106.750625',
    lat: '26.630845',
    count: 0
  },
  {
    code: '520113',
    name: '白云区',
    city_level: 3,
    lng: '106.623007',
    lat: '26.678562',
    count: 0
  },
  {
    code: '520115',
    name: '观山湖区',
    city_level: 3,
    lng: '106.622453',
    lat: '26.601450',
    count: 0
  },
  {
    code: '520121',
    name: '开阳县',
    city_level: 3,
    lng: '106.965090',
    lat: '27.057764',
    count: 0
  },
  {
    code: '520122',
    name: '息烽县',
    city_level: 3,
    lng: '106.740408',
    lat: '27.090479',
    count: 0
  },
  {
    code: '520123',
    name: '修文县',
    city_level: 3,
    lng: '106.592108',
    lat: '26.838926',
    count: 0
  },
  {
    code: '520181',
    name: '清镇市',
    city_level: 3,
    lng: '106.470715',
    lat: '26.556079',
    count: 0
  },
  {
    code: '520201',
    name: '钟山区',
    city_level: 3,
    lng: '104.843555',
    lat: '26.574979',
    count: 0
  },
  {
    code: '520203',
    name: '六枝特区',
    city_level: 3,
    lng: '105.480029',
    lat: '26.201228',
    count: 0
  },
  {
    code: '520204',
    name: '水城区',
    city_level: 3,
    lng: '105.348750',
    lat: '26.080788',
    count: 0
  },
  {
    code: '520281',
    name: '盘州市',
    city_level: 2,
    lng: '104.830359',
    lat: '26.592666',
    count: 0
  },
  {
    code: '520302',
    name: '红花岗区',
    city_level: 3,
    lng: '106.893709',
    lat: '27.644755',
    count: 0
  },
  {
    code: '520303',
    name: '汇川区',
    city_level: 3,
    lng: '106.934270',
    lat: '27.750125',
    count: 0
  },
  {
    code: '520304',
    name: '播州区',
    city_level: 2,
    lng: '106.927389',
    lat: '27.725654',
    count: 0
  },
  {
    code: '520322',
    name: '桐梓县',
    city_level: 3,
    lng: '106.825644',
    lat: '28.133583',
    count: 0
  },
  {
    code: '520323',
    name: '绥阳县',
    city_level: 3,
    lng: '107.191222',
    lat: '27.946222',
    count: 0
  },
  {
    code: '520324',
    name: '正安县',
    city_level: 3,
    lng: '107.453945',
    lat: '28.553285',
    count: 0
  },
  {
    code: '520325',
    name: '道真仡佬族苗族自治县',
    city_level: 3,
    lng: '107.613133',
    lat: '28.862425',
    count: 0
  },
  {
    code: '520326',
    name: '务川仡佬族苗族自治县',
    city_level: 3,
    lng: '107.898957',
    lat: '28.563086',
    count: 0
  },
  {
    code: '520327',
    name: '凤冈县',
    city_level: 3,
    lng: '107.716356',
    lat: '27.954695',
    count: 0
  },
  {
    code: '520328',
    name: '湄潭县',
    city_level: 3,
    lng: '107.465407',
    lat: '27.749055',
    count: 0
  },
  {
    code: '520329',
    name: '余庆县',
    city_level: 3,
    lng: '107.905278',
    lat: '27.215420',
    count: 0
  },
  {
    code: '520330',
    name: '习水县',
    city_level: 3,
    lng: '106.197138',
    lat: '28.331270',
    count: 0
  },
  {
    code: '520381',
    name: '赤水市',
    city_level: 3,
    lng: '105.697472',
    lat: '28.590337',
    count: 0
  },
  {
    code: '520382',
    name: '仁怀市',
    city_level: 3,
    lng: '106.400342',
    lat: '27.791650',
    count: 0
  },
  {
    code: '520402',
    name: '西秀区',
    city_level: 3,
    lng: '105.965535',
    lat: '26.245433',
    count: 0
  },
  {
    code: '520403',
    name: '平坝区',
    city_level: 3,
    lng: '106.255557',
    lat: '26.405502',
    count: 0
  },
  {
    code: '520422',
    name: '普定县',
    city_level: 3,
    lng: '105.743196',
    lat: '26.301446',
    count: 0
  },
  {
    code: '520423',
    name: '镇宁布依族苗族自治县',
    city_level: 3,
    lng: '105.770402',
    lat: '26.057362',
    count: 0
  },
  {
    code: '520424',
    name: '关岭布依族苗族自治县',
    city_level: 3,
    lng: '105.618745',
    lat: '25.943856',
    count: 0
  },
  {
    code: '520425',
    name: '紫云苗族布依族自治县',
    city_level: 3,
    lng: '106.084340',
    lat: '25.751052',
    count: 0
  },
  {
    code: '520502',
    name: '七星关区',
    city_level: 3,
    lng: '105.305138',
    lat: '27.298494',
    count: 0
  },
  {
    code: '520521',
    name: '大方县',
    city_level: 3,
    lng: '105.611656',
    lat: '27.137097',
    count: 0
  },
  {
    code: '520522',
    name: '黔西县',
    city_level: 3,
    lng: '106.033544',
    lat: '27.007713',
    count: 0
  },
  {
    code: '520523',
    name: '金沙县',
    city_level: 3,
    lng: '106.220228',
    lat: '27.459214',
    count: 0
  },
  {
    code: '520524',
    name: '织金县',
    city_level: 3,
    lng: '105.770542',
    lat: '26.663450',
    count: 0
  },
  {
    code: '520525',
    name: '纳雍县',
    city_level: 3,
    lng: '105.382715',
    lat: '26.777645',
    count: 0
  },
  {
    code: '520526',
    name: '威宁彝族回族苗族自治县',
    city_level: 3,
    lng: '104.278733',
    lat: '26.856193',
    count: 0
  },
  {
    code: '520527',
    name: '赫章县',
    city_level: 3,
    lng: '104.727418',
    lat: '27.123079',
    count: 0
  },
  {
    code: '520602',
    name: '碧江区',
    city_level: 3,
    lng: '109.181122',
    lat: '27.690653',
    count: 0
  },
  {
    code: '520603',
    name: '万山区',
    city_level: 3,
    lng: '109.213644',
    lat: '27.517896',
    count: 0
  },
  {
    code: '520621',
    name: '江口县',
    city_level: 3,
    lng: '108.839557',
    lat: '27.699650',
    count: 0
  },
  {
    code: '520622',
    name: '玉屏侗族自治县',
    city_level: 3,
    lng: '108.914680',
    lat: '27.243012',
    count: 0
  },
  {
    code: '520623',
    name: '石阡县',
    city_level: 3,
    lng: '108.223612',
    lat: '27.513829',
    count: 0
  },
  {
    code: '520624',
    name: '思南县',
    city_level: 3,
    lng: '108.253873',
    lat: '27.937560',
    count: 0
  },
  {
    code: '520625',
    name: '印江土家族苗族自治县',
    city_level: 3,
    lng: '108.409752',
    lat: '27.994247',
    count: 0
  },
  {
    code: '520626',
    name: '德江县',
    city_level: 3,
    lng: '108.119807',
    lat: '28.263964',
    count: 0
  },
  {
    code: '520627',
    name: '沿河土家族自治县',
    city_level: 3,
    lng: '108.503870',
    lat: '28.563928',
    count: 0
  },
  {
    code: '520628',
    name: '松桃苗族自治县',
    city_level: 3,
    lng: '109.202886',
    lat: '28.154071',
    count: 0
  },
  {
    code: '522301',
    name: '兴义市',
    city_level: 3,
    lng: '104.895467',
    lat: '25.092040',
    count: 0
  },
  {
    code: '522302',
    name: '兴仁市',
    city_level: 3,
    lng: '105.192780',
    lat: '25.431377',
    count: 0
  },
  {
    code: '522323',
    name: '普安县',
    city_level: 3,
    lng: '104.953063',
    lat: '25.784135',
    count: 0
  },
  {
    code: '522324',
    name: '晴隆县',
    city_level: 3,
    lng: '105.218991',
    lat: '25.834784',
    count: 0
  },
  {
    code: '522325',
    name: '贞丰县',
    city_level: 3,
    lng: '105.649864',
    lat: '25.385760',
    count: 0
  },
  {
    code: '522326',
    name: '望谟县',
    city_level: 3,
    lng: '106.093850',
    lat: '25.171024',
    count: 0
  },
  {
    code: '522327',
    name: '册亨县',
    city_level: 3,
    lng: '105.811593',
    lat: '24.983663',
    count: 0
  },
  {
    code: '522328',
    name: '安龙县',
    city_level: 3,
    lng: '105.442701',
    lat: '25.099014',
    count: 0
  },
  {
    code: '522601',
    name: '凯里市',
    city_level: 3,
    lng: '107.981212',
    lat: '26.566867',
    count: 0
  },
  {
    code: '522622',
    name: '黄平县',
    city_level: 3,
    lng: '107.916412',
    lat: '26.905396',
    count: 0
  },
  {
    code: '522623',
    name: '施秉县',
    city_level: 3,
    lng: '108.124380',
    lat: '27.032920',
    count: 0
  },
  {
    code: '522624',
    name: '三穗县',
    city_level: 3,
    lng: '108.675267',
    lat: '26.952968',
    count: 0
  },
  {
    code: '522625',
    name: '镇远县',
    city_level: 3,
    lng: '108.429691',
    lat: '27.049110',
    count: 0
  },
  {
    code: '522626',
    name: '岑巩县',
    city_level: 3,
    lng: '108.816060',
    lat: '27.173887',
    count: 0
  },
  {
    code: '522627',
    name: '天柱县',
    city_level: 3,
    lng: '109.207757',
    lat: '26.909678',
    count: 0
  },
  {
    code: '522628',
    name: '锦屏县',
    city_level: 3,
    lng: '109.200534',
    lat: '26.676233',
    count: 0
  },
  {
    code: '522629',
    name: '剑河县',
    city_level: 3,
    lng: '108.441501',
    lat: '26.728274',
    count: 0
  },
  {
    code: '522630',
    name: '台江县',
    city_level: 3,
    lng: '108.321245',
    lat: '26.667525',
    count: 0
  },
  {
    code: '522631',
    name: '黎平县',
    city_level: 3,
    lng: '109.136658',
    lat: '26.230424',
    count: 0
  },
  {
    code: '522632',
    name: '榕江县',
    city_level: 3,
    lng: '108.521881',
    lat: '25.931893',
    count: 0
  },
  {
    code: '522633',
    name: '从江县',
    city_level: 3,
    lng: '108.905329',
    lat: '25.753009',
    count: 0
  },
  {
    code: '522634',
    name: '雷山县',
    city_level: 3,
    lng: '108.077540',
    lat: '26.378443',
    count: 0
  },
  {
    code: '522635',
    name: '麻江县',
    city_level: 3,
    lng: '107.589359',
    lat: '26.491105',
    count: 0
  },
  {
    code: '522636',
    name: '丹寨县',
    city_level: 3,
    lng: '107.788728',
    lat: '26.198320',
    count: 0
  },
  {
    code: '522701',
    name: '都匀市',
    city_level: 3,
    lng: '107.518847',
    lat: '26.259427',
    count: 0
  },
  {
    code: '522702',
    name: '福泉市',
    city_level: 3,
    lng: '107.520386',
    lat: '26.686335',
    count: 0
  },
  {
    code: '522722',
    name: '荔波县',
    city_level: 3,
    lng: '107.886450',
    lat: '25.410654',
    count: 0
  },
  {
    code: '522723',
    name: '贵定县',
    city_level: 3,
    lng: '107.232889',
    lat: '26.557071',
    count: 0
  },
  {
    code: '522725',
    name: '瓮安县',
    city_level: 3,
    lng: '107.471555',
    lat: '27.078472',
    count: 0
  },
  {
    code: '522726',
    name: '独山县',
    city_level: 3,
    lng: '107.545048',
    lat: '25.822132',
    count: 0
  },
  {
    code: '522727',
    name: '平塘县',
    city_level: 3,
    lng: '107.323077',
    lat: '25.831955',
    count: 0
  },
  {
    code: '522728',
    name: '罗甸县',
    city_level: 3,
    lng: '106.751418',
    lat: '25.424845',
    count: 0
  },
  {
    code: '522729',
    name: '长顺县',
    city_level: 3,
    lng: '106.447376',
    lat: '26.022116',
    count: 0
  },
  {
    code: '522730',
    name: '龙里县',
    city_level: 3,
    lng: '106.979524',
    lat: '26.453154',
    count: 0
  },
  {
    code: '522731',
    name: '惠水县',
    city_level: 3,
    lng: '106.657089',
    lat: '26.132061',
    count: 0
  },
  {
    code: '522732',
    name: '三都水族自治县',
    city_level: 3,
    lng: '107.869749',
    lat: '25.983202',
    count: 0
  },
  {
    code: '530102',
    name: '五华区',
    city_level: 3,
    lng: '102.706959',
    lat: '25.043652',
    count: 0
  },
  {
    code: '530103',
    name: '盘龙区',
    city_level: 3,
    lng: '102.751907',
    lat: '25.116106',
    count: 0
  },
  {
    code: '530111',
    name: '官渡区',
    city_level: 3,
    lng: '102.743812',
    lat: '25.015105',
    count: 0
  },
  {
    code: '530112',
    name: '西山区',
    city_level: 3,
    lng: '102.664376',
    lat: '25.038297',
    count: 0
  },
  {
    code: '530113',
    name: '东川区',
    city_level: 3,
    lng: '103.187825',
    lat: '26.082873',
    count: 0
  },
  {
    code: '530114',
    name: '呈贡区',
    city_level: 3,
    lng: '102.821468',
    lat: '24.885532',
    count: 0
  },
  {
    code: '530115',
    name: '晋宁区',
    city_level: 3,
    lng: '102.594986',
    lat: '24.666945',
    count: 0
  },
  {
    code: '530124',
    name: '富民县',
    city_level: 3,
    lng: '102.497600',
    lat: '25.221935',
    count: 0
  },
  {
    code: '530125',
    name: '宜良县',
    city_level: 3,
    lng: '103.141296',
    lat: '24.919715',
    count: 0
  },
  {
    code: '530126',
    name: '石林彝族自治县',
    city_level: 3,
    lng: '103.290785',
    lat: '24.771798',
    count: 0
  },
  {
    code: '530127',
    name: '嵩明县',
    city_level: 3,
    lng: '103.036908',
    lat: '25.338644',
    count: 0
  },
  {
    code: '530128',
    name: '禄劝彝族苗族自治县',
    city_level: 3,
    lng: '102.471518',
    lat: '25.551332',
    count: 0
  },
  {
    code: '530129',
    name: '寻甸回族彝族自治县',
    city_level: 3,
    lng: '103.256604',
    lat: '25.558175',
    count: 0
  },
  {
    code: '530181',
    name: '安宁市',
    city_level: 3,
    lng: '102.478494',
    lat: '24.919493',
    count: 0
  },
  {
    code: '530302',
    name: '麒麟区',
    city_level: 3,
    lng: '103.805012',
    lat: '25.495241',
    count: 0
  },
  {
    code: '530303',
    name: '沾益区',
    city_level: 2,
    lng: '103.796167',
    lat: '25.489999',
    count: 0
  },
  {
    code: '530304',
    name: '马龙区',
    city_level: 3,
    lng: '104.520734',
    lat: '26.403423',
    count: 0
  },
  {
    code: '530322',
    name: '陆良县',
    city_level: 3,
    lng: '103.666663',
    lat: '25.030051',
    count: 0
  },
  {
    code: '530323',
    name: '师宗县',
    city_level: 3,
    lng: '103.985478',
    lat: '24.822403',
    count: 0
  },
  {
    code: '530324',
    name: '罗平县',
    city_level: 3,
    lng: '104.308675',
    lat: '24.884626',
    count: 0
  },
  {
    code: '530325',
    name: '富源县',
    city_level: 3,
    lng: '104.255015',
    lat: '25.674238',
    count: 0
  },
  {
    code: '530326',
    name: '会泽县',
    city_level: 3,
    lng: '103.297361',
    lat: '26.417947',
    count: 0
  },
  {
    code: '530381',
    name: '宣威市',
    city_level: 3,
    lng: '104.104497',
    lat: '26.219759',
    count: 0
  },
  {
    code: '530402',
    name: '红塔区',
    city_level: 3,
    lng: '102.540179',
    lat: '24.341098',
    count: 0
  },
  {
    code: '530403',
    name: '江川区',
    city_level: 3,
    lng: '102.753760',
    lat: '24.287586',
    count: 0
  },
  {
    code: '530423',
    name: '通海县',
    city_level: 3,
    lng: '102.760039',
    lat: '24.112205',
    count: 0
  },
  {
    code: '530424',
    name: '华宁县',
    city_level: 3,
    lng: '102.928835',
    lat: '24.192761',
    count: 0
  },
  {
    code: '530425',
    name: '易门县',
    city_level: 3,
    lng: '102.162531',
    lat: '24.671651',
    count: 0
  },
  {
    code: '530426',
    name: '峨山彝族自治县',
    city_level: 3,
    lng: '102.405819',
    lat: '24.168957',
    count: 0
  },
  {
    code: '530427',
    name: '新平彝族傣族自治县',
    city_level: 3,
    lng: '101.990157',
    lat: '24.070051',
    count: 0
  },
  {
    code: '530428',
    name: '元江哈尼族彝族傣族自治县',
    city_level: 3,
    lng: '101.998103',
    lat: '23.596503',
    count: 0
  },
  {
    code: '530481',
    name: '澄江市',
    city_level: 3,
    lng: '102.910673',
    lat: '24.671778',
    count: 0
  },
  {
    code: '530502',
    name: '隆阳区',
    city_level: 3,
    lng: '99.165607',
    lat: '25.121154',
    count: 0
  },
  {
    code: '530521',
    name: '施甸县',
    city_level: 3,
    lng: '99.189221',
    lat: '24.723064',
    count: 0
  },
  {
    code: '530523',
    name: '龙陵县',
    city_level: 3,
    lng: '98.689230',
    lat: '24.586766',
    count: 0
  },
  {
    code: '530524',
    name: '昌宁县',
    city_level: 3,
    lng: '99.605142',
    lat: '24.827839',
    count: 0
  },
  {
    code: '530581',
    name: '腾冲市',
    city_level: 3,
    lng: '98.485333',
    lat: '25.033111',
    count: 0
  },
  {
    code: '530602',
    name: '昭阳区',
    city_level: 3,
    lng: '103.706539',
    lat: '27.320075',
    count: 0
  },
  {
    code: '530621',
    name: '鲁甸县',
    city_level: 3,
    lng: '103.558042',
    lat: '27.186659',
    count: 0
  },
  {
    code: '530622',
    name: '巧家县',
    city_level: 3,
    lng: '102.930164',
    lat: '26.908461',
    count: 0
  },
  {
    code: '530623',
    name: '盐津县',
    city_level: 3,
    lng: '104.234442',
    lat: '28.108710',
    count: 0
  },
  {
    code: '530624',
    name: '大关县',
    city_level: 3,
    lng: '103.891146',
    lat: '27.747978',
    count: 0
  },
  {
    code: '530625',
    name: '永善县',
    city_level: 3,
    lng: '103.638067',
    lat: '28.229113',
    count: 0
  },
  {
    code: '530626',
    name: '绥江县',
    city_level: 3,
    lng: '103.968978',
    lat: '28.592100',
    count: 0
  },
  {
    code: '530627',
    name: '镇雄县',
    city_level: 3,
    lng: '104.873579',
    lat: '27.441668',
    count: 0
  },
  {
    code: '530628',
    name: '彝良县',
    city_level: 3,
    lng: '104.048289',
    lat: '27.625419',
    count: 0
  },
  {
    code: '530629',
    name: '威信县',
    city_level: 3,
    lng: '105.049027',
    lat: '27.846901',
    count: 0
  },
  {
    code: '530681',
    name: '水富市',
    city_level: 2,
    lng: '103.717465',
    lat: '27.338257',
    count: 0
  },
  {
    code: '530702',
    name: '古城区',
    city_level: 3,
    lng: '100.225766',
    lat: '26.877190',
    count: 0
  },
  {
    code: '530721',
    name: '玉龙纳西族自治县',
    city_level: 3,
    lng: '100.236955',
    lat: '26.821460',
    count: 0
  },
  {
    code: '530722',
    name: '永胜县',
    city_level: 3,
    lng: '100.750795',
    lat: '26.684225',
    count: 0
  },
  {
    code: '530723',
    name: '华坪县',
    city_level: 3,
    lng: '101.266195',
    lat: '26.629211',
    count: 0
  },
  {
    code: '530724',
    name: '宁蒗彝族自治县',
    city_level: 3,
    lng: '100.852001',
    lat: '27.282071',
    count: 0
  },
  {
    code: '530802',
    name: '思茅区',
    city_level: 3,
    lng: '100.977165',
    lat: '22.786910',
    count: 0
  },
  {
    code: '530821',
    name: '宁洱哈尼族彝族自治县',
    city_level: 3,
    lng: '101.045317',
    lat: '23.061750',
    count: 0
  },
  {
    code: '530822',
    name: '墨江哈尼族自治县',
    city_level: 3,
    lng: '101.692461',
    lat: '23.431894',
    count: 0
  },
  {
    code: '530823',
    name: '景东彝族自治县',
    city_level: 3,
    lng: '100.833877',
    lat: '24.446731',
    count: 0
  },
  {
    code: '530824',
    name: '景谷傣族彝族自治县',
    city_level: 3,
    lng: '100.702871',
    lat: '23.497028',
    count: 0
  },
  {
    code: '530825',
    name: '镇沅彝族哈尼族拉祜族自治县',
    city_level: 3,
    lng: '101.108595',
    lat: '24.004442',
    count: 0
  },
  {
    code: '530826',
    name: '江城哈尼族彝族自治县',
    city_level: 3,
    lng: '101.862120',
    lat: '22.585868',
    count: 0
  },
  {
    code: '530827',
    name: '孟连傣族拉祜族佤族自治县',
    city_level: 3,
    lng: '99.584157',
    lat: '22.329100',
    count: 0
  },
  {
    code: '530828',
    name: '澜沧拉祜族自治县',
    city_level: 3,
    lng: '99.931975',
    lat: '22.555905',
    count: 0
  },
  {
    code: '530829',
    name: '西盟佤族自治县',
    city_level: 3,
    lng: '99.590124',
    lat: '22.644508',
    count: 0
  },
  {
    code: '530902',
    name: '临翔区',
    city_level: 3,
    lng: '100.082523',
    lat: '23.895137',
    count: 0
  },
  {
    code: '530921',
    name: '凤庆县',
    city_level: 3,
    lng: '99.928460',
    lat: '24.580424',
    count: 0
  },
  {
    code: '530922',
    name: '云县',
    city_level: 3,
    lng: '100.123248',
    lat: '24.437061',
    count: 0
  },
  {
    code: '530923',
    name: '永德县',
    city_level: 3,
    lng: '99.259340',
    lat: '24.018357',
    count: 0
  },
  {
    code: '530924',
    name: '镇康县',
    city_level: 3,
    lng: '98.825285',
    lat: '23.762584',
    count: 0
  },
  {
    code: '530925',
    name: '双江拉祜族佤族布朗族傣族自治县',
    city_level: 3,
    lng: '99.827698',
    lat: '23.473499',
    count: 0
  },
  {
    code: '530926',
    name: '耿马傣族佤族自治县',
    city_level: 3,
    lng: '99.397127',
    lat: '23.538092',
    count: 0
  },
  {
    code: '530927',
    name: '沧源佤族自治县',
    city_level: 3,
    lng: '99.246197',
    lat: '23.146712',
    count: 0
  },
  {
    code: '532301',
    name: '楚雄市',
    city_level: 2,
    lng: '101.528068',
    lat: '25.045532',
    count: 0
  },
  {
    code: '532322',
    name: '双柏县',
    city_level: 3,
    lng: '101.641937',
    lat: '24.688875',
    count: 0
  },
  {
    code: '532323',
    name: '牟定县',
    city_level: 3,
    lng: '101.546566',
    lat: '25.313122',
    count: 0
  },
  {
    code: '532324',
    name: '南华县',
    city_level: 3,
    lng: '101.273577',
    lat: '25.192293',
    count: 0
  },
  {
    code: '532325',
    name: '姚安县',
    city_level: 3,
    lng: '101.241728',
    lat: '25.504173',
    count: 0
  },
  {
    code: '532326',
    name: '大姚县',
    city_level: 3,
    lng: '101.336617',
    lat: '25.729513',
    count: 0
  },
  {
    code: '532327',
    name: '永仁县',
    city_level: 3,
    lng: '101.666133',
    lat: '26.049464',
    count: 0
  },
  {
    code: '532328',
    name: '元谋县',
    city_level: 3,
    lng: '101.874520',
    lat: '25.704338',
    count: 0
  },
  {
    code: '532329',
    name: '武定县',
    city_level: 3,
    lng: '102.404338',
    lat: '25.530389',
    count: 0
  },
  {
    code: '532331',
    name: '禄丰县',
    city_level: 3,
    lng: '102.079027',
    lat: '25.150111',
    count: 0
  },
  {
    code: '532501',
    name: '个旧市',
    city_level: 3,
    lng: '103.160034',
    lat: '23.359121',
    count: 0
  },
  {
    code: '532502',
    name: '开远市',
    city_level: 3,
    lng: '103.267143',
    lat: '23.714316',
    count: 0
  },
  {
    code: '532503',
    name: '蒙自市',
    city_level: 3,
    lng: '103.364905',
    lat: '23.396201',
    count: 0
  },
  {
    code: '532504',
    name: '弥勒市',
    city_level: 3,
    lng: '103.414874',
    lat: '24.411912',
    count: 0
  },
  {
    code: '532523',
    name: '屏边苗族自治县',
    city_level: 3,
    lng: '103.687612',
    lat: '22.983560',
    count: 0
  },
  {
    code: '532524',
    name: '建水县',
    city_level: 3,
    lng: '102.826557',
    lat: '23.634700',
    count: 0
  },
  {
    code: '532525',
    name: '石屏县',
    city_level: 3,
    lng: '102.494984',
    lat: '23.705936',
    count: 0
  },
  {
    code: '532527',
    name: '泸西县',
    city_level: 3,
    lng: '103.766196',
    lat: '24.532025',
    count: 0
  },
  {
    code: '532528',
    name: '元阳县',
    city_level: 3,
    lng: '102.835223',
    lat: '23.219932',
    count: 0
  },
  {
    code: '532529',
    name: '红河县',
    city_level: 3,
    lng: '102.420600',
    lat: '23.369161',
    count: 0
  },
  {
    code: '532530',
    name: '金平苗族瑶族傣族自治县',
    city_level: 3,
    lng: '103.226448',
    lat: '22.779543',
    count: 0
  },
  {
    code: '532531',
    name: '绿春县',
    city_level: 3,
    lng: '102.392463',
    lat: '22.993718',
    count: 0
  },
  {
    code: '532532',
    name: '河口瑶族自治县',
    city_level: 3,
    lng: '103.939350',
    lat: '22.529404',
    count: 0
  },
  {
    code: '532601',
    name: '文山市',
    city_level: 2,
    lng: '104.216248',
    lat: '23.400733',
    count: 0
  },
  {
    code: '532622',
    name: '砚山县',
    city_level: 3,
    lng: '104.337242',
    lat: '23.605865',
    count: 0
  },
  {
    code: '532623',
    name: '西畴县',
    city_level: 3,
    lng: '104.672597',
    lat: '23.437782',
    count: 0
  },
  {
    code: '532624',
    name: '麻栗坡县',
    city_level: 3,
    lng: '104.702799',
    lat: '23.125714',
    count: 0
  },
  {
    code: '532625',
    name: '马关县',
    city_level: 3,
    lng: '104.394158',
    lat: '23.012915',
    count: 0
  },
  {
    code: '532626',
    name: '丘北县',
    city_level: 3,
    lng: '104.195820',
    lat: '24.041919',
    count: 0
  },
  {
    code: '532627',
    name: '广南县',
    city_level: 3,
    lng: '105.054981',
    lat: '24.045941',
    count: 0
  },
  {
    code: '532628',
    name: '富宁县',
    city_level: 3,
    lng: '105.630999',
    lat: '23.625283',
    count: 0
  },
  {
    code: '532801',
    name: '景洪市',
    city_level: 3,
    lng: '100.771679',
    lat: '22.000143',
    count: 0
  },
  {
    code: '532822',
    name: '勐海县',
    city_level: 3,
    lng: '100.452548',
    lat: '21.957354',
    count: 0
  },
  {
    code: '532823',
    name: '勐腊县',
    city_level: 3,
    lng: '101.564636',
    lat: '21.459233',
    count: 0
  },
  {
    code: '532901',
    name: '大理市',
    city_level: 2,
    lng: '100.267638',
    lat: '25.606486',
    count: 0
  },
  {
    code: '532922',
    name: '漾濞彝族自治县',
    city_level: 3,
    lng: '99.958015',
    lat: '25.670148',
    count: 0
  },
  {
    code: '532923',
    name: '祥云县',
    city_level: 3,
    lng: '100.550946',
    lat: '25.483850',
    count: 0
  },
  {
    code: '532924',
    name: '宾川县',
    city_level: 3,
    lng: '100.575412',
    lat: '25.827182',
    count: 0
  },
  {
    code: '532925',
    name: '弥渡县',
    city_level: 3,
    lng: '100.490991',
    lat: '25.343804',
    count: 0
  },
  {
    code: '532926',
    name: '南涧彝族自治县',
    city_level: 3,
    lng: '100.509036',
    lat: '25.043510',
    count: 0
  },
  {
    code: '532927',
    name: '巍山彝族回族自治县',
    city_level: 3,
    lng: '100.307175',
    lat: '25.227212',
    count: 0
  },
  {
    code: '532928',
    name: '永平县',
    city_level: 3,
    lng: '99.541236',
    lat: '25.464681',
    count: 0
  },
  {
    code: '532929',
    name: '云龙县',
    city_level: 3,
    lng: '99.371121',
    lat: '25.885596',
    count: 0
  },
  {
    code: '532930',
    name: '洱源县',
    city_level: 3,
    lng: '99.951054',
    lat: '26.111160',
    count: 0
  },
  {
    code: '532931',
    name: '剑川县',
    city_level: 3,
    lng: '99.905559',
    lat: '26.537033',
    count: 0
  },
  {
    code: '532932',
    name: '鹤庆县',
    city_level: 3,
    lng: '100.176498',
    lat: '26.560231',
    count: 0
  },
  {
    code: '533102',
    name: '瑞丽市',
    city_level: 3,
    lng: '97.855477',
    lat: '24.017836',
    count: 0
  },
  {
    code: '533103',
    name: '芒市',
    city_level: 3,
    lng: '98.588086',
    lat: '24.433690',
    count: 0
  },
  {
    code: '533122',
    name: '梁河县',
    city_level: 3,
    lng: '98.296657',
    lat: '24.804232',
    count: 0
  },
  {
    code: '533123',
    name: '盈江县',
    city_level: 3,
    lng: '97.943613',
    lat: '24.691283',
    count: 0
  },
  {
    code: '533124',
    name: '陇川县',
    city_level: 3,
    lng: '97.792105',
    lat: '24.182965',
    count: 0
  },
  {
    code: '533301',
    name: '泸水市',
    city_level: 3,
    lng: '98.857977',
    lat: '25.822880',
    count: 0
  },
  {
    code: '533323',
    name: '福贡县',
    city_level: 3,
    lng: '98.869132',
    lat: '26.901832',
    count: 0
  },
  {
    code: '533324',
    name: '贡山独龙族怒族自治县',
    city_level: 3,
    lng: '98.665965',
    lat: '27.740999',
    count: 0
  },
  {
    code: '533325',
    name: '兰坪白族普米族自治县',
    city_level: 3,
    lng: '99.416677',
    lat: '26.453571',
    count: 0
  },
  {
    code: '533401',
    name: '香格里拉市',
    city_level: 3,
    lng: '99.700836',
    lat: '27.829743',
    count: 0
  },
  {
    code: '533422',
    name: '德钦县',
    city_level: 3,
    lng: '98.911561',
    lat: '28.486162',
    count: 0
  },
  {
    code: '533423',
    name: '维西傈僳族自治县',
    city_level: 3,
    lng: '99.287173',
    lat: '27.177162',
    count: 0
  },
  {
    code: '540102',
    name: '城关区',
    city_level: 3,
    lng: '103.825255',
    lat: '36.057054',
    count: 0
  },
  {
    code: '540103',
    name: '堆龙德庆区',
    city_level: 2,
    lng: '91.140856',
    lat: '29.645554',
    count: 0
  },
  {
    code: '540104',
    name: '达孜区',
    city_level: 3,
    lng: '91.500130',
    lat: '29.819293',
    count: 0
  },
  {
    code: '540121',
    name: '林周县',
    city_level: 3,
    lng: '91.265288',
    lat: '29.893545',
    count: 0
  },
  {
    code: '540122',
    name: '当雄县',
    city_level: 3,
    lng: '91.101162',
    lat: '30.473119',
    count: 0
  },
  {
    code: '540123',
    name: '尼木县',
    city_level: 3,
    lng: '90.164524',
    lat: '29.431832',
    count: 0
  },
  {
    code: '540124',
    name: '曲水县',
    city_level: 3,
    lng: '90.743853',
    lat: '29.353059',
    count: 0
  },
  {
    code: '540127',
    name: '墨竹工卡县',
    city_level: 3,
    lng: '91.730866',
    lat: '29.834132',
    count: 0
  },
  {
    code: '540202',
    name: '桑珠孜区',
    city_level: 3,
    lng: '88.887270',
    lat: '29.270389',
    count: 0
  },
  {
    code: '540221',
    name: '南木林县',
    city_level: 3,
    lng: '89.099243',
    lat: '29.682331',
    count: 0
  },
  {
    code: '540222',
    name: '江孜县',
    city_level: 3,
    lng: '89.605574',
    lat: '28.911659',
    count: 0
  },
  {
    code: '540223',
    name: '定日县',
    city_level: 3,
    lng: '87.126120',
    lat: '28.658743',
    count: 0
  },
  {
    code: '540224',
    name: '萨迦县',
    city_level: 3,
    lng: '88.021674',
    lat: '28.899664',
    count: 0
  },
  {
    code: '540225',
    name: '拉孜县',
    city_level: 3,
    lng: '87.637041',
    lat: '29.081660',
    count: 0
  },
  {
    code: '540226',
    name: '昂仁县',
    city_level: 3,
    lng: '87.236051',
    lat: '29.294802',
    count: 0
  },
  {
    code: '540227',
    name: '谢通门县',
    city_level: 3,
    lng: '88.261620',
    lat: '29.432641',
    count: 0
  },
  {
    code: '540228',
    name: '白朗县',
    city_level: 3,
    lng: '89.097806',
    lat: '28.814286',
    count: 0
  },
  {
    code: '540229',
    name: '仁布县',
    city_level: 3,
    lng: '89.841984',
    lat: '29.230933',
    count: 0
  },
  {
    code: '540230',
    name: '康马县',
    city_level: 3,
    lng: '89.681663',
    lat: '28.555627',
    count: 0
  },
  {
    code: '540231',
    name: '定结县',
    city_level: 3,
    lng: '87.765872',
    lat: '28.364159',
    count: 0
  },
  {
    code: '540232',
    name: '仲巴县',
    city_level: 3,
    lng: '84.031530',
    lat: '29.770279',
    count: 0
  },
  {
    code: '540233',
    name: '亚东县',
    city_level: 3,
    lng: '88.907094',
    lat: '27.484806',
    count: 0
  },
  {
    code: '540234',
    name: '吉隆县',
    city_level: 3,
    lng: '85.297535',
    lat: '28.852394',
    count: 0
  },
  {
    code: '540235',
    name: '聂拉木县',
    city_level: 3,
    lng: '85.982237',
    lat: '28.155186',
    count: 0
  },
  {
    code: '540236',
    name: '萨嘎县',
    city_level: 3,
    lng: '85.232941',
    lat: '29.328818',
    count: 0
  },
  {
    code: '540237',
    name: '岗巴县',
    city_level: 3,
    lng: '88.520031',
    lat: '28.274601',
    count: 0
  },
  {
    code: '540302',
    name: '卡若区',
    city_level: 3,
    lng: '97.180437',
    lat: '31.138507',
    count: 0
  },
  {
    code: '540321',
    name: '江达县',
    city_level: 3,
    lng: '98.218430',
    lat: '31.499202',
    count: 0
  },
  {
    code: '540322',
    name: '贡觉县',
    city_level: 3,
    lng: '98.270970',
    lat: '30.860099',
    count: 0
  },
  {
    code: '540323',
    name: '类乌齐县',
    city_level: 3,
    lng: '96.600246',
    lat: '31.211601',
    count: 0
  },
  {
    code: '540324',
    name: '丁青县',
    city_level: 3,
    lng: '95.595761',
    lat: '31.412405',
    count: 0
  },
  {
    code: '540325',
    name: '察雅县',
    city_level: 3,
    lng: '97.568752',
    lat: '30.653943',
    count: 0
  },
  {
    code: '540326',
    name: '八宿县',
    city_level: 3,
    lng: '96.917836',
    lat: '30.053209',
    count: 0
  },
  {
    code: '540327',
    name: '左贡县',
    city_level: 3,
    lng: '97.841022',
    lat: '29.671069',
    count: 0
  },
  {
    code: '540328',
    name: '芒康县',
    city_level: 3,
    lng: '98.593113',
    lat: '29.679908',
    count: 0
  },
  {
    code: '540329',
    name: '洛隆县',
    city_level: 3,
    lng: '95.824567',
    lat: '30.741571',
    count: 0
  },
  {
    code: '540330',
    name: '边坝县',
    city_level: 3,
    lng: '94.707800',
    lat: '30.933652',
    count: 0
  },
  {
    code: '540402',
    name: '巴宜区',
    city_level: 3,
    lng: '94.360994',
    lat: '29.653727',
    count: 0
  },
  {
    code: '540421',
    name: '工布江达县',
    city_level: 3,
    lng: '93.246077',
    lat: '29.885280',
    count: 0
  },
  {
    code: '540422',
    name: '米林县',
    city_level: 2,
    lng: '94.361490',
    lat: '29.649128',
    count: 0
  },
  {
    code: '540423',
    name: '墨脱县',
    city_level: 3,
    lng: '95.332241',
    lat: '29.325734',
    count: 0
  },
  {
    code: '540424',
    name: '波密县',
    city_level: 3,
    lng: '95.768158',
    lat: '29.858766',
    count: 0
  },
  {
    code: '540425',
    name: '察隅县',
    city_level: 3,
    lng: '97.465014',
    lat: '28.660234',
    count: 0
  },
  {
    code: '540426',
    name: '朗县',
    city_level: 3,
    lng: '93.074702',
    lat: '29.046337',
    count: 0
  },
  {
    code: '540502',
    name: '乃东区',
    city_level: 3,
    lng: '91.761539',
    lat: '29.224904',
    count: 0
  },
  {
    code: '540521',
    name: '扎囊县',
    city_level: 3,
    lng: '91.337250',
    lat: '29.245114',
    count: 0
  },
  {
    code: '540522',
    name: '贡嘎县',
    city_level: 3,
    lng: '90.984140',
    lat: '29.289455',
    count: 0
  },
  {
    code: '540523',
    name: '桑日县',
    city_level: 3,
    lng: '92.015818',
    lat: '29.259189',
    count: 0
  },
  {
    code: '540524',
    name: '琼结县',
    city_level: 3,
    lng: '91.683881',
    lat: '29.024625',
    count: 0
  },
  {
    code: '540525',
    name: '曲松县',
    city_level: 3,
    lng: '92.203739',
    lat: '29.062826',
    count: 0
  },
  {
    code: '540526',
    name: '措美县',
    city_level: 3,
    lng: '91.433509',
    lat: '28.438202',
    count: 0
  },
  {
    code: '540527',
    name: '洛扎县',
    city_level: 3,
    lng: '90.859992',
    lat: '28.385713',
    count: 0
  },
  {
    code: '540528',
    name: '加查县',
    city_level: 3,
    lng: '92.593993',
    lat: '29.140290',
    count: 0
  },
  {
    code: '540529',
    name: '隆子县',
    city_level: 2,
    lng: '91.773134',
    lat: '29.237137',
    count: 0
  },
  {
    code: '540530',
    name: '错那县',
    city_level: 2,
    lng: '91.773134',
    lat: '29.237137',
    count: 0
  },
  {
    code: '540531',
    name: '浪卡子县',
    city_level: 3,
    lng: '90.397977',
    lat: '28.968031',
    count: 0
  },
  {
    code: '540602',
    name: '色尼区',
    city_level: 2,
    lng: '92.060211',
    lat: '31.476004',
    count: 0
  },
  {
    code: '540621',
    name: '嘉黎县',
    city_level: 3,
    lng: '93.232528',
    lat: '30.640815',
    count: 0
  },
  {
    code: '540622',
    name: '比如县',
    city_level: 3,
    lng: '93.679639',
    lat: '31.480250',
    count: 0
  },
  {
    code: '540623',
    name: '聂荣县',
    city_level: 3,
    lng: '92.303346',
    lat: '32.107772',
    count: 0
  },
  {
    code: '540624',
    name: '安多县',
    city_level: 3,
    lng: '91.682330',
    lat: '32.265176',
    count: 0
  },
  {
    code: '540625',
    name: '申扎县',
    city_level: 3,
    lng: '88.709853',
    lat: '30.930505',
    count: 0
  },
  {
    code: '540626',
    name: '索县',
    city_level: 3,
    lng: '93.785631',
    lat: '31.886918',
    count: 0
  },
  {
    code: '540627',
    name: '班戈县',
    city_level: 3,
    lng: '90.009957',
    lat: '31.392411',
    count: 0
  },
  {
    code: '540628',
    name: '巴青县',
    city_level: 3,
    lng: '94.053463',
    lat: '31.918563',
    count: 0
  },
  {
    code: '540629',
    name: '尼玛县',
    city_level: 3,
    lng: '87.236772',
    lat: '31.784701',
    count: 0
  },
  {
    code: '540630',
    name: '双湖县',
    city_level: 3,
    lng: '88.837642',
    lat: '33.188515',
    count: 0
  },
  {
    code: '542521',
    name: '普兰县',
    city_level: 3,
    lng: '81.176237',
    lat: '30.294402',
    count: 0
  },
  {
    code: '542522',
    name: '札达县',
    city_level: 3,
    lng: '79.802706',
    lat: '31.479217',
    count: 0
  },
  {
    code: '542523',
    name: '噶尔县',
    city_level: 3,
    lng: '80.096419',
    lat: '32.491488',
    count: 0
  },
  {
    code: '542524',
    name: '日土县',
    city_level: 3,
    lng: '79.732427',
    lat: '33.381359',
    count: 0
  },
  {
    code: '542525',
    name: '革吉县',
    city_level: 3,
    lng: '81.145433',
    lat: '32.387233',
    count: 0
  },
  {
    code: '542526',
    name: '改则县',
    city_level: 3,
    lng: '84.062590',
    lat: '32.302713',
    count: 0
  },
  {
    code: '542527',
    name: '措勤县',
    city_level: 3,
    lng: '85.159494',
    lat: '31.016769',
    count: 0
  },
  {
    code: '610102',
    name: '新城区',
    city_level: 3,
    lng: '108.960747',
    lat: '34.266451',
    count: 0
  },
  {
    code: '610103',
    name: '碑林区',
    city_level: 3,
    lng: '108.934235',
    lat: '34.230769',
    count: 0
  },
  {
    code: '610104',
    name: '莲湖区',
    city_level: 3,
    lng: '108.944041',
    lat: '34.264995',
    count: 0
  },
  {
    code: '610111',
    name: '灞桥区',
    city_level: 3,
    lng: '109.064671',
    lat: '34.273409',
    count: 0
  },
  {
    code: '610112',
    name: '未央区',
    city_level: 3,
    lng: '108.946850',
    lat: '34.292873',
    count: 0
  },
  {
    code: '610113',
    name: '雁塔区',
    city_level: 3,
    lng: '108.926593',
    lat: '34.213389',
    count: 0
  },
  {
    code: '610114',
    name: '阎良区',
    city_level: 3,
    lng: '109.226102',
    lat: '34.662234',
    count: 0
  },
  {
    code: '610115',
    name: '临潼区',
    city_level: 3,
    lng: '109.214238',
    lat: '34.367275',
    count: 0
  },
  {
    code: '610116',
    name: '长安区',
    city_level: 3,
    lng: '108.906917',
    lat: '34.159016',
    count: 0
  },
  {
    code: '610117',
    name: '高陵区',
    city_level: 3,
    lng: '109.088297',
    lat: '34.534830',
    count: 0
  },
  {
    code: '610118',
    name: '鄠邑区',
    city_level: 3,
    lng: '108.605011',
    lat: '34.108626',
    count: 0
  },
  {
    code: '610122',
    name: '蓝田县',
    city_level: 3,
    lng: '109.323479',
    lat: '34.151624',
    count: 0
  },
  {
    code: '610124',
    name: '周至县',
    city_level: 3,
    lng: '108.222154',
    lat: '34.163621',
    count: 0
  },
  {
    code: '610202',
    name: '王益区',
    city_level: 3,
    lng: '109.075578',
    lat: '35.068964',
    count: 0
  },
  {
    code: '610203',
    name: '印台区',
    city_level: 3,
    lng: '109.099975',
    lat: '35.114492',
    count: 0
  },
  {
    code: '610204',
    name: '耀州区',
    city_level: 3,
    lng: '108.980514',
    lat: '34.908916',
    count: 0
  },
  {
    code: '610222',
    name: '宜君县',
    city_level: 3,
    lng: '109.116932',
    lat: '35.398577',
    count: 0
  },
  {
    code: '610302',
    name: '渭滨区',
    city_level: 3,
    lng: '107.149968',
    lat: '34.371184',
    count: 0
  },
  {
    code: '610303',
    name: '金台区',
    city_level: 3,
    lng: '107.146806',
    lat: '34.376069',
    count: 0
  },
  {
    code: '610304',
    name: '陈仓区',
    city_level: 3,
    lng: '107.363567',
    lat: '34.354701',
    count: 0
  },
  {
    code: '610322',
    name: '凤翔县',
    city_level: 3,
    lng: '107.400737',
    lat: '34.521218',
    count: 0
  },
  {
    code: '610323',
    name: '岐山县',
    city_level: 3,
    lng: '107.621054',
    lat: '34.443459',
    count: 0
  },
  {
    code: '610324',
    name: '扶风县',
    city_level: 3,
    lng: '107.900219',
    lat: '34.375411',
    count: 0
  },
  {
    code: '610326',
    name: '眉县',
    city_level: 3,
    lng: '107.749767',
    lat: '34.274247',
    count: 0
  },
  {
    code: '610327',
    name: '陇县',
    city_level: 3,
    lng: '106.864397',
    lat: '34.893050',
    count: 0
  },
  {
    code: '610328',
    name: '千阳县',
    city_level: 3,
    lng: '107.132442',
    lat: '34.642381',
    count: 0
  },
  {
    code: '610329',
    name: '麟游县',
    city_level: 3,
    lng: '107.793525',
    lat: '34.677902',
    count: 0
  },
  {
    code: '610330',
    name: '凤县',
    city_level: 3,
    lng: '106.515756',
    lat: '33.908469',
    count: 0
  },
  {
    code: '610331',
    name: '太白县',
    city_level: 3,
    lng: '107.319116',
    lat: '34.058401',
    count: 0
  },
  {
    code: '610402',
    name: '秦都区',
    city_level: 3,
    lng: '108.706272',
    lat: '34.329567',
    count: 0
  },
  {
    code: '610403',
    name: '杨陵区',
    city_level: 3,
    lng: '108.084732',
    lat: '34.272117',
    count: 0
  },
  {
    code: '610404',
    name: '渭城区',
    city_level: 3,
    lng: '108.737213',
    lat: '34.361988',
    count: 0
  },
  {
    code: '610422',
    name: '三原县',
    city_level: 3,
    lng: '108.940509',
    lat: '34.617382',
    count: 0
  },
  {
    code: '610423',
    name: '泾阳县',
    city_level: 3,
    lng: '108.842623',
    lat: '34.527114',
    count: 0
  },
  {
    code: '610424',
    name: '乾县',
    city_level: 3,
    lng: '108.239473',
    lat: '34.527551',
    count: 0
  },
  {
    code: '610425',
    name: '礼泉县',
    city_level: 3,
    lng: '108.425018',
    lat: '34.481764',
    count: 0
  },
  {
    code: '610426',
    name: '永寿县',
    city_level: 3,
    lng: '108.142311',
    lat: '34.691979',
    count: 0
  },
  {
    code: '610428',
    name: '长武县',
    city_level: 3,
    lng: '107.798757',
    lat: '35.205886',
    count: 0
  },
  {
    code: '610429',
    name: '旬邑县',
    city_level: 3,
    lng: '108.333986',
    lat: '35.111978',
    count: 0
  },
  {
    code: '610430',
    name: '淳化县',
    city_level: 3,
    lng: '108.580681',
    lat: '34.799250',
    count: 0
  },
  {
    code: '610431',
    name: '武功县',
    city_level: 3,
    lng: '108.200398',
    lat: '34.260204',
    count: 0
  },
  {
    code: '610481',
    name: '兴平市',
    city_level: 3,
    lng: '108.490475',
    lat: '34.299221',
    count: 0
  },
  {
    code: '610482',
    name: '彬州市',
    city_level: 2,
    lng: '108.708991',
    lat: '34.329605',
    count: 0
  },
  {
    code: '610502',
    name: '临渭区',
    city_level: 3,
    lng: '109.492726',
    lat: '34.498192',
    count: 0
  },
  {
    code: '610503',
    name: '华州区',
    city_level: 3,
    lng: '109.761417',
    lat: '34.511949',
    count: 0
  },
  {
    code: '610522',
    name: '潼关县',
    city_level: 3,
    lng: '110.246350',
    lat: '34.544296',
    count: 0
  },
  {
    code: '610523',
    name: '大荔县',
    city_level: 3,
    lng: '109.941658',
    lat: '34.797184',
    count: 0
  },
  {
    code: '610524',
    name: '合阳县',
    city_level: 3,
    lng: '110.149466',
    lat: '35.237986',
    count: 0
  },
  {
    code: '610525',
    name: '澄城县',
    city_level: 3,
    lng: '109.932350',
    lat: '35.190245',
    count: 0
  },
  {
    code: '610526',
    name: '蒲城县',
    city_level: 3,
    lng: '109.586506',
    lat: '34.955855',
    count: 0
  },
  {
    code: '610527',
    name: '白水县',
    city_level: 3,
    lng: '109.590671',
    lat: '35.177452',
    count: 0
  },
  {
    code: '610528',
    name: '富平县',
    city_level: 3,
    lng: '109.180331',
    lat: '34.751086',
    count: 0
  },
  {
    code: '610581',
    name: '韩城市',
    city_level: 3,
    lng: '110.442847',
    lat: '35.476788',
    count: 0
  },
  {
    code: '610582',
    name: '华阴市',
    city_level: 3,
    lng: '110.092301',
    lat: '34.566096',
    count: 0
  },
  {
    code: '610602',
    name: '宝塔区',
    city_level: 3,
    lng: '109.493106',
    lat: '36.591266',
    count: 0
  },
  {
    code: '610603',
    name: '安塞区',
    city_level: 3,
    lng: '109.325340',
    lat: '36.864410',
    count: 0
  },
  {
    code: '610621',
    name: '延长县',
    city_level: 3,
    lng: '110.012334',
    lat: '36.579313',
    count: 0
  },
  {
    code: '610622',
    name: '延川县',
    city_level: 3,
    lng: '110.193514',
    lat: '36.878117',
    count: 0
  },
  {
    code: '610625',
    name: '志丹县',
    city_level: 3,
    lng: '108.768432',
    lat: '36.822194',
    count: 0
  },
  {
    code: '610626',
    name: '吴起县',
    city_level: 3,
    lng: '108.175933',
    lat: '36.927216',
    count: 0
  },
  {
    code: '610627',
    name: '甘泉县',
    city_level: 3,
    lng: '109.351020',
    lat: '36.276526',
    count: 0
  },
  {
    code: '610628',
    name: '富县',
    city_level: 3,
    lng: '109.379711',
    lat: '35.988010',
    count: 0
  },
  {
    code: '610629',
    name: '洛川县',
    city_level: 3,
    lng: '109.432369',
    lat: '35.761975',
    count: 0
  },
  {
    code: '610630',
    name: '宜川县',
    city_level: 3,
    lng: '110.168963',
    lat: '36.050178',
    count: 0
  },
  {
    code: '610631',
    name: '黄龙县',
    city_level: 3,
    lng: '109.840373',
    lat: '35.584467',
    count: 0
  },
  {
    code: '610632',
    name: '黄陵县',
    city_level: 3,
    lng: '109.262961',
    lat: '35.579428',
    count: 0
  },
  {
    code: '610681',
    name: '子长市',
    city_level: 2,
    lng: '109.489727',
    lat: '36.585455',
    count: 0
  },
  {
    code: '610702',
    name: '汉台区',
    city_level: 3,
    lng: '107.031856',
    lat: '33.067771',
    count: 0
  },
  {
    code: '610703',
    name: '南郑区',
    city_level: 2,
    lng: '107.023323',
    lat: '33.067480',
    count: 0
  },
  {
    code: '610722',
    name: '城固县',
    city_level: 3,
    lng: '107.333930',
    lat: '33.157131',
    count: 0
  },
  {
    code: '610723',
    name: '洋县',
    city_level: 3,
    lng: '107.545837',
    lat: '33.222739',
    count: 0
  },
  {
    code: '610724',
    name: '西乡县',
    city_level: 3,
    lng: '107.766614',
    lat: '32.983101',
    count: 0
  },
  {
    code: '610725',
    name: '勉县',
    city_level: 3,
    lng: '106.673221',
    lat: '33.153553',
    count: 0
  },
  {
    code: '610726',
    name: '宁强县',
    city_level: 3,
    lng: '106.257171',
    lat: '32.829694',
    count: 0
  },
  {
    code: '610727',
    name: '略阳县',
    city_level: 3,
    lng: '106.156718',
    lat: '33.327281',
    count: 0
  },
  {
    code: '610728',
    name: '镇巴县',
    city_level: 3,
    lng: '107.895035',
    lat: '32.536704',
    count: 0
  },
  {
    code: '610729',
    name: '留坝县',
    city_level: 3,
    lng: '106.920808',
    lat: '33.617571',
    count: 0
  },
  {
    code: '610730',
    name: '佛坪县',
    city_level: 3,
    lng: '107.990539',
    lat: '33.524359',
    count: 0
  },
  {
    code: '610802',
    name: '榆阳区',
    city_level: 3,
    lng: '109.720309',
    lat: '38.277029',
    count: 0
  },
  {
    code: '610803',
    name: '横山区',
    city_level: 3,
    lng: '109.294346',
    lat: '37.962209',
    count: 0
  },
  {
    code: '610822',
    name: '府谷县',
    city_level: 3,
    lng: '111.067366',
    lat: '39.028116',
    count: 0
  },
  {
    code: '610824',
    name: '靖边县',
    city_level: 3,
    lng: '108.793988',
    lat: '37.599438',
    count: 0
  },
  {
    code: '610825',
    name: '定边县',
    city_level: 3,
    lng: '107.601267',
    lat: '37.594612',
    count: 0
  },
  {
    code: '610826',
    name: '绥德县',
    city_level: 3,
    lng: '110.263362',
    lat: '37.502940',
    count: 0
  },
  {
    code: '610827',
    name: '米脂县',
    city_level: 3,
    lng: '110.183754',
    lat: '37.755417',
    count: 0
  },
  {
    code: '610828',
    name: '佳县',
    city_level: 3,
    lng: '110.491345',
    lat: '38.019511',
    count: 0
  },
  {
    code: '610829',
    name: '吴堡县',
    city_level: 3,
    lng: '110.739673',
    lat: '37.452068',
    count: 0
  },
  {
    code: '610830',
    name: '清涧县',
    city_level: 3,
    lng: '110.121209',
    lat: '37.088878',
    count: 0
  },
  {
    code: '610831',
    name: '子洲县',
    city_level: 3,
    lng: '110.035250',
    lat: '37.610683',
    count: 0
  },
  {
    code: '610881',
    name: '神木市',
    city_level: 3,
    lng: '110.497002',
    lat: '38.835640',
    count: 0
  },
  {
    code: '610902',
    name: '汉滨区',
    city_level: 3,
    lng: '109.026836',
    lat: '32.695173',
    count: 0
  },
  {
    code: '610921',
    name: '汉阴县',
    city_level: 3,
    lng: '108.508745',
    lat: '32.893026',
    count: 0
  },
  {
    code: '610922',
    name: '石泉县',
    city_level: 3,
    lng: '108.247887',
    lat: '33.038408',
    count: 0
  },
  {
    code: '610923',
    name: '宁陕县',
    city_level: 3,
    lng: '108.314283',
    lat: '33.310527',
    count: 0
  },
  {
    code: '610924',
    name: '紫阳县',
    city_level: 3,
    lng: '108.534229',
    lat: '32.520246',
    count: 0
  },
  {
    code: '610925',
    name: '岚皋县',
    city_level: 3,
    lng: '108.902049',
    lat: '32.307001',
    count: 0
  },
  {
    code: '610926',
    name: '平利县',
    city_level: 3,
    lng: '109.361864',
    lat: '32.388854',
    count: 0
  },
  {
    code: '610927',
    name: '镇坪县',
    city_level: 3,
    lng: '109.526873',
    lat: '31.883672',
    count: 0
  },
  {
    code: '610928',
    name: '旬阳县',
    city_level: 3,
    lng: '109.365265',
    lat: '32.834086',
    count: 0
  },
  {
    code: '610929',
    name: '白河县',
    city_level: 3,
    lng: '110.112629',
    lat: '32.809026',
    count: 0
  },
  {
    code: '611002',
    name: '商州区',
    city_level: 3,
    lng: '109.941241',
    lat: '33.862703',
    count: 0
  },
  {
    code: '611021',
    name: '洛南县',
    city_level: 3,
    lng: '110.148509',
    lat: '34.090838',
    count: 0
  },
  {
    code: '611022',
    name: '丹凤县',
    city_level: 3,
    lng: '110.331676',
    lat: '33.698185',
    count: 0
  },
  {
    code: '611023',
    name: '商南县',
    city_level: 3,
    lng: '110.881807',
    lat: '33.530995',
    count: 0
  },
  {
    code: '611024',
    name: '山阳县',
    city_level: 3,
    lng: '109.882290',
    lat: '33.532172',
    count: 0
  },
  {
    code: '611025',
    name: '镇安县',
    city_level: 3,
    lng: '109.152893',
    lat: '33.423357',
    count: 0
  },
  {
    code: '611026',
    name: '柞水县',
    city_level: 3,
    lng: '109.114207',
    lat: '33.686110',
    count: 0
  },
  {
    code: '620102',
    name: '城关区',
    city_level: 3,
    lng: '103.825255',
    lat: '36.057054',
    count: 0
  },
  {
    code: '620103',
    name: '七里河区',
    city_level: 3,
    lng: '103.785866',
    lat: '36.065915',
    count: 0
  },
  {
    code: '620104',
    name: '西固区',
    city_level: 3,
    lng: '103.627964',
    lat: '36.088431',
    count: 0
  },
  {
    code: '620105',
    name: '安宁区',
    city_level: 3,
    lng: '103.719090',
    lat: '36.103927',
    count: 0
  },
  {
    code: '620111',
    name: '红古区',
    city_level: 3,
    lng: '102.859323',
    lat: '36.345669',
    count: 0
  },
  {
    code: '620121',
    name: '永登县',
    city_level: 3,
    lng: '103.260380',
    lat: '36.736513',
    count: 0
  },
  {
    code: '620122',
    name: '皋兰县',
    city_level: 3,
    lng: '103.947377',
    lat: '36.332663',
    count: 0
  },
  {
    code: '620123',
    name: '榆中县',
    city_level: 3,
    lng: '104.112527',
    lat: '35.843056',
    count: 0
  },
  {
    code: '620302',
    name: '金川区',
    city_level: 3,
    lng: '102.194089',
    lat: '38.521085',
    count: 0
  },
  {
    code: '620321',
    name: '永昌县',
    city_level: 3,
    lng: '101.984649',
    lat: '38.243170',
    count: 0
  },
  {
    code: '620402',
    name: '白银区',
    city_level: 3,
    lng: '104.148556',
    lat: '36.535398',
    count: 0
  },
  {
    code: '620403',
    name: '平川区',
    city_level: 3,
    lng: '104.825208',
    lat: '36.728304',
    count: 0
  },
  {
    code: '620421',
    name: '靖远县',
    city_level: 3,
    lng: '104.676774',
    lat: '36.571366',
    count: 0
  },
  {
    code: '620422',
    name: '会宁县',
    city_level: 3,
    lng: '105.053358',
    lat: '35.692823',
    count: 0
  },
  {
    code: '620423',
    name: '景泰县',
    city_level: 3,
    lng: '104.063091',
    lat: '37.183804',
    count: 0
  },
  {
    code: '620502',
    name: '秦州区',
    city_level: 3,
    lng: '105.724199',
    lat: '34.580892',
    count: 0
  },
  {
    code: '620503',
    name: '麦积区',
    city_level: 3,
    lng: '105.889557',
    lat: '34.570384',
    count: 0
  },
  {
    code: '620521',
    name: '清水县',
    city_level: 3,
    lng: '106.137293',
    lat: '34.749865',
    count: 0
  },
  {
    code: '620522',
    name: '秦安县',
    city_level: 3,
    lng: '105.674983',
    lat: '34.858916',
    count: 0
  },
  {
    code: '620523',
    name: '甘谷县',
    city_level: 3,
    lng: '105.340864',
    lat: '34.745465',
    count: 0
  },
  {
    code: '620524',
    name: '武山县',
    city_level: 3,
    lng: '104.890782',
    lat: '34.721380',
    count: 0
  },
  {
    code: '620525',
    name: '张家川回族自治县',
    city_level: 3,
    lng: '106.204518',
    lat: '34.988037',
    count: 0
  },
  {
    code: '620602',
    name: '凉州区',
    city_level: 3,
    lng: '102.729890',
    lat: '37.820619',
    count: 0
  },
  {
    code: '620621',
    name: '民勤县',
    city_level: 3,
    lng: '103.093792',
    lat: '38.624350',
    count: 0
  },
  {
    code: '620622',
    name: '古浪县',
    city_level: 3,
    lng: '102.897533',
    lat: '37.470120',
    count: 0
  },
  {
    code: '620623',
    name: '天祝藏族自治县',
    city_level: 3,
    lng: '103.141757',
    lat: '36.971740',
    count: 0
  },
  {
    code: '620702',
    name: '甘州区',
    city_level: 3,
    lng: '100.478058',
    lat: '38.929763',
    count: 0
  },
  {
    code: '620721',
    name: '肃南裕固族自治县',
    city_level: 3,
    lng: '99.615601',
    lat: '38.836932',
    count: 0
  },
  {
    code: '620722',
    name: '民乐县',
    city_level: 3,
    lng: '100.812860',
    lat: '38.430794',
    count: 0
  },
  {
    code: '620723',
    name: '临泽县',
    city_level: 3,
    lng: '100.164445',
    lat: '39.152642',
    count: 0
  },
  {
    code: '620724',
    name: '高台县',
    city_level: 3,
    lng: '99.819317',
    lat: '39.377733',
    count: 0
  },
  {
    code: '620725',
    name: '山丹县',
    city_level: 3,
    lng: '101.088575',
    lat: '38.784758',
    count: 0
  },
  {
    code: '620802',
    name: '崆峒区',
    city_level: 3,
    lng: '106.674767',
    lat: '35.542491',
    count: 0
  },
  {
    code: '620821',
    name: '泾川县',
    city_level: 3,
    lng: '107.367850',
    lat: '35.332666',
    count: 0
  },
  {
    code: '620822',
    name: '灵台县',
    city_level: 3,
    lng: '107.621124',
    lat: '35.065399',
    count: 0
  },
  {
    code: '620823',
    name: '崇信县',
    city_level: 3,
    lng: '107.035409',
    lat: '35.302123',
    count: 0
  },
  {
    code: '620825',
    name: '庄浪县',
    city_level: 3,
    lng: '106.036687',
    lat: '35.202385',
    count: 0
  },
  {
    code: '620826',
    name: '静宁县',
    city_level: 3,
    lng: '105.732556',
    lat: '35.521977',
    count: 0
  },
  {
    code: '620881',
    name: '华亭市',
    city_level: 3,
    lng: '106.662460',
    lat: '35.219951',
    count: 0
  },
  {
    code: '620902',
    name: '肃州区',
    city_level: 3,
    lng: '98.507850',
    lat: '39.745071',
    count: 0
  },
  {
    code: '620921',
    name: '金塔县',
    city_level: 3,
    lng: '98.903270',
    lat: '39.983599',
    count: 0
  },
  {
    code: '620922',
    name: '瓜州县',
    city_level: 3,
    lng: '95.782306',
    lat: '40.520545',
    count: 0
  },
  {
    code: '620923',
    name: '肃北蒙古族自治县',
    city_level: 3,
    lng: '94.876579',
    lat: '39.512450',
    count: 0
  },
  {
    code: '620924',
    name: '阿克塞哈萨克族自治县',
    city_level: 3,
    lng: '94.340204',
    lat: '39.633943',
    count: 0
  },
  {
    code: '620981',
    name: '玉门市',
    city_level: 2,
    lng: '98.494483',
    lat: '39.732410',
    count: 0
  },
  {
    code: '620982',
    name: '敦煌市',
    city_level: 3,
    lng: '94.661967',
    lat: '40.142128',
    count: 0
  },
  {
    code: '621002',
    name: '西峰区',
    city_level: 3,
    lng: '107.651077',
    lat: '35.730652',
    count: 0
  },
  {
    code: '621021',
    name: '庆城县',
    city_level: 3,
    lng: '107.881802',
    lat: '36.016299',
    count: 0
  },
  {
    code: '621022',
    name: '环县',
    city_level: 3,
    lng: '107.308501',
    lat: '36.568435',
    count: 0
  },
  {
    code: '621023',
    name: '华池县',
    city_level: 3,
    lng: '107.990035',
    lat: '36.461355',
    count: 0
  },
  {
    code: '621024',
    name: '合水县',
    city_level: 3,
    lng: '108.019530',
    lat: '35.819243',
    count: 0
  },
  {
    code: '621025',
    name: '正宁县',
    city_level: 3,
    lng: '108.359976',
    lat: '35.491890',
    count: 0
  },
  {
    code: '621026',
    name: '宁县',
    city_level: 3,
    lng: '107.928369',
    lat: '35.502177',
    count: 0
  },
  {
    code: '621027',
    name: '镇原县',
    city_level: 3,
    lng: '107.200832',
    lat: '35.677462',
    count: 0
  },
  {
    code: '621102',
    name: '安定区',
    city_level: 3,
    lng: '104.610668',
    lat: '35.580629',
    count: 0
  },
  {
    code: '621121',
    name: '通渭县',
    city_level: 3,
    lng: '105.242061',
    lat: '35.210831',
    count: 0
  },
  {
    code: '621122',
    name: '陇西县',
    city_level: 3,
    lng: '104.634984',
    lat: '35.003940',
    count: 0
  },
  {
    code: '621123',
    name: '渭源县',
    city_level: 3,
    lng: '104.215467',
    lat: '35.136755',
    count: 0
  },
  {
    code: '621124',
    name: '临洮县',
    city_level: 3,
    lng: '103.859565',
    lat: '35.394989',
    count: 0
  },
  {
    code: '621125',
    name: '漳县',
    city_level: 3,
    lng: '104.471572',
    lat: '34.848444',
    count: 0
  },
  {
    code: '621126',
    name: '岷县',
    city_level: 3,
    lng: '104.036880',
    lat: '34.438076',
    count: 0
  },
  {
    code: '621202',
    name: '武都区',
    city_level: 3,
    lng: '104.926337',
    lat: '33.392211',
    count: 0
  },
  {
    code: '621221',
    name: '成县',
    city_level: 3,
    lng: '105.742203',
    lat: '33.750477',
    count: 0
  },
  {
    code: '621222',
    name: '文县',
    city_level: 3,
    lng: '104.683434',
    lat: '32.943815',
    count: 0
  },
  {
    code: '621223',
    name: '宕昌县',
    city_level: 3,
    lng: '104.393385',
    lat: '34.047261',
    count: 0
  },
  {
    code: '621224',
    name: '康县',
    city_level: 3,
    lng: '105.609169',
    lat: '33.329136',
    count: 0
  },
  {
    code: '621225',
    name: '西和县',
    city_level: 3,
    lng: '105.298756',
    lat: '34.014215',
    count: 0
  },
  {
    code: '621226',
    name: '礼县',
    city_level: 3,
    lng: '105.178640',
    lat: '34.189345',
    count: 0
  },
  {
    code: '621227',
    name: '徽县',
    city_level: 3,
    lng: '106.087780',
    lat: '33.768826',
    count: 0
  },
  {
    code: '621228',
    name: '两当县',
    city_level: 3,
    lng: '106.304967',
    lat: '33.908917',
    count: 0
  },
  {
    code: '622901',
    name: '临夏市',
    city_level: 2,
    lng: '103.210538',
    lat: '35.601182',
    count: 0
  },
  {
    code: '622921',
    name: '临夏县',
    city_level: 3,
    lng: '102.995502',
    lat: '35.491637',
    count: 0
  },
  {
    code: '622922',
    name: '康乐县',
    city_level: 3,
    lng: '103.708354',
    lat: '35.370505',
    count: 0
  },
  {
    code: '622923',
    name: '永靖县',
    city_level: 3,
    lng: '103.285854',
    lat: '35.958306',
    count: 0
  },
  {
    code: '622924',
    name: '广河县',
    city_level: 3,
    lng: '103.575834',
    lat: '35.488052',
    count: 0
  },
  {
    code: '622925',
    name: '和政县',
    city_level: 3,
    lng: '103.350997',
    lat: '35.424603',
    count: 0
  },
  {
    code: '622926',
    name: '东乡族自治县',
    city_level: 3,
    lng: '103.389346',
    lat: '35.663752',
    count: 0
  },
  {
    code: '622927',
    name: '积石山保安族东乡族撒拉族自治县',
    city_level: 3,
    lng: '102.875843',
    lat: '35.717661',
    count: 0
  },
  {
    code: '623001',
    name: '合作市',
    city_level: 3,
    lng: '102.910882',
    lat: '35.000399',
    count: 0
  },
  {
    code: '623021',
    name: '临潭县',
    city_level: 3,
    lng: '103.353919',
    lat: '34.692747',
    count: 0
  },
  {
    code: '623022',
    name: '卓尼县',
    city_level: 3,
    lng: '103.507109',
    lat: '34.589588',
    count: 0
  },
  {
    code: '623023',
    name: '舟曲县',
    city_level: 3,
    lng: '104.371586',
    lat: '33.785259',
    count: 0
  },
  {
    code: '623024',
    name: '迭部县',
    city_level: 3,
    lng: '103.221870',
    lat: '34.055939',
    count: 0
  },
  {
    code: '623025',
    name: '玛曲县',
    city_level: 3,
    lng: '102.072698',
    lat: '33.997712',
    count: 0
  },
  {
    code: '623026',
    name: '碌曲县',
    city_level: 3,
    lng: '102.487327',
    lat: '34.590944',
    count: 0
  },
  {
    code: '623027',
    name: '夏河县',
    city_level: 3,
    lng: '102.521807',
    lat: '35.202503',
    count: 0
  },
  {
    code: '630102',
    name: '城东区',
    city_level: 3,
    lng: '101.803717',
    lat: '36.599745',
    count: 0
  },
  {
    code: '630103',
    name: '城中区',
    city_level: 3,
    lng: '101.784554',
    lat: '36.621181',
    count: 0
  },
  {
    code: '630104',
    name: '城西区',
    city_level: 3,
    lng: '101.765843',
    lat: '36.628305',
    count: 0
  },
  {
    code: '630105',
    name: '城北区',
    city_level: 3,
    lng: '101.766228',
    lat: '36.650038',
    count: 0
  },
  {
    code: '630106',
    name: '湟中区',
    city_level: 2,
    lng: '101.778228',
    lat: '36.617144',
    count: 0
  },
  {
    code: '630121',
    name: '大通回族土族自治县',
    city_level: 3,
    lng: '101.685643',
    lat: '36.926955',
    count: 0
  },
  {
    code: '630123',
    name: '湟源县',
    city_level: 3,
    lng: '101.256464',
    lat: '36.682427',
    count: 0
  },
  {
    code: '630202',
    name: '乐都区',
    city_level: 3,
    lng: '102.401725',
    lat: '36.482058',
    count: 0
  },
  {
    code: '630203',
    name: '平安区',
    city_level: 3,
    lng: '102.108835',
    lat: '36.500563',
    count: 0
  },
  {
    code: '630222',
    name: '民和回族土族自治县',
    city_level: 3,
    lng: '102.830892',
    lat: '36.320321',
    count: 0
  },
  {
    code: '630223',
    name: '互助土族自治县',
    city_level: 3,
    lng: '101.959271',
    lat: '36.844249',
    count: 0
  },
  {
    code: '630224',
    name: '化隆回族自治县',
    city_level: 3,
    lng: '102.264143',
    lat: '36.094908',
    count: 0
  },
  {
    code: '630225',
    name: '循化撒拉族自治县',
    city_level: 3,
    lng: '102.485646',
    lat: '35.848586',
    count: 0
  },
  {
    code: '632221',
    name: '门源回族自治县',
    city_level: 3,
    lng: '101.622364',
    lat: '37.376449',
    count: 0
  },
  {
    code: '632222',
    name: '祁连县',
    city_level: 3,
    lng: '100.253211',
    lat: '38.177112',
    count: 0
  },
  {
    code: '632223',
    name: '海晏县',
    city_level: 3,
    lng: '100.994430',
    lat: '36.896467',
    count: 0
  },
  {
    code: '632224',
    name: '刚察县',
    city_level: 3,
    lng: '100.145833',
    lat: '37.325470',
    count: 0
  },
  {
    code: '632301',
    name: '同仁市',
    city_level: 3,
    lng: '102.018323',
    lat: '35.516063',
    count: 0
  },
  {
    code: '632322',
    name: '尖扎县',
    city_level: 3,
    lng: '102.031183',
    lat: '35.938299',
    count: 0
  },
  {
    code: '632323',
    name: '泽库县',
    city_level: 3,
    lng: '101.466689',
    lat: '35.035313',
    count: 0
  },
  {
    code: '632324',
    name: '河南蒙古族自治县',
    city_level: 3,
    lng: '101.616308',
    lat: '34.734773',
    count: 0
  },
  {
    code: '632521',
    name: '共和县',
    city_level: 3,
    lng: '100.620031',
    lat: '36.284107',
    count: 0
  },
  {
    code: '632522',
    name: '同德县',
    city_level: 3,
    lng: '100.578052',
    lat: '35.254791',
    count: 0
  },
  {
    code: '632523',
    name: '贵德县',
    city_level: 3,
    lng: '101.435256',
    lat: '36.040680',
    count: 0
  },
  {
    code: '632524',
    name: '兴海县',
    city_level: 3,
    lng: '99.987966',
    lat: '35.588613',
    count: 0
  },
  {
    code: '632525',
    name: '贵南县',
    city_level: 3,
    lng: '100.747503',
    lat: '35.586715',
    count: 0
  },
  {
    code: '632621',
    name: '玛沁县',
    city_level: 3,
    lng: '100.238888',
    lat: '34.477433',
    count: 0
  },
  {
    code: '632622',
    name: '班玛县',
    city_level: 3,
    lng: '100.737138',
    lat: '32.932723',
    count: 0
  },
  {
    code: '632623',
    name: '甘德县',
    city_level: 3,
    lng: '99.900905',
    lat: '33.969219',
    count: 0
  },
  {
    code: '632624',
    name: '达日县',
    city_level: 3,
    lng: '99.651392',
    lat: '33.748921',
    count: 0
  },
  {
    code: '632625',
    name: '久治县',
    city_level: 3,
    lng: '101.482831',
    lat: '33.429471',
    count: 0
  },
  {
    code: '632626',
    name: '玛多县',
    city_level: 3,
    lng: '98.209206',
    lat: '34.915946',
    count: 0
  },
  {
    code: '632701',
    name: '玉树市',
    city_level: 3,
    lng: '97.008785',
    lat: '32.993107',
    count: 0
  },
  {
    code: '632722',
    name: '杂多县',
    city_level: 3,
    lng: '95.300723',
    lat: '32.893185',
    count: 0
  },
  {
    code: '632723',
    name: '称多县',
    city_level: 3,
    lng: '97.110832',
    lat: '33.369218',
    count: 0
  },
  {
    code: '632724',
    name: '治多县',
    city_level: 3,
    lng: '95.613080',
    lat: '33.852751',
    count: 0
  },
  {
    code: '632725',
    name: '囊谦县',
    city_level: 3,
    lng: '96.480650',
    lat: '32.203246',
    count: 0
  },
  {
    code: '632726',
    name: '曲麻莱县',
    city_level: 3,
    lng: '95.797367',
    lat: '34.126429',
    count: 0
  },
  {
    code: '632801',
    name: '格尔木市',
    city_level: 3,
    lng: '94.928484',
    lat: '36.406404',
    count: 0
  },
  {
    code: '632802',
    name: '德令哈市',
    city_level: 3,
    lng: '97.360985',
    lat: '37.369436',
    count: 0
  },
  {
    code: '632803',
    name: '茫崖市',
    city_level: 2,
    lng: '97.369751',
    lat: '37.377139',
    count: 0
  },
  {
    code: '632821',
    name: '乌兰县',
    city_level: 3,
    lng: '98.480195',
    lat: '36.929749',
    count: 0
  },
  {
    code: '632822',
    name: '都兰县',
    city_level: 3,
    lng: '98.095844',
    lat: '36.302496',
    count: 0
  },
  {
    code: '632823',
    name: '天峻县',
    city_level: 3,
    lng: '99.022984',
    lat: '37.300851',
    count: 0
  },
  {
    code: '640104',
    name: '兴庆区',
    city_level: 3,
    lng: '106.288650',
    lat: '38.473610',
    count: 0
  },
  {
    code: '640105',
    name: '西夏区',
    city_level: 3,
    lng: '106.156394',
    lat: '38.496040',
    count: 0
  },
  {
    code: '640106',
    name: '金凤区',
    city_level: 3,
    lng: '106.242542',
    lat: '38.473305',
    count: 0
  },
  {
    code: '640121',
    name: '永宁县',
    city_level: 3,
    lng: '106.253145',
    lat: '38.277373',
    count: 0
  },
  {
    code: '640122',
    name: '贺兰县',
    city_level: 3,
    lng: '106.349828',
    lat: '38.554344',
    count: 0
  },
  {
    code: '640181',
    name: '灵武市',
    city_level: 3,
    lng: '106.340054',
    lat: '38.102655',
    count: 0
  },
  {
    code: '640202',
    name: '大武口区',
    city_level: 3,
    lng: '106.367861',
    lat: '39.019060',
    count: 0
  },
  {
    code: '640205',
    name: '惠农区',
    city_level: 3,
    lng: '106.781176',
    lat: '39.239302',
    count: 0
  },
  {
    code: '640221',
    name: '平罗县',
    city_level: 3,
    lng: '106.523474',
    lat: '38.913544',
    count: 0
  },
  {
    code: '640302',
    name: '利通区',
    city_level: 3,
    lng: '106.212566',
    lat: '37.983458',
    count: 0
  },
  {
    code: '640303',
    name: '红寺堡区',
    city_level: 3,
    lng: '106.062114',
    lat: '37.425702',
    count: 0
  },
  {
    code: '640323',
    name: '盐池县',
    city_level: 3,
    lng: '107.407359',
    lat: '37.783205',
    count: 0
  },
  {
    code: '640324',
    name: '同心县',
    city_level: 3,
    lng: '105.914458',
    lat: '36.980575',
    count: 0
  },
  {
    code: '640381',
    name: '青铜峡市',
    city_level: 3,
    lng: '106.078818',
    lat: '38.021302',
    count: 0
  },
  {
    code: '640402',
    name: '原州区',
    city_level: 3,
    lng: '106.287782',
    lat: '36.003740',
    count: 0
  },
  {
    code: '640422',
    name: '西吉县',
    city_level: 3,
    lng: '105.729085',
    lat: '35.963913',
    count: 0
  },
  {
    code: '640423',
    name: '隆德县',
    city_level: 3,
    lng: '106.111595',
    lat: '35.625915',
    count: 0
  },
  {
    code: '640424',
    name: '泾源县',
    city_level: 3,
    lng: '106.330646',
    lat: '35.498160',
    count: 0
  },
  {
    code: '640425',
    name: '彭阳县',
    city_level: 3,
    lng: '106.638340',
    lat: '35.849565',
    count: 0
  },
  {
    code: '640502',
    name: '沙坡头区',
    city_level: 3,
    lng: '105.190536',
    lat: '37.514564',
    count: 0
  },
  {
    code: '640521',
    name: '中宁县',
    city_level: 3,
    lng: '105.685285',
    lat: '37.491505',
    count: 0
  },
  {
    code: '640522',
    name: '海原县',
    city_level: 3,
    lng: '105.643487',
    lat: '36.565033',
    count: 0
  },
  {
    code: '650102',
    name: '天山区',
    city_level: 3,
    lng: '87.631676',
    lat: '43.794399',
    count: 0
  },
  {
    code: '650103',
    name: '沙依巴克区',
    city_level: 3,
    lng: '87.598195',
    lat: '43.800939',
    count: 0
  },
  {
    code: '650104',
    name: '新市区',
    city_level: 3,
    lng: '87.573916',
    lat: '43.843752',
    count: 0
  },
  {
    code: '650105',
    name: '水磨沟区',
    city_level: 3,
    lng: '87.642482',
    lat: '43.832459',
    count: 0
  },
  {
    code: '650106',
    name: '头屯河区',
    city_level: 2,
    lng: '87.616848',
    lat: '43.825592',
    count: 0
  },
  {
    code: '650107',
    name: '达坂城区',
    city_level: 3,
    lng: '88.311099',
    lat: '43.363668',
    count: 0
  },
  {
    code: '650109',
    name: '米东区',
    city_level: 3,
    lng: '87.655755',
    lat: '43.973579',
    count: 0
  },
  {
    code: '650121',
    name: '乌鲁木齐县',
    city_level: 3,
    lng: '87.409417',
    lat: '43.471360',
    count: 0
  },
  {
    code: '650202',
    name: '独山子区',
    city_level: 3,
    lng: '84.886974',
    lat: '44.328096',
    count: 0
  },
  {
    code: '650203',
    name: '克拉玛依区',
    city_level: 3,
    lng: '84.867844',
    lat: '45.602526',
    count: 0
  },
  {
    code: '650204',
    name: '白碱滩区',
    city_level: 3,
    lng: '85.119186',
    lat: '45.685473',
    count: 0
  },
  {
    code: '650205',
    name: '乌尔禾区',
    city_level: 3,
    lng: '85.693742',
    lat: '46.089148',
    count: 0
  },
  {
    code: '650402',
    name: '高昌区',
    city_level: 3,
    lng: '89.182342',
    lat: '42.947635',
    count: 0
  },
  {
    code: '650421',
    name: '鄯善县',
    city_level: 3,
    lng: '90.213330',
    lat: '42.868744',
    count: 0
  },
  {
    code: '650422',
    name: '托克逊县',
    city_level: 3,
    lng: '88.638816',
    lat: '42.792454',
    count: 0
  },
  {
    code: '650502',
    name: '伊州区',
    city_level: 3,
    lng: '93.514797',
    lat: '42.827255',
    count: 0
  },
  {
    code: '650521',
    name: '巴里坤哈萨克自治县',
    city_level: 3,
    lng: '93.016624',
    lat: '43.598762',
    count: 0
  },
  {
    code: '650522',
    name: '伊吾县',
    city_level: 3,
    lng: '94.697074',
    lat: '43.254978',
    count: 0
  },
  {
    code: '652301',
    name: '昌吉市',
    city_level: 3,
    lng: '87.304112',
    lat: '44.013183',
    count: 0
  },
  {
    code: '652302',
    name: '阜康市',
    city_level: 3,
    lng: '87.946894',
    lat: '44.168576',
    count: 0
  },
  {
    code: '652323',
    name: '呼图壁县',
    city_level: 3,
    lng: '86.898902',
    lat: '44.191428',
    count: 0
  },
  {
    code: '652324',
    name: '玛纳斯县',
    city_level: 3,
    lng: '86.213997',
    lat: '44.303893',
    count: 0
  },
  {
    code: '652325',
    name: '奇台县',
    city_level: 3,
    lng: '89.593967',
    lat: '44.022066',
    count: 0
  },
  {
    code: '652327',
    name: '吉木萨尔县',
    city_level: 3,
    lng: '89.180437',
    lat: '44.000497',
    count: 0
  },
  {
    code: '652328',
    name: '木垒哈萨克自治县',
    city_level: 3,
    lng: '90.286028',
    lat: '43.834689',
    count: 0
  },
  {
    code: '652701',
    name: '博乐市',
    city_level: 3,
    lng: '82.051005',
    lat: '44.853870',
    count: 0
  },
  {
    code: '652702',
    name: '阿拉山口市',
    city_level: 3,
    lng: '82.559396',
    lat: '45.172228',
    count: 0
  },
  {
    code: '652722',
    name: '精河县',
    city_level: 3,
    lng: '82.894195',
    lat: '44.600408',
    count: 0
  },
  {
    code: '652723',
    name: '温泉县',
    city_level: 3,
    lng: '81.024816',
    lat: '44.968857',
    count: 0
  },
  {
    code: '652801',
    name: '库尔勒市',
    city_level: 3,
    lng: '86.174633',
    lat: '41.725892',
    count: 0
  },
  {
    code: '652822',
    name: '轮台县',
    city_level: 3,
    lng: '84.252156',
    lat: '41.777702',
    count: 0
  },
  {
    code: '652823',
    name: '尉犁县',
    city_level: 3,
    lng: '86.261321',
    lat: '41.343933',
    count: 0
  },
  {
    code: '652824',
    name: '若羌县',
    city_level: 3,
    lng: '88.167152',
    lat: '39.023242',
    count: 0
  },
  {
    code: '652825',
    name: '且末县',
    city_level: 3,
    lng: '85.529702',
    lat: '38.145486',
    count: 0
  },
  {
    code: '652826',
    name: '焉耆回族自治县',
    city_level: 3,
    lng: '86.574067',
    lat: '42.059759',
    count: 0
  },
  {
    code: '652827',
    name: '和静县',
    city_level: 3,
    lng: '86.384065',
    lat: '42.323625',
    count: 0
  },
  {
    code: '652828',
    name: '和硕县',
    city_level: 3,
    lng: '86.863963',
    lat: '42.268371',
    count: 0
  },
  {
    code: '652829',
    name: '博湖县',
    city_level: 3,
    lng: '86.631998',
    lat: '41.980152',
    count: 0
  },
  {
    code: '652901',
    name: '阿克苏市',
    city_level: 2,
    lng: '80.260605',
    lat: '41.168779',
    count: 0
  },
  {
    code: '652902',
    name: '库车市',
    city_level: 3,
    lng: '83.060231',
    lat: '41.720265',
    count: 0
  },
  {
    code: '652922',
    name: '温宿县',
    city_level: 3,
    lng: '80.238959',
    lat: '41.276688',
    count: 0
  },
  {
    code: '652924',
    name: '沙雅县',
    city_level: 3,
    lng: '82.781819',
    lat: '41.221667',
    count: 0
  },
  {
    code: '652925',
    name: '新和县',
    city_level: 3,
    lng: '82.609220',
    lat: '41.548118',
    count: 0
  },
  {
    code: '652926',
    name: '拜城县',
    city_level: 3,
    lng: '81.874156',
    lat: '41.796910',
    count: 0
  },
  {
    code: '652927',
    name: '乌什县',
    city_level: 3,
    lng: '79.224445',
    lat: '41.214652',
    count: 0
  },
  {
    code: '652928',
    name: '阿瓦提县',
    city_level: 3,
    lng: '80.373137',
    lat: '40.644529',
    count: 0
  },
  {
    code: '652929',
    name: '柯坪县',
    city_level: 3,
    lng: '79.047291',
    lat: '40.508340',
    count: 0
  },
  {
    code: '653001',
    name: '阿图什市',
    city_level: 3,
    lng: '76.168400',
    lat: '39.716160',
    count: 0
  },
  {
    code: '653022',
    name: '阿克陶县',
    city_level: 3,
    lng: '75.947396',
    lat: '39.147785',
    count: 0
  },
  {
    code: '653023',
    name: '阿合奇县',
    city_level: 3,
    lng: '78.446253',
    lat: '40.936936',
    count: 0
  },
  {
    code: '653024',
    name: '乌恰县',
    city_level: 3,
    lng: '75.259228',
    lat: '39.719310',
    count: 0
  },
  {
    code: '653101',
    name: '喀什市',
    city_level: 2,
    lng: '75.989755',
    lat: '39.470400',
    count: 0
  },
  {
    code: '653121',
    name: '疏附县',
    city_level: 3,
    lng: '75.862814',
    lat: '39.375044',
    count: 0
  },
  {
    code: '653122',
    name: '疏勒县',
    city_level: 3,
    lng: '76.048139',
    lat: '39.401385',
    count: 0
  },
  {
    code: '653123',
    name: '英吉沙县',
    city_level: 3,
    lng: '76.175729',
    lat: '38.930382',
    count: 0
  },
  {
    code: '653124',
    name: '泽普县',
    city_level: 3,
    lng: '77.260103',
    lat: '38.184955',
    count: 0
  },
  {
    code: '653125',
    name: '莎车县',
    city_level: 3,
    lng: '77.245761',
    lat: '38.414217',
    count: 0
  },
  {
    code: '653126',
    name: '叶城县',
    city_level: 3,
    lng: '77.413836',
    lat: '37.882989',
    count: 0
  },
  {
    code: '653127',
    name: '麦盖提县',
    city_level: 3,
    lng: '77.648006',
    lat: '38.902232',
    count: 0
  },
  {
    code: '653128',
    name: '岳普湖县',
    city_level: 3,
    lng: '76.773163',
    lat: '39.224200',
    count: 0
  },
  {
    code: '653129',
    name: '伽师县',
    city_level: 3,
    lng: '76.723720',
    lat: '39.488182',
    count: 0
  },
  {
    code: '653130',
    name: '巴楚县',
    city_level: 3,
    lng: '78.549297',
    lat: '39.785155',
    count: 0
  },
  {
    code: '653131',
    name: '塔什库尔干塔吉克自治县',
    city_level: 3,
    lng: '75.229889',
    lat: '37.772094',
    count: 0
  },
  {
    code: '653201',
    name: '和田市',
    city_level: 3,
    lng: '79.913534',
    lat: '37.112149',
    count: 0
  },
  {
    code: '653221',
    name: '和田县',
    city_level: 3,
    lng: '79.819070',
    lat: '37.120031',
    count: 0
  },
  {
    code: '653222',
    name: '墨玉县',
    city_level: 3,
    lng: '79.728841',
    lat: '37.277340',
    count: 0
  },
  {
    code: '653223',
    name: '皮山县',
    city_level: 3,
    lng: '78.283669',
    lat: '37.621450',
    count: 0
  },
  {
    code: '653224',
    name: '洛浦县',
    city_level: 3,
    lng: '80.188986',
    lat: '37.073667',
    count: 0
  },
  {
    code: '653225',
    name: '策勒县',
    city_level: 3,
    lng: '80.806159',
    lat: '36.998335',
    count: 0
  },
  {
    code: '653226',
    name: '于田县',
    city_level: 3,
    lng: '81.677418',
    lat: '36.857081',
    count: 0
  },
  {
    code: '653227',
    name: '民丰县',
    city_level: 3,
    lng: '82.695862',
    lat: '37.064080',
    count: 0
  },
  {
    code: '654002',
    name: '伊宁市',
    city_level: 3,
    lng: '81.277950',
    lat: '43.908558',
    count: 0
  },
  {
    code: '654003',
    name: '奎屯市',
    city_level: 3,
    lng: '84.903267',
    lat: '44.426529',
    count: 0
  },
  {
    code: '654004',
    name: '霍尔果斯市',
    city_level: 3,
    lng: '80.420759',
    lat: '44.201669',
    count: 0
  },
  {
    code: '654021',
    name: '伊宁县',
    city_level: 3,
    lng: '81.527453',
    lat: '43.977138',
    count: 0
  },
  {
    code: '654022',
    name: '察布查尔锡伯自治县',
    city_level: 3,
    lng: '81.151337',
    lat: '43.840726',
    count: 0
  },
  {
    code: '654023',
    name: '霍城县',
    city_level: 3,
    lng: '80.874181',
    lat: '44.053592',
    count: 0
  },
  {
    code: '654024',
    name: '巩留县',
    city_level: 3,
    lng: '82.231718',
    lat: '43.482628',
    count: 0
  },
  {
    code: '654025',
    name: '新源县',
    city_level: 3,
    lng: '83.260770',
    lat: '43.429930',
    count: 0
  },
  {
    code: '654026',
    name: '昭苏县',
    city_level: 3,
    lng: '81.130975',
    lat: '43.157293',
    count: 0
  },
  {
    code: '654027',
    name: '特克斯县',
    city_level: 3,
    lng: '81.836206',
    lat: '43.217184',
    count: 0
  },
  {
    code: '654028',
    name: '尼勒克县',
    city_level: 3,
    lng: '82.511810',
    lat: '43.800247',
    count: 0
  },
  {
    code: '654201',
    name: '塔城市',
    city_level: 3,
    lng: '82.978928',
    lat: '46.748523',
    count: 0
  },
  {
    code: '654202',
    name: '乌苏市',
    city_level: 3,
    lng: '84.713736',
    lat: '44.418887',
    count: 0
  },
  {
    code: '654221',
    name: '额敏县',
    city_level: 3,
    lng: '83.628303',
    lat: '46.524673',
    count: 0
  },
  {
    code: '654223',
    name: '沙湾县',
    city_level: 3,
    lng: '85.619416',
    lat: '44.326388',
    count: 0
  },
  {
    code: '654224',
    name: '托里县',
    city_level: 3,
    lng: '83.606951',
    lat: '45.947638',
    count: 0
  },
  {
    code: '654225',
    name: '裕民县',
    city_level: 3,
    lng: '82.982668',
    lat: '46.201104',
    count: 0
  },
  {
    code: '654226',
    name: '和布克赛尔蒙古自治县',
    city_level: 3,
    lng: '85.728328',
    lat: '46.793235',
    count: 0
  },
  {
    code: '654301',
    name: '阿勒泰市',
    city_level: 3,
    lng: '88.131842',
    lat: '47.827309',
    count: 0
  },
  {
    code: '654321',
    name: '布尔津县',
    city_level: 3,
    lng: '86.874897',
    lat: '47.701850',
    count: 0
  },
  {
    code: '654322',
    name: '富蕴县',
    city_level: 3,
    lng: '89.530734',
    lat: '46.995158',
    count: 0
  },
  {
    code: '654323',
    name: '福海县',
    city_level: 3,
    lng: '87.486703',
    lat: '47.111919',
    count: 0
  },
  {
    code: '654324',
    name: '哈巴河县',
    city_level: 3,
    lng: '86.418621',
    lat: '48.060846',
    count: 0
  },
  {
    code: '654325',
    name: '青河县',
    city_level: 3,
    lng: '90.382961',
    lat: '46.674205',
    count: 0
  },
  {
    code: '654326',
    name: '吉木乃县',
    city_level: 3,
    lng: '85.874096',
    lat: '47.443101',
    count: 0
  },
  {
    code: '659001',
    name: '石河子市',
    city_level: 3,
    lng: '86.08046',
    lat: '44.305428',
    count: 0
  },
  {
    code: '659002',
    name: '阿拉尔市',
    city_level: 3,
    lng: '81.280527',
    lat: '40.547653',
    count: 0
  },
  {
    code: '659003',
    name: '图木舒克市',
    city_level: 3,
    lng: '79.074089',
    lat: '39.868968',
    count: 0
  },
  {
    code: '659004',
    name: '五家渠市',
    city_level: 3,
    lng: '87.54324',
    lat: '44.166756',
    count: 0
  },
  {
    code: '659005',
    name: '北屯市',
    city_level: 3,
    lng: '87.832595',
    lat: '47.326883',
    count: 0
  },
  {
    code: '659006',
    name: '铁门关市',
    city_level: 3,
    lng: '85.669533',
    lat: '41.8633',
    count: 0
  },
  {
    code: '659007',
    name: '双河市',
    city_level: 3,
    lng: '82.353656',
    lat: '44.840524',
    count: 0
  },
  {
    code: '659008',
    name: '可克达拉市',
    city_level: 3,
    lng: '81.044542',
    lat: '43.944798',
    count: 0
  },
  {
    code: '659009',
    name: '昆玉市',
    city_level: 3,
    lng: '79.291083',
    lat: '37.209642',
    count: 0
  },
  {
    code: '659010',
    name: '胡杨河市',
    city_level: 3,
    lng: '85.997154',
    lat: '46.451208',
    count: 0
  },
  {
    code: '710000',
    name: '台湾省',
    city_level: 3,
    lng: '121.508903',
    lat: '25.044319'
  },
  {
    code: '810000',
    name: '香港特别行政区',
    city_level: 3,
    lng: '114.163825',
    lat: '22.276284'
  },
  {
    code: '820000',
    name: '澳门特别行政区',
    city_level: 3,
    lng: '113.543028',
    lat: '22.186835'
  }
]
export default {
  list,
  province_list,
  city_list,
  district_list
}
